import styled from 'styled-components';

const SelectFilterStyle = styled.div`
  &.v-select-filter {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 15px;
    &-tenant {
      padding: 0 !important;
      button {
        border: 1px solid #8c8c8c;
      }
      .v-select-filter {
        &-genres,
        &-languages,
        &-regions {
          .Select__control {
            border: 1px solid #8c8c8c;
          }
          .Select__indicator {
            color: #121212 !important;
          }
        }
      }
    }
  }
  .v-select-filter {
    &--btnFilter {
      max-width: 275px;
      width: 100%;
      height: 38px;
      border-radius: 38px;
      background-color: #e5e5e5;
      background-size: cover;
      font-size: 14px;
      color: #627280;
      text-decoration: none solid rgb(98, 114, 128);
      box-shadow: 0px 1px 2px rgb(0 0 0);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 2rem;
      margin-bottom: 1rem;
    }
    &__col {
      width: 45%;
      margin-right: 1rem;
      padding-bottom: 0.5rem;
      color: #627280;
      /* display: flex;
      justify-content: center; */
      button {
        width: 100%;
        padding: 0.4825rem 1.5rem;
        background: #e5e5e5;
        color: #627280;
        i {
          font-size: 18px;
          padding-right: 0.1rem;
        }
      }
      margin-bottom: 1rem;
    }
  }
  @media (min-width: 768px) {
    &.v-select-filter {
      padding: 20px 0;
      justify-content: flex-start;
    }
    .v-select-filter {
      &__col {
        width: 22%;
        max-width: 275px;
        button {
          width: auto;
        }
      }
    }
  }
  @media (min-width: 992px) {
    .v-select-filter {
      &__col {
        width: 20%;
        max-width: 270px;
        margin-bottom: 0;
      }
    }
  }
  @media (min-width: 1280px) {
    .v-select-filter {
      &__col {
        width: 15%;
      }
    }
    &.v-select-filter {
      &-youtube {
        .v-select-filter__col {
          width: 23%;
        }
      }
    }
  }
`;

export { SelectFilterStyle };
