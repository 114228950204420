// Get the Component base class from Video.js
import { pushEventFirebase } from '../components/pushEventFirebase';
import { _handleRewatchCuratedLive } from '../function/initCuratedLive';
import videojs from 'video.js';
import jquery from 'jquery';

var Button = videojs.getComponent('Button');

// The videojs.extend function is used to assist with inheritance. In
// an ES6 environment, `class SeekNext extends Component` would work
// identically.

var Prev = videojs.extend(Button, {
  // The constructor of a component receives two arguments: the
  // player it will be associated with and an object of options.
  constructor: function (player, options) {
    // It is important to invoke the superclass before anything else,
    // to get all the features of components out of the box!
    Button.apply(this, arguments);
    // If a `text` option was passed in, update the text content of
    // the component.
    player.on('loadeddata', () => {
      setTimeout(() => {
        this.handleHidden();
      }, 0);
    });
    player.on('error', () => {
      this.handleHidden();
    });
  },

  // The `createEl` function of a component creates its DOM element.
  createEl: function () {
    const btn = videojs.dom.createEl('button', {
      className: 'vjs-prev-epg  vjs-control vjs-button',
    });
    btn.innerHTML =
      '<i class="vjs-icon-previous-item vjs-icon-placeholder" aria-hidden="true"></i>';
    return btn;
  },
  handleHidden: function () {
    const { entity, component } = this.options_;
    if (component.listEpg && component.listEpg.items.length > 0) {
      entity.items = component.listEpg.items;
    }
    let idWatching = component.isWatch && component.isWatch.id;
    let src = this.player_.src();
    const $player = jquery(this.player_.el_);
    let nextEps = '';
    $player.find('.vjs-next-epg').addClass('vjs-button-next-open');
    if (entity.items.length > 0) {
      entity.items.map((item, index) => {
        if (item.id === idWatching) {
          this.currentEpisode = index;
        }
      });
      nextEps = this.currentEpisode + 1;
      if (
        nextEps === entity.items.length ||
        (entity.items[nextEps] &&
          $player.find(`[id='${entity.items[nextEps].id}']`).hasClass('active'))
      ) {
        $player.find('.vjs-next-epg').removeClass('vjs-button-next-open');
        $player.addClass('vjs-button-nextepg-open');
      }
    }
  },
  handleClick: function (event) {
    const { entity, component } = this.options_;
    let prev = this.currentEpisode > 0 && this.currentEpisode - 1;
    const $player = jquery(this.player_.el_);
    if (entity.items.length > 0 && prev >= 0) {
      const episode = entity.items[prev];
      const preEpisode = entity.items[this.currentEpisode];
      _handleRewatchCuratedLive(component, episode, false);
      if ($player.hasClass('vjs-rewatching') && $player.find(`[id='${episode.id}']`)) {
        $player.find(`[id='${preEpisode.id}']`).removeClass('rewatching');
        $player.find(`[id='${episode.id}']`).addClass('rewatching');
      }
    }
  },
});

// Register the component with Video.js, so it can be used in players.
videojs.registerComponent('PrevLive', Prev);
