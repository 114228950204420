Object.defineProperty(exports, "__esModule", { value: true });
exports.getNavigationInfo = void 0;
var constants_1 = require("../constants");
function getNavigationInfo(navigationSlug, acceptLanguage) {
    if (acceptLanguage === void 0) { acceptLanguage = 'en'; }
    if (navigationSlug === constants_1.ODXKey.homePageSlug) {
        return Promise.resolve({
            name: 'Home',
            page_options: {
                content_navigation_option: 'default',
            },
            display_style: 'NORMAL',
        });
    }
    return Promise.reject('Method not implemented');
}
exports.getNavigationInfo = getNavigationInfo;
