import { firebase } from '../firebase/firebase';
import { store } from '~store';
import { getConfigByKey } from '~features/tenantConfig/services';
import * as Version from '~constants/envConfig';

const setUserProperties = () => {
  if (firebase.apps.length === 0 || !getConfigByKey('feature_advanced_web_multitenancy_enabled')) {
    return;
  }

  let firebaseConfig = getConfigByKey('integration_config.firebase');
  if (firebaseConfig) {
    firebaseConfig = JSON.parse(firebaseConfig.replace(/'/g, '"'));
  }
  if (
    !firebaseConfig ||
    (firebaseConfig && firebaseConfig.project_info) ||
    !Version.VERSION_PRELEASE
  ) {
    return;
  }
  const { language } = (store.getState() as any).root;
  const { tenant_slug } = getConfigByKey();
  const { account } = (store.getState() as any).auth;
  let memberShip = `${tenant_slug}-anonymous`;
  let params = {
    ui_language: language,
    membership: memberShip,
    user_id: '',
    build_type: Version.VERSION_PRELEASE,
  };
  if (account && account.profile) {
    const subscriptionPlanInfo = account.profile.subscription_plan_info;
    const { id } = account.profile;
    memberShip = `${tenant_slug}-free`;
    if (subscriptionPlanInfo && subscriptionPlanInfo.slug) {
      memberShip = `${tenant_slug}-${subscriptionPlanInfo.slug}`;
    }
    params = { ...params, membership: memberShip, user_id: id };
    firebase.analytics().setUserId(id);
  } else {
    firebase.analytics().setUserId(null || '');
  }
  firebase.analytics().setUserProperties(params);
};

export { setUserProperties };
