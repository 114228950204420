// import 'url-change-event';
import videojs from 'video.js';
// import * as eme from 'videojs-contrib-eme'
import _ from 'lodash';
import { getDrmLinkPlay } from '~core/method/contentMethod';
import { setClosePlayer } from './drm';
import { drmCCU } from '~api/drmApi';
import { TRAILER_TYPE } from '~core/constants';
import $ from 'jquery';
import * as shaka from 'shaka-player';

const eme = require('videojs-contrib-eme').default;
const mime = require('mime-types');

const messageErrorTitle = 'Exceed Simultaneous Watch Limit';
const messageErrorSmallTitle = `You have reached the limit for simultaneous watch. In order to watch on this device,
please close the player in other devices first and refresh this page.`;
const excludeContentTypes = [TRAILER_TYPE];
const sessionEnded = {};
let timeInterval = null;
let drmEnded = false;

const handlePingFailure = (error, player) => {
  const modal = player.createModal();

  const $contentEl = $(modal.contentEl_);
  $contentEl.append(
    $(`
    <p class='vjs-drm-error--title'>${messageErrorTitle}</p>
    <p class='vjs-drm-error--small-title'>${messageErrorSmallTitle}</p>
  `),
  );
  modal.addClass('vjs-drm-error');
  player.src('');
  clearInterval(timeInterval);
};

const handleDrmEnd = payload => {
  const { session } = payload;
  if (sessionEnded[session]) {
    return;
  }
  sessionEnded[session] = true;
  clearInterval(timeInterval);
  drmCCU({ ...payload, action: 'end' });
};

export async function drmShaka(player, content) {
  const { drm_session_info } = content;
  const link_play = getDrmLinkPlay.call(content);

  if (_.isEmpty(drm_session_info)) {
    return;
  }
  const {
    fairplay_cert_url,
    fairplay_license_path,
    merchant,
    operator_id,
    playready_license_path,
    session,
    session_id,
    user_id,
    widevide_license_path,
    auth_token,
  } = drm_session_info;

  const drmTodayData = {
    merchant,
    userId: user_id,
    sessionId: session,
  };
  let requestDrm = null;

  // if (content.type && !excludeContentTypes.includes(content.type)) {
  //   try {
  //     requestDrm = await drmCCU({ user_id, session });
  //     drmEnded = false;
  //   } catch (error) {
  //     handlePingFailure(error, player);
  //     return;
  //   }

  //   timeInterval = setInterval(() => {
  //     drmCCU({ user_id, session }).catch(error => {
  //       handlePingFailure(error, player);
  //     });
  //   }, 60 * 1000);

  //   // player.tech(true).on('licenserequestattempted', function (event) {
  //   // })
  //   // player.tech(true).on('keystatuschange', function (event) {
  //   // })
  //   // player.tech().on('keysessioncreated', function (event) {
  //   // })

  //   player.on(['dispose'], function (event) {
  //     handleDrmEnd({ user_id, session });
  //   });
  //   $(window).on('urlchangeevent', function (event) {
  //     handleDrmEnd({ user_id, session });
  //     $(window).off('urlchangeevent');
  //   });
  // }

  shaka.polyfill.installAll();

  let _src = player.src;
  player.src = function (value) {
    if (value !== undefined) {
      _src.call(player, value);
    } else {
      return link_play;
    }
  };

  const drmTodayDataB = btoa(JSON.stringify(drmTodayData));

  var headers = {
    'x-dt-auth-token': auth_token,
    'x-dt-custom-data': drmTodayDataB,
  };

  const video = player.el_.querySelector('video');
  const playerShaka = new shaka.Player(video);

  const req = await fetch(fairplay_cert_url);
  const cert = await req.arrayBuffer();

  playerShaka.configure({
    drm: {
      servers: {
        'com.widevine.alpha': widevide_license_path,
        'com.microsoft.playready': playready_license_path,
        'com.apple.fps.1_0': fairplay_license_path,
      },
      advanced: {
        'com.apple.fps.1_0': {
          serverCertificate: new Uint8Array(cert),
        },
      },
    },
  });

  playerShaka.getNetworkingEngine().registerRequestFilter(function (type, request) {
    if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
      // request.headers["x-dt-auth-token"] = "your upfront token goes here";
      request.headers = headers;
    }
  });

  playerShaka.getNetworkingEngine().registerResponseFilter(function (type, response) {
    if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
      if (playerShaka.keySystem() === 'com.apple.fps.1_0') {
        let responseText = shaka.util.StringUtils.fromUTF8(response.data);
        response.data = shaka.util.Uint8ArrayUtils.fromBase64(responseText).buffer;
      }
    }
  });

  await playerShaka.load(link_play);
}
