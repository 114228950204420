import React, { useState } from 'react';
import { SVG } from '~components';
import { IconButton, Tooltip, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import DialogChangeNickname from './DialogChangeNickname';

const TooltipStyled = withStyles(theme => ({
  tooltip: {
    width: '145px',
    height: '25px',
    textAlign: 'center',
    fontWeight: 'normal',
    backgroundColor: theme.palette.common.white,
    color: '#000000',
    fontFamily: 'Neue Haas Grotesk Display Pro',
    fontStyle: 'normal',
    fontSize: 14,
    letterSpacing: '0.025em',
    borderRadius: '2px',
  },
}))(Tooltip);

const ButtonChangeNickname = props => {
  const { nickname } = props;
  const [open, setOpen] = useState(false);
  const [newNickname, setNewNickname] = useState('');
  const [error, setError] = useState('');
  const handleOpenNicknameDialog = () => {
    setOpen(true);
    setNewNickname(nickname);
  };
  const handleCloseNicknameDialog = () => {
    setNewNickname('');
    setError('');
    setOpen(false);
  };
  return (
    <>
      <TooltipStyled title="Change nickname">
        <IconButton onClick={handleOpenNicknameDialog}>
          <SVG src={require('~img/icons/projectw/chat/edit-3.svg').default} />
        </IconButton>
      </TooltipStyled>
      <DialogChangeNickname
        openNicknameDialog={open}
        handleCloseNicknameDialog={handleCloseNicknameDialog}
        newNickname={newNickname}
        setNewNickname={setNewNickname}
        error={error}
        setError={setError}
      />
    </>
  );
};

const mapStateToProps = state => ({
  nickname: state.global.chatLive.nickname,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonChangeNickname);
