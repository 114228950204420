import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import profilePageId from '../../../constants/ids/profilePageId';
import { getConfigByKey } from '../../tenantConfig/services';
import { setTermsAndConditions } from '~components/global/globalServices';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  projectw__ui: {
    '& > p': {
      wordBreak: 'break-word !important',
    },
  },
});

const termsOfUseLink = getConfigByKey('termsOfUseLink');

const TermOfUse = props => {
  const { modalShow, documents, showModal } = props;
  const classes = useStyles();

  useEffect(() => {
    const { dpSetTermsAndConditions } = props;
    if (termsOfUseLink || !modalShow) {
      return;
    }
    dpSetTermsAndConditions();
  }, [modalShow]);

  if (termsOfUseLink) {
    return null;
  }

  const projectw_theme = getConfigByKey('ui_theme') === 'projectw';

  return (
    <Modal
      show={modalShow}
      onHide={() => showModal()}
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zIndex: 10000 }}
    >
      <Modal.Header closeButton style={{ border: 'none' }} />
      <Modal.Body style={{ height: 700, overflow: 'auto' }}>
        <div
          style={{ padding: 50, paddingTop: 0 }}
          className={projectw_theme ? `${classes.projectw__ui}` : ''}
          dangerouslySetInnerHTML={{
            __html: documents.termsAndConditions.content || '',
          }}
        ></div>
      </Modal.Body>
    </Modal>
  );
};

TermOfUse.defaultProps = {
  showModal: () => {},
  modalShow: false,
};

const mapDispatchToProps = {
  dpSetTermsAndConditions: () => setTermsAndConditions(),
};

const mapStateToProps = state => ({
  documents: state.global.documents,
});

export default connect(mapStateToProps, mapDispatchToProps)(TermOfUse);
