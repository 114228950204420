import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { RecordContentSpotLight } from '..';
import { getBanner19_6 } from '~core/method/contentMethod';
import moment from 'moment';

const Wrapper = styled.div``;

function SpotlighComponent(props) {
  const { record = {}, contentView = null, contentViewById } = props;
  const refPlayer = useRef(null);
  const [hoverState, setHoverState] = useState(false);

  const onMouseEnter = (event, record) => {
    setHoverState(true);
    refPlayer.current && refPlayer.current.play();
  };

  const onMouseLeave = (event, record) => {
    setHoverState(false);
    if (!refPlayer.current) {
      return;
    }
    refPlayer.current.pause();
  };

  return (
    <Wrapper
      className="wrapper-hover spotlight"
      onMouseEnter={event => onMouseEnter(event, record)}
      onMouseLeave={event => onMouseLeave(event, record)}
    >
      <RecordContentSpotLight
        hoverState={hoverState}
        contentViewById={contentViewById}
        contentView={contentView}
        {...props}
      />
    </Wrapper>
  );
}

export default SpotlighComponent;
