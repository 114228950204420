export const MOVIE_TYPE = 1;
export const SHOW_TYPE = 2;
export const SEASON_TYPE = 3;
export const EPISODE_TYPE = 4;
export const CHANNEL_TYPE = 5;
export const CLIP = 6;
export const CURATED_LIVE = 7;

export const TVOD = 'tvod';
export const SVOD = 'svod';
export const FREE = 'free';

export const CATEGORIES = 'categories';
export const GENRES = 'genres';
export const SUB_CATEGORIES = 'sub-categories';
export const REGIONS = 'regions';
