/* tslint:disable */
/* eslint-disable */
/**
 * ODX/V3 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProgramMetaCcLanguagesEnum = exports.ProgramMetaProduceCountriesEnum = void 0;
exports.ProgramMetaProduceCountriesEnum = {
    KR: 'KR',
    CN: 'CN',
    US: 'US',
    CA: 'CA',
    TW: 'TW',
    HK: 'HK',
    JP: 'JP',
    MX: 'MX',
    GT: 'GT',
    SV: 'SV',
    CO: 'CO',
    PE: 'PE',
    EC: 'EC',
    HN: 'HN',
    CR: 'CR',
    NI: 'NI',
    PA: 'PA',
    BO: 'BO',
    VE: 'VE',
    CL: 'CL',
    AR: 'AR',
    PY: 'PY',
    UY: 'UY',
    BR: 'BR',
    CU: 'CU',
    DO: 'DO',
    PR: 'PR',
    unknown_default_open_api: '11184809'
};
exports.ProgramMetaCcLanguagesEnum = {
    ko: 'ko',
    en: 'en',
    es: 'es',
    zh_Hans: 'zh-Hans',
    zh_Hant: 'zh-Hant',
    zh_hans: 'zh-hans',
    zh_hant: 'zh-hant',
    pt: 'pt',
    unknown_default_open_api: '11184809'
};
