import { getConfigFromEnv } from '~constants/tenantConfig/tenantEnvConfig';
import { getTenantStrings } from '~constants/tenantConfig/tenantStrings';
import { getTenantFeatureFlags } from '~constants/tenantConfig/tenantFeatureFlags';
import { getIntegrationConfigs } from '~constants/tenantConfig/integrationConfigs';
import { getDeepProp } from '~utils/utils';
import { REACT_APP_SSR } from '~constants/envConfig';
import { isBrowser, isNode } from '~utils/ssr';
import i18n from 'i18next';

let currentLanguage = '';
const rootState = JSON.parse(window.localStorage.getItem('persist:root'));

if (rootState && rootState.root) {
  currentLanguage = JSON.parse(rootState.root).language;
}

export const storeSubscribe = store => {
  const state = store.getState();
  if (currentLanguage !== state.root.language) {
    currentLanguage = state.root.language;
  }
};

export const getConfigByKey = (name = '') => {
  const configs = {
    _ssr: REACT_APP_SSR,
    ...getTenantStrings(),
    features: {
      ...getTenantFeatureFlags(),
    },
    integrations: {
      ...getIntegrationConfigs(),
    },
    ...getConfigFromEnv(),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  if (isBrowser()) {
    configs.currentLanguage = i18n.language || currentLanguage;
  }

  if (!name) {
    return configs;
  }

  const value = getDeepProp(configs, name, configs.currentLanguage) || '';
  return value;
};
