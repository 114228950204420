export const MOVIE_TYPE = 1;
export const EPISODE_TYPE = 4;
export const CHANNEL_TYPE = 5;
export const SHOW_TYPE = 2;
export const SEASON_TYPE = 3;
export const CURATED_LIVE_CHANNEL_TYPE = 7;
export const CLIP_TYPE = 6;
export const TRAILER_TYPE = 8;
export const LIVE_EVENT = 9;
export const SIMULATED_LIVE_EVENT = 10;

export const PENDING_STATUS = 0;

export const CONTENT_TYPE = Object.freeze({
  MOVIE_TYPE,
  EPISODE_TYPE,
  CHANNEL_TYPE,
  SHOW_TYPE,
  SEASON_TYPE,
  CURATED_LIVE_CHANNEL_TYPE,
  CLIP_TYPE,
  TRAILER_TYPE,
  PENDING_STATUS,
  LIVE_EVENT,
  SIMULATED_LIVE_EVENT,
});
