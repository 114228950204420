import * as shareApi from '../../api/share';

const getDeepLink = params => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      shareApi
        .getDeepLink(params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
};

export { getDeepLink };
