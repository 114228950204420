import CoolDownLive from '../components/cooldownLive';
import ReactDOM from 'react-dom';
import React from 'react';
import moment from 'moment';
import * as types from './../constants';
import videojs from 'video.js';
import jquery from 'jquery';

const vjsComponent = videojs.getComponent('Component');
class Cooldown extends vjsComponent {
  constructor(player, options) {
    super(player, options);
    /* Bind the current class context to the mount method */
    this.mount = this.mount.bind(this);
    /* When player is ready, call method to mount React component */
    const { entityDetail } = options.component;
    const $player = jquery(player.player_.el_);
    this.init = true;
    player.on('ready', () => {
      this.handelDisplay(entityDetail);
    });
    if (this.init) {
      if (entityDetail.type === types.CURATED_LIVE) {
        player.on('loadedmetadata', () => {
          this.init = false;
          if (
            entityDetail.current_live_cursor &&
            player.duration() < entityDetail.current_live_cursor.current_video_position
          ) {
            if (!options.component.isRewatchCuratedLive) {
              $player.addClass('vjs-live vjs-liveui');
            }
          }
          player.on('ended', () => {
            this.handleCooldown(true);
          });
        });
      }
    }
    /* Remove React root when component is destroyed */
    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }

  createEl() {
    const div = videojs.dom.createEl('div', {
      className: 'vjs-cooldown-display',
      id: 'vjs-cooldown-display',
    });
    return div;
  }
  handelDisplay(entityDetail) {
    if (entityDetail.type === types.CURATED_LIVE) {
      this.mount();
      this.handleCooldown(false);
    } else {
      return null;
    }
  }
  handleCooldown(isEnd = false) {
    const { isRender, component } = this.options_;
    const { entityDetail } = component;
    const { current_live_cursor, next_content } = entityDetail;
    const $player = jquery(this.player_.el_);
    let endTime;
    let nextTime = '';
    let nowTime = moment().unix();
    $player.addClass('vjs-curated-live');
    if ($player.hasClass('vjs-rewatching')) {
      return;
    }
    if (!current_live_cursor && !next_content && !isRender) {
      $player.addClass('vjs-live vjs-liveui vjs-none-linkplay');
      return;
    }
    if (next_content) {
      nextTime = next_content.start_at;
      if (nowTime < nextTime && !current_live_cursor) {
        $player.addClass('vjs-live vjs-liveui vjs-none-linkplay');
        return $player.find('.vjs-cooldown-display').addClass('vjs-cooldown-open');
      }
    }
  }

  /**
   * We will render out the React EpisodeList component into the DOM element
   * generated automatically by the VideoJS createEl() method.
   *
   * We fetch that generated element using `this.el()`, a method provided by the
   * vjsComponent class that this class is extending.
   */
  mount() {
    const { component } = this.options_;
    ReactDOM.render(<CoolDownLive component={component} player={this.player_} />, this.el());
  }
}

/**
 * Make sure to register the vjsComponent so Video JS knows it exists
 */
vjsComponent.registerComponent('CoolDown', Cooldown);

export default Cooldown;
