import { IAxiosResponseData } from '~core/models';
import { apiAdapter, config, axiosTakeDataFromResponse } from '~share';

const { contentService } = apiAdapter;

const getDeepLink = (params: {
  content_id: string;
}): Promise<IAxiosResponseData<typeof contentService.getContentDeepLink>> => {
  const { content_id } = params;
  const { tenantSlug = '' } = config;
  return contentService.getContentDeepLink(content_id, tenantSlug).then(axiosTakeDataFromResponse);
};

export { getDeepLink };
