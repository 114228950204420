import React, { useState } from 'react';
import { connect } from 'react-redux';
import { StyledButton } from './styled';
import CropFreeIcon from '@material-ui/icons/CropFree';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import { setVolume } from '../services';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Actions(props) {
  const { volume, currentLive, language } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const [open, setOpen] = useState(volume === 1 || false);
  const handleClickVolume = () => {
    props.dispatchSetVolume(!open ? 1 : 0);
    setOpen(!open);
  };

  const handleClickWatch = () => {
    if (!currentLive) return;
    history.push(`/${language}/detail/${currentLive.channelId}`, currentLive);
  };
  return (
    <StyledButton>
      <div className="btn--volume" onClick={() => handleClickVolume()}>
        {open ? <VolumeUpIcon /> : <VolumeOffIcon />}
      </div>
      <div className="btn--watch" onClick={() => handleClickWatch()}>
        <CropFreeIcon />
        {t('ribbon.slide.watch')}
      </div>
    </StyledButton>
  );
}

const mapStateToProps = state => ({
  schedule: state.liveSchedule.schedule,
  currentLive: state.liveSchedule.currentLive,
  volume: state.liveSchedule.volume,
  language: state.root.language,
});

const mapDispatchToProps = {
  dispatchSetVolume: value => setVolume(value),
};
export default connect(mapStateToProps, mapDispatchToProps)(Actions);
