import React from 'react';
import VideoJs from './videojs';

const FullMode = React.memo(props => {
  const { component, isBlockAds } = props;
  return (
    <VideoJs
      component={component.props}
      state={component.state}
      ref={component.playerRef}
      isBlockAds={isBlockAds}
    />
  );
});

export default FullMode;
