export const splitBreakLine = (str = '') => {
  if (!str) {
    return [];
  }
  const strSplit = str.split(/(\n)+/);
  return strSplit;
};

export function truncateText($textDom: any, text: string) {
  if (!text) {
    return '';
  }
  const textSplit = (text || '').split(' ');
  const truncateEllipsis = '...';

  let end = 0;
  let right = textSplit.length;
  while (end <= right) {
    $textDom.text(textSplit.slice(0, end).join(' '));

    if (end === right && $textDom.prop('scrollHeight') === Math.ceil($textDom.height())) {
      break;
    }
    $textDom.text(textSplit.slice(0, end).join(' ') + truncateEllipsis);
    if (end > 0 && $textDom.prop('scrollHeight') > Math.ceil($textDom.height())) {
      $textDom.text(textSplit.slice(0, end - 1).join(' ') + truncateEllipsis);
      break;
    }
    end++;
  }
}
