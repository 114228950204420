import React from 'react';
import PropTypes from 'prop-types';
import * as displayType from '../../constants';
import homePageId from '../../../../constants/ids/homePageId';
import UpgradeButton from './upgrade-button';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

class WatchButton extends React.Component {
  render() {
    const { t, item, index, account, payperview } = this.props;
    if (item.type === displayType.CHANNEL_TYPE || item.type === displayType.CURATED_LIVE) {
      return (
        <StyledButton
          data-testid={`${homePageId.bannerId.contentPlayBtn}-${index}`}
          className="v-slide--btn"
        >
          <i className="fa fa-play" aria-hidden="true" />{' '}
          <span>{t('ribbon.slide.txtWatchLive')}</span>
        </StyledButton>
      );
    }
    if (item.num_first_episode_preview > 0 || !item.is_premium || payperview) {
      return (
        <StyledButton
          data-testid={`${homePageId.bannerId.contentPlayBtn}-${index}`}
          className="v-slide--btn"
        >
          <span>
            <i className="fa fa-play" aria-hidden="true" /> {t('ribbon.slide.watch')}
          </span>
        </StyledButton>
      );
    }
    return <UpgradeButton account={account} item={item} index={index} {...this.props} />;
  }
}

const StyledButton = styled.button`
  &.v-slide {
    &--btn {
      background-color: ${({ theme }) => theme.primaryColor};
      color: #fff;
      font-size: 1rem;
      text-transform: capitalize;
      padding: 9px 13px;
      margin: 1rem 0;
      border-radius: 8px;
      border: none !important;
      outline: none !important;
      i {
        padding-right: 0.25em;
      }
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    &.v-slide {
      &--btn {
        i {
          padding-right: 0.5em;
        }
      }
    }
  }
  @media (min-width: 1280px) {
  }
  @media (min-width: 1440px) {
  }
  @media (min-width: 1680px) {
  }
`;

WatchButton.propTypes = {
  item: PropTypes.any,
  account: PropTypes.any,
  index: PropTypes.number,
};

export default withTranslation()(WatchButton);
