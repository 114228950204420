import React, { Component } from 'react';
import { getConfigByKey } from '../../tenantConfig/services';

export const Blackout = props => {
  const sitename = getConfigByKey('site_name');
  const contents = {
    en: {
      title: 'Announcement',
      txt1: 'The current broadcasting content is being block due to sensitive or political contents.',
      txt2: `${sitename} is fully behind our audiences while trying to balance between blocking or cutting off partial of the Drama or show due to the nature of live channel broadcast time vs the scheduled broadcast contents.`,
      txt3: 'Please be aware that some of these sensitive contents might be leaked as it not our intention to display offensive contents due to live broadcast from Vietnam broadcasting center.',
      txt4: `${sitename} team sincerely thank you for your understanding and being our value customers.`,
    },
    vi: {
      title: 'Thông báo',
      txt1: 'Nội dung phát sóng hiện tại đang bị chặn vì có thể chứa nội dung nhạy cảm hoặc chính trị.',
      txt2: `${sitename} luôn đứng sau lưng quyền tự do của quý vị. Chúng tôi luôn cố gắng cân bằng giữa việc ngăn chặn nội dung chính trị hoặc cắt một phần của Phim truyền hình, chương trình của quý khán giả. Tuy nhiên nếu có sai sót xin quý vị thông cảm do tính chất phát sóng kênh trực tiếp có thể khác so với nội dung phát sóng theo lịch trình.`,
      txt3: 'Xin lưu ý rằng một số nội dung nhạy cảm này có thể bị rò rỉ, không phải vì chúng tôi muốn phát sóng những nội dung gây mất lòng, nhưng vì những chương trình này được phát trực tiếp từ đài truyền hình Việt Nam, cho nên sẽ có những thiếu sót ngoài ý muốn.',
      txt4: `${sitename} Team chân thành cảm ơn quý khách đã hiểu, thông cảm cho và là khách hàng quý của chúng tôi.`,
    },
  };

  const sctvContents = {
    en: {
      title: 'Announcement',
      txt1: 'Chương trình đang tạm thời bị gián đoạn vì lý do bản quyền.',
      txt2: 'Mong Quý vị khán giả thông cảm và quay lại sau.',
    },
    vi: {
      title: 'Thông báo',
      txt1: 'Chương trình đang tạm thời bị gián đoạn vì lý do bản quyền.',
      txt2: 'Mong Quý vị khán giả thông cảm và quay lại sau.',
    },
  };

  const isSctvTenant = getConfigByKey('tenant_slug') === 'sctv';
  const textContents = !isSctvTenant ? contents : sctvContents;
  return (
    <div className="vjs-black-out-announcement">
      <div className="vjs-black-out-announcement--logo-wrap">
        <img
          className="vjs-black-out-announcement--logo"
          src={`${getConfigByKey('nav_bar_logo')}`}
        />
      </div>
      {Object.keys(textContents).map((lng, index) => {
        return (
          <React.Fragment key={index}>
            <div className="vjs-black-out-announcement--item">
              <h3 className="vjs-black-out-announcement--title">{textContents[lng].title}</h3>
              {[1, 2, 3, 4].map((value, indexs) => {
                return (
                  <p className="vjs-black-out-announcement--txt" key={indexs}>
                    {textContents[lng]['txt' + value]}
                  </p>
                );
              })}
            </div>
            <hr />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Blackout;
