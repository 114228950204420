import React, { useState } from 'react';
import { TooltipWrap } from '../actionButton';
import { withTranslation } from 'react-i18next';
import PopupShare from '../../../../../features/share/components/popup';

function Share(props) {
  const { t, item, target } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TooltipWrap title={t('share.txtShare')} arrow placement="top">
        <div className="button" onClick={() => setOpen(true)}>
          <div className="button--wrap">
            <i className="fa fa-share" aria-hidden="true" />
          </div>
        </div>
      </TooltipWrap>
      <PopupShare
        entityDetail={item}
        open={open}
        setOpen={value => setOpen(value)}
        target={target || 'landing'}
        pageVod
        entityTrailer={null}
      />
    </>
  );
}

export default withTranslation()(Share);
