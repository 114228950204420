import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getActiveComponent, getStepPayperview } from '../functions';
import queryString from 'query-string';
import PopupUpgrade from '~features/landingpage/components/popupUpgrade';
import { getConfigByKey } from '~features/tenantConfig/services';
import { TENANT_NAME_ENUM } from '~core/constants';

const Upgrade = props => {
  const { open, location } = props;
  const [step, setStep] = useState(0);
  const [componentActive, setComponent] = useState(null);

  let paramQuery = queryString.parse(location.search, { decode: false });
  let state = location.state;
  useEffect(() => {
    if ((state && state.payperview) || step === 3) {
      return getStepPayperview(3, props, setStep);
    }
    if (paramQuery && paramQuery.token) {
      return getStepPayperview(2, props, setStep);
    }
    if (open) {
      getStepPayperview(1, props, setStep);
      setComponent(getActiveComponent(props, setStep));
    }
  }, [open]);
  useEffect(() => {
    setComponent(getActiveComponent(props, setStep));
  }, [step, state && state.payperview]);
  if (getConfigByKey('ui_theme') === TENANT_NAME_ENUM.PROJECTW) {
    return null;
  }
  if (open && getConfigByKey('tenant_slug') === 'sctv') {
    return <PopupUpgrade {...props} />;
  }
  if (open) return componentActive;
  return null;
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Upgrade)));
