import React from 'react';
import RecordContentDesktop from './RecordContentDesktop';
import RecordContentMobile from './RecordContentMobile';

function RecordContent(props) {
  const { isMobileDetect } = props;
  if (!isMobileDetect) {
    return <RecordContentDesktop {...props} />;
  }
  return <RecordContentMobile {...props} />;
}

export default RecordContent;
