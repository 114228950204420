import { createSlice } from '@reduxjs/toolkit';
import { getConfigByKey } from '~features/tenantConfig/services';

const should_display_geoblocked_contents_in_listing = getConfigByKey(
  'should_display_geoblocked_contents_in_listing',
);

const blockCountrySlice = createSlice({
  name: 'blockCountry',
  initialState: {
    loaded: should_display_geoblocked_contents_in_listing ? true : false,
    loading: should_display_geoblocked_contents_in_listing ? false : true,
    isBlock: false,
    duration: 60 * 5,
    expire: 0,
  },
  reducers: {
    setLoaidng(state, action) {
      const value = action.payload;
      state.loading = value;
    },
    setLoaded(state, action) {
      state.loaded = action.payload;
    },
    setIsBlock(state, action) {
      state.isBlock = action.payload;
    },
    setNewState(state, action) {
      state = { ...state, ...action.payload };
      return state;
    },
  },
});

export const { setLoaidng, setLoaded, setIsBlock, setNewState } = blockCountrySlice.actions;

export default blockCountrySlice.reducer;
