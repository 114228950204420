import * as types from './../constants';
import { getConfigByKey } from '../../tenantConfig/services';
import videojs from 'video.js';
import jquery from 'jquery';

var div = videojs.getComponent('Component');
var NotifyPolicy = videojs.extend(div, {
  constructor: function (player, options) {
    this.timer = 20;
    this.timerDisclaimer = 6;
    this.interval = null;
    player.one('loadeddata', () => {
      this.handleAutoHide();
    });
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.timerOut) {
      clearInterval(this.timerOut);
      this.timerOut = null;
    }
    div.apply(this, arguments);
  },
  createEl: function () {
    const div = videojs.dom.createEl('div', {
      className: 'vjs-top-policy',
    });
    const t = this.options_.t;

    div.innerHTML = `
      <div class='vjs-policy--container'>
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <div>
          <p class='vjs-warning'>${t('video.messagePopup.txtWarning')} </p>
          <p> ${t('video.messagePopup.txtPolicy', {
            tenantSlug: getConfigByKey('tenant_slug', false).toUpperCase(),
          })}</p>
        </div>
      </div>
    `;
    return div;
  },

  handlePolicy: function () {
    if (!this.player_) {
      return;
    }
    const $player = jquery(this.player_.el_);
    let timer = this.timer * 1000;
    let isShow = false;
    let timeCheckAds = 1000;
    const { dispatchUpdateDisclaimer, detailPage } = this.options_.component.props;
    let count = detailPage.playerDisclaimer + 1;
    if (count > 3) {
      timer = this.timerDisclaimer * 1000;
    }
    this.interval = setInterval(() => {
      if (isShow) {
        clearInterval(this.interval);
        return;
      }
      if (
        $player.hasClass('vjs-ad-playing') ||
        !$player.find('.vjs-top-policy').hasClass('vjs-ads')
      ) {
        return;
      }
      $player.find('.vjs-top-policy').addClass('vjs-show');
      $player.addClass('vjs-policy-open');
      isShow = true;
      this.timerOut = setInterval(() => {
        timeCheckAds += 1000;
        if (
          $player.hasClass('vjs-ad-playing') ||
          !$player.find('.vjs-top-policy').hasClass('vjs-ads')
        ) {
          $player.find('.vjs-top-policy').removeClass('vjs-show');
          $player.removeClass('vjs-policy-open');
          this.handlePolicy();
          clearInterval(this.timerOut);
          return;
        }
        if (timeCheckAds === timer) {
          clearInterval(this.timerOut);
          dispatchUpdateDisclaimer(count).then(res => {
            $player.find('.vjs-top-policy').removeClass('vjs-show');
            $player.removeClass('vjs-policy-open');
          });
        }
      }, 1000);
    }, 3000);
  },

  handleAutoHide: function () {
    const { entityDetail } = this.options_.component;
    let isLive = false;
    const type = this.options_.component.state.entityDetail.type;
    if (type === types.CURATED_LIVE || type === types.CHANNEL_TYPE) {
      isLive = true;
    }
    if (this.timerOut || !isLive) {
      return;
    }
    if (entityDetail.is_watchable) {
      this.handlePolicy();
    }
  },
});

videojs.registerComponent('NotifyPolicy', NotifyPolicy);
