// Q.net - DRMToday HTML5 sample
import * as shaka from 'shaka-player';
import axios from 'axios';
import queryString from 'query-string';
import { store } from '~root/store';
import moment from 'moment';
import jquery from 'jquery';
import * as actions from '../actions';
import { _handelLiveUi } from './initCuratedLive';
import { CURATED_LIVE, CHANNEL_TYPE } from '../constants';

let video;
let player;
let vjsPlayer;
let detail = {};
let globalToken = '';
let isClose = false;
let timer = null;
let manifestUrl = '';
let language = 'en';
let modal = null;
let method = null;
let isFreshToken = false;

const APIPROD = 'https://api.qnet.com.vn';
const APITEST = 'https://sandbox.qnet.com.vn';

const api =
  process.env.REACT_APP_ENV === 'PROD' || process.env.REACT_APP_ENV === 'RC' ? APIPROD : APITEST;

const contents = {
  en: {
    txt400: 'Sorry, this content has been expired. Please try watching other contents!',
    txt401:
      '	Sorry, there is some problem with the authentication of the encrypted stream. Please try again!',
    txt405: 'Sorry, your number of concurrent streams exceeds the limit. Please try again!',
  },
  vi: {
    txt400: 'Xin lỗi, nội dung này đã hết hạn. Vui lòng tham khảo & xem các nội dung khác!',
    txt401:
      '	Xin lỗi, có lỗi xảy ra trong quá trình xác thực của nội dung mã hóa. Vui lòng thử lại!',
    txt405:
      'Xin lỗi, số lượng lượt xem đồng thời của tài khoản này đã vượt quá giới hạn cho phép. Vui lòng đóng ứng dụng trên thiết bị khác và thử lại!',
  },
};

function setToken(params) {
  globalToken = params;
  const data = {
    token: params,
    content_id: detail.id,
    slug: detail.slug,
    baseUrl: window.location.href,
    time: moment().unix(),
    remove: false,
  };
  method &&
    method.setState({
      tokenDrm: params,
    });
  store.dispatch(actions.setTokenDrm(data));
}

export function setClosePlayer(value, message, player = vjsPlayer) {
  isClose = value;
  modal = player.createModal(message);
  modal.addClass('vjs-drm-error');
  clearInterval(timer);
}

async function pingOrRefreshToken(value = null) {
  const { session, operator_id, session_id } = detail.drm_session_info;
  let params = {
    sessionId: session_id,
    operatorId: operator_id,
    session,
  };
  if (globalToken && (!value || isFreshToken)) {
    params = { token: globalToken };
  }

  const queryParams = queryString.stringify(params);
  let linkApi = `${api}/csl/ping?${queryParams}`;
  if (value === 'refresh') {
    isFreshToken = false;
    linkApi = `${api}/csl/refresh?${queryParams}`;
  }

  try {
    const tokenData = await axios.get(linkApi, { validateStatus: () => true });
    const { data, status } = tokenData;
    data.token && setToken(data.token);
    if (status)
      switch (status) {
      case 401: {
        setClosePlayer(true, contents[language].txt401);
        break;
      }
      case 405: {
        setClosePlayer(true, contents[language].txt405);
        break;
      }
      case 400: {
        setClosePlayer(true, contents[language].txt400);
        break;
      }
      case 426: {
        pingOrRefreshToken('refresh');
        break;
      }
      default:
        modal && modal.close();
        break;
      }
    return data && data.token;
  } catch (error) {
    console.error(error, 'error');
  }
  return globalToken;
}

export async function endDrmToday(token = globalToken) {
  const params = {
    token,
  };
  if (!token) return;
  clearInterval(timer);
  const data = {
    token: null,
    content_id: detail.id,
    slug: detail.slug,
    baseUrl: window.location.href,
    time: moment().unix(),
    remove: true,
  };
  if (token) store.dispatch(actions.setTokenDrm(data));
  const queryParams = queryString.stringify(params);
  const linkApi = `${api}/csl/end?${queryParams}`;
  const tokenData = await axios.get(linkApi, { validateStatus: () => true });
  const { status } = tokenData;
  if (status === '426' || status === 426) {
    setToken(token);
    const newTokenData = await pingOrRefreshToken('refresh');
    await endDrmToday((newTokenData && newTokenData) || null);
  }
}

async function getWidevineCert() {
  const { widevide_license_path, user_id, session, operator_id } = detail.drm_session_info;
  const message = new Uint8Array(2);
  // in order our drm server to redirect this to google we need to send empty 2 bytes with values 8 and 4
  message[0] = 8;
  message[1] = 4;

  const request = new XMLHttpRequest();
  request.open('POST', widevide_license_path);
  request.responseType = 'arraybuffer';

  let params = {
    userId: user_id,
    sessionId: session,
    merchant: 'qnet',
  };

  params = btoa(JSON.stringify(params));

  request.setRequestHeader('dt-custom-data', params);

  await request.addEventListener('error', ev => {
    console.log('error', ev);
  });

  await request.addEventListener('load', ev => {
    console.log('data', ev);
  });

  request.send(message);
  return message;
}

function setWidevineDataResponse(response) {
  const wrappedArray = new Uint8Array(response.data);

  // Convert it to a string.
  const wrappedString = String.fromCharCode.apply(null, wrappedArray);

  // Parse the JSON string into an object.
  let wrapped;
  try {
    wrapped = JSON.parse(wrappedString);
  } catch (err) {
    throw new Error(`Error while parsing JSON: ${err}`);
  }

  // This is a base64-encoded version of the raw license.
  const rawLicenseBase64 = wrapped.license;

  // Decode it to a string.
  const rawLicenseString = atob(rawLicenseBase64);

  // Convert that string into a Uint8Array and replace the response data to
  // feed it to the Widevine CDM.
  response.data = new Uint8Array(rawLicenseString.length);
  for (let i = 0; i < rawLicenseString.length; ++i) {
    response.data[i] = rawLicenseString.charCodeAt(i);
  }
}

async function setupDRMToday() {
  const {
    widevide_license_path,
    user_id,
    session,
    operator_id,
    playready_license_path,
    merchant = 'qnet',
  } = detail.drm_session_info;
  try {
    // timer = setInterval(async () => {
    const token = await pingOrRefreshToken();
    if (token || globalToken) {
      // clearInterval(timer)
      await getWidevineCert();
      playStream();
      timer = setInterval(async () => {
        await pingOrRefreshToken();
      }, 60000);
    }
    // }, 5000)
  } catch {
    console.log('err');
    return;
  }

  player.configure({
    drm: {
      servers: {
        'com.widevine.alpha': widevide_license_path,
        'com.microsoft.playready': playready_license_path,
      },
      advanced: {
        'com.widevine.alpha': {
          videoRobustness: 'SW_SECURE_DECODE',
          audioRobustness: 'SW_SECURE_CRYPTO',
        },
      },
    },
  });
  const net = player.getNetworkingEngine();

  const requestTypes = shaka.net.NetworkingEngine.RequestType;
  // Setting up the License Request

  net.registerRequestFilter((type, request) => {
    if (type === requestTypes.LICENSE) {
      let drmTodayData = {
        userId: user_id,
        sessionId: session,
        merchant,
      };
      drmTodayData = btoa(JSON.stringify(drmTodayData));
      request.headers['dt-custom-data'] = drmTodayData;
    }
  });

  // Setting up the license response
  net.registerResponseFilter((type, response) => {
    if (type === requestTypes.LICENSE) {
      const keySystem = player.keySystem();
      if (keySystem === 'com.widevine.alpha' || keySystem === 'com.microsoft.playready') {
        setWidevineDataResponse(response);
      }

      // For Playready the data returned by DRMToday doesn't need handling and
      // can be sent directly to the CDM.
    }
  });
}

function playStream() {
  player
    .unload()
    .then(() => player.load(manifestUrl))
    .then(() => {
      console.log('Playing stream');
      if ((detail.type === CURATED_LIVE || detail.type === CHANNEL_TYPE) && method)
        _handelLiveUi(method);
    })
    .catch(onError);
}

function initDrm(players, component) {
  // Install built-in polyfills to patch browser incompatibilities.
  shaka.polyfill.installAll();
  vjsPlayer = players;
  language = component.props.root.language;
  detail = component.entityDetail;
  method = component;
  if (!detail.drm_session_info) return;
  const drmContent = component.props.detailPage.player.drm || [];
  drmContent.map(items => {
    const oldToken = items.token;
    const timerexp = moment().unix() - items.time;
    if (oldToken && timerexp >= 120) {
      endDrmToday(oldToken);
    } else if (items.slug === detail.slug) {
      isFreshToken = true;
      setToken(oldToken);
    }
  });
  manifestUrl = component.entityDetail.link_play;
  // Check to see if the browser supports the basic APIs Shaka needs.
  if (shaka.Player.isBrowserSupported()) {
    // Everything looks good!
    initPlayer(component);
    // loadAppEvents();
  } else {
    // This browser does not have the minimum set of APIs we need.
    console.error('Browser not supported!');
  }
}

function initPlayer(component) {
  const _src = vjsPlayer.src;
  vjsPlayer.src = function (value) {
    if (value !== undefined) {
      _src.call(vjsPlayer, value);
    } else {
      return manifestUrl;
    }
  };
  if (detail.type === CURATED_LIVE || detail.type === CHANNEL_TYPE) _handelLiveUi(component);
  video = vjsPlayer.el_.querySelector('video');
  player = new shaka.Player(video);
  component.playerShaka = player;
  setupDRMToday();
  vjsPlayer.on('ended', () => {
    endDrmToday();
  });
  vjsPlayer.on('loadeddata', () => {
    if (detail.type === CURATED_LIVE || detail.type === CHANNEL_TYPE) {
      _handelLiveUi(component);
      const $player = jquery(component.videoPlayer.player_.el_);
      const uilive = setInterval(() => {
        $player.addClass('vjs-liveui');
        if ($player.hasClass('vjs-liveui')) {
          clearInterval(uilive);
        }
      }, 500);
    }
  });
  player.addEventListener('error', onErrorEvent);
}

function onErrorEvent(event) {
  // Extract the shaka.util.Error object from the event.
  onError(event.detail);
}

function onError(error) {
  // Log the error.
  console.error('Error code', error.code, 'object', error);
}

export default initDrm;
