import { getConfigByKey } from '~features/tenantConfig/services';
import { TENANT_NAME_ENUM } from '~core/constants';
import { pushAnalytics } from './Analytics';

export const W_FIREBASE_EVENTS = {
  sign_up_started: 'sign_up_started',
  sign_in_started: 'sign_in_started',
  sign_up_processing: 'sign_up_processing',
  sign_in_processing: 'sign_in_processing',
  sign_up_email_verifying: 'sign_up_email_verifying',
  sign_up_completed: 'sign_up_completed',
  sign_in_completed: 'sign_in_completed',
  ppv_started: 'ppv_started',
  ppv_payment_started: 'ppv_payment_started',
  ppv_payment_processing: 'ppv_payment_processing',
  ppv_payment_completed: 'ppv_payment_completed',
  ppv_illuon_credit_payment_completed: 'ppv_illuon_credit_payment_completed',
  ppv_package_selection_changed: 'ppv_package_selection_changed',
  ppv_payment_method_selected: 'ppv_payment_method_selected',
  ppv_payment_agreement_accepted: 'ppv_payment_agreement_accepted',
};

export const wFirebaseEvents = {
  signUpStartedEvent: () => {
    pushAnalyticsW(W_FIREBASE_EVENTS.sign_up_started, {});
  },
  signInStartedEvent: () => {
    pushAnalyticsW(W_FIREBASE_EVENTS.sign_in_started, {});
  },
  signUpProcessingEvent: (params: { [key: string]: any }) => {
    const { email } = params;
    const payload = { email };
    pushAnalyticsW(W_FIREBASE_EVENTS.sign_up_processing, payload);
  },
  signInProcessingEvent: (params: { [key: string]: any }) => {
    const { email } = params;
    const payload = { email };
    pushAnalyticsW(W_FIREBASE_EVENTS.sign_in_processing, payload);
  },
  signUpEmailVerifyingEvent: (params: { [key: string]: any }) => {
    const { email } = params;
    const payload = { email };
    pushAnalyticsW(W_FIREBASE_EVENTS.sign_up_email_verifying, payload);
  },
  signUpCompletedEvent: (params: { [key: string]: any }) => {
    const { email } = params;
    const payload = { email };
    pushAnalyticsW(W_FIREBASE_EVENTS.sign_up_completed, payload);
  },
  signInCompletedEvent: (params: { [key: string]: any }) => {
    const { email } = params;
    const payload = { email };
    pushAnalyticsW(W_FIREBASE_EVENTS.sign_in_completed, payload);
  },
  ppvStartedEvent: (params: { [key: string]: any }) => {
    const { content_id } = params;
    const payload = {
      content_id,
    };
    pushAnalyticsW(W_FIREBASE_EVENTS.ppv_started, payload);
  },
  ppvPaymentStartedEvent: (params: { [key: string]: any }) => {
    const {
      content_id,
      email,
      purchase_amount,
      currency_unit,
      purchase_item_name,
      payment_method,
    } = params;
    const payload = {
      content_id,
      email,
      purchase_amount,
      currency_unit,
      purchase_item_name,
      payment_method,
    };
    pushAnalyticsW(W_FIREBASE_EVENTS.ppv_payment_started, payload);
  },
  ppvPaymentProcessingEvent: (params: { [key: string]: any }) => {
    const {
      content_id,
      email,
      purchase_amount,
      currency_unit,
      purchase_item_name,
      payment_method,
    } = params;
    const payload = {
      content_id,
      email,
      purchase_amount,
      currency_unit,
      purchase_item_name,
      payment_method,
    };
    pushAnalyticsW(W_FIREBASE_EVENTS.ppv_payment_processing, payload);
  },
  ppvPaymentCompletedEvent: (params: { [key: string]: any }) => {
    const {
      content_id,
      email,
      purchase_amount,
      currency_unit,
      purchase_item_name,
      payment_method,
    } = params;
    const payload = {
      content_id,
      email,
      purchase_amount,
      currency_unit,
      purchase_item_name,
      payment_method,
    };
    pushAnalyticsW(W_FIREBASE_EVENTS.ppv_payment_completed, payload);
  },
  ppvIlluonCreditPaymentCompletedEvent: (params: { [key: string]: any }) => {
    const { content_id, email } = params;
    const payload = {
      content_id,
      email,
    };
    pushAnalyticsW(W_FIREBASE_EVENTS.ppv_illuon_credit_payment_completed, payload);
  },
  ppvPackageSelectionChanged: (params: { [key: string]: any }) => {
    const { content_id, email, purchase_amount, currency_unit, purchase_item_name } = params;
    const payload = {
      content_id,
      email,
      purchase_amount,
      currency_unit,
      purchase_item_name,
    };
    pushAnalyticsW(W_FIREBASE_EVENTS.ppv_package_selection_changed, payload);
  },
  ppvPaymentMethodSelected: (params: { [key: string]: any }) => {
    const {
      content_id,
      email,
      purchase_amount,
      currency_unit,
      purchase_item_name,
      payment_method,
    } = params;
    const payload = {
      content_id,
      email,
      purchase_amount,
      currency_unit,
      purchase_item_name,
      payment_method,
    };
    pushAnalyticsW(W_FIREBASE_EVENTS.ppv_payment_method_selected, payload);
  },
  ppvPaymentAgreementAccepted: (params: { [key: string]: any }) => {
    const {
      content_id,
      email,
      purchase_amount,
      currency_unit,
      purchase_item_name,
      payment_method,
    } = params;
    const payload = {
      content_id,
      email,
      purchase_amount,
      currency_unit,
      purchase_item_name,
      payment_method,
    };
    pushAnalyticsW(W_FIREBASE_EVENTS.ppv_payment_agreement_accepted, payload);
  },
};

export const pushAnalyticsW = (event: any, params: { [key: string]: any }) => {
  if (TENANT_NAME_ENUM.PROJECTW === getConfigByKey('ui_theme') && !(window as any).fbq) {
    return;
  }
  try {
    pushAnalytics(event, params, {
      toSlug: false,
      itemName: false,
      platform: false,
    });
  } catch (error) {}
};
