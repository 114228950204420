// Get the Component base class from Video.js
import videojs from 'video.js';
import { pushEventFirebase } from '../components/pushEventFirebase';
import { CHANNEL_TYPE, CURATED_LIVE } from '../constants';
import { tenantEnvConfig } from '~constants/tenantConfig/tenantEnvConfig';

const Button = videojs.getComponent('Button');

// The videojs.extend function is used to assist with inheritance. In
// an ES6 environment, `class SeekNext extends Component` would work
// identically.

const TopNavigation = videojs.extend(Button, {
  // The constructor of a component receives two arguments: the
  // player it will be associated with and an object of options.
  constructor(player, options) {
    // It is important to invoke the superclass before anything else,
    // to get all the features of components out of the box!
    Button.apply(this, arguments);
    // If a `text` option was passed in, update the text content of
    // the component.
  },

  // The `createEl` function of a component creates its DOM element.
  createEl() {
    const btn = videojs.dom.createEl('button', {
      className: 'vjs-top-navigation',
    });
    const uiComponent = `
    <i class="fa fa-arrow-left icon" aria-hidden="true"></i> 
    <div class="vjs-logo">
      <img src=${require('~img/icons/odv/logoPlayer.png').default} width="50" height="50" />
    </div>
    `;
    btn.innerHTML = '<i class="fa fa-arrow-left icon" aria-hidden="true"></i> ';
    if (tenantEnvConfig.ui_theme === 'odv') {
      btn.innerHTML = uiComponent;
    }
    return btn;
  },

  handleClick(event) {
    const { entityDetail, backUrl } = this.options_.component;
    const { match = {}, history, language } = this.options_.component.props || {};
    const { location } = history;
    pushEventFirebase(entityDetail, 'Back');

    if (
      match.path.indexOf('/landing/') >= 0 ||
      (location.state && location.state.navigation === 'home') ||
      entityDetail.type === CHANNEL_TYPE ||
      entityDetail.type === CURATED_LIVE
    ) {
      return history.goBack();
    }
    if (backUrl || window.history.length === 2) {
      return history.replace(`/${language}/landing/${backUrl}`);
    }
    return history.goBack();
  },
});

// Register the component with Video.js, so it can be used in players.
videojs.registerComponent('TopNavigation', TopNavigation);
