import { PayloadAction } from '@reduxjs/toolkit';
import * as types from './actionTypes';

export interface IToast {
  message: string;
  isShow: boolean;
  timeOut: number;
}
const initialState: IToast = {
  message: '',
  isShow: false,
  timeOut: -1,
};

export default function toast(state = initialState, action: PayloadAction<Partial<IToast>>) {
  switch (action.type) {
    case types.UPDATE_TOAST:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
