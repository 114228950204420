/* tslint:disable */
/* eslint-disable */
/**
 * Vimai Core Service UI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientEventsApi = exports.ClientEventsApiFactory = exports.ClientEventsApiFp = exports.ClientEventsApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * ClientEventsApi - axios parameter creator
 * @export
 */
var ClientEventsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Post save player event
         * @param {string} tenantId
         * @param {SavePlayerEventRequestBody} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePlayerEvent: function (tenantId, data, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('savePlayerEvent', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('savePlayerEvent', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/player_event/"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Send player event
         * @param {string} tenantId
         * @param {string} [t] type: event type
         * @param {string} [dt] dt_client: The timestamp for the point of sending the request.
         * @param {string} [u] user_id: Don’t include for guest user
         * @param {string} [cid] client_id: Web use clientId, App use app device id
         * @param {string} [m] membership: Membership eg ODK-PREMIUM
         * @param {string} [s] service_name: tenant slug
         * @param {string} [p] platform: platform name, eg: ios
         * @param {string} [os] os: operation system name
         * @param {string} [ep] episode: episode slug
         * @param {string} [st] screen_type: normal or fullscreen
         * @param {string} [fr] frame_rate: Encoding profile, eg: 3128601
         * @param {string} [fs] frame_size: Resolution of the device, eg: 1280x720
         * @param {string} [sl] subtitle_language: Language code used for subtitle. eg: en
         * @param {string} [d] device: Device type for the client, eg: desktop
         * @param {boolean} [r] is_recommend: Send only watching from recommend.
         * @param {boolean} [ad] is_adblock: Send only the client use adblock.
         * @param {string} [fc] from_carousel: Carousel that user clicked to enter the current page
         * @param {number} [v] value: Collected value for the event type.
         * @param {string} [wp] watching_position: Current watching position in secs
         * @param {string} [c] cdn: cdn value
         * @param {string} [adc] ad_client: Client of the advertisement
         * @param {string} [adpid] ad_pod_id: Id of the pod
         * @param {string} [adpt] ad_pod_type: Type of the pod, eg: pre
         * @param {number} [adpi] ad_pod_index: The index of the pod
         * @param {number} [adpio] ad_pod_time_offset: Time offset that the advertisement starts
         * @param {number} [adptc] ad_pod_total_count: Total count of the advertisement in the pod
         * @param {string} [adcid] ad_creative_id
         * @param {number} [ado] ad_order: Order of the advertisement in the pod
         * @param {number} [add] ad_duration: Total duration of the advertisement
         * @param {boolean} [ads] ad_skippable: True if the advertisement is skippable.
         * @param {boolean} [adb] ad_bumper: True if the advertisement type is bumper
         * @param {string} [adn] ad_network: eg: AdSense, DCM, ...
         * @param {string} [adt] ad_title: Title of the advertisement
         * @param {string} [adlit] ad_line_item_id: Line item id of the advertisement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPlayerEvent: function (tenantId, t, dt, u, cid, m, s, p, os, ep, st, fr, fs, sl, d, r, ad, fc, v, wp, c, adc, adpid, adpt, adpi, adpio, adptc, adcid, ado, add, ads, adb, adn, adt, adlit, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('sendPlayerEvent', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/player_event/"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (t !== undefined) {
                                localVarQueryParameter['t'] = t;
                            }
                            if (dt !== undefined) {
                                localVarQueryParameter['dt'] = dt;
                            }
                            if (u !== undefined) {
                                localVarQueryParameter['u'] = u;
                            }
                            if (cid !== undefined) {
                                localVarQueryParameter['cid'] = cid;
                            }
                            if (m !== undefined) {
                                localVarQueryParameter['m'] = m;
                            }
                            if (s !== undefined) {
                                localVarQueryParameter['s'] = s;
                            }
                            if (p !== undefined) {
                                localVarQueryParameter['p'] = p;
                            }
                            if (os !== undefined) {
                                localVarQueryParameter['os'] = os;
                            }
                            if (ep !== undefined) {
                                localVarQueryParameter['ep'] = ep;
                            }
                            if (st !== undefined) {
                                localVarQueryParameter['st'] = st;
                            }
                            if (fr !== undefined) {
                                localVarQueryParameter['fr'] = fr;
                            }
                            if (fs !== undefined) {
                                localVarQueryParameter['fs'] = fs;
                            }
                            if (sl !== undefined) {
                                localVarQueryParameter['sl'] = sl;
                            }
                            if (d !== undefined) {
                                localVarQueryParameter['d'] = d;
                            }
                            if (r !== undefined) {
                                localVarQueryParameter['r'] = r;
                            }
                            if (ad !== undefined) {
                                localVarQueryParameter['ad'] = ad;
                            }
                            if (fc !== undefined) {
                                localVarQueryParameter['fc'] = fc;
                            }
                            if (v !== undefined) {
                                localVarQueryParameter['v'] = v;
                            }
                            if (wp !== undefined) {
                                localVarQueryParameter['wp'] = wp;
                            }
                            if (c !== undefined) {
                                localVarQueryParameter['c'] = c;
                            }
                            if (adc !== undefined) {
                                localVarQueryParameter['adc'] = adc;
                            }
                            if (adpid !== undefined) {
                                localVarQueryParameter['adpid'] = adpid;
                            }
                            if (adpt !== undefined) {
                                localVarQueryParameter['adpt'] = adpt;
                            }
                            if (adpi !== undefined) {
                                localVarQueryParameter['adpi'] = adpi;
                            }
                            if (adpio !== undefined) {
                                localVarQueryParameter['adpio'] = adpio;
                            }
                            if (adptc !== undefined) {
                                localVarQueryParameter['adptc'] = adptc;
                            }
                            if (adcid !== undefined) {
                                localVarQueryParameter['adcid'] = adcid;
                            }
                            if (ado !== undefined) {
                                localVarQueryParameter['ado'] = ado;
                            }
                            if (add !== undefined) {
                                localVarQueryParameter['add'] = add;
                            }
                            if (ads !== undefined) {
                                localVarQueryParameter['ads'] = ads;
                            }
                            if (adb !== undefined) {
                                localVarQueryParameter['adb'] = adb;
                            }
                            if (adn !== undefined) {
                                localVarQueryParameter['adn'] = adn;
                            }
                            if (adt !== undefined) {
                                localVarQueryParameter['adt'] = adt;
                            }
                            if (adlit !== undefined) {
                                localVarQueryParameter['adlit'] = adlit;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ClientEventsApiAxiosParamCreator = ClientEventsApiAxiosParamCreator;
/**
 * ClientEventsApi - functional programming interface
 * @export
 */
var ClientEventsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ClientEventsApiAxiosParamCreator)(configuration);
    return {
        /**
         * Post save player event
         * @param {string} tenantId
         * @param {SavePlayerEventRequestBody} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePlayerEvent: function (tenantId, data, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.savePlayerEvent(tenantId, data, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Send player event
         * @param {string} tenantId
         * @param {string} [t] type: event type
         * @param {string} [dt] dt_client: The timestamp for the point of sending the request.
         * @param {string} [u] user_id: Don’t include for guest user
         * @param {string} [cid] client_id: Web use clientId, App use app device id
         * @param {string} [m] membership: Membership eg ODK-PREMIUM
         * @param {string} [s] service_name: tenant slug
         * @param {string} [p] platform: platform name, eg: ios
         * @param {string} [os] os: operation system name
         * @param {string} [ep] episode: episode slug
         * @param {string} [st] screen_type: normal or fullscreen
         * @param {string} [fr] frame_rate: Encoding profile, eg: 3128601
         * @param {string} [fs] frame_size: Resolution of the device, eg: 1280x720
         * @param {string} [sl] subtitle_language: Language code used for subtitle. eg: en
         * @param {string} [d] device: Device type for the client, eg: desktop
         * @param {boolean} [r] is_recommend: Send only watching from recommend.
         * @param {boolean} [ad] is_adblock: Send only the client use adblock.
         * @param {string} [fc] from_carousel: Carousel that user clicked to enter the current page
         * @param {number} [v] value: Collected value for the event type.
         * @param {string} [wp] watching_position: Current watching position in secs
         * @param {string} [c] cdn: cdn value
         * @param {string} [adc] ad_client: Client of the advertisement
         * @param {string} [adpid] ad_pod_id: Id of the pod
         * @param {string} [adpt] ad_pod_type: Type of the pod, eg: pre
         * @param {number} [adpi] ad_pod_index: The index of the pod
         * @param {number} [adpio] ad_pod_time_offset: Time offset that the advertisement starts
         * @param {number} [adptc] ad_pod_total_count: Total count of the advertisement in the pod
         * @param {string} [adcid] ad_creative_id
         * @param {number} [ado] ad_order: Order of the advertisement in the pod
         * @param {number} [add] ad_duration: Total duration of the advertisement
         * @param {boolean} [ads] ad_skippable: True if the advertisement is skippable.
         * @param {boolean} [adb] ad_bumper: True if the advertisement type is bumper
         * @param {string} [adn] ad_network: eg: AdSense, DCM, ...
         * @param {string} [adt] ad_title: Title of the advertisement
         * @param {string} [adlit] ad_line_item_id: Line item id of the advertisement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPlayerEvent: function (tenantId, t, dt, u, cid, m, s, p, os, ep, st, fr, fs, sl, d, r, ad, fc, v, wp, c, adc, adpid, adpt, adpi, adpio, adptc, adcid, ado, add, ads, adb, adn, adt, adlit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.sendPlayerEvent(tenantId, t, dt, u, cid, m, s, p, os, ep, st, fr, fs, sl, d, r, ad, fc, v, wp, c, adc, adpid, adpt, adpi, adpio, adptc, adcid, ado, add, ads, adb, adn, adt, adlit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.ClientEventsApiFp = ClientEventsApiFp;
/**
 * ClientEventsApi - factory interface
 * @export
 */
var ClientEventsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ClientEventsApiFp)(configuration);
    return {
        /**
         * Post save player event
         * @param {string} tenantId
         * @param {SavePlayerEventRequestBody} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePlayerEvent: function (tenantId, data, options) {
            return localVarFp.savePlayerEvent(tenantId, data, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Send player event
         * @param {string} tenantId
         * @param {string} [t] type: event type
         * @param {string} [dt] dt_client: The timestamp for the point of sending the request.
         * @param {string} [u] user_id: Don’t include for guest user
         * @param {string} [cid] client_id: Web use clientId, App use app device id
         * @param {string} [m] membership: Membership eg ODK-PREMIUM
         * @param {string} [s] service_name: tenant slug
         * @param {string} [p] platform: platform name, eg: ios
         * @param {string} [os] os: operation system name
         * @param {string} [ep] episode: episode slug
         * @param {string} [st] screen_type: normal or fullscreen
         * @param {string} [fr] frame_rate: Encoding profile, eg: 3128601
         * @param {string} [fs] frame_size: Resolution of the device, eg: 1280x720
         * @param {string} [sl] subtitle_language: Language code used for subtitle. eg: en
         * @param {string} [d] device: Device type for the client, eg: desktop
         * @param {boolean} [r] is_recommend: Send only watching from recommend.
         * @param {boolean} [ad] is_adblock: Send only the client use adblock.
         * @param {string} [fc] from_carousel: Carousel that user clicked to enter the current page
         * @param {number} [v] value: Collected value for the event type.
         * @param {string} [wp] watching_position: Current watching position in secs
         * @param {string} [c] cdn: cdn value
         * @param {string} [adc] ad_client: Client of the advertisement
         * @param {string} [adpid] ad_pod_id: Id of the pod
         * @param {string} [adpt] ad_pod_type: Type of the pod, eg: pre
         * @param {number} [adpi] ad_pod_index: The index of the pod
         * @param {number} [adpio] ad_pod_time_offset: Time offset that the advertisement starts
         * @param {number} [adptc] ad_pod_total_count: Total count of the advertisement in the pod
         * @param {string} [adcid] ad_creative_id
         * @param {number} [ado] ad_order: Order of the advertisement in the pod
         * @param {number} [add] ad_duration: Total duration of the advertisement
         * @param {boolean} [ads] ad_skippable: True if the advertisement is skippable.
         * @param {boolean} [adb] ad_bumper: True if the advertisement type is bumper
         * @param {string} [adn] ad_network: eg: AdSense, DCM, ...
         * @param {string} [adt] ad_title: Title of the advertisement
         * @param {string} [adlit] ad_line_item_id: Line item id of the advertisement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPlayerEvent: function (tenantId, t, dt, u, cid, m, s, p, os, ep, st, fr, fs, sl, d, r, ad, fc, v, wp, c, adc, adpid, adpt, adpi, adpio, adptc, adcid, ado, add, ads, adb, adn, adt, adlit, options) {
            return localVarFp.sendPlayerEvent(tenantId, t, dt, u, cid, m, s, p, os, ep, st, fr, fs, sl, d, r, ad, fc, v, wp, c, adc, adpid, adpt, adpi, adpio, adptc, adcid, ado, add, ads, adb, adn, adt, adlit, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ClientEventsApiFactory = ClientEventsApiFactory;
/**
 * ClientEventsApi - object-oriented interface
 * @export
 * @class ClientEventsApi
 * @extends {BaseAPI}
 */
var ClientEventsApi = /** @class */ (function (_super) {
    __extends(ClientEventsApi, _super);
    function ClientEventsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Post save player event
     * @param {string} tenantId
     * @param {SavePlayerEventRequestBody} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientEventsApi
     */
    ClientEventsApi.prototype.savePlayerEvent = function (tenantId, data, options) {
        var _this = this;
        return (0, exports.ClientEventsApiFp)(this.configuration).savePlayerEvent(tenantId, data, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Send player event
     * @param {string} tenantId
     * @param {string} [t] type: event type
     * @param {string} [dt] dt_client: The timestamp for the point of sending the request.
     * @param {string} [u] user_id: Don’t include for guest user
     * @param {string} [cid] client_id: Web use clientId, App use app device id
     * @param {string} [m] membership: Membership eg ODK-PREMIUM
     * @param {string} [s] service_name: tenant slug
     * @param {string} [p] platform: platform name, eg: ios
     * @param {string} [os] os: operation system name
     * @param {string} [ep] episode: episode slug
     * @param {string} [st] screen_type: normal or fullscreen
     * @param {string} [fr] frame_rate: Encoding profile, eg: 3128601
     * @param {string} [fs] frame_size: Resolution of the device, eg: 1280x720
     * @param {string} [sl] subtitle_language: Language code used for subtitle. eg: en
     * @param {string} [d] device: Device type for the client, eg: desktop
     * @param {boolean} [r] is_recommend: Send only watching from recommend.
     * @param {boolean} [ad] is_adblock: Send only the client use adblock.
     * @param {string} [fc] from_carousel: Carousel that user clicked to enter the current page
     * @param {number} [v] value: Collected value for the event type.
     * @param {string} [wp] watching_position: Current watching position in secs
     * @param {string} [c] cdn: cdn value
     * @param {string} [adc] ad_client: Client of the advertisement
     * @param {string} [adpid] ad_pod_id: Id of the pod
     * @param {string} [adpt] ad_pod_type: Type of the pod, eg: pre
     * @param {number} [adpi] ad_pod_index: The index of the pod
     * @param {number} [adpio] ad_pod_time_offset: Time offset that the advertisement starts
     * @param {number} [adptc] ad_pod_total_count: Total count of the advertisement in the pod
     * @param {string} [adcid] ad_creative_id
     * @param {number} [ado] ad_order: Order of the advertisement in the pod
     * @param {number} [add] ad_duration: Total duration of the advertisement
     * @param {boolean} [ads] ad_skippable: True if the advertisement is skippable.
     * @param {boolean} [adb] ad_bumper: True if the advertisement type is bumper
     * @param {string} [adn] ad_network: eg: AdSense, DCM, ...
     * @param {string} [adt] ad_title: Title of the advertisement
     * @param {string} [adlit] ad_line_item_id: Line item id of the advertisement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientEventsApi
     */
    ClientEventsApi.prototype.sendPlayerEvent = function (tenantId, t, dt, u, cid, m, s, p, os, ep, st, fr, fs, sl, d, r, ad, fc, v, wp, c, adc, adpid, adpt, adpi, adpio, adptc, adcid, ado, add, ads, adb, adn, adt, adlit, options) {
        var _this = this;
        return (0, exports.ClientEventsApiFp)(this.configuration).sendPlayerEvent(tenantId, t, dt, u, cid, m, s, p, os, ep, st, fr, fs, sl, d, r, ad, fc, v, wp, c, adc, adpid, adpt, adpi, adpio, adptc, adcid, ado, add, ads, adb, adn, adt, adlit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ClientEventsApi;
}(base_1.BaseAPI));
exports.ClientEventsApi = ClientEventsApi;
