import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import * as APIString from '~constants/apiString';
import loginFormId from '~constants/ids/loginFormId';

import { getConfigByKey } from '~features/tenantConfig/services';
import { ReactComponent as IconKaKaoTalk } from '~img/icons/projectw/signin/kakaotalk.svg';
import KakaoLogin from 'react-kakao-login';
import { REACT_APP_KAKAO_APPLICATION_ID } from '~constants/envConfig';
import { loginKaKaoTalk } from '../../services';
import { loading } from '../../../loadingPage/services';
import { GroupSocial, ButtonW } from './loginButtonStyled';

const LoginKaKaoTalk = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setResMessage, setAction } = props;
  const token = '36e4ad832cb2e6dcb2ee54418b323257';
  const onSuccess = res => {
    console.log('Response - KakaoTalk : ', res);
    const { response } = res;
    let data = {
      token: response.access_token,
    };
    const snType = 'kakao_talk';
    dispatch(loading(true));
    dispatch(loginKaKaoTalk(data, snType))
      .then(res => {})
      .catch(err => {
        setResMessage &&
          setResMessage({
            alert: true,
            message: err.message,
            isLoading: false,
          });
        setAction &&
          setAction({
            isLoading: false,
            resMessage: err.message,
            showAlert: true,
          });
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
  const onFail = err => {
    console.error('Err - KakaoTalk : ', err);
    setResMessage &&
      setResMessage({
        alert: true,
        message: err.error_description,
        isLoading: false,
      });
    setAction &&
      setAction({
        isLoading: false,
        resMessage: err.error_description,
        showAlert: true,
      });
  };
  const onLogout = () => {
    console.info('Info - KakaoTalk : ');
  };

  if (!getConfigByKey('features.loginKaKaoTalk')) return null;
  return (
    <KakaoLogin
      token={REACT_APP_KAKAO_APPLICATION_ID}
      redirect_uri={`https://${getConfigByKey('hostName')}`}
      onSuccess={res => onSuccess(res)}
      onFail={err => onFail(err)}
      onLogout={onLogout}
      getProfile={true}
      render={({ onClick }) => {
        return (
          <GroupSocial onClick={onClick}>
            <IconKaKaoTalk />
            <ButtonW data-testid={loginFormId.continueWithFacebookBtn} bsSize="large">
              {t('loginScreen.signIn-Kakao')}
            </ButtonW>
          </GroupSocial>
        );
      }}
    />
  );
};

const mapDispatchToProps = {};

const mapStateToProps = state => ({
  account: state.auth.account,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginKaKaoTalk);
