import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { LineClampStyled } from '../../landingpage/styles/line-clamp';
import classnames from 'classnames';
import { setActiveRibbonId } from '../services';

const useIntersectionObserver = (setActiveId, activeRibbonId) => {
  const headingElements = Array.from(document.querySelectorAll('h2'));
  React.useEffect(() => {
    const callback = headings => {
      headings.forEach(entry => {
        if (entry.isIntersecting) {
          if (entry.target.id === activeRibbonId) return;
          setActiveId(entry.target.id);
        }
      });
    };
    const observer = new IntersectionObserver(callback, {
      rootMargin: '100px',
      threshold: 0,
    });
    headingElements.forEach(element => observer.observe(element));
    return () => observer.disconnect();
  }, [setActiveId, headingElements[0]]);
};

function RibbonList(props) {
  const { schedule, setActiveRibbonId, activeRibbonId } = props;
  useIntersectionObserver(setActiveRibbonId, activeRibbonId);

  return (
    <StyledList className="rb-list">
      {schedule.ribbons.map((rb, i) => (
        <div
          className={classnames('rb-list--item', rb.id === activeRibbonId && 'active')}
          onClick={() => setActiveRibbonId(rb.id)}
          key={i}
        >
          <img className="image-wrapper" src={`${rb.icon_url}`} />
          <a href={'#' + rb.slug}>
            <LineClampStyled className="line-clamp-2">{rb.name}</LineClampStyled>
          </a>
        </div>
      ))}
    </StyledList>
  );
}

const StyledList = styled.div`
  height: calc(100vh - 300px);
  overflow-y: scroll;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  font-size: 23px;
  &.rb-list {
    .rb-list--item {
      cursor: pointer;
      padding: 15px 0;
      display: flex;
      align-items: center;
      & .image-wrapper {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        padding: 5px;
        border-radius: 5px;
      }
      & a {
        color: white;
      }
      &:hover a,
      &.active a {
        color: ${({ theme }) => theme.primaryColor};
        text-decoration: none;
      }
      &:hover .image-wrapper,
      &.active .image-wrapper {
        filter: ${({ theme }) => theme.filterColor};
        /* background-color: rgba(242, 104, 34, 0.85); */
      }
    }
  }
`;

const mapDispatchToProps = {
  setActiveRibbonId: id => setActiveRibbonId(id),
};
const mapStateToProps = state => ({
  schedule: state.liveSchedule.schedule,
  activeRibbonId: state.liveSchedule.activeRibbonId,
});

export default connect(mapStateToProps, mapDispatchToProps)(RibbonList);
