var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiError = exports.InvalidProfileTokenError = exports.UnauthorizedError = exports.AnonymousBlocked = exports.MemberShipBlockedError = exports.GeolocationBlockedError = exports.ConcurrentLimitBlockedError = void 0;
var BackendApiError = /** @class */ (function (_super) {
    __extends(BackendApiError, _super);
    function BackendApiError() {
        return _super.call(this) || this;
    }
    return BackendApiError;
}(Error));
var ConcurrentLimitBlockedError = function (devices) {
    var e = new BackendApiError();
    e.name = 'concurrent-limit-blocked';
    e.message = 'Max concurrent streams reached.';
    e.info = { devices: devices };
    return e;
};
exports.ConcurrentLimitBlockedError = ConcurrentLimitBlockedError;
var GeolocationBlockedError = function () {
    var e = new BackendApiError();
    e.name = 'geo-blocking';
    e.message = 'Service is not supported for the current region';
    return e;
};
exports.GeolocationBlockedError = GeolocationBlockedError;
var MemberShipBlockedError = function () {
    var e = new BackendApiError();
    e.name = 'membership-blocked';
    e.message = 'This content is not available for current membership';
    return e;
};
exports.MemberShipBlockedError = MemberShipBlockedError;
var AnonymousBlocked = function () {
    var e = new BackendApiError();
    e.name = 'content-anonymous-blocked';
    e.message = 'This content require authentication to watch';
    return e;
};
exports.AnonymousBlocked = AnonymousBlocked;
var UnauthorizedError = function () {
    var e = new BackendApiError();
    e.name = 'unauthorized';
    e.message = 'Please sign in again.';
    return e;
};
exports.UnauthorizedError = UnauthorizedError;
var InvalidProfileTokenError = function () {
    var e = Error();
    e.name = 'invalid-profile-token';
    e.message = 'Invalid profile token.';
    return e;
};
exports.InvalidProfileTokenError = InvalidProfileTokenError;
var ApiError = function (i) {
    var raw = i.rawError;
    raw.name = 'api-error';
    raw.message = i.message;
    return raw;
};
exports.ApiError = ApiError;
