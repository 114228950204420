import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getTimePaidContent } from '../functions/getTimePaidContent';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
class TimePaid extends React.Component {
  render() {
    const { paidContents, idContent, target, timePaidContents, paymentInfors, t } = this.props;
    let time = timePaidContents;
    if (!timePaidContents && idContent) {
      time = getTimePaidContent(paidContents, idContent, paymentInfors);
    }
    if (!time || (time && time.hour === 0 && !time.day)) return null;
    if (target === 'landing') {
      return (
        <DivStyle className="timepaid__container-landing">
          <QueryBuilderIcon />
          <span>
            {time && time.day
              ? `${time.day} ${t('payperview.day')} `
              : `${time.hour} ${time.hour === 1 ? t('payperview.hour') : t('payperview.hours')}`}
          </span>
        </DivStyle>
      );
    }
    return (
      <DivStyle className="timepaid__container">
        <i className="fa fa-clock-o" aria-hidden="true" />
        <span>
          {time && time.day
            ? `${time.day} ${t('payperview.day')} `
            : `${time.hour} ${time.hour === 1 ? t('payperview.hour') : t('payperview.hours')}`}
        </span>
      </DivStyle>
    );
  }
}

const mapStateToProps = state => ({
  account: state.auth.account,
  paidContents: state.auth.paidContents,
});

const mapDispatchToProps = {};

const DivStyle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #2574d4;
  font-size: 10px;
  &.timepaid__container {
    &-landing {
      color: #fff;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      border-radius: 3.6em;
      border: 1px solid #fff;
      padding: 0.7em 1.2em 0.7em 0.7em;
      margin-left: 1em;
      svg {
        font-size: 1.8em;
        margin-right: 3px;
      }
      span {
        white-space: nowrap;
        font-size: 1.4em;
      }
    }
    i.fa {
      font-size: 1.5rem;
    }
    span {
      padding: 0 0.5rem;
    }
  }
  @media (min-width: 992px) {
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TimePaid));
