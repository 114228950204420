import { getConfigByKey } from '~features/tenantConfig/services';
import camelcaseKeys from 'camelcase-keys';
import _ from 'lodash';
import { IAxiosResponseData, Pagination } from '~core/models';
import { TENANT_NAME_ENUM } from '~core/constants';
import { getViewContentsByIds } from './content';
import { apiAdapter, config, axiosTakeDataFromResponse } from '~share';

const { contentService, contentListingService } = apiAdapter;

export { getDetail, viewDetail, getSeason };

export const CONTENT_DETAIL_SELECT_FIELDS = [
  'can_preview',
  'category',
  'category_slug',
  'content_categories',
  'genre',
  'has_free_content',
  'id',
  'group',
  'images',
  'is_new_release',
  'is_premium',
  'long_description',
  'restriction',
  'num_first_episode_preview',
  'people',
  'provider_slug',
  'regions',
  'release_date',
  'released_episode_count',
  'runtime',
  'seasons',
  'short_description',
  'slug',
  'title',
  'total_episodes',
  'episode',
  'trailers',
  'type',
  'video_source',
  'subtitles',
  'price',
  'is_ads_disabled_when_playing',
  'metadata',
  'short_title',
  'duration',
  'payment_type',
  'film_code',
  'production_company_name',
  'prices',
  'show_info',
  'start_on',
  'schedule_publish',
  'license_period',
  'unpublished_time',
];

export const CONTENT_DETAIL_SEASON_SELECT_FIELDS = ['id', 'slug', 'title'];
export const CONTENT_METADATA = ['peoples', 'genres', 'regions'];

export const CONTENT_DETAIL_TRAILER_SELECT_FIELDS = [
  'id',
  'images',
  'is_new_release',
  'title',
  'link_play',
  'duration',
];

export const CONTENT_VIEWS_SELECT_FIELDS = [
  'current_season',
  'id',
  'slug',
  'is_watchable',
  'progress',
  'youtube_video_id',
  'link_play',
  'play_info',
  'payment_infors',
  'is_favorite',
  'refund',
  'show_info',
  'geo_blocked',
  'prices',
  'applicable_for_bign_ticket',
];

export const CONTENT_SEASON_SELECT_FIELDS = ['episodes', 'id', 'slug', 'title', 'is_new_release'];

export const CONTENT_SEASON_ITEMS_SELECT_FIELDS = [
  'id',
  'slug',
  'title',
  'video_source',
  'is_premium',
  'is_new_release',
  'images',
  'can_preview',
  'episode',
  'type',
  'is_watchable',
  'short_title',
];

function getDetail(
  id: string,
): Promise<IAxiosResponseData<typeof contentService.getContentDetail>> {
  const select = JSON.stringify({
    Content: CONTENT_DETAIL_SELECT_FIELDS,
    SeasonList: CONTENT_DETAIL_SEASON_SELECT_FIELDS,
    TrailerSerializerDocumentation: CONTENT_DETAIL_TRAILER_SELECT_FIELDS,
  });
  const {
    tenantSlug = '',
    imageResolutionScale,
    imageFormat,
    platformSlug,
    acceptLanguage,
  } = config;
  return contentService
    .getContentDetail(
      id,
      tenantSlug,
      acceptLanguage,
      select,
      imageResolutionScale,
      platformSlug,
      undefined,
      imageFormat,
    )
    .then(axiosTakeDataFromResponse);
}

function viewDetail(
  id: string,
): Promise<IAxiosResponseData<typeof contentService.getContentViewInfo>> {
  const select = JSON.stringify({
    Content: [
      ...CONTENT_VIEWS_SELECT_FIELDS,
      ([TENANT_NAME_ENUM.SCTV, TENANT_NAME_ENUM.PROJECTW] as any[]).includes(
        getConfigByKey('ui_theme'),
      )
        ? 'drm_session_info'
        : '',
    ],
  });
  const { tenantSlug = '', authorization, imageResolutionScale, acceptLanguage } = config;
  return contentService
    .getContentViewInfo(id, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale)
    .then(axiosTakeDataFromResponse);
}

function getSeason(id: string): Promise<IAxiosResponseData<typeof contentService.getSeasonDetail>> {
  const select = JSON.stringify({
    SeasonDetail: CONTENT_SEASON_SELECT_FIELDS,
    Content: CONTENT_SEASON_ITEMS_SELECT_FIELDS,
  });
  const { tenantSlug = '', imageResolutionScale, imageFormat } = config;
  return contentService
    .getSeasonDetail(
      id,
      tenantSlug,
      undefined,
      undefined,
      select,
      imageResolutionScale,
      imageFormat,
    )
    .then(axiosTakeDataFromResponse)
    .then(async (data: any) => {
      const { episodes } = data;
      let newEpisodes = [];
      if (!_.isEmpty(episodes)) {
        const ids = episodes.map((eps: any) => eps.id);
        const viewIds = await getViewContentsByIds(ids);

        const viewContentById = _.keyBy(viewIds, 'id');
        newEpisodes = episodes.map((eps: any) => {
          const viewEps = viewContentById[eps.id] || {};
          return {
            ...eps,
            ...viewEps,
          };
        });
        data.episodes = newEpisodes;
      }
      return data;
    });
}

export function getPickRibbonRelative(
  id: string,
  params: {
    expect_types?: string;
    display_type?: string;
    has_top_contents?: boolean;
  } & Pagination = {},
): Promise<IAxiosResponseData<typeof contentListingService.getRelatedContentsOfContent>> {
  const { page, limit, expectTypes, displayType, hasTopContents } = camelcaseKeys(params);

  const { tenantSlug = '', authorization, imageResolutionScale, acceptLanguage } = config;
  return contentListingService
    .getRelatedContentsOfContent(
      id,
      tenantSlug,
      authorization,
      acceptLanguage,
      imageResolutionScale,
      undefined,
      page,
      limit,
      expectTypes,
      displayType,
      hasTopContents,
    )
    .then(axiosTakeDataFromResponse);
}

export function getCurrentTimeLiveEvent(
  contentId: string,
): Promise<IAxiosResponseData<typeof contentService.getContentCurrentStreaming>> {
  const { tenantSlug = '', acceptLanguage } = config;
  return contentService
    .getContentCurrentStreaming(contentId, tenantSlug, acceptLanguage)
    .then(axiosTakeDataFromResponse);
}
