import { getConfigFromEnv } from './tenantEnvConfig';
import { REACT_APP_ENV } from '~constants/apiString';
import _ from 'lodash';

const featureFlagsByTenantSlug = {
  // Default for other tenants
  default: {},

  odv: {
    subscriptionFeature: true,
    firebaseFeature: true,
    gtmFeature: true,
    onesignal: true,
    cookielaw: true,
    invite_referrals: true,
    bottom_bar_information: true,
    deep_link: true,
    notify_player: true,
    payment_card_feature: true,
    coupon_feature: true,
    vertical_ribbon: true,
    ppvPaymentPaypalFeature: true,
    ppvPaymentStripFeature: true,
    ppvPaymentBootpayFeature: false,
    deviceOptionsFeature: true,
    tvodFeature: true,
    signInFacebookFeature: true,
    skyscrapperBannerAds: true,
    loginGoogle: true,
    loginfb: true,
    nameAccount: true,
    popoverSignup: true,
    ABTesting: true,
    smartBanner: true,
    extraBannerAds: false,
  },

  sctv: {
    rewatchChannelFeature: true,
    subscriptionFeature: true,
    firebaseFeature: true,
    bottom_bar_information: true,
    deep_link: false,
    notify_player: false,
    payment_sms_feature: true,
    payment_method_feature: true,
    vertical_ribbon: false,
    loginPhoneNumber: true,
    svodAppFeature: false,
    deviceOptionsFeature: false,
    tvodFeature: false,
    skyscrapperBannerAds: false,
    pgMomoFeature: true,
    signInFacebookFeature: false,
    loginGoogle: false,
    loginfb: true,
    nameAccount: false,
    popoverSignup: false,
    ABTesting: false,
    extraBannerAds: false,
  },
  projectw: {
    subscriptionFeature: false,
    firebaseFeature: true,
    bottom_bar_information: true,
    deep_link: false,
    notify_player: false,
    vertical_ribbon: false,
    ppvPaymentBootpayFeature: true,
    tvodFeature: true,
    signInFacebookFeature: false,
    skyscrapperBannerAds: false,
    loginGoogle: true,
    loginfb: false,
    loginKaKaoTalk: true,
    loginNaver: true,
    nameAccount: false,
    popoverSignup: false,
    ABTesting: false,
    extraBannerAds: false,
    disabledGeoBlockingFeature: true,
    maxQualitySelectorFeature: true,
  },
  escondido: {
    subscriptionFeature: true,
    bottom_bar_information: false,
    deep_link: true,
    notify_player: false,
    vertical_ribbon: false,
    payment_card_feature: true,
    ppvPaymentPaypalFeature: true,
    ppvPaymentStripFeature: true,
    ppvPaymentBootpayFeature: false,
    tvodFeature: true,
    skyscrapperBannerAds: false,
    loginGoogle: false,
    loginfb: false,
    detailOnHover: true,
    nameAccount: true,
    popoverSignup: true,
    ABTesting: false,
    extraBannerAds: false,
  },
};

export let tenantFeatureFlags = {};

export const getTenantFeatureFlags = () => {
  if (!_.isEmpty(tenantFeatureFlags)) {
    return tenantFeatureFlags;
  }
  const tenantEnvConfig = getConfigFromEnv();

  const config =
    tenantEnvConfig.ui_theme in featureFlagsByTenantSlug
      ? featureFlagsByTenantSlug[tenantEnvConfig.ui_theme as keyof typeof featureFlagsByTenantSlug]
      : featureFlagsByTenantSlug['default'];
  tenantFeatureFlags = config;
  return tenantFeatureFlags;
};

export const getTenantFeatureFlagsWithSsr = (utils: any) => {
  const ssrTenant: keyof typeof featureFlagsByTenantSlug =
    (utils.requestSsr && utils.requestSsr.envConfig.ui_theme) || 'default';
  return featureFlagsByTenantSlug[ssrTenant];
};
