import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import * as types from './../constants';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Type from '~components/ribbon/components/contents/type';
import Ribbons from '~components/ribbon/styled';
import { getTimePaidContent } from '~components/ribbon/functions/getTimePaidContent';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import TopContents from '~components/ribbon/components/contents/topContents';
const Regions = ({ listRegions }) => {
  const history = useHistory();
  const language = useSelector(state => state.root.language);

  const pushState = slug => {
    return `/${language}/nationalities/${slug}`;
  };
  return listRegions.map((items, index) => {
    return (
      <React.Fragment key={index}>
        {!index && ' | '}
        <Link className="linkTagProjectW" to={pushState}>
          {items.name}
          {index < listRegions.length - 1 ? `, ` : null}
        </Link>
      </React.Fragment>
    );
  });
};

const SubTitle = props => {
  const { item } = props;
  const { t } = useTranslation();

  const releaseYear = item.release_date
    ? moment(item.release_date, 'YYYY-MM-DD').format(`YYYY`)
    : null;

  const totalEps = item.total_episodes;
  const listRegions = (item.metadata && item.metadata.regions) || [];
  if (item.type === types.MOVIE_TYPE || item.type === types.CLIP) {
    return (
      <div className="v-verticalRibbon--eps">
        {releaseYear}{' '}
        {listRegions && listRegions.length ? <Regions listRegions={listRegions} /> : null}
      </div>
    );
  }
  if (item.type === types.SHOW_TYPE && totalEps) {
    return (
      <p className="v-verticalRibbon--eps" style={{ textTransform: 'lowercase' }}>
        {totalEps} {t('video.eps')}
      </p>
    );
  }
  return null;
};

function RibbonItemHorizontal(props) {
  const { item, t, ribbon, search, paidContents } = props;
  const language = useSelector(state => state.root.language);
  let { type } = item;
  let linkTo =
    type === types.CHANNEL_TYPE || type === types.CURATED_LIVE || item.video_source === 1
      ? `/detail/${item.slug}`
      : `/landing/${item.slug}`;
  if (
    item.video_source === 1 &&
    (type === types.SHOW_TYPE || type === types.MOVIE_TYPE || type === types.CLIP)
  )
    linkTo = `/landing/${item.slug}`;
  const displayTypeStr = types[`CLASS_${(ribbon && ribbon.display_type) || 1}`];
  let img = item.images[displayTypeStr] || item.images.thumbnail;
  const timePaidContents = getTimePaidContent(paidContents, item.id);

  const handleClickNavigate = e => {
    if (item.bannerExtra) {
      e.preventDefault();
      const newWindow = window.open(
        'https://www.contentpromo.ondemandkorea.com/odv-tundra-vi',
        '_blank',
        'noopener,noreferrer',
      );
      if (newWindow) newWindow.opener = null;
      return;
    }
  };
  return (
    <LinkStyled
      className={`v-verticalRibbon ${search ? 'v-verticalRibbonSearch' : null}`}
      to={`/${language}${linkTo}`}
      onClick={e => handleClickNavigate(e)}
    >
      <Ribbons className={`v-verticalRibbon--img-wrap ribbonItemContainer ${displayTypeStr}`}>
        <img className="v-verticalRibbon--thumbnail" src={img} />
        <Type timePaidContents={timePaidContents} paymentType={item.payment_type} {...props} />
        <TopContents {...props} />
      </Ribbons>
      <div className="v-verticalRibbon--info">
        <p className="v-verticalRibbon--title">{item.title}</p>
        <SubTitle {...props} />
        {/* <p className='v-verticalRibbon--update'>{item.is_new_release && 'da cap nhap tap' + item.total_episodes ? item.total_episodes + 'Tập' : ''}</p> */}
      </div>
    </LinkStyled>
  );
}

const LinkStyled = styled(Link)`
  &.v-verticalRibbon {
    display: flex;
    flex-direction: row;
    font-size: 1em;
    padding: 0.5rem 0;
    &:hover {
      text-decoration: none;
      .v-verticalRibbon--thumbnail {
        transform: scale(1.1);
      }
    }
    &.v-verticalRibbonSearch {
      .v-verticalRibbon {
        &--img-wrap {
          width: 10rem;
          padding-top: calc(9 * 10rem / 16);
          &.poster {
            padding-top: calc(40 / 27 * 10rem);
          }
        }
        &--info {
          width: calc(100% - 10rem);
        }
      }
    }
    .ribbonTicketVip {
      top: 6px;
      right: 4px;
      z-index: 9;
    }
    .ribbonTicketYotube {
      width: 68px;
      height: auto;
      left: 12px;
      bottom: 7px;
    }
    .ribbonTicketTopContent {
      width: auto;
      max-width: 64px;
    }
    .poster {
      .ribbonTicketTopContent {
        width: 45%;
        max-width: unset;
      }
    }
  }
  .v-verticalRibbon {
    &--img-wrap {
      width: 45%;
      /* height: 162px; */
      overflow: hidden;
      border-radius: 6px;
      position: relative;
      border: none;
      padding: 0;
      padding-top: calc(9 * 45% / 16);
      background: #fff;
      z-index: 1;
      &.poster {
        padding-top: calc(40 / 27 * 45%);
      }
      &.thumbnail {
        margin-bottom: 0;
      }
    }
    &--thumbnail {
      display: block;
      max-width: 100%;
      transition: all 200ms ease-out;
      transform: scale(1);
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
      cursor: pointer;
    }
    &--info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0 0.5rem;
      width: 55%;
      p {
        margin: 0 0 5px;
      }
    }
    &--title {
      color: #e4ebee;
      font-size: 16px;
    }
    &--eps,
    &--update {
      font-size: 14px;
      color: #9b9b9b;
      display: flex;
      text-decoration: none;
      flex-wrap: wrap;
      a {
        font-size: 14px;
        color: #9b9b9b;
        padding-left: 6px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &--youtubeBadge {
      position: absolute;
      left: 0.5rem;
      width: 4.5rem;
      bottom: 0.5rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
`;
export default withTranslation()(RibbonItemHorizontal);
