import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { conenct, useDispatch, useSelector } from 'react-redux';
import {
  setIsShowVerifyEmailModal,
  setIsWelcome,
  setIsShowVerifyEmailPPV,
  setCheckVerifiedEmail,
  setIsShowSignInForm,
} from '~components/global/globalSlice';
import { logout } from '~features/auth/services';
import _ from 'lodash';
import { getProfile } from '~features/profile/services';
import { resendEmail } from '~features/confirmation/services';
import { isLoggedVerifiedEmail } from '~core/method/authMethod';
import Loader from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

const VerifyEmailComponent = styled.div`
  font-family: 'Neue Haas Grotesk Display Pro';
  text-align: center;
  color: #000000;
  min-height: ${props => (props.openShowPPV ? '70vh' : '')};
  height: ${props => (props.openShowPPV ? '100vh' : '')};
  .verifyEmail {
    &__container {
      margin-top: 212px;
      &.onPPV {
        margin-top: unset;
      }
      &__title {
        padding-bottom: 44px;
        .txtTitle {
          margin: 0;
          color: #000000;
          font-weight: 600;
          font-size: 27px;
          line-height: 32px;
        }
      }
      &__description {
        font-weight: 700;
        font-size: 20px;
        line-height: 21px;
        padding: 0 22px;
        font-family: 'Neue Machina';
        .txtDes {
          margin: 0;
          &.frist {
            padding-bottom: 35px;
          }
        }
      }
      &__groupBtn {
        margin-top: 44px;
        display: block;
        .btn {
          padding: 16.5px 79px;
          font-size: 13px;
          font-weight: 600;
          text-align: center;
          letter-spacing: 0.025em;
          text-transform: uppercase;
          line-height: 133.5%;
          margin-bottom: 30px;
          border-radius: 0;
          min-width: 341px;
          &:hover,
          &:active,
          &:focus {
            border: 0;
            border-radius: 0;
            outline: none;
          }
          &.hadVerified {
            color: #fff;
            background-color: #000;
            border: 0;
          }
          &.resendVerify {
            color: #000;
            background-color: #e5e5ea;
            border: 0;
          }
          &.logout {
            border: 1px solid #000;
            background-color: #fff;
            color: #000;
          }
        }
      }
      .showMessage {
        margin-top: 57px;
        font-size: 17px;
        color: #000;
        font-weight: 400;
        line-height: 146%;
        .message {
          &.errorMsg {
            color: #e92121;
          }
        }
      }
    }
  }
  @media (max-width: 992px) {
    .verifyEmail {
      &__container {
        margin-top: 40px;
        &__description {
          padding: 0;
          font-size: 16px;
        }
        &__groupBtn {
          .btn {
            padding: 16.5px 45px;
            min-width: 100%;
          }
        }
      }
    }
  }
`;

function VerifyEmailForm(props) {
  const { openShowPPV = false } = props;
  const { t } = useTranslation();
  const [infoMessage, setInfoMesage] = React.useState({
    alert: false,
    message: '',
    messageErr: '',
  });
  const [isLoading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const account = useSelector(state => state.auth.account);
  const auth = useSelector(state => state.auth);
  const checkEmailConfirm = res => {
    if (!res.email_confirmed) {
      setInfoMesage({
        alert: true,
        message: '',
        messageErr: `${t('projectw__modal.Popup_VerifyEmail.txtNotiUnVerify')}`,
      });
      return;
    }
    if (openShowPPV) {
      dispatch(setIsWelcome(false));
    }
    dispatch(setIsWelcome(true));
    dispatch(setIsShowVerifyEmailPPV(false));
    dispatch(setCheckVerifiedEmail(true));
  };

  const handleRefreshProfile = () => {
    setLoading(true);
    dispatch(getProfile())
      .then(res => {
        checkEmailConfirm(res);
      })
      .catch(error => {
        setInfoMesage('Something wrong');
      })
      .finally(() => setLoading(false));
  };

  const handleVerifiedEmail = () => {
    handleRefreshProfile();
  };

  const handleLogOut = () => {
    dispatch(logout());
    dispatch(setIsShowVerifyEmailModal(false));
    dispatch(setIsShowVerifyEmailPPV(false));
    dispatch(setIsShowSignInForm(true));
  };

  const UserEmail = account => {
    if (_.isEmpty(account) || _.isEmpty(account.profile)) {
      return;
    }
    return account.profile.email;
  };

  const handleResend = () => {
    setInfoMesage({
      alert: true,
      messageErr: '',
      message: `${t('projectw__modal.Popup_VerifyEmail.txtNotiResendEmail', {
        userEmail: UserEmail(account),
      })}`,
    });
    if (!isLoggedVerifiedEmail.call(auth)) {
      setLoading(true);
      dispatch(resendEmail(1))
        .then(res => {})
        .catch(error => {})
        .finally(() => setLoading(false));
    }
  };

  return (
    <VerifyEmailComponent openShowPPV className="verifyEmail">
      <div className={`verifyEmail__container ${openShowPPV && 'onPPV'}`}>
        <div className="verifyEmail__container__title">
          <p className="txtTitle">{t('projectw__modal.Popup_VerifyEmail.title')}</p>
        </div>
        <div className="verifyEmail__container__description">
          <p className="txtDes frist">{t('projectw__modal.Popup_VerifyEmail.description')}</p>
          <p className="txtDes">{t('projectw__modal.Popup_VerifyEmail.descriptionSecond')}</p>
        </div>
        <div className="verifyEmail__container__groupBtn">
          <Button onClick={handleVerifiedEmail} className="btn hadVerified">
            {t('projectw__modal.Popup_VerifyEmail.btnVerified')}
          </Button>
          <Button onClick={handleResend} className="btn resendVerify">
            {t('projectw__modal.Popup_VerifyEmail.btnResend')}
          </Button>
          <Button onClick={handleLogOut} className="btn logout">
            {t('projectw__modal.Popup_VerifyEmail.btnLogout')}
          </Button>
        </div>
        {isLoading ? (
          <Loader type={'Oval'} color="#000" height="30" width="30" />
        ) : (
          <div className="showMessage">
            <p className={`message ${!_.isEmpty(infoMessage.messageErr) && 'errorMsg'}`}>
              {(infoMessage.alert && infoMessage.message) ||
                (infoMessage.alert && infoMessage.messageErr)}
            </p>
          </div>
        )}
      </div>
    </VerifyEmailComponent>
  );
}

export default VerifyEmailForm;
