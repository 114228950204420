import React, { useState, useEffect } from 'react';
import { useSelector, connect, useDispatch } from 'react-redux';

import { Box } from '@material-ui/core';

import FilterContent from '~components/filter/filter';
import { loading } from '~features/loadingPage/services';
import _ from 'lodash';
import Recommend from './recommend/Recommend';
import Upcoming from './upcoming';
import VideoText from './videoText';
import { BANNER_DISPLAY, ILLUON_COLLECTION, ILLUON_SPOTLIGHT } from './constants';
import { setNamePage, setIsHomePageClient } from '~components/global/globalSlice';
import { debug } from '~utils/utils';

const getIlluonRibbons = (ribbons = []) =>
  ribbons.filter(
    ribbon => ribbon.display_type === ILLUON_SPOTLIGHT || ribbon.display_type === ILLUON_COLLECTION,
  );

function HomePageProjectW(props) {
  const home = useSelector(state => state.home);
  const dispatch = useDispatch();
  const { ribbons: ribbonsFromState = [], name: pageName = '' } = home.page || {};
  const { menu = {} } = home;
  const [ribbons, setRibbons] = useState([]);
  const { isLoadingHomePage, dpLoading, dpSetNamePage } = props;
  const isMobileDetect = window.innerWidth < 768;

  React.useEffect(() => {
    dpSetNamePage('');
  }, []);

  useEffect(() => {
    dpLoading(!!isLoadingHomePage);
  }, [isLoadingHomePage]);

  useEffect(() => {
    if (_.isEmpty(ribbonsFromState)) {
      return;
    }
    setRibbons(getIlluonRibbons(ribbonsFromState));
  }, [ribbonsFromState]);

  React.useEffect(() => {
    dispatch(setIsHomePageClient(true));
    return () => {
      dispatch(setIsHomePageClient(false));
    };
  }, []);

  return (
    <>
      <FilterContent hiddenFilter />
      {home.page && (
        <Box>
          <VideoText className="home-section" {...props} />
          {ribbons.map(ribbon => (
            <div key={ribbon.id}>
              <Upcoming className="home-section" ribbon={ribbon} />
              <Recommend className="home-section" ribbon={ribbon} />
            </div>
          ))}
        </Box>
      )}
    </>
  );
}
const mapStateToProps = state => ({
  displayContents: state.home.displayContents,
  isLoadingHomePage: state.home.isLoadingPage,
});

const mapDispatchToProps = {
  dpLoading: value => loading(value),
  dpSetNamePage: value => setNamePage(value),
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePageProjectW);
