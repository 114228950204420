import * as actions from './globalSlice';
import * as documentsApis from '~api/documents';
import * as documentConstants from '~constants/documents';
import { loading } from '~features/loadingPage/services';
import { isProjectW } from '~core/method/authMethod';

export const setTermsAndConditions = params => {
  return dispatch => {
    {
      isProjectW() && dispatch(loading(true));
    }
    return new Promise((resolve, reject) => {
      documentsApis
        .getDocumentByType({ type: documentConstants.TERMS_AND_CONDITIONS })
        .then(res => {
          dispatch(actions.setTermsAndConditions(res));
          resolve(res);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          {
            isProjectW() && dispatch(loading(false));
          }
        });
    });
  };
};

export const setPrivacyAndPolicy = () => {
  return dispatch => {
    {
      isProjectW() && dispatch(loading(true));
    }

    return new Promise((resolve, reject) => {
      documentsApis
        .getDocumentByType({ type: documentConstants.PRIVACY_AND_POLICY })
        .then(res => {
          dispatch(actions.setPrivacyAndPolicy(res));
          resolve(res);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          {
            isProjectW() && dispatch(loading(false));
          }
        });
    });
  };
};

export const hideMenu = () => {
  return dispatch => {
    dispatch(actions.hideMenu());
  };
};

export const showMenu = () => {
  return dispatch => {
    dispatch(actions.showMenu());
  };
};

export const setIsHomePage = payload => {
  return dispatch => {
    dispatch(actions.setIsHomePage(payload));
  };
};

export const setIsWelcome = () => {
  return dispatch => {
    dispatch(actions.setIsWelcome());
  };
};

export const setWatchNowClick = () => {
  return dispatch => {
    dispatch(actions.setWatchNowClick());
  };
};

export const setNavigationContents = payload => {
  return dispatch => {
    dispatch(actions.setTargerPlayer(payload));
  };
};
export const setNamePage = () => {
  return dispatch => {
    dispatch(actions.setNamePage());
  };
};

export const setLoginModalShow = () => {
  return dispatch => {
    dispatch(actions.setLoginModalShow());
  };
};

export const setIsShowModal_Forgot = () => {
  return dispatch => {
    dispatch(actions.setIsShowModal_Forgot());
  };
};

export const setIsShowSignInForm = () => {
  return dispatch => {
    dispatch(actions.setIsShowSignInForm());
  };
};

export const setIsSuccess = () => {
  return dispatch => {
    dispatch(actions.setIsSuccess());
  };
};

export const setStatusRefund = () => {
  return dispatch => {
    dispatch(actions.setStatusRefund());
  };
};

export const toggleChatLive = value => {
  return dispatch => {
    dispatch(actions.toggleChatLive(value));
  };
};

export const setIsShowTrailer = () => {
  return dispatch => {
    dispatch(actions.setIsShowTrailer());
  };
};

export const setLiveEventStatus = () => {
  return dispatch => {
    dispatch(actions.setLiveEventStatus());
  };
};

export const setIsShowVerifyEmailModal = value => {
  return dispatch => {
    dispatch(actions.setIsShowVerifyEmailModal(value));
  };
};

export const setIsShowVerifyEmailPPV = value => {
  return dispatch => {
    dispatch(actions.setIsShowVerifyEmailPPV(value));
  };
};

export const setCheckVerifiedEmail = value => {
  return dispatch => {
    dispatch(actions.setCheckVerifiedEmail(value));
  };
};

export const setNewNamePage = value => {
  return dispatch => {
    dispatch(actions.setNewNamePage(value));
  };
};

export const setNicknameChatLive = value => {
  return dispatch => {
    dispatch(actions.setNicknameChatLive(value));
  };
};

export const setIsPageNotFound = value => {
  return dispatch => {
    dispatch(actions.setIsPageNotFound(value));
  };
};

export const setPricesView = value => {
  return dispatch => {
    dispatch(actions.setPricesView(value));
  };
};

export const setIsContentUnpublish = value => {
  return dispatch => {
    dispatch(actions.setIsContentUnpublish(value));
  };
};

export const setOpenModalPPV = value => {
  return dispatch => {
    dispatch(actions.setOpenModalPPV(value));
  };
};

export const setIsHomePageClient = value => {
  return dispatch => {
    dispatch(actions.setIsHomePageClient(value));
  };
};

export const setIsLoadingConfirmPPV = value => {
  return dispatch => {
    dispatch(actions.setIsLoadingConfirmPPV(value));
  };
};
