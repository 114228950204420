import BackButton from '../backButton';
import ReactDOM from 'react-dom';
import videojs from 'video.js';
import React from 'react';
import { CHANNEL_TYPE, CURATED_LIVE } from '../../../constants';
import { pushEventFirebase } from '../../../components/pushEventFirebase';

const vjsComponent = videojs.getComponent('Component');

class BackButtonTopNav extends vjsComponent {
  constructor(player, options) {
    super(player, options);

    /* Bind the current class context to the mount method */
    this.mount = this.mount.bind(this);

    /* When player is ready, call method to mount React component */
    player.ready(() => {
      this.mount();
    });

    /* Remove React root when component is destroyed */
    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }

  createEl() {
    const div = videojs.dom.createEl('div', {
      className: 'vjs--escondido-back',
    });
    return div;
  }

  handleClick = () => {
    const { entityDetail, backUrl } = this.options_.component;
    pushEventFirebase(entityDetail, 'Back');
    return this.options_.component.props.history.goBack();
  };

  mount() {
    const { component } = this.options_;

    ReactDOM.render(
      <BackButton
        component={component}
        player={this.player_}
        handleClick={this.handleClick}
        body="vjs-button-back"
      />,
      this.el(),
    );
  }
}

/**
 * Make sure to register the vjsComponent so Video JS knows it exists
 */
// var TopNavigation = videojs.extend(div, BackButton)

videojs.registerComponent('BackButton', BackButtonTopNav);
