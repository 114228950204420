import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckboxPolicy from './checkbox';

import { Button } from 'react-bootstrap';
import queryString from 'query-string';
import * as payperviewService from '../services';
import { setDataPaySubcription } from '../functions';
import { loading } from '~features/loadingPage/services';
import { getConfigByKey } from '~features/tenantConfig/services';
import { wFirebaseEvents } from '~services/analytics';
import { TENANT_NAME_ENUM } from '~core/constants';

const Loading = props => {
  return (
    <div className="paypal--loading">
      <CircularProgress />
    </div>
  );
};

const Paypal = props => {
  const { dpLoading = () => {} } = props;

  const {
    postSubscriptionsPayperview,
    t,
    location,
    state,
    setLoading = dpLoading,
    hasDescription = true,
    typePayment,
    submitTxt = t('subcriptions.paypal.btnComplete'),
    confirmPrivacy = false,
    confirmPaymentPayPal = false,
    idPrices,
    selectedPrice,
    pricePayment,
    userProfile,
    confirmPayment,
    priceFocus,
  } = props;

  let contentId = state.infoShow ? state.infoShow.id : state.entityDetail.id;
  const [checked, setChecked] = useState(confirmPrivacy);
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(true);

  useEffect(() => {
    let paramQuery = queryString.parse(location.search, { decode: false });
    let disabled = false;
    if (paramQuery && paramQuery.token) disabled = true;
    setStatus(false);
    setChecked(disabled);
  }, []);

  useEffect(() => {
    setChecked(confirmPrivacy);
  }, [confirmPrivacy]);

  const getLinkPaypal = () => {
    setMessage('');
    setStatus(true);
    setLoading(true);
    let data = {
      provider: 'paypal',
      contentId: contentId,
      tvod_type: priceFocus.type || undefined,
      price_id: priceFocus.id,
    };

    data = setDataPaySubcription(data);

    if (getConfigByKey('ui_theme') === TENANT_NAME_ENUM.PROJECTW) {
      const payloadFirebase = {
        content_id: contentId,
        email: userProfile.email,
        purchase_amount: priceFocus.price,
        currency_unit: priceFocus.currency,
        payment_method: data.payment_provider,
        purchase_item_name: priceFocus.name,
      };
      wFirebaseEvents.ppvPaymentStartedEvent(payloadFirebase);
    }
    postSubscriptionsPayperview(data)
      .then(res => {
        window.location.href = res.redirect_url;
      })
      .catch(err => {
        setMessage(err.message);
        setLoading(false);
      })
      .finally(() => {
        setStatus(false);
      });
  };

  return (
    <DivStyle className={`paypal ${getConfigByKey('ui_theme')}`}>
      <React.Fragment>
        {hasDescription && (
          <>
            <p
              className="paypal--title"
              dangerouslySetInnerHTML={{
                __html: t('subcriptions.paypal.txtTitlePaypal'),
              }}
            />
            <CheckboxPolicy {...props} setStatePolicy={{ setChecked, checked }} />
          </>
        )}

        {status.loading ? (
          <Loading />
        ) : (
          <Button
            id="linkPaypal"
            className={`project-w--watchmore paynow`}
            disabled={!confirmPayment}
            onClick={() => getLinkPaypal()}
          >
            {getConfigByKey('ui_theme') === 'projectw'
              ? t('projectw__PPV.paymentxt.payment_button')
              : submitTxt}
          </Button>
        )}
      </React.Fragment>
      {message && <p className="paypal--err">{message}</p>}
    </DivStyle>
  );
};

const mapStateToProps = state => ({
  userProfile: state.auth.account.profile,
});

const mapDispatchToProps = {
  postSubscriptionsPayperview: params => payperviewService.postSubscriptionsPayperview(params),
  dpLoading: value => loading(value),
};

const DivStyle = styled.div`
  &.projectw {
    .v-payment--submit {
      font-family: 'Neue Haas Grotesk Display Pro';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 133.5%;
      /* or 17px */

      text-align: center;
      letter-spacing: 0.025em;
      text-transform: uppercase;

      /* Light/gray/3 */

      color: #c7c7cc;
      background-color: #000000;
      width: 100%;
      padding: 16px;
    }
  }
  .paypal {
    &--title {
      font-size: 1rem;
      font-weight: 100;
      margin-bottom: 2rem;
      br {
        content: '';
        margin-bottom: 1rem;
        display: block;
        font-size: 1rem;
      }
    }
    &--loading {
      text-align: center;
    }
    &--btn {
      margin-left: 0;
    }
    &--err {
      color: #f23b46;
    }
  }
`;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(React.memo(Paypal))),
);
