var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OdxAuthenticationApi = void 0;
var P = require("../parser");
var integration_service_1 = require("../integration-service");
var utils_1 = require("../utils");
var mappers_1 = require("../mappers");
var parser_1 = require("../parser");
var OdxAuthenticationApi = /** @class */ (function (_super) {
    __extends(OdxAuthenticationApi, _super);
    function OdxAuthenticationApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OdxAuthenticationApi.prototype.forceLogout = function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
        throw new Error('Method not implemented.');
    };
    OdxAuthenticationApi.prototype.hybridProfileLogin = function (accountId, profileId, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var result, e_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.odxService.odxProfileApi.profileSignIn(profileId, undefined, {
                                pin: data.pin || '----',
                            })];
                    case 1:
                        result = _b.sent();
                        resolve(__assign(__assign({}, result), { data: (0, parser_1.ParseProfileLoginInfo)((_a = result.data) === null || _a === void 0 ? void 0 : _a.result) }));
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _b.sent();
                        reject((0, utils_1.transformError)(e_1));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxAuthenticationApi.prototype.listAvailableAvatars = function (accountId, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var result, e_2;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.odxService.odxProfileAvatarsApi.profileAvatarsList()];
                    case 1:
                        result = _c.sent();
                        resolve(__assign(__assign({}, result), { data: {
                                items: (_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.result) === null || _b === void 0 ? void 0 : _b.map(mappers_1.mapOdxProfileAvatarToHybridProfileAvatar),
                            } }));
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _c.sent();
                        reject((0, utils_1.transformError)(e_2));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxAuthenticationApi.prototype.createHybridProfile = function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var result, e_3;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.odxService.odxProfileApi.profileCreate(undefined, data)];
                    case 1:
                        result = _c.sent();
                        resolve(__assign(__assign({}, result), { data: (0, mappers_1.mapOdxProfileToAccountHybridProfile)((_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.result) === null || _b === void 0 ? void 0 : _b.profile) }));
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _c.sent();
                        reject((0, utils_1.transformError)(e_3));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxAuthenticationApi.prototype.deleteHybridProfile = function (accountId, profileId, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var result, e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.odxService.odxProfileApi.profileDelete(profileId)];
                    case 1:
                        result = _a.sent();
                        resolve(__assign(__assign({}, result), { data: null }));
                        return [3 /*break*/, 3];
                    case 2:
                        e_4 = _a.sent();
                        reject((0, utils_1.transformError)(e_4));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxAuthenticationApi.prototype.getHybridProfile = function (accountId, profileId, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var result, e_5;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.odxService.odxProfileApi.profileRetrieve()];
                    case 1:
                        result = _c.sent();
                        resolve(__assign(__assign({}, result), { data: (0, mappers_1.mapOdxProfileToAccountHybridProfile)((_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.result) === null || _b === void 0 ? void 0 : _b.profile) }));
                        return [3 /*break*/, 3];
                    case 2:
                        e_5 = _c.sent();
                        reject((0, utils_1.transformError)(e_5));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxAuthenticationApi.prototype.getHybridProfiles = function (accountId, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var result, e_6;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.odxService.odxProfileApi.profileList()];
                    case 1:
                        result = _c.sent();
                        resolve(__assign(__assign({}, result), { data: {
                                items: (_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.result) === null || _b === void 0 ? void 0 : _b.map(mappers_1.mapOdxProfileToAccountHybridProfile),
                                metadata: null,
                            } }));
                        return [3 /*break*/, 3];
                    case 2:
                        e_6 = _c.sent();
                        reject((0, utils_1.transformError)(e_6));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxAuthenticationApi.prototype.updateHybridProfile = function (accountId, profileId, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var result, e_7;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.odxService.odxProfileApi.profilePartialUpdate(profileId, undefined, data)];
                    case 1:
                        result = _c.sent();
                        resolve(__assign(__assign({}, result), { data: (0, mappers_1.mapOdxProfileToAccountHybridProfile)((_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.result) === null || _b === void 0 ? void 0 : _b.profile) }));
                        return [3 /*break*/, 3];
                    case 2:
                        e_7 = _c.sent();
                        reject((0, utils_1.transformError)(e_7));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxAuthenticationApi.prototype.activateLoginSession = function (tenantSlug, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.changeAccountPassword = function (data, authorization, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.changeMemberPassword = function (accountId, memberId, tenantSlug, loginUrl, data, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.changeUserPassword = function (tenantSlug, data, authorization, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.checkAccountPassword = function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.checkExistedEmail = function (data, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.createGuestAccount = function (tenantSlug, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.createHardwareLoginSession = function (tenantSlug, authorization, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.createSsoToken = function (id, tenantSlug, authorization, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.deleteAccountSession = function (accountId, sessionId, tenantSlug, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.forgetAccountPassword = function (data, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.forgetUserPassword = function (tenantSlug, data, authorization, acceptLanguage, options) {
        return this.odxService.odxUserResetPasswordApi
            .resetPasswordEmailPost(tenantSlug, { email: data.email }, undefined, acceptLanguage)
            .then(function (response) { return (__assign(__assign({}, response), { data: null })); });
    };
    OdxAuthenticationApi.prototype.forgotMemberPassword = function (resetPasswordUrl, data, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.getAccountSessions = function (accountId, tenantSlug, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.loginAdmin = function (data, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.loginCmsAccount = function (data, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.loginEmail = function (data, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.loginFacebook = function (data, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.loginGoogle = function (options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.loginKit = function (options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.loginWithAppleOs = function (tenantSlug, data, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.loginWithAppleOtherOs = function (tenantSlug, data, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.loginWithAuthorizationCode = function (data, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.loginWithFacebook = function (tenantSlug, data, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.loginWithGoogle = function (tenantSlug, data, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.loginWithHardwareId = function (tenantSlug, data, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.loginWithSsoToken = function (tenantSlug, authorization, ssoToken, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.logout = function (tenantSlug, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.refreshAuthorizationCode = function (authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.refreshUserToken = function (authorization, acceptLanguage, idempotencyKey, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.registerEmail = function (data, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.registerOrLogin = function (tenantSlug, data, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.resendConfirmationEmail = function (tenantSlug, data, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.resetAccountPassword = function (data, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.resetMemberPassword = function (tenantSlug, data, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.resetUserPassword = function (tenantSlug, data, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.sendEmailAccountConfirmation = function (id, tenantSlug, data, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.updateForgotPassword = function (options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.validateEmail = function (otp, email, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.loginWithEmail = function (tenantSlug, data, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var loginInfo, signInResult, e_8;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loginInfo = { email: data.email, password: data.password, keep: true };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.odxService.odxUserApi.signInPost(tenantSlug, loginInfo)];
                    case 2:
                        signInResult = _a.sent();
                        resolve(__assign(__assign({}, signInResult), { data: P.ParseOdxSignInResultToLoginInfo(signInResult.data.result) }));
                        return [3 /*break*/, 4];
                    case 3:
                        e_8 = _a.sent();
                        reject((0, utils_1.transformError)(e_8));
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxAuthenticationApi.prototype.loginWithPhoneOrEmail = function (tenantSlug, data, options) {
        var _this = this;
        var loginInfo = { email: data.username, password: data.password };
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var signInResult, e_9;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.odxService.odxUserApi.signInPost(tenantSlug, loginInfo)];
                    case 1:
                        signInResult = _a.sent();
                        resolve(__assign(__assign({}, signInResult), { data: P.ParseOdxSignInResultToLoginInfo(signInResult.data.result) }));
                        return [3 /*break*/, 3];
                    case 2:
                        e_9 = _a.sent();
                        reject((0, utils_1.transformError)(e_9));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxAuthenticationApi.prototype.registerEmailAccount = function (tenantSlug, data, authorization, acceptLanguage, registerType, options) {
        var _this = this;
        var info = { email: data.email, password: data.password };
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var signUpResult, e_10;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.odxService.odxUserApi.signUpPost(tenantSlug, info)];
                    case 1:
                        signUpResult = _a.sent();
                        resolve(__assign(__assign({}, signUpResult), { data: null }));
                        return [3 /*break*/, 3];
                    case 2:
                        e_10 = _a.sent();
                        reject((0, utils_1.transformError)(e_10));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxAuthenticationApi.prototype.createLoginSession = function (tenantSlug, sessionType, sessionParams, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var from, to, productCode, sessionResult, e_11;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        from = (0, utils_1.getValueOfKeyPairString)(sessionParams, 'from');
                        to = (0, utils_1.getValueOfKeyPairString)(sessionParams, 'to');
                        productCode = (0, utils_1.getValueOfKeyPairString)(sessionParams, 'product_code');
                        return [4 /*yield*/, this.odxService.odxUserQrLoginApi.qrLoginInitialize(tenantSlug, undefined, sessionType === 'sign-up-with-samsung-free-trial' ? 'samsung' : from !== null && from !== void 0 ? from : undefined, to, productCode)];
                    case 1:
                        sessionResult = _b.sent();
                        resolve(__assign(__assign({}, sessionResult), { data: P.ParseOdxQrInitializeToLoginSession((_a = sessionResult === null || sessionResult === void 0 ? void 0 : sessionResult.data) === null || _a === void 0 ? void 0 : _a.result) }));
                        return [3 /*break*/, 3];
                    case 2:
                        e_11 = _b.sent();
                        reject((0, utils_1.transformError)(e_11));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxAuthenticationApi.prototype.validateLoginSession = function (tenantSlug, code, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var sessionResult, apiKey, profileResult, e_12, e_13;
            var _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _e.trys.push([0, 6, 7, 8]);
                        return [4 /*yield*/, this.odxService.odxUserQrLoginApi.qrLoginCheck(tenantSlug, {
                                verify_code: code,
                            })];
                    case 1:
                        sessionResult = _e.sent();
                        apiKey = "ODX ".concat((_b = (_a = sessionResult === null || sessionResult === void 0 ? void 0 : sessionResult.data) === null || _a === void 0 ? void 0 : _a.result) === null || _b === void 0 ? void 0 : _b.token);
                        this.odxService.setApiKey({
                            Authorization: apiKey,
                            'Profile-Token': 'remove',
                        });
                        profileResult = void 0;
                        _e.label = 2;
                    case 2:
                        _e.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.odxService.odxUserApi.profileGet(tenantSlug)];
                    case 3:
                        profileResult = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_12 = _e.sent();
                        console.log(e_12);
                        return [3 /*break*/, 5];
                    case 5:
                        resolve(__assign(__assign({}, profileResult), { data: P.CombineQrCheckAndProfileToLoginInfo((_c = sessionResult === null || sessionResult === void 0 ? void 0 : sessionResult.data) === null || _c === void 0 ? void 0 : _c.result, (_d = profileResult === null || profileResult === void 0 ? void 0 : profileResult.data) === null || _d === void 0 ? void 0 : _d.result) }));
                        return [3 /*break*/, 8];
                    case 6:
                        e_13 = _e.sent();
                        reject((0, utils_1.transformError)(e_13));
                        return [3 /*break*/, 8];
                    case 7:
                        this.odxService.setApiKey('auto');
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxAuthenticationApi.prototype.loginCmsAccountManyTenants = function (data, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.validateHardwareLoginSession = function (tenantSlug, authorization, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.loginWithSocialNetwork = function (tenantSlug, snType, data, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAuthenticationApi.prototype.activateSsoLoginSession = function (tenantSlug, options) {
        return undefined;
    };
    OdxAuthenticationApi.prototype.createSsoLoginSession = function (tenantSlug, authorization, acceptLanguage, sessionType, sessionParams, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var from, to, productCode, sessionResult, e_14;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        from = (0, utils_1.getValueOfKeyPairString)(sessionParams, 'from');
                        to = (0, utils_1.getValueOfKeyPairString)(sessionParams, 'to');
                        productCode = (0, utils_1.getValueOfKeyPairString)(sessionParams, 'product_code');
                        return [4 /*yield*/, this.odxService.odxUserQrPassApi.qrPassInitialize(tenantSlug, undefined, sessionType === 'sign-up-with-samsung-free-trial' ? 'samsung' : from !== null && from !== void 0 ? from : undefined, to, productCode)];
                    case 1:
                        sessionResult = _b.sent();
                        resolve(__assign(__assign({}, sessionResult), { data: P.ParseOdxQrInitializeToLoginSession((_a = sessionResult === null || sessionResult === void 0 ? void 0 : sessionResult.data) === null || _a === void 0 ? void 0 : _a.result) }));
                        return [3 /*break*/, 3];
                    case 2:
                        e_14 = _b.sent();
                        reject((0, utils_1.transformError)(e_14));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxAuthenticationApi.prototype.validateSsoLoginSession = function (tenantSlug, code, authorization, acceptLanguage, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var sessionResult, e_15;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.odxService.odxUserQrPassApi.qrPassCheck(tenantSlug, {
                                verify_code: code,
                            })];
                    case 1:
                        sessionResult = _a.sent();
                        resolve(__assign(__assign({}, sessionResult), { data: null }));
                        return [3 /*break*/, 3];
                    case 2:
                        e_15 = _a.sent();
                        reject((0, utils_1.transformError)(e_15));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    return OdxAuthenticationApi;
}(integration_service_1.OdxIntegration));
exports.OdxAuthenticationApi = OdxAuthenticationApi;
