// Get the Component base class from Video.js
import videojs from 'video.js';
import jquery from 'jquery';
import { getConfigByKey } from '../../tenantConfig/services';
import { EPISODE_TYPE } from '../constants';

const Component = videojs.getComponent('Button');
// The videojs.extend function is used to assist with inheritance. In
// an ES6 environment, `class ListEps extends Component` would work
// identically.

const LinkEps = videojs.extend(Component, {
  // The constructor of a component receives two arguments: the
  // player it will be associated with and an object of options.
  constructor(player, options) {
    // It is important to invoke the superclass before anything else,
    // to get all the features of components out of the box!

    Component.apply(this, arguments);
    this.dataHref = options.item.id || '';
    // If a `text` option was passed in, update the text content of
    // the component.
    this.setAttribute('data-index', options.index);
  },

  // The `createEl` function of a component creates its DOM element.
  createEl() {
    const { item, entityDetail, component, index } = this.options_;

    const { account, t, match } = component.props;
    const { entityTrailer, isHasSubcription } = component.state;
    let vipbage = 'isVip';
    let active = 'deactive';
    if (
      isHasSubcription ||
      item.can_preview ||
      item.is_watchable ||
      (account && account.profile.has_subscription)
    ) {
      vipbage = 'notVip';
    }
    if (
      (item.trailer && entityTrailer && entityTrailer.id === item.id) ||
      (!entityTrailer && entityDetail.id === item.id)
    ) {
      active = 'active';
    }
    const btn = videojs.dom.createEl('button', {
      className: `vjs-control vjs-button vjs-list-eps ${active} `,
      id: item.id,
    });

    const isSctvTenant = getConfigByKey('tenant_slug') === 'sctv';
    const title = isSctvTenant
      ? t('laddingPage.episodes', { episodes: item.episode })
      : EPISODE_TYPE === item.type
      ? item.short_title || t('laddingPage.episodes', { episodes: item.episode })
      : item.title;
    btn.innerHTML = `
      <div class='vjs-list-container'>
        <span class='vjs-list-title-eps'>${title}</span>
        <div class='vjs-list-vipbage'>
          <span class=${vipbage}>
            <i class='fa fa-star'></i> Vip
          </span>
          <i class="fa fa-check" aria-hidden="true"></i>
        </div>
      </div>`;
    return btn;
  },

  handleClick(event) {
    event.stopPropagation();

    if (!this.dataHref) {
      return;
    }
    const { item } = this.options_;
    const { component } = this.options_;
    const { entityDetail } = component;
    const $this = jquery(this.el());
    const parentList = $this.parent();
    const { account, history, dispatchSetCurrentLink, language, match } = component.props;
    const { infoShow, isHasSubcription, isRestrictions, tvod } = component.state;

    const { state } = history.location;
    const { player_ } = component.videoPlayer;
    const regex = new RegExp('/:lg/((?:landing)|(?:detail))/:slug', 'g');
    const matchUrl = regex.exec(match.path || '');
    const page = matchUrl.length === 2 ? matchUrl[1] : 'detail';
    let search = '';
    let url = `/${language}/${page}/${item.slug}`;
    if (infoShow) {
      let episodeIndex = item.episode;
      if (episodeIndex < 10) {
        episodeIndex = `0${episodeIndex}`;
      }
      search = `?eps=${episodeIndex}`;
    }
    if (item.trailer) {
      url = `/${language}/${page}/${entityDetail.slug}`;
      search = `?trailer=${item.slug}`;
    }

    if (isHasSubcription || item.can_preview || item.is_watchable) {
      parentList.find('.active').removeClass('active').addClass('deactive');
      $this.addClass('active');
      // component._asyncGetVideo(item.id)
      history.replace({
        pathname: url,
        search,
        state: {
          ...state,
          programSlug: infoShow ? infoShow.slug : entityDetail.slug,
        },
      });
    } else {
      if (getConfigByKey('features.svodAppFeature')) {
        return component.props.dpSetOpenSvodApp(true);
      }
      if (player_.isFullscreen()) {
        player_.exitFullscreen();
      }
      if (component.videoPlayer) {
        const player = component.videoPlayer;
        player.pause();
      }
      dispatchSetCurrentLink({ url, isRestrictions });
      return component.setState({
        popupUpgrade: true,
        entity: item,
        redirect: url,
      });
    }
  },
});

// Register the component with Video.js, so it can be used in players.
videojs.registerComponent('LinkEps', LinkEps);
