import React, { useRef, useState } from 'react';
import { FormControlLabel, Radio, Checkbox, Typography } from '@material-ui/core';
import CheckboxPolicy from '../checkbox';
import TermOfUse from '~features/box/components/termOfUsePopup';
import PrivacyPolicy from '~features/box/components/privacyPolicyPopup';
import { getConfigByKey } from '~features/tenantConfig/services';
import { isPPV, isNewPPV__NoCache } from '~core/method/contentMethod';
import { useTranslation } from 'react-i18next';
import { LazyImage } from '~components';
import { wFirebaseEvents } from '~services/analytics';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { BOOTPAY_METHOD_ENUM } from '~core/constants/bootpay';
import { Divider } from '@material-ui/core';
import { AVAILABLE_LOCALES } from '~i18n';

const PPV_BOOTPAY = 'bootpay';
const PPV_PAYPAL = 'paypal';
const CONFIRM = 3;
const ALL = 0;
const privacyPolicyLink = getConfigByKey('privacyPolicyLink');
const termsOfUseLink = getConfigByKey('termsOfUseLink');

function PaymentMethod(props) {
  const {
    setConfirmPayment,
    confirmPayment,
    paymentCountry: pg,
    setPaymentCountry,
    content = {},
    packageEarly,
    packageBig3,
    confirmPaymentPayPal,
    setConfirmPaymentPayPal,
    pricesTickContentView = [],
    pricePayment,
    idPrices,
    selectedPrice,
    priceFocus,
    buttonDisplayType = true,
    bootpayMethod,
    setBootpayMethod,
    scrollToEndElement,
  } = props;

  const { t, i18n } = useTranslation();
  const profile = useSelector(
    state => !_.isEmpty(state.auth.account) && state.auth.account.profile,
  );

  const confirmPaymentRef = useRef(false);

  const activPayPal = pg === PPV_PAYPAL;
  const activeBootPay = pg === PPV_BOOTPAY;

  React.useEffect(() => {
    if (confirmPaymentRef.current === true || !confirmPayment) {
      return;
    }
    confirmPaymentRef.current = true;
    const payloadFireBase = {
      content_id: content.id,
      email: profile.email,
      purchase_amount: priceFocus.price,
      currency_unit: priceFocus.currency,
      purchase_item_name: priceFocus.name,
      payment_method: pg,
    };
    wFirebaseEvents.ppvPaymentAgreementAccepted(payloadFireBase);
  }, [confirmPayment]);

  React.useEffect(() => {
    setConfirmPayment(false);
  }, [pg]);

  const handleActivePaypal = e => {
    setPaymentCountry(PPV_PAYPAL);
    setConfirmPayment(false);
    const payloadFireBase = {
      content_id: content.id,
      email: profile.email,
      purchase_amount: priceFocus.price,
      currency_unit: priceFocus.currency,
      purchase_item_name: priceFocus.name,
      payment_method: PPV_PAYPAL,
    };
    wFirebaseEvents.ppvPaymentMethodSelected(payloadFireBase);
  };

  const handleActiveBootPay = e => {
    setPaymentCountry(PPV_BOOTPAY);
    setConfirmPaymentPayPal(false);
    const payloadFireBase = {
      content_id: content.id,
      email: profile.email,
      purchase_amount: priceFocus.price,
      currency_unit: priceFocus.currency,
      purchase_item_name: priceFocus.name,
      payment_method: PPV_BOOTPAY,
    };
    wFirebaseEvents.ppvPaymentMethodSelected(payloadFireBase);
  };

  const handleClickPaymentPGMethod = (pg, bootpayMethod = '') => {
    try {
      setPaymentCountry(pg);
      setConfirmPaymentPayPal(false);
      setConfirmPayment(false);
      setBootpayMethod(bootpayMethod);
      scrollToEndElement();
      const payloadFireBase = {
        content_id: content.id,
        email: profile.email,
        purchase_amount: priceFocus.price,
        currency_unit: priceFocus.currency,
        purchase_item_name: priceFocus.name,
        payment_method: pg,
      };
      wFirebaseEvents.ppvPaymentMethodSelected(payloadFireBase);
    } catch (error) {}
  };

  if (!isNewPPV__NoCache.call(pricesTickContentView)) {
    return null;
  }

  const handleFunctionConfirmPayment = () => {
    setConfirmPayment(!confirmPayment);
  };

  const renderPrivacyAndTerms = (lang = 'en') => {
    return (
      <div className="privacy__terms">
        {lang === 'ko' ? '' : t('projectw__PPV.agreement')}
        {` `}
        <span
          style={{
            borderBottom: '1px solid #000',
            cursor: 'pointer',
          }}
        >
          <a className="disable__textdecoration" target="_blank" href={`/${lang}/privacy-policy`}>
            {t('projectw__PPV.privacy policy')}
          </a>
        </span>
        {` ${t('projectw__PPV.and')} `}
        <br />
        <span
          style={{
            borderBottom: '1px solid #000',
            cursor: 'pointer',
          }}
        >
          <a className="disable__textdecoration" target="_blank" href={`/${lang}/terms-of-service`}>
            {t('projectw__PPV.term of service')}
          </a>
        </span>
        {lang === 'ko' ? ` ${t('projectw__PPV.agreement')}` : ''}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="select__payment">
        {buttonDisplayType ? (
          <div>
            <Divider />

            <section className="ppv-payments">
              <label className="ppv-payments--label">{t('projectw__PPV.payment')}:</label>
              <div className="ppv-payments__pg">
                <button
                  className={`ppv-btn ${
                    bootpayMethod === BOOTPAY_METHOD_ENUM.CARD ? 'active' : ''
                  }`}
                  onClick={() => handleClickPaymentPGMethod(PPV_BOOTPAY, BOOTPAY_METHOD_ENUM.CARD)}
                >
                  {t('projectw__PPV.credit card')}
                </button>
                <button
                  className={`ppv-btn ${
                    bootpayMethod === BOOTPAY_METHOD_ENUM.NAVERPAY ? 'active' : ''
                  }`}
                  onClick={() =>
                    handleClickPaymentPGMethod(PPV_BOOTPAY, BOOTPAY_METHOD_ENUM.NAVERPAY)
                  }
                >
                  {t('projectw__PPV.naver pay')}
                </button>
                <button
                  className={`ppv-btn ${
                    bootpayMethod === BOOTPAY_METHOD_ENUM.KAKAOPAY ? 'active' : ''
                  }`}
                  onClick={() =>
                    handleClickPaymentPGMethod(PPV_BOOTPAY, BOOTPAY_METHOD_ENUM.KAKAOPAY)
                  }
                >
                  {t('projectw__PPV.kakao pay')}
                </button>
                <button
                  className={`ppv-btn ${pg === PPV_PAYPAL ? 'active' : ''}`}
                  onClick={() => handleClickPaymentPGMethod(PPV_PAYPAL)}
                >
                  <LazyImage
                    alt=""
                    className="selected--img"
                    src={require('~img/img/paypal.png').default}
                  />
                </button>
              </div>
            </section>
          </div>
        ) : (
          <section>
            <p className="ppv__body__gateway__title">
              {t('projectw__PPV.paymentxt.txtSelectPayment')}
            </p>
            <div>
              <div className="selected">
                <Radio
                  name="payment-method"
                  checked={pg === PPV_BOOTPAY}
                  value={PPV_BOOTPAY}
                  onChange={e => handleActiveBootPay(e)}
                />
                <div>
                  <LazyImage
                    alt=""
                    className="selected--img"
                    src={require('~img/img/projectw/bootpay-white.png').default}
                  />
                </div>
              </div>
              <Typography className="notedPayment" component="p">
                {t('projectw__PPV.paymentxt.txtDesPaymentInternational')}
              </Typography>
            </div>
            <div>
              <div className="selected">
                <Radio
                  name="payment-method"
                  checked={pg === PPV_PAYPAL}
                  value={PPV_PAYPAL}
                  onChange={e => handleActivePaypal(e)}
                />
                <div>
                  <LazyImage
                    alt=""
                    className="selected--img"
                    src={require('~img/img/paypal.png').default}
                  />
                </div>
              </div>
              <Typography className="notedPayment" component="p">
                {t('projectw__PPV.paymentxt.txtDesPaymentInKorean')}
              </Typography>
            </div>
          </section>
        )}
      </div>
      <React.Fragment>
        {activPayPal && (
          <React.Fragment>
            {/* <div className="description__payment">
              <p className="txt">{t('projectw__PPV.paymentxt.paypal_des.ss1')}</p>
              <p className="txt">{t('projectw__PPV.paymentxt.paypal_des.ss2')}</p>
            </div> */}
            <div className="confirm__privacy">
              <FormControlLabel
                control={
                  <Checkbox checked={confirmPayment} onChange={handleFunctionConfirmPayment} />
                }
                label={renderPrivacyAndTerms(i18n.language)}
              />
            </div>
          </React.Fragment>
        )}
        {activeBootPay && (
          <React.Fragment>
            {/* <div className="description__payment">
              <p className="txt">{t('projectw__PPV.paymentxt.bootpay_des.ss1')}</p>
              <p className="txt">{t('projectw__PPV.paymentxt.bootpay_des.ss2')}</p>
            </div> */}
            <div className="confirm__privacy">
              <FormControlLabel
                control={
                  <Checkbox checked={confirmPayment} onChange={handleFunctionConfirmPayment} />
                }
                label={renderPrivacyAndTerms(i18n.language)}
              />
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}

export default PaymentMethod;
