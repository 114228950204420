import React from 'react';
import { RibbonsItem } from '~features/ribbon/views/projectw/style/index';
import _ from 'lodash';
import { ListUpMobile } from '~features/homepage/projectw/style/homepage';
import { RecordContentMobile } from '..';

function RibbonMobile(props) {
  const { vodlist, titleRibbon } = props;
  if (vodlist) {
    return (
      <ListUpMobile className="isMobile">
        {titleRibbon &&
          titleRibbon.map(
            (ribbon, index) =>
              index === 0 &&
              ribbon.items.map((item, index) => (
                <RecordContentMobile {...props} item={item} index={index} />
              )),
          )}
      </ListUpMobile>
    );
  }
  return (
    <ListUpMobile className="isMobile">
      {!_.isEmpty(titleRibbon) &&
        titleRibbon.map((item, index) => (
          <RecordContentMobile {...props} item={item} index={index} />
        ))}
    </ListUpMobile>
  );
}

export default RibbonMobile;
