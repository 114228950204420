Object.defineProperty(exports, "__esModule", { value: true });
exports.ParseListMenuItemDetail = void 0;
var factory_1 = require("../factory");
var utils_1 = require("../utils");
var constants_1 = require("../constants");
var integration_service_1 = require("../integration-service");
var ParseListMenuItemDetail = function (response) {
    var tvGuidePage = (0, factory_1.createTvGuidePage)();
    var homePage = (0, factory_1.createHomePage)();
    var tvShow = (0, factory_1.createTvShowNavigation)();
    var mainMenuItems = response.contents.filter(function (x) { return ['movies', 'odk-channels'].includes(x.slug); }) || [];
    mainMenuItems.unshift(tvShow);
    var tvShowSubItems = response.contents.filter(function (x) {
        return !['movies', 'odk-channels', 'odk-shop', 'odk-shops', 'tv-guide', 'tv-guides'].includes(x.slug) &&
            x.type === 'category';
    }) || [];
    var tenantPages = mainMenuItems.map(function (menu) {
        var subItems = menu.slug === constants_1.ODXKey.tvShowsSlug
            ? tvShowSubItems.map(function (m) { return ({
                id: constants_1.ODXKey.defaultUnknown,
                slug: constants_1.ODXKey.defaultUnknown,
                type: constants_1.RibbonType.DATA_SOURCE,
                name: m.title,
                data_source: 'tenant_page',
                data_source_id: m.slug,
            }); })
            : [];
        // https://pm.youthdev.net/issues/49303
        // Remove home-shopping and replace with odk-original
        var homeShoppingIndex = subItems.findIndex(function (i) { return i.data_source_id === 'home-shopping'; });
        var odkOriginalIndex = subItems.findIndex(function (i) { return i.data_source_id === 'odk-original'; });
        if (homeShoppingIndex >= 0 && odkOriginalIndex >= 0) {
            var tmp = subItems[homeShoppingIndex];
            subItems[homeShoppingIndex] = subItems[odkOriginalIndex];
            subItems[odkOriginalIndex] = tmp;
        }
        var newHomeShoppingIndex = subItems.findIndex(function (i) { return i.data_source_id === 'home-shopping'; });
        if (newHomeShoppingIndex >= 0) {
            subItems.splice(newHomeShoppingIndex, 1);
        }
        // https://pm.youthdev.net/issues/50546
        // Change ODK-Channels name
        var menuTitle = menu.title;
        if (menu.slug === 'odk-channels') {
            var currentLanguage = integration_service_1.OdxIntegration.currentProvider.get('acceptLanguage');
            if (currentLanguage === 'en') {
                menuTitle = 'Live TV';
            }
            else {
                menuTitle = '라이브 TV';
            }
        }
        return {
            sub_menu: [],
            banner_style: 'HOME_PAGE_STYLE',
            name: menuTitle,
            slug: menu.slug,
            id: menu.slug,
            sub_items: subItems,
            display_style: (0, utils_1.getPageDisplayStyleFromNavigationItem)(menu),
            page_options: {
                content_navigation_option: 'default',
                contain_sub_item: subItems.length > 0,
            },
        };
    });
    tenantPages.unshift(homePage);
    tenantPages.unshift(tvGuidePage);
    // https://pm.youthdev.net/issues/50546
    // Change position of odk-channels to above home
    var odkChannelIndex = tenantPages.findIndex(function (m) { return m.slug === 'odk-channels'; });
    var homePageIndex = tenantPages.findIndex(function (m) { return m.slug === constants_1.ODXKey.homePageSlug; });
    if (odkChannelIndex >= 0 && homePageIndex >= 0) {
        (0, utils_1.arrEleMove)(tenantPages, odkChannelIndex, homePageIndex);
    }
    return tenantPages;
};
exports.ParseListMenuItemDetail = ParseListMenuItemDetail;
