import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { ModalStyled } from '../styles/styled';
import Register from '~features/box/pages/subscriptionPlanSign/views/register';

const RegisterPopup = props => {
  const { param, hideModal } = props;
  const [hide, setHide] = useState(false);

  useEffect(() => {
    setHide(false);
  }, []);
  const hideModalUpgrade = () => {
    setHide(true);
    if (!param) return hideModal();
    param.method.hideModal();
  };
  return (
    <ModalStyled
      show={!hide}
      onHide={() => hideModalUpgrade()}
      aria-labelledby="contained-modal-title-vcenter"
      className="payperview-register"
      bsSize="large"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <Register popup {...props} setHideRegister={setHide} />
      </Modal.Body>
    </ModalStyled>
  );
};

const mapStateToProps = state => ({
  account: state.auth.account,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RegisterPopup)),
);
