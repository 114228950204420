import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { loading } from '~features/loadingPage/services';
import { withTranslation } from 'react-i18next';
import { setUserProperties } from '~root/services/analytics/SetUserProperties';
import { RowStyled } from '../../homepage/views/styled';
import { getSlugMatch } from '../../../utils/slug';
import LiveSchedule from '../components/liveSchedule';
import PlayerPage from '~features/detailpage/views/playerPage';
import { LiveStyled } from '../styles';
import getSlugEpgs from '../../homepage/functions/getCurrentEpg';
import { getLiveSchedules, updateCurrentLive } from '../services';
import WrapRibbon from '~features/homepage/components/wrapRibbon';
import OverlayLoading from '~components/loading/overlayLoading';
import { isMobile } from 'react-device-detect';

const FilterContent = React.lazy(() => import('~components/filter/filter'));

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentId: null,
      liveMobie: window.innerWidth >= 1024 ? false : true,
      initFitter: true,
    };
  }

  componentDidMount() {
    setUserProperties();
    const { currentLive, dpUpdateCurrentLive } = this.props;
    window.document.body.classList.add('v-homePage', 'v-livePage');
    this._getFilterData().then(res => {
      let detailCurrentLive = getSlugEpgs(this.props.schedule, null);
      this.setState({
        contentId: currentLive.channelId || (detailCurrentLive && detailCurrentLive.programSlug),
      });
      if (currentLive && !currentLive.channelId && detailCurrentLive) {
        let params = {
          liveId: detailCurrentLive.detail.id,
          rewatchId: false,
          channelId: detailCurrentLive.programSlug,
          detail: detailCurrentLive.detail,
        };
        dpUpdateCurrentLive(params);
      }
    });
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
    window.document.body.classList.remove('v-homePage', 'v-livePage');
    window.removeEventListener('resize', this.updateDimensions);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.isLoadingPage !== nextProps.isLoadingPage ||
      this.props.currentLive !== nextProps.currentLive
    ) {
      this.setState(
        {
          contentId: null,
        },
        () => {
          this.setState({
            contentId:
              nextProps.currentLive.channelId ||
              (getSlugEpgs(nextProps.schedule, null) &&
                getSlugEpgs(nextProps.schedule, null).programSlug),
          });
        },
      );
    }
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this._getFilterData();
    }
  }

  updateDimensions = () => {
    const { liveMobie } = this.state;
    let innerWidth = window.innerWidth;
    if (innerWidth >= 1024 && liveMobie) {
      return this.setState({
        liveMobie: false,
        initFitter: false,
      });
    }
    if (innerWidth < 1024 && !liveMobie) {
      this.setState({
        liveMobie: true,
        initFitter: false,
      });
    }
  };
  _getFilterData() {
    const { dispatchLoadingPage } = this.props;
    const menuItem = this._getMenuItem(getSlugMatch(this.nextProps, this.props));
    const slug = menuItem && menuItem.slug;
    return new Promise((resolve, reject) => {
      this.props
        .getLiveSchedules(slug)
        .then(() => {
          resolve(true);
        })
        .catch(() => {})
        .finally(() => {
          dispatchLoadingPage(false);
        });
    });
  }

  _getMenuItem = (slug = '/') => {
    let result = null;
    const { menu } = this.props;

    if (!Array.isArray(menu) || menu.length === 0) return result;
    if (slug === '/') {
      result = menu[0];
    } else {
      menu.some(menuItem => {
        if (slug === menuItem.slug) {
          result = menuItem;
          return true;
        }
      });
    }
    this.menuActive = result;
    return result;
  };

  render() {
    const { currentLive, auth, isLoadingPage, pageLive } = this.props;
    const { contentId, liveMobie, initFitter } = this.state;
    if (isMobile && liveMobie)
      return (
        <RowStyled className="v-home" props={{ isHasSubcription: auth.isHasSubcription }}>
          {isLoadingPage ? <OverlayLoading /> : null}
          <div className="v-home__container">
            <WrapRibbon ribbon={(pageLive && pageLive.ribbons) || []} livePage />
          </div>
          {initFitter && <FilterContent filterTenant hiddenFilter />}
        </RowStyled>
      );
    return (
      <LiveStyled className="livePage">
        <div className="livePage__gradient" />
        <RowStyled className="v-home" props={{ isHasSubcription: auth.isHasSubcription }}>
          <div className="v-home__container livePage__schedule">
            <LiveSchedule />
          </div>
        </RowStyled>
        {contentId && !isLoadingPage && (
          <PlayerPage
            contentId={contentId}
            rewatchId={currentLive.rewatchId}
            disableControl
            className="livePage__player"
          />
        )}
        {initFitter && <FilterContent filterTenant />}
      </LiveStyled>
    );
  }
}

HomePage.propTypes = {
  menu: PropTypes.any,
  isLoadingPage: PropTypes.any,
};

HomePage.serverFetch = [];

const mapStateToProps = state => ({
  menu: state.home.menu,
  isLoadingPage: state.home.isLoadingPage,
  auth: state.auth,
  schedule: state.liveSchedule.schedule,
  currentLive: state.liveSchedule.currentLive,
  pageLive: state.liveSchedule.page,
});

const mapDispatchToProps = {
  getLiveSchedules: pageSlug => getLiveSchedules(pageSlug),
  dispatchLoadingPage: value => loading(value),
  dpUpdateCurrentLive: params => updateCurrentLive(params),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HomePage)),
);
