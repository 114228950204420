import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { ModalStyled } from './../views/styled';
import { Modal, Button, Tooltip } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { getDeepLink } from '../services';
import queryString from 'query-string';
import { getConfigByKey } from '../../tenantConfig/services';

const PopupShare = props => {
  const { setOpen, open, entityDetail, t, dpGetDeepLink, target, entityTrailer, pageVod } = props;
  const [isTooltip, setTooltip] = useState(false);
  const [deepLink, setDeepLink] = useState(window.location.href);
  const txtUrl =
    pageVod && target === 'landing'
      ? `${window.location.origin}/landing/${entityDetail.slug || entityDetail.id}`
      : window.location.href;

  React.useEffect(() => {
    let id = entityDetail.id;
    let params = queryString.parse(props.location.search);
    if (!getConfigByKey('features.deep_link')) return;
    if (
      (!entityTrailer && params && params.trailer) ||
      (entityTrailer && params && !params.trailer)
    )
      return;
    if (entityDetail.entityDetail) id = entityDetail.entityDetail.id;
    if (entityTrailer && params.trailer) id = entityTrailer.id;
    if (!open) return setDeepLink(txtUrl);
    dpGetDeepLink({
      content_id: id,
      target,
      web_redirect: txtUrl,
    })
      .then(res => {
        const { deep_link } = res;
        setDeepLink(deep_link || '');
      })
      .catch(() => {});
  }, [entityTrailer, open]);

  const onCopy = () => {
    setTooltip(true);
    setTimeout(() => {
      setTooltip(false);
    }, 3000);
  };

  return (
    <ModalStyled
      show={open}
      onHide={() => setOpen(false)}
      aria-labelledby="contained-modal-title-vcenter"
      className="popup-share"
    >
      <Modal.Header closeButton>
        <div className="popup-share-thumbnail">
          <img src={entityDetail.images.backdrop || entityDetail.images.thumbnail} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <p className="popup-share--title">{entityDetail.title}</p>
        <p className="popup-share--description">{entityDetail.long_description}</p>
        <div className="popup-share__wrapClipboard">
          <p className="popup-share__wrapClipboard--label">{t('share.txtShare')}</p>
          <Tooltip
            placement="top"
            className={`popup-share__wrapClipboard--tooltip ${isTooltip && 'in'}`}
            id="tooltip-top"
          >
            {t('share.txtCopied')}
          </Tooltip>
          <div className="popup-share__wrapClipboard--copy">
            <span>{deepLink}</span>
            <CopyToClipboard text={deepLink}>
              <Button className="popup-share__wrapClipboard--btn" onClick={() => onCopy()}>
                <i className="fa fa-clone" aria-hidden="true" />
                {t('share.txtCopy')}
              </Button>
            </CopyToClipboard>
          </div>
        </div>
        <div className="popup-share__wrap-share">
          <FacebookShareButton
            className="fb-share-button popup-share--btn"
            url={deepLink}
            onClick={() => setOpen(false)}
          >
            <div className="popup-share__wrap-share--logo-fb" />
            <span>FaceBook</span>
          </FacebookShareButton>
          <TwitterShareButton
            className="twitter-share-button popup-share--btn"
            url={deepLink}
            onClick={() => setOpen(false)}
          >
            <div className="popup-share__wrap-share--logo-twitter" />
            <span>Twitter</span>
          </TwitterShareButton>
          {/* <div className="fb-share-button"
data-href="https://rc.ondemandviet.com/landing/ai-uong-du-hiep-truyen-10b08d98"
data-layout="button_count">
            FaceBook
</div> */}
          {/* <a className="btn-floating btn btn-tw" type="button" role="button"
   href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fparse.com"
   rel="noopener">
                 <div className='popup-share__wrap-share--logo-twitter' />
            Twitter
  <i className="fab fa-2x fa-twitter"></i>
</a> */}
        </div>
      </Modal.Body>
    </ModalStyled>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  dpGetDeepLink: params => getDeepLink(params),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PopupShare)),
);
