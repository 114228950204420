import * as actions from './actions';
import * as appAPIs from '../../api/appAPIs';
import pushAnalytics from './../../services/analytics/Analytics';
import * as apiLandding from '../../api/landdingPageApi';
import * as contentApi from '~api/content';
import * as reviews from '~api/review';
import * as apiDetail from '../../api/detailPage';
import { getConfigByKey } from '~features/tenantConfig/services';
import { TENANT_NAME_ENUM } from '~core/constants';
import { CONTENT_TYPE } from '~core/constants';
import * as accountApi from '~api/account';
import {
  setIsPageNotFound,
  setPricesView,
  setIsContentUnpublish,
} from '~components/global/globalSlice';

export const ERRCODE_UNPUBLISH = 'unpublished_content_error';
export const ERRCODE_DOMAIN = 'domain_exception';

export { getRelate, postFavorite, getDetailView, getDetailNew, getSeasonNew, getDetailNewApi };

function getRelate(id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_relate(id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function postFavorite(entityDetail) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!entityDetail) {
        return resolve(null);
      }
      const { slug, category_slug, provider_slug } = entityDetail;
      pushAnalytics('click', {
        content_type: 'button',
        item_name: 'add to list',
        program_name: slug,
        content_provider: provider_slug,
        category: category_slug,
      });
      appAPIs
        .postFavorite(slug)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getDetailNew(id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiLandding
        .getDetail(id)
        .then(response => {
          dispatch(actions.updateDetailLandingPage(response));
          // dispatch(actions.getDetail(response))
          // dispatch(actions.reinitLandingPage(response))
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getDetailView(id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiLandding
        .viewDetail(id)
        .then(response => {
          dispatch(actions.updateDetailLandingPage(response));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getSeasonNew(id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiLandding
        .getSeason(id)
        .then(response => {
          dispatch(actions.updateSeasonLandingPage(response));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getViewBlockCountry(slug) {
  return new Promise((resolve, reject) => {
    const promises = [
      new Promise((resolve, reject) => {
        apiLandding
          .viewDetail(slug)
          .then(response => {
            // dispatch(actions.reinitLandingPage(response))
            resolve(response);
          })
          .catch(error => {
            if (error.error_code === 'domain_exception') {
              resolve({ isGeoBlocked: true, result: error });
            }
            reject(error);
          });
      }),
    ];
    return Promise.all(promises)
      .then(response => {
        const [resViewDetail, resBlockCountry] = response;
        resolve({
          ...resViewDetail,
          ...resBlockCountry,
        });
      })
      .catch(error => {
        reject(error);
      });
  });
}

function getDetailNewApi(slug) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const promises = [
        getViewBlockCountry(slug),
        new Promise((resolve, reject) => {
          apiLandding
            .getDetail(slug)
            .then(async response => {
              // if (
              //   getConfigByKey('ui_theme') === TENANT_NAME_ENUM.PROJECTW &&
              //   response.type === CONTENT_TYPE.EPISODE_TYPE
              // ) {
              //   const show = await reviews.getProgramShow(response.id)
              //   const showView = await apiLandding.viewDetail(show.id)
              //   response.show = showView
              // }
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        }),
      ];
      return Promise.all(promises)
        .then(data => {
          let result = {};
          data.map((value, index) => {
            if (index > 0 && value.show_info) {
              const { payment_infors, refund, ...showInfo } = value.show_info;
              delete value.show_info;
              result.show_info = { ...result.show_info, ...showInfo };
            }
            if (index === 0) {
              const { prices = [] } = value;
              dispatch(setPricesView(prices || []));
            }
            Object.assign(result, value);
          });
          // dispatch(actions.getDetail(result))
          dispatch(actions.updateDetailLandingPage(result));
          dispatch(setIsPageNotFound(false));
          resolve(result);
        })
        .catch(error => {
          dispatch(actions.updateDetailLandingPage({}));
          dispatch(setPricesView([]));
          if (error && error.error_code === ERRCODE_DOMAIN) {
            dispatch(setIsPageNotFound(true));
          }
          if (error && error.error_code === ERRCODE_UNPUBLISH) {
            dispatch(setIsContentUnpublish(true));
          } else {
            dispatch(setIsContentUnpublish(false));
          }
          reject(error);
        });
    });
  };
}

export function getPickRibbonRelative(id, queryParams = {}) {
  return dispatch => {
    dispatch(actions.fetchPickRibbonRelative({}));
    return new Promise((resolve, reject) => {
      apiLandding
        .getPickRibbonRelative(id, queryParams)
        .then(response => {
          dispatch(actions.fetchPickRibbonRelative(response));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

export const getViewContentsByIds = (ids = []) => {
  return new Promise((resolve, reject) => {
    contentApi
      .getViewContentsByIds(ids)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getReviews = (params = {}) => {
  return new Promise((resolve, reject) => {
    reviews
      .getReviews(params)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export function getProgramShow(contentId = '') {
  return new Promise((resolve, reject) => {
    reviews
      .getProgramShow(contentId)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function resetData() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(actions.resetData());
      resolve(true);
    });
  };
}

export function chatUserInfor() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      accountApi
        .chatUserInfor()
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
  };
}

export function channelInfo(contentId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      contentApi
        .channelInfo(contentId)
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
  };
}

export function getCurrentTimeBE(contentId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiLandding
        .getCurrentTimeLiveEvent(contentId)
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
  };
}
