Object.defineProperty(exports, "__esModule", { value: true });
exports.CacheableRequests = void 0;
// @ts-
var integration_service_1 = require("../integration-service");
var cacheable_1 = require("../utils/cacheable");
var utils_1 = require("../utils");
var Tuple = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return args;
};
var Refresher = /** @class */ (function () {
    function Refresher() {
    }
    Refresher.getProgramInformation = function (slug, tenantSlug, language) {
        return integration_service_1.OdxIntegration.currentService.odxProgramApi.programRetrieve(slug, tenantSlug, undefined, language);
    };
    Refresher.getProgramTrailers = function (contentSlug, tenantSlug, language) {
        return integration_service_1.OdxIntegration.currentService.odxProgramApi.programEpisodesList(tenantSlug, contentSlug, language, undefined, ['trailer'], 1, 100);
    };
    Refresher.getProgramEpisodes = function (programSlug, tenantSlug, language, page, limit) {
        return integration_service_1.OdxIntegration.currentService.odxProgramApi.programEpisodesList(tenantSlug, programSlug, language, undefined, ['series'], page, limit);
    };
    Refresher.getEpisodeInformation = function (episodeId, tenantSlug, language) {
        return integration_service_1.OdxIntegration.currentService.odxEpisodeApi.episodeRetrieve(episodeId.toString(), tenantSlug, undefined, language);
    };
    Refresher.getEpisodePlayback = function (episodeId, tenantSlug, language) {
        var xAdParams = integration_service_1.OdxIntegration.currentProvider.get('adParams');
        var isLat = (0, utils_1.getValueOfKeyPairString)(xAdParams, 'is_lat');
        var did = (0, utils_1.getValueOfKeyPairString)(xAdParams, 'did');
        var adid = (0, utils_1.getValueOfKeyPairString)(xAdParams, 'adid');
        return integration_service_1.OdxIntegration.currentService.odxPlaybackApi.playbackRetrieve(episodeId === null || episodeId === void 0 ? void 0 : episodeId.toString(), tenantSlug, undefined, language, isLat ? decodeURIComponent(isLat) : undefined, did ? decodeURIComponent(did) : undefined, adid ? decodeURIComponent(adid) : undefined);
    };
    return Refresher;
}());
var CacheableRequests = /** @class */ (function () {
    function CacheableRequests() {
    }
    CacheableRequests.getProgramInformation = function (slug, tenantSlug, language) {
        var args = Tuple(slug, tenantSlug, language);
        return (0, cacheable_1.observeCache)('getProgramInformation', args, function () { return Refresher.getProgramInformation.apply(Refresher, args); });
    };
    CacheableRequests.getProgramTrailers = function (contentSlug, tenantSlug, language) {
        var args = Tuple(contentSlug, tenantSlug, language);
        return (0, cacheable_1.observeCache)('getProgramTrailers', args, function () { return Refresher.getProgramTrailers.apply(Refresher, args); });
    };
    CacheableRequests.getProgramEpisodes = function (programSlug, tenantSlug, language, page, limit) {
        var args = Tuple(programSlug, tenantSlug, language, page, limit);
        return (0, cacheable_1.observeCache)('getProgramEpisodes', args, function () { return Refresher.getProgramEpisodes.apply(Refresher, args); });
    };
    CacheableRequests.getEpisodeInformation = function (episodeId, tenantSlug, language) {
        var args = Tuple(episodeId, tenantSlug, language);
        return (0, cacheable_1.observeCache)('getEpisodeInformation', args, function () { return Refresher.getEpisodeInformation.apply(Refresher, args); });
    };
    CacheableRequests.getEpisodePlayback = function (episodeId, tenantSlug, language) {
        var args = Tuple(episodeId, tenantSlug, language);
        var options = { ttl: cacheable_1.T.FIVE_MINUTES, refreshTimeout: cacheable_1.T.ONE_MINUTE };
        return (0, cacheable_1.observeCache)('getEpisodePlayback', args, function () { return Refresher.getEpisodePlayback.apply(Refresher, args); }, options);
    };
    return CacheableRequests;
}());
exports.CacheableRequests = CacheableRequests;
