import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Col, Row, Button } from 'react-bootstrap';
import { getConfigByKey } from '~features/tenantConfig/services';
import { connect } from 'react-redux';
import classnames from 'classnames';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import { useLocation, useRouteMatch } from 'react-router';
import { loading } from '~features/loadingPage/services';
import { Link } from 'react-router-dom';
import { makeStyles, FormControlLabel, Radio, Checkbox, Typography } from '@material-ui/core';
import _ from 'lodash';
import LoginGoogle from '../login/loginGoogle';
import LoginFaceBook from '../login/loginFacebook';
import LoginKakaTalk from '../login/loginKaKaoTalk';
import LoginNaver from '../login/loginNaver';
import LoginEmail from '../login/loginEmail';
import PrivacyPolicy from '../../../box/components/privacyPolicyPopup';
import { verifyEmailFormat } from '../../../../utils/utils';
import { registerEmail, loginEmail } from '../../services';
import registerFormId from '../../../../constants/ids/registerFormId';
import TermOfUse from '../../../box/components/termOfUsePopup';
import pushAnalytics from '../../../../../src/services/analytics/Analytics';
import { ReactComponent as IconsPassword } from '../../../../assets/icons/eyeW.svg';
import { ReactComponent as IconsHidePassword } from '../../../../assets/icons/eyeWFlash.svg';
import WatchMoreModal from '../watchMoreModal';
import {
  setWatchNowClick,
  setLoginModalShow,
  setIsWelcome,
  setIsShowSignInForm,
  setIsShowVerifyEmailModal,
  setIsShowVerifyEmailPPV,
} from '~components/global/globalSlice';
import { wFirebaseEvents } from '~services/analytics';
import AppleLogin from '../login/AppleLogin';

const useStyles = makeStyles({
  projectw__terms_privacy: {
    color: '#8E8E93',
    fontSize: '12px',
    lineHeight: '133.5%',
    letterSpacing: '0.05em',
    borderBottom: '0.5px solid #8E8E93',
    marginTop: '100px',
    '&:focus,&:visited,&:hover,&:active': {
      color: '#8E8E93',
      textDecoration: 'none',
    },
  },
  projectw__terms_privacy__header: {
    color: '#000',
    borderBottom: '0.5px solid #000',
    '&:focus,&:visited,&:hover,&:active': {
      color: '#000',
      textDecoration: 'none',
    },
  },
  projectw__titlePPV: {
    color: '#000',
    fontFamily: 'Neue Haas Grotesk Display Pro',
    fontSize: '24px',
    fontWeight: 500,
  },
  projectw__titlePPV_V2: {
    textTransform: 'uppercase',
    margin: 0,
    marginBottom: 'unset !important',
  },
});
const PrivacyAndTerms = ({ lang = 'en' }) => {
  const { t } = useTranslation();
  return (
    <div className="privacy__terms">
      {lang === 'ko' ? '' : t('projectw__PPV.agreement')}{' '}
      <span
        style={{
          borderBottom: '1px solid #000',
          cursor: 'pointer',
        }}
      >
        <a
          style={{
            color: '#000',
          }}
          className="disable__textdecoration"
          target="_blank"
          href={`/${lang}/privacy-policy`}
          rel="noreferrer"
        >
          {t('projectw__PPV.privacy policy')}
        </a>
      </span>
      {` ${t('projectw__PPV.and')} `}
      <span
        style={{
          borderBottom: '1px solid #000',
          cursor: 'pointer',
        }}
      >
        <a
          style={{
            color: '#000',
          }}
          className="disable__textdecoration"
          target="_blank"
          href={`/${lang}/terms-of-service`}
          rel="noreferrer"
        >
          {t('projectw__PPV.term of service')}
        </a>
      </span>
      {lang === 'ko' ? ` ${t('projectw__PPV.agreement')}` : ''}
    </div>
  );
};
function SignUpForm(props) {
  // const component = props.props
  const {
    checkYourPaidAccount,
    hiddenModalLogin,
    registerModal,
    isShowLoginModal,
    isShowVerifyEmailModal,
    dpSetIsShowVerifyEmailModal,
    dpSetIsShowVerifyEmailPPV,
  } = props;
  const [isShow, setShowPassword] = React.useState(false);
  const [isConfirmPassword, setIsConfirmPassword] = React.useState(false);
  const [watchMoreModal, setWatchMoreModal] = React.useState(false);
  const { i18n } = useTranslation();
  const [alertError, setAlertError] = React.useState({
    errorEmail: false,
    errorPassword: false,
    errorConfirmPassword: false,
    errorFromSystems: false,
    errorFnameLname: false,
  });
  const [user, setUser] = React.useState({
    email: '',
    password: '',
    firstname: '',
    lastname: '',
    phone: undefined,
    birthday: undefined,
    gender: undefined,
    confirmPassword: '',
  });

  const classes = useStyles();

  const [action, setAction] = React.useState({
    isLoading: false,
    resMessage: '',
    showAlert: false,
  });
  // const { param, modalShow, hideModal } = component
  // const { isLoading } = props.state
  const location = useLocation();
  const { dpOpenWelcome, isShowWelcome, openShowPPV } = props;
  const [isOpenShowPPV, setIsOpenPPV] = React.useState(false);
  const [isShowConfirm, setIsShowConfirm] = React.useState(false);
  const [privacyPolicyModalShow, setPrivacyPolicyModalShow] = React.useState(false);
  const [termOfUseModalShow, setTermOfUseModalShow] = React.useState(false);
  const [legalAgeCheck, setLegalAgeCheck] = useState(false);
  const [agreeCheck, setAgreeCheck] = useState(false);

  const pathnameSplits = location.pathname.split('/');

  React.useEffect(() => {
    wFirebaseEvents.signUpStartedEvent();

    if (pathnameSplits.length > 1 && pathnameSplits[1] === 'terms-of-use') {
      setTermOfUseModalShow(true);
    }
  }, []);
  const [modalRegis, setModalRegis] = React.useState(registerModal);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!openShowPPV) {
      return;
    }
    setIsOpenPPV(openShowPPV);
  }, [isOpenShowPPV]);

  const ModalSignIn = () => {
    props.setLoginShowModal(true);
    props.setIsShowSignInForm(false);
    setModalRegis(!registerModal);
  };

  const onChangeText = (key, e) => {
    let value = '';
    value = e.target.value;
    setUser({
      ...user,
      [key]: value,
    });
    setAction({
      resMessage: '',
    });
  };

  const onKeyDownEnter = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      signUp();
    }
  };

  const signUp = () => {
    const { email, firstname, password, lastname, phone, gender, birthday, confirmPassword } = user;

    if (
      _.isEmpty(agreeCheck && legalAgeCheck && email && password && firstname && confirmPassword)
    ) {
      return;
    }

    if (!firstname || _.isEmpty(firstname)) {
      setAction({
        isLoading: false,
        resMessage: 'Ensure this field is not empty',
        showAlert: true,
      });
      setAlertError({
        ...alertError,
        errorEmail: false,
        errorPassword: false,
        errorConfirmPassword: false,
        errorFromSystems: false,
        errorFnameLname: true,
      });
      return;
    }

    if (!verifyEmailFormat(email)) {
      setAction({
        isLoading: false,
        resMessage: 'Invalid e-mail address',
        showAlert: true,
      });
      setAlertError({
        ...alertError,
        errorEmail: true,
        errorPassword: false,
        errorConfirmPassword: false,
        errorFromSystems: false,
        errorFnameLname: false,
      });
      return;
    }

    if (password.length < 6) {
      setAction({
        isLoading: false,
        resMessage: 'Ensure this field has at least 6 characters.',
        showAlert: true,
      });
      setAlertError({
        ...alertError,
        errorEmail: false,
        errorPassword: true,
        errorConfirmPassword: false,
        errorFromSystems: false,
        errorFnameLname: false,
      });
      return;
    }

    if (confirmPassword !== password) {
      setIsConfirmPassword(true);
      setAction({
        isLoading: false,
        resMessage: 'Confirm Password Not Correct Password',
        showAlert: true,
      });
      setAlertError({
        ...alertError,
        errorEmail: false,
        errorPassword: false,
        errorConfirmPassword: true,
        errorFromSystems: false,
        errorFnameLname: false,
      });
      return;
    }

    setAction({
      isLoading: true,
      resMessage: '',
      showAlert: false,
    });
    setAlertError({
      ...alertError,
      errorEmail: false,
      errorPassword: false,
      errorConfirmPassword: false,
      errorFromSystems: false,
      errorFnameLname: false,
    });
    const params = {
      email,
      firstName: firstname.split(' ').slice(0, -1).join(' ') || undefined,
      lastName: firstname.split(' ').slice(-1).join(' '),
      phoneNumber: phone,
      password,
      gender,
      dob: birthday,
    };
    props.dpLoading(true);
    wFirebaseEvents.signUpProcessingEvent({ email });
    props
      .dispatchRegisterEmail(params)
      .then(response => {
        if (!openShowPPV) {
          dpSetIsShowVerifyEmailModal(true);
        }
        dpSetIsShowVerifyEmailPPV(true);
        dpOpenWelcome(false);
        setTimeout(() => {
          setAction({
            isLoading: false,
            resMessage: '',
            showAlert: false,
          });
        }, 500);
        // pushAnalytics('sign_up', { method: 'email' });
        wFirebaseEvents.signUpEmailVerifyingEvent({ email });
        props.dispatchLoginEmail(email, password);
        if (typeof Storage !== 'undefined') localStorage.removeItem('email');
      })
      .catch(error => {
        setTimeout(() => {
          if (error.error >= 500) {
            setAction({
              isLoading: false,
              resMessage: 'The system is having problems',
              showAlert: true,
            });
            setAlertError({
              ...alertError,
              errorEmail: false,
              errorPassword: false,
              errorConfirmPassword: false,
              errorFromSystems: true,
              errorFnameLname: false,
            });
          } else {
            setAlertError({
              ...alertError,
              errorEmail: false,
              errorPassword: false,
              errorConfirmPassword: false,
              errorFromSystems: true,
              errorFnameLname: false,
            });
            setAction({
              isLoading: false,
              resMessage: error.message || error.detail,
              showAlert: true,
            });
          }
        }, 1000);
      })
      .finally(() => {
        props.dpLoading(false);
      });
  };

  // const hiddenModalSignUp = () => {
  //   props.setLoginShowModal(true)
  //   hiddenModalLogin()
  //   setModalRegis(false)
  //   setWatchMoreModal(false)
  // }

  const { errorEmail, errorPassword, errorConfirmPassword, errorFromSystems, errorFnameLname } =
    alertError;

  const { email, password, confirmPassword, firstname } = user;

  return (
    <Row className="v-loginForm--row project-w--row">
      <Col sm={12} className="v-loginForm--header project-w--header">
        {!isOpenShowPPV && (
          <p data-testid={registerFormId.title} className="v-loginForm--title project-w--title">
            {t('projectw__modal.SignUp.title')}
          </p>
        )}
        {isOpenShowPPV && (
          <>
            <p
              data-testid={registerFormId.title}
              className={`v-loginForm--title project-w--title ${classes.projectw__titlePPV_V2}`}
            >
              {t('projectw__modal.SignUp.title_modalPPV_V2_')}
            </p>
            <p
              data-testid={registerFormId.title}
              className={`v-loginForm--title project-w--title ${classes.projectw__titlePPV_V2}`}
              style={{ fontSize: '20px' }}
            >
              {t('projectw__modal.SignUp.title_modalPPV_V2__')}{' '}
              <span
                className={classes.projectw__titlePPV}
                style={{
                  borderBottom: '1px solid #000',
                  cursor: 'pointer',
                  textTransform: 'uppercase',
                  fontSize: '20px',
                }}
                onClick={ModalSignIn}
              >
                {t('projectw__modal.SignUp.title2__ModalPPV')}
              </span>
            </p>
          </>
        )}
      </Col>
      <Col sm={12} className="v-loginForm--header project-w--header">
        <p data-testid={registerFormId.title} className="v-loginForm--title project-w--titleSecond">
          {t('projectw__modal.SignUp.nameAndEmailAddressIsAllYouNeedToBecomeAnIlluonMember')}
        </p>
      </Col>
      <Col sm={12}>
        <form onSubmit={signUp} autoComplete="off">
          {/* <p
            style={{ float: 'left' }}
            className="v-loginForm--legal project-w--legal"
          >
            {t('registerScreen.lastName&firstName')}
          </p> */}
          <div className="project-w--form">
            <FormControlW
              data-testid={registerFormId.firstNameTxt}
              type="text"
              placeholder={t('projectw__modal.SignUp.fullname__placeholder')}
              name="firstname"
              bsSize="lg"
              value={user.firstname}
              onChange={value => onChangeText('firstname', value)}
              onKeyDown={e => onKeyDownEnter(e)}
            />
            {errorFnameLname && (
              <p
                data-testid={registerFormId.errorMessageLbl}
                className="error-data project-W-error"
              >
                {action.resMessage}
              </p>
            )}
          </div>
          <div className="project-w--form">
            <FormControlW
              data-testid={registerFormId.emailTxt}
              type="text"
              style={
                action.showAlert === true && !verifyEmailFormat(user.email)
                  ? { color: '#E92121' }
                  : {}
              }
              placeholder={t('projectw__modal.SignUp.email__placeholder')}
              name={t('projectw__modal.SignUp.email__placeholder')}
              id="formLogin"
              value={user.email}
              onChange={value => onChangeText('email', value)}
              onKeyDown={e => onKeyDownEnter(e)}
            />
            {errorEmail && (
              <p
                data-testid={registerFormId.errorMessageLbl}
                className="error-data project-W-error"
              >
                {action.resMessage}
              </p>
            )}
            {/* <p
              style={{ float: 'left' }}
              className="v-loginForm--legal project-w--legal"
            >
              {t('registerScreen.Password')}
            </p> */}
          </div>
          <div className="project-w--form">
            <FormControlW
              data-testid={registerFormId.passwordTxt}
              type={`${isShow ? 'text' : 'password'}`}
              className="input__password"
              placeholder={t('projectw__modal.SignUp.password__placeholder')}
              value={user.password}
              name={t('projectw__modal.SignUp.password__placeholder')}
              autoComplete="new-password"
              id="formLogin"
              onChange={value => onChangeText('password', value)}
              onKeyDown={e => onKeyDownEnter(e)}
            />
            {errorPassword && (
              <p
                data-testid={registerFormId.errorMessageLbl}
                className="error-data project-W-error"
              >
                {action.resMessage}
              </p>
            )}
            <button
              className="v-loginForm--showPass project-w--showPass"
              tabIndex="-1"
              onClick={e => {
                e.preventDefault();
                setShowPassword(!isShow);
              }}
              style={{ top: '27px', right: 0 }}
            >
              <i
                className={classnames(isShow ? 'fa fa-eye' : 'fa fa-eye-slash')}
                aria-hidden="true"
                style={getConfigByKey('ui_theme') !== 'projectw' ? {} : { display: 'none' }}
              />
              {getConfigByKey('ui_theme') === 'projectw' &&
                (isShow ? <IconsPassword /> : <IconsHidePassword />)}
            </button>
          </div>
          <div className="project-w--form">
            <FormControlW
              data-testid={registerFormId.passwordTxt}
              type={isShowConfirm ? 'text' : 'password'}
              className="input__password"
              placeholder={t('projectw__modal.SignUp.ConfirmPass')}
              value={user.confirmPassword}
              name={t('projectw__modal.SignUp.ConfirmPass')}
              autoComplete="new-password"
              id="formLogin"
              onChange={value => onChangeText('confirmPassword', value)}
              onKeyDown={e => onKeyDownEnter(e)}
            />
            <div className="project-w--form-confirm">
              <FormControlLabel
                control={<Checkbox onChange={e => setLegalAgeCheck(e.target.checked)} />}
                label={t('projectw__modal.SignUp.iAmOfLegalAge')}
              />
            </div>
            <div className="project-w--form-confirm">
              <FormControlLabel
                control={<Checkbox onChange={e => setAgreeCheck(e.target.checked)} />}
                label={<PrivacyAndTerms lang={i18n.language} />}
              />
            </div>
            {errorConfirmPassword && (
              <p
                data-testid={registerFormId.errorMessageLbl}
                className="error-data project-W-error"
              >
                {action.resMessage}
              </p>
            )}
            <button
              className="v-loginForm--showPass project-w--showPass"
              tabIndex="-1"
              onClick={e => {
                e.preventDefault();
                setIsShowConfirm(!isShowConfirm);
              }}
              style={{ top: '27px', right: 0 }}
            >
              <i
                className={classnames(isShowConfirm ? 'fa fa-eye' : 'fa fa-eye-slash')}
                aria-hidden="true"
                style={getConfigByKey('ui_theme') !== 'projectw' ? {} : { display: 'none' }}
              />
              {getConfigByKey('ui_theme') === 'projectw' &&
                (isShowConfirm ? <IconsPassword /> : <IconsHidePassword />)}
            </button>
          </div>
          <Button
            data-testid={registerFormId.registerBtn}
            onClick={signUp}
            style={action.showAlert === true ? { background: '#e92121', color: '#fff' } : {}}
            disabled={
              _.isEmpty(password && email && confirmPassword && firstname) ||
              !agreeCheck ||
              !legalAgeCheck
            }
            className="project-w--signIn"
            bsSize="large"
          >
            {action.isLoading === true ? (
              <Loader type="Oval" color="white" height="20" width="20" />
            ) : (
              `${t('projectw__modal.SignUp.btnSignUp')}`
            )}
          </Button>
          {errorFromSystems && (
            <p data-testid={registerFormId.errorMessageLbl} className="error-data project-W-error">
              {action.resMessage}
            </p>
          )}
        </form>
      </Col>
      <div
        className={classnames(
          'v-loginForm--or project-w--or',
          !getConfigByKey('features.loginfb') && !getConfigByKey('features.loginGoogle') && 'hide',
        )}
      >
        {t('loginScreen.Or')}
      </div>
      <Col sm={12}>
        <LoginGoogle openShowPPV={openShowPPV} target="projectw" />
        {/* <LoginNaver setAction={setAction} /> */}
        <LoginKakaTalk setAction={setAction} />
        <AppleLogin setAction={setAction} />
        <LoginFaceBook {...props} />
      </Col>
      <Col sm={12}>
        <GroupAction>
          <p style={{ fontWeight: 400 }}>{t('projectw__modal.SignUp.already__account')}</p>
          <ButtonSignUp data-testid={registerFormId.loginBtn} onClick={ModalSignIn} bsSize="large">
            {t('projectw__modal.SignUp.txtSignIn')}
          </ButtonSignUp>
        </GroupAction>
      </Col>
      <Col sm={12}>
        <GroupAction>
          <Link
            target="_blank"
            to={`/${i18n.language}/terms-of-service`}
            style={{
              cursor: 'pointer',
              fontWeight: 400,
              textDecoration: 'none',
              color: '#8E8E93',
            }}
            className={classes.projectw__terms_privacy}
          >
            {t('projectw__modal.SignUp.terms')}
          </Link>
          <span
            style={{
              cursor: 'pointer',
              fontWeight: 400,
              marginBottom: 'unset',
              padding: '0 3px',
            }}
          >
            &
          </span>
          <Link
            target="_blank"
            to={`/${i18n.language}/privacy-policy`}
            style={{
              cursor: 'pointer',
              fontWeight: 400,
              textDecoration: 'none',
              color: '#8E8E93',
            }}
            className={classes.projectw__terms_privacy}
          >
            {t('projectw__modal.SignUp.privacy')}
          </Link>
          {/* <PrivacyPolicy
            modalShow={privacyPolicyModalShow}
            showModal={() => setPrivacyPolicyModalShow(!privacyPolicyModalShow)}
          />
          <TermOfUse
            modalShow={termOfUseModalShow}
            showModal={() => setTermOfUseModalShow(!termOfUseModalShow)}
          /> */}
        </GroupAction>
      </Col>
    </Row>
  );
}

const GroupAction = styled.div`
  font-family: var(--font);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 133.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  span {
    color: #8e8e93;
    margin-top: 100px;
    margin-bottom: 20px;
    letter-spacing: 2px;
    margin-left: 3px;
    &:nth-child(1),
    &:nth-child(3) {
      border-bottom: 1px solid #000;
    }
  }
`;

const ButtonSignUp = styled.p`
  font-weight: 600;
  padding-left: 10px;
  &:hover {
    cursor: pointer;
  }
`;

const FormControlW = styled.input`
  font-size: 17px;
  width: 100%;
  padding: 10px 8px;
  font-weight: 400;
  margin-bottom: 17px;
  border-radius: 0;
  color: #000000;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #c7c7cc;
  &.input__password {
    margin-bottom: 10px;
  }

  &::placeholder {
    color: #c7c7cc;
    opacity: 1;
    font-size: 18px;
  }
  &:hover,
  &:focus {
    background: #fff;
    color: #000000;
    outline: none !important;
  }
  input:focus {
    outline: none !important;
  }
`;
const mapStateToProps = state => ({
  account: state.auth.account,
  isShowLoginModal: state.global.ActionLogin.isShowLoginModal,
  isShowWelcome: state.global.modal.isWelcome,
  isShowVerifyEmailModal: state.global.isShowVerifyEmailModal,
});

const mapDispatchToProps = {
  dispatchRegisterEmail: params => registerEmail(params),
  dispatchLoginEmail: (email, password) => loginEmail(email, password),
  setLoginShowModal: value => setLoginModalShow(value),
  setIsShowSignInForm: value => setIsShowSignInForm(value),
  dpOpenWelcome: value => setIsWelcome(value),
  dpLoading: value => loading(value),
  dpSetIsShowVerifyEmailModal: value => setIsShowVerifyEmailModal(value),
  dpSetIsShowVerifyEmailPPV: value => setIsShowVerifyEmailPPV(value),
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
