import React from 'react';
import { getConfigByKey } from '~features/tenantConfig/services';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
const BackButton = React.memo(props => {
  const { handleClick } = props;
  return (
    <React.Fragment>
      <div className="vjs--escondido-back__btn" onClick={handleClick}>
        <KeyboardBackspaceIcon />
      </div>
      <img src={getConfigByKey('nav_bar_logo')} width="80" height="30" />
    </React.Fragment>
  );
});

export default BackButton;
