import * as types from './actionTypes';
import { getConfigByKey } from '~features/tenantConfig/services';
import { DEFAULT_LOCALE } from '~i18n';
import { isBrowser } from '~utils/ssr';
import { PayloadAction } from '@reduxjs/toolkit';

export interface IRootState {
  isAuth: boolean;
  language: string;
  isBlock: boolean;
  tenantSlug: string | boolean;
  muxKey: string | null;
  isFollowSubcription: boolean;
}
const subscriptionFeature: boolean = getConfigByKey('features.subscriptionFeature');
const initialState: IRootState = {
  isAuth: false,
  language: DEFAULT_LOCALE,
  isBlock: false,
  tenantSlug: false,
  muxKey: null,
  isFollowSubcription: subscriptionFeature || false,
};

export function root(state = initialState, action: PayloadAction<Partial<IRootState>>) {
  switch (action.type) {
    case types.INITIAL_APP:
      return {
        ...state,
      };
    case types.LOADING_LOCAL_STORAGE:
      return {
        ...state,
      };
    case types.AUTHENTICATE:
      return {
        ...state,
        isAuth: action.payload.isAuth,
      };
    case types.CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.payload.language,
      };
    case types.COUNTRIES_BLOCK:
      return {
        ...state,
        isBlock: action.payload.isBlock,
      };
    case types.UPDATE_TENANT_SLUG:
      return {
        ...state,
        tenantSlug: action.payload.tenantSlug,
      };
    case types.UPDATE_MUX_KEY:
      return {
        ...state,
        muxKey: action.payload.muxKey,
      };
    case types.UPDATE_REGION_SUBCRIPTION:
      if (!subscriptionFeature && isBrowser()) {
        return state;
      }
      return {
        ...state,
        isFollowSubcription: action.payload.isFollowSubcription,
      };
    default:
      return state;
  }
}
