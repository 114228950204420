import axiosClientRocketchat from '~api/axiosClientRocketchat';
import queryString from 'query-string';

export const sendMessageApi = (
  payload: { message: string },
  header: { 'X-Auth-Token': string; 'X-User-Id': string },
) => {
  const { sendMessageDebug } = queryString.parse(window.location.search);

  return new Promise((resolve, reject) => {
    axiosClientRocketchat
      .post('/chat.sendMessage', JSON.stringify(payload), {
        headers: {
          ...header,
        },
      })
      .then(res => {
        resolve(res);
      })
      .catch(error => reject(error));
  });
};

export const getMeApi = (header: { 'X-Auth-Token': string; 'X-User-Id': string }) => {
  return new Promise((resolve, reject) => {
    axiosClientRocketchat
      .get('/me', {
        headers: {
          ...header,
        },
      })
      .then(res => {
        resolve(res);
      })
      .catch(error => reject(error));
  });
};
