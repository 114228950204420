import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import { WatchMoreStyled } from '../style/homepage';

function WatchMore(props) {
  const { watchMore, setWatchMore } = props;
  const history = useHistory();
  const { i18n } = useTranslation();
  const handleOnClick = () => {
    if (watchMore) {
    }
    // setWatchMore(true)
  };
  return (
    <Link
      style={{ textDecoration: 'none' }}
      to={`/${i18n.language}/vod-list-page`}
      className="see-more"
    >
      <WatchMoreStyled onClick={handleOnClick}>
        <AddIcon />
        <span>SEE MORE</span>
      </WatchMoreStyled>
    </Link>
  );
}

export default WatchMore;
