import React from 'react';
import loginFormId from '~constants/ids/loginFormId';
import { Modal, Col, Row, Button } from 'react-bootstrap';
import LoginProjectW from './login/projectw';
import { ModalStyled } from './login/styled';
import { connect, useDispatch, useSelector } from 'react-redux';
import { setIsWelcome, setLoginModalShow } from '~components/global/globalServices';
import { setIsShowVerifyEmailModal } from '~components/global/globalSlice';
import { setIsShowLogin } from '~features/auth/services';

const WatchMoreModal = React.memo(props => {
  const component = props.props;
  const { hiddenModalLogin, watchMoreModal, hiddenWatchMore, hiddenModalLogin_V2 } = props;
  const [modalSignUp, setModalSignUp] = React.useState(watchMoreModal);
  const { param, modalShow, hideModal } = component;
  const dispatch = useDispatch();

  const handleClose = () => {
    props.dpSetIsLogin(true);
    props.dpSetIsWelcome(!props.isShowWelcome);
    props.dpSetIsShowVerifyEmailModal(false);
    hiddenModalLogin_V2();
    // dispatch(setIsShowLogin(false))
  };

  const isShowWelcome = useSelector(state => state.global.modal.isWelcome);

  return (
    <ModalStyled
      show={isShowWelcome}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      className="v-loginForm project-w"
    >
      <Modal.Body className="project-w--Body">
        <Row className="v-loginForm--row project-w--row">
          <Col sm={12} className="v-loginForm--header project-w--header">
            <p
              data-testid={loginFormId.title}
              className="v-loginForm--title project-w--title"
              style={{
                fontSize: '27px',
              }}
            >
              Welcome to IILUON
            </p>
          </Col>
          <Col sm={12} className="v-loginForm--header project-w--header">
            <p
              data-testid={loginFormId.title}
              style={{
                textTransform: 'uppercase',
                fontFamily: 'Neue Machina',
                fontWeight: 700,
                marginBottom: 44,
                marginTop: 44,
              }}
              className="v-loginForm--title project-w--title"
            >
              Discover Wonderful, Hand-Picked Perfoming Art, Film,
              <span style={{ display: 'block' }}>ConCerts,MUSICLAS, theatre , Dance And ETC.</span>A
              New Film Every Single Day
            </p>
          </Col>
          <Col sm={12}>
            <Button className="project-w--watchmore" bsSize="large" onClick={handleClose}>
              Watch Now
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </ModalStyled>
  );
});

const mapStateToProps = state => ({
  isShowWelcome: state.global.modal.isWelcome,
});

const mapDispatchToProps = {
  dpSetIsWelcome: value => setIsWelcome(value),
  dpSetIsLogin: value => setLoginModalShow(value),
  dpSetIsShowVerifyEmailModal: value => setIsShowVerifyEmailModal(value),
};

export default connect(mapStateToProps, mapDispatchToProps)(WatchMoreModal);
