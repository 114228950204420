import * as types from './actionTypes';
import { UPDATE_REACTED_CONTENT } from './actionTypes';

export function getMenu(menu) {
  return {
    type: types.GET_MENU,
    menu,
  };
}

export function getPageRequest() {
  return {
    type: types.GET_PAGE_REQUEST,
  };
}

export function getPage(page) {
  return {
    type: types.GET_PAGE,
    page,
  };
}

export function getDetail(detail) {
  return {
    type: types.GET_DETAIL,
    detail,
  };
}

export function refreshPage(isRefreshPage) {
  return {
    type: types.REFRESH_PAGE,
    isRefreshPage,
  };
}

export function getFinal() {
  return {
    type: types.GET_FINAL,
  };
}

export function updateRibbonTarget(value) {
  return {
    type: types.GET_RIBBON_TARGET,
    idRibbonTarget: value,
  };
}

export function updateStatusRemove(payload) {
  return {
    type: types.UPDATE_STATUS_REMOVE_PROCESS,
    payload,
  };
}

export function updateSeach(queryParams) {
  return {
    type: types.UPDATE_SEARCH,
    queryParams,
  };
}

export function updateSlugPage(payload) {
  return {
    type: types.UPDATE_PAGE_SLUG,
    payload,
  };
}

export function setTopTenContents(payload) {
  return {
    type: types.SET_TOP_TEN_CONTENTS,
    payload,
  };
}

export function setLinkPlayOfTopTenContent(payload) {
  return {
    type: types.SET_LINK_PLAY_OF_TOP_TEN_CONTENT,
    payload,
  };
}
export function updateReactedContent(payload) {
  return {
    type: types.UPDATE_REACTED_CONTENT,
    payload,
  };
}

export function updateSsr(payload) {
  return {
    type: types.UPDATE_SSR,
    payload,
  };
}

export function updateRibbons(ribbons) {
  return {
    type: types.UPDATE_RIBBONS,
    ribbons,
  };
}

export function resetPageData() {
  return {
    type: types.RESET_PAGE_DATA,
  };
}
