/* tslint:disable */
/* eslint-disable */
/**
 * ODX/V3 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OdxV3UserProfileSubscriptionIntervalUnitEnum = exports.OdxV3UserProfileSubscriptionProviderEnum = exports.OdxV3UserProfileSubscriptionTypeEnum = exports.OdxV3UserProfileLoginTypeEnum = void 0;
exports.OdxV3UserProfileLoginTypeEnum = {
    email: 'email',
    apple: 'apple',
    facebook: 'facebook',
    unknown_default_open_api: '11184809'
};
exports.OdxV3UserProfileSubscriptionTypeEnum = {
    basic: 'basic',
    plus: 'plus',
    premium: 'premium',
    premium_box: 'premium-box',
    unknown_default_open_api: '11184809'
};
exports.OdxV3UserProfileSubscriptionProviderEnum = {
    web: 'web',
    apple: 'apple',
    google: 'google',
    roku: 'roku',
    unknown_default_open_api: '11184809'
};
exports.OdxV3UserProfileSubscriptionIntervalUnitEnum = {
    month: 'month',
    year: 'year',
    unknown_default_open_api: '11184809'
};
