import React from 'react';
import styled from 'styled-components';
import { timeFormatDurationHHMMSS } from '~core/method/contentMethod';
import moment from 'moment';
import { connect } from 'react-redux';
import { getCurrentTimeBE } from '~features/landingpage/services';
import _ from 'lodash';

const TimeLiveEvent = styled.div`
  font-family: 'Neue Haas Grotesk Display Pro';
  position: absolute;
  bottom: 75px;
  left: 10px;
  margin-bottom: calc(10px / var(--scaling));
  .timelive {
    color: #fff;
    font-size: 16px;
    line-height: 21.36px;
    font-weight: 400;
    letter-spacing: 0.025em;
    margin: 0;
    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
`;

function CurrentTimeForAllContent(props) {
  const { player } = props;
  const [currentTimeForAllContent, setCurrentTimeForAllContent] = React.useState({
    duration: 0,
  });

  React.useEffect(() => {
    const setCurrentTimeContent = setInterval(() => {
      if (currentTimeForAllContent.duration === player.currentTime().toFixed(0)) {
        return;
      }
      setCurrentTimeForAllContent({
        duration: player.currentTime().toFixed(0),
      });
    }, 1000);
    return () => {
      clearInterval(setCurrentTimeContent);
    };
  }, [currentTimeForAllContent.duration]);

  return (
    <TimeLiveEvent classNam="inTimeLive">
      <p className="timelive">{timeFormatDurationHHMMSS.call(currentTimeForAllContent)}</p>
    </TimeLiveEvent>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  dpGetTimeCurrent: contentId => getCurrentTimeBE(contentId),
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentTimeForAllContent);
