import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';

export const TabComponent = styled.div`
  &.TabsComponents {
    font-family: var(--font);
    padding: 20px 23px;
    .TabsComponents {
      &-title {
        font-size: 62px;
        font-family: var(--font);
      }
      &-imageProfile {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        .top {
          display: flex;
        }
        .bottom {
          margin-top: 14px;
        }
        .avatar {
          max-width: 100%;
          max-height: 100%;
          position: relative;
          .wrapImage {
            width: 186px;
            height: 186px;
            img {
              width: 100%;
              height: 100%;
              max-height: 186px;
              max-width: 186px;

              object-fit: cover;
              object-position: center;
            }
          }
        }
        .action {
          margin-left: 23px;
          &-title {
            font-size: 16px;
            font-family: var(--font);
            color: #000;
            padding-bottom: 10px;
            span {
              color: #838393;
              font-size: 13px;
              padding-left: 14px;
            }
          }
          .rec_mobile {
            display: none;
          }
          &_selectImage {
            width: 100%;
            padding-bottom: 69px;
            .btnUpload {
              padding: 6.5px 19.5px;
              background: none;
              cursor: pointer;
              border: 1px solid #000;
              font-size: 13px;
              text-transform: uppercase;
              text-align: center;
              font-weight: 500;
              line-height: 133.5%;
              letter-spacing: 0.025em;
            }
          }
          &_confirm {
            .MuiCheckbox-colorSecondary.Mui-checked {
              color: #000 !important;
            }
            color: #48484a;
            padding-bottom: 10px;
            line-height: 133.5%;
          }
          &_saveChange {
            width: 100%;
            .btnSave {
              padding: 16.5px 17px;
              color: #fff;
              background-color: #000;
              text-align: center;
              text-transform: uppercase;
              font-size: 13px;
              cursor: pointer;
              border: none;
              font-weight: 500;
              line-height: 133.5%;
              letter-spacing: 0.025em;
            }
          }
        }
      }
      &-formChangeInfo {
        margin-top: 86px;
        width: 100%;
        margin-bottom: 78px;
        /* input {
          color: #000 !important;
          letter-spacing: 0.025em;
          line-height: 146%;
        } */
        /* label {
          font-weight: 500;
        } */
        .first_lastName {
          display: flex;
          width: 100%;
          .fName {
            max-width: 100%;
            &::placeholder {
              color: #8e8e93;
              line-height: 133.5%;
              letter-spacing: 0.05em;
            }
          }
          .lName {
            margin-left: 24px;
            max-width: 100%;
            &::placeholder {
              color: #8e8e93;
              line-height: 133.5%;
              letter-spacing: 0.05em;
            }
          }
        }
        input:disabled {
          color: #8e8e93 !important;
        }
        .passWord {
          &::placeholder {
            color: #8e8e93;
            line-height: 133.5%;
            letter-spacing: 0.05em;
          }
          label {
            font-size: 13px;
            text-transform: uppercase;
          }
        }
        .btnAction {
          padding: 16.5px 17px;
          text-align: center;
          text-transform: uppercase;
          color: #fff;
          background-color: #000;
          border-radius: 0;
          font-weight: 500;
          &:hover {
            background: #000;
          }
        }
        .btnActionNoneEvent {
          padding: 16.5px 17px;
          text-align: center;
          text-transform: uppercase;
          color: #c7c7cc;
          background-color: #e5e5ea;
          pointer-events: none;
          border-radius: 0;
          font-weight: 500;
          &:hover {
            background: #000;
            pointer-events: none;
          }
        }
        .DelAccount {
          margin-top: 36px;
          .btnDel {
            padding: 16.5px 43px;
            font-size: 13px;
            text-transform: uppercase;
            color: #fff;
            border: none;
            background: #e92121;
            text-align: center;
            font-weight: 500;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    &.TabsComponents {
      /* padding: 10px 8px; */
      width: 100% !important;
      padding: 8px !important;
      .TabsComponents {
        &-title {
          /* display: none; */
        }
        &-imageProfile {
          padding: 0 8px;
          .avatar {
            .wrapImage {
              width: 100px;
              height: 100px;
            }
          }
          .action {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &-title {
              padding-bottom: unset;
              span {
                display: none;
              }
            }
            .rec_mobile {
              display: block;
              font-size: 11px;
              padding-bottom: 10px;
              color: #838393;
            }
            .action_selectImage {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
`;
export const DialogStyled = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    border-radius: 0;
    border: ${props => (props.favoritepage ? '1px solid #fff' : '0')};
  }
  .title {
    max-width: 380px;
    h2 {
      text-align: center;
      font-size: 27px;
      font-family: var(--font);
      font-weight: bold;
    }
  }
  .content {
    &-Txt {
      padding: ${props => (props.continuePage ? '8px 24px' : '16px 24px')};
      h2 {
        font-size: 17px;
        font-family: var(--font);
        text-align: center;
        font-weight: 400;
      }
    }
  }
  .MuiDialogActions-root {
    padding: unset !important;
    &.modalUnpublish {
      justify-content: center;
    }
  }
  .btnAction {
    padding: 16.5px 70.25px;
    text-align: center;
    text-transform: uppercase;
    font-family: var(--font);
    font-size: 13px;
    font-weight: bold;
    border-radius: 0;
    &.my__review {
      padding: 16.5px 55.25px;
    }
    &.cancel__refund {
      padding: 16.5px 18px;
    }
    @media (max-width: 767px) {
      padding: 16.5px 56px;
      &.cancel__refund {
        padding: 16.5px 18px;
      }
    }
  }
  .buttonConfirmUnpublish {
    background-color: #000;
    color: #fff;
    margin-bottom: 41.5px;
    margin-left: unset !important;
    max-width: 275px;
    width: 100%;
    &:hover,
    &:active,
    &:focus {
      background: #000;
      color: #fff;
    }
  }
  .Confirm {
    background: #000;
    color: #fff;
    width: 100%;
    margin-left: unset !important;

    &:hover,
    &:active,
    &:focus {
      background: #000;
      color: #fff;
    }
  }
  .Cancel {
    background: #d1d1d6;
    color: #3a3a3c;
    width: 100%;
    &:hover,
    &:active,
    &:focus {
      background: #d1d1d6;
      color: #3a3a3c;
    }
  }
`;
export const MyReviewStyled = styled.div`
  &.Components_MyReview {
    /* margin: 0 -23px; */
    font-family: var(--font);
    font-weight: 400;
    color: #000;
    .MuiRating-iconEmpty {
      display: none;
    }
    .Components_MyReview {
      &--title {
        font-size: 62px;
        font-family: var(--font);
        padding: 20px 23px;
        border-right: 0.5px solid #000000;
        border-bottom: 0.5px solid #000000;
        margin-bottom: 0px;
      }
      &--emptyData {
        font-family: var(--font);
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.005em;
        color: #000000;
        margin-top: 10px;
        padding-left: 30px;
      }
      &--errorTxt {
        color: red;
        font-family: var(--font);
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        margin-top: 10px;
        padding-left: 30px;
      }
      &--items {
        &:nth-child(2) {
          /* border-top: 0.5px solid #000; */
        }
        border-bottom: 0.5px solid #000;
        border-right: 0.5px solid #000;
        box-shadow: -0.5px 0 0 0 #000;
        .titleReview {
          display: flex;
          border-bottom: 0.5px solid #c7c7c7;
          .thumbnail {
            position: relative;
            width: calc(178 / 1085 * 100%);
            flex-shrink: 0;
            align-self: flex-start;
            margin-bottom: unset !important;
            border: none;
            &::before {
              content: '';
              width: 100%;
              display: block;
              padding-top: calc(104 / 178 * 100%);
            }
            .wrap-img {
              max-width: 100%;
              max-height: 100%;
              img {
                position: absolute;
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
                top: 0;
                left: 0;
              }
            }
          }
          .infoReview {
            padding-left: 15px;
            padding-top: 10px;
            p {
              margin: 0 !important;
            }
            &-title {
              a {
                text-decoration: none;
                color: #000;
              }
              font-size: 24px;
            }
            &-createDate {
              font-size: 16px;
              span {
                color: red;
              }
            }
            &-ratingReview {
              .MuiRating-root {
                color: #1c1c1e;
              }
            }
            &-action {
              margin-left: auto;
              .btnAction {
                /* border: 0.5px solid #000; */
                border-left: 0.5px solid #000;
                border-bottom: 0.5px solid #000;
                padding: 6.5px 32px;
                border-radius: unset;
                min-width: 119px;
                text-align: center;
              }
            }
          }
        }
        .contentReview > p {
          font-size: 14px;
          padding: 10px 15px;
          word-break: break-word;
          margin: 0;
        }
      }
    }
    .actionPagination {
      padding: 24.5px 0;
      button:disabled {
        path {
          stroke: #aeaeb2;
        }
      }
      button {
        &:focus,
        &:active,
        &:hover {
          background: none;
          box-shadow: none;
          outline: none;
        }
        & .icon {
          width: 54px;
          height: 56px;
        }
        &.Next {
          transform: rotate(-90deg);
        }
        &.Prev {
          transform: rotate(90deg);
        }
      }
      .pageNumber {
        font-size: 30px;
        padding: 0 47px;
      }
    }
  }
  @media (max-width: 991px) {
    &.Components_MyReview {
      margin: 0;
      .Components_MyReview {
        &--title {
          /* display: none; */
        }
        &--items {
          .titleReview {
            .thumbnail {
              width: calc(123 / 320 * 100%);
              &::before {
                /* padding-top: calc(72 / 375 * 100%); */
              }
            }
            .infoReview {
              padding-top: 10px;
              p {
                margin: 0;
              }
              &-title {
                font-size: 16px;
                font-weight: 500;
                display: -webkit-box;
                max-height: 58px;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
                -webkit-line-clamp: 1;
              }
              &-createDate {
                font-size: 12px;
              }
              &-action {
                max-height: 100%;
                height: 100%;
                .btnAction {
                  padding-top: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 350px) {
    &.Components_MyReview {
      .Components_MyReview {
        &--items {
          .titleReview {
            .infoReview {
              &-title {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
`;
