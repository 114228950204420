import { store } from '~store';
import moment from 'moment';
import { decodeToken } from 'react-jwt';
import { startFetch } from './fetch';
import { resetActiveIDs } from '~components/filter/filterSlice';
import { setIsShowLogin } from '~features/auth/actions';
import { logout } from '~features/auth/services';
import { history } from '~store/history';
import * as homeActions from '~features/homepage/actions';

const REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED';
const REFRESH_TOKEN_INVALID = 'REFRESH_TOKEN_INVALID';
const AUTHENTICATION_FAILED = 'authentication_failed';
let buffers: any = [];
let isRefreshing = false;

function addBuffer(buffer: any) {
  buffers.push(buffer);
}

function resetBuffers() {
  buffers = [];
}

function getBuffers() {
  return buffers;
}

function setIsRefreshing(value: boolean) {
  isRefreshing = value;
}

function getIsRefreshing() {
  return isRefreshing;
}

function checkLoginGuestAccount() {
  const auth = (store.getState() as any).auth;

  const guestAccount = auth.guestAccount;
  if (auth.isLogin || guestAccount) return true;
  return false;
}

function requestRefreshToken() {
  const auth = (store.getState() as any).auth;
  const { account, guestAccount } = auth;
  const acc = account || guestAccount;
  const refreshToken = acc && acc.refresh_token ? acc.refresh_token : '';
  const path = '/backend/cas/refresh/';
  const headers = {
    Authorization: refreshToken,
  };
  return new Promise((resolve, reject) => {
    const dispatch = store.dispatch;
    startFetch(path, 'POST', headers, {})
      .then(response => {
        let actions = 'LOGIN_SUCCESS';
        if (auth.guestAccount) {
          actions = 'LOGIN_GUEST_SUCCESS';
        }
        dispatch({ type: actions, account: response });
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function isRequestRefreshToken() {
  const auth = (store.getState() as any).auth;
  const { account, guestAccount } = auth;
  const acc = account || guestAccount;

  if (!acc) return false;

  const durationTime = 60 * 5;
  const timestamp = moment().unix();
  const dcToken: any = decodeToken(acc.access_token || '');
  const exp = dcToken.exp || 0;
  const restExpireTime = exp - timestamp;
  if (!(restExpireTime < durationTime)) {
    return false;
  }
  return true;
}

async function doneRefreshToken(response: any, error: any) {
  const buffers = getBuffers();
  const dispatch = store.dispatch;

  const isRefreshFailed = !!(
    response === null &&
    error &&
    (error.error_code === REFRESH_TOKEN_EXPIRED ||
      error.error_code === REFRESH_TOKEN_INVALID ||
      error.error_code === AUTHENTICATION_FAILED)
  );
  let authorization = '';

  if (isRefreshFailed) {
    dispatch(homeActions.getFinal());
    dispatch(setIsShowLogin(true));
    history.push('/');
    try {
      const newGuestAccount: any = await dispatch(logout() as any);
      authorization = newGuestAccount.access_token || '';
    } catch (error) {}
  } else {
    authorization = response && response.access_token ? response.access_token : '';
  }
  buffers.some((buffer: any) => {
    const [fetchApi, options] = buffer;
    if (typeof fetchApi !== 'function') {
      return;
    }

    const { headers } = options;

    const { Authorization, authorization: oldAuthorization, ...newHeaders } = headers;

    const opts = {
      ...options,
      headers: {
        ...newHeaders,
        Authorization: authorization,
      },
    };

    fetchApi(opts);
  });
  resetBuffers();
  setIsRefreshing(false);
}

function checkRefreshToken(buffer: any, { path, method, headers, body }: any) {
  const auth = (store.getState() as any).auth;

  if (typeof buffer !== 'function') {
    return;
  }

  const isRequest = isRequestRefreshToken();
  const isLogin = checkLoginGuestAccount();

  if (headers && (headers.Authorization || headers.authorization)) {
    if (isRequest && !isRefreshing) {
      setIsRefreshing(true);
      requestRefreshToken()
        .then(response => {
          doneRefreshToken(response, null);
        })
        .catch(error => {
          doneRefreshToken(null, error);
        });
    }
    if (isRequest && isRefreshing) {
      addBuffer([buffer, { path, method, headers, body }]);
      return;
    }
  }

  buffer({ path, method, headers, body });
}

export { isRequestRefreshToken, doneRefreshToken, checkRefreshToken };
