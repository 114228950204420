/* tslint:disable */
/* eslint-disable */
/**
 * Vimai Core Service UI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DebugApi = exports.DebugApiFactory = exports.DebugApiFp = exports.DebugApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * DebugApi - axios parameter creator
 * @export
 */
var DebugApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * debug request api
         * @summary debug request api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugRequestApi: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/debug_request/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * debug request api
         * @summary debug request api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugRequestApi_1: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/debug_request/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get mobi event logs
         * @summary Get mobi event logs
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [startTime] start time
         * @param {number} [endTime] End time
         * @param {string} [vasCode] VAS Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMobileEventLogs: function (tenantSlug, authorization, acceptLanguage, startTime, endTime, vasCode, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getMobileEventLogs', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/mobi_event_logs/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (startTime !== undefined) {
                                localVarQueryParameter['start_time'] = startTime;
                            }
                            if (endTime !== undefined) {
                                localVarQueryParameter['end_time'] = endTime;
                            }
                            if (vasCode !== undefined) {
                                localVarQueryParameter['vas_code'] = vasCode;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * [BACKEND-ONLY] Get profile By UUCode
         * @summary [BACKEND-ONLY] Get profile By UUCode
         * @param {string} uuCode uu_code
         * @param {string} [authorization] super backend secret key
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileByUucode: function (uuCode, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'uuCode' is not null or undefined
                            (0, common_1.assertParamExists)('getProfileByUucode', 'uuCode', uuCode);
                            localVarPath = "/backend/cas/private/uucode";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (uuCode !== undefined) {
                                localVarQueryParameter['uu_code'] = uuCode;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Inspect request metadata
         * @summary Inspect request metadata
         * @param {string} secretToken
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inspectRequestMetadata: function (secretToken, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'secretToken' is not null or undefined
                            (0, common_1.assertParamExists)('inspectRequestMetadata', 'secretToken', secretToken);
                            localVarPath = "/backend/cm/private/request_context/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (secretToken !== undefined) {
                                localVarQueryParameter['secret_token'] = secretToken;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * [ADMIN-TOOL-ONLY] Test Search
         * @summary [ADMIN-TOOL-ONLY] Test Search
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [keyword] is ajax
         * @param {number} [page] number page
         * @param {number} [limit] limit of a page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inspectSearchIndex: function (authorization, acceptLanguage, keyword, page, limit, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/cm/private/search_index_document/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (keyword !== undefined) {
                                localVarQueryParameter['keyword'] = keyword;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * [DEBUG] TriggerCASCommand
         * @summary [DEBUG] TriggerCASCommand
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerCasCommand: function (tenantSlug, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('triggerCasCommand', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/trigger_cas_command/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * [DEBUG] TriggerCommand
         * @summary [DEBUG] TriggerCommand
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerCommand: function (tenantSlug, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('triggerCommand', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/trigger_command/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update content statistic.
         * @summary Update content statistic.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentStatistic: function (authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/cm/private/statistics/sync";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.DebugApiAxiosParamCreator = DebugApiAxiosParamCreator;
/**
 * DebugApi - functional programming interface
 * @export
 */
var DebugApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.DebugApiAxiosParamCreator)(configuration);
    return {
        /**
         * debug request api
         * @summary debug request api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugRequestApi: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.debugRequestApi(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * debug request api
         * @summary debug request api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugRequestApi_1: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.debugRequestApi_1(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get mobi event logs
         * @summary Get mobi event logs
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [startTime] start time
         * @param {number} [endTime] End time
         * @param {string} [vasCode] VAS Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMobileEventLogs: function (tenantSlug, authorization, acceptLanguage, startTime, endTime, vasCode, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMobileEventLogs(tenantSlug, authorization, acceptLanguage, startTime, endTime, vasCode, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * [BACKEND-ONLY] Get profile By UUCode
         * @summary [BACKEND-ONLY] Get profile By UUCode
         * @param {string} uuCode uu_code
         * @param {string} [authorization] super backend secret key
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileByUucode: function (uuCode, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getProfileByUucode(uuCode, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Inspect request metadata
         * @summary Inspect request metadata
         * @param {string} secretToken
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inspectRequestMetadata: function (secretToken, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.inspectRequestMetadata(secretToken, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * [ADMIN-TOOL-ONLY] Test Search
         * @summary [ADMIN-TOOL-ONLY] Test Search
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [keyword] is ajax
         * @param {number} [page] number page
         * @param {number} [limit] limit of a page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inspectSearchIndex: function (authorization, acceptLanguage, keyword, page, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.inspectSearchIndex(authorization, acceptLanguage, keyword, page, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * [DEBUG] TriggerCASCommand
         * @summary [DEBUG] TriggerCASCommand
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerCasCommand: function (tenantSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.triggerCasCommand(tenantSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * [DEBUG] TriggerCommand
         * @summary [DEBUG] TriggerCommand
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerCommand: function (tenantSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.triggerCommand(tenantSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update content statistic.
         * @summary Update content statistic.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentStatistic: function (authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateContentStatistic(authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.DebugApiFp = DebugApiFp;
/**
 * DebugApi - factory interface
 * @export
 */
var DebugApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.DebugApiFp)(configuration);
    return {
        /**
         * debug request api
         * @summary debug request api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugRequestApi: function (options) {
            return localVarFp.debugRequestApi(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * debug request api
         * @summary debug request api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugRequestApi_1: function (options) {
            return localVarFp.debugRequestApi_1(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get mobi event logs
         * @summary Get mobi event logs
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [startTime] start time
         * @param {number} [endTime] End time
         * @param {string} [vasCode] VAS Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMobileEventLogs: function (tenantSlug, authorization, acceptLanguage, startTime, endTime, vasCode, options) {
            return localVarFp.getMobileEventLogs(tenantSlug, authorization, acceptLanguage, startTime, endTime, vasCode, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * [BACKEND-ONLY] Get profile By UUCode
         * @summary [BACKEND-ONLY] Get profile By UUCode
         * @param {string} uuCode uu_code
         * @param {string} [authorization] super backend secret key
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileByUucode: function (uuCode, authorization, acceptLanguage, options) {
            return localVarFp.getProfileByUucode(uuCode, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Inspect request metadata
         * @summary Inspect request metadata
         * @param {string} secretToken
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inspectRequestMetadata: function (secretToken, authorization, acceptLanguage, options) {
            return localVarFp.inspectRequestMetadata(secretToken, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * [ADMIN-TOOL-ONLY] Test Search
         * @summary [ADMIN-TOOL-ONLY] Test Search
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [keyword] is ajax
         * @param {number} [page] number page
         * @param {number} [limit] limit of a page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inspectSearchIndex: function (authorization, acceptLanguage, keyword, page, limit, options) {
            return localVarFp.inspectSearchIndex(authorization, acceptLanguage, keyword, page, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * [DEBUG] TriggerCASCommand
         * @summary [DEBUG] TriggerCASCommand
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerCasCommand: function (tenantSlug, options) {
            return localVarFp.triggerCasCommand(tenantSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * [DEBUG] TriggerCommand
         * @summary [DEBUG] TriggerCommand
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerCommand: function (tenantSlug, options) {
            return localVarFp.triggerCommand(tenantSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update content statistic.
         * @summary Update content statistic.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentStatistic: function (authorization, acceptLanguage, options) {
            return localVarFp.updateContentStatistic(authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.DebugApiFactory = DebugApiFactory;
/**
 * DebugApi - object-oriented interface
 * @export
 * @class DebugApi
 * @extends {BaseAPI}
 */
var DebugApi = /** @class */ (function (_super) {
    __extends(DebugApi, _super);
    function DebugApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * debug request api
     * @summary debug request api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebugApi
     */
    DebugApi.prototype.debugRequestApi = function (options) {
        var _this = this;
        return (0, exports.DebugApiFp)(this.configuration).debugRequestApi(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * debug request api
     * @summary debug request api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebugApi
     */
    DebugApi.prototype.debugRequestApi_1 = function (options) {
        var _this = this;
        return (0, exports.DebugApiFp)(this.configuration).debugRequestApi_1(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get mobi event logs
     * @summary Get mobi event logs
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {number} [startTime] start time
     * @param {number} [endTime] End time
     * @param {string} [vasCode] VAS Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebugApi
     */
    DebugApi.prototype.getMobileEventLogs = function (tenantSlug, authorization, acceptLanguage, startTime, endTime, vasCode, options) {
        var _this = this;
        return (0, exports.DebugApiFp)(this.configuration).getMobileEventLogs(tenantSlug, authorization, acceptLanguage, startTime, endTime, vasCode, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * [BACKEND-ONLY] Get profile By UUCode
     * @summary [BACKEND-ONLY] Get profile By UUCode
     * @param {string} uuCode uu_code
     * @param {string} [authorization] super backend secret key
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebugApi
     */
    DebugApi.prototype.getProfileByUucode = function (uuCode, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.DebugApiFp)(this.configuration).getProfileByUucode(uuCode, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Inspect request metadata
     * @summary Inspect request metadata
     * @param {string} secretToken
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebugApi
     */
    DebugApi.prototype.inspectRequestMetadata = function (secretToken, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.DebugApiFp)(this.configuration).inspectRequestMetadata(secretToken, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * [ADMIN-TOOL-ONLY] Test Search
     * @summary [ADMIN-TOOL-ONLY] Test Search
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [keyword] is ajax
     * @param {number} [page] number page
     * @param {number} [limit] limit of a page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebugApi
     */
    DebugApi.prototype.inspectSearchIndex = function (authorization, acceptLanguage, keyword, page, limit, options) {
        var _this = this;
        return (0, exports.DebugApiFp)(this.configuration).inspectSearchIndex(authorization, acceptLanguage, keyword, page, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * [DEBUG] TriggerCASCommand
     * @summary [DEBUG] TriggerCASCommand
     * @param {string} tenantSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebugApi
     */
    DebugApi.prototype.triggerCasCommand = function (tenantSlug, options) {
        var _this = this;
        return (0, exports.DebugApiFp)(this.configuration).triggerCasCommand(tenantSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * [DEBUG] TriggerCommand
     * @summary [DEBUG] TriggerCommand
     * @param {string} tenantSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebugApi
     */
    DebugApi.prototype.triggerCommand = function (tenantSlug, options) {
        var _this = this;
        return (0, exports.DebugApiFp)(this.configuration).triggerCommand(tenantSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update content statistic.
     * @summary Update content statistic.
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebugApi
     */
    DebugApi.prototype.updateContentStatistic = function (authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.DebugApiFp)(this.configuration).updateContentStatistic(authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return DebugApi;
}(base_1.BaseAPI));
exports.DebugApi = DebugApi;
