import ReactDOM from 'react-dom';
import React from 'react';
import videojs from 'video.js';
import jquery from 'jquery';
import ActionLiveEvent from './ActionLiveEvent';
import _ from 'lodash';
import { canWatchContent } from '~core/method/contentMethod';

import { Provider } from 'react-redux';
import { store, persistor } from '~store';
import { BrowserRouter, Router } from 'react-router-dom';
import { history } from '~store/history';
import { provideComponent } from '~root';

const vjsComponent = videojs.getComponent('Component');

class LiveEvent extends vjsComponent {
  constructor(player, options) {
    super(player, options);
    const $player = jquery(this.el());
    const { bigPlayButton } = player;
    player.on('ready', () => {
      $player.parent().find('.vjs-control-bar').addClass('pending__controlbar');
      jquery(bigPlayButton.el_).addClass('pending__button hide');
      $player.parent().find('.vjs-big-pause-button').addClass('hide');
      this.mount();
    });
    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }
  // TODO : Create class element for component render
  createEl() {
    const _liveEvent = videojs.createEl('div', {
      className: 'vjs-liveEvent',
    });
    return _liveEvent;
  }

  //TODO : Render your component
  mount() {
    const { component, entity, detail } = this.options_;
    provideComponent(
      <ActionLiveEvent
        player={this.player_}
        component={component}
        entity={entity}
        detail={detail}
      />,
      this.el(),
    );
  }
}

/**
 * Make sure to register the vjsComponent so Video JS knows it exists
 */
// TODO :  Add component into player
videojs.registerComponent('LiveEvent', LiveEvent);

export default LiveEvent;
