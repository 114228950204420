/* tslint:disable */
/* eslint-disable */
/**
 * ODX/V3 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OdxV1ProgramForEpisodeListServiceNameEnum = void 0;
exports.OdxV1ProgramForEpisodeListServiceNameEnum = {
    odk: 'odk',
    odc: 'odc',
    odv: 'odv',
    odl: 'odl',
    unknown_default_open_api: '11184809'
};
