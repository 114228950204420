import React, { useState } from 'react';
import { useGoogleLogin } from 'react-google-login';
import styled from 'styled-components';
import { getConfigByKey } from '~features/tenantConfig/services';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { loginGoogle } from '../../services';
import { ReactComponent as IconGoogle } from '~img/icons/projectw/signin/Google.svg';
import classnames from 'classnames';
import { canWatchContent } from '~core/method/contentMethod';
import {
  setIsShowVerifyEmailModal,
  setIsWelcome,
  setIsShowVerifyEmailPPV,
  setCheckVerifiedEmail,
  setIsShowSignInForm,
} from '~components/global/globalSlice';
import { isLoggedVerifiedEmail } from '~core/method/authMethod';
import { debug } from '~utils/utils';
import _ from 'lodash';
import { wFirebaseEvents } from '~services/analytics';
import { GroupSocial, ButtonW } from './loginButtonStyled';

const keyClient = getConfigByKey('integrations.loginGoogle');
const clientId = (keyClient && keyClient.ggId) || '';

const LoginGoogle = props => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const {
    dispatchLoginGoogle,
    followSubcriptions,
    target,
    checkfollowPayperviews,
    PpvParams,
    openShowPPV,
  } = props;
  const onSuccess = res => {
    let data = {
      token: res.tokenId,
    };
    setMessage(``);
    dispatchLoginGoogle(data)
      .then(res => {
        const {
          action_type,
          profile: { email },
        } = res;
        if (action_type === 'sign_in') {
          wFirebaseEvents.signInCompletedEvent({ email });
        } else {
          wFirebaseEvents.signUpCompletedEvent({ email });
        }
        if (target === 'subcription') return followSubcriptions && followSubcriptions(res);
        if (target === 'login')
          return checkfollowPayperviews && checkfollowPayperviews(PpvParams, res);
        if (target === 'projectw' && !_.isEmpty(res.profile)) {
          const { profile = {} } = res;
          if (!profile.email_confirmed && openShowPPV) {
            debug(() => {
              console.log('result google account', res);
            });
            dispatch(setIsShowVerifyEmailPPV(true));
            return;
          }
          if (!profile.email_confirmed && !openShowPPV) {
            debug(() => {
              console.log('result google account', res);
            });
            dispatch(setIsShowVerifyEmailModal(true));
            return;
          }
          dispatch(setIsShowVerifyEmailModal(false));
          dispatch(setIsShowVerifyEmailPPV(false));
          return;
        }
        window.location.reload();
      })
      .catch(error => {
        setMessage(`${error.message}`);
      });
  };

  const onFailure = res => {
    if (res.error === 'popup_closed_by_user' || res.error === 'idpiframe_initialization_failed')
      return;
    setMessage(`${res.error}`);
  };

  const { signIn } = useGoogleLogin(
    {
      onSuccess,
      onFailure,
      clientId,
      cookiePolicy: 'single_host_origin',
      isSignedIn: false,
    },
    () => {
      setMessage(``);
    },
  );
  if (!getConfigByKey('features.loginGoogle')) return null;
  return (
    <React.Fragment>
      <GroupSocial
        onClick={signIn}
        className={classnames('registerGoogle', `registerGoogle--${target}`)}
      >
        <IconGoogle />
        <ButtonW bsSize="large">{t('projectw__modal.SignIn.btn__google')}</ButtonW>
      </GroupSocial>
      <p className="registerGoogle--error error-data">{message}</p>
    </React.Fragment>
  );
};

const Button = styled('button')`
  &.registerGoogle {
    font-size: 10px;
    position: relative;
    padding: 1.3em 0;
    width: 100%;
    border: 1px solid #b3bec4;
    border-radius: 0.6em;
    background: #ffffff;
    &--login {
      padding: 1.1em 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .registerGoogle {
        &--icon {
          width: 2.5em;
          position: unset;
          transform: translateY(0%);
          margin-right: 0.7em;
        }
        &--title {
          color: #111212;
          font-size: 1.8em;
          font-weight: bold;
        }
      }
    }
    &--subcription {
      padding: 1em 0;
      margin-bottom: 1em;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 1em;
      border: 1px solid #c8d4db;
      .registerGoogle {
        &--icon {
          width: 3.2em;
          transform: translateY(0%);
          position: unset;
          margin-right: 0.7em;
        }
        &--title {
          color: #111212;
          font-size: 1.8em;
        }
      }
    }
  }
  .registerGoogle {
    &--error {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      color: #ed5858;
    }
    &--icon {
      width: 2.7em;
      transform: translateY(-50%);
      top: 50%;
      left: 1.2em;
      position: absolute;
    }
    &--title {
      color: #283237;
      font-size: 1.6em;
      font-weight: bold;
    }
  }
`;

const mapDispatchToProps = {
  dispatchLoginGoogle: data => loginGoogle(data),
};

const mapStateToProps = state => ({
  account: state.auth.account,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginGoogle);
