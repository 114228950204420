// Get the Component base class from Video.js
import { pushEventFirebase } from './../components/pushEventFirebase';
import videojs from 'video.js';
import jquery from 'jquery';

var Button = videojs.getComponent('Button');

// The videojs.extend function is used to assist with inheritance. In
// an ES6 environment, `class SeekNext extends Component` would work
// identically.

var Next = videojs.extend(Button, {
  // The constructor of a component receives two arguments: the
  // player it will be associated with and an object of options.
  constructor: function (player, options) {
    this.autoNext = false;
    // It is important to invoke the superclass before anything else,
    // to get all the features of components out of the box!
    Button.apply(this, arguments);
    // If a `text` option was passed in, update the text content of
    // the component.

    player.on('loadeddata', () => {
      setTimeout(() => {
        this.handleHidden();
      }, 0);
    });
    player.on('error', () => {
      this.handleHidden();
    });
  },

  // The `createEl` function of a component creates its DOM element.
  createEl: function () {
    const btn = videojs.dom.createEl('button', {
      className: 'vjs-next vjs-control vjs-button vjs-list-eps',
    });
    btn.innerHTML = '<i class="vjs-icon-next-item vjs-icon-placeholder" aria-hidden="true"></i>';
    return btn;
  },
  handleHidden: function () {
    const { episodes } = this.options_;
    const $this = jquery(this.el());
    const parentControlbar = $this.parent();
    const active = parentControlbar.find('.vjs-list-eps.active');
    this.currentEpisode = parseInt(active.attr('data-index')) + 1;
    if (!this.currentEpisode || !episodes) {
      return;
    }
    const $el = jquery(this.el());
    if (this.currentEpisode === episodes.length) {
      $el.addClass('vjs-episodes--hide').parents('.vjs-episodes').addClass('vjs-episode-last');
    } else {
      $el
        .removeClass('vjs-episodes--hide')
        .parents('.vjs-episodes')
        .removeClass('vjs-episode-last');
    }
  },
  handleClick: function (event) {
    const { episodes, component } = this.options_;
    const { entityDetail, isHasSubcription } = component.state;
    const $this = jquery(this.el());
    const parentControlbar = $this.parent();
    const active = parentControlbar.find('.vjs-list-eps.active');
    const next = active.next();
    const indexEpisode = this.currentEpisode;
    const { account } = component.props;
    const player_ = component.videoPlayer.player_;
    if (!episodes || indexEpisode < 0 || indexEpisode >= episodes.length) {
      return;
    }
    if ($this.hasClass('is-auto-next')) {
      $this.removeClass('is-auto-next');
    } else {
      pushEventFirebase(entityDetail, 'next');
    }
    const episode = episodes[indexEpisode];
    if (
      isHasSubcription ||
      episode.can_preview ||
      episode.is_watchable ||
      (account && account.profile.has_subscription)
    ) {
      //   next.removeClass('deactive').addClass('active')
      //   active.addClass('deactive').removeClass('active')
      next.click();
      this.handleHidden();
      // component._asyncGetVideo(episode.id)
    } else {
      if (player_.isFullscreen()) {
        player_.exitFullscreen();
      }
      if (component.videoPlayer) {
        const player = component.videoPlayer;
        player.pause();
      }
      next.click();
    }
  },
});

// Register the component with Video.js, so it can be used in players.
videojs.registerComponent('Next', Next);
