import styled from 'styled-components';

const FilterStyle = styled.div`
  position: relative;
  display: grid;
  hr {
    /* margin: 0; */
    border-color: #c9c5c5;
  }
  .panel-body {
    padding-top: 0;
    padding-bottom: 0;
  }
  .panel {
    border: none;
    box-shadow: none;
    border-radius: 0;
    margin: 0;
    background-color: ${({ theme }) => theme.body.background};
  }
  .panel-footer {
    border: none;
    background-color: transparent;
    padding-bottom: 0;
  }
  button {
    border: none;
    background-color: transparent;
  }

  .v-filter {
    display: ${props => (props.visible ? 'flex' : 'none')};
    font-size: 1rem;
    /* padding: 2em 1rem; */
    min-height: unset;
    background-color: ${({ theme }) => theme.body.background};
    &__right {
      width: 100%;
      &--ads {
        position: static;
        top: 2rem;
        right: 0;
        width: auto;
      }
    }
    &__left {
      .panel-body {
        padding-left: 0;
      }
    }
    &__items {
      .panel {
        border-left: 1px solid #c9c5c5;
      }
    }
    &--toggle {
      display: flex;
      align-items: center;
      position: relative;
      border: 1px solid #8c8c8c;
      border-radius: 6px;
      padding: 0.5rem 1rem 0.5rem 10px;
      max-height: 2.5rem;
      background: #f2f2f2;
      justify-content: space-between;
      svg {
        font-size: 1.25em;
        color: ${({ theme }) => theme.filter.colorTxt};
      }
    }
    &--title {
      font-size: 1em;
      color: ${({ theme }) => theme.filter.colorTxt};
      font-weight: 400;
      margin: 0 0 0 0.75rem;
      white-space: nowrap;
    }

    &--list,
    &--label {
      font-size: 1em;
      color: #666666;
      padding: 0;
      font-weight: normal;
    }
    &--row,
    &--list {
      display: flex;
      margin-bottom: 0.5em;
      flex-wrap: wrap;
    }
    &--item {
      cursor: pointer;
      color: ${({ theme }) => theme.filter.colorTxt};
      transition: all 0.25s;
      padding: 0.125em 1em;
      margin-bottom: 0.25em;
      &::first-letter {
        text-transform: capitalize;
      }
      &.active {
        background-color: ${({ theme }) => theme.filter.colorBtn};
        color: white;
        border-radius: 5px;
      }
      &:first-of-type {
        cursor: default;
        pointer-events: none;
      }
    }
    &--removeall {
      color: ${({ theme }) => theme.filter.colorTxt};
      padding: 0.125em 1em;
      border-radius: 0.35em;
      border: 2px solid ${({ theme }) => theme.filter.colorTxt};
      transition: all 0.25s;
      &:active {
        background-color: ${({ theme }) => theme.filter.colorBtnActive};
        color: white;
      }
    }
    &--label {
      margin-right: 0.5em;
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
  &.open {
    .v-filter {
      &--toggle {
        ion-icon {
          color: ${({ theme }) => theme.filter.colorBtn};
        }
      }
    }
  }
  @media (min-width: 768px) {
    padding-left: 15px;
  }
  @media (min-width: 992px) {
    padding: 0.25em 1em 1em 15px;
  }
  @media (min-width: 1280px) {
    .v-filter {
      /* min-height: ${props => `${!props.props.isHasSubcription ? `85px` : `0`}`}; */
      &--title {
        font-size: 16px;
      }
      &__right {
        &--ads {
          position: absolute;
          top: 2rem;
          right: 0;
          width: calc(100% - 15rem);
          .v-ads-banner {
            margin: 0px;
          }
        }
      }
    }
  }
`;

export default FilterStyle;
