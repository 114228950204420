import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const ModalStyled = styled(Modal)`
  &.project-w {
    font-family: var(--font);

    .modal-dialog {
      /* animation: modalSlide 0.7s ease-out forwards; */
      min-height: 100%;
      height: 100%;
      /* margin: 0; */
    }
    @keyframes modalSlide {
      from {
        transform: translateX(200%);
        opacity: 0;
      }

      to {
        transform: translateX(73%);
        opacity: 1;
      }
    }
    .modal-body {
      color: #000000 !important;
      padding: 3.5em 4em 6em !important;
    }
    .modal-content {
      min-height: 100%;
      border-radius: 0 !important;
      background: #fff !important;
      color: #000000 !important;
      /* max-width: 450px; */
      margin-left: auto;
    }
  }
  .project-w {
    width: 100%;
    font-family: var(--font);
    font-size: 12px;
    &--form {
      position: relative;
      &-confirm {
        text-align: start;
        padding: 0 7px;
        a:hover {
          text-decoration: none;
        }
        &:first-of-type {
          margin-top: 14px;
        }
        &:last-of-type {
          margin-bottom: 20px;
        }

        .MuiCheckbox-root {
          padding: 0 9px;
          color: #5a5a5a;
          &:hover {
            background-color: transparent;
          }
          svg {
            font-size: 27px;
            path {
              fill: #000;
              stroke: #fff;
            }
          }
        }
        .MuiFormControlLabel-label {
          font-family: var(--font);
          text-align: start;
          font-size: 13px;
          font-weight: 500;
          font-size: 13px;
          line-height: 133.5%;
          letter-spacing: 0.025em;
          color: #000000;
        }
      }
    }
    &--Body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      position: relative;
      top: 250px;
      background-color: #fff;
    }
    &--showPass {
      border: none;
      background: none;
      padding: 0;
      right: 0;
    }
    &--legal {
      text-transform: uppercase;
      font-size: 12px !important;
      color: #aeaeb2 !important;
      padding-left: 8px;
    }
    &--header {
      color: #000000 !important;
      font-family: var(--font);
    }
    &--title {
      margin-bottom: 20px;
    }
    &--titleSecond {
      font-size: 13px !important;
      font-weight: 400 !important;
      margin: 20px 0 40px 0px;
      letter-spacing: 0.7px;
      max-width: 450px;
      span {
        color: #000;
        margin-top: 100px;
        letter-spacing: 2px;
        font-size: 16px !important;
        margin-left: 5px;
        border-bottom: 1.5px solid #000;
      }
      @media (max-width: 767px) {
        max-width: 100%;
        width: 100%;
      }
    }
    &--or {
      margin: 60px 0 60px 0 !important;
      color: #aeaeb2 !important;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-weight: 400;
      &:after,
      &:before {
        position: absolute;
        content: '';
        height: 1px;
        background-color: #c7c7cc;
        width: 32%;
        top: 50%;
        left: 2em;
      }
    }
    &--forgotPassword {
      margin: 16px 10px;
      font-weight: 500;
      font-size: 12px;
      color: #000000;
      letter-spacing: 0.05em;
    }
    &--signIn {
      color: #c7c7cc;
      background-color: #e5e5ea;
      font-weight: 600;
      font-size: 13px;
      letter-spacing: 0.025em;
      text-transform: uppercase !important;
      border-radius: 0 !important;
      padding: 16.5px 0;
      height: 3.8em;
      width: 100%;
      border: none;
      margin-top: 5px;
      margin-bottom: 10px;
      &:focus,
      &:active,
      &:hover {
        border: none;
        outline: none !important;
        border-color: white !important;
        pointer-events: none;
        background-color: #e5e5ea;
        color: #c7c7cc;
      }
      &:not(:disabled) {
        background: #000;
        color: #fff;
        pointer-events: all;
      }
    }
    &--watchmore {
      color: #fff;
      background-color: #000;
      font-weight: 600;
      font-size: 13px;
      letter-spacing: 0.025em;
      text-transform: uppercase !important;
      border-radius: 0 !important;
      padding: 16.5px 0;
      width: 100%;
      border: none;
      margin-top: 5px;
      &:focus,
      &:active,
      &:hover {
        border: none;
        background-color: #000;
        color: #fff !important;
      }
    }
    .Correct-True {
      background-color: #000;
    }
    &--btnFacebook {
      border-radius: 0 !important;
      color: #000000 !important;
    }
  }
  &.v-loginForm {
    &.project-w {
      padding-right: 0 !important;
      .modal-dialog {
        transform: translateX(100%);
        transition: transform 0.7s ease-out;
      }
      &.fade.in .modal-dialog {
        transform: translateX(0);
      }
    }
    z-index: 9999;
    font-size: 10px;
    .modal-header {
      position: absolute;
      right: 0;
      padding: 20px 20px 0 0;
      border: none;
      font-size: 2.4em;
      cursor: pointer;
      z-index: 999;
    }

    .modal-content {
      background: #1e232a;
      border-radius: 0.8em;
      color: #fff;
    }
    .modal-body {
      padding: 3.5em 2.5em 12em;
    }
  }
  .v-loginForm {
    color: #fff;
    &--close {
      color: #9a9a9a;
      width: 1em;
    }
    &--row {
      display: flex;
      justify-content: center;
      text-align: center;
      flex-direction: column;
    }
    &--header {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      flex-direction: column;
    }
    &--title {
      font-size: 2.4em;
      font-weight: 500;
      font-family: var(--font);
    }
    &--odm {
      font-size: 2.4em;
      font-weight: bolder;
      margin-bottom: 0.8em;
    }
    &--btn {
      background: ${({ theme }) => theme.primaryColor};
      color: rgb(255, 255, 255);
      font-size: 1.5rem;
      width: 100%;
      margin: 0.75rem 0px;
      padding: 1rem 0px;
      text-transform: capitalize;
      font-weight: 300;
      border: none;
      &__gray {
        background: #7d7d7d;
      }
    }
    &--or {
      color: #949494;
      position: relative;
      font-size: 1.4em;
      margin-bottom: 0.55em;
      &:after,
      &:before {
        position: absolute;
        content: '';
        height: 1px;
        border: 1px solid #e6ecef;
        width: 32%;
        top: 50%;
        left: 2em;
      }
      &:after {
        left: unset;
        right: 2em;
      }
    }
    &--authTemplate {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
    }
    &--legal {
      color: #b8b6b6;
      font-size: 1.4em;
      font-weight: 500;
    }
    &--showPass {
      position: absolute;
      right: 0.35em;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0.2em;
      font-size: 3em;
      color: #6e6e6e;
      cursor: pointer;
    }
    &--forgot {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.6em;
      &-checkbox {
        font-size: 1.4em;
        color: #b8b6b6;
        margin: 0;
        input {
          display: none;
          + span {
            position: relative;
            padding-left: 1rem;
            &:before,
            &:after {
              font: normal normal normal 14px/1 FontAwesome;
              display: inline-block;
              position: absolute;
              background-color: #f5f5f5;
              border: 1px solid ${({ theme }) => theme.primaryColor};
              content: '';
              width: 20px;
              height: 20px;
              top: 50%;
              right: 100%;
              transform: translate(0, -50%);
              border-radius: 2px;
            }
          }
        }
        input:checked {
          + span {
            &:before {
              border: 1px solid ${({ theme }) => theme.primaryColor};
            }
            &:after {
              color: ${({ theme }) => theme.primaryColor};
              background-color: #fff;
              content: '\f00c';
              font-size: 10px;
              line-height: 18px;
            }
          }
        }
      }
    }
    &--forgotPassword {
      display: flex;
      color: ${({ theme }) => theme.fourColor};
      padding: 0;
      font-size: 1.4em;
      font-weight: 400;
    }
    &--signIn {
      font-size: 1.8em;
      color: #fff;
      text-transform: capitalize;
      padding: 1em 0;
      border-radius: 10px;
      width: 100%;
      border: none;
      background: ${({ theme }) => theme.primaryColor};
      margin-top: 0.89em;
      &:focus,
      &:active,
      &:hover {
        background: ${({ theme }) => theme.primaryColor};
        border: none;
      }
    }
    &--signUp {
      width: 100%;
      margin-top: 47px;
      font-size: 1.8em;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &__btn {
        color: #fff;
        text-transform: capitalize;
        padding: 0.835em 0;
        border-radius: 10px;
        width: 100%;
        border: 1px solid #d8d8d8;
        background: unset;
        &:focus,
        &:active,
        &:hover {
          background: unset;
          border: 1px solid #d8d8d8;
        }
      }
    }
  }

  @media (min-width: 576px) {
    &.v-loginForm:not(.project-w) {
      .modal-dialog {
        margin: auto;
      }
    }
  }
  @media (min-width: 768px) {
    &.v-loginForm {
      .modal-dialog {
        max-width: 58.8em;
      }
      &.project-w {
        .modal-dialog {
          margin-right: 0;
          margin-top: 0;
          margin-bottom: 0;
          .modal-content {
            max-width: 450px;
          }
        }
      }
      .modal-body {
        padding: 3.5em 8em 6em;
      }
    }
    .v-loginForm {
      &--or {
        &:after,
        &:before {
          width: 38%;
        }
      }
    }
  }
  @media (min-width: 1440px) {
    &.v-loginForm {
      &.project-w {
        &.fade.in .modal-dialog {
          transform: translateX(0);
          /* margin-right: calc((100vw - 1440px) / 2 - 10px); */
        }
      }
    }
  }

  @media (max-width: 991px) {
    &.project-w {
      padding-left: 0px !important;
      #close {
        display: block !important;
        font-size: 15px;
        width: 25px;
        height: 25px;
        position: relative;
        left: 90%;
        top: 10px;
      }
      .modal-dialog {
        /* animation: MobilemodalSlide 0.7s ease-out forwards !important; */
      }
      @keyframes MobilemodalSlide {
        from {
          transform: translateX(200%);
          opacity: 0;
        }
        to {
          transform: translateX(0%);
          opacity: 1;
        }
      }
      .project-w--titleSecond {
        font-size: 15px !important;
        padding: 0;
        span {
          font-size: 12px !important;
        }
      }
    }
  }
  @media (max-width: 360px) {
    &.project-w {
      .project-w--titleSecond {
        /* padding: 0 75px; */
      }
    }
  }
`;
