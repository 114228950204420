import React from 'react';
import { getConfigByKey } from '~features/tenantConfig/services';
import { EPISODE_TYPE } from './../constants';
import { ReactTitle } from 'react-meta-tags';

const TitleSeo = React.memo(props => {
  const { infoShow, entityDetail } = props;
  let title = infoShow
    ? infoShow.title
    : entityDetail && entityDetail.type !== EPISODE_TYPE
    ? entityDetail.title + `- Watch Online | ${getConfigByKey('site_name')}`
    : ` Watch${
        getConfigByKey('ui_theme') === 'projectw' ? '' : ' Vietnam '
      }Movies and Shows | ${getConfigByKey('site_name')}`;
  return <ReactTitle title={title + ` - Watch Online | ${getConfigByKey('site_name')}`} />;
});

export default TitleSeo;
