/* tslint:disable */
/* eslint-disable */
/**
 * Vimai Core Service UI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserHistoryCurrencyEnum = exports.UserHistoryTypeEnum = void 0;
exports.UserHistoryTypeEnum = {
    Code: 'CODE',
    Subscribe: 'SUBSCRIBE',
    ChangePlan: 'CHANGE_PLAN',
    Renew: 'RENEW',
    Cancel: 'CANCEL',
    Suspend: 'SUSPEND',
    Reactive: 'REACTIVE'
};
exports.UserHistoryCurrencyEnum = {
    Usd: 'usd',
    Vnd: 'vnd'
};
