import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';

import $ from 'jquery';
import WatchMore from '~features/homepage/projectw/watchMore';
import { getConfigByKey } from '~features/tenantConfig/services';
import { getThumbnail9_5 } from '~core/method/contentMethod';
import MobileListUpComing from './listUpComingMobile';
import { Root } from '../style/homepage';
import { ReactComponent as ArrowBackIcon } from '../../../../assets/icons/projectw/icon_page_back.svg';
import { ReactComponent as ArrowForwardIcon } from '../../../../assets/icons/projectw/icon_page_next.svg';
import { useMediaQuery } from 'react-responsive';
import { detectLanguageByText } from '~core/utils';

const useStyles = makeStyles({
  contextItem: {
    borderBottom: '0.5px solid #000',
    cursor: 'pointer',
    color: '#000000',
    padding: 0,
    paddingLeft: 15,
    position: 'relative',
    transition: '0.2s all ease-in-out 0s',
    '&.active': {
      color: '#fff',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      top: 'unset',
      left: 0,
      width: '100%',
      height: 0,
      backgroundColor: '#000',
      transition: '0.1s all ease-in-out 0s',
    },
    '&.active:before': {
      bottom: 'unset',
      top: 0,
      height: '100%',
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingRight: '15px',
    marginTop: '10px',
    zIndex: 99,
    '& :first-child': {
      flexBasis: '55%',
      marginRight: '16px',
      '@media(max-width: 1439px)': {
        flexBasis: '64%',
      },
    },
    '& > p': {
      lineHeight: '29px',
    },
  },
  name: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    fontFamily: 'var(--font)',
    letterSpacing: '-0.005em',
  },
  contextImage: {
    textDecoration: 'none',
    textAlign: 'left',
    width: '315px',
    padding: 0,
    flexShrink: 0,
    '& .date-time': {
      marginTop: '16px',
      letterSpacing: '0.05em',
      fontSize: '16px',
    },
    '& .wrap-image': {
      paddingTop: 'calc(5/9*100%)',
      position: 'relative',
      '& .image': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
    },
  },
  contextAction: {
    textDecoration: 'none',
    textAlign: 'left',
    padding: 0,
    marginTop: '1.5em',
  },
  icon: {
    fontFamily: 'Neue Haas Grotesk Display Pro',
    fontSize: 60,
  },
  iconDisable: {
    fontFamily: 'Neue Haas Grotesk Display Pro',
    fontSize: 60,
    color: 'gray',
  },
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  linkColor: {
    color: '#000',
    '&:hover': {
      color: '#000',
    },
  },
  btnPaging: {
    width: 80,
    height: 80,
    // backgroundColor: '#999999',
    borderRadius: 40,
    '&:hover': {
      // backgroundColor: '#999999',
      backgroundColor: 'white',
      cursor: 'pointer',
    },
  },
  btnPagingDisable: {
    width: 80,
    height: 80,
    // backgroundColor: '#d6d6d6',
    borderRadius: 40,
    '& path': {
      stroke: '#AEAEB2',
    },
    '&:hover': {
      pointerEvents: 'none',
      cursor: 'none',
      backgroundColor: 'white',
    },
    '&:focus': {
      backgroundColor: 'white',
      pointerEvents: 'none',
    },
    '&:active': {
      backgroundColor: 'white',
      pointerEvents: 'none',
    },
  },
});

const getImage = (record = null) => getThumbnail9_5.call(record);

const getCategory = record => {
  if (!record || !record.content_categories || !record.content_categories.length) {
    return {};
  }
  return record.content_categories[0];
};

const getLandingPageLink = record => {
  if (!record || !record.slug) {
    return '#';
  }
  return `/${getConfigByKey('currentLanguage')}/landing/${record.slug}`;
};

const formatDate = dateString => {
  if (!dateString) {
    return '';
  }
  return moment(dateString, 'YYYY-MM-DD').format('YYYY');
};

const LIMIT_PAGE = 16;

function ListUpComing(props) {
  const { contents = [] } = props;
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [indexItem, setIndexItem] = useState(0);
  const [pageSize, setPageSize] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [currentContentHover, setCurrentContentHover] = useState({});
  const isDisableNext = pageSize === totalPage;
  const isDisablePrev = pageSize === 1;
  const [hasPaging, setHasPaging] = useState(contents.length > LIMIT_PAGE);
  const [isMobileDetect, setIsMobileDetect] = useState(checkIsMobile());
  const [watchMore, setWatchMore] = useState(false);
  const isDeskTopDetect = useMediaQuery({
    query: '(min-width: 768px)',
  });

  useEffect(() => {
    const $body = $('body');
    const $window = $(window);
    $body.on('mousewheel', handleMouseWheel);
    $window.on('resize', () => {
      setIsMobileDetect(old => {
        const isMobile = checkIsMobile();

        return old === isMobile ? old : isMobile;
      });
    });
    return () => {
      $body.off();
      $window.off();
    };
  }, []);

  function handleMouseWheel(event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    const x = event.clientX;
    const y = event.clientY;

    const el = $(document.elementFromPoint(x, y));
    let dataIndex = el.attr('data-index');
    if (!dataIndex) {
      const parent = el.parents('.hover-event');
      dataIndex = parent.attr('data-index');
    }
    if (dataIndex) {
      onMouseOverItem(parseInt(dataIndex));
    }
  }
  function checkIsMobile() {
    return window.innerWidth < 768;
  }

  function onMouseOverItem(index) {
    if (indexItem != index) {
      setIndexItem(oldIndex => (oldIndex === index ? oldIndex : index));
    }
  }

  function onHandleNext() {
    if (pageSize + 1 > totalPage) return;
    const { data: result } = onPaginatedItems(contents, pageSize + 1);
    setData(result);
    setPageSize(pageSize + 1);
  }

  function onHandlePrev() {
    if (pageSize - 1 < 0) return;
    const { data: result } = onPaginatedItems(contents, pageSize - 1);
    setData(result);
    setPageSize(pageSize - 1);
  }

  useEffect(() => {
    const { data: result, total_pages } = onPaginatedItems(contents, pageSize);
    setData(result);
    setTotalPage(total_pages);
    setHasPaging(contents.length > LIMIT_PAGE);
    if (_.isEmpty(contents)) {
    }
  }, [contents]);

  useEffect(() => {
    const content = (data && data[indexItem]) || false;
    if (!content) {
      return;
    }
    setCurrentContentHover(oldContent => {
      if (oldContent.id === content.id) {
        return oldContent;
      }
      return content;
    });
  }, [indexItem, data]);

  function onPaginatedItems(items, page, pageSize) {
    const pg = page || 1;
    const pgSize = pageSize || LIMIT_PAGE;
    const offset = (pg - 1) * pgSize;
    const pagedItems = _.drop(items, offset).slice(0, pgSize);
    return {
      page: pg,
      pageSize: pgSize,
      total: items.length,
      total_pages: Math.ceil(items.length / pgSize),
      data: pagedItems,
    };
  }

  const handleHover = event => {
    try {
      const { currentTarget } = event;

      const imageHover = window.document.querySelector('.image-hover');

      const listShow = window.document.querySelector('.list--show');

      const targetBoundingClient = currentTarget.getBoundingClientRect();
      const listShowBoudingClient = (listShow && listShow.getBoundingClientRect()) || 0;
      const imageHoverBoudingClient = imageHover.getBoundingClientRect();

      if (
        targetBoundingClient.top + imageHoverBoudingClient.height >
        listShowBoudingClient.bottom
      ) {
        $(imageHover).css({
          top:
            targetBoundingClient.top -
            listShowBoudingClient.top -
            (imageHoverBoudingClient.height - targetBoundingClient.height),
        });
        return;
      }
      $(imageHover).css({
        top: Math.floor(targetBoundingClient.top) - Math.floor(listShowBoudingClient.top),
      });
    } catch (error) {}
  };
  if (!isDeskTopDetect) return <MobileListUpComing {...props} />;
  return (
    <Root>
      <div className={`${classes.contextImage}`}>
        <Link className={classes.linkColor} to={getLandingPageLink(currentContentHover)}>
          <div
            className="image-hover"
            style={{
              position: 'relative',
              top: '0',
              transition: 'top 0.25s ease-out',
            }}
          >
            <div className="wrap-image">
              {getImage(currentContentHover) && (
                <img
                  className="image"
                  alt=""
                  src={getImage(currentContentHover)}
                  key={getImage(currentContentHover)}
                />
              )}
            </div>
            {currentContentHover.release_year && (
              <Col xs={6} sm={6}>
                <div className="date-time">
                  {/* {formatDate(currentContentHover.release_date || '')} */}
                  {currentContentHover.release_year}
                </div>
              </Col>
            )}
          </div>
          {/* <Col xs={6} sm={6}>
            <div className="date-time">7pm</div>
          </Col> */}
        </Link>
      </div>
      <div
        style={{
          textDecoration: 'none',
          textAlign: 'left',
          padding: 0,
          flexGrow: '1',
        }}
      >
        <List dense={false} component="div" disablePadding className="list">
          <div className="list--draft">
            {Array.from(Array(hasPaging ? LIMIT_PAGE : contents.length).keys()).map(item => (
              <Link key={item} className={classes.link} to="#">
                <ListItem
                  // key={item}
                  component="div"
                  className={classes.contextItem}
                >
                  <ListItemText
                    className={classes.item}
                    primary={
                      <Typography className={classes.name}>
                        {(data[item] && data[item].title) || ''}
                      </Typography>
                    }
                    secondary={
                      <Typography className={classes.name}>
                        {(data[item] && data[item].name) || ''}
                      </Typography>
                    }
                  />
                </ListItem>
              </Link>
            ))}
          </div>
          <div className={`list--show ${hasPaging ? 'paging' : ''}`}>
            {data.map((item, index) => (
              <Link
                onMouseEnter={handleHover}
                key={item.id}
                className={classes.link}
                to={getLandingPageLink(item)}
              >
                <ListItem
                  component="div"
                  className={`${classes.contextItem} ${
                    index === indexItem ? 'active' : ''
                  } hover-event`}
                  onMouseEnter={event => {
                    onMouseOverItem(index);
                  }}
                  data-index={index}
                >
                  <ListItemText
                    className={classes.item}
                    primary={<Typography className={classes.name}>{item.title}</Typography>}
                    secondary={
                      <Typography
                        className={`${classes.name} ${
                          detectLanguageByText(getCategory(item).name || '') + '-font'
                        }`}
                      >
                        {getCategory(item).name || ''}
                      </Typography>
                    }
                  />
                  <div className="image-hidden">
                    <img src={getImage(item)} />
                  </div>
                </ListItem>
              </Link>
            ))}
          </div>
        </List>
        <div className="actions">
          {hasPaging ? (
            <div className={classes.contextAction} style={{ marginBottom: '5.5em' }}>
              <Button
                onClick={onHandlePrev}
                className={isDisablePrev ? classes.btnPagingDisable : classes.btnPaging}
                disableRipple
              >
                <ArrowBackIcon className={classes.icon} />
              </Button>
              <Button
                onClick={onHandleNext}
                // className={classes.btnPaging}
                disableRipple
                style={{ marginLeft: 15 }}
                className={isDisableNext ? classes.btnPagingDisable : classes.btnPaging}
              >
                <ArrowForwardIcon />
              </Button>
            </div>
          ) : (
            <span />
          )}
          <WatchMore watchMore={watchMore} setWatchMore={setWatchMore} />
        </div>
      </div>
    </Root>
  );
}

export default ListUpComing;
