/* tslint:disable */
/* eslint-disable */
/**
 * ODX/V3 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OdxV1AgeRatingNameEnum = exports.OdxV1AgeRatingLabelEnum = void 0;
exports.OdxV1AgeRatingLabelEnum = {
    All: 'All',
    PG: 'PG',
    _12: '12+',
    _13: '13+',
    _14: '14+',
    _15: '15+',
    _16: '16+',
    _17: '17+',
    _18: '18+',
    _19: '19+',
    NR: 'NR',
    unknown_default_open_api: '11184809'
};
exports.OdxV1AgeRatingNameEnum = {
    All: 'All',
    PG: 'PG',
    R12: 'R12',
    R13: 'R13',
    R14: 'R14',
    R15: 'R15',
    R16: 'R16',
    R17: 'R17',
    R18: 'R18',
    R19: 'R19',
    NR: 'NR',
    unknown_default_open_api: '11184809'
};
