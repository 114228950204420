import moment from 'moment';

export default function getSlugEpgs(schedule, timelineFrom = null) {
  let breakFunc = false;
  let programSlug = '';
  let ribbonSlug = '';
  let detail = '';
  schedule.ribbons.some((rb, i) => {
    schedule.channels[rb.id].some(cn => {
      if (cn.restriction) return;
      breakFunc = schedule.epgs[cn.id].some(res => {
        const startAt = res.start_at;
        const endAt = res.end_at;
        const { real_duration } = res;
        const nowTime = moment();
        const currentPosition = parseInt(nowTime.unix() - startAt);
        if (
          parseInt(startAt) <= nowTime.unix() &&
          parseInt(endAt) > nowTime.unix() &&
          !res.is_blackout &&
          currentPosition > 0 &&
          real_duration > currentPosition
        ) {
          detail = res;
          return true;
        }
      });
      if (breakFunc) {
        programSlug = cn.id;
        return true;
      }
    });
    ribbonSlug = rb.id;
    if (breakFunc) return true;
  });
  return {
    programSlug,
    ribbonSlug,
    detail,
  };
}
