import React from 'react';
import { withTranslation } from 'react-i18next';
import Slider from 'react-slick';
import Ribbons from '~components/ribbon/styled';
import RibbonItem from '~components/ribbon/components/ribbonItem';
import { settingSlide } from '~components/ribbon/functions/initSlide';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SliderRibbon = React.memo(props => {
  const { topTenLanding, RibbonsSide, topContents } = props;
  if (topTenLanding && topContents.length) {
    return (
      <Ribbons className="ribbonContainer">
        <p className="v-ribbon--top-content-title">Top 10</p>
        <Slider {...settingSlide(props)}>
          {topContents.map((item, index) => {
            return <RibbonItem item={item} ribbon={topContents} index={index} key={index} />;
          })}
        </Slider>
      </Ribbons>
    );
  }
  if (!topTenLanding && RibbonsSide.length) {
    return RibbonsSide.map((items, index) => {
      if (!items.items.length) return null;
      return (
        <Ribbons className="ribbonContainer">
          <p className="v-ribbon--top-content-title">{items.name}</p>
          <Slider {...settingSlide(props)}>
            {items.items.map((item, index) => {
              return <RibbonItem item={item} ribbon={items} index={index} key={index} />;
            })}
          </Slider>
        </Ribbons>
      );
    });
  }
  return null;
});

export default withTranslation()(SliderRibbon);
