import { isProjectW } from '~core/method/authMethod';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
html {
  background: ${({ theme }) => (isProjectW() ? '' : theme.body.background)};
  &.smartbanner-show{
    .rootContainer .v-landing{
      margin-top: 0!important;
    }
  }
  body {
      background: ${({ theme }) => theme.body.background};
      font-family: ${({ theme }) => theme.body.font};
      .rootContainer {
        background: ${({ theme }) => theme.homePage.background};
        font-family: ${({ theme }) => theme.body.font};
        .v-home__container,
        .ribbonPageContainer,
        .ribbonContainer{
          background: ${({ theme }) => theme.homePage.backgroundRibbon};
        }
        .ribbonHeadAll, .texttruncate {
          color: ${({ theme }) => theme.ribbon.itemNameColor};
          display: ${({ theme }) => theme.ribbon.displayTitleItem};
        }
        .video-js {
          font-family: ${({ theme }) => theme.body.font};
        }
      }
      .smartbanner{
        background: #f5f5f5;
        height: 110px;
        margin: auto;
        box-shadow: none;
        .smartbanner-container{
          white-space: nowrap;
          display: flex;
          align-items: center;
          height: 100%;
        }
        .smartbanner-close {
          color: #9b9b9b;
          background: none;
          box-shadow: none;
          font-size: 32px;
          margin:0 20px;
          font-weight: normal;
          text-shadow: none;
        }
        .smartbanner-icon{
          margin-right: 20px; 
        }
        .smartbanner-info{
          text-shadow: none;
          color: #9b9b9b;
          font-size: 14px;
          font-weight: normal;
          .smartbanner-title {
            color: black;
            font-size: 18px;
            margin-bottom: 10px;
          }
        }
        .smartbanner-button {
          box-shadow: none;
          background: none;
          text-shadow: none;
          .smartbanner-button-text {
            background: none;
            color: ${({ theme }) => theme.primaryColor};
            font-size: 16px;
          }
        }
      }
    }
    body.v-box,body.v-forgot{
      .rootContainer {
        background: ${({ theme }) => theme.plans.background};
      }
      footer{
        background: ${({ theme }) => theme.body.background};
      }
    }
}

`;

export default GlobalStyles;
