import React from 'react';
import { StyledChannelNavigator } from './styled';
import { ReactComponent as FirstPageIcon } from '../../../assets/icons/first_page.svg';
import { ReactComponent as ArrowBackIcon } from '../../../assets/icons/arrow_back.svg';
import { ReactComponent as ArrowForwardIcon } from '../../../assets/icons/arrow_forward.svg';
import { ReactComponent as FastBackward } from '../../../assets/icons/fast-arrow-left.svg';
import { ReactComponent as FastForward } from '../../../assets/icons/fast-arrow-right.svg';
import { IconButton } from '@material-ui/core';
import { addMinutes, format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

function RibbonChannelNavigator({ landmark, hourLandmarks, onFirstPage, onChange, children }) {
  const { t } = useTranslation();
  const isPrevHidden = landmark === 0;
  const isNextHidden = landmark === hourLandmarks.length - 1;
  const timelineFrom = hourLandmarks[landmark];
  const now = new Date().getTime();
  const isFastBackwardDisplay = now < timelineFrom;
  const isFastForwardDisplay = now > hourLandmarks[landmark + 1];
  const curLandmark = hourLandmarks.findIndex(
    (l, i) =>
      l < now && ((hourLandmarks[i + 1] && hourLandmarks[i + 1] > now) || !hourLandmarks[i + 1]),
  );

  return (
    <StyledChannelNavigator>
      {children}

      <div className={classnames('arrows arrows--left', isPrevHidden && 'hidden')}>
        <div style={{ width: 58 }}>
          <IconButton
            style={{ marginRight: 3 }}
            className={classnames('back-to-live', isFastBackwardDisplay && 'visible')}
            onClick={() => onChange(curLandmark - landmark)}
          >
            <FastBackward color="#fff" width={13} style={{ marginRight: 5 }} />
            {t('live')}
          </IconButton>
        </div>
        <IconButton onClick={onFirstPage}>
          <FirstPageIcon fill="#fff" width={14} height={14} />
        </IconButton>
        <IconButton onClick={() => onChange(-1)}>
          <ArrowBackIcon fill="#fff" width={14} height={14} />
        </IconButton>
      </div>
      <div className="timeSchedule">
        {[0, 1, 2, 3, 4, 5].map(i => {
          if (i === 5) return <div key={i} />;
          return (
            <div key={i} className="timeSchedule-items">
              {format(addMinutes(timelineFrom, i * 30), 'hh:mm aa')}
              {i === 4 ? (
                <div
                  className={classnames(
                    // 'arrows arrows--right',
                    'arrows--btn-right',
                    isNextHidden && 'hidden',
                    isFastForwardDisplay && 'arrows--visible',
                  )}
                >
                  <IconButton style={{ marginRight: 5 }} onClick={() => onChange(1)}>
                    <ArrowForwardIcon fill="#fff" width={14} height={14} />
                  </IconButton>
                  <div style={{ width: 58 }}>
                    <IconButton
                      className={classnames('back-to-live', isFastForwardDisplay && 'visible')}
                      onClick={() => onChange(curLandmark - landmark)}
                    >
                      {t('live')}
                      <FastForward color="#fff" width={13} style={{ marginLeft: 5 }} />
                    </IconButton>
                  </div>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </StyledChannelNavigator>
  );
}

export default RibbonChannelNavigator;
