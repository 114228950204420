import React from 'react';
import styled from 'styled-components';
import ContentAgeRating from './ContentAgeRating';
import BigTitle from './BigTitle';

const TopLeftContainerStyled = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 20px;
`;

function TopLeftContainer(props) {
  const { player, entity } = props;

  return (
    <TopLeftContainerStyled>
      {/* <BigTitle {...props}></BigTitle> */}
      <ContentAgeRating {...props}></ContentAgeRating>
    </TopLeftContainerStyled>
  );
}

export default TopLeftContainer;
