import { StyledLinearIndicator } from './styled';
import React, { useEffect, useState } from 'react';
import { addMinutes, differenceInMinutes } from 'date-fns';
import classnames from 'classnames';
export default function LineIndicator({ timelineFrom, widthSchedule }) {
  const [curPos, setCurPos] = useState(0);
  const timelineFromDate = new Date(timelineFrom);
  const timelineTo = addMinutes(new Date(timelineFrom), 150);
  const handleIndicator = () => {
    const now = new Date();
    if (now < timelineFromDate || now > timelineTo) {
      setCurPos(-1);
    } else {
      const width = differenceInMinutes(now, timelineFromDate) * (widthSchedule / 150);
      const widthPercent = (width * 100) / widthSchedule;

      setCurPos(
        widthPercent,
        // differenceInMinutes(now, timelineFromDate) * (widthSchedule / 150),
      );
    }
  };

  useEffect(() => {
    handleIndicator();
    const interval = setInterval(() => {
      handleIndicator();
    }, (widthSchedule / 150) * 1000);
    return () => clearInterval(interval);
  }, [timelineFrom]);

  return (
    <StyledLinearIndicator className={classnames('indicator--container', curPos < 0 && 'hidden')}>
      <div style={{ left: `${curPos}%` }} className="indicator--container__item" />
    </StyledLinearIndicator>
  );
}
