/* tslint:disable */
/* eslint-disable */
/**
 * Vimai Core Service UI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BillingApi = exports.BillingApiFactory = exports.BillingApiFp = exports.BillingApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * BillingApi - axios parameter creator
 * @export
 */
var BillingApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * User active code
         * @summary User active code
         * @param {UserActiveCodeRequest} data
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        activateSubscriptionByCode: function (data, acceptLanguage, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('activateSubscriptionByCode', 'data', data);
                            localVarPath = "/backend/billing/subscription/code/active";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Cancel subscription
         * @summary Cancel subscription
         * @param {CancelSubscriptionRequest} data
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        cancelUserSubscription: function (data, acceptLanguage, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('cancelUserSubscription', 'data', data);
                            localVarPath = "/backend/billing/subscription/cancel";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Curre
         * @summary curre
         * @param {string} token token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        confirmPaypalTransaction: function (token, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'token' is not null or undefined
                            (0, common_1.assertParamExists)('confirmPaypalTransaction', 'token', token);
                            localVarPath = "/backend/billing/subscription/paypal/complete";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (token !== undefined) {
                                localVarQueryParameter['token'] = token;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createPaypalIpn: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/billing/subscription/paypal/webhook";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List mobile card purchase option
         * @summary List mobile card purchase option
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMobileCardPurchaseOption: function (acceptLanguage, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/billing/subscription/card/package";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Complete subscription with paypal Send paypal transaction to validate with backend If current subscription method is code then user can purchase paypal If current subscription method is paypal then user only purchase if canceled
         * @summary Complete subscription with paypal
         * @param {string} auth Access token
         * @param {string} packageId package id
         * @param {string} successUrl success url callback
         * @param {string} failUrl fail url callback
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPaypalPurchaseOption: function (auth, packageId, successUrl, failUrl, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'auth' is not null or undefined
                            (0, common_1.assertParamExists)('getPaypalPurchaseOption', 'auth', auth);
                            // verify required parameter 'packageId' is not null or undefined
                            (0, common_1.assertParamExists)('getPaypalPurchaseOption', 'packageId', packageId);
                            // verify required parameter 'successUrl' is not null or undefined
                            (0, common_1.assertParamExists)('getPaypalPurchaseOption', 'successUrl', successUrl);
                            // verify required parameter 'failUrl' is not null or undefined
                            (0, common_1.assertParamExists)('getPaypalPurchaseOption', 'failUrl', failUrl);
                            localVarPath = "/backend/billing/subscription/paypal/purchase";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (packageId !== undefined) {
                                localVarQueryParameter['package_id'] = packageId;
                            }
                            if (successUrl !== undefined) {
                                localVarQueryParameter['success_url'] = successUrl;
                            }
                            if (failUrl !== undefined) {
                                localVarQueryParameter['fail_url'] = failUrl;
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            if (auth !== undefined && auth !== null) {
                                localVarHeaderParameter['auth'] = String(auth);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * get paypal package
         * @summary get paypal package
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPaypalPurchasePackage: function (acceptLanguage, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/billing/subscription/paypal/package";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPaypalWebhook: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/billing/subscription/paypal/webhook";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List sms purchase option
         * @summary List sms purchase option
         * @param {PurchaseOptionRequest} data
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSmsPurchaseOption: function (data, acceptLanguage, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('getSmsPurchaseOption', 'data', data);
                            localVarPath = "/backend/billing/subscription/sms/package";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * [BACKEND] Get current user package & info
         * @summary [BACKEND] Get current user package & info
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserSubscriptionInfo: function (acceptLanguage, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/billing/backend_api/subscription/info";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get current user package & info
         * @summary Get current user package & info
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserSubscriptionInfo_1: function (acceptLanguage, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/billing/subscription/info";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get history user
         * @summary Get history user
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {number} [page] page number (default &#x3D; 0)
         * @param {number} [limit] limit number
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserTransactionHistory: function (acceptLanguage, authorization, page, limit, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/billing/subscription/history";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Mobile card callback MO
         * @summary Mobile card callback MO
         * @param {string} moid Required param
         * @param {string} phone Required param
         * @param {string} content Required param
         * @param {string} shortCode Required param
         * @param {string} signature Required param
         * @param {string} prefix Required param
         * @param {string} value Required param
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        processMobileCardPayment: function (moid, phone, content, shortCode, signature, prefix, value, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'moid' is not null or undefined
                            (0, common_1.assertParamExists)('processMobileCardPayment', 'moid', moid);
                            // verify required parameter 'phone' is not null or undefined
                            (0, common_1.assertParamExists)('processMobileCardPayment', 'phone', phone);
                            // verify required parameter 'content' is not null or undefined
                            (0, common_1.assertParamExists)('processMobileCardPayment', 'content', content);
                            // verify required parameter 'shortCode' is not null or undefined
                            (0, common_1.assertParamExists)('processMobileCardPayment', 'shortCode', shortCode);
                            // verify required parameter 'signature' is not null or undefined
                            (0, common_1.assertParamExists)('processMobileCardPayment', 'signature', signature);
                            // verify required parameter 'prefix' is not null or undefined
                            (0, common_1.assertParamExists)('processMobileCardPayment', 'prefix', prefix);
                            // verify required parameter 'value' is not null or undefined
                            (0, common_1.assertParamExists)('processMobileCardPayment', 'value', value);
                            localVarPath = "/backend/billing/subscription/card/active";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (moid !== undefined) {
                                localVarQueryParameter['moid'] = moid;
                            }
                            if (phone !== undefined) {
                                localVarQueryParameter['phone'] = phone;
                            }
                            if (content !== undefined) {
                                localVarQueryParameter['content'] = content;
                            }
                            if (shortCode !== undefined) {
                                localVarQueryParameter['short_code'] = shortCode;
                            }
                            if (signature !== undefined) {
                                localVarQueryParameter['signature'] = signature;
                            }
                            if (prefix !== undefined) {
                                localVarQueryParameter['prefix'] = prefix;
                            }
                            if (value !== undefined) {
                                localVarQueryParameter['value'] = value;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Mobile card callback MO
         * @summary Mobile card callback MO
         * @param {MobileCardCallbackRequest} data
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        processMobileCardPayment_2: function (data, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('processMobileCardPayment_2', 'data', data);
                            localVarPath = "/backend/billing/subscription/card/active";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * [BACKEND-ONLY] Mobile card callback MO
         * @summary [BACKEND-ONLY] Mobile card callback MO
         * @param {string} moid Reqired param
         * @param {string} phone Required param
         * @param {string} content Required param
         * @param {string} shortCode Required param
         * @param {string} signature Required param
         * @param {string} prefix Required param
         * @param {string} value Required param
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        processSmsCardPayment: function (moid, phone, content, shortCode, signature, prefix, value, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'moid' is not null or undefined
                            (0, common_1.assertParamExists)('processSmsCardPayment', 'moid', moid);
                            // verify required parameter 'phone' is not null or undefined
                            (0, common_1.assertParamExists)('processSmsCardPayment', 'phone', phone);
                            // verify required parameter 'content' is not null or undefined
                            (0, common_1.assertParamExists)('processSmsCardPayment', 'content', content);
                            // verify required parameter 'shortCode' is not null or undefined
                            (0, common_1.assertParamExists)('processSmsCardPayment', 'shortCode', shortCode);
                            // verify required parameter 'signature' is not null or undefined
                            (0, common_1.assertParamExists)('processSmsCardPayment', 'signature', signature);
                            // verify required parameter 'prefix' is not null or undefined
                            (0, common_1.assertParamExists)('processSmsCardPayment', 'prefix', prefix);
                            // verify required parameter 'value' is not null or undefined
                            (0, common_1.assertParamExists)('processSmsCardPayment', 'value', value);
                            localVarPath = "/backend/billing/receiveMO";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (moid !== undefined) {
                                localVarQueryParameter['moid'] = moid;
                            }
                            if (phone !== undefined) {
                                localVarQueryParameter['phone'] = phone;
                            }
                            if (content !== undefined) {
                                localVarQueryParameter['content'] = content;
                            }
                            if (shortCode !== undefined) {
                                localVarQueryParameter['short_code'] = shortCode;
                            }
                            if (signature !== undefined) {
                                localVarQueryParameter['signature'] = signature;
                            }
                            if (prefix !== undefined) {
                                localVarQueryParameter['prefix'] = prefix;
                            }
                            if (value !== undefined) {
                                localVarQueryParameter['value'] = value;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * [BACKEND-ONLY] Mobile card callback MO
         * @summary [BACKEND-ONLY] Mobile card callback MO
         * @param {MobileCardCallbackRequest} data
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        processSmsCardPayment_3: function (data, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('processSmsCardPayment_3', 'data', data);
                            localVarPath = "/backend/billing/receiveMO";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.BillingApiAxiosParamCreator = BillingApiAxiosParamCreator;
/**
 * BillingApi - functional programming interface
 * @export
 */
var BillingApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.BillingApiAxiosParamCreator)(configuration);
    return {
        /**
         * User active code
         * @summary User active code
         * @param {UserActiveCodeRequest} data
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        activateSubscriptionByCode: function (data, acceptLanguage, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.activateSubscriptionByCode(data, acceptLanguage, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Cancel subscription
         * @summary Cancel subscription
         * @param {CancelSubscriptionRequest} data
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        cancelUserSubscription: function (data, acceptLanguage, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.cancelUserSubscription(data, acceptLanguage, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Curre
         * @summary curre
         * @param {string} token token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        confirmPaypalTransaction: function (token, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.confirmPaypalTransaction(token, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createPaypalIpn: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createPaypalIpn(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * List mobile card purchase option
         * @summary List mobile card purchase option
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMobileCardPurchaseOption: function (acceptLanguage, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMobileCardPurchaseOption(acceptLanguage, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Complete subscription with paypal Send paypal transaction to validate with backend If current subscription method is code then user can purchase paypal If current subscription method is paypal then user only purchase if canceled
         * @summary Complete subscription with paypal
         * @param {string} auth Access token
         * @param {string} packageId package id
         * @param {string} successUrl success url callback
         * @param {string} failUrl fail url callback
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPaypalPurchaseOption: function (auth, packageId, successUrl, failUrl, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPaypalPurchaseOption(auth, packageId, successUrl, failUrl, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * get paypal package
         * @summary get paypal package
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPaypalPurchasePackage: function (acceptLanguage, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPaypalPurchasePackage(acceptLanguage, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPaypalWebhook: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPaypalWebhook(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * List sms purchase option
         * @summary List sms purchase option
         * @param {PurchaseOptionRequest} data
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSmsPurchaseOption: function (data, acceptLanguage, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSmsPurchaseOption(data, acceptLanguage, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * [BACKEND] Get current user package & info
         * @summary [BACKEND] Get current user package & info
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserSubscriptionInfo: function (acceptLanguage, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUserSubscriptionInfo(acceptLanguage, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get current user package & info
         * @summary Get current user package & info
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserSubscriptionInfo_1: function (acceptLanguage, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUserSubscriptionInfo_1(acceptLanguage, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get history user
         * @summary Get history user
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {number} [page] page number (default &#x3D; 0)
         * @param {number} [limit] limit number
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserTransactionHistory: function (acceptLanguage, authorization, page, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUserTransactionHistory(acceptLanguage, authorization, page, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Mobile card callback MO
         * @summary Mobile card callback MO
         * @param {string} moid Required param
         * @param {string} phone Required param
         * @param {string} content Required param
         * @param {string} shortCode Required param
         * @param {string} signature Required param
         * @param {string} prefix Required param
         * @param {string} value Required param
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        processMobileCardPayment: function (moid, phone, content, shortCode, signature, prefix, value, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.processMobileCardPayment(moid, phone, content, shortCode, signature, prefix, value, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Mobile card callback MO
         * @summary Mobile card callback MO
         * @param {MobileCardCallbackRequest} data
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        processMobileCardPayment_2: function (data, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.processMobileCardPayment_2(data, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * [BACKEND-ONLY] Mobile card callback MO
         * @summary [BACKEND-ONLY] Mobile card callback MO
         * @param {string} moid Reqired param
         * @param {string} phone Required param
         * @param {string} content Required param
         * @param {string} shortCode Required param
         * @param {string} signature Required param
         * @param {string} prefix Required param
         * @param {string} value Required param
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        processSmsCardPayment: function (moid, phone, content, shortCode, signature, prefix, value, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.processSmsCardPayment(moid, phone, content, shortCode, signature, prefix, value, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * [BACKEND-ONLY] Mobile card callback MO
         * @summary [BACKEND-ONLY] Mobile card callback MO
         * @param {MobileCardCallbackRequest} data
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        processSmsCardPayment_3: function (data, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.processSmsCardPayment_3(data, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.BillingApiFp = BillingApiFp;
/**
 * BillingApi - factory interface
 * @export
 */
var BillingApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.BillingApiFp)(configuration);
    return {
        /**
         * User active code
         * @summary User active code
         * @param {UserActiveCodeRequest} data
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        activateSubscriptionByCode: function (data, acceptLanguage, authorization, options) {
            return localVarFp.activateSubscriptionByCode(data, acceptLanguage, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Cancel subscription
         * @summary Cancel subscription
         * @param {CancelSubscriptionRequest} data
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        cancelUserSubscription: function (data, acceptLanguage, authorization, options) {
            return localVarFp.cancelUserSubscription(data, acceptLanguage, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Curre
         * @summary curre
         * @param {string} token token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        confirmPaypalTransaction: function (token, options) {
            return localVarFp.confirmPaypalTransaction(token, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createPaypalIpn: function (options) {
            return localVarFp.createPaypalIpn(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List mobile card purchase option
         * @summary List mobile card purchase option
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMobileCardPurchaseOption: function (acceptLanguage, authorization, options) {
            return localVarFp.getMobileCardPurchaseOption(acceptLanguage, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Complete subscription with paypal Send paypal transaction to validate with backend If current subscription method is code then user can purchase paypal If current subscription method is paypal then user only purchase if canceled
         * @summary Complete subscription with paypal
         * @param {string} auth Access token
         * @param {string} packageId package id
         * @param {string} successUrl success url callback
         * @param {string} failUrl fail url callback
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPaypalPurchaseOption: function (auth, packageId, successUrl, failUrl, acceptLanguage, options) {
            return localVarFp.getPaypalPurchaseOption(auth, packageId, successUrl, failUrl, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * get paypal package
         * @summary get paypal package
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPaypalPurchasePackage: function (acceptLanguage, authorization, options) {
            return localVarFp.getPaypalPurchasePackage(acceptLanguage, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPaypalWebhook: function (options) {
            return localVarFp.getPaypalWebhook(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List sms purchase option
         * @summary List sms purchase option
         * @param {PurchaseOptionRequest} data
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSmsPurchaseOption: function (data, acceptLanguage, authorization, options) {
            return localVarFp.getSmsPurchaseOption(data, acceptLanguage, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * [BACKEND] Get current user package & info
         * @summary [BACKEND] Get current user package & info
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserSubscriptionInfo: function (acceptLanguage, authorization, options) {
            return localVarFp.getUserSubscriptionInfo(acceptLanguage, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get current user package & info
         * @summary Get current user package & info
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserSubscriptionInfo_1: function (acceptLanguage, authorization, options) {
            return localVarFp.getUserSubscriptionInfo_1(acceptLanguage, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get history user
         * @summary Get history user
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {number} [page] page number (default &#x3D; 0)
         * @param {number} [limit] limit number
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserTransactionHistory: function (acceptLanguage, authorization, page, limit, options) {
            return localVarFp.getUserTransactionHistory(acceptLanguage, authorization, page, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Mobile card callback MO
         * @summary Mobile card callback MO
         * @param {string} moid Required param
         * @param {string} phone Required param
         * @param {string} content Required param
         * @param {string} shortCode Required param
         * @param {string} signature Required param
         * @param {string} prefix Required param
         * @param {string} value Required param
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        processMobileCardPayment: function (moid, phone, content, shortCode, signature, prefix, value, options) {
            return localVarFp.processMobileCardPayment(moid, phone, content, shortCode, signature, prefix, value, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Mobile card callback MO
         * @summary Mobile card callback MO
         * @param {MobileCardCallbackRequest} data
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        processMobileCardPayment_2: function (data, options) {
            return localVarFp.processMobileCardPayment_2(data, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * [BACKEND-ONLY] Mobile card callback MO
         * @summary [BACKEND-ONLY] Mobile card callback MO
         * @param {string} moid Reqired param
         * @param {string} phone Required param
         * @param {string} content Required param
         * @param {string} shortCode Required param
         * @param {string} signature Required param
         * @param {string} prefix Required param
         * @param {string} value Required param
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        processSmsCardPayment: function (moid, phone, content, shortCode, signature, prefix, value, options) {
            return localVarFp.processSmsCardPayment(moid, phone, content, shortCode, signature, prefix, value, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * [BACKEND-ONLY] Mobile card callback MO
         * @summary [BACKEND-ONLY] Mobile card callback MO
         * @param {MobileCardCallbackRequest} data
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        processSmsCardPayment_3: function (data, options) {
            return localVarFp.processSmsCardPayment_3(data, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.BillingApiFactory = BillingApiFactory;
/**
 * BillingApi - object-oriented interface
 * @export
 * @class BillingApi
 * @extends {BaseAPI}
 */
var BillingApi = /** @class */ (function (_super) {
    __extends(BillingApi, _super);
    function BillingApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * User active code
     * @summary User active code
     * @param {UserActiveCodeRequest} data
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [authorization] Access token
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    BillingApi.prototype.activateSubscriptionByCode = function (data, acceptLanguage, authorization, options) {
        var _this = this;
        return (0, exports.BillingApiFp)(this.configuration).activateSubscriptionByCode(data, acceptLanguage, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Cancel subscription
     * @summary Cancel subscription
     * @param {CancelSubscriptionRequest} data
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [authorization] Access token
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    BillingApi.prototype.cancelUserSubscription = function (data, acceptLanguage, authorization, options) {
        var _this = this;
        return (0, exports.BillingApiFp)(this.configuration).cancelUserSubscription(data, acceptLanguage, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Curre
     * @summary curre
     * @param {string} token token
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    BillingApi.prototype.confirmPaypalTransaction = function (token, options) {
        var _this = this;
        return (0, exports.BillingApiFp)(this.configuration).confirmPaypalTransaction(token, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    BillingApi.prototype.createPaypalIpn = function (options) {
        var _this = this;
        return (0, exports.BillingApiFp)(this.configuration).createPaypalIpn(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List mobile card purchase option
     * @summary List mobile card purchase option
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [authorization] Access token
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    BillingApi.prototype.getMobileCardPurchaseOption = function (acceptLanguage, authorization, options) {
        var _this = this;
        return (0, exports.BillingApiFp)(this.configuration).getMobileCardPurchaseOption(acceptLanguage, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Complete subscription with paypal Send paypal transaction to validate with backend If current subscription method is code then user can purchase paypal If current subscription method is paypal then user only purchase if canceled
     * @summary Complete subscription with paypal
     * @param {string} auth Access token
     * @param {string} packageId package id
     * @param {string} successUrl success url callback
     * @param {string} failUrl fail url callback
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    BillingApi.prototype.getPaypalPurchaseOption = function (auth, packageId, successUrl, failUrl, acceptLanguage, options) {
        var _this = this;
        return (0, exports.BillingApiFp)(this.configuration).getPaypalPurchaseOption(auth, packageId, successUrl, failUrl, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * get paypal package
     * @summary get paypal package
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [authorization] Access token
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    BillingApi.prototype.getPaypalPurchasePackage = function (acceptLanguage, authorization, options) {
        var _this = this;
        return (0, exports.BillingApiFp)(this.configuration).getPaypalPurchasePackage(acceptLanguage, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    BillingApi.prototype.getPaypalWebhook = function (options) {
        var _this = this;
        return (0, exports.BillingApiFp)(this.configuration).getPaypalWebhook(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List sms purchase option
     * @summary List sms purchase option
     * @param {PurchaseOptionRequest} data
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [authorization] Access token
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    BillingApi.prototype.getSmsPurchaseOption = function (data, acceptLanguage, authorization, options) {
        var _this = this;
        return (0, exports.BillingApiFp)(this.configuration).getSmsPurchaseOption(data, acceptLanguage, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * [BACKEND] Get current user package & info
     * @summary [BACKEND] Get current user package & info
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [authorization] Access token
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    BillingApi.prototype.getUserSubscriptionInfo = function (acceptLanguage, authorization, options) {
        var _this = this;
        return (0, exports.BillingApiFp)(this.configuration).getUserSubscriptionInfo(acceptLanguage, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get current user package & info
     * @summary Get current user package & info
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [authorization] Access token
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    BillingApi.prototype.getUserSubscriptionInfo_1 = function (acceptLanguage, authorization, options) {
        var _this = this;
        return (0, exports.BillingApiFp)(this.configuration).getUserSubscriptionInfo_1(acceptLanguage, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get history user
     * @summary Get history user
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [authorization] Access token
     * @param {number} [page] page number (default &#x3D; 0)
     * @param {number} [limit] limit number
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    BillingApi.prototype.getUserTransactionHistory = function (acceptLanguage, authorization, page, limit, options) {
        var _this = this;
        return (0, exports.BillingApiFp)(this.configuration).getUserTransactionHistory(acceptLanguage, authorization, page, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Mobile card callback MO
     * @summary Mobile card callback MO
     * @param {string} moid Required param
     * @param {string} phone Required param
     * @param {string} content Required param
     * @param {string} shortCode Required param
     * @param {string} signature Required param
     * @param {string} prefix Required param
     * @param {string} value Required param
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    BillingApi.prototype.processMobileCardPayment = function (moid, phone, content, shortCode, signature, prefix, value, options) {
        var _this = this;
        return (0, exports.BillingApiFp)(this.configuration).processMobileCardPayment(moid, phone, content, shortCode, signature, prefix, value, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Mobile card callback MO
     * @summary Mobile card callback MO
     * @param {MobileCardCallbackRequest} data
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    BillingApi.prototype.processMobileCardPayment_2 = function (data, options) {
        var _this = this;
        return (0, exports.BillingApiFp)(this.configuration).processMobileCardPayment_2(data, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * [BACKEND-ONLY] Mobile card callback MO
     * @summary [BACKEND-ONLY] Mobile card callback MO
     * @param {string} moid Reqired param
     * @param {string} phone Required param
     * @param {string} content Required param
     * @param {string} shortCode Required param
     * @param {string} signature Required param
     * @param {string} prefix Required param
     * @param {string} value Required param
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    BillingApi.prototype.processSmsCardPayment = function (moid, phone, content, shortCode, signature, prefix, value, options) {
        var _this = this;
        return (0, exports.BillingApiFp)(this.configuration).processSmsCardPayment(moid, phone, content, shortCode, signature, prefix, value, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * [BACKEND-ONLY] Mobile card callback MO
     * @summary [BACKEND-ONLY] Mobile card callback MO
     * @param {MobileCardCallbackRequest} data
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    BillingApi.prototype.processSmsCardPayment_3 = function (data, options) {
        var _this = this;
        return (0, exports.BillingApiFp)(this.configuration).processSmsCardPayment_3(data, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return BillingApi;
}(base_1.BaseAPI));
exports.BillingApi = BillingApi;
