import jquery from 'jquery';
import { localStorage as ls } from 'js-storage';
import VTTConverter from 'srt-webvtt';
import { getConfigByKey } from '~features/tenantConfig/services';
import { getDefaultLocale } from '~i18n';

const removeTextTrack = (component, entityDetail, currentTracks = null) => {
  const tracks = currentTracks || component.videoPlayer.textTracks();
  for (let i = 0; i < tracks.length; i++) {
    const track = tracks[i];
    component.videoPlayer.removeRemoteTextTrack(track);
  }
};

const checkLanguage = (t, key) => {
  let translate = '';
  switch (key) {
    case 'es':
      translate = t('locale.es');
      break;
    case 'en':
      translate = t('locale.en');
      break;
    case 'ko':
      translate = t('locale.ko');
      break;
    default:
      translate = t('locale.vi');
  }
  return translate;
};

const initTextTrack = (component, entityDetail, t) => {
  const items = [];
  if (!component.videoPlayer) {
    return;
  }
  const $player = jquery(component.videoPlayer.player_.el_);
  removeTextTrack(component, entityDetail);
  if (entityDetail && entityDetail.subtitles && entityDetail.subtitles.length > 0) {
    const { subtitles } = entityDetail;
    $player.find('button.vjs-subs-caps-button').removeClass('vjs-not-subtitle');
    entityDetail.subtitles.map((item, i) => {
      if (getConfigByKey('ui_theme') === 'projectw' && item.language_code === 'vi') {
        return null;
      }
      component.subtitleRender = true;
      // component.setState({
      //   subtitleSelected: item.language === 0 ? false : item.id,
      // })
      const init = 0;
      const convert = init => {
        if (init < 3) {
          fetch(item.subtitle_url)
            .then(res => res.blob())
            .then(blob => {
              const file = new File([blob], item.name, {
                lastModified: 1534584790000,
              });
              const vttConverter = new VTTConverter(file);
              vttConverter
                .getURL()
                .then(url => {
                  const lsSubtitle = ls.get('subtitle');

                  const subtitle = {
                    subtitle_color: item.subtitle_color || '',
                    border_text_color: item.border_text_color || '',
                    src: url,
                    srclang: item.standard_language_code || item.language_code,
                    // label: checkLanguage(t, item.language_code),
                    label: item.standard_language_name || checkLanguage(t, item.language_code),
                    kind: 'subtitles',
                    id: item.id,
                    // mode:
                    //   item.language_code === (lsSubtitle || getDefaultLocale())
                    //     ? 'showing'
                    //     : 'hidden',
                    // default: item.language_code === getDefaultLocale(),
                  };
                  let tracks = component.videoPlayer.remoteTextTracks();

                  removeTextTrack(
                    component,
                    null,
                    tracks.tracks_.filter(track => track.id === subtitle.id),
                  );

                  const trackEl = component.videoPlayer.addRemoteTextTrack(subtitle, true);

                  tracks = component.videoPlayer.remoteTextTracks();

                  for (let i = 0; i < tracks.length; i++) {
                    const track = tracks[i];

                    // find the metadata track that's labeled ads
                    if (
                      track.kind === 'subtitles' &&
                      track.language === (lsSubtitle || getDefaultLocale())
                    ) {
                      track.mode = 'showing';
                    } else {
                      track.mode = 'hidden';
                    }
                  }
                  if (subtitle.mode === 'showing') {
                    component.videoPlayer.trigger('subtitleChanged', subtitle);
                  }

                  trackEl.addEventListener('load', event => {});
                })
                .catch(() => {
                  init += 1;
                  convert(init);
                });
            });
        }
      };
      convert(init);
    });
    setTimeout(() => {
      component.subtitleRender = false;
    }, 1000);
  } else {
    $player.find('button.vjs-subs-caps-button').addClass('vjs-not-subtitle');
  }
  if (entityDetail && entityDetail.subtitles && entityDetail.subtitles.length > 0) {
    entityDetail.subtitles.map((item, i) =>
      items.push({
        id: item.id,
        name: item.name,
      }),
    );
  }
  component.setState({
    subtitles: items,
  });
  component.videoPlayer.on('loaddatameta', () => {
    removeTextTrack(component, entityDetail);
    const tracks = component.videoPlayer.textTracks();
    component._fitterSubtitle(tracks);
  });
};

export default initTextTrack;
