import React from 'react';
import { TYPE_RECENT_MOVIE } from '../constants';
import { getConfigByKey } from '../../../features/tenantConfig/services';
import styled from 'styled-components';

const responsive = data => {
  const { props } = data;
  let responsiveData = [
    { breakpoint: 480, settings: { slidesToShow: 2, slidesToScroll: 2 } },
    { breakpoint: 687, settings: { slidesToShow: 2, slidesToScroll: 2 } },
    { breakpoint: 768, settings: { slidesToShow: 3, slidesToScroll: 3 } },
    { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3 } },
  ];
  if (
    (props && props.match.params && !props.match.params.slug) ||
    !getConfigByKey('features.vertical_ribbon', true)
  ) {
    responsiveData = [
      ...responsiveData,
      { breakpoint: 1350, settings: { slidesToShow: 4, slidesToScroll: 4 } },
      { breakpoint: 1660, settings: { slidesToShow: 5, slidesToScroll: 5 } },
      { breakpoint: 1980, settings: { slidesToShow: 6, slidesToScroll: 6 } },
    ];
  } else {
    responsiveData = [
      ...responsiveData,
      { breakpoint: 1350, settings: { slidesToShow: 3, slidesToScroll: 3 } },
      { breakpoint: 1660, settings: { slidesToShow: 4, slidesToScroll: 4 } },
      { breakpoint: 1980, settings: { slidesToShow: 5, slidesToScroll: 5 } },
    ];
  }
  if (
    props.ribbon &&
    props.ribbon.display_type === 2 &&
    getConfigByKey('tenant_slug', false) === 'sctv'
  ) {
    responsiveData = [
      { breakpoint: 480, settings: { slidesToShow: 2.5, slidesToScroll: 3 } },
      { breakpoint: 687, settings: { slidesToShow: 3.5, slidesToScroll: 4 } },
      { breakpoint: 768, settings: { slidesToShow: 4, slidesToScroll: 4 } },
      { breakpoint: 1024, settings: { slidesToShow: 5, slidesToScroll: 5 } },
      { breakpoint: 1350, settings: { slidesToShow: 6, slidesToScroll: 6 } },
      { breakpoint: 1660, settings: { slidesToShow: 7, slidesToScroll: 7 } },
      { breakpoint: 1980, settings: { slidesToShow: 8, slidesToScroll: 8 } },
    ];
  }
  return responsiveData;
};
function PrevButton({ onClick, props }) {
  if (onClick) {
    if (props && props.related === 'moreLikeThis') {
      return (
        <ButtonArrow className="btnArrowPre">
          <button onClick={onClick}>
            <i className="fa fa-chevron-left prevButtonIcon" />
          </button>
        </ButtonArrow>
      );
    }
    return (
      <a
        onClick={onClick}
        className={`prevButton ${
          props &&
          props.props &&
          props.props.ribbon &&
          props.props.ribbon.type === TYPE_RECENT_MOVIE &&
          'customiseSlickBtn'
        }`}
      >
        <i className="fa fa-chevron-left prevButtonIcon" />
      </a>
    );
  }
  return null;
}

function NextButton({ onClick, props }) {
  if (onClick) {
    if (props && props.related === 'moreLikeThis') {
      return (
        <ButtonArrow className="btnArrowNext">
          <button onClick={onClick}>
            <i className="fa fa-chevron-right nextButtonIcon" />
          </button>
        </ButtonArrow>
      );
    }
    return (
      <a
        onClick={onClick}
        className={`nextButton ${
          props &&
          props.props &&
          props.props.ribbon &&
          props.props.ribbon.type === TYPE_RECENT_MOVIE &&
          'customiseSlickBtn'
        }`}
      >
        <i className="fa fa-chevron-right nextButtonIcon" />
      </a>
    );
  }
  return null;
}

const settingSlide = (props, related = null) => {
  let respon = responsive(props);
  if (related) {
    respon = [
      {
        breakpoint: 480,
        settings: { slidesToShow: 2, slidesToScroll: 2 },
      },
      { breakpoint: 687, settings: { slidesToShow: 2, slidesToScroll: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 2 } },
      { breakpoint: 1024, settings: { slidesToShow: 2.5, slidesToScroll: 2 } },
      { breakpoint: 1350, settings: { slidesToShow: 3, slidesToScroll: 3 } },
      { breakpoint: 1660, settings: { slidesToShow: 3.75, slidesToScroll: 4 } },
      { breakpoint: 1980, settings: { slidesToShow: 4, slidesToScroll: 4 } },
    ];
    if (getConfigByKey('tenant_slug') === 'sctv') {
      respon[4] = {
        breakpoint: 1350,
        settings: { slidesToShow: 3.5, slidesToScroll: 3 },
      };
      respon[5] = {
        breakpoint: 1660,
        settings: { slidesToShow: 4.5, slidesToScroll: 4 },
      };
      respon[6] = {
        breakpoint: 1980,
        settings: { slidesToShow: 5.5, slidesToScroll: 5 },
      };
    }
    if (related === 'moreLikeThis') {
      respon[3] = {
        breakpoint: 1024,
        settings: { slidesToShow: 4, slidesToScroll: 4 },
      };
      respon[4] = {
        breakpoint: 1350,
        settings: { slidesToShow: 4, slidesToScroll: 4 },
      };
      respon[5] = {
        breakpoint: 1660,
        settings: { slidesToShow: 5, slidesToScroll: 5 },
      };
      respon[6] = {
        breakpoint: 1980,
        settings: { slidesToShow: 5.5, slidesToScroll: 5 },
      };
    }
  }
  const sliderSettings = {
    dots: false,
    infinite: false,
    lazyLoad: 'ondemand',
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    // mobileFirst: false,
    prevArrow: <PrevButton props={{ ...props, related }} />,
    nextArrow: <NextButton props={{ ...props, related }} />,
    beforeChange: (current, next) => {
      return props.handleLazyLoad && props.handleLazyLoad(next);
    },
    responsive: respon,
  };
  return sliderSettings;
};

const ButtonArrow = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  font-size: 10px;
  &.btnArrowPre {
    left: 4.4em;
    right: unset;
  }
  &.btnArrowNext {
    right: 4.4em;
    left: unset;
  }
  button {
    border-radius: 50%;
    width: 5.4em;
    height: 5.4em;
    background: rgb(218, 218, 218, 0.5);
    transition: 0.3s ease;
    border: none;
    i {
      color: #121212;
      font-size: 2.2em;
    }
    &:hover {
      background: rgb(218, 218, 218);
    }
  }
`;
export { settingSlide };
