import GroupButton from '../liveGroupButton';
import ReactDOM from 'react-dom';
import videojs from 'video.js';
import React from 'react';
import jquery from 'jquery';

const vjsComponent = videojs.getComponent('Component');

class listButtonLive extends vjsComponent {
  constructor(player, options) {
    super(player, options);

    /* Bind the current class context to the mount method */
    this.mount = this.mount.bind(this);

    /* When player is ready, call method to mount React component */
    player.ready(() => {
      this.mount();
    });

    /* Remove React root when component is destroyed */
    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }

  createEl() {
    const div = videojs.dom.createEl('div', {
      className: 'vjs--escondido-wrapButtonLive',
      id: 'vjs-epg-display',
    });
    return div;
  }
  fullScreen = () => {
    if (this.player_.isFullscreen_) return this.player_.exitFullscreen();
    this.player_.requestFullscreen();
  };

  handleClickEps = id => {
    if (!id) return;
    const $player = jquery(this.player_.el_);
    const $el = $player.find('.vjs-list-eps .dropdown-menu .linkEps');
    const $eps = $el.find(`#${id}`);
    return $eps.trigger('click');
  };
  mount() {
    const { component, status, type, episodes } = this.options_;

    ReactDOM.render(
      <GroupButton
        component={component}
        status={status}
        type={type}
        player={this.player_}
        fullScreen={this.fullScreen}
        handleClickEps={id => this.handleClickEps(id)}
        episodes={episodes}
        body="live-ui"
      />,
      this.el(),
    );
  }
}

/**
 * Make sure to register the vjsComponent so Video JS knows it exists
 */
vjsComponent.registerComponent('listButtonLive', listButtonLive);

export default listButtonLive;
