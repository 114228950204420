import { IAxiosResponseData } from '~core/models';
import { store } from '~store';
import moment from 'moment';
import { apiAdapter, config, axiosTakeDataFromResponse } from '~share';
import {
  ConfirmSubscriptionPerContentRequest,
  PaySubscriptionPerContentRequest,
  ApplyBigNCouponForAccountRequest,
} from '../../vimai-ts-api-adapter/api-adapters/api-adapter-vimai/models';
import { UserApiInterface } from '~vimai-api-adapter';

const { paymentService, userService } = apiAdapter;

const postSubscriptionsPayperview = (
  params: PaySubscriptionPerContentRequest,
): Promise<IAxiosResponseData<typeof paymentService.createSingleContentSubscription>> | void => {
  const { account } = (store.getState() as any).auth;
  if (!account) {
    return;
  }

  const { content_id } = params;

  const data = params;
  const { accountId = '', tenantSlug = '', authorization, acceptLanguage, timezone } = config;
  const keyByPeriodTime = Math.floor(moment().add(5, 'seconds').unix() / 10);
  const idempotencyKey = accountId + content_id + keyByPeriodTime;

  return paymentService
    .createSingleContentSubscription(
      accountId,
      tenantSlug,
      data,
      authorization,
      acceptLanguage,
      idempotencyKey,
      timezone,
    )
    .then(axiosTakeDataFromResponse);
};

const postConfirmPaypalPayperview = (
  params: ConfirmSubscriptionPerContentRequest,
): Promise<IAxiosResponseData<typeof paymentService.confirmSingleContentSubscription>> | void => {
  const { content_id } = params;

  const confirmData = params;

  const keyByPeriodTime = Math.floor(moment().add(5, 'seconds').unix() / 10);

  const { account } = (store.getState() as any).auth;
  if (!account) {
    return;
  }
  const { accountId = '', tenantSlug = '', authorization, acceptLanguage, timezone } = config;

  const idempotencyKey = accountId + content_id + keyByPeriodTime;

  return paymentService
    .confirmSingleContentSubscription(
      accountId,
      tenantSlug,
      confirmData,
      authorization,
      acceptLanguage,
      idempotencyKey,
      timezone,
    )
    .then(axiosTakeDataFromResponse);
};

const getPaymentMethod = (): Promise<
  IAxiosResponseData<typeof paymentService.getTenantPaymentMethods>
> => {
  const { tenantSlug = '' } = config;
  return paymentService.getTenantPaymentMethods(tenantSlug).then(axiosTakeDataFromResponse);
};

export const getTimesOfPackage = (): Promise<
  IAxiosResponseData<typeof paymentService.remainingPurchasesAccount>
> | void => {
  const { account } = (store.getState() as any).auth;
  if (!account) {
    return;
  }
  const { accountId = '', tenantSlug = '', authorization, acceptLanguage } = config;
  return paymentService
    .remainingPurchasesAccount(accountId, tenantSlug, authorization, acceptLanguage)
    .then(axiosTakeDataFromResponse);
};

export const applyCoupon = (
  data: ApplyBigNCouponForAccountRequest,
): Promise<IAxiosResponseData<UserApiInterface['applyBigNCouponCodeApi']> | void> => {
  const { account } = (store.getState() as any).auth;
  if (!account) {
    return Promise.resolve();
  }
  const { accountId = '', tenantSlug = '', acceptLanguage, authorization } = config;
  return userService
    .applyBigNCouponCodeApi(accountId, tenantSlug, data, authorization)
    .then(axiosTakeDataFromResponse);
};

export { postSubscriptionsPayperview, postConfirmPaypalPayperview, getPaymentMethod };
