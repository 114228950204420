import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import { contentTypes } from '../../../../utils/utils';
import * as types from '../../constants';

const VipType = props => {
  const { entityDetail, payperview } = props;
  const { payment_type, has_free_content } = entityDetail;
  if ((payment_type === types.TVOD || payment_type === 2) && !payperview) {
    return (
      <div className={classnames('ribbonTicketTvod')}>
        <img width="20" height="20" src={require('~img/icons/odv/viplvl2.svg').default} />
      </div>
    );
  }
  if (!payperview && !has_free_content)
    return (
      <div className="v-slide__vip">
        <i className="fa fa-star v-slide__vip--fa" aria-hidden="true" />
        <span className="v-slide__vip-txt"> VIP</span>
      </div>
    );
  return null;
};
export default class Type extends React.Component {
  render() {
    const { entityDetail, payperview, payment_type } = this.props;
    const isLive = entityDetail.type === 5 || entityDetail.type === 7;
    // eslint-disable-next-line camelcase
    const { content_categories } = entityDetail;
    let statusText = contentTypes(entityDetail);
    if (Array.isArray(content_categories) && content_categories.length > 0) {
      statusText = content_categories[0].name;
    }

    return (
      <StyledComp className="v-slide--type" data-test={statusText}>
        {isLive && (
          <div className="v-slide--live">
            <i className="fa fa-circle" aria-hidden="true" style={{ paddingRight: '0.5rem' }} />
            Live
          </div>
        )}
        <VipType {...this.props} />
        <img
          loading="lazy"
          className={`v-slide--icon-ytb ${!entityDetail.video_source && 'hide'}`}
          src={require('~img/img/youtube.png').default}
          width="80"
          height="25"
        />
      </StyledComp>
    );
  }
}

const StyledComp = styled.div`
  &.v-slide {
    &--type {
      color: #e8b600;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
    }
  }
  & .v-slide {
    &--live {
      width: 4.25rem;
      font-weight: 700;
      font-size: 0.875rem;
      padding: 0.025rem 0 0.125rem 0.65rem;
      text-transform: uppercase;
      color: #ff0000;
      background: #fff;
      border-radius: 20px;
      margin-right: 1rem;
    }
    &__vip {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #e8b600;
      font-size: 1rem;
      margin: 0;
      &--fa {
        padding: 2px;
        font-size: 0.5rem;
        color: #fff;
        background: #e8b600;
        border-radius: 50%;
        margin-right: 0.35rem;
        width: 0.75rem;
        height: 0.75rem;
        display: flex;
        justify-content: center;
      }
      &-txt {
        font-weight: 600;
      }
    }
    &--title {
      color: #7d7d7d;
      text-transform: capitalize;
      margin: 0.5em 1rem 0.5rem 0;
    }
    &--icon {
      &-ytb {
        width: 4.5rem;
        margin-left: 0;
        height: 100%;
      }
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .v-slide {
      &--title {
        color: white;
        margin-right: 1.125em;
      }
    }
  }
  @media (min-width: 1280px) {
  }
  @media (min-width: 1440px) {
  }
  @media (min-width: 1680px) {
  }
`;

Type.propTypes = {
  entityDetail: PropTypes.any,
};
