import { firebase } from '../firebase/firebase';
import TagManager from 'react-gtm-module';
import { objectToSlug, getTenantConfig } from '~utils/utils';
import { getConfigByKey } from '~features/tenantConfig/services';

export const pushAnalytics = (
  event: any,
  params: { [key: string]: any },
  { toSlug = true, itemName = false, platform = true }: any = {
    toSlug: true,
    itemName: false,
  },
) => {
  if (!(window as any).fbq) {
    return;
  }
  let firebaseConfig = getConfigByKey('integration_config.firebase');
  if (firebaseConfig) firebaseConfig = JSON.parse(firebaseConfig.replace(/'/g, '"'));
  if (!firebaseConfig || (firebaseConfig && firebaseConfig.project_info)) {
    return;
  }
  if (firebase.apps.length === 0 || !getConfigByKey('feature_advanced_web_multitenancy_enabled')) {
    return;
  }
  let dataLayer: { event: any; item_name?: string } = {
    event: event,
  };
  if (toSlug) {
    params = objectToSlug(params);
  }
  if (platform) {
    params = {
      ...params,
      app_platform: 'Web',
    };
  }

  if (itemName) {
    dataLayer = { ...dataLayer, item_name: params.item_name || '' };
  }
  Object.assign(dataLayer, params);
  firebase.analytics().logEvent(event, params);
  const tenantConfig = getTenantConfig(getConfigByKey('tenant_slug'));
  if (tenantConfig && !tenantConfig.GTM) {
    return;
  }
  TagManager.dataLayer({
    dataLayer,
  });
};

export const pushAnalyticsNotSlug = (event: any, params: any) => {
  pushAnalytics(event, params, { toSlug: false, itemName: true });
};

export default pushAnalytics;
