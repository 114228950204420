/* tslint:disable */
/* eslint-disable */
/**
 * Vimai Core Service UI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageDetailDisplayStyleEnum = exports.PageDetailBannerStyleEnum = void 0;
exports.PageDetailBannerStyleEnum = {
    HomePageStyle: 'HOME_PAGE_STYLE',
    TvshowPageStyle: 'TVSHOW_PAGE_STYLE',
    MoviePageStyle: 'MOVIE_PAGE_STYLE'
};
exports.PageDetailDisplayStyleEnum = {
    Normal: 'NORMAL',
    TabbedBase: 'TABBED_BASE',
    Youtube: 'YOUTUBE',
    Live: 'LIVE',
    Search: 'SEARCH',
    MyList: 'MY_LIST',
    Country: 'COUNTRY',
    VodList: 'VOD_LIST',
    TvGuide: 'TV_GUIDE',
    TvShows: 'TV_SHOWS',
    Movies: 'MOVIES',
    Channels: 'CHANNELS',
    Favorites: 'FAVORITES',
    Lsb: 'LSB'
};
