import React, { useState, useRef, useEffect } from 'react';
import { RealTimeAPI } from 'rocket.chat.realtime.api.rxjs';
import { v4 as uuid } from 'uuid';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { DEBUG, REACT_APP_ROCKETCHAT_WEBSOCKET } from '~constants/envConfig';
import _ from 'lodash';
import { debug } from '~utils/utils';
import { ANONYMOUS_CHAT_USER } from '~core/constants';
import {
  MESSAGE_SENDING_STATE,
  MESSAGE_TYPING_STATE,
  MESSAGE_SUCCESS_STATE,
  MESSAGE_ERROR_STATE,
} from '~core/constants';
import { sendMessageApi, getMeApi } from '~api/chatApi';
import { useSelector } from 'react-redux';
import { isLogged } from '~core/method/authMethod';
import { isEditedMessage, isRemovedMessage } from '~core/method/message';

const start = async ({ token, rid }) => {
  if (!REACT_APP_ROCKETCHAT_WEBSOCKET) {
    return;
  }
  const realTimeAPI = new RealTimeAPI(REACT_APP_ROCKETCHAT_WEBSOCKET);
  debug(() => {
    realTimeAPI.onMessage(message => {
      console.log('message rocketchat', message);
    });
    realTimeAPI.onError(error => console.error(error, 'error rocketchat'));
  });

  return realTimeAPI;
};

const getMessageNotUserJoined = messages => {
  return messages.filter(message => !message.t || message.t !== 'uj');
};

const getNormalMessages = messages => {
  return messages.filter(message => !message.t);
};

const getRemovedMessages = messages => {
  return messages.filter(message => message.t && message.t === 'rm');
};

const getEditedMessages = messages => {
  return messages.filter(message => {
    return !_.isEmpty(message.editedBy);
  });
};

export const useChatLiveWebSocket = ({ roomId = '', token = '' } = {}) => {
  const location = useLocation();
  const auth = useSelector(state => state.auth);

  const [messageHistory, setMessageHistory] = useState([]);
  const [newMessages, setNewMessages] = useState([]);
  const [messagesState, setMessagesState] = useState({});
  const [removedMessages, setRemovedMessages] = useState({});
  const [editedMessages, setEditedMessages] = useState({});
  const [error, setError] = useState(null);

  const { tokenChatLive, roomId: rid } = queryString.parse(location.search);
  const paramsRef = useRef({});
  const reconnectTimeOutRef = useRef();
  const authRef = useRef(null);
  const tokenRef = useRef(null);
  const userInfo = useRef(null);
  const [authRocketChat, setAuthRocketChat] = useState(null);
  const [params, setParams] = useState({
    roomId: DEBUG ? rid || roomId : roomId,
    token: DEBUG ? tokenChatLive || token : token,
  });

  const addMessageState = message => {
    setMessagesState(old => ({
      ...old,
      [message._id]: {
        ...message,
        state: MESSAGE_SENDING_STATE,
      },
    }));
  };

  const updateMessagesState = newState => {
    setMessagesState(old => ({
      ...old,
      ...newState,
    }));
  };

  const realTimeAPI = useRef(null);
  // Creating a ref for storing websocket object

  const resendMessage = messageId => {
    if (!isLogged.call(auth) || !realTimeAPI.current || authRef.current === ANONYMOUS_CHAT_USER) {
      return;
    }
    let message = null;
    setMessagesState(old => {
      const messageById = old[messageId] || null;
      messageById && (message = messageById);
      return old;
    });
    if (!message) {
      return;
    }
    addMessageState(message);
    handleSendMessage(message);
  };

  const sendMessage = message => {
    if (!isLogged.call(auth) || !realTimeAPI.current || authRef.current === ANONYMOUS_CHAT_USER) {
      return;
    }

    // realTimeAPI.current.sendMessage({
    //   msg: 'method',
    //   method: 'sendMessage',
    //   id: uuid(),
    //   params: [
    //     {
    //       _id: uuid(),
    //       rid: params.roomId,
    //       msg: message,
    //     },
    //   ],
    // })
    const messageId = uuid();
    const newMessage = {
      _id: messageId,
      msg: message,
      // ts: moment().valueOf(),
      u: {
        _id: tokenRef.current.id,
        name: (userInfo.current || {}).name || '',
        username: authRef.current,
      },
      rid: params.roomId,
    };

    addMessageState(newMessage);
    setNewMessages([newMessage]);
    handleSendMessage(newMessage);

    return;
  };

  const handleSendMessage = message => {
    const newMessage = message;
    sendMessageApi(
      { message: newMessage },
      {
        'X-Auth-Token': tokenRef.current.token,
        'X-User-Id': tokenRef.current.id,
      },
    )
      .then(res => {
        setMessagesState(old => {
          const messageSuccess = {
            ...(old[newMessage._id] || {}),
            state: MESSAGE_SUCCESS_STATE,
          };

          updateMessagesState({ [messageSuccess._id]: messageSuccess });
          return old;
        });
      })
      .catch(error => {
        setMessagesState(old => {
          const messageError = {
            ...(old[newMessage._id] || {}),
            state: MESSAGE_ERROR_STATE,
          };
          updateMessagesState({ [messageError._id]: messageError });
          return old;
        });
      });
  };

  const disconnectChat = () => {
    try {
      realTimeAPI.current.disconnect();
    } catch (error) {}
  };

  const initChat = async (websocket, params) => {
    if (!websocket) {
      return;
    }

    websocket.connectToServer();
    websocket.keepAlive().subscribe();

    if (!params.token) {
      return;
    }

    const auth = websocket.loginWithAuthToken(params.token);

    auth.subscribe(data => {
      const { error } = data;
      if (!_.isEmpty(error)) {
        setError(error);
        return;
      }

      websocket.callMethod('joinRoom', params.roomId);
      if (data.msg === 'result') {
        tokenRef.current = data.result;
        setAuthRocketChat(tokenRef.current);
        getMeApi({
          'X-Auth-Token': tokenRef.current.token,
          'X-User-Id': tokenRef.current.id,
        }).then(res => {
          userInfo.current = res;
        });
      }
      initEvents();
    });
    websocket.getObservableFilteredByMessageType('added').subscribe(data => {
      const { fields } = data;

      authRef.current = fields.username || '';
    });

    const initEvents = () => {
      websocket.getObservableFilteredByMessageType('ping').subscribe(data => {
        autoReconnectChat();
      });
      websocket.callMethod('loadHistory', params.roomId, null, 500, null).subscribe(res => {
        const { result = {} } = res;
        setMessageHistory(getNormalMessages(_.reverse(result.messages || [])));
      });
      websocket.getSubscription('stream-room-messages', params.roomId, false).subscribe(
        res => {
          let newMessages = [];
          let currentMessageState = {};
          let newRemovedMessages = [];
          let newEditedMessages = [];
          const messagesBeenSend = [];

          setMessagesState(old => {
            currentMessageState = old;
            return old;
          });

          try {
            const messagesList = res.fields.args;

            messagesList.map(message => {
              if (isRemovedMessage.call(message)) {
                newRemovedMessages.push(message);
                return null;
              }

              if (isEditedMessage.call(message)) {
                newEditedMessages.push(message);
                return null;
              }
              newMessages.push(message);
              return null;
            });

            newMessages = getNormalMessages(newMessages).filter(ms => {
              const messageBeenSend = {
                ...(currentMessageState[ms._id] || {}),
              };

              if (!_.isEmpty(messageBeenSend)) {
                messagesBeenSend.push({ ...messageBeenSend, ...ms });
                return false;
              }

              return true;
            });

            !_.isEmpty(messagesBeenSend) &&
              setMessagesState(old => {
                return {
                  ...old,
                  ..._.keyBy(messagesBeenSend, '_id'),
                };
              });

            setRemovedMessages(old => {
              return {
                ...old,
                ..._.keyBy(newRemovedMessages, '_id'),
              };
            });
            setEditedMessages(old => {
              return {
                ...old,
                ..._.keyBy(newEditedMessages, '_id'),
              };
            });
          } catch (error) {}

          !_.isEmpty(newMessages) && setNewMessages(newMessages);
        },
        error => {},
      );
    };
  };

  const openChatConnect = (params, renew = true) => {
    start(params).then(websocket => {
      disconnectChat();
      realTimeAPI.current = websocket;
      window.realTimeAPI = websocket;
      initChat(websocket, params);
    });
  };

  const autoReconnectChat = () => {
    clearTimeout(reconnectTimeOutRef.current);
    reconnectTimeOutRef.current = setTimeout(() => {
      const webSocket = realTimeAPI.current;

      if (webSocket && webSocket.webSocket.closed) {
        setParams(old => {
          openChatConnect(old);
          return old;
        });
      }

      autoReconnectChat();
    }, 60 * 1000);
  };

  useEffect(() => {
    setParams({
      roomId,
      token,
    });
  }, [roomId, token]);

  useEffect(() => {
    const preParams = { ...(paramsRef.current || {}) };
    paramsRef.current = {
      ...params,
    };
    if (!params.roomId || !params.token) {
      disconnectChat();
      return;
    }
    if (preParams.roomId === params.roomId && preParams.token === params.token) {
      // initChat(realTimeAPI.current, params)
      // autoReconnectChat()
      return;
    }

    try {
      // if (realTimeAPI.current) {
      //   // realTimeAPI.current.connectToServer()
      //   initChat(realTimeAPI.current, params)
      //   return
      // }
      openChatConnect(params);
    } catch (error) {}
  }, [params]);

  useEffect(() => {
    autoReconnectChat();
    return () => {
      disconnectChat();
    };
  }, []);

  return {
    connected: realTimeAPI.current,
    sendMessage,
    resendMessage,
    messageHistory,
    newMessages,
    messagesState,
    removedMessages,
    editedMessages,
    authRocketChat,
    error,
  };
};
