import React from 'react';
import { ModalStyled } from './login/styled';
import { useTranslation } from 'react-i18next';
import { Modal, Col, Row, Button } from 'react-bootstrap';
import loginFormId from '~constants/ids/loginFormId';
import { getConfigByKey } from '~features/tenantConfig/services';
import LoginGoogle from './login/loginGoogle';
import LoginFaceBook from './login/loginFacebook';
import LoginApple from './login/loginKaKaoTalk';
import LoginTwitter from './login/loginNaver';
import LoginEmail from './login/loginEmail';
import PrivacyPolicy from '../../box/components/privacyPolicyPopup';
import { connect } from 'react-redux';
import { verifyEmailFormat } from '../../../utils/utils';
import { registerEmail, loginEmail } from '../services';
import registerFormId from '../../../constants/ids/registerFormId';
import TermOfUse from '../../box/components/termOfUsePopup';
import pushAnalytics from '../../../../src/services/analytics/Analytics';
import classnames from 'classnames';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import LoginProjectW from './login/projectw';
import { useLocation, useRouteMatch } from 'react-router';
import { ReactComponent as IconsPassword } from '../../../assets/icons/eyeW.svg';
import { ReactComponent as IconsHidePassword } from '../../../assets/icons/eyeWFlash.svg';
import WatchMoreModal from './watchMoreModal';
import { setWatchNowClick, setLoginModalShow } from '~components/global/globalSlice';
import SignUpForm from './projectw/SignUpForm';
import VerifyEmailModal from './login/VerifyEmailModal';

const SignUpProjectW = React.memo(props => {
  const component = props.props;
  const {
    checkYourPaidAccount,
    hiddenModalLogin,
    registerModal,
    isShowLoginModal,
    isShowVerifyEmailModal,
  } = props;

  const [watchMoreModal, setWatchMoreModal] = React.useState(false);

  const { param, modalShow, hideModal } = component;
  const { isLoading } = props.state;
  const location = useLocation();

  const hiddenModalSignUp = () => {
    props.setLoginShowModal(true);
    hiddenModalLogin();
    setWatchMoreModal(false);
  };

  if (isShowVerifyEmailModal) return <VerifyEmailModal {...props} />;
  return (
    <ModalStyled
      show={modalShow}
      onHide={hiddenModalSignUp}
      aria-labelledby="contained-modal-title-vcenter"
      className="v-loginForm project-w"
    >
      <i
        style={{ display: 'none' }}
        onClick={() => hiddenModalSignUp()}
        className="fa fa-times"
        id="close"
        aria-hidden="true"
      ></i>
      <Modal.Body>
        {/* {isLoading && <OverlayLoading />} */}
        <SignUpForm {...props} />
      </Modal.Body>
    </ModalStyled>
  );
});

const GroupAction = styled.div`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 133.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: #8e8e93;
    margin-top: 100px;
    letter-spacing: 2px;
    margin-left: 3px;
    &:nth-child(1),
    &:nth-child(3) {
      border-bottom: 1px solid #000;
    }
  }
`;

const ButtonSignUp = styled.p`
  font-weight: 600;
  padding-left: 10px;
  &:hover {
    cursor: pointer;
  }
`;

const FormControlW = styled.input`
  font-size: 17px;
  width: 100%;
  padding: 10px 8px;
  font-weight: 400;
  margin-bottom: 17px;
  border-radius: 0;
  color: #000000;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #c7c7cc;
  &.input__password {
    margin-bottom: 10px;
  }

  &::placeholder {
    color: #c7c7cc;
    opacity: 1;
    font-size: 18px;
  }
  &:hover,
  &:focus {
    background: #fff;
    color: #000000;
    outline: none !important;
  }
  input:focus {
    outline: none !important;
  }
`;
const mapStateToProps = state => ({
  account: state.auth.account,
  isShowLoginModal: state.global.ActionLogin.isShowLoginModal,
  isShowWelcome: state.global.modal.isWelcome,
  isShowVerifyEmailModal: state.global.isShowVerifyEmailModal,
});

const mapDispatchToProps = {
  dispatchRegisterEmail: params => registerEmail(params),
  dispatchLoginEmail: (email, password) => loginEmail(email, password),
  setLoginShowModal: value => setLoginModalShow(value),
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpProjectW);
