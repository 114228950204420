import React from 'react';
import RibbonItem from './ribbonItem';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import Ribbons from '../styled';
import homePageId from '../../../constants/ids/homePageId';
import { withTranslation } from 'react-i18next';
import pushAnalytics from '../../../services/analytics/Analytics';
import { updateRibbonTarget, updateStatusRemove } from '../../../features/homepage/services';
import { connect } from 'react-redux';
import * as display from '../constants';
import { Button } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import RemoveItem from '../../remove/popup';
import { getConfigByKey } from '../../../features/tenantConfig/services';
import { withRouter } from 'react-router';
import { getTenantConfig } from '../../../utils/utils';
import styled from 'styled-components';
import queryString from 'query-string';
import { getRibbonDetail } from '../../ribbon/services';
import LazyLoadRibbon from './ribbonOdv/ribbon-loadmore';
import { settingSlide } from '../functions/initSlide';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class RibbonOdv extends React.Component {
  constructor(props) {
    super(props);
    this.sliderRef = React.createRef();
    this.state = {
      viewMore: 24,
      ribbonItems: [],
      page: 1,
      hasMoreItems: true,
      isRemove: { isOpen: false, items: {} },
    };
  }

  componentWillMount() {
    if (isMobile) {
      this.setState({
        viewMore: 15,
      });
    }
  }

  componentDidMount() {
    const { ribbon } = this.props;
    this.setState({
      ribbonItems: ribbon.items || [],
    });
  }

  componentWillReceiveProps(nextProps) {
    const { ribbon } = nextProps;
    if (ribbon.id !== this.props.ribbon.id) {
      this.setState({
        ribbonItems: ribbon.items || [],
      });
    }
  }

  handleLazyLoad = (curentSilde = null) => {
    const { dpgetRibbonDetail, ribbon } = this.props;
    const { ribbonItems, page, hasMoreItems, viewMore } = this.state;
    let paramQuery = queryString.parse(this.props.search, { decode: false });
    const { slug } = paramQuery;
    let search = `?${this.props.search}&page=${page}`;
    const { innerSlider } = this.sliderRef.current;
    const total = ribbonItems.length;
    const slideScroll = innerSlider.props.slidesToScroll;
    let restSilde = total - (slideScroll + curentSilde);
    if (!hasMoreItems || restSilde > 3) {
      return;
    }

    dpgetRibbonDetail(slug, ribbon.slug, search)
      .then(res => {
        const data = ribbonItems;
        data.push(...res.items);
        if (res.items.length <= 0 || data.length >= viewMore) {
          return this.setState({
            hasMoreItems: false,
          });
        }
        let timer = setTimeout(() => {
          this.setState({
            ribbonItems: data,
            page: page + 1,
          });
          clearTimeout(timer);
        }, 500);
      })
      .catch(() => {})
      .finally(() => {});
  };

  handleResetStatus() {
    let data = {
      isOpen: false,
      items: {},
    };
    this.setState({
      isRemove: data,
    });
    this.props.dpUpdateStatusRemove(data);
  }

  handleOpen(items = {}) {
    let data = {
      isOpen: true,
      items,
    };
    this.setState({
      isRemove: data,
    });
    this.props.dpUpdateStatusRemove(data);
  }

  handleRemoveRecent(data) {
    const { ribbonItems } = this.state;
    let index = ribbonItems.findIndex(item => item.id === data.items.id);
    ribbonItems.splice(index, 1);
    this.setState({
      ribbonItems: ribbonItems,
    });
  }

  _renderRibbonItem(ribbon, ribbonIndex) {
    const { ribbonItems, viewMore } = this.state;
    this.countRibbonShow = 0;
    let ribbonItemsTmp = (ribbonItems.length && ribbonItems) || ribbon.items;
    if (ribbonItemsTmp && ribbonItemsTmp.length > 0) {
      this.countRibbonShow++;
      return ribbonItemsTmp.slice(0, viewMore || 24).map((item, index) => {
        return (
          <RibbonItem
            item={item}
            ribbon={ribbon}
            ribbonIndex={ribbonIndex}
            index={index}
            key={index}
            lazyLoad
            handleOpen={() => this.handleOpen(item)}
          />
        );
      });
    }
    return '';
  }

  _renderViewMore(ribbon) {
    const { dpUpdateRibbonTarget, search, t, language } = this.props;
    const { viewMore } = this.state;
    let paramQuery = queryString.parse(search, { decode: false });
    if (ribbon.items.length <= viewMore - 1) return;
    return (
      <div className="see-more">
        <Ribbons className="ribbonItemContainer" style={{ position: 'relative' }}>
          <div className={`imageWrapper ${display[`CLASS_${ribbon.display_type || 1}`]}`}>
            <Link
              to={{
                pathname: `/${language}/category/${ribbon.slug}`,
                search: `tenants=${paramQuery.slug}&${search ? '?' + search : ''}`,
                state: { ribbon: paramQuery.slug },
              }}
              onClick={() => {
                pushAnalytics('click', {
                  content_type: 'button',
                  item_name: 'See all',
                  widget: ribbon.slug,
                });
                dpUpdateRibbonTarget(ribbon.id);
              }}
            >
              <Button className="srcImg btn-see-more">
                <p>{t('ribbon.txtViewMore')}</p>
              </Button>
            </Link>
          </div>
        </Ribbons>
      </div>
    );
  }

  _renderHead() {
    const { dpUpdateRibbonTarget, isAllButtonDisplay, t, ribbon, search, title, index, language } =
      this.props;
    const ribbonItems = ribbon.items || [];
    let paramQuery = queryString.parse(search, { decode: false });

    return (
      <div className="ribbonHead">
        <h4 data-testid={`${homePageId.ribbonId.titleLbl}-${index}`} className="title">
          {title}
        </h4>
        {/* <div style={{backgroundColor: '#dedede', height: 1, width: '80%', marginTop: 35 }}/> */}
        {isAllButtonDisplay
          ? ribbonItems.length > 0 && (
              <Link
                data-testid={`${homePageId.ribbonId.showAllBtn}-${index}`}
                className="viewAll"
                to={{
                  pathname: `/${language}/category/${ribbon.slug}`,
                  search: `tenants=${paramQuery.slug}&${search ? '?' + search : ''}`,
                  state: { ribbon: paramQuery.slug },
                }}
                onClick={() => {
                  pushAnalytics('click', {
                    content_type: 'button',
                    item_name: 'See all',
                    widget: ribbon.slug,
                  });
                  window.scrollTo(0, 0);
                  dpUpdateRibbonTarget(ribbon.id);
                }}
              >
                <h5 className="title_action">
                  {t('ribbon.txtViewMore')}
                  {/* <i
                    style={{ fontSize: 15 }}
                    className="fa fa-chevron-right icon_chevron_right"
                  /> */}
                </h5>
              </Link>
            )
          : null}
      </div>
    );
  }

  render() {
    const { isRemove, hasMoreItems } = this.state;
    const { index, ribbon, match } = this.props;
    const sliderSettings = settingSlide(this);
    const ribbonItems = ribbon.items || [];
    if (!ribbonItems.length) return null;
    return (
      <StyledRibbons
        className="ribbonContainer"
        data-test={`${display[`DISPLAY_${ribbon.type || 1}`]}`}
      >
        <React.Fragment>
          {this._renderHead(index)}
          <div className={`sliderBlock slider-${display[`CLASS_${ribbon.display_type || 1}`]}`}>
            <Slider {...sliderSettings} ref={this.sliderRef}>
              {this._renderRibbonItem(ribbon, index)}
              {this._renderViewMore(ribbon)}
            </Slider>
          </div>
        </React.Fragment>
        <RemoveItem
          hideModal={() => this.handleResetStatus()}
          modalShow={isRemove.isOpen}
          data={isRemove}
          handleRemoveRecent={data => this.handleRemoveRecent(data)}
        />
      </StyledRibbons>
    );
  }
}

const StyledRibbons = styled(Ribbons)`
  margin-bottom: 40px;
  /* padding-left: 15px;*/
  @media (min-width: 992px) {
    margin-bottom: 48px;
  }
`;

RibbonOdv.defaultProps = {
  title: '',
  ribbon: [],
  isAllButtonDisplay: true,
  onPressViewAll: () => {},
};

const mapStateToProps = state => ({
  account: state.auth.account,
  language: state.root.language,
});

const mapDispatchToProps = {
  dpUpdateRibbonTarget: id => updateRibbonTarget(id),
  dpUpdateStatusRemove: data => updateStatusRemove(data),
  dpgetRibbonDetail: (pageSlug, ribbonSlug, search) =>
    getRibbonDetail(pageSlug, ribbonSlug, search),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RibbonOdv)),
);
