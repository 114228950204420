import { IAxiosResponseData } from '~core/models';
import { apiAdapter, config, axiosTakeDataFromResponse } from '~share';

const { documentService } = apiAdapter;

export function getDocumentByType(params: {
  type: string;
}): Promise<IAxiosResponseData<typeof documentService.getDocuments>> {
  const select = JSON.stringify({
    Document: ['id', 'content', 'updated_at'],
  });

  const { type } = params;
  const { tenantSlug = '', authorization, platformSlug = '', acceptLanguage } = config;
  return documentService
    .getDocuments(type, platformSlug, tenantSlug, authorization, acceptLanguage, select)
    .then(axiosTakeDataFromResponse);
}
