// primary, secondary, tertiary, quaternary, quinary, senary, septenary, octonary, nonary, and denary
// Opacity %   255 Step        2 digit HEX prefix
// 0%          0.00            00
// 5%          12.75           0C
// 10%         25.50           19
// 15%         38.25           26
// 20%         51.00           33
// 25%         63.75           3F
// 30%         76.50           4C
// 35%         89.25           59
// 40%         102.00          66
// 45%         114.75          72
// 50%         127.50          7F
// 55%         140.25          8C
// 60%         153.00          99
// 65%         165.75          A5
// 70%         178.50          B2
// 75%         191.25          BF
// 80%         204.00          CC
// 85%         216.75          D8
// 90%         229.50          E5
// 95%         242.25          F2
// 100%        255.00          FF
const odv = {
  name: 'odv',
  primaryColor: '#2574d4',
  secondaryColor: '#0095ff',
  thirdColor: 'rgba(247,244,243,.7)',
  fourColor: '#4a68e2',
  filterColor:
    'invert(53%) sepia(64%) saturate(6447%) hue-rotate(200deg) brightness(90%) contrast(83%)',
  typography: {
    primaryColor: '#333333',
  },
  spinner: {
    color: '#2574d4',
  },
  body: {
    background: '#111212',
    font: 'Noto Sans',
    colors: '#fff',
  },
  header: {
    logoSize768: '150px',
    logoSize1440: '150px',
    imglogo: '11rem',
    textTranform: 'capitalize',
    backgroundAccount: '#153861',
    background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.88) 0%, rgba(0, 0, 0, 0) 100%)',
  },
  homePage: {
    background: '#111212',
    backgroundRibbon: 'unset',
    colorRibbon: '#000000',
  },
  livePage: {
    colorIndicator: 'red',
  },
  newSlide: {
    btn: 'linear-gradient(0deg, #4257f5 5%, #2239e6 100% )',
    btnUpgrade: 'linear-gradient(0deg, #e8b600 5%, #e8b600 100%)',
    colorTextMobile: '#7d7d7d',
    backgroundMobile: '#fff',
  },
  filter: {
    colorBtn: '#f2f2f2',
    colorBtnActive: '#f2f2f2',
    colorTxt: '#627280',
  },
  ribbon: {
    background: '#111212',
    itemNameColor: '#e4ebee',
    subItemNameColor: '#9b9b9b',
    titleColor: '#ffffff',
    viewAll: '#9b9b9b',
    displayTitle: 'block',
  },
  landingPage: {
    relatedName: '#555555',
    bannerColorDecription: 'rgb(125,125,125)',
    bannerBackgroundDecription: '#111212',
    bannerColorTitle: '#f7f4f3 ',
    background: '#111212',
    description: '#9b9b9b',
    hoverButton: '#8c8c8c',
  },
  searchPage: {
    colorTitle: '#f7f4f3',
    colorKey: '#2574d4',
    suggest: '#9b9b9b',
  },
  input: {
    color: '#283237',
    placeholder: '#999999',
    label: '#197ffc',
    error: '',
    focus: '#2574d4',
    backgroundColor: '#fff',
  },
  signin: {
    secondaryColor: '#596d79',
  },
  register: {
    backgroundColor: '#fbfbfc',
    color: '#283237',
    secondaryColor: '#b3bec4',
    link: '#2574d4',
    buttonSave: {
      color: '#f7f8fa',
      backgroundColor: '#2574d4',
    },
    buttonFB: {
      backgroundColor: '#ffffff',
      color: '#197ffc',
    },
  },
  plans: {
    background: '#111212',
    color: '#ffffff',
    secondaryColor: '#9b9b9b',
    btn: '#197ffc',
    thirdColor: '#197ffc',
    plan: {
      renewalImage: require('../../assets/img/autoRenewal.svg').default,
      buttonColorHover: '#ffffff',
    },
    device: {
      colorTitle: '#1e232a',
      colorDescription: '#596d79',
    },
  },
  ppv: {
    background: '#1E232A',
    backgroundBtn: 'unset',
    colorBtn: '#fff',
    plan: {
      background: '#1E232A',
      colorCategory: '#a7b9c4',
      colorTitle: '#f7f4f3',
      colorTitleOffer: '#fff',
      colorDolar: '#4a90ff',
      showImg: require('~img/img/odv/ppv1.png').default,
      offImg: require('~img/img/odv/ppv2.png').default,
    },
    checkout: {
      colorTitle: '#fff',
      colorTitleSmall: '#d6d6d6',
      colorSelectMethod: '#b3bec4',
      backgroundInput: '#fefefe',
      backgroundInputErr: '#fff3f4',
      txtError: '#f23b46',
      txtLabel: '#94a3ad',
    },
    success: {
      colorTitle: '#fff',
      colorDescription: '#b3bec4',
      colorBtn: '#fff',
      backgroundBtn: '#2574d4',
    },
  },

  regitraton: {
    color: '#283237',
  },
  player: {
    progress: {
      color: '#2574d4',
      loadHolder: '#dadada',
    },
    btnServicePack: '#1558ab',
  },
  profile: {
    sitebar: {
      background: '#2e3540',
      backgroundActive: '#0095ff',
      txtColor: '#f7f4f3',
      txtActive: '#fff',
    },
    rightSite: {
      background: '#f7f4f3',
    },
    unActive: {
      txtColorUnActive: '#fff',
    },
    update: {
      colorChangeEmail: '#4A90E2',
    },
  },
};
const sctv = {
  name: 'sctv',
  primaryColor: '#E72D30',
  secondaryColor: '#e30000',
  thirdColor: '#E72D30',
  fourColor: '#E72D30',
  filterColor:
    'invert(19%) sepia(64%) saturate(4515%) hue-rotate(347deg) brightness(98%) contrast(84%)',
  typography: {
    primaryColor: '#ffffff',
  },
  spinner: {
    color: '#E72D30',
  },
  body: {
    background: '#171717',
    font: 'Roboto',
    colors: '#fff',
  },
  header: {
    logoSize768: '150px',
    logoSize1440: '150px',

    imglogo: '14rem',
    textTranform: 'capitalize',
    backgroundAccount: '#B5611D',
    background:
      'linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0.7) 70%, rgba(0, 0, 0, 0) 100%)',
  },
  homePage: {
    background: '#000000',
    backgroundRibbon: '#000000',
    ribbon: '#000000',
  },
  livePage: {
    colorIndicator: 'red',
  },
  newSlide: {
    btn: 'linear-gradient(0deg, #4257f5 5%, #2239e6 100% )',
    btnUpgrade: 'linear-gradient(0deg, #e8b600 5%, #e8b600 100%)',
    colorTextMobile: '#fff',
    backgroundMobile: '#000000',
  },
  filter: {
    colorBtn: 'red',
    colorBtnActive: 'yellow',
    colorTxt: '#fff',
  },
  ribbon: {
    background: '#000000',
    itemNameColor: '#fff',
    subItemNameColor: '#fff',
    titleColor: '#fff',
    viewAll: 'red',
    displayTitleItem: 'none',
  },
  landingPage: {
    relatedName: '#fff',
    bannerColorDecription: '#fff',
    bannerBackgroundDecription: '#000000',
    background: '#111212',
    bannerColorTitle: '#fff',
    description: '#E72D30',
    hoverButton: '#E72D30',
  },
  searchPage: {
    colorTitle: '#fff',
    colorKey: '#fff',
    suggest: '#fff',
  },
  input: {
    color: '#e30000',
    placeholder: '#94a3ad',
    label: '#94a3ad',
    error: '',
    focus: '#e30000',
    backgroundColor: '#000000',
  },
  register: {
    backgroundColor: '#000000',
    color: '#FFFFFF',
    secondaryColor: '#b3bec4',
    colorInput: '#94a3ad',
    link: '#b3bec4',
    buttonSave: {
      backgroundColor: '#e30000',
      color: '#ffffff',
    },
    buttonFB: {
      backgroundColor: '#fefefe',
      color: '#1977f3',
    },
  },
  signin: {
    secondaryColor: '#d6d6d6',
  },
  plans: {
    background: '#171717',
    color: '#ffffff',
    secondaryColor: '#d6d6d6',
    btn: '#E72D30',
    thirdColor: '#E72D30',
    plan: {
      renewalImage: require('../../assets/img/sctv/auto-renewal.svg').default,
      buttonColorHover: '#ffffff',
    },
    device: {
      colorTitle: '#1e232a',
      colorDescription: '#596d79',
    },
  },
  ppv: {
    background: '#1E232A',
    backgroundBtn: 'unset',
    colorBtn: '#fff',
    plan: {
      background: '#1E232A',
      colorCategory: '#a7b9c4',
      colorTitle: '#f7f4f3',
      colorTitleOffer: '#fff',
      colorDolar: '#4a90ff',
      showImg: require('~img/img/odv/ppv1.png').default,
      offImg: require('~img/img/odv/ppv2.png').default,
    },
    checkout: {
      colorTitle: '#fff',
      colorTitleSmall: '#d6d6d6',
      colorSelectMethod: '#b3bec4',
      backgroundInput: '#fefefe',
      backgroundInputErr: '#fff3f4',
      txtError: '#f23b46',
      txtLabel: '#94a3ad',
    },
    success: {
      colorTitle: '#fff',
      colorDescription: '#b3bec4',
      colorBtn: '#fff',
      backgroundBtn: '#2574d4',
    },
  },
  regitraton: {
    color: '#ffffff',
  },
  player: {
    progress: {
      color: '#2574d4',
      loadHolder: '#dadada',
    },
    btnServicePack: '#E72D30',
  },
  profile: {
    sitebar: {
      background: '#2e3540',
      backgroundActive: '#E72D30',
      txtColor: '#f7f4f3',
      txtActive: '#fff',
    },
    rightSite: {
      background: '#f7f4f3',
    },
    unActive: {
      txtColorUnActive: '#fff',
    },
    update: {
      colorChangeEmail: '#E72D30',
    },
  },
};
const escondido = {
  name: 'escondido',
  primaryColor: '#eb2227',
  secondaryColor: '#ff1e23',
  thirdColor: 'rgba(235, 34, 39, 0.85)',
  fourColor: '#fff',
  filterColor:
    'brightness(0) saturate(100%) invert(25%) sepia(89%) saturate(6069%) hue-rotate(348deg) brightness(94%) contrast(96%)',
  typography: {
    primaryColor: '#333333',
  },
  spinner: {
    color: '#eb2227',
  },
  body: {
    background: '#000000',
    font: 'Roboto Condensed',
    colors: '#fff',
  },
  header: {
    logoSize768: '150px',
    logoSize1440: '150px',

    imglogo: '11rem',
    textTranform: 'uppercase',
    backgroundAccount: '#eb2227',
    background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.88) 0%, rgba(0, 0, 0, 0) 100%)',
  },
  homePage: {
    background: '#000000',
    backgroundRibbon: 'unset',
    colorRibbon: '#000000',
  },
  livePage: {
    colorIndicator: '#faa535',
  },
  newSlide: {
    btn: 'linear-gradient(0deg, #4257f5 5%, #2239e6 100% )',
    btnUpgrade: 'linear-gradient(0deg, #e8b600 5%, #e8b600 100%)',
    colorTextMobile: '#7d7d7d',
    backgroundMobile: '#fff',
  },
  filter: {
    colorBtn: '#f2f2f2',
    colorBtnActive: '#f2f2f2',
    colorTxt: '#627280',
  },
  ribbon: {
    background: '#111212',
    itemNameColor: '#e4ebee',
    subItemNameColor: '#9b9b9b',
    titleColor: '#ffffff',
    viewAll: '#9b9b9b',
    displayTitle: 'block',
  },
  landingPage: {
    relatedName: '#555555',
    bannerColorDecription: 'rgb(125,125,125)',
    background: '#111212',
    bannerBackgroundDecription: '#111212',
    bannerColorTitle: '#fff',
    description: '#9b9b9b',
  },
  searchPage: {
    colorTitle: '#f7f4f3',
    colorKey: '#eb2227',
    suggest: '#9b9b9b',
  },
  input: {
    color: '#283237',
    placeholder: '#999999',
    label: '#eb2227',
    error: '',
    focus: '#eb2227',
    backgroundColor: '#fff',
  },
  signin: {
    secondaryColor: '#596d79',
  },
  register: {
    backgroundColor: '#FFFFFF',
    color: '#283237',
    secondaryColor: '#b3bec4',
    link: '#2574d4',
    buttonSave: {
      color: '#fff',
      backgroundColor: '#eb2227',
    },
    buttonFB: {
      backgroundColor: '#ffffff',
      color: '#eb2227',
    },
  },
  plans: {
    background: '#fff',
    color: '#333333',
    secondaryColor: '#596d79',
    btn: '#eb2227',
    thirdColor: '#eb2227',
    plan: {
      renewalImage: require('../../assets/img/autoRenewal.svg').default,
      buttonColorHover: '#ffffff',
    },
    device: {
      colorTitle: '#1e232a',
      colorDescription: '#596d79',
    },
  },
  ppv: {
    background: '#1E232A',
    backgroundBtn: 'unset',
    colorBtn: '#fff',
    plan: {
      background: '#1E232A',
      colorCategory: '#a7b9c4',
      colorTitle: '#f7f4f3',
      colorTitleOffer: '#fff',
      colorDolar: '#eb2227',
      showImg: require('~img/img/odv/ppv1.png').default,
      offImg: require('~img/img/odv/ppv2.png').default,
    },
    checkout: {
      colorTitle: '#fff',
      colorTitleSmall: '#d6d6d6',
      colorSelectMethod: '#b3bec4',
      backgroundInput: '#fefefe',
      backgroundInputErr: '#fff3f4',
      txtError: '#f23b46',
      txtLabel: '#94a3ad',
    },
    success: {
      colorTitle: '#fff',
      colorDescription: '#b3bec4',
      colorBtn: '#fff',
      backgroundBtn: '#2574d4',
    },
  },
  regitraton: {
    color: '#283237',
  },
  player: {
    progress: {
      color: '#eb2227',
      loadHolder: '#dadada',
    },
    btnServicePack: '#eb2227',
  },
  profile: {
    sitebar: {
      background: '#2e3540',
      backgroundActive: '#eb2227',
      txtColor: '#f7f4f3',
      txtActive: '#fff',
    },
    rightSite: {
      background: '#f7f4f3',
    },
    unActive: {
      txtColorUnActive: '#fff',
    },
    update: {
      colorChangeEmail: '#eb2227',
    },
  },
};

const projectw = {
  name: 'projectw',
  primaryColor: '#000',
  secondaryColor: '#000',
  thirdColor: 'rgba(247,244,243,.7)',
  typography: {
    primaryColor: '#333333',
  },
  spinner: {
    color: '#fff',
  },
  body: {
    background: '#000',
    font: 'Neue Haas Grotesk Display Pro',
    colors: '#fff',
  },
  header: {
    logoSize768: '135px',
    logoSize1440: '224px',
    imglogo: '104px',
    textTranform: 'capitalize',
    backgroundAccount: '#153861',
    background: '#fff',
  },
  homePage: {
    background: '#131313',
    backgroundRibbon: '#111212',
    colorRibbon: '#000000',
  },
  newSlide: {
    btn: 'linear-gradient(0deg, #4257f5 5%, #2239e6 100% )',
    btnUpgrade: 'linear-gradient(0deg, #e8b600 5%, #e8b600 100%)',
    colorTextMobile: '#7d7d7d',
    backgroundMobile: '#fff',
  },
  filter: {
    colorBtn: '#f2f2f2',
    colorBtnActive: '#f2f2f2',
    colorTxt: '#627280',
  },
  ribbon: {
    background: '#111212',
    itemNameColor: '#e4ebee',
    subItemNameColor: '#9b9b9b',
    titleColor: '#e5e5e5',
    viewAll: '#9b9b9b',
    displayTitle: 'block',
  },
  landingPage: {
    relatedName: '#555555',
    bannerColorDecription: 'rgb(125,125,125)',
    bannerBackgroundDecription: '#111212',
    bannerColorTitle: '#f7f4f3 ',
    background: '#111212',
    description: '#9b9b9b',
    selectNewEps: '#2574d4',
    hoverButton: '#8c8c8c',
  },
  searchPage: {
    colorTitle: '#f7f4f3',
    colorKey: '#2574d4',
    suggest: '#9b9b9b',
  },
  input: {
    color: '#283237',
    placeholder: '#999999',
    label: '#2574d4',
    error: '',
    focus: '#2574d4',
    backgroundColor: '#fff',
  },
  signin: {
    secondaryColor: '#596d79',
  },
  register: {
    backgroundColor: '#FFFFFF',
    color: '#283237',
    secondaryColor: '#b3bec4',
    link: '#2574d4',
    buttonSave: {
      color: '#f7f8fa',
      backgroundColor: '#2574d4',
    },
    buttonFB: {
      backgroundColor: '#ffffff',
      color: '#2574d4',
    },
  },
  plans: {
    background: '#fff',
    color: '#333333',
    secondaryColor: '#596d79',
    plan: {
      buttonBorderColor: '#2574d4',
      renewalImage: require('../../assets/img/autoRenewal.svg').default,
      buttonColorHover: '#ffffff',
    },
  },
  ppv: {
    background: '#1E232A',
    backgroundBtn: 'unset',
    colorBtn: '#fff',
    plan: {
      background: '#1E232A',
      colorCategory: '#a7b9c4',
      colorTitle: '#f7f4f3',
      colorTitleOffer: '#fff',
      colorDolar: '#4a90ff',
      showImg: 'none',
      offImg: require('~img/img/odv/ppv2.png').default,
    },
    checkout: {
      colorTitle: '#fff',
      colorTitleSmall: '#d6d6d6',
      colorSelectMethod: '#b3bec4',
      backgroundInput: '#fefefe',
      backgroundInputErr: '#fff3f4',
      txtError: '#f23b46',
      txtLabel: '#94a3ad',
    },
    success: {
      colorTitle: '#fff',
      colorDescription: '#b3bec4',
      colorBtn: '#fff',
      backgroundBtn: '#2574d4',
    },
  },

  regitraton: {
    color: '#283237',
  },
  player: {
    progress: {
      color: '#2574d4',
      loadHolder: '#dadada',
    },
    btnServicePack: '#eb2227',
  },
  profile: {
    sitebar: {
      background: '#fff',
      backgroundActive: '#000',
      txtColor: '#000',
      txtfirstColor: '#fff',
      txtActive: '#fff',
    },
    rightSite: {
      background: '#fff',
    },
    unActive: {
      txtColorUnActive: '#000',
    },
    update: {
      colorChangeEmail: '#000',
    },
  },
};
export const data = {
  odv,
  sctv,
  escondido,
  //TODO : Config Theme for ProjectW
  projectw,
};
