import videojs from 'video.js';
// Get the Component base class from Video.js
var BigPlayButton = videojs.getComponent('BigPlayButton');

// The videojs.extend function is used to assist with inheritance. In
// an ES6 environment, `class SeekNext extends Component` would work
// identically.

var ButtonBigPause = videojs.extend(BigPlayButton, {
  // The constructor of a component receives two arguments: the
  // player it will be associated with and an object of options.
  constructor: function (player, options) {
    // It is important to invoke the superclass before anything else,
    // to get all the features of components out of the box!
    BigPlayButton.apply(this, arguments);
    // If a `text` option was passed in, update the text content of
    // the component.
  },

  // The `createEl` function of a component creates its DOM element.
  createEl: function () {
    const btn = videojs.dom.createEl('button', {
      className: 'vjs-big-pause-button',
    });
    btn.innerHTML = '<span class="vjs-icon-pause" aria-hidden="true"></span>';
    return btn;
  },
  handleClick: function (event) {
    this.player_.pause();
  },
});

// Register the component with Video.js, so it can be used in players.
videojs.registerComponent('ButtonBigPause', ButtonBigPause);
