import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { loading } from '~features/loadingPage/services';
import { withTranslation } from 'react-i18next';
import { setUserProperties } from '~root/services/analytics/SetUserProperties';
import { getTenantConfig } from '~root/utils/utils';
import { RowStyled } from './styled';
import { isMobileOnly } from 'react-device-detect';
import OverlayLoading from '~components/loading/overlayLoading';
import NewSlickSlider from '~components/newSlider';
import WrapRibbon from '../components/wrapRibbon';
import { tenantEnvConfig } from '~constants/tenantConfig/tenantEnvConfig';
import { getConfigByKey } from '~features/tenantConfig/services';
import loadable from '@loadable/component';
import { isBrowser } from '~utils/ssr';
import { isNode } from '~utils/ssr';

import AdsBanner from '~components/adsBanner/views/index';
import FilterContent from '~components/filter/filter';
// const AdsBanner = loadable(() => import('~components/adsBanner/views/index'))
// const FilterContent = loadable(() => import('~components/filter/filter'))

class HomeOdv extends PureComponent {
  constructor(props) {
    super(props);
    this.isHomepage = true;
    this.state = {
      privacyPolicyModalShow: false,
      termOfUseModalShow: false,
      count: 1,
      ribbons: null,
      ribbonMobile: null,
      isMobile: window.innerWidth <= 992,
    };

    this.filterRef = React.createRef();
  }

  componentDidMount() {
    setUserProperties();
    const {
      dispatchLoadingPage,
      dispatchGetFavorite,
      dpGetReactedContents,
      page,
      location,
      account,
      match,
    } = this.props;
    const { state, search } = location;

    let params = queryString.parse(search);
    window.document.body.classList.add('v-homePage');
    const locales = tenantEnvConfig.locales ||
      getConfigByKey('locales') || ['vi', 'en', 'ko', 'es'];
    let stringLanguage = locales && locales.join('|');

    const baseRouteUrl = `/:lng(${stringLanguage})`;
    if (match.path !== '/:lg') {
      window.document.body.classList.add('v-tenant');
    }
    window.addEventListener('resize', this.updateDimensions);

    dispatchLoadingPage(false);
    this.setState({
      ribbons: page && page.ribbons,
      ribbonMobile: this.sortData(page),
    });
    if (state && state.login && !this.props.account) {
      this.props.showLoginAction();
    }
    const token = params ? params.code : null;
    if (token) this.props.showLoginAction();
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
    window.document.body.classList.remove('v-homePage');
    window.document.body.classList.remove('v-tenant');

    window.removeEventListener('resize', this.updateDimensions);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isLoadingPage !== nextProps.isLoadingPage) {
      this.setState(
        {
          ribbons: null,
          ribbonMobile: null,
        },
        () => {
          this.setState({
            ribbons: nextProps.page && nextProps.page.ribbons,
            ribbonMobile: this.sortData(nextProps.page),
          });
        },
      );
      if (nextProps.location.pathname !== '/') {
        return window.document.body.classList.add('v-tenant');
      }
      window.document.body.classList.remove('v-tenant');
    }
  }

  sortData(page) {
    if (!page || !page.side_navigation_section) return;
    let { ribbons, side_navigation_section } = page;
    let list = ribbons;
    if (side_navigation_section) {
      list = [...list, ...side_navigation_section];
    }
    let ribbonTmp = this.unique(list).sort((a, b) => {
      if (a.odr > b.odr) return 1;
      return -1;
    });
    return ribbonTmp;
  }

  unique(arr) {
    const unique = arr
      .map(e => e['id'])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e])
      .map(e => arr[e]);
    return unique;
  }

  updateDimensions = () => {
    const { page } = this.props;
    const { isMobile } = this.state;

    if (!page || isMobileOnly) {
      return;
    }
    let { ribbons } = page;
    let innerWidth = window.innerWidth;
    if (innerWidth > 992 && isMobile) {
      return this.setState({
        ribbons: ribbons,
        isMobile: false,
      });
    }
    if (innerWidth <= 992 && !isMobile) {
      this.setState({
        isMobile: true,
      });
    }
  };

  render() {
    const { isLoadingPage, auth, page } = this.props;
    const { ribbons, isMobile, ribbonMobile } = this.state;
    const tenantConfig = getTenantConfig(tenantEnvConfig.ui_theme);
    const ribbonData = isMobile ? ribbonMobile : ribbons;
    return (
      <RowStyled className="v-home" props={{ isHasSubcription: auth.isHasSubcription }}>
        {isLoadingPage && isBrowser() ? <OverlayLoading /> : null}
        <div className="v-home__container">
          <NewSlickSlider showLoginAction={this.props.showLoginAction} isMobile={isMobile} />
          <FilterContent
            ref={this.filterRef}
            filterTenant
            visible={tenantConfig.HOME_RENDER_FILTER}
          />
          {ribbonData || isNode() ? (
            <WrapRibbon ribbon={ribbonData || (page && page.ribbons)}>
              <AdsBanner position="top" customeClass="v-home__left-site--ads" />
            </WrapRibbon>
          ) : null}
          <AdsBanner position="bottom" />
        </div>
      </RowStyled>
    );
  }
}

HomeOdv.defaultProps = {
  showLoginAction: param => {},
};
HomeOdv.propTypes = {
  account: PropTypes.object,
  menu: PropTypes.any,
  page: PropTypes.any,
  isLoadingPage: PropTypes.any,
  dispatchGetMenu: PropTypes.func,
  dispatchGetPage: PropTypes.func,
  dispatchGetMenuPage: PropTypes.func,
  showLoginAction: PropTypes.func,
};

const mapStateToProps = state => ({
  menu: state.home.menu,
  page: state.home.page,
  isLoadingPage: state.home.isLoadingPage,
  account: state.auth.account,
  auth: state.auth,
});

const mapDispatchToProps = {
  dispatchLoadingPage: value => loading(value),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HomeOdv)));
