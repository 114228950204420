/* tslint:disable */
/* eslint-disable */
/**
 * Vimai Core Service UI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentBlockingErrorCodeEnum = exports.ContentSubtypeEnum = void 0;
exports.ContentSubtypeEnum = {
    Default: 'default',
    VarietyShow: 'variety_show',
    TvNews: 'tv_news'
};
exports.ContentBlockingErrorCodeEnum = {
    GeoBlocking: 'geo-blocking',
    MembershipBlocked: 'membership-blocked',
    AgeRestrictionBlocked: 'age-restriction-blocked',
    ContentPpvBlocked: 'content-ppv-blocked',
    ContentGuestBlocked: 'content-guest-blocked',
    ContentAnonymousBlocked: 'content-anonymous-blocked',
    ConcurrentLimitBlocked: 'concurrent-limit-blocked'
};
