export const getHomePageSelector = state => {
  try {
    return state.home.page;
  } catch (error) {
    return null;
  }
};
export const getFilterDataSelector = state => {
  try {
    return state.filter.filterData;
  } catch (error) {
    return null;
  }
};
