import React from 'react';
import { RibbonDeskTop, RibbonMobile } from '..';

function RibbonComponent(props) {
  const { isMobileDetect } = props;
  if (!isMobileDetect) {
    return <RibbonDeskTop {...props} />;
  }
  return <RibbonMobile {...props} />;
}

export default RibbonComponent;
