import * as type from '../../constants';
import '../../videoComponents';
import { controlMovie } from './movie';
import { controlShow } from './show';
import { controlLive } from './live';

const ControlBarEscondido = (player, component, data = {}) => {
  if (player.childrenAdded) {
    return;
  }
  const { entityDetail } = data;
  const controlBar = player.getChild('controlBar');
  const { infoShow, isRestrictions } = component.state;

  let isAdult = infoShow ? infoShow.restriction : entityDetail.restriction;
  player.addChild('BackButton', { component: component });
  player.addChild('BtnReports', {
    component: component,
    entity: entityDetail,
  });
  player.addChild('Reports', {
    component: component,
    channelId: entityDetail.id,
  });
  if (isAdult && !isRestrictions) {
    player.addChild('WarningScreen', {
      component: component,
      channelId: entityDetail.id,
    });
  }
  if (entityDetail.type === type.CURATED_LIVE || entityDetail.type === type.CHANNEL_TYPE) {
    return controlLive(controlBar, component, data, player);
  }
  if (entityDetail.type === type.SHOW_TYPE || entityDetail.type === type.EPISODE_TYPE) {
    return controlShow(controlBar, component, data, player);
  }
  return controlMovie(controlBar, component, entityDetail);
  // init.handelEvent(component, (data && data.renderLive) || {})
};

export default ControlBarEscondido;
