import { IAxiosResponseData } from '~core/models';
import { apiAdapter, config, axiosTakeDataFromResponse } from '~share';
import camelcaseKeys from 'camelcase-keys';

const { contentListingService } = apiAdapter;

function getLiveSchedules(
  pageSlug: string,
  params: {
    ribbon_ids: string;
    timezone?: string;
    mode: 'range_time' | 'freeze';
    start_time: number;
    end_time: number;
  },
): Promise<IAxiosResponseData<typeof contentListingService.getLiveSchedulesForPage>> {
  const { ribbonIds, timezone, mode, startTime, endTime } = camelcaseKeys(params);
  const {
    tenantSlug = '',
    authorization,
    imageResolutionScale,
    platformSlug = '',
    acceptLanguage,
  } = config;
  return contentListingService
    .getLiveSchedulesForPage(
      pageSlug,
      platformSlug,
      tenantSlug,
      startTime,
      endTime,
      authorization,
      acceptLanguage,
      undefined,
      imageResolutionScale,
      ribbonIds,
      timezone,
      mode,
    )
    .then(axiosTakeDataFromResponse);
}

export { getLiveSchedules };
