var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OdxUserContentApi = void 0;
var integration_service_1 = require("../integration-service");
var mappers_1 = require("../mappers");
var content_utils_1 = require("../utils/content-utils");
var cacheable_requests_1 = require("../requests/cacheable-requests");
var utils_1 = require("../utils");
var OdxUserContentApi = /** @class */ (function (_super) {
    __extends(OdxUserContentApi, _super);
    function OdxUserContentApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OdxUserContentApi.prototype.addUserRecentContent = function (data, authorization, options) {
        return Promise.reject('Method not implemented');
    };
    OdxUserContentApi.prototype.createReviewOfContent = function (contentId, tenantSlug, data, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxUserContentApi.prototype.getAccountInteractedContents = function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, select, parentId, contentIds, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var userId, profileId, isPrimaryProfile, contentInfo, cwInfo, info, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        userId = parseInt(accountId, 10);
                        profileId = this.provider.get('profileId');
                        isPrimaryProfile = this.provider.get('isPrimaryProfile');
                        contentInfo = (0, content_utils_1.getIdInfoFromContentIdentity)(parentId);
                        return [4 /*yield*/, this.odxCWService.odxCW.cwV3GetCwByProfileProgram(tenantSlug, userId, parseInt(profileId, 10), contentInfo.id, isPrimaryProfile ? 'true' : 'false')];
                    case 1:
                        cwInfo = _a.sent();
                        info = {
                            items: cwInfo.data.results.map(mappers_1.mapCWContinuousWatchingHistoryToInteractedContent),
                            metadata: null,
                        };
                        resolve(__assign(__assign({}, cwInfo), { data: info }));
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        reject((0, utils_1.transformError)(e_1));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxUserContentApi.prototype.getContentProgress = function (authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxUserContentApi.prototype.getCurrentContentProgress = function (contentId, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxUserContentApi.prototype.getFavoriteContents = function (authorization, acceptLanguage, select, imageResolutionScale, resolution, size, cursor, xhr, imageFormat, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var tenantSlug, data, e_2;
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 2, , 3]);
                        tenantSlug = this.provider.get('tenantSlug');
                        return [4 /*yield*/, this.odxService.odxProfileFavoritesApi.profileFavoritesList(tenantSlug, acceptLanguage, undefined, 1, 100)];
                    case 1:
                        data = _d.sent();
                        resolve(__assign(__assign({}, data), { data: {
                                items: (_c = (_b = (_a = data.data) === null || _a === void 0 ? void 0 : _a.result) === null || _b === void 0 ? void 0 : _b.results) === null || _c === void 0 ? void 0 : _c.map(mappers_1.mapFavoritesToMovieDetail),
                                cursor: '',
                            } }));
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _d.sent();
                        reject((0, utils_1.transformError)(e_2));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxUserContentApi.prototype.getListWatchlater = function (authorization, acceptLanguage, resolution, size, cursor, xhr, options) {
        return Promise.reject('Method not implemented');
    };
    OdxUserContentApi.prototype.getReactedContents = function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, options) {
        return Promise.reject('Method not implemented');
    };
    OdxUserContentApi.prototype.getRecentContentOfUser = function (authorization, page, limit, xhr, options) {
        return Promise.reject('Method not implemented');
    };
    OdxUserContentApi.prototype.getReviewOfContent = function (contentId, tenantSlug, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxUserContentApi.prototype.getSelectedSubtitle = function (contentId, authorization, options) {
        return Promise.reject('Method not implemented');
    };
    OdxUserContentApi.prototype.getUserContent = function (userId, contentId, authorization, options) {
        return Promise.reject('Method not implemented');
    };
    OdxUserContentApi.prototype.getUserContentProgress = function (contentId, authorization, options) {
        return Promise.reject('Method not implemented');
    };
    OdxUserContentApi.prototype.getUserWatchlater = function (userId, cursor, authorization, options) {
        return Promise.reject('Method not implemented');
    };
    OdxUserContentApi.prototype.invokeFavoriteContent = function (data, authorization, acceptLanguage, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var slug, tenantSlug, result, currentData, e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        slug = (0, content_utils_1.getIdInfoFromContentIdentity)(data.content_id).slug;
                        tenantSlug = this.provider.get('tenantSlug');
                        result = void 0;
                        return [4 /*yield*/, this.odxService.odxProgramApi.programRetrieve(slug, tenantSlug, undefined, acceptLanguage)];
                    case 1:
                        currentData = _a.sent();
                        if (!!currentData.data.result.is_favorite) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.odxService.odxProfileFavoritesApi.profileFavoritesCreate(tenantSlug, undefined, acceptLanguage, {
                                program_slug: slug,
                            })];
                    case 2:
                        result = _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.odxService.odxProfileFavoritesApi.profileFavoritesDelete(slug, tenantSlug, undefined, acceptLanguage)];
                    case 4:
                        result = _a.sent();
                        _a.label = 5;
                    case 5:
                        resolve(__assign(__assign({}, result), { data: null }));
                        return [3 /*break*/, 7];
                    case 6:
                        e_3 = _a.sent();
                        reject((0, utils_1.transformError)(e_3));
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxUserContentApi.prototype.invokeWatchlaterContent = function (data, authorization, options) {
        return Promise.reject('Method not implemented');
    };
    OdxUserContentApi.prototype.reactContent = function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxUserContentApi.prototype.removeContentFromHistory = function (contentId, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxUserContentApi.prototype.selectSubtitle = function (data, authorization, options) {
        return Promise.reject('Method not implemented');
    };
    OdxUserContentApi.prototype.setUserContentRating = function (data, authorization, options) {
        return Promise.reject('Method not implemented');
    };
    OdxUserContentApi.prototype.updateContentProgress = function (contentId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var tenantSlug, userId, profileId, isPrimaryProfile, _a, id, slug, type, episodeId, programId, movieInfoResponse, movieInfo, episodeInfoResponse, episodeInfo, contentDuration, result, e_4;
            var _b, _c, _d, _e, _f, _g;
            return __generator(this, function (_h) {
                switch (_h.label) {
                    case 0:
                        _h.trys.push([0, 6, , 7]);
                        tenantSlug = this.provider.get('tenantSlug');
                        userId = this.provider.get('userId');
                        profileId = this.provider.get('profileId');
                        isPrimaryProfile = this.provider.get('isPrimaryProfile');
                        if (!userId) {
                            reject('user_id not found');
                            return [2 /*return*/];
                        }
                        _a = (0, content_utils_1.getIdInfoFromContentIdentity)(contentId), id = _a.id, slug = _a.slug, type = _a.type;
                        episodeId = void 0;
                        programId = void 0;
                        if (!(type === 'movie')) return [3 /*break*/, 2];
                        return [4 /*yield*/, cacheable_requests_1.CacheableRequests.getProgramInformation(slug, tenantSlug, acceptLanguage)];
                    case 1:
                        movieInfoResponse = _h.sent();
                        movieInfo = (_b = movieInfoResponse === null || movieInfoResponse === void 0 ? void 0 : movieInfoResponse.data) === null || _b === void 0 ? void 0 : _b.result;
                        programId = movieInfo === null || movieInfo === void 0 ? void 0 : movieInfo.id;
                        episodeId = (_c = movieInfo === null || movieInfo === void 0 ? void 0 : movieInfo.direct_play_episode) === null || _c === void 0 ? void 0 : _c.id;
                        _h.label = 2;
                    case 2:
                        if (!(type === 'episode')) return [3 /*break*/, 4];
                        return [4 /*yield*/, cacheable_requests_1.CacheableRequests.getEpisodeInformation(id, tenantSlug, acceptLanguage)];
                    case 3:
                        episodeInfoResponse = _h.sent();
                        episodeInfo = (_d = episodeInfoResponse === null || episodeInfoResponse === void 0 ? void 0 : episodeInfoResponse.data) === null || _d === void 0 ? void 0 : _d.result;
                        programId = (_e = episodeInfo === null || episodeInfo === void 0 ? void 0 : episodeInfo.program) === null || _e === void 0 ? void 0 : _e.id;
                        episodeId = episodeInfo === null || episodeInfo === void 0 ? void 0 : episodeInfo.id;
                        _h.label = 4;
                    case 4:
                        if (!(programId && episodeId)) {
                            console.log('Unable to update content progress. This can be logic error');
                            reject('Content not found');
                        }
                        contentDuration = void 0;
                        try {
                            contentDuration = parseInt((_f = data.total_duration) === null || _f === void 0 ? void 0 : _f.toString(), 10);
                        }
                        catch (_) {
                            contentDuration = 0;
                        }
                        return [4 /*yield*/, this.odxCWService.odxCW.cwV3Post(tenantSlug, {
                                user_id: parseInt(userId, 10),
                                profile_id: parseInt(profileId, 10),
                                is_primary: isPrimaryProfile,
                                program_id: programId,
                                episode_id: episodeId,
                                duration: contentDuration,
                                last_position: parseInt((_g = data.progress) === null || _g === void 0 ? void 0 : _g.toString(), 10),
                                total_play_time: 1,
                            })];
                    case 5:
                        result = _h.sent();
                        resolve(__assign(__assign({}, result), { data: null }));
                        return [3 /*break*/, 7];
                    case 6:
                        e_4 = _h.sent();
                        reject((0, utils_1.transformError)(e_4));
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxUserContentApi.prototype.updateContentProgress_1 = function (data, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxUserContentApi.prototype.updateUserContentProgress = function (data, authorization, options) {
        return Promise.reject('Method not implemented');
    };
    OdxUserContentApi.prototype.removeContentFromWatchingList = function (accountId, contentId, tenantSlug, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    return OdxUserContentApi;
}(integration_service_1.OdxIntegration));
exports.OdxUserContentApi = OdxUserContentApi;
