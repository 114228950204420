import { getConfigByKey } from '~features/tenantConfig/services';
import 'moment-duration-format';
import moment, { Moment } from 'moment';

import _ from 'lodash';
import queryString from 'query-string';
import videojs from 'video.js';
import { useTranslation } from 'react-i18next';
import mime from 'mime-types';
import {
  Content,
  PaidContentByAccountResponse,
  Login,
  PaidContentRefundResponse,
  TVODPrice,
} from '~vimai-api-adapter';
import {
  DEFAULT_BACKDROP,
  DEFAULT_BANNER_16_9,
  DEFAULT_THUMBNAIL_9_5,
  CONTENT_TYPE,
  EPISODE_TYPE,
  PENDING_STATUS,
  SHOW_TYPE,
  LIVE_EVENT,
  SIMULATED_LIVE_EVENT,
  IMAGE_BACKDROP_TYPE,
  IMAGE_THUMBNAIL_TYPE,
  IMAGE_BANNER_TYPE,
  SUPPORT_FORMAT_WEBP,
} from '~core/constants';
import { getImageWidth, getResolutionScaleDevice } from '~utils/utils';
import { isDVR } from './licensePeridosMethod';
import { hasSubscription } from './accountMethod';

export function timeFormatDuration(this: Content, content: Content) {
  const self: Content = this;
  const { duration = 0 } = content || self;
  if (!duration) {
    return '';
  }

  const time = moment.duration(duration, 'seconds');
  const hours = _.toInteger(duration / (60 * 60));
  const minutes = _.toInteger((duration % 3600) / 60);
  const hr = `${hours === 0 ? '' : `${hours}${hours > 1 ? 'hrs' : 'hr'}`}`;
  const min = `${minutes === 0 ? '' : `${minutes}${minutes > 1 ? 'mins' : 'min'}`}`;

  return time.format('h _, m _', {
    trim: 'all',
  });
}

export function timeFormatDurationHHMMSS(this: Content, content: Content) {
  const self: Content = content || this;
  const { duration: durationSeconds = 0, runtime: durationMinutes = 0 } = self;

  const duration = durationSeconds || 0;
  const durationEmpty = '00:00:00';
  // if (!duration) {
  //   duration = durationMinutes * 60

  // }
  const hours = _.toInteger(duration / (60 * 60))
    .toString()
    .padStart(2, '0');
  const minutes = _.toInteger((duration % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const seconds = _.toInteger(duration % 60)
    .toString()
    .padStart(2, '0');
  const durationTimeStr = `${hours}:${minutes}:${seconds}`;
  if (durationTimeStr === durationEmpty) {
    return '';
  }
  return durationTimeStr;
}

export function canWatchFreeContent(this: Content) {
  const self: Content = this;
  let result = false;
  result = !!(self.is_watchable && !isPPV.call(self));
  if (self.type === CONTENT_TYPE.SHOW_TYPE) {
    result = !!(
      self.num_first_episode_preview ||
      0 > 0 ||
      self.has_free_content ||
      (self.current_season &&
        self.current_season.current_episode &&
        self.current_season.current_episode.can_preview)
    );
  }

  return result;
}

export function isPremiumContent(this: Content) {
  const self: Content = this;

  try {
    return self.is_premium;
  } catch (error) {}
  return false;
}

export function isPPV(this: Content) {
  const self: Content = this;

  try {
    return !_.isEmpty(self.price) || !_.isEmpty(self.prices);
  } catch (error) {}
  return false;
}

export function hasBeenPaidContent(this: Content) {
  const self: Content = this;

  if (this.type == CONTENT_TYPE.EPISODE_TYPE) {
    return (
      self.show_info &&
      self.show_info.payment_infors &&
      self.show_info.payment_infors.remaining_time
    );
  }

  return !!(self.payment_infors && self.payment_infors.remaining_time);
}

export function isPaidContentOfList(
  this: Content,
  paidContents: PaidContentByAccountResponse[] = [],
) {
  const self: Content = this;

  if (paidContents.length === 0) {
    return false;
  }
  let content = null;
  for (const paidContent of paidContents) {
    if (self.id === paidContent.content_id) {
      content = paidContent;
      break;
    }
  }
  return content !== null;
}

export function canWatchContent(
  this: Content,
  account: Login,
  paidContents: PaidContentByAccountResponse[] = [],
) {
  const self: Content = this;

  if (canWatchFreeContent.call(self)) {
    return true;
  }

  if (_.isEmpty(account)) {
    return false;
  }

  return !!(
    hasSubscription.call(account) ||
    hasBeenPaidContent.call(self) ||
    isPaidContentOfList.call(self, paidContents)
  );
}

export function isPending(this: Content) {
  const self: Content = this;

  try {
    let { status = null } = self.refund || {};
    if (self.show_info && self.show_info.refund && self.type === EPISODE_TYPE) {
      status = self.show_info.refund.status || null;
    }
    if (status === PENDING_STATUS) {
      return true;
    }
  } catch (error) {
    return false;
  }
  return false;
}

// get attrs

export function getBanner19_6(this: Content) {
  const self: Content = this;

  try {
    const { banner_19_6_ratio, banner, backdrop, thumbnail_9_5_ratio, thumbnail } =
      self.images || {};

    const result =
      banner_19_6_ratio ||
      banner ||
      backdrop ||
      thumbnail_9_5_ratio ||
      thumbnail ||
      DEFAULT_BANNER_16_9;
    return addParamsLinkImage(result, {
      width: getImageWidth(getResolutionScaleDevice(), IMAGE_BANNER_TYPE),
    });
  } catch (error) {
    return '';
  }
}

export function getThumbnail9_5(this: Content) {
  const self: Content = this;

  try {
    const { thumbnail_9_5_ratio, thumbnail } = self.images || {};
    const result = thumbnail_9_5_ratio || thumbnail || DEFAULT_THUMBNAIL_9_5 || '';
    return addParamsLinkImage(result, {
      width: getImageWidth(getResolutionScaleDevice(), IMAGE_THUMBNAIL_TYPE),
    });
  } catch (error) {
    return '';
  }
}

export function getBackdrop(this: Content) {
  const self: Content = this;

  try {
    const { backdrop, banner_19_6_ratio, banner } = self.images || {};
    const result = backdrop || banner_19_6_ratio || banner || DEFAULT_BACKDROP || '';
    return addParamsLinkImage(result, {
      width: getImageWidth(getResolutionScaleDevice(), IMAGE_BACKDROP_TYPE),
    });
  } catch (error) {
    return '';
  }
}

export function getCurrentEpisode(this: Content) {
  const self: Content = this;

  try {
    if (self.type !== SHOW_TYPE) {
      return null;
    }
    if (self.current_season && self.current_season.current_episode) {
      return self.current_season.current_episode;
    }
  } catch (error) {}
  return null;
}

export function getProgressPercent(this: Content) {
  const self: Content = this;

  try {
    const obj = self.type === SHOW_TYPE ? getCurrentEpisode.call(self) : self;
    const { duration = 0, progress = 0 } = obj || {};

    const percent = ((progress || 0) / (duration || 0)) * 100;

    const percentRounded = _.round(percent, 2);

    if (!Number.isFinite(percent)) {
      return 0;
    }
    if (percent < 0) {
      return 0;
    }
    if (percentRounded > 100) {
      return 100;
    }
    return percentRounded;
  } catch (error) {
    return 0;
  }
}

export function convertCurrency(this: PaidContentRefundResponse) {
  const self: PaidContentRefundResponse = this;

  try {
    const { amount = 0, currency_unit = '' } = self;
    const currencyFormatter = require('currency-formatter');
    return currencyFormatter.format(amount, {
      code: `${currency_unit.toUpperCase()}`,
    });
  } catch (error) {}
}

export function convertCurrency_String(this: TVODPrice) {
  const self: TVODPrice = this;

  try {
    const { price = 0, currency = '' } = self;
    const currencyFormatter = require('currency-formatter');
    return currencyFormatter.format(price, {
      code: `${currency.toUpperCase()}`,
    });
  } catch (error) {
    return '';
  }
}

export function getTrailers(this: Content) {
  const self: Content = this;

  try {
    return self.trailers || [];
  } catch (error) {}
  return [];
}

export function getFirstTrailer(this: Content) {
  const self: Content = this;

  try {
    const trailers = getTrailers.call(self);
    return trailers[0];
  } catch (error) {}
  return null;
}

export function isLiveEvent(this: Content) {
  const self: Content = this;

  try {
    if (self.type === LIVE_EVENT || self.type === SIMULATED_LIVE_EVENT) {
      return true;
    }
  } catch (error) {
    return false;
  }
  return false;
}

export function getDurationToStartOn(this: Content, now: Moment) {
  const self: Content = this;

  try {
    const { duration = 0, start_on } = self;
    if (!start_on) {
      return null;
    }
    const startOnMoment = moment(start_on);
    const nowMoment = now ? now.clone() : moment();
    const durationToStartAt = startOnMoment.clone().diff(nowMoment.clone(), 'seconds');

    return durationToStartAt;
  } catch (error) {}
  return null;
}

export function getStartAt(this: Content) {
  const self: Content = this;

  try {
    const { duration = 0, start_on } = self;
    if (!start_on) {
      return null;
    }

    const startAt = moment(start_on);
    return startAt;
  } catch (error) {}
  return null;
}
export function getEndAt(this: Content) {
  const self: Content = this;

  try {
    const { duration = 0, start_on } = self;
    if (!start_on) {
      return null;
    }

    const endAt = moment(start_on).add(duration, 'seconds');
    return endAt;
  } catch (error) {}
  return null;
}

export function getDurationToEndLive(this: Content, now: Moment) {
  const self: Content = this;

  try {
    const { duration = 0, start_on } = self;
    if (!start_on) {
      return null;
    }
    const nowMoment = now ? now.clone() : moment();

    const endAt = getEndAt.call(self);
    if (endAt === null) {
      return null;
    }
    const durationToEnd = endAt.clone().diff(nowMoment.clone(), 'seconds');
    return durationToEnd;
  } catch (error) {}
  return null;
}
export function getDurationToUnpublishOn(
  this: Content & { unpublished_time: string },
  now: Moment,
) {
  const self: Content & { unpublished_time: string } = this;

  try {
    const { duration = 0, unpublished_time, start_on } = self;
    if (!start_on) {
      return null;
    }
    const nowMoment = now ? now.clone() : moment();

    const unpublishAt = getUnpublishOn.call(self);
    if (unpublishAt === null) {
      return null;
    }
    const durationToUnpublish = unpublishAt.clone().diff(nowMoment.clone(), 'seconds');

    return durationToUnpublish;
  } catch (error) {}
  return null;
}

export function getUnpublishOn(this: Content & { unpublished_time: string }) {
  const self: Content & { unpublished_time: string } = this;

  try {
    const { duration = 0, unpublished_time, start_on } = self;
    if (!start_on || !unpublished_time) {
      return null;
    }
    const unpublishAt = moment(unpublished_time);
    return unpublishAt;
  } catch (error) {}
  return null;
}

export function ignoreResumeType() {
  const ignoreTypes = [];
  try {
  } catch (error) {}
  return false;
}

export function checkDVRContent(this: Content) {
  const self: Content = this;
  try {
    const licenPeriod = _.isEmpty(self.license_period) ? null : self.license_period;
    return licenPeriod ? isDVR.call(licenPeriod) : false;
  } catch (error) {}
  return true;
}

export function getDefaultTypeImage() {
  return SUPPORT_FORMAT_WEBP ? 'webp' : 'jpeg';
}

export function addParamsLinkImage(linkImage: string, params = {}) {
  const defaultParams = { type: getDefaultTypeImage() };
  try {
    const url = new URL(linkImage);
    const oldSearchParams = queryString.parse(url.search);
    const newSearchParams = { ...oldSearchParams, ...defaultParams };
    url.search = queryString.stringify(newSearchParams);
    return url.href;
  } catch (error) {}
  return linkImage;
}

export function getDrmLinkPlay(this: Content) {
  const self: Content = this;

  try {
    const { link_play, play_info = {}, drm_session_info } = self;
    const { dash_link_play, hls_link_play } = play_info.data || {};
    const { IS_ANY_SAFARI, IS_IOS, IS_IPAD } = videojs.browser;
    if (_.isEmpty(drm_session_info)) {
      return link_play;
    }

    const hlsSupport = [IS_ANY_SAFARI, IS_IOS, IS_IPAD];

    if (hlsSupport.includes(true)) {
      return hls_link_play;
    }
    return dash_link_play;
  } catch (error) {}
  return '';
}

export function getLandingLink(this: Content) {
  const self: Content = this;

  const { slug = '' } = self;
  const lng = getConfigByKey('currentLanguage');
  if (_.isEmpty(slug)) {
    return '#';
  }
  return `/${lng}/landing/${slug}`;
}

export function getDrmSources(this: Content) {
  const self: Content = this;

  try {
    const { link_play, play_info = {}, drm_session_info } = self;
    const { dash_link_play = '', hls_link_play = '' } = play_info.data || {};
    if (_.isEmpty(drm_session_info)) {
      return link_play;
    }
    return [
      { src: dash_link_play, type: mime.lookup(dash_link_play) },
      { src: hls_link_play, type: mime.lookup(hls_link_play) },
    ];
  } catch (error) {}
  return [];
}

export function isDrmContent(this: Content) {
  const self: Content = this;

  try {
    return !_.isEmpty(self.drm_session_info);
  } catch (error) {
    return false;
  }
}

export function getIdsParentAndTrailers(this: Content) {
  const self: Content = this;

  try {
    const trailers = getTrailers.call(self);
    const { id } = self;
    return [id, ...trailers.map(trailer => trailer.id)];
  } catch (error) {
    return [];
  }
}

export function getIdsTrailers(this: Content) {
  const self: Content = this;

  try {
    const trailers = getTrailers.call(self);
    return trailers.map(trailer => trailer.id);
  } catch (error) {
    return [];
  }
}

export function getViewContentPlaying(this: Content, contentViewById: any) {
  const self: Content = this || {};

  try {
    const trailers = getTrailers.call(self);

    const viewRecord = contentViewById[self.id || ''] || {};
    let result: null | boolean = null;
    trailers.map(trailer => {
      const viewTrailerContent = contentViewById[trailer.id || ''] || {};
      if (!result && getDrmLinkPlay.call(viewTrailerContent)) {
        result = viewTrailerContent;
      }
    });

    // if (!result && viewRecord.link_play) {
    //   result = viewRecord;
    // }
    return result;
  } catch (error) {
    return null;
  }
}

export function getPriceById(this: Content, id: string) {
  const self: Content = this || {};

  try {
    const { prices = [] } = self;
    let price = null;
    prices.map(priceItem => {
      if (priceItem.id === id) {
        price = priceItem;
      }
    });
    return price;
  } catch (error) {
    return null;
  }
}

export function isNewPPV__NoCache(this: Content) {
  const self: Content = this || {};

  try {
    return !_.isEmpty(self);
  } catch (error) {
    return false;
  }
}

export function getDescriptionPricePackage(this: any) {
  if (_.isEmpty(this)) {
    return '';
  }
  try {
    const { description = '' } = this;
    if (_.isEmpty(description)) return '';
    const newDescriptions = description.split(/\r?\n/);
    return newDescriptions;
  } catch (err) {
    return '';
  }
}
