import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import queryString from 'query-string';
import { ReactSelectElement } from '../styled/select';
import { SelectFilterStyle } from '../styled/selectFilter';
import { isTablet } from 'react-device-detect';
import { components } from 'react-select';
import { updateHistoryFilter } from './../../../features/youtubePage/services';
import FilterHistory from './../../../features/youtubePage/component/filterHistory';
import classnames from 'classnames';
import { useDetectOrientation } from '~root/utils/utils';

function SelectFilter(props) {
  const { t, filter, filterHistory, filterTenant, activeIDs, isMobile, youtube, closeFilter } =
    props;
  const [genres, setGenres] = useState([]);
  const [languages, setLanguage] = useState([]);
  const [regions, setRegions] = useState([]);
  const [isOpenFilter, setOpenFilter] = useState({
    genres: (!isMobile && true) || false,
    languages: (!isMobile && true) || false,
    regions: (!isMobile && true) || false,
  });
  let regionsRef = useRef(null);
  let genresRef = useRef(null);
  let languageRef = useRef(null);
  const [landscape] = useDetectOrientation();
  const [contentProviders, setContentProviders] = useState([]);
  const [peoples, setPeoples] = useState([]);
  const [historyArray, setHistoryArray] = useState(filterHistory || []);
  let paramQuery = queryString.parse(props.location.search, { decode: false });
  useEffect(() => {
    let all = [
      {
        value: t('filter.all'),
        slug: 'all',
        name: t('filter.all'),
      },
    ];
    if (Array.isArray(filter.filterData)) {
      return;
    }
    setGenres([...all, ...filter.filterData.genres]);
    setLanguage([...all, ...filter.filterData.languages]);
    setRegions([...all, ...filter.filterData.regions]);
    if (filter.filterData.content_providers)
      setContentProviders([...all, ...filter.filterData.content_providers]);
    if (filter.filterData.peoples) setPeoples([...all, ...filter.filterData.peoples]);
  }, [filter]);

  function handleChange(value, params) {
    let select = {
      key: value,
      slug: params.slug,
      name: params.name,
    };
    if (historyArray.length > 20) {
      historyArray.splice(20, historyArray.length - 20);
    }
    if (select.slug !== 'all' && (!filterTenant || youtube)) {
      props.dpUpdateHistoryFilter(unique([select, ...historyArray]));
      setHistoryArray(unique([select, ...historyArray]));
    }
    if (isMobile || (!landscape && isTablet)) return props.handleToggleFilter(null, select, true);
    props.handleToggleFilter(null, select);
  }

  function unique(arr) {
    const unique = arr
      .map(e => e['slug'])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e])
      .map(e => arr[e]);
    return unique;
  }

  const handleOpen = (key, ref) => {
    if (!isMobile) return;
    let tmpOpen = isOpenFilter[key];
    setOpenFilter({
      ...isOpenFilter,
      [key]: !tmpOpen,
    });
    if (ref) {
      // return !tmpOpen ? ref.current.focus() : null
      return !tmpOpen ? ref.current.focus() : ref.current.blur();
    }
  };

  const handleRemove = () => {
    if (filterTenant && props.handleRemoveall) {
      return props.handleRemoveall();
    }
    props.handleRemoveAll();
  };

  const handleFiter = () => {
    closeFilter();
    props.handleToggleFilter(null, null);
  };

  const activeSelect = (option, key) => {
    if (
      option.slug === paramQuery[key] ||
      (activeIDs && activeIDs[key] && activeIDs[key][option.slug])
    ) {
      return option.slug;
    }
    return null;
  };

  const CaretDownIcon = () => {
    return <i className="fa fa-caret-down" aria-hidden="true" />;
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  return (
    <>
      <SelectFilterStyle
        className={classnames(
          'v-select-filter',
          filterTenant && 'v-select-filter-tenant',
          youtube && 'v-select-filter-youtube',
        )}
      >
        <div className="v-select-filter__col" style={{ display: isOpenFilter.regions && 'block' }}>
          <ReactSelectElement
            classNamePrefix={'Select'}
            className={`v-select-filter-regions`}
            ref={regionsRef}
            onChange={data => handleChange('region_slug', data)}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.slug}
            options={regions}
            components={{ DropdownIndicator }}
            // onMenuOpen={() => handleOpen('regions', regionsRef)}
            // onMenuClose={() => handleOpen('regions', regionsRef)}
            styles={
              isMobile && {
                menu: base => ({ ...base, position: 'relative' }),
              }
            }
            placeholder={t('filter.regions')}
            // menuIsOpen={isOpenFilter.regions && isMobile}
            value={regions.filter(option => activeSelect(option, 'region_slug'))}
          />
        </div>
        <div className="v-select-filter__col" style={{ display: isOpenFilter.genres && 'block' }}>
          <ReactSelectElement
            classNamePrefix={'Select'}
            className={`v-select-filter-genres`}
            ref={genresRef}
            onChange={data => handleChange('genre_slug', data)}
            // onMenuOpen={() => handleOpen('genres', genresRef)}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.slug}
            components={{ DropdownIndicator }}
            styles={
              isMobile && {
                menu: base => ({ ...base, position: 'relative' }),
              }
            }
            options={genres}
            // menuIsOpen={isOpenFilter.genres && isMobile}
            placeholder={t('filter.genres')}
            value={genres.filter(option => activeSelect(option, 'genre_slug'))}
          />
        </div>
        <div
          className={classnames('v-select-filter__col')}
          style={{ display: isOpenFilter.languages && 'block' }}
        >
          <ReactSelectElement
            classNamePrefix={'Select'}
            className={`v-select-filter-languages`}
            ref={languageRef}
            onChange={data => handleChange('language_slug', data)}
            getOptionLabel={option => t(option.name)}
            // onMenuOpen={() => handleOpen('languages', languageRef)}
            getOptionValue={option => option.slug}
            components={{ DropdownIndicator }}
            styles={
              isMobile && {
                menu: base => ({ ...base, position: 'relative' }),
              }
            }
            options={languages}
            placeholder={t('filter.languages')}
            // menuIsOpen={isOpenFilter.languages && isMobile}
            value={languages.filter(option => activeSelect(option, 'language_slug'))}
          />
        </div>
        <div
          className={classnames(
            isMobile && 'v-select-filter__col--actions',
            'v-select-filter__col ',
          )}
        >
          <Button
            onClick={() => handleFiter()}
            className={classnames(
              !isMobile && 'hide',
              'v-select-filter__col--btn v-select-filter__col--btn-apply',
            )}
          >
            {t('filter.apply')}
          </Button>
          <Button className="v-select-filter__col--btn" onClick={() => handleRemove()}>
            <i className="fa fa-trash" /> {t('filter.removeFilter')}
          </Button>
        </div>
      </SelectFilterStyle>
      {youtube && !isMobile ? (
        <React.Fragment>
          <FilterHistory selectFilter={select => props.handleToggleFilter(null, select)} />
        </React.Fragment>
      ) : null}
    </>
  );
}

const mapStateToProps = state => ({
  filter: state.filter,
  filterHistory: state.youtubePage.filterHistory,
  page: state.home.page,
});

const mapDispatchToProps = {
  dpUpdateHistoryFilter: data => updateHistoryFilter(data),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SelectFilter)),
);
