import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import * as APIString from '~constants/apiString';
import loginFormId from '~constants/ids/loginFormId';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { getConfigByKey } from '~features/tenantConfig/services';
import { ReactComponent as IconsFaceBook } from '../../../../assets/icons/FacebookW.svg';
import { GroupSocial, ButtonW } from './loginButtonStyled';

const LoginFaceBook = props => {
  const { t } = useTranslation();
  if (!getConfigByKey('features.loginfb')) return null;
  return (
    <FacebookLogin
      appId={APIString.FACEBOOK_APP_ID}
      callback={response => props._responseFacebook(response)}
      render={renderProps => (
        <GroupSocial
          data-testid={loginFormId.continueWithFacebookBtn}
          onClick={() => props._loginFb(renderProps)}
        >
          <IconsFaceBook />

          <ButtonW
            data-testid={loginFormId.continueWithFacebookBtn}
            onClick={() => props._loginFb(renderProps)}
            bsSize="large"
          >
            {t('loginScreen.buttonTextSignInFaceBookW')}
          </ButtonW>
        </GroupSocial>
      )}
    />
  );
};

const mapDispatchToProps = {};

const mapStateToProps = state => ({
  account: state.auth.account,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginFaceBook);
