/* tslint:disable */
/* eslint-disable */
/**
 * Vimai Core Service UI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminContentListingApi = exports.AdminContentListingApiFactory = exports.AdminContentListingApiFp = exports.AdminContentListingApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * AdminContentListingApi - axios parameter creator
 * @export
 */
var AdminContentListingApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create one broadcast schedule for curated live channel
         * @summary Create one broadcast schedule for curated live channel
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {BroadcastSchedule} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBroadcastSchedule: function (channelId, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('createBroadcastSchedule', 'channelId', channelId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('createBroadcastSchedule', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('createBroadcastSchedule', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/channels/{channel_id}/broadcast_schedule/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create one broadcast schedule for curated live channel
         * @summary Create one broadcast schedule for curated live channel
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {BroadcastScheduleSerializerV2} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChannelBroadcastSchedule: function (channelId, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('createChannelBroadcastSchedule', 'channelId', channelId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('createChannelBroadcastSchedule', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('createChannelBroadcastSchedule', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/channels/{channel_id}/broadcast_schedule_v2/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Create one ribbon
         * @param {string} tenantId Tenant slug
         * @param {RibbonResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRibbon: function (tenantId, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('createRibbon', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('createRibbon', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/ribbon_resources/"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create a tenant page
         * @summary Create a tenant page
         * @param {string} tenantId Tenant slug
         * @param {TenantPageResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenantPage: function (tenantId, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('createTenantPage', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('createTenantPage', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/tenant_pages/"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete one broadcast schedule
         * @summary Delete one broadcast schedule
         * @param {string} channelId
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBroadcastSchedule: function (channelId, id, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('deleteBroadcastSchedule', 'channelId', channelId);
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('deleteBroadcastSchedule', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('deleteBroadcastSchedule', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/channels/{channel_id}/broadcast_schedule/{id}/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete one broadcast schedule
         * @summary Delete one broadcast schedule
         * @param {string} channelId
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChannelBroadcastSchedule: function (channelId, id, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('deleteChannelBroadcastSchedule', 'channelId', channelId);
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('deleteChannelBroadcastSchedule', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('deleteChannelBroadcastSchedule', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/channels/{channel_id}/broadcast_schedule_v2/{id}/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete a epg feed
         * @param {string} channelId
         * @param {string} id A unique value identifying this epg feed.
         * @param {string} tenantSlug Tenant slug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChannelEgp: function (channelId, id, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('deleteChannelEgp', 'channelId', channelId);
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('deleteChannelEgp', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('deleteChannelEgp', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/channels/{channel_id}/epgs/{id}/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete one ribbon
         * @param {string} id
         * @param {string} tenantId Tenant slug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRibbon: function (id, tenantId, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('deleteRibbon', 'id', id);
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('deleteRibbon', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/ribbon_resources/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get a tenant page
         * @summary Get a tenant page
         * @param {string} id
         * @param {string} tenantId Tenant slug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenantPage: function (id, tenantId, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('deleteTenantPage', 'id', id);
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('deleteTenantPage', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/tenant_pages/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Export broadcast schedule epg list
         * @param {string} channelId
         * @param {string} scheduleId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportBroadcastScheduleEpgs: function (channelId, scheduleId, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('exportBroadcastScheduleEpgs', 'channelId', channelId);
                            // verify required parameter 'scheduleId' is not null or undefined
                            (0, common_1.assertParamExists)('exportBroadcastScheduleEpgs', 'scheduleId', scheduleId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('exportBroadcastScheduleEpgs', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/channels/{channel_id}/broadcast_schedule_v2/{schedule_id}/epgs/export/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("schedule_id", "}"), encodeURIComponent(String(scheduleId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Fill channel broadcast schedule
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {FillChannelScheduleRequest} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fillChannelBroadcastSchedule: function (channelId, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('fillChannelBroadcastSchedule', 'channelId', channelId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('fillChannelBroadcastSchedule', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('fillChannelBroadcastSchedule', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/channels/{channel_id}/broadcast_schedule/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get one broadcast schedule
         * @summary Get one broadcast schedule
         * @param {string} channelId
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBroadcastSchedule: function (channelId, id, tenantSlug, authorization, acceptLanguage, select, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('getBroadcastSchedule', 'channelId', channelId);
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getBroadcastSchedule', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getBroadcastSchedule', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/channels/{channel_id}/broadcast_schedule/{id}/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get broadcast schedule epg list
         * @param {string} channelId
         * @param {string} scheduleId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBroadcastScheduleEpgs: function (channelId, scheduleId, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('getBroadcastScheduleEpgs', 'channelId', channelId);
                            // verify required parameter 'scheduleId' is not null or undefined
                            (0, common_1.assertParamExists)('getBroadcastScheduleEpgs', 'scheduleId', scheduleId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getBroadcastScheduleEpgs', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/channels/{channel_id}/broadcast_schedule_v2/{schedule_id}/epgs/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("schedule_id", "}"), encodeURIComponent(String(scheduleId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get broadcast schedules of channel
         * @summary Get broadcast schedules of channel
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBroadcastSchedules: function (channelId, tenantSlug, search, ordering, page, pageSize, authorization, acceptLanguage, select, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('getBroadcastSchedules', 'channelId', channelId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getBroadcastSchedules', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/channels/{channel_id}/broadcast_schedule/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (search !== undefined) {
                                localVarQueryParameter['search'] = search;
                            }
                            if (ordering !== undefined) {
                                localVarQueryParameter['ordering'] = ordering;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get one broadcast schedule
         * @summary Get one broadcast schedule
         * @param {string} channelId
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelBroadcastSchedule: function (channelId, id, tenantSlug, authorization, acceptLanguage, select, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('getChannelBroadcastSchedule', 'channelId', channelId);
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getChannelBroadcastSchedule', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getChannelBroadcastSchedule', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/channels/{channel_id}/broadcast_schedule_v2/{id}/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get broadcast schedules of channel
         * @summary Get broadcast schedules of channel
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelBroadcastSchedules: function (channelId, tenantSlug, search, ordering, page, pageSize, authorization, acceptLanguage, select, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('getChannelBroadcastSchedules', 'channelId', channelId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getChannelBroadcastSchedules', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/channels/{channel_id}/broadcast_schedule_v2/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (search !== undefined) {
                                localVarQueryParameter['search'] = search;
                            }
                            if (ordering !== undefined) {
                                localVarQueryParameter['ordering'] = ordering;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get one epg feed
         * @param {string} channelId
         * @param {string} id A unique value identifying this epg feed.
         * @param {string} tenantSlug Tenant slug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelEpg: function (channelId, id, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('getChannelEpg', 'channelId', channelId);
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getChannelEpg', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getChannelEpg', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/channels/{channel_id}/epgs/{id}/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary List all epg feeds
         * @param {string} channelId
         * @param {string} tenantSlug Tenant slug
         * @param {number} [startAtGte]
         * @param {number} [endAtLte]
         * @param {number} [startAt]
         * @param {number} [endAt]
         * @param {string} [search] A search term.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelEpgs: function (channelId, tenantSlug, startAtGte, endAtLte, startAt, endAt, search, ordering, page, pageSize, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('getChannelEpgs', 'channelId', channelId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getChannelEpgs', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/channels/{channel_id}/epgs/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (startAtGte !== undefined) {
                                localVarQueryParameter['start_at__gte'] = startAtGte;
                            }
                            if (endAtLte !== undefined) {
                                localVarQueryParameter['end_at__lte'] = endAtLte;
                            }
                            if (startAt !== undefined) {
                                localVarQueryParameter['start_at'] = startAt;
                            }
                            if (endAt !== undefined) {
                                localVarQueryParameter['end_at'] = endAt;
                            }
                            if (search !== undefined) {
                                localVarQueryParameter['search'] = search;
                            }
                            if (ordering !== undefined) {
                                localVarQueryParameter['ordering'] = ordering;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get curated channel EPGs
         * @summary Get curated channel EPGs
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [scheduleDate] Specify will ignore start_time and end_time ( format - yyyy-mm-dd )
         * @param {string} [startTime] Filter programs start after this (default to now)
         * @param {string} [endTime] Filter programs end before this (default to now + 3hrs)
         * @param {string} [timezone] timezone of your current location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedChannelEpgs: function (channelId, tenantSlug, acceptLanguage, scheduleDate, startTime, endTime, timezone, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('getCuratedChannelEpgs', 'channelId', channelId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getCuratedChannelEpgs', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/curated_channels/{channel_id}/epgs/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (scheduleDate !== undefined) {
                                localVarQueryParameter['schedule_date'] = scheduleDate;
                            }
                            if (startTime !== undefined) {
                                localVarQueryParameter['start_time'] = startTime;
                            }
                            if (endTime !== undefined) {
                                localVarQueryParameter['end_time'] = endTime;
                            }
                            if (timezone !== undefined) {
                                localVarQueryParameter['timezone'] = timezone;
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary List all ribbon
         * @param {string} tenantId Tenant slug
         * @param {string} [id]
         * @param {string} [tenantPlatform]
         * @param {string} [tenantPage]
         * @param {string} [contentId]
         * @param {number} [type]
         * @param {string} [isReplicable]
         * @param {string} [search] A search term.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListRibbon: function (tenantId, id, tenantPlatform, tenantPage, contentId, type, isReplicable, search, ordering, page, pageSize, authorization, acceptLanguage, select, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('getListRibbon', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/ribbon_resources/"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            if (tenantPlatform !== undefined) {
                                localVarQueryParameter['tenant_platform'] = tenantPlatform;
                            }
                            if (tenantPage !== undefined) {
                                localVarQueryParameter['tenant_page'] = tenantPage;
                            }
                            if (contentId !== undefined) {
                                localVarQueryParameter['content_id'] = contentId;
                            }
                            if (type !== undefined) {
                                localVarQueryParameter['type'] = type;
                            }
                            if (isReplicable !== undefined) {
                                localVarQueryParameter['is_replicable'] = isReplicable;
                            }
                            if (search !== undefined) {
                                localVarQueryParameter['search'] = search;
                            }
                            if (ordering !== undefined) {
                                localVarQueryParameter['ordering'] = ordering;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get live channel EPGs
         * @summary Get live channel EPGs
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [scheduleDate] Specify will ignore start_time and end_time ( format - yyyy-mm-dd )
         * @param {string} [startTime] Filter programs start after this (default to now)
         * @param {string} [endTime] Filter programs end before this (default to now + 3hrs)
         * @param {string} [timezone] timezone of your current location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveChannelEpgs: function (channelId, tenantSlug, acceptLanguage, scheduleDate, startTime, endTime, timezone, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('getLiveChannelEpgs', 'channelId', channelId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getLiveChannelEpgs', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/live_channels/{channel_id}/epgs/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (scheduleDate !== undefined) {
                                localVarQueryParameter['schedule_date'] = scheduleDate;
                            }
                            if (startTime !== undefined) {
                                localVarQueryParameter['start_time'] = startTime;
                            }
                            if (endTime !== undefined) {
                                localVarQueryParameter['end_time'] = endTime;
                            }
                            if (timezone !== undefined) {
                                localVarQueryParameter['timezone'] = timezone;
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get one ribbon
         * @param {string} id
         * @param {string} tenantId Tenant slug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRibbon: function (id, tenantId, authorization, acceptLanguage, select, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getRibbon', 'id', id);
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('getRibbon', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/ribbon_resources/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get a tenant page
         * @summary Get a tenant page
         * @param {string} id
         * @param {string} tenantId Tenant slug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantPage: function (id, tenantId, authorization, acceptLanguage, select, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getTenantPage', 'id', id);
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('getTenantPage', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/tenant_pages/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List tenant pages
         * @summary List tenant pages
         * @param {string} tenantId Tenant slug
         * @param {string} [tenantPlatform]
         * @param {string} [displayStyle]
         * @param {string} [search] A search term.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantPages: function (tenantId, tenantPlatform, displayStyle, search, ordering, page, pageSize, authorization, acceptLanguage, select, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('getTenantPages', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/tenant_pages/"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (tenantPlatform !== undefined) {
                                localVarQueryParameter['tenant_platform'] = tenantPlatform;
                            }
                            if (displayStyle !== undefined) {
                                localVarQueryParameter['display_style'] = displayStyle;
                            }
                            if (search !== undefined) {
                                localVarQueryParameter['search'] = search;
                            }
                            if (ordering !== undefined) {
                                localVarQueryParameter['ordering'] = ordering;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Import epg
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importLiveChannelEpg: function (channelId, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('importLiveChannelEpg', 'channelId', channelId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('importLiveChannelEpg', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/channels/{channel_id}/epgs/import/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Import epg
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importLiveChannelEpg_1: function (channelId, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('importLiveChannelEpg_1', 'channelId', channelId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('importLiveChannelEpg_1', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/epg/import/{channel_id}/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update curated epg list of live channel
         * @summary Update curated epg list of live channel
         * @param {string} channelId
         * @param {string} id
         * @param {string} tenantSlug
         * @param {BroadcastSchedule} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBroadcastSchedule: function (channelId, id, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('updateBroadcastSchedule', 'channelId', channelId);
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('updateBroadcastSchedule', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('updateBroadcastSchedule', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateBroadcastSchedule', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/channels/{channel_id}/broadcast_schedule/{id}/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update broadcast schedule epg list
         * @param {string} channelId
         * @param {string} scheduleId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBroadcastScheduleEpgs: function (channelId, scheduleId, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('updateBroadcastScheduleEpgs', 'channelId', channelId);
                            // verify required parameter 'scheduleId' is not null or undefined
                            (0, common_1.assertParamExists)('updateBroadcastScheduleEpgs', 'scheduleId', scheduleId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('updateBroadcastScheduleEpgs', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/channels/{channel_id}/broadcast_schedule_v2/{schedule_id}/epgs/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("schedule_id", "}"), encodeURIComponent(String(scheduleId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update curated epg list of live channel
         * @summary Update curated epg list of live channel
         * @param {string} channelId
         * @param {string} id
         * @param {string} tenantSlug
         * @param {BroadcastScheduleSerializerV2} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChannelBroadcastSchedule: function (channelId, id, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('updateChannelBroadcastSchedule', 'channelId', channelId);
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('updateChannelBroadcastSchedule', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('updateChannelBroadcastSchedule', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateChannelBroadcastSchedule', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/channels/{channel_id}/broadcast_schedule_v2/{id}/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update a epg feed
         * @param {string} channelId
         * @param {string} id A unique value identifying this epg feed.
         * @param {string} tenantSlug Tenant slug
         * @param {EPGFeed} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChannelEpg: function (channelId, id, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('updateChannelEpg', 'channelId', channelId);
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('updateChannelEpg', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('updateChannelEpg', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateChannelEpg', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/channels/{channel_id}/epgs/{id}/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update season resource
         * @summary Update season resource
         * @param {string} parentId
         * @param {string} tenantId
         * @param {UpdateParentContentResourceRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {boolean} [autoSort] check auto sort data draft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChildrenContent: function (parentId, tenantId, data, authorization, acceptLanguage, autoSort, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'parentId' is not null or undefined
                            (0, common_1.assertParamExists)('updateChildrenContent', 'parentId', parentId);
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('updateChildrenContent', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateChildrenContent', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/parent_contents/{parent_id}/"
                                .replace("{".concat("parent_id", "}"), encodeURIComponent(String(parentId)))
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (autoSort !== undefined) {
                                localVarQueryParameter['auto_sort'] = autoSort;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update a curated epg feed for channel
         * @param {string} channelId
         * @param {string} tenantSlug Tenant slug
         * @param {UpdateCuratedEPGListRequest} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateCuratedChannelEpgs: function (channelId, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('updateCuratedChannelEpgs', 'channelId', channelId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('updateCuratedChannelEpgs', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateCuratedChannelEpgs', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/channels/{channel_id}/epgs/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update a ribbon
         * @param {string} id
         * @param {string} tenantId Tenant slug
         * @param {RibbonResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRibbon: function (id, tenantId, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('updateRibbon', 'id', id);
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('updateRibbon', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateRibbon', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/ribbon_resources/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update contents in ribbon
         * @summary Update contents in ribbon
         * @param {string} ribbonId
         * @param {string} tenantId
         * @param {UpdateContentsInRibbonRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRibbonContents: function (ribbonId, tenantId, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'ribbonId' is not null or undefined
                            (0, common_1.assertParamExists)('updateRibbonContents', 'ribbonId', ribbonId);
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('updateRibbonContents', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateRibbonContents', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/ribbon_contents/{ribbon_id}/"
                                .replace("{".concat("ribbon_id", "}"), encodeURIComponent(String(ribbonId)))
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Tenant page ribbon resource
         * @param {string} pageId
         * @param {string} tenantId
         * @param {UpdatePageRibbonResourceRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRibbonInPage: function (pageId, tenantId, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'pageId' is not null or undefined
                            (0, common_1.assertParamExists)('updateRibbonInPage', 'pageId', pageId);
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('updateRibbonInPage', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateRibbonInPage', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/page_ribbons/{page_id}/"
                                .replace("{".concat("page_id", "}"), encodeURIComponent(String(pageId)))
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update a tenant page
         * @summary Update a tenant page
         * @param {string} id
         * @param {string} tenantId Tenant slug
         * @param {TenantPageResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenantPage: function (id, tenantId, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('updateTenantPage', 'id', id);
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('updateTenantPage', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateTenantPage', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/tenant_pages/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update trailers for content
         * @summary Update trailers for content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {ContentTrailersRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrailersOfContent: function (contentId, tenantSlug, data, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('updateTrailersOfContent', 'contentId', contentId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('updateTrailersOfContent', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateTrailersOfContent', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/contents/{content_id}/trailers/"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.AdminContentListingApiAxiosParamCreator = AdminContentListingApiAxiosParamCreator;
/**
 * AdminContentListingApi - functional programming interface
 * @export
 */
var AdminContentListingApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.AdminContentListingApiAxiosParamCreator)(configuration);
    return {
        /**
         * Create one broadcast schedule for curated live channel
         * @summary Create one broadcast schedule for curated live channel
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {BroadcastSchedule} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBroadcastSchedule: function (channelId, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createBroadcastSchedule(channelId, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Create one broadcast schedule for curated live channel
         * @summary Create one broadcast schedule for curated live channel
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {BroadcastScheduleSerializerV2} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChannelBroadcastSchedule: function (channelId, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createChannelBroadcastSchedule(channelId, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Create one ribbon
         * @param {string} tenantId Tenant slug
         * @param {RibbonResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRibbon: function (tenantId, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createRibbon(tenantId, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Create a tenant page
         * @summary Create a tenant page
         * @param {string} tenantId Tenant slug
         * @param {TenantPageResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenantPage: function (tenantId, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createTenantPage(tenantId, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Delete one broadcast schedule
         * @summary Delete one broadcast schedule
         * @param {string} channelId
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBroadcastSchedule: function (channelId, id, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteBroadcastSchedule(channelId, id, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Delete one broadcast schedule
         * @summary Delete one broadcast schedule
         * @param {string} channelId
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChannelBroadcastSchedule: function (channelId, id, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteChannelBroadcastSchedule(channelId, id, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete a epg feed
         * @param {string} channelId
         * @param {string} id A unique value identifying this epg feed.
         * @param {string} tenantSlug Tenant slug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChannelEgp: function (channelId, id, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteChannelEgp(channelId, id, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete one ribbon
         * @param {string} id
         * @param {string} tenantId Tenant slug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRibbon: function (id, tenantId, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteRibbon(id, tenantId, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get a tenant page
         * @summary Get a tenant page
         * @param {string} id
         * @param {string} tenantId Tenant slug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenantPage: function (id, tenantId, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteTenantPage(id, tenantId, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Export broadcast schedule epg list
         * @param {string} channelId
         * @param {string} scheduleId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportBroadcastScheduleEpgs: function (channelId, scheduleId, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.exportBroadcastScheduleEpgs(channelId, scheduleId, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Fill channel broadcast schedule
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {FillChannelScheduleRequest} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fillChannelBroadcastSchedule: function (channelId, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.fillChannelBroadcastSchedule(channelId, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get one broadcast schedule
         * @summary Get one broadcast schedule
         * @param {string} channelId
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBroadcastSchedule: function (channelId, id, tenantSlug, authorization, acceptLanguage, select, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getBroadcastSchedule(channelId, id, tenantSlug, authorization, acceptLanguage, select, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get broadcast schedule epg list
         * @param {string} channelId
         * @param {string} scheduleId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBroadcastScheduleEpgs: function (channelId, scheduleId, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getBroadcastScheduleEpgs(channelId, scheduleId, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get broadcast schedules of channel
         * @summary Get broadcast schedules of channel
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBroadcastSchedules: function (channelId, tenantSlug, search, ordering, page, pageSize, authorization, acceptLanguage, select, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getBroadcastSchedules(channelId, tenantSlug, search, ordering, page, pageSize, authorization, acceptLanguage, select, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get one broadcast schedule
         * @summary Get one broadcast schedule
         * @param {string} channelId
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelBroadcastSchedule: function (channelId, id, tenantSlug, authorization, acceptLanguage, select, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getChannelBroadcastSchedule(channelId, id, tenantSlug, authorization, acceptLanguage, select, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get broadcast schedules of channel
         * @summary Get broadcast schedules of channel
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelBroadcastSchedules: function (channelId, tenantSlug, search, ordering, page, pageSize, authorization, acceptLanguage, select, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getChannelBroadcastSchedules(channelId, tenantSlug, search, ordering, page, pageSize, authorization, acceptLanguage, select, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get one epg feed
         * @param {string} channelId
         * @param {string} id A unique value identifying this epg feed.
         * @param {string} tenantSlug Tenant slug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelEpg: function (channelId, id, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getChannelEpg(channelId, id, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary List all epg feeds
         * @param {string} channelId
         * @param {string} tenantSlug Tenant slug
         * @param {number} [startAtGte]
         * @param {number} [endAtLte]
         * @param {number} [startAt]
         * @param {number} [endAt]
         * @param {string} [search] A search term.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelEpgs: function (channelId, tenantSlug, startAtGte, endAtLte, startAt, endAt, search, ordering, page, pageSize, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getChannelEpgs(channelId, tenantSlug, startAtGte, endAtLte, startAt, endAt, search, ordering, page, pageSize, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get curated channel EPGs
         * @summary Get curated channel EPGs
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [scheduleDate] Specify will ignore start_time and end_time ( format - yyyy-mm-dd )
         * @param {string} [startTime] Filter programs start after this (default to now)
         * @param {string} [endTime] Filter programs end before this (default to now + 3hrs)
         * @param {string} [timezone] timezone of your current location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedChannelEpgs: function (channelId, tenantSlug, acceptLanguage, scheduleDate, startTime, endTime, timezone, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getCuratedChannelEpgs(channelId, tenantSlug, acceptLanguage, scheduleDate, startTime, endTime, timezone, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary List all ribbon
         * @param {string} tenantId Tenant slug
         * @param {string} [id]
         * @param {string} [tenantPlatform]
         * @param {string} [tenantPage]
         * @param {string} [contentId]
         * @param {number} [type]
         * @param {string} [isReplicable]
         * @param {string} [search] A search term.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListRibbon: function (tenantId, id, tenantPlatform, tenantPage, contentId, type, isReplicable, search, ordering, page, pageSize, authorization, acceptLanguage, select, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getListRibbon(tenantId, id, tenantPlatform, tenantPage, contentId, type, isReplicable, search, ordering, page, pageSize, authorization, acceptLanguage, select, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get live channel EPGs
         * @summary Get live channel EPGs
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [scheduleDate] Specify will ignore start_time and end_time ( format - yyyy-mm-dd )
         * @param {string} [startTime] Filter programs start after this (default to now)
         * @param {string} [endTime] Filter programs end before this (default to now + 3hrs)
         * @param {string} [timezone] timezone of your current location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveChannelEpgs: function (channelId, tenantSlug, acceptLanguage, scheduleDate, startTime, endTime, timezone, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getLiveChannelEpgs(channelId, tenantSlug, acceptLanguage, scheduleDate, startTime, endTime, timezone, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get one ribbon
         * @param {string} id
         * @param {string} tenantId Tenant slug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRibbon: function (id, tenantId, authorization, acceptLanguage, select, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getRibbon(id, tenantId, authorization, acceptLanguage, select, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get a tenant page
         * @summary Get a tenant page
         * @param {string} id
         * @param {string} tenantId Tenant slug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantPage: function (id, tenantId, authorization, acceptLanguage, select, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTenantPage(id, tenantId, authorization, acceptLanguage, select, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * List tenant pages
         * @summary List tenant pages
         * @param {string} tenantId Tenant slug
         * @param {string} [tenantPlatform]
         * @param {string} [displayStyle]
         * @param {string} [search] A search term.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantPages: function (tenantId, tenantPlatform, displayStyle, search, ordering, page, pageSize, authorization, acceptLanguage, select, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTenantPages(tenantId, tenantPlatform, displayStyle, search, ordering, page, pageSize, authorization, acceptLanguage, select, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Import epg
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importLiveChannelEpg: function (channelId, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.importLiveChannelEpg(channelId, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Import epg
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importLiveChannelEpg_1: function (channelId, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.importLiveChannelEpg_1(channelId, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update curated epg list of live channel
         * @summary Update curated epg list of live channel
         * @param {string} channelId
         * @param {string} id
         * @param {string} tenantSlug
         * @param {BroadcastSchedule} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBroadcastSchedule: function (channelId, id, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateBroadcastSchedule(channelId, id, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update broadcast schedule epg list
         * @param {string} channelId
         * @param {string} scheduleId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBroadcastScheduleEpgs: function (channelId, scheduleId, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateBroadcastScheduleEpgs(channelId, scheduleId, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update curated epg list of live channel
         * @summary Update curated epg list of live channel
         * @param {string} channelId
         * @param {string} id
         * @param {string} tenantSlug
         * @param {BroadcastScheduleSerializerV2} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChannelBroadcastSchedule: function (channelId, id, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateChannelBroadcastSchedule(channelId, id, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update a epg feed
         * @param {string} channelId
         * @param {string} id A unique value identifying this epg feed.
         * @param {string} tenantSlug Tenant slug
         * @param {EPGFeed} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChannelEpg: function (channelId, id, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateChannelEpg(channelId, id, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update season resource
         * @summary Update season resource
         * @param {string} parentId
         * @param {string} tenantId
         * @param {UpdateParentContentResourceRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {boolean} [autoSort] check auto sort data draft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChildrenContent: function (parentId, tenantId, data, authorization, acceptLanguage, autoSort, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateChildrenContent(parentId, tenantId, data, authorization, acceptLanguage, autoSort, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update a curated epg feed for channel
         * @param {string} channelId
         * @param {string} tenantSlug Tenant slug
         * @param {UpdateCuratedEPGListRequest} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateCuratedChannelEpgs: function (channelId, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateCuratedChannelEpgs(channelId, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update a ribbon
         * @param {string} id
         * @param {string} tenantId Tenant slug
         * @param {RibbonResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRibbon: function (id, tenantId, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateRibbon(id, tenantId, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update contents in ribbon
         * @summary Update contents in ribbon
         * @param {string} ribbonId
         * @param {string} tenantId
         * @param {UpdateContentsInRibbonRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRibbonContents: function (ribbonId, tenantId, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateRibbonContents(ribbonId, tenantId, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Tenant page ribbon resource
         * @param {string} pageId
         * @param {string} tenantId
         * @param {UpdatePageRibbonResourceRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRibbonInPage: function (pageId, tenantId, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateRibbonInPage(pageId, tenantId, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update a tenant page
         * @summary Update a tenant page
         * @param {string} id
         * @param {string} tenantId Tenant slug
         * @param {TenantPageResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenantPage: function (id, tenantId, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateTenantPage(id, tenantId, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update trailers for content
         * @summary Update trailers for content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {ContentTrailersRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrailersOfContent: function (contentId, tenantSlug, data, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateTrailersOfContent(contentId, tenantSlug, data, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.AdminContentListingApiFp = AdminContentListingApiFp;
/**
 * AdminContentListingApi - factory interface
 * @export
 */
var AdminContentListingApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.AdminContentListingApiFp)(configuration);
    return {
        /**
         * Create one broadcast schedule for curated live channel
         * @summary Create one broadcast schedule for curated live channel
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {BroadcastSchedule} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBroadcastSchedule: function (channelId, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.createBroadcastSchedule(channelId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create one broadcast schedule for curated live channel
         * @summary Create one broadcast schedule for curated live channel
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {BroadcastScheduleSerializerV2} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChannelBroadcastSchedule: function (channelId, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.createChannelBroadcastSchedule(channelId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Create one ribbon
         * @param {string} tenantId Tenant slug
         * @param {RibbonResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRibbon: function (tenantId, data, authorization, acceptLanguage, options) {
            return localVarFp.createRibbon(tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create a tenant page
         * @summary Create a tenant page
         * @param {string} tenantId Tenant slug
         * @param {TenantPageResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenantPage: function (tenantId, data, authorization, acceptLanguage, options) {
            return localVarFp.createTenantPage(tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete one broadcast schedule
         * @summary Delete one broadcast schedule
         * @param {string} channelId
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBroadcastSchedule: function (channelId, id, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.deleteBroadcastSchedule(channelId, id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete one broadcast schedule
         * @summary Delete one broadcast schedule
         * @param {string} channelId
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChannelBroadcastSchedule: function (channelId, id, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.deleteChannelBroadcastSchedule(channelId, id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete a epg feed
         * @param {string} channelId
         * @param {string} id A unique value identifying this epg feed.
         * @param {string} tenantSlug Tenant slug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChannelEgp: function (channelId, id, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.deleteChannelEgp(channelId, id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete one ribbon
         * @param {string} id
         * @param {string} tenantId Tenant slug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRibbon: function (id, tenantId, authorization, acceptLanguage, options) {
            return localVarFp.deleteRibbon(id, tenantId, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get a tenant page
         * @summary Get a tenant page
         * @param {string} id
         * @param {string} tenantId Tenant slug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenantPage: function (id, tenantId, authorization, acceptLanguage, options) {
            return localVarFp.deleteTenantPage(id, tenantId, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Export broadcast schedule epg list
         * @param {string} channelId
         * @param {string} scheduleId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportBroadcastScheduleEpgs: function (channelId, scheduleId, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.exportBroadcastScheduleEpgs(channelId, scheduleId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Fill channel broadcast schedule
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {FillChannelScheduleRequest} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fillChannelBroadcastSchedule: function (channelId, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.fillChannelBroadcastSchedule(channelId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get one broadcast schedule
         * @summary Get one broadcast schedule
         * @param {string} channelId
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBroadcastSchedule: function (channelId, id, tenantSlug, authorization, acceptLanguage, select, options) {
            return localVarFp.getBroadcastSchedule(channelId, id, tenantSlug, authorization, acceptLanguage, select, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get broadcast schedule epg list
         * @param {string} channelId
         * @param {string} scheduleId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBroadcastScheduleEpgs: function (channelId, scheduleId, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.getBroadcastScheduleEpgs(channelId, scheduleId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get broadcast schedules of channel
         * @summary Get broadcast schedules of channel
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBroadcastSchedules: function (channelId, tenantSlug, search, ordering, page, pageSize, authorization, acceptLanguage, select, options) {
            return localVarFp.getBroadcastSchedules(channelId, tenantSlug, search, ordering, page, pageSize, authorization, acceptLanguage, select, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get one broadcast schedule
         * @summary Get one broadcast schedule
         * @param {string} channelId
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelBroadcastSchedule: function (channelId, id, tenantSlug, authorization, acceptLanguage, select, options) {
            return localVarFp.getChannelBroadcastSchedule(channelId, id, tenantSlug, authorization, acceptLanguage, select, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get broadcast schedules of channel
         * @summary Get broadcast schedules of channel
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelBroadcastSchedules: function (channelId, tenantSlug, search, ordering, page, pageSize, authorization, acceptLanguage, select, options) {
            return localVarFp.getChannelBroadcastSchedules(channelId, tenantSlug, search, ordering, page, pageSize, authorization, acceptLanguage, select, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get one epg feed
         * @param {string} channelId
         * @param {string} id A unique value identifying this epg feed.
         * @param {string} tenantSlug Tenant slug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelEpg: function (channelId, id, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.getChannelEpg(channelId, id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary List all epg feeds
         * @param {string} channelId
         * @param {string} tenantSlug Tenant slug
         * @param {number} [startAtGte]
         * @param {number} [endAtLte]
         * @param {number} [startAt]
         * @param {number} [endAt]
         * @param {string} [search] A search term.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelEpgs: function (channelId, tenantSlug, startAtGte, endAtLte, startAt, endAt, search, ordering, page, pageSize, authorization, acceptLanguage, options) {
            return localVarFp.getChannelEpgs(channelId, tenantSlug, startAtGte, endAtLte, startAt, endAt, search, ordering, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get curated channel EPGs
         * @summary Get curated channel EPGs
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [scheduleDate] Specify will ignore start_time and end_time ( format - yyyy-mm-dd )
         * @param {string} [startTime] Filter programs start after this (default to now)
         * @param {string} [endTime] Filter programs end before this (default to now + 3hrs)
         * @param {string} [timezone] timezone of your current location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCuratedChannelEpgs: function (channelId, tenantSlug, acceptLanguage, scheduleDate, startTime, endTime, timezone, options) {
            return localVarFp.getCuratedChannelEpgs(channelId, tenantSlug, acceptLanguage, scheduleDate, startTime, endTime, timezone, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary List all ribbon
         * @param {string} tenantId Tenant slug
         * @param {string} [id]
         * @param {string} [tenantPlatform]
         * @param {string} [tenantPage]
         * @param {string} [contentId]
         * @param {number} [type]
         * @param {string} [isReplicable]
         * @param {string} [search] A search term.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListRibbon: function (tenantId, id, tenantPlatform, tenantPage, contentId, type, isReplicable, search, ordering, page, pageSize, authorization, acceptLanguage, select, options) {
            return localVarFp.getListRibbon(tenantId, id, tenantPlatform, tenantPage, contentId, type, isReplicable, search, ordering, page, pageSize, authorization, acceptLanguage, select, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get live channel EPGs
         * @summary Get live channel EPGs
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [scheduleDate] Specify will ignore start_time and end_time ( format - yyyy-mm-dd )
         * @param {string} [startTime] Filter programs start after this (default to now)
         * @param {string} [endTime] Filter programs end before this (default to now + 3hrs)
         * @param {string} [timezone] timezone of your current location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveChannelEpgs: function (channelId, tenantSlug, acceptLanguage, scheduleDate, startTime, endTime, timezone, options) {
            return localVarFp.getLiveChannelEpgs(channelId, tenantSlug, acceptLanguage, scheduleDate, startTime, endTime, timezone, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get one ribbon
         * @param {string} id
         * @param {string} tenantId Tenant slug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRibbon: function (id, tenantId, authorization, acceptLanguage, select, options) {
            return localVarFp.getRibbon(id, tenantId, authorization, acceptLanguage, select, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get a tenant page
         * @summary Get a tenant page
         * @param {string} id
         * @param {string} tenantId Tenant slug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantPage: function (id, tenantId, authorization, acceptLanguage, select, options) {
            return localVarFp.getTenantPage(id, tenantId, authorization, acceptLanguage, select, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List tenant pages
         * @summary List tenant pages
         * @param {string} tenantId Tenant slug
         * @param {string} [tenantPlatform]
         * @param {string} [displayStyle]
         * @param {string} [search] A search term.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantPages: function (tenantId, tenantPlatform, displayStyle, search, ordering, page, pageSize, authorization, acceptLanguage, select, options) {
            return localVarFp.getTenantPages(tenantId, tenantPlatform, displayStyle, search, ordering, page, pageSize, authorization, acceptLanguage, select, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Import epg
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importLiveChannelEpg: function (channelId, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.importLiveChannelEpg(channelId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Import epg
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importLiveChannelEpg_1: function (channelId, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.importLiveChannelEpg_1(channelId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update curated epg list of live channel
         * @summary Update curated epg list of live channel
         * @param {string} channelId
         * @param {string} id
         * @param {string} tenantSlug
         * @param {BroadcastSchedule} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBroadcastSchedule: function (channelId, id, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.updateBroadcastSchedule(channelId, id, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update broadcast schedule epg list
         * @param {string} channelId
         * @param {string} scheduleId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBroadcastScheduleEpgs: function (channelId, scheduleId, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.updateBroadcastScheduleEpgs(channelId, scheduleId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update curated epg list of live channel
         * @summary Update curated epg list of live channel
         * @param {string} channelId
         * @param {string} id
         * @param {string} tenantSlug
         * @param {BroadcastScheduleSerializerV2} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChannelBroadcastSchedule: function (channelId, id, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.updateChannelBroadcastSchedule(channelId, id, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update a epg feed
         * @param {string} channelId
         * @param {string} id A unique value identifying this epg feed.
         * @param {string} tenantSlug Tenant slug
         * @param {EPGFeed} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChannelEpg: function (channelId, id, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.updateChannelEpg(channelId, id, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update season resource
         * @summary Update season resource
         * @param {string} parentId
         * @param {string} tenantId
         * @param {UpdateParentContentResourceRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {boolean} [autoSort] check auto sort data draft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChildrenContent: function (parentId, tenantId, data, authorization, acceptLanguage, autoSort, options) {
            return localVarFp.updateChildrenContent(parentId, tenantId, data, authorization, acceptLanguage, autoSort, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update a curated epg feed for channel
         * @param {string} channelId
         * @param {string} tenantSlug Tenant slug
         * @param {UpdateCuratedEPGListRequest} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateCuratedChannelEpgs: function (channelId, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.updateCuratedChannelEpgs(channelId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update a ribbon
         * @param {string} id
         * @param {string} tenantId Tenant slug
         * @param {RibbonResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRibbon: function (id, tenantId, data, authorization, acceptLanguage, options) {
            return localVarFp.updateRibbon(id, tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update contents in ribbon
         * @summary Update contents in ribbon
         * @param {string} ribbonId
         * @param {string} tenantId
         * @param {UpdateContentsInRibbonRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRibbonContents: function (ribbonId, tenantId, data, authorization, acceptLanguage, options) {
            return localVarFp.updateRibbonContents(ribbonId, tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Tenant page ribbon resource
         * @param {string} pageId
         * @param {string} tenantId
         * @param {UpdatePageRibbonResourceRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRibbonInPage: function (pageId, tenantId, data, authorization, acceptLanguage, options) {
            return localVarFp.updateRibbonInPage(pageId, tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update a tenant page
         * @summary Update a tenant page
         * @param {string} id
         * @param {string} tenantId Tenant slug
         * @param {TenantPageResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenantPage: function (id, tenantId, data, authorization, acceptLanguage, options) {
            return localVarFp.updateTenantPage(id, tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update trailers for content
         * @summary Update trailers for content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {ContentTrailersRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrailersOfContent: function (contentId, tenantSlug, data, options) {
            return localVarFp.updateTrailersOfContent(contentId, tenantSlug, data, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.AdminContentListingApiFactory = AdminContentListingApiFactory;
/**
 * AdminContentListingApi - object-oriented interface
 * @export
 * @class AdminContentListingApi
 * @extends {BaseAPI}
 */
var AdminContentListingApi = /** @class */ (function (_super) {
    __extends(AdminContentListingApi, _super);
    function AdminContentListingApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create one broadcast schedule for curated live channel
     * @summary Create one broadcast schedule for curated live channel
     * @param {string} channelId
     * @param {string} tenantSlug
     * @param {BroadcastSchedule} data
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.createBroadcastSchedule = function (channelId, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).createBroadcastSchedule(channelId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create one broadcast schedule for curated live channel
     * @summary Create one broadcast schedule for curated live channel
     * @param {string} channelId
     * @param {string} tenantSlug
     * @param {BroadcastScheduleSerializerV2} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.createChannelBroadcastSchedule = function (channelId, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).createChannelBroadcastSchedule(channelId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Create one ribbon
     * @param {string} tenantId Tenant slug
     * @param {RibbonResource} data
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.createRibbon = function (tenantId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).createRibbon(tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create a tenant page
     * @summary Create a tenant page
     * @param {string} tenantId Tenant slug
     * @param {TenantPageResource} data
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.createTenantPage = function (tenantId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).createTenantPage(tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete one broadcast schedule
     * @summary Delete one broadcast schedule
     * @param {string} channelId
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.deleteBroadcastSchedule = function (channelId, id, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).deleteBroadcastSchedule(channelId, id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete one broadcast schedule
     * @summary Delete one broadcast schedule
     * @param {string} channelId
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.deleteChannelBroadcastSchedule = function (channelId, id, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).deleteChannelBroadcastSchedule(channelId, id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete a epg feed
     * @param {string} channelId
     * @param {string} id A unique value identifying this epg feed.
     * @param {string} tenantSlug Tenant slug
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.deleteChannelEgp = function (channelId, id, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).deleteChannelEgp(channelId, id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete one ribbon
     * @param {string} id
     * @param {string} tenantId Tenant slug
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.deleteRibbon = function (id, tenantId, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).deleteRibbon(id, tenantId, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get a tenant page
     * @summary Get a tenant page
     * @param {string} id
     * @param {string} tenantId Tenant slug
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.deleteTenantPage = function (id, tenantId, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).deleteTenantPage(id, tenantId, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Export broadcast schedule epg list
     * @param {string} channelId
     * @param {string} scheduleId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.exportBroadcastScheduleEpgs = function (channelId, scheduleId, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).exportBroadcastScheduleEpgs(channelId, scheduleId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Fill channel broadcast schedule
     * @param {string} channelId
     * @param {string} tenantSlug
     * @param {FillChannelScheduleRequest} data
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.fillChannelBroadcastSchedule = function (channelId, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).fillChannelBroadcastSchedule(channelId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get one broadcast schedule
     * @summary Get one broadcast schedule
     * @param {string} channelId
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.getBroadcastSchedule = function (channelId, id, tenantSlug, authorization, acceptLanguage, select, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).getBroadcastSchedule(channelId, id, tenantSlug, authorization, acceptLanguage, select, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get broadcast schedule epg list
     * @param {string} channelId
     * @param {string} scheduleId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.getBroadcastScheduleEpgs = function (channelId, scheduleId, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).getBroadcastScheduleEpgs(channelId, scheduleId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get broadcast schedules of channel
     * @summary Get broadcast schedules of channel
     * @param {string} channelId
     * @param {string} tenantSlug
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.getBroadcastSchedules = function (channelId, tenantSlug, search, ordering, page, pageSize, authorization, acceptLanguage, select, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).getBroadcastSchedules(channelId, tenantSlug, search, ordering, page, pageSize, authorization, acceptLanguage, select, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get one broadcast schedule
     * @summary Get one broadcast schedule
     * @param {string} channelId
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.getChannelBroadcastSchedule = function (channelId, id, tenantSlug, authorization, acceptLanguage, select, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).getChannelBroadcastSchedule(channelId, id, tenantSlug, authorization, acceptLanguage, select, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get broadcast schedules of channel
     * @summary Get broadcast schedules of channel
     * @param {string} channelId
     * @param {string} tenantSlug
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.getChannelBroadcastSchedules = function (channelId, tenantSlug, search, ordering, page, pageSize, authorization, acceptLanguage, select, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).getChannelBroadcastSchedules(channelId, tenantSlug, search, ordering, page, pageSize, authorization, acceptLanguage, select, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get one epg feed
     * @param {string} channelId
     * @param {string} id A unique value identifying this epg feed.
     * @param {string} tenantSlug Tenant slug
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.getChannelEpg = function (channelId, id, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).getChannelEpg(channelId, id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary List all epg feeds
     * @param {string} channelId
     * @param {string} tenantSlug Tenant slug
     * @param {number} [startAtGte]
     * @param {number} [endAtLte]
     * @param {number} [startAt]
     * @param {number} [endAt]
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.getChannelEpgs = function (channelId, tenantSlug, startAtGte, endAtLte, startAt, endAt, search, ordering, page, pageSize, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).getChannelEpgs(channelId, tenantSlug, startAtGte, endAtLte, startAt, endAt, search, ordering, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get curated channel EPGs
     * @summary Get curated channel EPGs
     * @param {string} channelId
     * @param {string} tenantSlug
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [scheduleDate] Specify will ignore start_time and end_time ( format - yyyy-mm-dd )
     * @param {string} [startTime] Filter programs start after this (default to now)
     * @param {string} [endTime] Filter programs end before this (default to now + 3hrs)
     * @param {string} [timezone] timezone of your current location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.getCuratedChannelEpgs = function (channelId, tenantSlug, acceptLanguage, scheduleDate, startTime, endTime, timezone, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).getCuratedChannelEpgs(channelId, tenantSlug, acceptLanguage, scheduleDate, startTime, endTime, timezone, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary List all ribbon
     * @param {string} tenantId Tenant slug
     * @param {string} [id]
     * @param {string} [tenantPlatform]
     * @param {string} [tenantPage]
     * @param {string} [contentId]
     * @param {number} [type]
     * @param {string} [isReplicable]
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.getListRibbon = function (tenantId, id, tenantPlatform, tenantPage, contentId, type, isReplicable, search, ordering, page, pageSize, authorization, acceptLanguage, select, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).getListRibbon(tenantId, id, tenantPlatform, tenantPage, contentId, type, isReplicable, search, ordering, page, pageSize, authorization, acceptLanguage, select, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get live channel EPGs
     * @summary Get live channel EPGs
     * @param {string} channelId
     * @param {string} tenantSlug
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [scheduleDate] Specify will ignore start_time and end_time ( format - yyyy-mm-dd )
     * @param {string} [startTime] Filter programs start after this (default to now)
     * @param {string} [endTime] Filter programs end before this (default to now + 3hrs)
     * @param {string} [timezone] timezone of your current location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.getLiveChannelEpgs = function (channelId, tenantSlug, acceptLanguage, scheduleDate, startTime, endTime, timezone, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).getLiveChannelEpgs(channelId, tenantSlug, acceptLanguage, scheduleDate, startTime, endTime, timezone, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get one ribbon
     * @param {string} id
     * @param {string} tenantId Tenant slug
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.getRibbon = function (id, tenantId, authorization, acceptLanguage, select, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).getRibbon(id, tenantId, authorization, acceptLanguage, select, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get a tenant page
     * @summary Get a tenant page
     * @param {string} id
     * @param {string} tenantId Tenant slug
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.getTenantPage = function (id, tenantId, authorization, acceptLanguage, select, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).getTenantPage(id, tenantId, authorization, acceptLanguage, select, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List tenant pages
     * @summary List tenant pages
     * @param {string} tenantId Tenant slug
     * @param {string} [tenantPlatform]
     * @param {string} [displayStyle]
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.getTenantPages = function (tenantId, tenantPlatform, displayStyle, search, ordering, page, pageSize, authorization, acceptLanguage, select, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).getTenantPages(tenantId, tenantPlatform, displayStyle, search, ordering, page, pageSize, authorization, acceptLanguage, select, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Import epg
     * @param {string} channelId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.importLiveChannelEpg = function (channelId, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).importLiveChannelEpg(channelId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Import epg
     * @param {string} channelId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.importLiveChannelEpg_1 = function (channelId, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).importLiveChannelEpg_1(channelId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update curated epg list of live channel
     * @summary Update curated epg list of live channel
     * @param {string} channelId
     * @param {string} id
     * @param {string} tenantSlug
     * @param {BroadcastSchedule} data
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.updateBroadcastSchedule = function (channelId, id, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).updateBroadcastSchedule(channelId, id, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update broadcast schedule epg list
     * @param {string} channelId
     * @param {string} scheduleId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.updateBroadcastScheduleEpgs = function (channelId, scheduleId, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).updateBroadcastScheduleEpgs(channelId, scheduleId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update curated epg list of live channel
     * @summary Update curated epg list of live channel
     * @param {string} channelId
     * @param {string} id
     * @param {string} tenantSlug
     * @param {BroadcastScheduleSerializerV2} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.updateChannelBroadcastSchedule = function (channelId, id, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).updateChannelBroadcastSchedule(channelId, id, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update a epg feed
     * @param {string} channelId
     * @param {string} id A unique value identifying this epg feed.
     * @param {string} tenantSlug Tenant slug
     * @param {EPGFeed} data
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.updateChannelEpg = function (channelId, id, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).updateChannelEpg(channelId, id, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update season resource
     * @summary Update season resource
     * @param {string} parentId
     * @param {string} tenantId
     * @param {UpdateParentContentResourceRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {boolean} [autoSort] check auto sort data draft
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.updateChildrenContent = function (parentId, tenantId, data, authorization, acceptLanguage, autoSort, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).updateChildrenContent(parentId, tenantId, data, authorization, acceptLanguage, autoSort, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update a curated epg feed for channel
     * @param {string} channelId
     * @param {string} tenantSlug Tenant slug
     * @param {UpdateCuratedEPGListRequest} data
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.updateCuratedChannelEpgs = function (channelId, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).updateCuratedChannelEpgs(channelId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update a ribbon
     * @param {string} id
     * @param {string} tenantId Tenant slug
     * @param {RibbonResource} data
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.updateRibbon = function (id, tenantId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).updateRibbon(id, tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update contents in ribbon
     * @summary Update contents in ribbon
     * @param {string} ribbonId
     * @param {string} tenantId
     * @param {UpdateContentsInRibbonRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.updateRibbonContents = function (ribbonId, tenantId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).updateRibbonContents(ribbonId, tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Tenant page ribbon resource
     * @param {string} pageId
     * @param {string} tenantId
     * @param {UpdatePageRibbonResourceRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.updateRibbonInPage = function (pageId, tenantId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).updateRibbonInPage(pageId, tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update a tenant page
     * @summary Update a tenant page
     * @param {string} id
     * @param {string} tenantId Tenant slug
     * @param {TenantPageResource} data
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.updateTenantPage = function (id, tenantId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).updateTenantPage(id, tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update trailers for content
     * @summary Update trailers for content
     * @param {string} contentId
     * @param {string} tenantSlug
     * @param {ContentTrailersRequest} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentListingApi
     */
    AdminContentListingApi.prototype.updateTrailersOfContent = function (contentId, tenantSlug, data, options) {
        var _this = this;
        return (0, exports.AdminContentListingApiFp)(this.configuration).updateTrailersOfContent(contentId, tenantSlug, data, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AdminContentListingApi;
}(base_1.BaseAPI));
exports.AdminContentListingApi = AdminContentListingApi;
