import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Col, Row, Button, FormGroup, FormControl } from 'react-bootstrap';
import { verifyEmailFormat, verifyPhoneNumber } from '../../../utils/utils';
import { loginEmail, registerFB, getPaidContent, rememberAccount } from '../services';
import Loader from 'react-loader-spinner';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import * as APIString from '../../../constants/apiString';
import loginFormId from '../../../constants/ids/loginFormId';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import pushAnalytics from '../../../services/analytics/Analytics';
import styled from 'styled-components';
import { getConfigByKey, tenantConfig } from '../../tenantConfig/services';
import { updateDataPlanSelected } from './../../box/services';
import { getStepPayperview } from '~features/payperview/functions';
import LoginGoogle from './login/loginGoogle';
import LoginOdv from './login/odv';
import LoginProjectW from './login/projectw';
import { setLoginModalShow } from '~components/global/globalSlice';
import SignUpProjectW from './registerFormW';
import WatchMoreModal from './watchMoreModal';

class LoginForm extends Component {
  static propTypes = {
    modalShow: PropTypes.bool,
    showForgotPassAction: PropTypes.func,
    showRegisterAction: PropTypes.func,
    dispatchLoginEmail: PropTypes.func,
    dispatchRegisterFB: PropTypes.func,
    hideModal: PropTypes.func,
    param: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isLoading: false,
      showAlert: false,
      resMessage: '',
    };
  }

  componentDidMount() {
    const { email } = this.props.rememberAccount;
    this.setState({
      email: email || '',
    });
  }

  componentWillReceiveProps(nextProp) {
    if (this.props.isShowLogin !== nextProp.isShowLogin) {
      const { email } = nextProp.rememberAccount;
      this.setState({
        email: email || '',
      });
    }
  }

  _onChangeText = (key, e) => {
    this.setState({
      [key]: e.target.value,
      resMessage: '',
    });
  };

  _onKeyDownEnter = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      this._signIn();
    }
  };

  _signIn = (checkbox = false) => {
    const { email, password } = this.state;
    let { param, t, dispatchRememberAccount } = this.props;
    pushAnalytics('click', { content_type: 'button', item_name: 'Login' });
    if (!email || !password) {
      this.setState({
        isLoading: false,
        resMessage: t('alertNullEmailPass'),
        showAlert: true,
      });
      return;
    }

    let errors = [];

    const validEmail = verifyEmailFormat(email);
    const validPhoneNumber =
      getConfigByKey('features.loginPhoneNumber') && verifyPhoneNumber(email);

    if (getConfigByKey('features.loginPhoneNumber') && !validPhoneNumber) {
      errors.push(t('invalid_phone_number'));
    }
    if (!validEmail) {
      getConfigByKey('ui_theme') === 'projectw'
        ? errors.push('Invalid e-mail address')
        : errors.push(t('alertWrongEmailFormat'));
    }

    if (validEmail || validPhoneNumber) {
      errors = [];
    }

    if (errors.length) {
      this.setState({
        isLoading: false,
        resMessage: errors.join(' or '),
        showAlert: true,
      });
      return;
    }
    if (checkbox) {
      dispatchRememberAccount({ email, checkbox: true });
    } else {
      dispatchRememberAccount({ email: null, checkbox: false });
    }

    this.setState(
      {
        isLoading: true,
        showAlert: false,
        resMessage: '',
      },
      () => {
        this.props
          .dispatchLoginEmail(email, password)
          .then(res => {
            pushAnalytics('login', { method: 'email' });
            this.checkYourPaidAccount(param, res);
          })
          .catch(error => {
            setTimeout(() => {
              this.setState({
                isLoading: false,
                resMessage: error.message,
                showAlert: true,
              });
            }, 1000);
          });
      },
    );
  };
  _loginFb = renderProps => {
    pushAnalytics('click', {
      content_type: 'button',
      item_name: 'Sign in with Facebook',
    });
    renderProps.onClick();
  };
  _responseFacebook = response => {
    const { param } = this.props;
    const mac_address = '';
    const model = '';
    const device_id = '';
    const platform = 'web';
    const token = response.accessToken.toString();
    this.setState(
      {
        isLoading: true,
        showAlert: false,
        resMessage: '',
      },
      () => {
        this.props
          .dispatchRegisterFB(token, platform, model, device_id, mac_address)
          .then(res => {
            pushAnalytics('login', { method: 'facebook' });
            this.checkYourPaidAccount(param, res);
          })
          .catch(error => {
            setTimeout(() => {
              this.setState({
                isLoading: false,
                resMessage: error.message,
                showAlert: true,
              });
            }, 1000);
          });
      },
    );
  };

  _hrefSupcription = () => {
    const { history, param, language, isFollowSubcription, dpUpdateData } = this.props;
    let upgrade = param && param;
    pushAnalytics('click', {
      content_type: 'button',
      item_name: 'Register New Account',
    });
    if (param && param.payperview) {
      return getStepPayperview(0, this.props, param.method.setStep, param.method);
    }
    if (!isFollowSubcription) {
      dpUpdateData({
        upgrade: {
          upgrade: history.location.state,
          login: true,
        },
      });
      return history.push(`/${language}/box/signup`, upgrade);
    }
    history.push(`/${language}/box/offers`, upgrade);
  };

  _renderLoading() {
    const { isLoading } = this.state;

    if (isLoading) {
      return (
        <div style={styles.overlayLoading}>
          <Loader type="Oval" color="#FFFFFF" height="30" width="30" />
        </div>
      );
    }

    return null;
  }

  checkYourPaidAccount = (param, account) => {
    const { dispatchGetPaidContent, history, language, hideModal } = this.props;
    dispatchGetPaidContent()
      .then(paidContents => {
        setTimeout(() => {
          hideModal('showLoginModal');
          this.setState({
            isLoading: false,
            resMessage: '',
            showAlert: false,
          });
        }, 1000);
        if (account.profile.has_subscription || !param) return window.location.reload();
        if (param && param.svod === 1 && !account.profile.has_subscription) {
          return history.push(`/${language}/box/offers`, param);
        }
        const { state } = param && param.props;
        if (!state) return;
        let contentId = state && state.infoShow ? state.infoShow.id : state.entityDetail.id;
        let foundPaidContents =
          (Array.isArray(paidContents) &&
            paidContents.filter(element => contentId === element.content_id)) ||
          [];
        if (
          state &&
          param.props.target === 'player' &&
          state.redirect &&
          Array.isArray(foundPaidContents) &&
          foundPaidContents.length
        ) {
          let pathname = state.redirect;
          return history.replace({
            pathname,
            state: {
              payperview: true,
              isRestrictions: state.isRestrictions,
              isPopup: false,
            },
          });
        }
        if (Array.isArray(foundPaidContents) && foundPaidContents.length)
          return window.location.reload();
        if (param && param.payperview) {
          let timer = setTimeout(() => {
            clearTimeout(timer);
            return getStepPayperview(2, param.props, param.method.setStep, param.method);
          }, 700);
          return;
        }
      })
      .catch();
  };

  hiddenModalLogin = () => {
    const { param, hideModal } = this.props;
    if (param && param.payperview) {
      param.method.hideModal();
    }
    hideModal('showLoginModal');
    this._resetState();
  };

  _resetState = () => {
    this.setState({
      email: '',
      password: '',
      showAlert: false,
      resMessage: '',
    });
  };

  render() {
    const {
      modalShow,
      email,
      password,
      hideModal,
      showForgotPassAction,
      t,
      showRegisterAction,
      param,
      isShowLoginModal,
      isShowWelcome,
    } = this.props;
    const { showAlert, resMessage } = this.state;

    if (getConfigByKey('features.popoverSignup')) return <LoginOdv {...this} />;
    if (getConfigByKey('ui_theme') === 'projectw' && isShowLoginModal)
      return <LoginProjectW {...this} />;
    if (getConfigByKey('ui_theme') === 'projectw' && !isShowLoginModal)
      return <SignUpProjectW {...this} />;

    return (
      <div>
        <ModalStyled
          show={modalShow}
          onHide={() => this.hiddenModalLogin()}
          aria-labelledby="contained-modal-title-vcenter"
          className="v-loginForm"
        >
          {this._renderLoading()}
          <Modal.Body>
            <Row className="v-loginForm--grid">
              <Col md={12} className="v-loginForm--grid">
                <p data-testid={loginFormId.title} className="v-loginForm--title">
                  {t('loginScreen.title')}
                </p>
                <h2 className="v-loginForm--odm">{getConfigByKey('site_name')}</h2>
                {getConfigByKey('features.signInFacebookFeature') && (
                  <>
                    <FacebookLogin
                      appId={APIString.FACEBOOK_APP_ID}
                      callback={response => this._responseFacebook(response)}
                      render={renderProps => (
                        <Button
                          data-testid={loginFormId.continueWithFacebookBtn}
                          onClick={() => this._loginFb(renderProps)}
                          className="v-loginForm--btn"
                          bsSize="large"
                        >
                          {t('loginScreen.buttonContinueFb')}
                        </Button>
                      )}
                    />
                    <h5
                      style={{
                        textTransform: 'uppercase',
                        textAlign: 'left',
                        fontWeight: 400,
                      }}
                    >
                      {t('loginScreen.Or')}
                    </h5>
                  </>
                )}
              </Col>
              <Col md={12} className="v-loginForm--grid">
                <form onSubmit={() => this._signIn()}>
                  <FormGroup controlId="formLogin" type="text" style={{ justifyContent: 'center' }}>
                    <FormControl
                      data-testid={loginFormId.emailTxt}
                      type="text"
                      placeholder={
                        getConfigByKey('tenant_slug') === 'sctv'
                          ? t(`loginScreen.placeholderEmail.sctv`)
                          : t(`loginScreen.placeholderEmail.default`)
                      }
                      style={styles.Input}
                      name="name"
                      id="formLogin"
                      value={email}
                      onChange={e => this._onChangeText('email', e)}
                      onKeyDown={e => this._onKeyDownEnter(e)}
                    />
                    <FormControl
                      data-testid={loginFormId.passwordTxt}
                      type="password"
                      style={{ ...styles.Input, marginTop: 10 }}
                      placeholder={t('loginScreen.placeholderPassword')}
                      value={password}
                      name="password"
                      id="formLogin"
                      onChange={e => this._onChangeText('password', e)}
                      onKeyDown={e => this._onKeyDownEnter(e)}
                    />
                    <div
                      style={{
                        display: resMessage ? 'block' : 'none',
                        marginTop: 10,
                        textAlign: 'left',
                      }}
                    >
                      {showAlert ? (
                        <h6
                          data-testid={loginFormId.errorMessageLbl}
                          style={{
                            color: !showAlert ? '#0095ff' : '#ff0000',
                            fontSize: 16,
                          }}
                        >
                          {resMessage}
                        </h6>
                      ) : null}
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        margin: '10px 0',
                      }}
                    >
                      {getConfigByKey('feature_advanced_web_multitenancy_enabled') && (
                        <Button
                          data-testid={loginFormId.forgotPassword}
                          className="v-loginForm--btn__link"
                          style={{ padding: '0 3rem 0 0' }}
                          bsStyle="link"
                          onClick={() => {
                            pushAnalytics('click', {
                              content_type: 'button',
                              item_name: 'Forgot your password',
                            });
                            showForgotPassAction();
                            param && param.payperview && param.method.hideModal();
                          }}
                        >
                          {t('loginScreen.buttonTextForgotPass')}
                        </Button>
                      )}
                      <Button
                        data-testid={loginFormId.loginBtn}
                        onClick={() => this._signIn()}
                        bsStyle="success"
                        className="v-loginForm--btn form_submit"
                        bsSize="large"
                      >
                        {t('loginScreen.buttonTextSignIn')}
                      </Button>
                    </div>
                    {getConfigByKey('feature_advanced_web_multitenancy_enabled') && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          paddingTop: 10,
                          borderTop: '1px solid #cccccc',
                        }}
                      >
                        <h4
                          style={{ fontWeight: '100' }}
                          data-testid={loginFormId.doNotHaveAnAccountLbl}
                        >
                          {t('loginScreen.textNotAccount')}
                        </h4>
                        {!getConfigByKey('feature_advanced_web_multitenancy_enabled') ? (
                          <Button
                            data-testid={loginFormId.registerBtn}
                            onClick={() => {
                              showRegisterAction();
                              hideModal('showLoginModal');
                            }}
                            className="v-loginForm--btn v-loginForm--btn__gray"
                            bsSize="large"
                          >
                            {t('loginScreen.buttonTextRegis')}
                          </Button>
                        ) : (
                          <div onClick={() => this._hrefSupcription()} style={{ width: '100%' }}>
                            <Button
                              data-testid={loginFormId.registerBtn}
                              onClick={() => hideModal('showLoginModal')}
                              className="v-loginForm--btn v-loginForm--btn__gray"
                              bsSize="large"
                            >
                              {t('loginScreen.buttonTextRegis')}
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                    <LoginGoogle
                      checkfollowPayperviews={(param, account) =>
                        this.checkYourPaidAccount(param, account)
                      }
                      PpvParams={this.props.param}
                      target="login"
                    />
                  </FormGroup>
                </form>
              </Col>
            </Row>
          </Modal.Body>
        </ModalStyled>
      </div>
    );
  }
}

export const ModalStyled = styled(Modal)`
  &.v-loginForm {
    z-index: 9999;
    font-family: var(--font);
    .modal-body {
      padding: 1rem;
      font-size: 1rem;
      color: #666666;
    }
  }
  .v-loginForm {
    &--grid {
      display: flex;
      justify-content: center;
      text-align: center;
      flex-direction: column;
    }
    &--title {
      font-size: 1.5rem;
      color: #666666;
      font-family: var(--font);
    }
    &--odm {
      font-size: 1.75rem;
      color: ${({ theme }) => theme.primaryColor};
      margin-top: 0;
      font-weight: bolder;
    }
    &--btn {
      background: ${({ theme }) => theme.primaryColor};
      color: rgb(255, 255, 255);
      font-size: 1.5rem;
      width: 100%;
      margin: 0.75rem 0;
      padding: 1rem 0;
      text-transform: capitalize;
      font-weight: 300;
      border: none;
      &__gray {
        background: #7d7d7d;
      }
      &__link {
        color: ${({ theme }) => theme.primaryColor};
      }
    }
  }

  @media (min-width: 576px) {
    &.v-loginForm {
      .modal-body {
        padding: 1rem 4rem 0;
      }
      .modal-dialog {
        margin: auto;
      }
    }
  }

  @media (min-width: 768px) {
    .v-loginForm {
    }
  }

  @media (min-width: 992px) {
    .v-loginForm {
    }
  }
`;

const styles = {
  flex: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '0.85rem',
    textAlign: 'center',
  },
  flex_column: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
  },
  Input: {
    padding: '2rem 1rem',
    background: '#ededed',
    border: 'none',
  },
  overlayLoading: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: '#000000',
    zIndex: 999,
    opacity: 0.7,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  loginButton: {
    borderRadius: 50,
    backgroundColor: '#2260E2',
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 10,
    color: '#FFFFFF',
  },
};

LoginForm.defaultProps = {
  modalShow: false,
  showForgotPassAction: () => {},
  showRegisterAction: () => {},
  hideModal: () => {},
  param: null,
};

const mapDispatchToProps = {
  dispatchLoginEmail: (email, password) => loginEmail(email, password),
  dispatchRegisterFB: (token, platform, model, device_id, mac_address) =>
    registerFB(token, platform, model, device_id, mac_address),
  dpUpdateData: payload => updateDataPlanSelected(payload),
  dispatchGetPaidContent: () => getPaidContent(),
  dispatchRememberAccount: params => rememberAccount(params),
  dpShowLoginModal: value => setLoginModalShow(value),
};

const mapStateToProps = state => ({
  language: state.root.language,
  isFollowSubcription: state.root.isFollowSubcription,
  rememberAccount: state.auth.rememberAccount,
  isShowLogin: state.auth.isShowLogin,
  isShowLoginModal: state.global.ActionLogin.isShowLoginModal,
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LoginForm)),
);
