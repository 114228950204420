import React, { useEffect, useRef } from 'react';
import { App } from './app/views';
import { getConfigTemplateContent } from './app/services';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { changeLanguage } from '~app/services';
import { getConfigByKey } from '~features/tenantConfig/services';
import { useCookies } from 'react-cookie';
import { isNode } from '~utils/ssr';
import { getLocaleFromUrl } from '~utils/locales';
import * as homepageSv from '~features/homepage/services';
import $ from 'jquery';
import { useHistory } from 'react-router-dom';

const fonts = {
  Pretendard: 'Pretendard',
  'Neue Haas Grotesk Display Pro': 'Neue Haas Grotesk Display Pro',
  'Proxima Nova': 'Proxima Nova',
};

function InitApp(props) {
  const { platformConfig, dpGetConfigTemplateContent, menu, isSsrHome, auth } = props;
  const { i18n } = useTranslation();
  const [draft, setDraft] = React.useState(true);
  const [actived, setActivedMenu] = React.useState(false);
  const [init, setInitApp] = React.useState(false);
  const [cookies, setCookie] = useCookies(['language']);
  const history = useHistory();

  const authRef = useRef(auth);

  const dispatch = useDispatch();
  const localeFromUrl = getLocaleFromUrl();
  // let defaultLgn = localeFromUrl || getConfigByKey('default_locale') || 'en';
  // let language = JSON.parse(window.localStorage.getItem('persist:root'));

  React.useEffect(() => {
    setDraft(true);
    // if (!localeFromUrl && language) {
    //   language = JSON.parse(language.root);
    //   defaultLgn = language.language;
    // }

    if (!init) {
      // dispatch(changeLanguage(defaultLgn));
      // i18n.changeLanguage(defaultLgn);
      // setCookie('language', defaultLgn, { path: '/' });
    }
    setInitApp(true);
    dispatch(changeLanguage(i18n.language));
    const { language } = i18n;
    const newFont = language === 'ko' ? fonts.Pretendard : fonts['Proxima Nova'];
    $('html').attr('lang', language).css('--font', newFont);
  }, [i18n.language]);

  useEffect(() => {
    $('body,html').addClass(`${getConfigByKey('ui_theme')}-theme`);
    $('html').css({
      '--fontPretendard': fonts.Pretendard,
      '--fontProximaNova': fonts['Proxima Nova'],
    });
  }, []);

  // useEffect(() => {
  //   if (authRef.current.isLogin && !auth.isLogin) {
  //     history.replace(`/${i18n.language}`);
  //   }
  //   authRef.current = auth;
  // }, [auth]);

  React.useEffect(() => {
    if (platformConfig && platformConfig.tenant_pages) {
      dispatch(homepageSv.getMenu(platformConfig.tenant_pages));
      setActivedMenu(true);
    } else {
      dpGetConfigTemplateContent()
        .then(res => {
          dispatch(homepageSv.getMenu(res.tenant_pages));
        })
        .catch(() => {})
        .finally(() => {
          setActivedMenu(true);
        });
    }
  }, []);

  React.useEffect(() => {
    if (!draft) {
      return;
    }
    setDraft(false);
  }, [draft]);

  if (isNode() || isSsrHome) return <App />;
  return !draft && (actived || menu) ? <App /> : null;
}

const mapDispatchToProps = {
  dpGetConfigTemplateContent: () => getConfigTemplateContent(),
};

const mapStateToProps = state => ({
  platformConfig: state.tenantConfig.platformConfig,
  isSsrHome: state.home.isSsr,
  menu: state.home.menu,
  auth: state.auth,
});

export default connect(mapStateToProps, mapDispatchToProps)(InitApp);
