/* tslint:disable */
/* eslint-disable */
/**
 * Vimai Core Service UI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminPaymentApi = exports.AdminPaymentApiFactory = exports.AdminPaymentApiFp = exports.AdminPaymentApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * AdminPaymentApi - axios parameter creator
 * @export
 */
var AdminPaymentApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Cancel user subscription
         * @summary Cancel user subscription
         * @param {string} tenantSlug
         * @param {CancelUserSubscriptionByAdminRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelUserSubscription: function (tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('cancelUserSubscription', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('cancelUserSubscription', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/cancel_user_subscription"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create one paid content refund resource
         * @summary Create one  content refund resource
         * @param {string} tenantSlug
         * @param {PaidContentRefund} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContentRefund: function (tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('createContentRefund', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('createContentRefund', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/paid_content_refund/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create a TVOD price
         * @summary Create a TVOD price
         * @param {string} tenantSlug
         * @param {TVODPriceResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTvodPrice: function (tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('createTvodPrice', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('createTvodPrice', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/tvod_prices/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get a paid content refund
         * @summary Get a paid content refund
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentRefund: function (id, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getContentRefund', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getContentRefund', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/paid_content_refund/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get list paid content refund
         * @summary Get list paid content refund
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentRefunds: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getContentRefunds', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/paid_content_refund/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (search !== undefined) {
                                localVarQueryParameter['search'] = search;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get list of subscription product resource
         * @summary Get list of subscription product resource
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListSubscriptionProductResource: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getListSubscriptionProductResource', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/subscription_product_resources/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (search !== undefined) {
                                localVarQueryParameter['search'] = search;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get one Redeem Subscription
         * @summary Get one Redeem Subscription
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedeemSubscription: function (id, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getRedeemSubscription', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getRedeemSubscription', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/redeem_code_subscriptions/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List all Redeem Subscriptions
         * @summary List all Redeem Subscriptions
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedeemSubscriptions: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getRedeemSubscriptions', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/redeem_code_subscriptions/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (search !== undefined) {
                                localVarQueryParameter['search'] = search;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get subscription product resource
         * @summary Get subscription product resource
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionProductResource: function (id, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getSubscriptionProductResource', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getSubscriptionProductResource', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/subscription_product_resources/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get Subscription Tier
         * @summary Get Subscription Tier
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionTier: function (id, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getSubscriptionTier', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getSubscriptionTier', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/subscription_tiers/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get subscription tiers
         * @summary Get subscription tiers
         * @param {string} tenantSlug
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionTiers: function (tenantSlug, ordering, page, pageSize, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getSubscriptionTiers', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/subscription_tiers/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (ordering !== undefined) {
                                localVarQueryParameter['ordering'] = ordering;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get a TVOD price
         * @summary Get a TVOD price
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {'normal' | 'tvod_credit'} [tvodType] TVOD Price type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvodPriceDetail: function (id, tenantSlug, authorization, acceptLanguage, select, tvodType, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getTvodPriceDetail', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getTvodPriceDetail', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/tvod_prices/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (tvodType !== undefined) {
                                localVarQueryParameter['tvod_type'] = tvodType;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List all TVOD prices
         * @summary List all TVOD prices
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {'normal' | 'tvod_credit'} [type] TVOD Price type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvodPrices: function (tenantSlug, search, ordering, page, pageSize, authorization, acceptLanguage, select, type, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getTvodPrices', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/tvod_prices/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (search !== undefined) {
                                localVarQueryParameter['search'] = search;
                            }
                            if (ordering !== undefined) {
                                localVarQueryParameter['ordering'] = ordering;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (type !== undefined) {
                                localVarQueryParameter['type'] = type;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete a tvod price
         * @summary Delete a tvod price
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTvodPrice: function (id, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('removeTvodPrice', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('removeTvodPrice', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/tvod_prices/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update a paid content refund
         * @summary Update a paid content refund
         * @param {string} id
         * @param {string} tenantSlug
         * @param {PaidContentRefund} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentRefund: function (id, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('updateContentRefund', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('updateContentRefund', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateContentRefund', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/paid_content_refund/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update a TVOD price
         * @summary Update a TVOD price
         * @param {string} id
         * @param {string} tenantSlug
         * @param {TVODPriceResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTvodPrice: function (id, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('updateTvodPrice', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('updateTvodPrice', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateTvodPrice', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/tvod_prices/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.AdminPaymentApiAxiosParamCreator = AdminPaymentApiAxiosParamCreator;
/**
 * AdminPaymentApi - functional programming interface
 * @export
 */
var AdminPaymentApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.AdminPaymentApiAxiosParamCreator)(configuration);
    return {
        /**
         * Cancel user subscription
         * @summary Cancel user subscription
         * @param {string} tenantSlug
         * @param {CancelUserSubscriptionByAdminRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelUserSubscription: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.cancelUserSubscription(tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Create one paid content refund resource
         * @summary Create one  content refund resource
         * @param {string} tenantSlug
         * @param {PaidContentRefund} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContentRefund: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createContentRefund(tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Create a TVOD price
         * @summary Create a TVOD price
         * @param {string} tenantSlug
         * @param {TVODPriceResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTvodPrice: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createTvodPrice(tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get a paid content refund
         * @summary Get a paid content refund
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentRefund: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getContentRefund(id, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get list paid content refund
         * @summary Get list paid content refund
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentRefunds: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getContentRefunds(tenantSlug, search, page, pageSize, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get list of subscription product resource
         * @summary Get list of subscription product resource
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListSubscriptionProductResource: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getListSubscriptionProductResource(tenantSlug, search, page, pageSize, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get one Redeem Subscription
         * @summary Get one Redeem Subscription
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedeemSubscription: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getRedeemSubscription(id, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * List all Redeem Subscriptions
         * @summary List all Redeem Subscriptions
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedeemSubscriptions: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getRedeemSubscriptions(tenantSlug, search, page, pageSize, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get subscription product resource
         * @summary Get subscription product resource
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionProductResource: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSubscriptionProductResource(id, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get Subscription Tier
         * @summary Get Subscription Tier
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionTier: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSubscriptionTier(id, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get subscription tiers
         * @summary Get subscription tiers
         * @param {string} tenantSlug
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionTiers: function (tenantSlug, ordering, page, pageSize, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSubscriptionTiers(tenantSlug, ordering, page, pageSize, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get a TVOD price
         * @summary Get a TVOD price
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {'normal' | 'tvod_credit'} [tvodType] TVOD Price type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvodPriceDetail: function (id, tenantSlug, authorization, acceptLanguage, select, tvodType, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTvodPriceDetail(id, tenantSlug, authorization, acceptLanguage, select, tvodType, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * List all TVOD prices
         * @summary List all TVOD prices
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {'normal' | 'tvod_credit'} [type] TVOD Price type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvodPrices: function (tenantSlug, search, ordering, page, pageSize, authorization, acceptLanguage, select, type, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTvodPrices(tenantSlug, search, ordering, page, pageSize, authorization, acceptLanguage, select, type, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Delete a tvod price
         * @summary Delete a tvod price
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTvodPrice: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.removeTvodPrice(id, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update a paid content refund
         * @summary Update a paid content refund
         * @param {string} id
         * @param {string} tenantSlug
         * @param {PaidContentRefund} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentRefund: function (id, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateContentRefund(id, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update a TVOD price
         * @summary Update a TVOD price
         * @param {string} id
         * @param {string} tenantSlug
         * @param {TVODPriceResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTvodPrice: function (id, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateTvodPrice(id, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.AdminPaymentApiFp = AdminPaymentApiFp;
/**
 * AdminPaymentApi - factory interface
 * @export
 */
var AdminPaymentApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.AdminPaymentApiFp)(configuration);
    return {
        /**
         * Cancel user subscription
         * @summary Cancel user subscription
         * @param {string} tenantSlug
         * @param {CancelUserSubscriptionByAdminRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelUserSubscription: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.cancelUserSubscription(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create one paid content refund resource
         * @summary Create one  content refund resource
         * @param {string} tenantSlug
         * @param {PaidContentRefund} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContentRefund: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.createContentRefund(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create a TVOD price
         * @summary Create a TVOD price
         * @param {string} tenantSlug
         * @param {TVODPriceResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTvodPrice: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.createTvodPrice(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get a paid content refund
         * @summary Get a paid content refund
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentRefund: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.getContentRefund(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get list paid content refund
         * @summary Get list paid content refund
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentRefunds: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
            return localVarFp.getContentRefunds(tenantSlug, search, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get list of subscription product resource
         * @summary Get list of subscription product resource
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListSubscriptionProductResource: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
            return localVarFp.getListSubscriptionProductResource(tenantSlug, search, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get one Redeem Subscription
         * @summary Get one Redeem Subscription
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedeemSubscription: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.getRedeemSubscription(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List all Redeem Subscriptions
         * @summary List all Redeem Subscriptions
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedeemSubscriptions: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
            return localVarFp.getRedeemSubscriptions(tenantSlug, search, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get subscription product resource
         * @summary Get subscription product resource
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionProductResource: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.getSubscriptionProductResource(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get Subscription Tier
         * @summary Get Subscription Tier
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionTier: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.getSubscriptionTier(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get subscription tiers
         * @summary Get subscription tiers
         * @param {string} tenantSlug
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionTiers: function (tenantSlug, ordering, page, pageSize, authorization, acceptLanguage, options) {
            return localVarFp.getSubscriptionTiers(tenantSlug, ordering, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get a TVOD price
         * @summary Get a TVOD price
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {'normal' | 'tvod_credit'} [tvodType] TVOD Price type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvodPriceDetail: function (id, tenantSlug, authorization, acceptLanguage, select, tvodType, options) {
            return localVarFp.getTvodPriceDetail(id, tenantSlug, authorization, acceptLanguage, select, tvodType, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List all TVOD prices
         * @summary List all TVOD prices
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {'normal' | 'tvod_credit'} [type] TVOD Price type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvodPrices: function (tenantSlug, search, ordering, page, pageSize, authorization, acceptLanguage, select, type, options) {
            return localVarFp.getTvodPrices(tenantSlug, search, ordering, page, pageSize, authorization, acceptLanguage, select, type, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete a tvod price
         * @summary Delete a tvod price
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTvodPrice: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.removeTvodPrice(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update a paid content refund
         * @summary Update a paid content refund
         * @param {string} id
         * @param {string} tenantSlug
         * @param {PaidContentRefund} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentRefund: function (id, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.updateContentRefund(id, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update a TVOD price
         * @summary Update a TVOD price
         * @param {string} id
         * @param {string} tenantSlug
         * @param {TVODPriceResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTvodPrice: function (id, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.updateTvodPrice(id, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.AdminPaymentApiFactory = AdminPaymentApiFactory;
/**
 * AdminPaymentApi - object-oriented interface
 * @export
 * @class AdminPaymentApi
 * @extends {BaseAPI}
 */
var AdminPaymentApi = /** @class */ (function (_super) {
    __extends(AdminPaymentApi, _super);
    function AdminPaymentApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Cancel user subscription
     * @summary Cancel user subscription
     * @param {string} tenantSlug
     * @param {CancelUserSubscriptionByAdminRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPaymentApi
     */
    AdminPaymentApi.prototype.cancelUserSubscription = function (tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminPaymentApiFp)(this.configuration).cancelUserSubscription(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create one paid content refund resource
     * @summary Create one  content refund resource
     * @param {string} tenantSlug
     * @param {PaidContentRefund} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPaymentApi
     */
    AdminPaymentApi.prototype.createContentRefund = function (tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminPaymentApiFp)(this.configuration).createContentRefund(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create a TVOD price
     * @summary Create a TVOD price
     * @param {string} tenantSlug
     * @param {TVODPriceResource} data
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPaymentApi
     */
    AdminPaymentApi.prototype.createTvodPrice = function (tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminPaymentApiFp)(this.configuration).createTvodPrice(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get a paid content refund
     * @summary Get a paid content refund
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPaymentApi
     */
    AdminPaymentApi.prototype.getContentRefund = function (id, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminPaymentApiFp)(this.configuration).getContentRefund(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get list paid content refund
     * @summary Get list paid content refund
     * @param {string} tenantSlug
     * @param {string} [search] A search term.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPaymentApi
     */
    AdminPaymentApi.prototype.getContentRefunds = function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminPaymentApiFp)(this.configuration).getContentRefunds(tenantSlug, search, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get list of subscription product resource
     * @summary Get list of subscription product resource
     * @param {string} tenantSlug
     * @param {string} [search] A search term.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPaymentApi
     */
    AdminPaymentApi.prototype.getListSubscriptionProductResource = function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminPaymentApiFp)(this.configuration).getListSubscriptionProductResource(tenantSlug, search, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get one Redeem Subscription
     * @summary Get one Redeem Subscription
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPaymentApi
     */
    AdminPaymentApi.prototype.getRedeemSubscription = function (id, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminPaymentApiFp)(this.configuration).getRedeemSubscription(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List all Redeem Subscriptions
     * @summary List all Redeem Subscriptions
     * @param {string} tenantSlug
     * @param {string} [search] A search term.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPaymentApi
     */
    AdminPaymentApi.prototype.getRedeemSubscriptions = function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminPaymentApiFp)(this.configuration).getRedeemSubscriptions(tenantSlug, search, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get subscription product resource
     * @summary Get subscription product resource
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPaymentApi
     */
    AdminPaymentApi.prototype.getSubscriptionProductResource = function (id, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminPaymentApiFp)(this.configuration).getSubscriptionProductResource(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get Subscription Tier
     * @summary Get Subscription Tier
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPaymentApi
     */
    AdminPaymentApi.prototype.getSubscriptionTier = function (id, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminPaymentApiFp)(this.configuration).getSubscriptionTier(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get subscription tiers
     * @summary Get subscription tiers
     * @param {string} tenantSlug
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPaymentApi
     */
    AdminPaymentApi.prototype.getSubscriptionTiers = function (tenantSlug, ordering, page, pageSize, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminPaymentApiFp)(this.configuration).getSubscriptionTiers(tenantSlug, ordering, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get a TVOD price
     * @summary Get a TVOD price
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {'normal' | 'tvod_credit'} [tvodType] TVOD Price type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPaymentApi
     */
    AdminPaymentApi.prototype.getTvodPriceDetail = function (id, tenantSlug, authorization, acceptLanguage, select, tvodType, options) {
        var _this = this;
        return (0, exports.AdminPaymentApiFp)(this.configuration).getTvodPriceDetail(id, tenantSlug, authorization, acceptLanguage, select, tvodType, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List all TVOD prices
     * @summary List all TVOD prices
     * @param {string} tenantSlug
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {'normal' | 'tvod_credit'} [type] TVOD Price type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPaymentApi
     */
    AdminPaymentApi.prototype.getTvodPrices = function (tenantSlug, search, ordering, page, pageSize, authorization, acceptLanguage, select, type, options) {
        var _this = this;
        return (0, exports.AdminPaymentApiFp)(this.configuration).getTvodPrices(tenantSlug, search, ordering, page, pageSize, authorization, acceptLanguage, select, type, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete a tvod price
     * @summary Delete a tvod price
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPaymentApi
     */
    AdminPaymentApi.prototype.removeTvodPrice = function (id, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminPaymentApiFp)(this.configuration).removeTvodPrice(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update a paid content refund
     * @summary Update a paid content refund
     * @param {string} id
     * @param {string} tenantSlug
     * @param {PaidContentRefund} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPaymentApi
     */
    AdminPaymentApi.prototype.updateContentRefund = function (id, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminPaymentApiFp)(this.configuration).updateContentRefund(id, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update a TVOD price
     * @summary Update a TVOD price
     * @param {string} id
     * @param {string} tenantSlug
     * @param {TVODPriceResource} data
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPaymentApi
     */
    AdminPaymentApi.prototype.updateTvodPrice = function (id, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminPaymentApiFp)(this.configuration).updateTvodPrice(id, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AdminPaymentApi;
}(base_1.BaseAPI));
exports.AdminPaymentApi = AdminPaymentApi;
