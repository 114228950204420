import { RibbonsItem } from '~features/ribbon/views/projectw/style/index';
import _ from 'lodash';

import { RecordContentDesktop } from '..';

function RibbonDeskTop(props) {
  const { ribbons, titleRibbon, timeFormat, vodlist, language, types, ribbonActive } = props;
  if (vodlist) {
    return (
      <RibbonsItem>
        {!_.isEmpty(ribbonActive) &&
          ribbonActive.items.map((item, index) => (
            <RecordContentDesktop {...props} item={item} index={index} />
          ))}
      </RibbonsItem>
    );
  }
  return (
    <RibbonsItem types={types}>
      {!_.isEmpty(titleRibbon) &&
        titleRibbon.map((item, index) => (
          <RecordContentDesktop {...props} item={item} index={index} />
        ))}
    </RibbonsItem>
  );
}

export default RibbonDeskTop;
