/* tslint:disable */
/* eslint-disable */
/**
 * ODX/V3 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentApi = exports.PaymentApiFactory = exports.PaymentApiFp = exports.PaymentApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * PaymentApi - axios parameter creator
 * @export
 */
var PaymentApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary admin coupon create
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3CouponAdminCreateInputSchema} [odxV3CouponAdminCreateInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCouponCreate: function (serviceName, debug, acceptLanguage, odxV3CouponAdminCreateInputSchema, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'serviceName' is not null or undefined
                            (0, common_1.assertParamExists)('adminCouponCreate', 'serviceName', serviceName);
                            localVarPath = "/payment/coupon/admin/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication RequiredToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication RequiredToken required
                            _a.sent();
                            if (debug !== undefined) {
                                localVarQueryParameter['debug'] = debug;
                            }
                            if (serviceName !== undefined && serviceName !== null) {
                                localVarHeaderParameter['Service-Name'] = String(serviceName);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(odxV3CouponAdminCreateInputSchema, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary admin coupon retrieve
         * @param {string} code
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCouponRetrieve: function (code, serviceName, debug, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'code' is not null or undefined
                            (0, common_1.assertParamExists)('adminCouponRetrieve', 'code', code);
                            // verify required parameter 'serviceName' is not null or undefined
                            (0, common_1.assertParamExists)('adminCouponRetrieve', 'serviceName', serviceName);
                            localVarPath = "/payment/coupon/admin/{code}/"
                                .replace("{".concat("code", "}"), encodeURIComponent(String(code)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication RequiredToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication RequiredToken required
                            _a.sent();
                            if (debug !== undefined) {
                                localVarQueryParameter['debug'] = debug;
                            }
                            if (serviceName !== undefined && serviceName !== null) {
                                localVarHeaderParameter['Service-Name'] = String(serviceName);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary payment-method list
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentMethodList: function (serviceName, debug, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'serviceName' is not null or undefined
                            (0, common_1.assertParamExists)('paymentMethodList', 'serviceName', serviceName);
                            localVarPath = "/payment/payment-method/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication RequiredToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication RequiredToken required
                            _a.sent();
                            if (debug !== undefined) {
                                localVarQueryParameter['debug'] = debug;
                            }
                            if (serviceName !== undefined && serviceName !== null) {
                                localVarHeaderParameter['Service-Name'] = String(serviceName);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary payment-method partial update
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3PaymentMethodUpdateInputSchema} [odxV3PaymentMethodUpdateInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentMethodPartialUpdate: function (serviceName, debug, acceptLanguage, odxV3PaymentMethodUpdateInputSchema, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'serviceName' is not null or undefined
                            (0, common_1.assertParamExists)('paymentMethodPartialUpdate', 'serviceName', serviceName);
                            localVarPath = "/payment/payment-method/update/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication RequiredToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication RequiredToken required
                            _a.sent();
                            if (debug !== undefined) {
                                localVarQueryParameter['debug'] = debug;
                            }
                            if (serviceName !== undefined && serviceName !== null) {
                                localVarHeaderParameter['Service-Name'] = String(serviceName);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(odxV3PaymentMethodUpdateInputSchema, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary product-subscription list
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {string} [productCode] The product code to filter by
         * @param {boolean} [isActive] Filter by active status. If true, the product list shown to the customer
         * @param {string} [paymentPlatform] The payment platform to filter by / &#x60;web&#x60;, &#x60;apple&#x60;, &#x60;apple-tv&#x60;, &#x60;google&#x60;, &#x60;google-tv&#x60;, &#x60;roku&#x60;
         * @param {string} [platform] The payment platform to filter by / &#x60;web&#x60;, &#x60;apple&#x60;, &#x60;apple-tv&#x60;, &#x60;google&#x60;, &#x60;google-tv&#x60;, &#x60;roku&#x60;, &#x60;samsung&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSubscriptionList: function (serviceName, debug, acceptLanguage, productCode, isActive, paymentPlatform, platform, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'serviceName' is not null or undefined
                    (0, common_1.assertParamExists)('productSubscriptionList', 'serviceName', serviceName);
                    localVarPath = "/payment/product-subscription/";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (debug !== undefined) {
                        localVarQueryParameter['debug'] = debug;
                    }
                    if (productCode !== undefined) {
                        localVarQueryParameter['product_code'] = productCode;
                    }
                    if (isActive !== undefined) {
                        localVarQueryParameter['is_active'] = isActive;
                    }
                    if (paymentPlatform !== undefined) {
                        localVarQueryParameter['payment_platform'] = paymentPlatform;
                    }
                    if (platform !== undefined) {
                        localVarQueryParameter['platform'] = platform;
                    }
                    if (serviceName !== undefined && serviceName !== null) {
                        localVarHeaderParameter['Service-Name'] = String(serviceName);
                    }
                    if (acceptLanguage !== undefined && acceptLanguage !== null) {
                        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary product-subscription retrieve
         * @param {'ODK-PREMIUM-1-MONTH' | 'ODK-PREMIUM-1-YEAR' | 'ODK-PREMIUM-SAMSUNG-1-MONTH' | 'ODK-PREMIUM-SAMSUNG-1-YEAR'} productCode
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSubscriptionRetrieve: function (productCode, serviceName, debug, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'productCode' is not null or undefined
                    (0, common_1.assertParamExists)('productSubscriptionRetrieve', 'productCode', productCode);
                    // verify required parameter 'serviceName' is not null or undefined
                    (0, common_1.assertParamExists)('productSubscriptionRetrieve', 'serviceName', serviceName);
                    localVarPath = "/payment/product-subscription/{product_code}/"
                        .replace("{".concat("product_code", "}"), encodeURIComponent(String(productCode)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (debug !== undefined) {
                        localVarQueryParameter['debug'] = debug;
                    }
                    if (serviceName !== undefined && serviceName !== null) {
                        localVarHeaderParameter['Service-Name'] = String(serviceName);
                    }
                    if (acceptLanguage !== undefined && acceptLanguage !== null) {
                        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary recurly coupon retrieve
         * @param {'ODK-PREMIUM-1-MONTH' | 'ODK-PREMIUM-1-YEAR'} productCode
         * @param {string} couponCode
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recurlyCouponRetrieve: function (productCode, couponCode, serviceName, debug, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'productCode' is not null or undefined
                            (0, common_1.assertParamExists)('recurlyCouponRetrieve', 'productCode', productCode);
                            // verify required parameter 'couponCode' is not null or undefined
                            (0, common_1.assertParamExists)('recurlyCouponRetrieve', 'couponCode', couponCode);
                            // verify required parameter 'serviceName' is not null or undefined
                            (0, common_1.assertParamExists)('recurlyCouponRetrieve', 'serviceName', serviceName);
                            localVarPath = "/payment/coupon/recurly/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication RequiredToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication RequiredToken required
                            _a.sent();
                            if (productCode !== undefined) {
                                localVarQueryParameter['product_code'] = productCode;
                            }
                            if (couponCode !== undefined) {
                                localVarQueryParameter['coupon_code'] = couponCode;
                            }
                            if (debug !== undefined) {
                                localVarQueryParameter['debug'] = debug;
                            }
                            if (serviceName !== undefined && serviceName !== null) {
                                localVarHeaderParameter['Service-Name'] = String(serviceName);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary public-key list
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recurlyPublicKeyList: function (serviceName, debug, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'serviceName' is not null or undefined
                            (0, common_1.assertParamExists)('recurlyPublicKeyList', 'serviceName', serviceName);
                            localVarPath = "/payment/public-key/recurly/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication RequiredToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication RequiredToken required
                            _a.sent();
                            if (debug !== undefined) {
                                localVarQueryParameter['debug'] = debug;
                            }
                            if (serviceName !== undefined && serviceName !== null) {
                                localVarHeaderParameter['Service-Name'] = String(serviceName);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary register user and roku customer id relation
         * @param {OdkRokuUserRegisterSchema} [odkRokuUserRegisterSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerRokuUser: function (odkRokuUserRegisterSchema, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/roku/user/";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(odkRokuUserRegisterSchema, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary subscription cancel
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionCancelCreate: function (serviceName, debug, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'serviceName' is not null or undefined
                            (0, common_1.assertParamExists)('subscriptionCancelCreate', 'serviceName', serviceName);
                            localVarPath = "/payment/subscription/cancel/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication RequiredToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication RequiredToken required
                            _a.sent();
                            if (debug !== undefined) {
                                localVarQueryParameter['debug'] = debug;
                            }
                            if (serviceName !== undefined && serviceName !== null) {
                                localVarHeaderParameter['Service-Name'] = String(serviceName);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary subscription change
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3SubscriptionChangeInputSchema} [odxV3SubscriptionChangeInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionChangeCreate: function (serviceName, debug, acceptLanguage, odxV3SubscriptionChangeInputSchema, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'serviceName' is not null or undefined
                            (0, common_1.assertParamExists)('subscriptionChangeCreate', 'serviceName', serviceName);
                            localVarPath = "/payment/subscription/change/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication RequiredToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication RequiredToken required
                            _a.sent();
                            if (debug !== undefined) {
                                localVarQueryParameter['debug'] = debug;
                            }
                            if (serviceName !== undefined && serviceName !== null) {
                                localVarHeaderParameter['Service-Name'] = String(serviceName);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(odxV3SubscriptionChangeInputSchema, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Information about the next subscription. <br> A response status of 404 means that there is no next subscription.
         * @summary subscription renewal
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionChangeGet: function (serviceName, debug, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'serviceName' is not null or undefined
                            (0, common_1.assertParamExists)('subscriptionChangeGet', 'serviceName', serviceName);
                            localVarPath = "/payment/subscription/renewal/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication RequiredToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication RequiredToken required
                            _a.sent();
                            if (debug !== undefined) {
                                localVarQueryParameter['debug'] = debug;
                            }
                            if (serviceName !== undefined && serviceName !== null) {
                                localVarHeaderParameter['Service-Name'] = String(serviceName);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary subscription create
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3SubscriptionCreateInputSchema} [odxV3SubscriptionCreateInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionCreate: function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateInputSchema, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'serviceName' is not null or undefined
                            (0, common_1.assertParamExists)('subscriptionCreate', 'serviceName', serviceName);
                            localVarPath = "/payment/subscription/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication RequiredToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication RequiredToken required
                            _a.sent();
                            if (debug !== undefined) {
                                localVarQueryParameter['debug'] = debug;
                            }
                            if (serviceName !== undefined && serviceName !== null) {
                                localVarHeaderParameter['Service-Name'] = String(serviceName);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(odxV3SubscriptionCreateInputSchema, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary subscription create apple
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3SubscriptionCreateAppleInputSchema} [odxV3SubscriptionCreateAppleInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionCreateApple: function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateAppleInputSchema, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'serviceName' is not null or undefined
                            (0, common_1.assertParamExists)('subscriptionCreateApple', 'serviceName', serviceName);
                            localVarPath = "/payment/subscription/apple";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication RequiredToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication RequiredToken required
                            _a.sent();
                            if (debug !== undefined) {
                                localVarQueryParameter['debug'] = debug;
                            }
                            if (serviceName !== undefined && serviceName !== null) {
                                localVarHeaderParameter['Service-Name'] = String(serviceName);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(odxV3SubscriptionCreateAppleInputSchema, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary subscription create apple-tv
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3SubscriptionCreateAppleInputSchema} [odxV3SubscriptionCreateAppleInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionCreateAppleTv: function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateAppleInputSchema, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'serviceName' is not null or undefined
                            (0, common_1.assertParamExists)('subscriptionCreateAppleTv', 'serviceName', serviceName);
                            localVarPath = "/payment/subscription/apple-tv";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication RequiredToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication RequiredToken required
                            _a.sent();
                            if (debug !== undefined) {
                                localVarQueryParameter['debug'] = debug;
                            }
                            if (serviceName !== undefined && serviceName !== null) {
                                localVarHeaderParameter['Service-Name'] = String(serviceName);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(odxV3SubscriptionCreateAppleInputSchema, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary subscription create google
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3SubscriptionCreateGoogleInputSchema} [odxV3SubscriptionCreateGoogleInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionCreateGoogle: function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'serviceName' is not null or undefined
                            (0, common_1.assertParamExists)('subscriptionCreateGoogle', 'serviceName', serviceName);
                            localVarPath = "/payment/subscription/google";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication RequiredToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication RequiredToken required
                            _a.sent();
                            if (debug !== undefined) {
                                localVarQueryParameter['debug'] = debug;
                            }
                            if (serviceName !== undefined && serviceName !== null) {
                                localVarHeaderParameter['Service-Name'] = String(serviceName);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(odxV3SubscriptionCreateGoogleInputSchema, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary subscription create google-tv
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3SubscriptionCreateGoogleInputSchema} [odxV3SubscriptionCreateGoogleInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionCreateGoogleTv: function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'serviceName' is not null or undefined
                            (0, common_1.assertParamExists)('subscriptionCreateGoogleTv', 'serviceName', serviceName);
                            localVarPath = "/payment/subscription/google-tv";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication RequiredToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication RequiredToken required
                            _a.sent();
                            if (debug !== undefined) {
                                localVarQueryParameter['debug'] = debug;
                            }
                            if (serviceName !== undefined && serviceName !== null) {
                                localVarHeaderParameter['Service-Name'] = String(serviceName);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(odxV3SubscriptionCreateGoogleInputSchema, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary subscription update google
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3SubscriptionCreateGoogleInputSchema} [odxV3SubscriptionCreateGoogleInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionUpdateGoogle: function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'serviceName' is not null or undefined
                            (0, common_1.assertParamExists)('subscriptionUpdateGoogle', 'serviceName', serviceName);
                            localVarPath = "/payment/subscription/google";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication RequiredToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication RequiredToken required
                            _a.sent();
                            if (debug !== undefined) {
                                localVarQueryParameter['debug'] = debug;
                            }
                            if (serviceName !== undefined && serviceName !== null) {
                                localVarHeaderParameter['Service-Name'] = String(serviceName);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(odxV3SubscriptionCreateGoogleInputSchema, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary subscription update google-tv
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3SubscriptionCreateGoogleInputSchema} [odxV3SubscriptionCreateGoogleInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionUpdateGoogleTv: function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'serviceName' is not null or undefined
                            (0, common_1.assertParamExists)('subscriptionUpdateGoogleTv', 'serviceName', serviceName);
                            localVarPath = "/payment/subscription/google-tv";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication RequiredToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication RequiredToken required
                            _a.sent();
                            if (debug !== undefined) {
                                localVarQueryParameter['debug'] = debug;
                            }
                            if (serviceName !== undefined && serviceName !== null) {
                                localVarHeaderParameter['Service-Name'] = String(serviceName);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(odxV3SubscriptionCreateGoogleInputSchema, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PaymentApiAxiosParamCreator = PaymentApiAxiosParamCreator;
/**
 * PaymentApi - functional programming interface
 * @export
 */
var PaymentApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.PaymentApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary admin coupon create
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3CouponAdminCreateInputSchema} [odxV3CouponAdminCreateInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCouponCreate: function (serviceName, debug, acceptLanguage, odxV3CouponAdminCreateInputSchema, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.adminCouponCreate(serviceName, debug, acceptLanguage, odxV3CouponAdminCreateInputSchema, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary admin coupon retrieve
         * @param {string} code
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCouponRetrieve: function (code, serviceName, debug, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.adminCouponRetrieve(code, serviceName, debug, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary payment-method list
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentMethodList: function (serviceName, debug, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.paymentMethodList(serviceName, debug, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary payment-method partial update
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3PaymentMethodUpdateInputSchema} [odxV3PaymentMethodUpdateInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentMethodPartialUpdate: function (serviceName, debug, acceptLanguage, odxV3PaymentMethodUpdateInputSchema, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.paymentMethodPartialUpdate(serviceName, debug, acceptLanguage, odxV3PaymentMethodUpdateInputSchema, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary product-subscription list
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {string} [productCode] The product code to filter by
         * @param {boolean} [isActive] Filter by active status. If true, the product list shown to the customer
         * @param {string} [paymentPlatform] The payment platform to filter by / &#x60;web&#x60;, &#x60;apple&#x60;, &#x60;apple-tv&#x60;, &#x60;google&#x60;, &#x60;google-tv&#x60;, &#x60;roku&#x60;
         * @param {string} [platform] The payment platform to filter by / &#x60;web&#x60;, &#x60;apple&#x60;, &#x60;apple-tv&#x60;, &#x60;google&#x60;, &#x60;google-tv&#x60;, &#x60;roku&#x60;, &#x60;samsung&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSubscriptionList: function (serviceName, debug, acceptLanguage, productCode, isActive, paymentPlatform, platform, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.productSubscriptionList(serviceName, debug, acceptLanguage, productCode, isActive, paymentPlatform, platform, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary product-subscription retrieve
         * @param {'ODK-PREMIUM-1-MONTH' | 'ODK-PREMIUM-1-YEAR' | 'ODK-PREMIUM-SAMSUNG-1-MONTH' | 'ODK-PREMIUM-SAMSUNG-1-YEAR'} productCode
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSubscriptionRetrieve: function (productCode, serviceName, debug, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.productSubscriptionRetrieve(productCode, serviceName, debug, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary recurly coupon retrieve
         * @param {'ODK-PREMIUM-1-MONTH' | 'ODK-PREMIUM-1-YEAR'} productCode
         * @param {string} couponCode
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recurlyCouponRetrieve: function (productCode, couponCode, serviceName, debug, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.recurlyCouponRetrieve(productCode, couponCode, serviceName, debug, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary public-key list
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recurlyPublicKeyList: function (serviceName, debug, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.recurlyPublicKeyList(serviceName, debug, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary register user and roku customer id relation
         * @param {OdkRokuUserRegisterSchema} [odkRokuUserRegisterSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerRokuUser: function (odkRokuUserRegisterSchema, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.registerRokuUser(odkRokuUserRegisterSchema, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary subscription cancel
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionCancelCreate: function (serviceName, debug, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.subscriptionCancelCreate(serviceName, debug, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary subscription change
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3SubscriptionChangeInputSchema} [odxV3SubscriptionChangeInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionChangeCreate: function (serviceName, debug, acceptLanguage, odxV3SubscriptionChangeInputSchema, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.subscriptionChangeCreate(serviceName, debug, acceptLanguage, odxV3SubscriptionChangeInputSchema, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Information about the next subscription. <br> A response status of 404 means that there is no next subscription.
         * @summary subscription renewal
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionChangeGet: function (serviceName, debug, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.subscriptionChangeGet(serviceName, debug, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary subscription create
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3SubscriptionCreateInputSchema} [odxV3SubscriptionCreateInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionCreate: function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateInputSchema, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.subscriptionCreate(serviceName, debug, acceptLanguage, odxV3SubscriptionCreateInputSchema, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary subscription create apple
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3SubscriptionCreateAppleInputSchema} [odxV3SubscriptionCreateAppleInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionCreateApple: function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateAppleInputSchema, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.subscriptionCreateApple(serviceName, debug, acceptLanguage, odxV3SubscriptionCreateAppleInputSchema, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary subscription create apple-tv
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3SubscriptionCreateAppleInputSchema} [odxV3SubscriptionCreateAppleInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionCreateAppleTv: function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateAppleInputSchema, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.subscriptionCreateAppleTv(serviceName, debug, acceptLanguage, odxV3SubscriptionCreateAppleInputSchema, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary subscription create google
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3SubscriptionCreateGoogleInputSchema} [odxV3SubscriptionCreateGoogleInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionCreateGoogle: function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.subscriptionCreateGoogle(serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary subscription create google-tv
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3SubscriptionCreateGoogleInputSchema} [odxV3SubscriptionCreateGoogleInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionCreateGoogleTv: function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.subscriptionCreateGoogleTv(serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary subscription update google
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3SubscriptionCreateGoogleInputSchema} [odxV3SubscriptionCreateGoogleInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionUpdateGoogle: function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.subscriptionUpdateGoogle(serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary subscription update google-tv
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3SubscriptionCreateGoogleInputSchema} [odxV3SubscriptionCreateGoogleInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionUpdateGoogleTv: function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.subscriptionUpdateGoogleTv(serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.PaymentApiFp = PaymentApiFp;
/**
 * PaymentApi - factory interface
 * @export
 */
var PaymentApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.PaymentApiFp)(configuration);
    return {
        /**
         *
         * @summary admin coupon create
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3CouponAdminCreateInputSchema} [odxV3CouponAdminCreateInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCouponCreate: function (serviceName, debug, acceptLanguage, odxV3CouponAdminCreateInputSchema, options) {
            return localVarFp.adminCouponCreate(serviceName, debug, acceptLanguage, odxV3CouponAdminCreateInputSchema, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary admin coupon retrieve
         * @param {string} code
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCouponRetrieve: function (code, serviceName, debug, acceptLanguage, options) {
            return localVarFp.adminCouponRetrieve(code, serviceName, debug, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary payment-method list
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentMethodList: function (serviceName, debug, acceptLanguage, options) {
            return localVarFp.paymentMethodList(serviceName, debug, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary payment-method partial update
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3PaymentMethodUpdateInputSchema} [odxV3PaymentMethodUpdateInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentMethodPartialUpdate: function (serviceName, debug, acceptLanguage, odxV3PaymentMethodUpdateInputSchema, options) {
            return localVarFp.paymentMethodPartialUpdate(serviceName, debug, acceptLanguage, odxV3PaymentMethodUpdateInputSchema, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary product-subscription list
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {string} [productCode] The product code to filter by
         * @param {boolean} [isActive] Filter by active status. If true, the product list shown to the customer
         * @param {string} [paymentPlatform] The payment platform to filter by / &#x60;web&#x60;, &#x60;apple&#x60;, &#x60;apple-tv&#x60;, &#x60;google&#x60;, &#x60;google-tv&#x60;, &#x60;roku&#x60;
         * @param {string} [platform] The payment platform to filter by / &#x60;web&#x60;, &#x60;apple&#x60;, &#x60;apple-tv&#x60;, &#x60;google&#x60;, &#x60;google-tv&#x60;, &#x60;roku&#x60;, &#x60;samsung&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSubscriptionList: function (serviceName, debug, acceptLanguage, productCode, isActive, paymentPlatform, platform, options) {
            return localVarFp.productSubscriptionList(serviceName, debug, acceptLanguage, productCode, isActive, paymentPlatform, platform, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary product-subscription retrieve
         * @param {'ODK-PREMIUM-1-MONTH' | 'ODK-PREMIUM-1-YEAR' | 'ODK-PREMIUM-SAMSUNG-1-MONTH' | 'ODK-PREMIUM-SAMSUNG-1-YEAR'} productCode
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSubscriptionRetrieve: function (productCode, serviceName, debug, acceptLanguage, options) {
            return localVarFp.productSubscriptionRetrieve(productCode, serviceName, debug, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary recurly coupon retrieve
         * @param {'ODK-PREMIUM-1-MONTH' | 'ODK-PREMIUM-1-YEAR'} productCode
         * @param {string} couponCode
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recurlyCouponRetrieve: function (productCode, couponCode, serviceName, debug, acceptLanguage, options) {
            return localVarFp.recurlyCouponRetrieve(productCode, couponCode, serviceName, debug, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary public-key list
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recurlyPublicKeyList: function (serviceName, debug, acceptLanguage, options) {
            return localVarFp.recurlyPublicKeyList(serviceName, debug, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary register user and roku customer id relation
         * @param {OdkRokuUserRegisterSchema} [odkRokuUserRegisterSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerRokuUser: function (odkRokuUserRegisterSchema, options) {
            return localVarFp.registerRokuUser(odkRokuUserRegisterSchema, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary subscription cancel
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionCancelCreate: function (serviceName, debug, acceptLanguage, options) {
            return localVarFp.subscriptionCancelCreate(serviceName, debug, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary subscription change
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3SubscriptionChangeInputSchema} [odxV3SubscriptionChangeInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionChangeCreate: function (serviceName, debug, acceptLanguage, odxV3SubscriptionChangeInputSchema, options) {
            return localVarFp.subscriptionChangeCreate(serviceName, debug, acceptLanguage, odxV3SubscriptionChangeInputSchema, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Information about the next subscription. <br> A response status of 404 means that there is no next subscription.
         * @summary subscription renewal
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionChangeGet: function (serviceName, debug, acceptLanguage, options) {
            return localVarFp.subscriptionChangeGet(serviceName, debug, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary subscription create
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3SubscriptionCreateInputSchema} [odxV3SubscriptionCreateInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionCreate: function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateInputSchema, options) {
            return localVarFp.subscriptionCreate(serviceName, debug, acceptLanguage, odxV3SubscriptionCreateInputSchema, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary subscription create apple
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3SubscriptionCreateAppleInputSchema} [odxV3SubscriptionCreateAppleInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionCreateApple: function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateAppleInputSchema, options) {
            return localVarFp.subscriptionCreateApple(serviceName, debug, acceptLanguage, odxV3SubscriptionCreateAppleInputSchema, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary subscription create apple-tv
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3SubscriptionCreateAppleInputSchema} [odxV3SubscriptionCreateAppleInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionCreateAppleTv: function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateAppleInputSchema, options) {
            return localVarFp.subscriptionCreateAppleTv(serviceName, debug, acceptLanguage, odxV3SubscriptionCreateAppleInputSchema, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary subscription create google
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3SubscriptionCreateGoogleInputSchema} [odxV3SubscriptionCreateGoogleInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionCreateGoogle: function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options) {
            return localVarFp.subscriptionCreateGoogle(serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary subscription create google-tv
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3SubscriptionCreateGoogleInputSchema} [odxV3SubscriptionCreateGoogleInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionCreateGoogleTv: function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options) {
            return localVarFp.subscriptionCreateGoogleTv(serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary subscription update google
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3SubscriptionCreateGoogleInputSchema} [odxV3SubscriptionCreateGoogleInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionUpdateGoogle: function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options) {
            return localVarFp.subscriptionUpdateGoogle(serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary subscription update google-tv
         * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3SubscriptionCreateGoogleInputSchema} [odxV3SubscriptionCreateGoogleInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionUpdateGoogleTv: function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options) {
            return localVarFp.subscriptionUpdateGoogleTv(serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PaymentApiFactory = PaymentApiFactory;
/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
var PaymentApi = /** @class */ (function (_super) {
    __extends(PaymentApi, _super);
    function PaymentApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary admin coupon create
     * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
     * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
     * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
     * @param {OdxV3CouponAdminCreateInputSchema} [odxV3CouponAdminCreateInputSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.adminCouponCreate = function (serviceName, debug, acceptLanguage, odxV3CouponAdminCreateInputSchema, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).adminCouponCreate(serviceName, debug, acceptLanguage, odxV3CouponAdminCreateInputSchema, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary admin coupon retrieve
     * @param {string} code
     * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
     * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
     * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.adminCouponRetrieve = function (code, serviceName, debug, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).adminCouponRetrieve(code, serviceName, debug, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary payment-method list
     * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
     * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
     * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.paymentMethodList = function (serviceName, debug, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).paymentMethodList(serviceName, debug, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary payment-method partial update
     * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
     * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
     * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
     * @param {OdxV3PaymentMethodUpdateInputSchema} [odxV3PaymentMethodUpdateInputSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.paymentMethodPartialUpdate = function (serviceName, debug, acceptLanguage, odxV3PaymentMethodUpdateInputSchema, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).paymentMethodPartialUpdate(serviceName, debug, acceptLanguage, odxV3PaymentMethodUpdateInputSchema, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary product-subscription list
     * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
     * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
     * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
     * @param {string} [productCode] The product code to filter by
     * @param {boolean} [isActive] Filter by active status. If true, the product list shown to the customer
     * @param {string} [paymentPlatform] The payment platform to filter by / &#x60;web&#x60;, &#x60;apple&#x60;, &#x60;apple-tv&#x60;, &#x60;google&#x60;, &#x60;google-tv&#x60;, &#x60;roku&#x60;
     * @param {string} [platform] The payment platform to filter by / &#x60;web&#x60;, &#x60;apple&#x60;, &#x60;apple-tv&#x60;, &#x60;google&#x60;, &#x60;google-tv&#x60;, &#x60;roku&#x60;, &#x60;samsung&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.productSubscriptionList = function (serviceName, debug, acceptLanguage, productCode, isActive, paymentPlatform, platform, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).productSubscriptionList(serviceName, debug, acceptLanguage, productCode, isActive, paymentPlatform, platform, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary product-subscription retrieve
     * @param {'ODK-PREMIUM-1-MONTH' | 'ODK-PREMIUM-1-YEAR' | 'ODK-PREMIUM-SAMSUNG-1-MONTH' | 'ODK-PREMIUM-SAMSUNG-1-YEAR'} productCode
     * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
     * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
     * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.productSubscriptionRetrieve = function (productCode, serviceName, debug, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).productSubscriptionRetrieve(productCode, serviceName, debug, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary recurly coupon retrieve
     * @param {'ODK-PREMIUM-1-MONTH' | 'ODK-PREMIUM-1-YEAR'} productCode
     * @param {string} couponCode
     * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
     * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
     * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.recurlyCouponRetrieve = function (productCode, couponCode, serviceName, debug, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).recurlyCouponRetrieve(productCode, couponCode, serviceName, debug, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary public-key list
     * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
     * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
     * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.recurlyPublicKeyList = function (serviceName, debug, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).recurlyPublicKeyList(serviceName, debug, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary register user and roku customer id relation
     * @param {OdkRokuUserRegisterSchema} [odkRokuUserRegisterSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.registerRokuUser = function (odkRokuUserRegisterSchema, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).registerRokuUser(odkRokuUserRegisterSchema, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary subscription cancel
     * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
     * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
     * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.subscriptionCancelCreate = function (serviceName, debug, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).subscriptionCancelCreate(serviceName, debug, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary subscription change
     * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
     * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
     * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
     * @param {OdxV3SubscriptionChangeInputSchema} [odxV3SubscriptionChangeInputSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.subscriptionChangeCreate = function (serviceName, debug, acceptLanguage, odxV3SubscriptionChangeInputSchema, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).subscriptionChangeCreate(serviceName, debug, acceptLanguage, odxV3SubscriptionChangeInputSchema, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Information about the next subscription. <br> A response status of 404 means that there is no next subscription.
     * @summary subscription renewal
     * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
     * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
     * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.subscriptionChangeGet = function (serviceName, debug, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).subscriptionChangeGet(serviceName, debug, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary subscription create
     * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
     * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
     * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
     * @param {OdxV3SubscriptionCreateInputSchema} [odxV3SubscriptionCreateInputSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.subscriptionCreate = function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateInputSchema, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).subscriptionCreate(serviceName, debug, acceptLanguage, odxV3SubscriptionCreateInputSchema, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary subscription create apple
     * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
     * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
     * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
     * @param {OdxV3SubscriptionCreateAppleInputSchema} [odxV3SubscriptionCreateAppleInputSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.subscriptionCreateApple = function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateAppleInputSchema, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).subscriptionCreateApple(serviceName, debug, acceptLanguage, odxV3SubscriptionCreateAppleInputSchema, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary subscription create apple-tv
     * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
     * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
     * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
     * @param {OdxV3SubscriptionCreateAppleInputSchema} [odxV3SubscriptionCreateAppleInputSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.subscriptionCreateAppleTv = function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateAppleInputSchema, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).subscriptionCreateAppleTv(serviceName, debug, acceptLanguage, odxV3SubscriptionCreateAppleInputSchema, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary subscription create google
     * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
     * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
     * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
     * @param {OdxV3SubscriptionCreateGoogleInputSchema} [odxV3SubscriptionCreateGoogleInputSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.subscriptionCreateGoogle = function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).subscriptionCreateGoogle(serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary subscription create google-tv
     * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
     * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
     * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
     * @param {OdxV3SubscriptionCreateGoogleInputSchema} [odxV3SubscriptionCreateGoogleInputSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.subscriptionCreateGoogleTv = function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).subscriptionCreateGoogleTv(serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary subscription update google
     * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
     * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
     * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
     * @param {OdxV3SubscriptionCreateGoogleInputSchema} [odxV3SubscriptionCreateGoogleInputSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.subscriptionUpdateGoogle = function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).subscriptionUpdateGoogle(serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary subscription update google-tv
     * @param {'odk'} serviceName Only &#x60;\&quot;odk\&quot;&#x60; is supported.
     * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
     * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
     * @param {OdxV3SubscriptionCreateGoogleInputSchema} [odxV3SubscriptionCreateGoogleInputSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.subscriptionUpdateGoogleTv = function (serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).subscriptionUpdateGoogleTv(serviceName, debug, acceptLanguage, odxV3SubscriptionCreateGoogleInputSchema, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PaymentApi;
}(base_1.BaseAPI));
exports.PaymentApi = PaymentApi;
