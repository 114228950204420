import * as actions from './actions';
export { loading };

const loading = payload => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(actions.loading(payload));
      resolve();
    });
  };
};
