import * as actions from './actions';
import * as appAPIs from '../../api/appAPIs';
import * as apiHomePage from '~api/homePageApi';
import * as auth from '../../api/auth';
import * as reactedContent from '../../api/reactContent';
import { reject } from 'lodash';

export {
  getMenu,
  updateRibbonTarget,
  updateStatusRemove,
  updateMenu,
  setTopTenContentsByPageSlug,
  getLinkPlayOfTopTenContent,
};

function getMenu(response) {
  return dispatch => {
    dispatch(actions.getMenu(response));
  };
}

function updateMenu() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_menu()
        .then(response => {
          dispatch(actions.getMenu(response));
          resolve(response);
        })
        .catch(err => {
          console.log('response', err);
          reject(err);
        });
    });
  };
}

function updateRibbonTarget(id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (id) {
        dispatch(actions.updateRibbonTarget(id));
      }
      resolve();
    });
  };
}

function updateStatusRemove(data) {
  return dispatch => {
    dispatch(actions.updateStatusRemove(data));
  };
}

const setTopTenContentsByPageSlug = pageSlug => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiHomePage
        .getTopTen(pageSlug)
        .then(response => {
          dispatch(actions.setTopTenContents(response));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};
export function getReactedContents() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      reactedContent
        .getReactedContent()
        .then(response => {
          dispatch(actions.updateReactedContent(response));
          resolve(response);
        })
        .catch(errors => {
          reject(errors);
        });
    });
  };
}

const getLinkPlayOfTopTenContent = contentId => {
  return new Promise((resolve, reject) => {
    apiHomePage
      .getDetailContentById(contentId)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getViewContentsByIds = (ids = []) => {
  return new Promise((resolve, reject) => {
    apiHomePage
      .getViewContentsByIds(ids)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getViews = slug => {
  return new Promise((resolve, reject) => {
    apiHomePage
      .getViews(slug)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const resetPageData = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(actions.resetPageData());
    });
  };
};
