import React, { useState, useEffect, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { FilterMobile } from '../styled/selectFilterMobile';
import { searchContents } from '~features/search/services';
import FilterListIcon from '@material-ui/icons/FilterList';
import SelectFilter from './selectFilter';
import FilterHistory from './../../../features/youtubePage/component/filterHistory';
import classNames from 'classnames';

const Filter = React.memo(props => {
  const {
    handleToggleFilter,
    handleRemoveAll,
    handleRemoveall,
    filterTenant,
    activeIDs,
    t,
    youtube,
  } = props;
  const [open, setOpen] = useState(false);
  const closeFilter = () => {
    setOpen(false);
    document.body.style.overflow = 'auto';
  };
  const openFilter = () => {
    setOpen(true);
    document.body.style.overflow = 'hidden';
  };

  return (
    <FilterMobile
      className={classNames('filterMobile', 'isMobile', youtube && 'filterMobile-youtube')}
      // props={{ isSuggest: itemsSuggest.length }}
    >
      <div
        className="filterMobile--toggle"
        onClick={() => {
          openFilter();
        }}
      >
        <FilterListIcon />
        {t('filter.filterFilm')}
      </div>
      {youtube ? (
        <React.Fragment>
          <FilterHistory selectFilter={select => handleToggleFilter(null, select)} />
        </React.Fragment>
      ) : null}
      <div className={`filterMobile__wrap ${open && 'filterMobile--visible'}`}>
        <aside className="filterMobile">
          <div className="filterMobile__head">
            <span>{t('filter.filterFilm')}</span>
            <div className="filterMobile__closeButton" onClick={() => closeFilter()}>
              <CloseIcon />
            </div>
          </div>
          <div className="filterMobile--body">
            <SelectFilter
              handleToggleFilter={(event, select, blockFilter) =>
                handleToggleFilter(event, select, blockFilter)
              }
              handleRemoveAll={() => handleRemoveAll()}
              handleRemoveall={handleRemoveall}
              closeFilter={() => closeFilter()}
              filterTenant={filterTenant}
              activeIDs={activeIDs}
              isMobile
              youtube={youtube}
            />
          </div>
        </aside>
      </div>
    </FilterMobile>
  );
});

const mapStateToProps = state => ({
  menu: state.home.menu,
  page: state.home.page,
  account: state.auth.account,
});

const mapDispatchToProps = {
  dispatchSearchContents: params => searchContents(params),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Filter)));
