/* tslint:disable */
/* eslint-disable */
/**
 * ODX/V3 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentMethodList200ResponseAllOfResultCardTypeEnum = void 0;
exports.PaymentMethodList200ResponseAllOfResultCardTypeEnum = {
    American_Express: 'American Express',
    Dankort: 'Dankort',
    Diners_Club: 'Diners Club',
    Discover: 'Discover',
    Forbrugsforeningen: 'Forbrugsforeningen',
    JCB: 'JCB',
    Laser: 'Laser',
    Maestro: 'Maestro',
    MasterCard: 'MasterCard',
    Test_Card: 'Test Card',
    Union_Pay: 'Union Pay',
    Unknown: 'Unknown',
    Visa: 'Visa',
    Tarjeta_Naranja: 'Tarjeta Naranja',
    unknown_default_open_api: '11184809'
};
