import React from 'react';
import './style/style.scss';
export const WarningAlert = props => {
  const { t } = props.component.props;
  return (
    <div className="vjs-age-restrictions__container">
      <div className="vjs-age-restrictions__wrap">
        <div className="vjs-age-restrictions__header">
          <p className="vjs-age-restrictions__header--txtWarning">
            {t('player.warning.txtHeader')}
          </p>
          <p className="vjs-age-restrictions__header--txtWarnings">
            {t('player.warning.txtTitle')}
          </p>
        </div>
        <div className="vjs-age-restrictions__body">
          <p className="vjs-age-restrictions__body--txtDescription">
            {t('player.warning.txtDescription')}
          </p>
        </div>
        <div className="vjs-age-restrictions__footer">
          <button onClick={props.ClickUnConfirm} className="btn UnConfirm">
            {t('player.warning.txtContentUnConfirm')}
          </button>

          <button onClick={props.ClickConfirm} className="btn Confirm">
            {t('player.warning.txtContentConfirm')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default WarningAlert;
