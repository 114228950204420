import React, { Component } from 'react';
import { Panel, Button, Alert, Col, Row, FormControl } from 'react-bootstrap';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import Resizer from 'react-image-file-resizer';
import jquery from 'jquery';

class ReportContents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataReport: '',
      dataChoice: [],
      activeClass: [],
      errMess: '',
      isComplete: false,
      selectedFile: '',
      isLoading: false,
      textComment: '',
      isEnd: false,
    };
  }
  componentDidMount() {
    const { dispatchGetReportTemplates } = this.props.Player.props;
    const { player } = this.props;
    dispatchGetReportTemplates().then(res => {
      this.setState({
        dataReport: res.results,
        selectedFile: '',
        textComment: '',
      });
    });
    player.on('ended', () => {
      this.setState({
        isEnd: true,
      });
    });
    player.on('playing', () => {
      this.setState({
        isEnd: false,
      });
    });
  }

  handleClick(item) {
    const { dataChoice } = this.state;
    if (dataChoice.includes(item.description)) {
      return this.setState({
        dataChoice: dataChoice.filter(items => items !== item.description),
        errMess: '',
      });
    }
    this.setState({
      dataChoice: [...dataChoice, item.description],
      errMess: '',
    });
  }

  handleClose() {
    const { videoPlayer } = this.props.Player;
    const { isComplete, isEnd } = this.state;
    const $player = jquery(videoPlayer.el_);
    $player.removeClass('vjs-report-open');
    if (isComplete) {
      this.setState({
        isComplete: false,
        dataChoice: [],
        selectedFile: '',
        textComment: '',
        errMess: '',
      });
    } else {
      this.setState({
        isComplete: false,
        errMess: '',
      });
    }
    if (!isEnd) {
      videoPlayer.play();
    }
  }

  resizeFile = file =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        1280,
        1280,
        'JPEG',
        90,
        0,
        blob => {
          const nameSplit = file.name.split('.');
          const typeSplit = blob.type.split('/');
          const name = `${nameSplit.length ? nameSplit[0] : ''}.${
            typeSplit.length >= 2 ? typeSplit[1] : ''
          }`;
          blob.name = name;
          this.setState({
            selectedFile: blob,
          });
          resolve(blob);
        },
        'blob',
      );
    });

  handlePostReport() {
    const { detailPage, dispatchUploadImage, t, account } = this.props.Player.props;
    const id = detailPage.id;
    const { dataChoice, selectedFile, textComment } = this.state;
    let trimTxtComment = textComment.trim();
    if (!account) {
      this.setState({
        errMess: t('report.txtErrorAcc'),
      });
      return;
    }
    if (textComment && !trimTxtComment) {
      this.setState({
        errMess: t('report.txtErrorSpace'),
      });
      return;
    }
    let comment = trimTxtComment && ` - ${trimTxtComment.toString()}`;
    let data = {
      description: dataChoice.join(` - `) + comment,
    };
    if (!selectedFile) {
      return this.apiReport(id, data);
    }
    this.setState(
      {
        isLoading: true,
      },
      () =>
        dispatchUploadImage(selectedFile)
          .then(res => {
            data = {
              ...data,
              media_url: res.url,
            };
            this.apiReport(id, data);
          })
          .catch(error => {
            this.setState({
              errMess: error.message,
              isLoading: false,
            });
          }),
    );
  }

  apiReport(id, data) {
    const { dispatchPostReport } = this.props.Player.props;
    this.setState(
      {
        isLoading: true,
      },
      () => {
        dispatchPostReport(id, data)
          .then(res => {
            this.setState({
              isComplete: true,
              isLoading: false,
            });
          })
          .catch(error => {
            this.setState({
              isLoading: false,
              errMess: error.message,
            });
            console.log('error', error);
          });
      },
    );
  }

  checkBtnDisable() {
    const { isLoading, dataChoice, textComment } = this.state;
    if (!isLoading && dataChoice.length === 0 && !textComment) {
      return true;
    }
    return false;
  }

  handleUploadFile(event) {
    const { t } = this.props.Player.props;
    this.setState({
      errMess: '',
    });
    if (!event.target.files[0]) {
      return;
    }
    let fileSize = event.target.files[0].size / 1024 / 1024;
    if (fileSize > 10) {
      this.setState({
        errMess: t('report.txtErrorFile'),
      });
      return;
    }
    this.resizeFile(event.target.files[0]);
    // this.setState({
    //   selectedFile: event.target.files[0]
    // })
  }

  onChangeText(e) {
    this.setState({
      textComment: e.target.value,
      errMess: '',
    });
  }

  _renderContent() {
    const { dataReport, dataChoice } = this.state;
    if (!dataReport) {
      return;
    }
    return dataReport.map((item, index) => {
      return (
        <Alert
          className={`panel-body-alert--question ${
            dataChoice.includes(item.description) ? 'active' : ''
          }`}
          key={index}
          onClick={() => this.handleClick(item)}
        >
          {item.description}
        </Alert>
      );
    });
  }
  _renderComplete() {
    const { t } = this.props.Player.props;
    return (
      <React.Fragment>
        <Panel.Heading style={{ width: '100%' }}>
          <Panel.Title componentClass="h3">{t('report.txtTitleComplete')}</Panel.Title>
          <div className="col-sm-12 col-md-8 panel-heading--description">
            {/* <p dangerouslySetInnerHTML={{ __html: t('report.txtDescription')}}> </p> */}
            <p> {t('report.txtDesComplete')}</p>
          </div>
        </Panel.Heading>
        <Panel.Body>
          <Row className="panel-body--row">
            <Col sm={6} md={4} lg={4} xs={12} className="panel-body--col">
              <Button
                className="panel-footer--btn panel-footer--back"
                onClick={() => this.handleClose()}
              >
                {t('report.btnComplete')}
              </Button>
            </Col>
          </Row>
        </Panel.Body>
      </React.Fragment>
    );
  }

  _renderCommnent() {
    const { t } = this.props.Player.props;
    const { selectedFile } = this.state;
    return (
      <React.Fragment>
        <Alert
          className={`panel-body-alert--question `}
          style={{ marginBottom: 0 }}
          data-toggle="collapse"
          data-target="#Orther"
          aria-expanded="false"
          aria-controls="Orther"
        >
          {t('report.txtOrther')}
        </Alert>
        <div className="collapse" id="Orther">
          <FormControl
            componentClass="textarea"
            name="comment"
            placeholder={t('report.placeholder.txtComment')}
            onChange={e => this.onChangeText(e)}
            // onChange={(e) => this._onChangeText('comment', e)}
            rows="4"
          />
          <div className="panel-body--inputFileBox">
            <input
              type="file"
              id="file"
              className="panel-body--inputFileBox--inputFile"
              onChange={e => this.handleUploadFile(e)}
              accept="image/*"
            />
            <label htmlFor="file">
              <span id="file-name" className="panel-body--inputFileBox--fileBox">
                {selectedFile && selectedFile.name
                  ? selectedFile.name
                  : t('report.placeholder.txtFile')}
              </span>
              <span className="panel-body--inputFileBox--fileButton">{t('report.btnInput')}</span>
            </label>
          </div>
        </div>
      </React.Fragment>
    );
  }
  _renderReport() {
    const { errMess, isLoading } = this.state;
    const { t } = this.props.Player.props;
    return (
      <React.Fragment>
        <Panel.Heading>
          <Panel.Title componentClass="h3">{t('report.txtTitle')}</Panel.Title>
          <div className="col-sm-12 col-md-8 panel-heading--description">
            {/* <p dangerouslySetInnerHTML={{ __html: t('report.txtDescription')}}> </p> */}
            <p> {t('report.txtDescription1')}</p>
            <p>{t('report.txtDescription2')}</p>
          </div>
        </Panel.Heading>
        <Panel.Body>
          <Row className="panel-body--row">
            <Col sm={8} md={8} lg={6} xs={12} className="panel-body--col">
              {this._renderContent()}
              {this._renderCommnent()}
              {!isLoading ? (
                <Button
                  className="panel-footer--btn"
                  onClick={() => this.handlePostReport()}
                  disabled={this.checkBtnDisable()}
                >
                  {t('report.btnSend')}
                </Button>
              ) : (
                <div className="panel-footer--loadding">
                  <CircularProgress color="#ffffff" />
                </div>
              )}
              <p style={{ color: '#ff0000', marginTop: 10 }}>{errMess}</p>
            </Col>
          </Row>
        </Panel.Body>
      </React.Fragment>
    );
  }

  render() {
    const { isComplete } = this.state;
    return (
      <PanelStyled className="v-reportContents">
        <Button className="v-reportContents--close" onClick={() => this.handleClose()}>
          <i className="fa fa-arrow-left" />
        </Button>
        {isComplete ? this._renderComplete() : this._renderReport()}
      </PanelStyled>
    );
  }
}

export const PanelStyled = styled(Panel)`
  &.v-reportContents {
    display: block;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    /* opacity: 0.8; */
    position: absolute;
    background-color: #1c1c1c;
    border: none;
    color: #fff;
    z-index: 1000;
    height: 100%;
    width: 100%;
    top: 0;
    overflow-y: auto;
    .panel {
      background: #1c1c1c;
      &-title {
        font-size: 2.25rem;
        color: #ffffff;
        font-weight: 600;
        text-transform: capitalize;
      }
      &-heading {
        background: #1c1c1c;
        display: block;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: none;
        &--description {
          margin-top: 1.625rem;
          color: #999999;
          font-size: 1.3rem;
          line-height: 1.5rem;
          p {
            margin-bottom: 1.625rem;
            font-weight: 100;
          }
        }
      }
      &-body,
      &-footer {
        width: 100%;
        &--row {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &--col {
          display: flex;
          flex-direction: column;
        }
        &-alert {
          &--question {
            background: #454545;
            color: #ffffff;
            font-size: 1.125rem;
            border: none;
            cursor: pointer;
            text-align: left;
            padding: 1.5rem;
            margin-bottom: 1rem;
            cursor: pointer;
            &[aria-expanded='true'],
            &.active {
              background: #2574d4;
            }
            &:hover,
            &:active {
              box-shadow: 0px 0px 0px 0px #2574d4;
            }
          }
        }
        .collapse {
          border: 1px solid #454545;
          border-top: none;
        }
        &--back {
          background: #2574d4;
          width: 100%;
        }
        &--btn {
          margin-top: 2rem;
          background: #000000;
          color: #ffffff;
          font-size: 1.35rem;
          border: 1px solid #454545;
          display: flex;
          flex-grow: 1;
          justify-content: center;
          padding: 1rem;
          &:hover,
          &:active,
          &:focus {
            border: #ffffff;
            background: #2574d4;
          }
        }
        &--loadding {
          margin-top: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &--inputFileBox {
          display: flex;
          align-items: center;
          justify-content: center;
          &--inputFile {
            display: none;
          }
          label {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-around;
            border-top: 1px solid #454545;
            padding: 1rem 0;
            width: 80%;
          }
          &--fileBox {
            display: inline-block;
            width: 65%;
            text-align: left;
            font-size: 1rem;
            word-break: break-all;
            font-weight: 100;
          }
          &--fileButton {
            background: #dadada;
            border-radius: 5px;
            border: 1px solid #dadada;
            padding: 0.5rem 1.5rem;
            color: #666666;
          }
        }

        input,
        textarea {
          background: #1c1c1c;
          color: #fff;
          border: none;
          font-size: 1rem;
          resize: none;
          width: 100%;
          padding: 2rem;
        }
      }
    }
  }
  .v-reportContents {
    &--close {
      position: absolute;
      top: 2%;
      left: 2%;
      padding: 1px 6px;
      &:hover,
      &:active,
      &:focus {
        background: none;
        color: #fff;
      }
      i {
        font-size: 1.5rem;
      }
    }
  }
  @media (min-width: 576px) {
    &.v-reportContents {
      .panel {
        &-body {
          &--back {
            width: 21.875rem;
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    &.v-reportContents {
      display: flex;
      .panel {
        &-heading {
          display: flex;
          flex-shrink: 0;
        }
        &-body {
          overflow-y: auto;
        }
      }
    }
  }

  @media (min-width: 992px) {
    .v-reportContents {
    }
  }
  @media (max-width: 1024px) and (orientation: landscape) {
    &.v-reportContents {
      display: block;
    }
  }
`;

export default ReportContents;
