import React, { Component, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment-duration-format';
import '~core/utils/moment/duration-format';
import 'moment/locale/vi';
import 'moment/locale/ko';
import 'moment/locale/es';

import { getConfigByKey } from '~features/tenantConfig/services';
import { detectTheme } from '~features/theme/useTheme';
import * as authApi from '~features/auth/services';
import { PageLoading } from '~components/loading';
import OverlayLoading from '~components/loading/overlayLoading';
import BlockCountry from '~features/blockCountry';
import AdsBanner from '~components/adsBanner/views/index';
import { setIsHomePage, showMenu } from '~components/global/globalServices';
import classnames from 'classnames';
import * as method from '../functions/method-login';
import { setUserProperties } from '~root/services/analytics/SetUserProperties';
import onsignalService from '~root/services/onesignal/onesignalServices';
import initGTM from '~root/services/gtm/gtmServices';
import firebaseService from '~root/services/firebase/firebase';
import pushAnalytics from '../../services/analytics/Analytics';
import { AppStyled } from './styled';
import { tenantEnvConfig } from '~constants/tenantConfig/tenantEnvConfig';
import { initialApp, getRegionSubcription } from '../services';
import { setPreviousLocation } from '../../features/history/services';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';
import 'smart-app-banner/dist/smart-app-banner.css';

import './main.scss';
import $ from 'jquery';

import { gtmVar_setHasSubscription, gtmVar_setIsLoggedIn } from '~utils/gtm';
import { getAccountInfo } from '~features/profile/services';
import DefaultRouter, { BlockCountryHasHeaderFooter } from '../components/render-router';
import { withTranslation } from 'react-i18next';
import loadable from '@loadable/component';
import { getLocaleFromUrl } from '~utils/locales';
import { getFavorite } from '~features/myList/services';
import { getReactedContents } from '~features/homepage/services';
import { TENANT_NAME_ENUM } from '~core/constants';
import _ from 'lodash';

window.jQuery = $;
window.$ = $;
global.jQuery = $;
require('bootstrap')


const LoginForm = loadable(() => import('~features/auth/components/loginForm'));
const RegisterForm = loadable(() => import('~features/auth/components/registerForm'));
const ResetPasswordForm = loadable(() => import('~features/auth/components/resetPasswordForm'));
const MessagePopup = loadable(() => import('~features/auth/components/messagePopup'));

const Toast = loadable(() => import('~components/toast/index'));
const Backdrop = loadable(() => import('~components/backdrop/index'));

class App extends Component {
  constructor(props) {
    super(props);
    this.myInterval = null;
    this.countCheckSlugDomain = 0;
    this.initConfig = false;
    this.props.dispatchInitialApp();
    this.listAllowVerticalAds = [
      '',
      'page',
      'youtube',
      'landing',
      'search',
      'favorite',
      'genres',
      'nationalities',
      'directors',
      'casts',
      'producers',
      'profile',
    ];
    this.state = {
      showLoginModal: false,
      showRegisterModal: false,
      showResetPasswordModal: false,
      showMessagePopup: false,
      param: null,
      BlockCountry: false,
      isAdsVertical: window.innerWidth > 1891,
      allowVerticalAds: false,
    };
  }

  _initConfigs() {
    if (!getConfigByKey('feature_advanced_web_multitenancy_enabled')) {
      return;
    }
    firebaseService
      .initialize()
      .then(() => {
        setUserProperties();
      })
      .catch(() => {});
    onsignalService.initialize(this);
    initGTM();
  }

  _navigate = ({ location, action }) => {
    const { dpGetAccountInfo, account } = this.props;

    if (getConfigByKey('ui_theme') !== TENANT_NAME_ENUM.PROJECTW && account) {
      dpGetAccountInfo().then().catch();
    }

    pushAnalytics('optimize.activate');
    this.props.dpSetPreviousLocation(location);
    // this._checkPage(location)
    window.scrollTo(0, 0);
  };

  _checkPage = location => {
    const { dpSetIsHomePage } = this.props;
    const platformConfig = getConfigByKey('platformConfig');
    const { tenant_pages } = platformConfig;
    const homePage = !_.isEmpty(tenant_pages) ? tenant_pages[0] : null;
    const reHomePage = new RegExp(
      `^\/([a-z]{2}\/?)?${homePage ? `(page/${homePage.slug})?` : ''}$`,
    );
    let isHomePage = false;
    if (reHomePage.test(location.pathname)) {
      isHomePage = true;
    }
    dpSetIsHomePage(isHomePage);
  };

  _replaceUrl = location => {
    const { history, language } = this.props;

    const ls = getConfigByKey('locales') || ['vi', 'en'];
    const localeFromUrl = getLocaleFromUrl(true);
    let url = location.pathname || window.location.pathname;
    url = url.replace('/undefined/', '/');
    const pathSplit = url.split('/');
    if (/.+callback\/?$/.test(url) || localeFromUrl === language) return;
    if (localeFromUrl) {
      pathSplit[1] = language;
    }

    let link = localeFromUrl ? pathSplit.join('/') : `/${language}${pathSplit.join('/')}`;
    setTimeout(() => {
      history.replace({
        pathname: link,
        search: `${location.search}`,
        state: location.state,
      });
    }, 0);
  };

  _checkAuth = (initApp = false) => {
    const { auth, dpLoginGuestAccount } = this.props;
    const { account, isHasSubcription, guestAccount } = auth;
    if (!account && !guestAccount && initApp) dpLoginGuestAccount().then().catch();
    gtmVar_setIsLoggedIn(auth.isLogin);
    gtmVar_setHasSubscription(isHasSubcription);
  };

  componentWillMount() {
    const { account, dpShowMenu, location } = this.props;
    if (account && !account.profile) this.props.dispatchResetPaymentMethod();
    this._checkAuth(true);
    this._initConfigs();
    dpShowMenu();
    // this._checkPage(location)
    this._replaceUrl(location);
    this.unlisten = this.props.history.listen((location, action) => {
      this._replaceUrl(location);
      this._navigate({ location, action });
    });
  }

  componentDidMount() {
    const {
      dispatchGetRegionSubcription,
      dispatchGetPaidContent,
      account,
      isSsrHome,
      dpGetReactedContents,
      dispatchGetFavorite,
      blockCountry,
    } = this.props;

    window.addEventListener('resize', this.updateDimensions);

    if (account && account.profile) {
      getConfigByKey('ui_theme') !== TENANT_NAME_ENUM.PROJECTW &&
        dispatchGetPaidContent().then().catch();
      dpGetReactedContents();
      if (tenantEnvConfig.ui_theme === 'escondido') {
        dispatchGetFavorite();
      }
    }
    if (!isSsrHome || (isSsrHome && blockCountry.loading)) {
      dispatchGetRegionSubcription()
        .then(() => {})
        .catch(() => {
          window.document.body.classList.add('v-blockRegionSubcription');
        });
    }
    this.checkPageTypeAllowVerticalAds();
  }

  componentWillReceiveProps(nextProps) {
    const { location, account, dispatchGetPaidContent, page, language } = this.props;
    this._checkPage(location);

    try {
      if (
        nextProps.location.pathname !== location.pathname ||
        (page && nextProps.page.display_style !== page.display_style)
      ) {
        this.checkPageTypeAllowVerticalAds(nextProps);
        if (account && account.profile) {
          getConfigByKey('ui_theme') !== TENANT_NAME_ENUM.PROJECTW &&
            dispatchGetPaidContent().then().catch();
        }
      }
    } catch (error) {}

    this._checkAuth();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    this.unlisten();
  }

  checkPageTypeAllowVerticalAds(props) {
    const { page, location } = props || this.props;
    const slugType = location.pathname.split('/')[2];
    let found = this.listAllowVerticalAds.indexOf(slugType) > -1;
    if (location.pathname.split('/').length === 2) {
      found = true;
    }
    this.setState({
      allowVerticalAds: found && page && page.display_style !== 'LIVE',
    });
  }

  updateDimensions = () => {
    const { isAdsVertical } = this.state;
    const { innerWidth } = window;
    if (innerWidth > 1891 && !isAdsVertical) {
      return this.setState({
        isAdsVertical: true,
      });
    }
    if (innerWidth <= 1891 && isAdsVertical) {
      this.setState({
        isAdsVertical: false,
      });
    }
  };

  render() {
    const {
      showRegisterModal,
      showResetPasswordModal,
      showMessagePopup,
      param,
      isAdsVertical,
      allowVerticalAds,
    } = this.state;
    const selectedTheme = detectTheme(getConfigByKey('ui_theme'));

    const { root, auth, blockCountry, globalState, isLoading } = this.props;
    const skyscrapperBannerAds = getConfigByKey('features.skyscrapperBannerAds');
    moment.locale(root.language);
    if (!selectedTheme) return null;

    if (blockCountry.loading) {
      return <PageLoading />;
    }

    return (
      <AppStyled
        id="app"
        className={classnames('App', getConfigByKey('ui_theme'))}
        props={{
          isVerticalAds:
            skyscrapperBannerAds &&
            !auth.isHasSubcription &&
            allowVerticalAds &&
            !blockCountry.isBlock,
        }}
      >
        {isAdsVertical && !auth.isHasSubcription && (
          <AdsBanner position="left" customeClass="App__leftAd" />
        )}
        <div className="rootContainer">
          {blockCountry.loaded && !blockCountry.isBlock ? (
            <DefaultRouter component={this} />
          ) : getConfigByKey('ui_theme') === TENANT_NAME_ENUM.PROJECTW ? (
            <BlockCountryHasHeaderFooter />
          ) : (
            <BlockCountry />
          )}

          <LoginForm
            showForgotPassAction={() => method._showForgotPassModal(this)}
            showRegisterAction={() => method._showRegisterModal(this)}
            hideModal={key => method._hideModal(this, key)}
            modalShow={auth.isShowLogin}
            param={param}
          />

          <RegisterForm
            modalShow={showRegisterModal}
            showLoginAction={() => method._showLoginModal(this)}
            hideModal={key => method._hideModal(this, key)}
            showModal={key => method._showModal(this, key)}
          />

          <ResetPasswordForm
            modalShow={showResetPasswordModal}
            hideModal={key => method._hideModal(this, key)}
          />

          <MessagePopup
            modalShow={showMessagePopup}
            hideModal={key => method._hideModal(this, key)}
            showLoginAction={() => method._showLoginModal(this)}
          />
          <Toast />
          <Backdrop />
          {/* </Suspense> */}
        </div>
        {isAdsVertical && !auth.isHasSubcription && (
          <AdsBanner position="right" customeClass="App__rightAd" />
        )}
        {isLoading && <OverlayLoading />}
      </AppStyled>
    );
  }
}

const mapDispatchToProps = {
  dispatchInitialApp: () => initialApp(),
  dispatchGetRegionSubcription: () => getRegionSubcription(),
  dispatchSetIsShowLogin: value => authApi.setIsShowLogin(value),
  dpSetPreviousLocation: preLocation => setPreviousLocation(preLocation),
  dispatchGetPaidContent: () => authApi.getPaidContent(),
  dispatchResetPaymentMethod: () => authApi.resetPaymentMethod(),
  dpShowMenu: () => showMenu(),
  dpGetAccountInfo: () => getAccountInfo(),
  dpSetIsHomePage: payload => setIsHomePage(payload),
  dpLoginGuestAccount: () => authApi.loginGuestAccount(),
  dpGetReactedContents: params => getReactedContents(params),
  dispatchGetFavorite: () => getFavorite(),
};

const mapStateToProps = state => ({
  account: state.auth.account,
  root: state.root,
  blockCountry: state.blockCountry,
  isLoading: state.loading.isLoading,
  auth: state.auth,
  tenantConfigs: state.tenantConfigs,
  page: state.home.page,
  menu: state.home.menu,
  globalState: state.global,
  language: state.root.language,
  isSsrHome: state.home.isSsr,
});

const connectedApp = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App)),
);

export { connectedApp as App };
