import React, { useState } from 'react';
import { TooltipWrap } from '../actionButton';
import { withTranslation } from 'react-i18next';
import RemovePopup from '../../../../remove/popup';

function Remove(props) {
  const { t, item, handleRemoveRecent } = props;
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <TooltipWrap title={t('ribbon.removeFromList')} arrow placement="top">
        <div className="button" onClick={() => setModalShow(true)}>
          <i className="fa fa-close" aria-hidden="true" />
        </div>
      </TooltipWrap>
      <RemovePopup
        hideModal={() => setModalShow(false)}
        modalShow={modalShow}
        handleRemoveRecent={handleRemoveRecent}
        data={{
          items: item,
        }}
      />
    </>
  );
}

export default withTranslation()(Remove);
