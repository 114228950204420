import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ModalPPV from '~features/payperview/components/projectw/ModalPPV';
import LoginForm from '~features/auth/components/loginForm';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';

import {
  setWatchNowClick,
  setLoginModalShow,
  setIsShowSignInForm,
  setOpenModalPPV,
} from '~components/global/globalSlice';
import _ from 'lodash';
import { isLiveEvent } from '~core/method/contentMethod';

const WatchNowButtonStyled = styled.div`
  .btn-watch-now {
    border: 1px solid #fff;
    box-shadow: none;
    background-color: #000;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.025em;
    font-family: 'Neue Haas Grotesk Display Pro';
    padding: 10px;
    margin-right: 20px;
    text-transform: uppercase;
    line-height: 133.5%;
    @media (max-width: 767px) {
      margin-right: unset;
      &.preview__live {
        position: relative;
        margin: 8px;
      }
    }
  }
`;

const LIVING_LIVE_EVENT_STATE = 'living';

export const WatchNowButton = props => {
  const { setPopupUpgrade, content = {}, account, liveState = '' } = props;

  const location = useLocation();
  const [modalShow, setModalShow] = React.useState(false);
  const [open, setOpen] = useState();
  const [token, setToken] = useState('');
  const dispatch = useDispatch();
  const [translateX, setTranslateX] = React.useState(0);
  const landingPage = useSelector(state => state.landingPage);
  const { detail } = landingPage;
  const { trailers } = detail;

  const handleClose = () => {
    dispatch(setLoginModalShow(true));
    dispatch(setIsShowSignInForm(true));
    setModalShow(false);
    setOpen(false);
  };

  const handleOpen = () => {
    dispatch(setOpenModalPPV(true));
  };

  useEffect(() => {
    const paramQuery = queryString.parse(location.search, { decode: false });
    if (paramQuery && paramQuery.token) {
      setToken(paramQuery.token);
    }
  }, []);

  return (
    <WatchNowButtonStyled>
      <button
        className={`proxima-nova-font btn-watch-now ${
          liveState === LIVING_LIVE_EVENT_STATE && !_.isEmpty(trailers) ? 'preview__live' : ''
        }`}
        onClick={handleOpen}
      >
        {isLiveEvent.call(detail) ? 'watch live' : 'watch now'}
      </button>
      {/* <ModalPPV
        {...props}
        account={account}
        open={open}
        handleClose={() => handleClose()}
        translateX={translateX}
        token={token}
      /> */}
    </WatchNowButtonStyled>
  );
};

const mapStateToProps = state => ({
  account: state.auth.account,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WatchNowButton);
