import styled from 'styled-components';
import { Box } from '@material-ui/core';

export const BoxStyled = styled(Box)`
  .v-payment {
    &--title {
      color: ${({ theme }) => theme.typography.primaryColor};
    }
  }
  form {
    [class*='col'] {
      .row {
        margin-right: -0.5rem;
        margin-left: -0.5rem;
        [class*='col'] {
          padding-right: 0.5rem;
          padding-left: 0.5rem;
        }
      }
    }
  }
  .form-group {
    margin-bottom: 25px;
    position: relative;
    &.has-error {
      margin-bottom: 35px;
    }
    &[class*='has'] {
      .help-block {
        position: absolute;
        top: 100%;
        left: 0;
        font-size: 0.75rem;
      }
    }
    .v-payment--help {
      position: absolute;
      top: 110%;
      left: 1rem;
      font-size: 0.75rem;
      font-weight: 400;
    }
  }
  .form-group .v-group {
    margin-bottom: 0px;
  }
  .v-group {
    position: relative;
    margin-bottom: 25px;

    .v-input {
      background: none;
      font-size: 16px;
      color: #94a3ad;
      padding: 20px 10px 5px 5px;
      display: block;
      width: 100%;
      border: none;
      border-bottom: 1px solid #757575;
      &--bg-white {
        background-color: white;
      }
      .__PrivateStripeElement {
        transform: translateY(0.5rem);
      }
      &::-webkit-datetime-edit {
        transition: color 0.25s;
        text-transform: uppercase;
      }
      &[value=''] {
        // &::-webkit-datetime-edit-year-field,
        // &::-webkit-datetime-edit-month-field,
        // &::-webkit-datetime-edit-day-field,
        &::-webkit-datetime-edit {
          color: transparent;
        }
      }
      &:valid {
        color: ${({ theme }) => theme.input.color || '#283237'};
      }
      &.filled {
        border: 1px solid #b3bec4;
        padding: 18px 15px 5px 15px;
        border-radius: 6px;
        background: #fff;
        &:focus {
          outline: none;
          border: 1px solid ${({ theme }) => theme.input.color || '#283237'};
          ~ .v-label > span > span {
            color: ${({ theme }) => theme.plans.thirdColor};
          }
        }
        &:focus ~ label,
        &:valid ~ label {
          font-weight: 400;
        }
      }
      &:focus {
        outline: none;
        // &::-webkit-datetime-edit-year-field,
        // &::-webkit-datetime-edit-month-field,
        // &::-webkit-datetime-edit-day-field,
        &::-webkit-datetime-edit {
          color: ${({ theme }) => theme.input.color || '#283237'};
        }
      }
      &[type='date']:not([value='']) ~ label,
      &:focus ~ label,
      &:valid ~ label,
      &.StripeElement--focus ~ label,
      &.StripeElement--invalid ~ label,
      &.StripeElement--complete ~ label {
        top: 5px;
        font-size: 12px;
      }
      &:focus-within,
      &:hover {
        ~ label.v-label {
          color: ${({ theme }) => theme.input.label || '#283237'};
        }
      }
      &.StripeElement--invalid ~ label {
        font-weight: bold;
        color: #f23b46;
      }
      &.StripeElement--focus {
        outline: none;
        border: 1px solid ${({ theme }) => theme.input.focus || '#283237'};
      }
      &.StripeElement--invalid {
        /* font-weight: bold; */
        border: 1px solid #f23b46;
      }
      &:focus ~ .v-bar:before,
      &:focus ~ .v-bar:after {
        width: 50%;
      }
      &:focus ~ .v-highlight {
        -webkit-animation: inputHighlighter 0.4s ease;
        -moz-animation: inputHighlighter 0.4s ease;
        animation: inputHighlighter 0.4s ease;
      }
      &.StripeElement {
        border: 1px solid #b3bec4;
        padding: 1rem;
        background: #fefefe;
      }
    }

    .v-label {
      color: ${({ theme }) => theme.input.placeholder || '#283237'};
      font-size: 16px;
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      left: 5px;
      top: 20px;
      transition: 0.3s ease all;
      -moz-transition: 0.3s ease all;
      -webkit-transition: 0.3s ease all;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
      &.filled {
        left: 15px;
        top: 15px;
        right: 15px;
      }
      &.active {
        top: 5px !important;
      }
    }

    .v-bar {
      position: relative;
      display: block;
      width: 100%;
      &:before,
      &:after {
        content: '';
        height: 2px;
        width: 0;
        bottom: 1px;
        position: absolute;
        background: ${({ theme }) => theme.input.focus || '#283237'};
        transition: 0.3s ease all;
        -moz-transition: 0.3s ease all;
        -webkit-transition: 0.3s ease all;
      }
      &:before {
        left: 50%;
      }
      &:after {
        right: 50%;
      }
    }

    .v-highlight {
      position: absolute;
      height: 60%;
      width: 100%;
      top: 25%;
      left: 0;
      pointer-events: none;
      opacity: 0.5;
    }
  }

  /* ANIMATIONS ================ */
  @-webkit-keyframes inputHighlighter {
    from {
      background: ${({ theme }) => theme.input.focus || '#283237'};
    }
    to {
      width: 0;
      background: transparent;
    }
  }
  @-moz-keyframes inputHighlighter {
    from {
      background: ${({ theme }) => theme.input.focus || '#283237'};
    }
    to {
      width: 0;
      background: transparent;
    }
  }
  @keyframes inputHighlighter {
    from {
      background: ${({ theme }) => theme.input.focus || '#283237'};
    }
    to {
      width: 0;
      background: transparent;
    }
  }
`;
export const RowStyled = styled.div`
  &.input {
    &__form {
      font-size: 10px;
      margin-bottom: 2em;
      text-align: left;
      position: relative;
      input {
        &:focus {
          border: 1px solid ${({ theme }) => theme.input.label};
        }
      }
      &:active,
      &:focus-within,
      &:hover {
        label,
        label > span {
          color: ${({ theme }) => theme.input.label};
        }
        input {
          &:focus {
            border: 1px solid ${({ theme }) => theme.input.label};
          }
          border: 1px solid ${({ theme }) => theme.input.label};
        }
      }
      .select__value.select__daytime {
        background: #ffffff;
      }
    }
  }
  .input {
    &__form {
      &--label {
        span {
          font-size: 1.4em;
          margin-bottom: 0.25em;
          font-weight: 100;
          color: #141a23;
          span:last-child {
            margin-left: 0.2em;
            color: red;
            font-size: 1em;
          }
        }
      }

      &--input {
        position: relative;
        font-size: 1.6em;
        padding: 16px 20px;
        border-radius: 8px;
        border: 1px solid #c8d4db;
        background: #ffffff;
        width: 100%;
        color: #949494;
        &::placeholder {
          color: #c8d4db;
        }
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
      &--select {
        position: relative;
        svg {
          position: absolute;
          top: 0%;
          right: 6px;
          transform: translateY(110%);
          color: #9b9b9b;
          z-index: 0;
          font-size: 20px;
        }
      }
      &--warning {
        font-size: 1.4em;
        margin-bottom: 1.4em;
      }
      &--showPass {
        position: absolute;
        right: 0.55em;
        top: 61%;
        transform: translateY(-50%);
        margin-top: 0.2em;
        font-size: 1.75em;
        color: #6e6e6e;
        cursor: pointer;
      }
    }
  }
`;
