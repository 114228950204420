export default {
  bannerId: {
    contentTitleLbl: 'home-banner-content-title-lbl',
    contentRatingContainer: 'home-banner-content-rating-container',
    contentDurationLbl: 'home-banner-content-duration-lbl',
    contentSummaryLbl: 'home-banner-content-summary-lbl',
    contentPlayBtn: 'home-banner-content-play-btn',
    contentSlickBtn: 'home-banner-content-slick-btn',
    prevContentBtn: 'home-banner-prev-content-btn',
    nextContentBtn: 'home-banner-next-content-btn',
  },
  menuId: {
    pageBtn: 'home-menu-page-btn',
  },
  ribbonId: {
    titleLbl: 'home-ribbon-title-lbl',
    showAllBtn: 'home-ribbon-show-all-btn',
    scrollNextBtn: 'home-ribbon-scroll-next-btn',
    scrollPrevBtn: 'home-ribbon-scroll-prev-btn',
    itemId: {
      titleLbl: 'home-ribbon-item-title-lbl',
      viewCountLbl: 'home-ribbon-item-view-count-lbl',
    },
  },
};
