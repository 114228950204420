import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BackdropState {
  count: number;
}

const initialState: BackdropState = {
  count: 0,
};

const backdropSlice = createSlice({
  name: 'backdrop',
  initialState,
  reducers: {
    isLoading(state, action: PayloadAction<BackdropState['count']>) {
      const value = action.payload ? 1 : -1;
      state.count = state.count === 0 && value < 0 ? 0 : state.count + value;
    },
  },
});

export const { isLoading } = backdropSlice.actions;

export default backdropSlice.reducer;
