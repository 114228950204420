import React from 'react';
import { StyledCurrentChannelInfo } from './styled';
import { LineClampStyled } from '../../landingpage/styles/line-clamp';
import { connect } from 'react-redux';
import { secondsToTime } from '../../../utils/utils';
import Actions from './ribbonButton';
function RibbonCurrentChannel(props) {
  const { detail } = props.currentLive;
  if (!detail) return null;
  const { h, m, s } = secondsToTime(detail.end_at - detail.start_at);
  return (
    <StyledCurrentChannelInfo>
      <div className="programme--container">
        <div className="programme-logo">
          <img src={detail.title_image_url} />
        </div>
        <div className="time-range">
          {h}:{m}:{s}
        </div>
        <div className="description">
          <LineClampStyled className="line-clamp-3">{detail.description}</LineClampStyled>
        </div>
      </div>
      <Actions />
    </StyledCurrentChannelInfo>
  );
}

const mapStateToProps = state => ({
  currentLive: state.liveSchedule.currentLive,
});

export default connect(mapStateToProps)(RibbonCurrentChannel);
