import * as actions from './actions';
import * as appAPIs from '../../api/appAPIs';
import pushAnalytics from './../../services/analytics/Analytics';
export {
  getPage,
  getRibbon,
  getPageMenu,
  getDetail,
  getSeason,
  refreshPage,
  getRelate,
  getEpg,
  searchContents,
};

function getPage(uuid, slug = '') {
  return dispatch => {
    dispatch(actions.getPageRequest());
    appAPIs
      .get_page(uuid, slug)
      .then(response => {
        dispatch(actions.getPage(response));
      })
      .catch(err => {
        console.log('response', err);
      });
  };
}

function getPageMenu(id, uuid) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_page_menu(id, uuid)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getRibbon(id, page) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_ribbon(id, page)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getDetail(slug) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_detail(slug)
        .then(response => {
          dispatch(actions.getDetail(response));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getSeason(season_id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_season(season_id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function refreshPage(isRefreshPage) {
  return dispatch => {
    dispatch(actions.refreshPage(isRefreshPage));
  };
}

function getRelate(id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_relate(id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getEpg(params) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_epg(params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function searchContents(params) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      pushAnalytics('search', { search_term: params.keyword });
      appAPIs
        .get_search(params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}
