import React from 'react';
import {
  getLandingLink,
  getProgressPercent,
  getThumbnail9_5,
  timeFormatDuration,
} from '~core/method/contentMethod';
import { Link } from 'react-router-dom';
import { LazyImage } from '~components';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { Popper, ClickAwayListener } from '@material-ui/core';
import ModalDialog from '~features/profile/projectw/components/ModalDialog';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import LinearProgress from '@material-ui/core/LinearProgress';
import { RemoveContentConituneWatching } from '~features/profile/services';
import { useDispatch } from 'react-redux';

const RecordContentStyled = styled.div`
  p {
    margin: 0;
  }
  &.Items {
    align-items: flex-start;
    width: 50%;
    border-bottom: 0.5px solid #000;
    display: flex;
    text-decoration: none;
    font-family: var(--font);
    color: #000;
    font-weight: 400;
    background-color: #fff;
    &:nth-child(2n + 1) {
      border-inline-end: 0.5px solid #000;
      .Items--Image {
        width: calc(50% + 0.5px);
      }
    }
    &:nth-last-child(2):nth-child(2n + 1),
    &:nth-last-child(1) {
      border-bottom: 0.5px solid #000;
    }
    .Items--Image {
      background-color: #fff;
      max-width: 100%;
      height: 100%;
      width: 50%;
      position: relative;
      &::before {
        padding-top: calc(5 / 9 * 100%);
        display: block;
        content: '';
      }
      img {
        display: block;
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        min-height: 95px;
        object-fit: cover;
      }
      .category {
        position: absolute;
        background: #fff;
        padding: 3px;
        max-width: 100px;
        border: none;
        span {
          font-family: var(--font);
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 133.5%;
          letter-spacing: 0.05em;
          color: #000000;
        }
      }
    }
    .Items--Content {
      background-color: #fff;
      max-width: 100%;
      max-height: 100%;
      width: 50%;
      height: 100%;
      padding: ${props => (props.vodlist ? '8px 12px' : '10px 12px')};
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: calc(max(1.11111vw, 15px));
      &--title {
        font-style: normal;
        font-weight: 400;
        line-height: initial;
        letter-spacing: -0.005em;
        letter-spacing: -0.005em;
        font-size: 1.5rem;
        display: -webkit-box;
        max-height: 58px;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-line-clamp: 2;
        color: #000;
        &:hover,
        &:active,
        &:visited,
        &:target,
        &:focus,
        &:focus-within,
        &:focus-visible {
          color: #000;
          text-decoration: none;
          outline: none;
        }
      }
      &--desc {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 146%;
        display: -webkit-box;
        max-height: 3.2rem;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-line-clamp: 2;
        line-height: 1.6rem;
        word-break: keep-all;
        letter-spacing: 0.025em;
        margin: 0;
        padding-top: 2px;
      }
      &--hour {
        /* position: absolute; */
        /* bottom: 0; */
        /* margin-top: auto; */
        font-family: var(--font);
        font-style: normal;
        font-weight: 400;
        font-size: 0.9rem;
        line-height: 133.5%;
        letter-spacing: 0.05em;
        color: #000000;
        margin: 0;
        &.vodlist {
          font-weight: 500;
        }
      }
      .favoritePage {
        display: flex;
        margin-left: auto;
        cursor: pointer;
      }
    }
    .linear__progress {
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: transparent;
      height: 3px;
      width: 100%;
      .MuiLinearProgress-bar {
        background-color: #e92121;
      }
    }
  }
  @media (max-width: 1024px) {
    &.Items {
      .Items--Content {
        &--title {
          font-size: 1.25rem;
          /* line-height: 150%; */
        }
        &--desc {
          font-size: 1rem;
          line-height: 150%;
        }
      }
    }
  }
`;

const RecordContentSearch = styled(Link)`
  p {
    margin: 0;
  }
  text-decoration: none;
  display: flex;
  height: 100%;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  @media (max-width: 767px) {
    padding: 8px;
  }
  .Item {
    &--Image {
      position: relative;
      width: calc(227 / 440 * 100%);
      flex-shrink: 0;
      align-self: flex-start;
      &::before {
        content: '';
        width: 100%;
        display: block;
        padding-top: calc(125 / 227 * 100%);
      }
      @media (max-width: 767px) {
        gap: 10px;
        width: 187.5px;
        height: 100%;
      }
      &--image {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
      }
      &--category {
        padding: 3px;
        position: absolute;
        top: 0;
        width: fit-content;
        height: 24px;
        background: #ffffff;
        font-family: var(--font);
        font-style: normal;
        font-size: 12px;
        letter-spacing: 0.05em;
        color: #000000;
      }
    }
    &--Content {
      display: flex;
      flex-direction: column;
      font-family: var(--font);
      font-style: normal;
      color: #000000;
      position: relative;
      padding: 5px 20px;
      width: 50%;
      @media (max-width: 767px) {
        align-items: flex-start;
        gap: 6px;
        width: 187.5px;
        padding: 0px;
        padding-left: 8px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 1;
      }
      &--title {
        font-size: 18px;
        letter-spacing: -0.005em;
        margin-bottom: 0px;
        overflow: hidden;
        display: -webkit-box;
        /* max-height: 3.2rem; */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-line-clamp: 2;
      }
      &--description {
        font-family: var(--font);
        font-style: normal;
        font-size: 12px;
        line-height: 138%;
        letter-spacing: 0.025em;
        color: #000000;
        margin: unset;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-line-clamp: 2;
      }
      &--duration {
        font-size: 12px;
        letter-spacing: 0.05em;
        margin-bottom: 0;
        margin-top: auto;
      }
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 0px;
    width: 100%;
  }
`;

const SearchItemStyled = styled.div`
  width: 440px;
  background: #ffffff;
  border: 0.5px solid #000000;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    border-bottom: 0.5px solid #000;
    border-top: 0;
    &:nth-child(1) {
      border-top: 0.5px solid #000;
    }
  }
`;

const ButtonStyledFavorite = styled.div`
  background-color: #000;
  color: #fff;
  border-radius: 0;
  padding: 9px 10px;
  width: 100%;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 146%;
  text-align: center;
  letter-spacing: 0.025em;
  cursor: pointer;
`;

const SEARCH_RECORD = 'search';
const DEAFULT_RECORD = 'default';
const CONTINUE_RECORD = 'continue';

function RecordContentDesktop(props) {
  const {
    index,
    item,
    types,
    handleClick,
    favoritepage,
    handleAddFavorite = () => {},
    handleRemoveContentWatching,
    vodlist,
  } = props;
  const { i18n, t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const [openModalConfirm, setOpenModalConfirm] = React.useState(false);
  const dispatch = useDispatch();
  const handleClickOpen = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const timeFormat = item => {
    return timeFormatDuration.call(item);
  };

  const getLinkDetailContent = item => {
    return getLandingLink.call(item);
  };

  const handleOpenModalConfirm = () => {
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleConfirmRemoveContentWatching = contentId => {
    handleRemoveContentWatching(contentId);
    handleCloseModalConfirm();
  };

  if (types === SEARCH_RECORD)
    return (
      <RecordContentSearch
        to={!item.slug ? '#' : `/${i18n.language}/landing/${item.slug}`}
        onClick={handleClick}
        className="Item"
      >
        <div className="Item--Image">
          <img className="Item--Image--image" src={getThumbnail9_5.call(item)} />
          {!_.isEmpty(item.content_categories) && (
            <div className="Item--Image--category">
              <span>{!_.isEmpty(item.content_categories) && item.content_categories[0].name}</span>
            </div>
          )}
        </div>
        <div className="Item--Content">
          <p className="Item--Content--title">{item.title}</p>
          <p className="Item--Content--description">{item.short_description || ''}</p>
        </div>
      </RecordContentSearch>
    );

  if (types === CONTINUE_RECORD) {
    return (
      <RecordContentStyled key={index} className="Items">
        <Link className="Items--Image" to={getLinkDetailContent(item)}>
          <LazyImage src={getThumbnail9_5.call(item) || ''} alt="" />
          <LinearProgress
            variant="determinate"
            className="linear__progress"
            value={getProgressPercent.call(item)}
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          >
            {/* {!_.isEmpty(item.content_categories) && (
              <div className="category">
                <span>{item.content_categories.map(cate => cate.name)}</span>
              </div>
            )} */}
          </div>
        </Link>

        <div className="Items--Content">
          <div>
            <Link to={getLinkDetailContent(item)} className="Items--Content--title">
              {item.title}
            </Link>
            <p className="Items--Content--desc">
              {item.long_description || item.short_description || ''}
            </p>
          </div>
          {/* <p className="Items--Content--hour">{timeFormat(item)}</p> */}
          {CONTINUE_RECORD && (
            <>
              <div
                onMouseEnter={handleClickOpen}
                onMouseLeave={() => setAnchorEl(false)}
                onClick={handleOpenModalConfirm}
                className="favoritePage"
              >
                <DeleteOutlineIcon />
              </div>
              <Popper id={id} open={open} anchorEl={anchorEl} placement={'top'}>
                <ClickAwayListener onClickAway={() => setAnchorEl(false)}>
                  <ButtonStyledFavorite>
                    {t('projectw__MyPage.continueWatchingTab.titleConfirmRemove')}
                  </ButtonStyledFavorite>
                </ClickAwayListener>
              </Popper>
              <ModalDialog
                open={openModalConfirm}
                close={handleCloseModalConfirm}
                actionConfirm={() => handleConfirmRemoveContentWatching(item.id)}
                title={t('projectw__MyPage.continueWatchingTab.titleConfirmRemove')}
                txtConfirm={t('projectw__MyPage.continueWatchingTab.yes')}
                txtCancel={t('projectw__MyPage.continueWatchingTab.no')}
                description={t('projectw__MyPage.continueWatchingTab.descriptionConfirmRemove')}
                favoritepage
                continuePage
              />
            </>
          )}
        </div>
      </RecordContentStyled>
    );
  }
  return (
    <RecordContentStyled vodlist key={index} className="Items">
      <Link className="Items--Image" to={getLinkDetailContent(item)}>
        <LazyImage src={getThumbnail9_5.call(item) || ''} alt="" />
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        >
          {/* {!_.isEmpty(item.content_categories) && (
            <div className="category">
              <span>{item.content_categories.map(cate => cate.name)}</span>
            </div>
          )} */}
        </div>
      </Link>

      <div className="Items--Content">
        <div>
          <Link to={getLinkDetailContent(item)} className="Items--Content--title">
            {item.title}
          </Link>
          <p className="Items--Content--desc">
            {item.long_description || item.short_description || ''}
          </p>
        </div>
        {vodlist && !_.isEmpty(item.content_categories) && (
          <p className="Items--Content--hour vodlist">
            {item.content_categories.map(cate => cate.name)}
          </p>
        )}
        {!vodlist && <p className="Items--Content--hour">{timeFormat(item)}</p>}
        {favoritepage && (
          <>
            <div
              onMouseEnter={handleClickOpen}
              onMouseLeave={() => setAnchorEl(false)}
              onClick={handleOpenModalConfirm}
              className="favoritePage"
            >
              <FavoriteIcon />
            </div>
            <Popper id={id} open={open} anchorEl={anchorEl} placement={'top'}>
              <ClickAwayListener onClickAway={() => setAnchorEl(false)}>
                <ButtonStyledFavorite>Remove from Favorites</ButtonStyledFavorite>
              </ClickAwayListener>
            </Popper>
            <ModalDialog
              open={openModalConfirm}
              close={handleCloseModalConfirm}
              actionConfirm={() => {
                handleAddFavorite(item);
                handleCloseModalConfirm();
              }}
              title={'Remove from favorites'}
              txtConfirm={t('projectw__modal.CancelRefund.btnConfirm')}
              txtCancel={t('projectw__modal.CancelRefund.btnCancel')}
              description={`Do you want to remove ${item.title} from favorites?`}
              favoritepage
            />
          </>
        )}
      </div>
    </RecordContentStyled>
  );
}
export default RecordContentDesktop;
