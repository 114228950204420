import { CURATED_LIVE, EPISODE_TYPE } from './../constants';
import { getConfigByKey } from '../../tenantConfig/services';
import videojs from 'video.js';
import $ from 'jquery';

const Component = videojs.getComponent('Component');
const TitleControlBar = videojs.extend(Component, {
  constructor: function (player, options) {
    Component.apply(this, arguments);
    player.on('loadeddata', () => {
      this.updateTextContent();
    });
    player.on('error', () => {
      this.updateTextContent();
    });

    this.on(player, ['title-control-bar-change'], this.handleChangeTitle);
  },

  createEl: function () {
    const el = videojs.dom.createEl('div', {
      className: 'vjs-control vjs-title-control-bar',
    });
    return el;
  },

  // This function could be called at any time to update the text
  // contents of the component.
  handleChangeTitle: function (event, { type, data: newTitle }) {
    const $player = $(this.player_.el_);

    if (type === 'active' && $player.hasClass('vjs-rewatching')) {
      return;
    }

    $(this.el_).addClass('skip-title-loadeddata').text(newTitle);
  },
  updateTextContent: function () {
    if ($(this.el_).hasClass('skip-title-loadeddata')) {
      return;
    }
    const {
      isWatch,
      state,
      entityDetail,
      props: { t },
    } = this.options_.component;
    let txtTitle = EPISODE_TYPE === entityDetail.type ? entityDetail.short_title : state.title;
    const index = this.options_.eps && this.options_.eps.findIndex(_ => _.title === txtTitle);
    if (index !== -1 && !state.title) {
      const curEPS = this.options_.eps[index];
      txtTitle = t('laddingPage.episodes', { episodes: curEPS.episode });
    }
    if (getConfigByKey('tenant_slug') === 'sctv') {
      if (index !== -1) {
        const curEPS = this.options_.eps[index];
        txtTitle = t('laddingPage.episodes', { episodes: curEPS.episode });
      }
    }
    if (entityDetail && entityDetail.type === CURATED_LIVE && isWatch) {
      txtTitle = isWatch.short_title || isWatch.title;
    }
    videojs.dom.emptyEl(this.el());
    videojs.dom.appendContent(this.el(), txtTitle);
  },
});

// Register the component with Video.js, so it can be used in players.
videojs.registerComponent('TitleControlBar', TitleControlBar);
