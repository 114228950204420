/* tslint:disable */
/* eslint-disable */
/**
 * Continuous Watching API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultApi = exports.DefaultApiFactory = exports.DefaultApiFp = exports.DefaultApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * DefaultApi - axios parameter creator
 * @export
 */
var DefaultApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Delete watch history data by profile
         * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
         * @param {number} userId
         * @param {number} profileId
         * @param {string} isPrimary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwV3DeleteWhByProfile: function (oDKServiceName, userId, profileId, isPrimary, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'oDKServiceName' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3DeleteWhByProfile', 'oDKServiceName', oDKServiceName);
                    // verify required parameter 'userId' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3DeleteWhByProfile', 'userId', userId);
                    // verify required parameter 'profileId' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3DeleteWhByProfile', 'profileId', profileId);
                    // verify required parameter 'isPrimary' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3DeleteWhByProfile', 'isPrimary', isPrimary);
                    localVarPath = "/wh/users/{user_id}/profiles/{profile_id}"
                        .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)))
                        .replace("{".concat("profile_id", "}"), encodeURIComponent(String(profileId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (isPrimary !== undefined) {
                        localVarQueryParameter['is_primary'] = isPrimary;
                    }
                    if (oDKServiceName !== undefined && oDKServiceName !== null) {
                        localVarHeaderParameter['ODK-Service-Name'] = String(oDKServiceName);
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Delete watch history data by profile, episode
         * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
         * @param {number} userId
         * @param {number} profileId
         * @param {number} episodeId
         * @param {string} isPrimary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwV3DeleteWhByProfileEpisode: function (oDKServiceName, userId, profileId, episodeId, isPrimary, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'oDKServiceName' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3DeleteWhByProfileEpisode', 'oDKServiceName', oDKServiceName);
                    // verify required parameter 'userId' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3DeleteWhByProfileEpisode', 'userId', userId);
                    // verify required parameter 'profileId' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3DeleteWhByProfileEpisode', 'profileId', profileId);
                    // verify required parameter 'episodeId' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3DeleteWhByProfileEpisode', 'episodeId', episodeId);
                    // verify required parameter 'isPrimary' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3DeleteWhByProfileEpisode', 'isPrimary', isPrimary);
                    localVarPath = "/cw/users/{user_id}/profiles/{profile_id}/episodes/{episode_id}"
                        .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)))
                        .replace("{".concat("profile_id", "}"), encodeURIComponent(String(profileId)))
                        .replace("{".concat("episode_id", "}"), encodeURIComponent(String(episodeId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (isPrimary !== undefined) {
                        localVarQueryParameter['is_primary'] = isPrimary;
                    }
                    if (oDKServiceName !== undefined && oDKServiceName !== null) {
                        localVarHeaderParameter['ODK-Service-Name'] = String(oDKServiceName);
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get continuous watching data by profile
         * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
         * @param {number} userId
         * @param {number} profileId
         * @param {string} isPrimary
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwV3GetCwByProfile: function (oDKServiceName, userId, profileId, isPrimary, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'oDKServiceName' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3GetCwByProfile', 'oDKServiceName', oDKServiceName);
                    // verify required parameter 'userId' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3GetCwByProfile', 'userId', userId);
                    // verify required parameter 'profileId' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3GetCwByProfile', 'profileId', profileId);
                    // verify required parameter 'isPrimary' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3GetCwByProfile', 'isPrimary', isPrimary);
                    localVarPath = "/cw/users/{user_id}/profiles/{profile_id}"
                        .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)))
                        .replace("{".concat("profile_id", "}"), encodeURIComponent(String(profileId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (isPrimary !== undefined) {
                        localVarQueryParameter['is_primary'] = isPrimary;
                    }
                    if (pageSize !== undefined) {
                        localVarQueryParameter['page_size'] = pageSize;
                    }
                    if (oDKServiceName !== undefined && oDKServiceName !== null) {
                        localVarHeaderParameter['ODK-Service-Name'] = String(oDKServiceName);
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get continuous watching data by profile, episode
         * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
         * @param {number} userId
         * @param {number} profileId
         * @param {number} episodeId
         * @param {string} isPrimary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwV3GetCwByProfileEpisode: function (oDKServiceName, userId, profileId, episodeId, isPrimary, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'oDKServiceName' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3GetCwByProfileEpisode', 'oDKServiceName', oDKServiceName);
                    // verify required parameter 'userId' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3GetCwByProfileEpisode', 'userId', userId);
                    // verify required parameter 'profileId' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3GetCwByProfileEpisode', 'profileId', profileId);
                    // verify required parameter 'episodeId' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3GetCwByProfileEpisode', 'episodeId', episodeId);
                    // verify required parameter 'isPrimary' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3GetCwByProfileEpisode', 'isPrimary', isPrimary);
                    localVarPath = "/cw/users/{user_id}/profiles/{profile_id}/episodes/{episode_id}"
                        .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)))
                        .replace("{".concat("profile_id", "}"), encodeURIComponent(String(profileId)))
                        .replace("{".concat("episode_id", "}"), encodeURIComponent(String(episodeId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (isPrimary !== undefined) {
                        localVarQueryParameter['is_primary'] = isPrimary;
                    }
                    if (oDKServiceName !== undefined && oDKServiceName !== null) {
                        localVarHeaderParameter['ODK-Service-Name'] = String(oDKServiceName);
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get continuous watching data by profile, program
         * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
         * @param {number} userId
         * @param {number} profileId
         * @param {number} programId
         * @param {string} isPrimary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwV3GetCwByProfileProgram: function (oDKServiceName, userId, profileId, programId, isPrimary, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'oDKServiceName' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3GetCwByProfileProgram', 'oDKServiceName', oDKServiceName);
                    // verify required parameter 'userId' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3GetCwByProfileProgram', 'userId', userId);
                    // verify required parameter 'profileId' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3GetCwByProfileProgram', 'profileId', profileId);
                    // verify required parameter 'programId' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3GetCwByProfileProgram', 'programId', programId);
                    // verify required parameter 'isPrimary' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3GetCwByProfileProgram', 'isPrimary', isPrimary);
                    localVarPath = "/cw/users/{user_id}/profiles/{profile_id}/programs/{program_id}"
                        .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)))
                        .replace("{".concat("profile_id", "}"), encodeURIComponent(String(profileId)))
                        .replace("{".concat("program_id", "}"), encodeURIComponent(String(programId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (isPrimary !== undefined) {
                        localVarQueryParameter['is_primary'] = isPrimary;
                    }
                    if (oDKServiceName !== undefined && oDKServiceName !== null) {
                        localVarHeaderParameter['ODK-Service-Name'] = String(oDKServiceName);
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get continuous watching latest episode each program by profile
         * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
         * @param {number} userId
         * @param {number} profileId
         * @param {string} isPrimary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwV3GetLatestEpisodeEachProgramCwByProfile: function (oDKServiceName, userId, profileId, isPrimary, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'oDKServiceName' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3GetLatestEpisodeEachProgramCwByProfile', 'oDKServiceName', oDKServiceName);
                    // verify required parameter 'userId' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3GetLatestEpisodeEachProgramCwByProfile', 'userId', userId);
                    // verify required parameter 'profileId' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3GetLatestEpisodeEachProgramCwByProfile', 'profileId', profileId);
                    // verify required parameter 'isPrimary' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3GetLatestEpisodeEachProgramCwByProfile', 'isPrimary', isPrimary);
                    localVarPath = "/cw/users/{user_id}/profiles/{profile_id}/latest-episode"
                        .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)))
                        .replace("{".concat("profile_id", "}"), encodeURIComponent(String(profileId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (isPrimary !== undefined) {
                        localVarQueryParameter['is_primary'] = isPrimary;
                    }
                    if (oDKServiceName !== undefined && oDKServiceName !== null) {
                        localVarHeaderParameter['ODK-Service-Name'] = String(oDKServiceName);
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get watch history data by profile
         * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
         * @param {number} userId
         * @param {number} profileId
         * @param {string} isPrimary
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwV3GetWhByProfile: function (oDKServiceName, userId, profileId, isPrimary, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'oDKServiceName' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3GetWhByProfile', 'oDKServiceName', oDKServiceName);
                    // verify required parameter 'userId' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3GetWhByProfile', 'userId', userId);
                    // verify required parameter 'profileId' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3GetWhByProfile', 'profileId', profileId);
                    // verify required parameter 'isPrimary' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3GetWhByProfile', 'isPrimary', isPrimary);
                    localVarPath = "/wh/users/{user_id}/profiles/{profile_id}"
                        .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)))
                        .replace("{".concat("profile_id", "}"), encodeURIComponent(String(profileId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (isPrimary !== undefined) {
                        localVarQueryParameter['is_primary'] = isPrimary;
                    }
                    if (pageSize !== undefined) {
                        localVarQueryParameter['page_size'] = pageSize;
                    }
                    if (oDKServiceName !== undefined && oDKServiceName !== null) {
                        localVarHeaderParameter['ODK-Service-Name'] = String(oDKServiceName);
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Save continuous watching data
         * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
         * @param {SaveContinuousWatching} [saveContinuousWatching]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwV3Post: function (oDKServiceName, saveContinuousWatching, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'oDKServiceName' is not null or undefined
                    (0, common_1.assertParamExists)('cwV3Post', 'oDKServiceName', oDKServiceName);
                    localVarPath = "/cw";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (oDKServiceName !== undefined && oDKServiceName !== null) {
                        localVarHeaderParameter['ODK-Service-Name'] = String(oDKServiceName);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(saveContinuousWatching, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.DefaultApiAxiosParamCreator = DefaultApiAxiosParamCreator;
/**
 * DefaultApi - functional programming interface
 * @export
 */
var DefaultApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.DefaultApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Delete watch history data by profile
         * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
         * @param {number} userId
         * @param {number} profileId
         * @param {string} isPrimary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwV3DeleteWhByProfile: function (oDKServiceName, userId, profileId, isPrimary, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.cwV3DeleteWhByProfile(oDKServiceName, userId, profileId, isPrimary, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete watch history data by profile, episode
         * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
         * @param {number} userId
         * @param {number} profileId
         * @param {number} episodeId
         * @param {string} isPrimary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwV3DeleteWhByProfileEpisode: function (oDKServiceName, userId, profileId, episodeId, isPrimary, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.cwV3DeleteWhByProfileEpisode(oDKServiceName, userId, profileId, episodeId, isPrimary, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get continuous watching data by profile
         * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
         * @param {number} userId
         * @param {number} profileId
         * @param {string} isPrimary
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwV3GetCwByProfile: function (oDKServiceName, userId, profileId, isPrimary, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.cwV3GetCwByProfile(oDKServiceName, userId, profileId, isPrimary, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get continuous watching data by profile, episode
         * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
         * @param {number} userId
         * @param {number} profileId
         * @param {number} episodeId
         * @param {string} isPrimary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwV3GetCwByProfileEpisode: function (oDKServiceName, userId, profileId, episodeId, isPrimary, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.cwV3GetCwByProfileEpisode(oDKServiceName, userId, profileId, episodeId, isPrimary, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get continuous watching data by profile, program
         * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
         * @param {number} userId
         * @param {number} profileId
         * @param {number} programId
         * @param {string} isPrimary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwV3GetCwByProfileProgram: function (oDKServiceName, userId, profileId, programId, isPrimary, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.cwV3GetCwByProfileProgram(oDKServiceName, userId, profileId, programId, isPrimary, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get continuous watching latest episode each program by profile
         * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
         * @param {number} userId
         * @param {number} profileId
         * @param {string} isPrimary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwV3GetLatestEpisodeEachProgramCwByProfile: function (oDKServiceName, userId, profileId, isPrimary, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.cwV3GetLatestEpisodeEachProgramCwByProfile(oDKServiceName, userId, profileId, isPrimary, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get watch history data by profile
         * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
         * @param {number} userId
         * @param {number} profileId
         * @param {string} isPrimary
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwV3GetWhByProfile: function (oDKServiceName, userId, profileId, isPrimary, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.cwV3GetWhByProfile(oDKServiceName, userId, profileId, isPrimary, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Save continuous watching data
         * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
         * @param {SaveContinuousWatching} [saveContinuousWatching]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwV3Post: function (oDKServiceName, saveContinuousWatching, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.cwV3Post(oDKServiceName, saveContinuousWatching, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.DefaultApiFp = DefaultApiFp;
/**
 * DefaultApi - factory interface
 * @export
 */
var DefaultApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.DefaultApiFp)(configuration);
    return {
        /**
         *
         * @summary Delete watch history data by profile
         * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
         * @param {number} userId
         * @param {number} profileId
         * @param {string} isPrimary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwV3DeleteWhByProfile: function (oDKServiceName, userId, profileId, isPrimary, options) {
            return localVarFp.cwV3DeleteWhByProfile(oDKServiceName, userId, profileId, isPrimary, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete watch history data by profile, episode
         * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
         * @param {number} userId
         * @param {number} profileId
         * @param {number} episodeId
         * @param {string} isPrimary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwV3DeleteWhByProfileEpisode: function (oDKServiceName, userId, profileId, episodeId, isPrimary, options) {
            return localVarFp.cwV3DeleteWhByProfileEpisode(oDKServiceName, userId, profileId, episodeId, isPrimary, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get continuous watching data by profile
         * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
         * @param {number} userId
         * @param {number} profileId
         * @param {string} isPrimary
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwV3GetCwByProfile: function (oDKServiceName, userId, profileId, isPrimary, pageSize, options) {
            return localVarFp.cwV3GetCwByProfile(oDKServiceName, userId, profileId, isPrimary, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get continuous watching data by profile, episode
         * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
         * @param {number} userId
         * @param {number} profileId
         * @param {number} episodeId
         * @param {string} isPrimary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwV3GetCwByProfileEpisode: function (oDKServiceName, userId, profileId, episodeId, isPrimary, options) {
            return localVarFp.cwV3GetCwByProfileEpisode(oDKServiceName, userId, profileId, episodeId, isPrimary, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get continuous watching data by profile, program
         * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
         * @param {number} userId
         * @param {number} profileId
         * @param {number} programId
         * @param {string} isPrimary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwV3GetCwByProfileProgram: function (oDKServiceName, userId, profileId, programId, isPrimary, options) {
            return localVarFp.cwV3GetCwByProfileProgram(oDKServiceName, userId, profileId, programId, isPrimary, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get continuous watching latest episode each program by profile
         * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
         * @param {number} userId
         * @param {number} profileId
         * @param {string} isPrimary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwV3GetLatestEpisodeEachProgramCwByProfile: function (oDKServiceName, userId, profileId, isPrimary, options) {
            return localVarFp.cwV3GetLatestEpisodeEachProgramCwByProfile(oDKServiceName, userId, profileId, isPrimary, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get watch history data by profile
         * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
         * @param {number} userId
         * @param {number} profileId
         * @param {string} isPrimary
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwV3GetWhByProfile: function (oDKServiceName, userId, profileId, isPrimary, pageSize, options) {
            return localVarFp.cwV3GetWhByProfile(oDKServiceName, userId, profileId, isPrimary, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Save continuous watching data
         * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
         * @param {SaveContinuousWatching} [saveContinuousWatching]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwV3Post: function (oDKServiceName, saveContinuousWatching, options) {
            return localVarFp.cwV3Post(oDKServiceName, saveContinuousWatching, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.DefaultApiFactory = DefaultApiFactory;
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
var DefaultApi = /** @class */ (function (_super) {
    __extends(DefaultApi, _super);
    function DefaultApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Delete watch history data by profile
     * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
     * @param {number} userId
     * @param {number} profileId
     * @param {string} isPrimary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.cwV3DeleteWhByProfile = function (oDKServiceName, userId, profileId, isPrimary, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).cwV3DeleteWhByProfile(oDKServiceName, userId, profileId, isPrimary, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete watch history data by profile, episode
     * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
     * @param {number} userId
     * @param {number} profileId
     * @param {number} episodeId
     * @param {string} isPrimary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.cwV3DeleteWhByProfileEpisode = function (oDKServiceName, userId, profileId, episodeId, isPrimary, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).cwV3DeleteWhByProfileEpisode(oDKServiceName, userId, profileId, episodeId, isPrimary, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get continuous watching data by profile
     * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
     * @param {number} userId
     * @param {number} profileId
     * @param {string} isPrimary
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.cwV3GetCwByProfile = function (oDKServiceName, userId, profileId, isPrimary, pageSize, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).cwV3GetCwByProfile(oDKServiceName, userId, profileId, isPrimary, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get continuous watching data by profile, episode
     * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
     * @param {number} userId
     * @param {number} profileId
     * @param {number} episodeId
     * @param {string} isPrimary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.cwV3GetCwByProfileEpisode = function (oDKServiceName, userId, profileId, episodeId, isPrimary, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).cwV3GetCwByProfileEpisode(oDKServiceName, userId, profileId, episodeId, isPrimary, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get continuous watching data by profile, program
     * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
     * @param {number} userId
     * @param {number} profileId
     * @param {number} programId
     * @param {string} isPrimary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.cwV3GetCwByProfileProgram = function (oDKServiceName, userId, profileId, programId, isPrimary, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).cwV3GetCwByProfileProgram(oDKServiceName, userId, profileId, programId, isPrimary, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get continuous watching latest episode each program by profile
     * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
     * @param {number} userId
     * @param {number} profileId
     * @param {string} isPrimary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.cwV3GetLatestEpisodeEachProgramCwByProfile = function (oDKServiceName, userId, profileId, isPrimary, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).cwV3GetLatestEpisodeEachProgramCwByProfile(oDKServiceName, userId, profileId, isPrimary, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get watch history data by profile
     * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
     * @param {number} userId
     * @param {number} profileId
     * @param {string} isPrimary
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.cwV3GetWhByProfile = function (oDKServiceName, userId, profileId, isPrimary, pageSize, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).cwV3GetWhByProfile(oDKServiceName, userId, profileId, isPrimary, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Save continuous watching data
     * @param {'odk' | 'odl' | 'odc'} oDKServiceName Which service
     * @param {SaveContinuousWatching} [saveContinuousWatching]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.cwV3Post = function (oDKServiceName, saveContinuousWatching, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).cwV3Post(oDKServiceName, saveContinuousWatching, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return DefaultApi;
}(base_1.BaseAPI));
exports.DefaultApi = DefaultApi;
