import React, { useState, useEffect, memo } from 'react';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import VerticalRibon from './../../verticalRibbon/views';
import Ribbon from '../../../components/ribbon';
import { isMobile } from 'react-device-detect';
import { tenantEnvConfig } from '../../../constants/tenantConfig/tenantEnvConfig';
import { getConfigByKey } from '../../tenantConfig/services';
import classNames from 'classnames';
const RibbonItem = memo(Ribbon, function isEqual(prevProps, nextProps) {
  return prevProps.title === nextProps.title && prevProps.ribbon.id === nextProps.ribbon.id;
});

const RibbonSlide = ({ data, children }) => {
  const { page, account, t, ribbon, isLoadingPage, livePage } = data;
  const [ribbonSlide, setRibbonSlide] = useState([]);
  useEffect(() => {
    setRibbonSlide(ribbon || []);
  }, [ribbon]);

  let count = 0;
  if (page) {
    let search = page.search;
    let data = [];
    let ribbonTmp = (ribbonSlide.length && ribbonSlide) || page.ribbons;
    if (ribbonTmp && ribbonTmp.length > 0) {
      data = ribbonTmp.map((ribbons, index) => {
        if (isMobile) {
          ribbons.is_visible_in_ribbon_main_section = true;
        }
        if (
          (!account && ribbons.type === 5 && ribbons.items.length === 0) ||
          !ribbons.is_visible_in_ribbon_main_section
        ) {
          return null;
        }
        if (ribbons.items.length > 0) {
          count++;
        }
        return (
          <React.Fragment key={ribbons.id}>
            <RibbonItem
              key={index}
              index={index}
              title={ribbons.name}
              ribbon={ribbons}
              search={`${search}&type=${ribbons.type}${livePage ? '&livePage=true' : ''}`}
            />
            {index === 0 && !isLoadingPage ? children : null}
          </React.Fragment>
        );
      });
    }
    return count > 0 ? (
      data
    ) : (
      <div
        style={{ textAlign: 'center' }}
        className={classNames('error-data', isLoadingPage && 'hide')}
      >
        {count === 0 && ribbonSlide && ribbonSlide.length > 0
          ? t('filter.txtFitlerNondata')
          : t('notDataToShow')}
      </div>
    );
  }
  if (isLoadingPage) return null;
  return (
    <div className="v-home__left-site--error error-data" style={{ textAlign: 'center' }}>
      {t('notDataToShow')}
    </div>
  );
};

const WrapRibbon = props => {
  const { match, children } = props;

  if (!match.params.slug || !getConfigByKey('features.vertical_ribbon', true)) {
    return (
      <div
        className={`${tenantEnvConfig.uiTheme !== 'projectw' && 'v-home__wrap'} v-home__wrap--only`}
      >
        {isMobile ? children : null}
        <RibbonSlide data={props}>{!isMobile ? children : null}</RibbonSlide>
      </div>
    );
  }
  return (
    <div className="v-home__wrap">
      <Col
        xs={12}
        sm={12}
        md={8}
        lg={8}
        className="v-home__left-site"
        style={{ textDecoration: 'none' }}
      >
        {isMobile ? children : null}
        <RibbonSlide data={props}>{!isMobile ? children : null}</RibbonSlide>
      </Col>
      <Col className="v-home__right-site" xs={12} sm={12} md={4} lg={4}>
        <VerticalRibon />
      </Col>
    </div>
  );
};

const mapStateToProps = state => ({
  page: state.home.page,
  menu: state.home.menu,
  account: state.auth.account,
  isLoadingPage: state.home.isLoadingPage,
});

export default withRouter(connect(mapStateToProps)(withTranslation()(WrapRibbon)));
