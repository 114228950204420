import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { DialogStyled } from './style/TabRight';
import { Box, Button, IconButton, Typography } from '@material-ui/core';

export default function ModalDialog(props) {
  const {
    open,
    close,
    title,
    description,
    txtConfirm,
    txtCancel,
    actionConfirm,
    favoritepage,
    hide = false,
    modalUnpublish,
    continuePage,
  } = props;
  return (
    <DialogStyled
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="dialogStyled"
      favoritepage={favoritepage}
      continuePage={continuePage}
    >
      <DialogTitle className="title" id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent className="content">
        <DialogContentText
          className="content-Txt"
          id="alert-dialog-description"
          style={{ maxWidth: '341px' }}
        >
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={modalUnpublish && 'modalUnpublish'}>
        {!hide && (
          <Button className="btnAction cancel__refund Cancel " onClick={close}>
            {txtCancel}
          </Button>
        )}
        {!hide && (
          <Button
            className="btnAction cancel__refund Confirm"
            onClick={() => actionConfirm()}
            autoFocus
          >
            {txtConfirm}
          </Button>
        )}
        {modalUnpublish && (
          <Button
            className="btnAction buttonConfirmUnpublish"
            onClick={() => actionConfirm()}
            autoFocus
          >
            {txtConfirm}
          </Button>
        )}
      </DialogActions>
    </DialogStyled>
  );
}
