import * as actions from './actions';
import * as appAPIs from '../../api/appAPIs';
import * as homePageApi from '../../api/homePageApi';
import queryString from 'query-string';
import * as metaTagActions from '~components/metaTags/actions';

export { getRibbonDetail, getRibbonVOD, getRibbonVOD_Personalized };

function getRibbonDetail(pageSlug, ribbonSlug, search) {
  const queryParams = queryString.parse(search);
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (queryParams.type !== '5' && queryParams.type !== '9') {
        homePageApi
          .getRibbonDetail(pageSlug, ribbonSlug, search)
          .then(response => {
            if (ribbonSlug !== 'system_top_10_weekly')
              dispatch(metaTagActions.updateSeo(response.seo || {}));
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      } else {
        homePageApi
          .getRibbonDetailRecent(pageSlug, ribbonSlug, search)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      }
    });
  };
}

const getRibbonVOD = pageSlug => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      homePageApi
        .getRibbonVOD(pageSlug)
        .then(response => {
          dispatch(actions.getRibbonVOD(response));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};

const getRibbonVOD_Personalized = pageSlug => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      homePageApi
        .getRibbonVOD_Personalized(pageSlug)
        .then(response => {
          dispatch(actions.getRibbonVOD_Personalized(response));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};
