import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import BadgeNewRelease from '../newReleaseBadge';
import classnames from 'classnames';

export default class Heading extends React.PureComponent {
  render() {
    const { img, data, secondSlider, newBanner, active } = this.props;
    if (secondSlider) {
      return (
        <StyledComp
          className={classnames('v-slide__header v-slide__header--cover', active && 'active')}
        >
          <div className="v-slide__subThumbnail">
            <img src={img} />
          </div>
        </StyledComp>
      );
    }
    return (
      <StyledComp className="v-slide__header">
        <BadgeNewRelease item={data} target="banner" />
        <div className={classnames('v-slide__banner', newBanner && 'v-slide__newBanner')}>
          <img className="v-slide--thumbnail" src={img} />
          <div className="vignette vignette-layer" />
        </div>
      </StyledComp>
    );
  }
}

const StyledComp = styled(Panel.Heading)`
  &.v-slide__header {
    padding: 0;
    border-radius: 0;
    border: none;
    position: relative;
    background-color: unset;
    &--cover {
      cursor: pointer;
      &:after {
        transition: 0.5s all;
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(17, 18, 18, 0.7);
        border-radius: 4px;
      }
      &.active {
        &:after {
          background: unset;
        }
      }
    }
  }
  & .v-slide {
    &__subThumbnail {
      list-style: none;
      cursor: pointer;
      /* margin-bottom: 0.4rem; */
      position: relative;
      width: 100%;
      padding-top: calc(9 / 16 * 100%);
      img {
        background-color: #9b9b9b;
        background-position: 50%;
        background-size: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        top: 0;
        object-fit: cover;
        /* border: 1px solid #fff; */
      }
    }
    &__thumbnail,
    &__banner {
      cursor: pointer;
      position: relative;
      padding-top: calc(9 / 16 * 100%);
      background-image: linear-gradient(#fff, #000);
      &:before {
        content: '';
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0) 125%);
        z-index: 1;
      }
    }
    &__newBanner {
      padding-top: calc(100%);
      &:before {
        content: '';
        bottom: -3px;
        left: 0;
        top: unset;
        position: absolute;
        width: 100%;
        height: 70%;
        background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgb(17, 18, 18) 100%);
        z-index: 1;
      }
    }
    &--thumbnail {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      max-width: 100%;
      max-height: 100%;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    & .v-slide {
      &__thumbnail,
      &__banner {
        padding-top: calc(6 / 19 * 100%);
        &:before {
          background-image: linear-gradient(90deg, #000 0%, rgba(255, 255, 255, 0) 30%);
        }
      }
      &__newBanner {
        padding-top: calc(67 / 190 * 100%);
        &:before {
          height: 100%;
          bottom: 0;
          background-image: linear-gradient(
              270deg,
              rgba(255, 255, 255, 0) 85%,
              rgba(0, 0, 0, 0.8) 100%
            ),
            linear-gradient(90deg, rgba(255, 255, 255, 0) 85%, rgba(0, 0, 0, 0.8) 100%);
        }
      }
    }
  }
`;

Heading.propTypes = {
  data: PropTypes.any,
  img: PropTypes.string,
};
