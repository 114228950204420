import React from 'react';
import { connect } from 'react-redux';
import homePageId from '../../../../constants/ids/homePageId';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import { LineClampStyled } from '../../../../features/landingpage/styles/line-clamp';
import Type from '../../component/body/type';
import WatchButton from '../../component/body/watch-button';

class BodyOdv extends React.Component {
  render() {
    const { account } = this.props;
    const { data, index } = this.props;

    return (
      <StyledComp className="v-slide__body">
        <div className="v-slide__body--top">
          <div className="v-slide__body--obs">
            <Type entityDetail={data} />
            <p
              data-testid={`${homePageId.bannerId.contentTitleLbl}-${index}`}
              className="v-slide--name"
            >
              {data.title}
            </p>
          </div>
        </div>
        {/* <p data-testid={`${homePageId.bannerId.contentSummaryLbl}-${index}`} className='v-slide--description'>
          <LineClampStyled className='line-clamp-3 sm-line-clamp-5'>
            {data.long_description || data.short_description}
          </LineClampStyled>
        </p> */}
        <WatchButton item={data} index={index} account={account} />
      </StyledComp>
    );
  }
}

const StyledComp = styled(Panel.Body)`
  &.v-slide__body {
    padding: 1rem 15px;
    z-index: 2;
    display: block;
    background: ${({ theme }) => theme.newSlide.backgroundMobile};
    .star-ratings {
      margin: 0.5em 0;
    }
    & .v-slide {
      &__body {
        &--top {
          position: relative;
          width: 100%;
        }
        &--obs {
          position: absolute;
          bottom: calc(100% + 1.825em);
          left: 0;
          z-index: 10;
        }
      }
      &--name {
        text-shadow: 0px 1px 1px #000000;
        color: white;
        font-size: 1.25em;
        text-transform: uppercase;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &--description {
        color: ${({ theme }) => theme.newSlide.colorTextMobile};
        font-size: 0.825em;
        line-height: 1.4em;
        height: auto;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        max-height: calc(1rem * 10 * 1.5);
      }
    }
    .v-slide--title {
      font-size: 1em !important;
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
    &.v-slide {
      &__body {
        padding: 1rem 45px;
      }
    }
  }
  @media (min-width: 992px) {
    &.v-slide__body {
      background: none;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 47%;
      height: 100%;
      padding-left: 45px;
      &--top {
        position: relative;
      }
      &--obs {
        position: static;
      }
      & .v-slide {
        &--name {
          font-size: 2em;
          margin: 0.5em 0;
          line-height: 1.5em;
          max-height: 3em;
          overflow: hidden;
          white-space: normal;
        }
        &--description {
          color: white;
          height: auto;
          line-height: 1.5em;
          font-weight: 100;
          font-size: 1em;
        }
      }
      .v-slide--title {
        font-size: 1.25em !important;
      }
    }
  }
  @media (min-width: 1280px) {
  }
  @media (min-width: 1440px) {
  }
  @media (min-width: 1660px) {
    &.v-slide__body {
      & .v-slide {
        &--name {
          font-size: 2.5em;
        }
      }
      .v-slide--title {
        font-size: 1.5em !important;
      }
    }
  }
`;

BodyOdv.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
};

const mapStateToProps = state => ({
  account: state.auth.account,
});

export default connect(mapStateToProps, null)(BodyOdv);
