import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { ReactComponent as ArrowForwardIcon } from '../../../../assets/icons/projectw/icon_page_next.svg';
import { ReactComponent as ArrowBackIcon } from '../../../../assets/icons/projectw/icon_page_back.svg';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Root, ListUpMobile } from '../style/homepage';
import { Col, Row } from 'react-bootstrap';
import { isMobileOnly, isTablet, isMobile, withOrientationChange } from 'react-device-detect';
import lodash from 'lodash';
import { getThumbnail9_5, timeFormatDuration } from '~core/method/contentMethod';
import WatchMore from '~features/homepage/projectw/watchMore';

const useStyles = makeStyles({
  contextItem: {
    borderBottom: '1px solid black',
    cursor: 'pointer',
    color: '#000000',
    padding: 0,
    paddingLeft: 15,
    position: 'relative',
    transition: '0.2s all ease-in-out 0s',
    '&:hover': {
      color: '#fff',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      top: 'unset',
      left: 0,
      width: '100%',
      height: 0,
      backgroundColor: '#000',
      transition: '0.1s all ease-in-out 0s',
    },
    '&:hover:before': {
      bottom: 'unset',
      top: 0,
      height: '100%',
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingRight: '15px',
    marginTop: '10px',
    zIndex: 99,
    '& :first-child': {
      flexBasis: '60%',
      marginRight: '16px',
    },
    '& > p': {
      lineHeight: '29px',
    },
  },
  name: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    fontFamily: 'Neue Haas Grotesk Display Pro',
    letterSpacing: '-0.005em',
  },
  contextImage: {
    textDecoration: 'none',
    textAlign: 'left',
    padding: 0,
    '& .date-time': {
      marginTop: '16px',
    },
  },
  contextAction: {
    textDecoration: 'none',
    textAlign: 'left',
    padding: 0,
    marginTop: '1.5em',
  },
  icon: {
    fontFamily: 'Neue Haas Grotesk Display Pro',
    fontSize: 60,
  },
  iconDisable: {
    fontFamily: 'Neue Haas Grotesk Display Pro',
    fontSize: 60,
    color: 'gray',
  },
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  linkColor: {
    color: '#000',
    '&:hover': {
      color: '#000',
    },
  },
  btnPaging: {
    width: 80,
    height: 80,
    // backgroundColor: '#999999',
    borderRadius: 40,
    '&:hover': {
      // backgroundColor: '#999999',
      backgroundColor: 'white',
      cursor: 'pointer',
    },
  },
  btnPagingDisable: {
    width: 80,
    height: 80,
    // backgroundColor: '#d6d6d6',
    borderRadius: 40,
    '& path': {
      stroke: '#AEAEB2',
    },
    '&:hover': {
      pointerEvents: 'none',
      cursor: 'none',
      backgroundColor: 'white',
    },
    '&:focus': {
      backgroundColor: 'white',
      pointerEvents: 'none',
    },
    '&:active': {
      backgroundColor: 'white',
      pointerEvents: 'none',
    },
  },
});

const getLandingPageLink = record => {
  if (!record || !record.slug) {
    return '#';
  }
  return `/landing/${record.slug}`;
};

const formatDate = dateString => {
  if (!dateString) {
    return '';
  }
  return moment(dateString, 'YYYY-MM-DD').format('YYYY');
};

const LIMIT_PAGE = 10;

function MobileListUpComing(props) {
  const { contents = [] } = props;
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [indexItem, setIndexItem] = useState(0);
  const [pageSize, setPageSize] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [currentContentHover, setCurrentContentHover] = useState({});
  const isDisableNext = pageSize === totalPage;
  const isDisablePrev = pageSize === 1;
  const [hasPaging, setHasPaging] = useState(contents.length > LIMIT_PAGE ? true : false);
  const [watchMore, setWatchMore] = useState(false);

  function onMouseOverItem(index) {
    if (indexItem != index) {
      setIndexItem(index);
    }
  }
  const timeFormat = item => {
    return timeFormatDuration.call(item);
  };
  function onHandleNext() {
    if (pageSize + 1 > totalPage) return;
    const { data: result } = onPaginatedItems(contents, pageSize + 1);
    setData(result);
    setPageSize(pageSize + 1);
  }

  function onHandlePrev() {
    if (pageSize - 1 < 0) return;
    const { data: result } = onPaginatedItems(contents, pageSize - 1);
    setData(result);
    setPageSize(pageSize - 1);
  }

  useEffect(() => {
    const { data: result, total_pages } = onPaginatedItems(contents, pageSize);
    setData(result);
    setTotalPage(total_pages);
    setHasPaging(contents.length > LIMIT_PAGE ? true : false);
  }, [contents]);

  useEffect(() => {
    if (contents && contents[indexItem]) {
      setCurrentContentHover(contents[indexItem]);
    }
  }, [indexItem, contents]);

  function onPaginatedItems(items, page, pageSize) {
    var pg = page || 1,
      pgSize = pageSize || LIMIT_PAGE,
      offset = (pg - 1) * pgSize,
      pagedItems = _.drop(items, offset).slice(0, pgSize);
    return {
      page: pg,
      pageSize: pgSize,
      total: items.length,
      total_pages: Math.ceil(items.length / pgSize),
      data: pagedItems,
    };
  }
  return (
    <ListUpMobile className="isMobile">
      {data.map(item => (
        <Link key={item.id} className="isMobile-Link" to={getLandingPageLink(item)}>
          <div className="isMobile-Link--ComponentItem">
            <div className="isMobile-Link--ComponentItem--wrapImage">
              <img className="image" src={getThumbnail9_5.call(item)} />
            </div>
            <div className="isMobile-Link--ComponentItem--Content">
              <p className="Title">{item.title}</p>
              <div className="SubContent">
                {/* <p className="TimeAndType">{timeFormat(item)}</p> */}
                {/* {item.content_categories.map(
                  cate =>
                    !lodash.isEmpty(cate.name) && (
                      <div className="Around"></div>
                    ),
                )}{' '} */}
                {/* <p className="category">
                  {item.content_categories.map(cate => cate.name)}{' '}
                </p> */}

                {_.compact([...(item.content_categories || [])]).map((cate, index) => (
                  <div key={`${index}-${cate.slug}`}>
                    {index === 0 ? null : <div className="Around"></div>}

                    <p className="category">{typeof cate === 'string' ? cate : cate.name}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Link>
      ))}
      <div className="actions">
        {hasPaging ? (
          <div>
            <Button
              onClick={onHandlePrev}
              className={isDisablePrev ? classes.btnPagingDisable : classes.btnPaging}
              disableRipple
              id="PrevNext"
            >
              <ArrowBackIcon className={classes.icon} />
            </Button>
            <Button
              onClick={onHandleNext}
              // className={classes.btnPaging}
              disableRipple
              id="PrevNext"
              className={isDisableNext ? classes.btnPagingDisable : classes.btnPaging}
            >
              <ArrowForwardIcon />
            </Button>
          </div>
        ) : (
          <span></span>
        )}
        <WatchMore watchMore={watchMore} setWatchMore={setWatchMore} />
      </div>
    </ListUpMobile>
  );
}

export default MobileListUpComing;
