import React from 'react';
import { connect } from 'react-redux';
import homePageId from '../../../../constants/ids/homePageId';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import { LineClampStyled } from '../../../../features/landingpage/styles/line-clamp';
import Type from '../../component/body/type';
import WatchButton from '../../component/body/watch-button';
import { getTimePaidContent } from '~components/ribbon/functions/getTimePaidContent';

const Regions = ({ listRegions, language }) => {
  return listRegions.map((items, index) => {
    return (
      <React.Fragment key={index}>
        <span className="v-slide--regions">
          {items.name} {index < listRegions.length - 1 ? ', ' : null}
        </span>
      </React.Fragment>
    );
  });
};

const BodyOdv = React.memo(props => {
  const { account, paidContents, language } = props;
  const { data, index } = props;
  const timePaidContents = getTimePaidContent(paidContents, data.id);
  let payperview = timePaidContents && (timePaidContents.hour || timePaidContents.day);
  const listRegions = (data.metadata && data.metadata.regions) || [];
  if (data.bannerExtra) return null;
  return (
    <StyledComp className="v-slide__body">
      <div className="v-slide__body--top">
        <Type entityDetail={data} payperview={payperview} />
        <Regions listRegions={listRegions} language={language} />
        <h1
          data-testid={`${homePageId.bannerId.contentTitleLbl}-${index}`}
          className="v-slide--name"
        >
          {data.title}
        </h1>
        <div
          data-testid={`${homePageId.bannerId.contentSummaryLbl}-${index}`}
          className="v-slide--description"
        >
          <LineClampStyled className="line-clamp-3 lg-line-clamp-1 xl-line-clamp-1">
            {data.long_description || data.short_description}
          </LineClampStyled>
        </div>
      </div>
      <WatchButton payperview={payperview} item={data} index={index} account={account} {...props} />
    </StyledComp>
  );
});

const StyledComp = styled(Panel.Body)`
  &.v-slide__body {
    background: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 90%;
    height: 100%;
    z-index: 2;
    &--top {
      position: relative;
    }
    &--obs {
      position: static;
    }
    .star-ratings {
      margin: 0.5em 0;
    }
    & .v-slide {
      &__body {
        &--top {
          position: relative;
          width: 100%;
        }
      }
      &--regions{
        font-size: 16px;
        color: #ffffff;
        font-style:italic;
        text-decoration: none solid rgb(255, 255, 255);
        font-weight: 100;
      }
      &--name {
        text-shadow: 0px 1px 1px #000000;
        color: white;
        font-size: 27px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 1.25em;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0.125rem 0 0.5rem 0;
      }
      &--description {
        color: #fff;
        font-size: 15px;
        line-height: 1.5rem;
        height: auto;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        max-height: calc(1rem * 3 * 1.5);
      }
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {

  }
    @media (min-width: 992px) {
      &.v-slide__body {
        &.v-slide {
          &__body {
            width: 47%;
          }
        }
        & .v-slide {
          &--name {
            font-size: 2.5em;
            max-height: 3em;
            margin: 0.125rem 0;
            overflow: hidden;
            white-space: normal;
          }
          &--description {
            color: #ffffff;
            text-decoration: none solid rgb(255, 255, 255);
            text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
            height: auto;
            line-height: 1.5em;
            font-weight: 100;
            font-size: 1.125em;
          }
        }
      }
    }
  }
  @media (min-width: 1280px) {
  }
  @media (min-width: 1440px) {
  }
  @media (min-width: 1680px) {
  }
`;

BodyOdv.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
};

const mapStateToProps = state => ({
  account: state.auth.account,
  paidContents: state.auth.paidContents,
});

export default connect(mapStateToProps, null)(BodyOdv);
