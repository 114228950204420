import React, { Component } from 'react';
import Escondido from '../../live-schedule/views';
import Odv from './homeOdv';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { tenantEnvConfig } from '../../../constants/tenantConfig/tenantEnvConfig';
import { getDisplayStyle } from '../functions';
import * as filterServices from '~components/filter/services';
import * as actions from '~features/homepage/actions';
import { store } from '~root/store';
import HomeProjectw from '~features/homepage/views/HomeProjectW';
import { getConfigByKey } from '~features/tenantConfig/services';
class HomePage extends Component {
  componentDidMount() {
    const { isSsrHome } = this.props;
    if (window.innerWidth <= 992 && getConfigByKey('ui_theme') === 'escondido') {
      return this.props.history.push(`/${this.props.language}/install-app`);
    }
    if (isSsrHome) {
      store.dispatch(actions.updateSsr(false));
    }
  }

  render() {
    const { menu } = this.props;
    const displayStyle = getDisplayStyle(menu, this.props);
    const uiTheme = getConfigByKey('ui_theme');

    let Component;
    if (uiTheme === 'projectw') {
      return <HomeProjectw {...this.props} />;
    }
    switch (displayStyle) {
      case 'LIVE':
        Component = Escondido;
        break;
      default:
        Component = Odv;
    }
    return <Component {...this.props} />;
  }
}

const mapStateToProps = state => ({
  menu: state.home.menu,
  language: state.root.language,
  isSsrHome: state.home.isSsr,
});

HomePage.serverFetch = [
  pageSlug => filterServices.getFilterData(pageSlug),
  (pageSlug, params) => filterServices.getPage(params),
];

export default withRouter(connect(mapStateToProps)(HomePage));
