import React, { useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { ReactComponent as IconChatClose } from '~img/icons/projectw/chat/icon-chat-close.svg';
import { useDispatch, useSelector } from 'react-redux';
import { toggleChatLive } from '~components/global/globalServices';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment';
import { ReactComponent as IconCloseOpenLiveChatMobile } from '~img/icons/projectw/CloseLiveChatMobile.svg';
import { useMediaQuery } from 'react-responsive';

const useStyles = makeStyles({
  iconChatClose: {
    position: 'relative',
    zIndex: '999',
    '@media(max-width : 767px)': {
      display: 'none !important',
    },
  },
});

function OpenCloseChatLive(props) {
  const { isToggle, player } = props;
  const classes = useStyles();
  const { inPlayer = false } = props;
  const open = useSelector(state => state.global.chatLive.open);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 768px)',
  });

  const handleClick = e => {
    if (player && player.isFullscreen()) {
      player.exitFullscreen();
      dispatch(toggleChatLive(true));
      return;
    }
    dispatch(toggleChatLive());
  };

  const handleVideoFullscreenChange = (player, event) => {
    if (player.isFullscreen()) {
      dispatch(toggleChatLive(false));
    }
  };

  useEffect(() => {
    if (!player) {
      return;
    }

    player.on('fullscreenchange', function (event) {
      handleVideoFullscreenChange(this, event);
    });
  }, []);

  if (inPlayer && open) {
    return null;
  }

  return (
    <IconButton className={`${isToggle ? `${classes.iconChatClose}` : ''}`} onClick={handleClick}>
      {isDesktopOrLaptop ? <IconChatClose /> : <IconCloseOpenLiveChatMobile />}
    </IconButton>
  );
}

export default OpenCloseChatLive;
