// Get the Component base class from Video.js
// import { pushEventFirebase } from './../components/pushEventFirebase'
import videojs from 'video.js';
import jquery from 'jquery';

var Button = videojs.getComponent('Button');

// The videojs.extend function is used to assist with inheritance. In
// an ES6 environment, `class SeekNext extends Component` would work
// identically.

var BtnReports = videojs.extend(Button, {
  // The constructor of a component receives two arguments: the
  // player it will be associated with and an object of options.
  constructor: function (player, options) {
    // It is important to invoke the superclass before anything else,
    // to get all the features of components out of the box!
    Button.apply(this, arguments);
    const $player = jquery(player.el_);
    player.on('play', () => {
      if ($player.hasClass('vjs-report-open')) {
        player.pause();
      }
    });
    // If a `text` option was passed in, update the text content of
    // the component.
  },

  // The `createEl` function of a component creates its DOM element.
  createEl: function () {
    const { isRestrictions, infoShow } = this.options_.component.state;
    const { entityDetail } = this.options_.component;
    const { t } = this.options_.component.props;
    let isAdult = infoShow ? infoShow.restriction : entityDetail.restriction;

    const btn = videojs.dom.createEl('button', {
      className: `vjs-report vjs-button ${isAdult && !isRestrictions && 'disable'}`,
    });
    btn.innerHTML = `<i class="fa fa-exclamation" aria-hidden="true"></i><span>${t(
      `report.txtReport`,
    )}</span>`;
    return btn;
  },
  handleClick: function (event) {
    const { component } = this.options_;
    const player_ = component.videoPlayer.player_;
    const { account, showLoginAction } = component.props;
    const $player = jquery(this.player_.el_);
    if (!account) {
      if (player_.isFullscreen()) {
        player_.exitFullscreen();
      }
      return showLoginAction();
    }
    $player.toggleClass('vjs-report-open');
    this.player_.pause();
  },
});

// Register the component with Video.js, so it can be used in players.
videojs.registerComponent('BtnReports', BtnReports);
