import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import CastIcon from '@material-ui/icons/Cast';
import CropFreeIcon from '@material-ui/icons/CropFree';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { LineClampStyled } from '~features/landingpage/styles/line-clamp';
import CheckIcon from '@material-ui/icons/Check';
import ButtonSubtitle from './buttonSubttile';
import ButtonEpisode from './buttonEpisodes';
import { EPISODE_TYPE } from '../../constants';

const GroupButton = React.memo(props => {
  const { component, fullScreen, status } = props;
  const { entityDetail, isWatch } = component;
  const { account, t, myList } = component.props;
  const { isRewatchCuratedLive, infoShow } = component.state;
  const [isShowDetail, setShowDetail] = useState(false);
  const [showDetail, setShowDetailData] = useState(null);
  const [isRemove, setRemove] = useState(entityDetail.is_favorite || false);
  const [loading, setLoadding] = useState(false);

  let entity = entityDetail.type === EPISODE_TYPE && infoShow ? infoShow : entityDetail;
  useEffect(() => {
    setShowDetailData(isWatch);
  }, [isWatch]);

  useEffect(() => {
    if (infoShow) {
      setLoadding(true);
      component.props.dispatchGetFavorite().finally(() => {
        setLoadding(false);
      });
    }
  }, []);

  useEffect(() => {
    if (!infoShow) return;
    const result = myList.filter(element => element.id === entity.id);
    if (result && result.length && infoShow) {
      return setRemove(true);
    }
    setRemove(false);
  }, [myList]);

  const CustomiseTooltip = withStyles({
    tooltip: {
      backgroundColor: 'unset',
      marginTop: 5,
      fontSize: 18,
      color: '#d1d1d1',
      textTransform: 'capitalize',
    },
  })(Tooltip);

  const handleButtonShare = () => {
    component.setState({
      isOpenPopupShare: true,
    });
  };

  const handleAddMylist = () => {
    setLoadding(true);
    component.props
      .dpPostFavorite(entity)
      .then(response => {
        setRemove(!isRemove);
      })
      .catch(err => {
        console.log('dispatchGetFavorite error', err);
      })
      .finally(() => {
        setLoadding(false);
      });
  };

  return (
    <ul className="vjs-control-bar-live">
      {account ? (
        <CustomiseTooltip
          title={`${
            !isRemove ? t('player.controlBar.txtAddMyList') : t('player.controlBar.txtRemoveMyList')
          }`}
          placement="Bottom"
        >
          <li className="vjs--selector" onClick={() => handleAddMylist()}>
            {!loading ? (
              <div className="vjs--selector__icons">{!isRemove ? <AddIcon /> : <CheckIcon />}</div>
            ) : (
              <i
                className="fa fa-circle-o-notch fa-spin"
                style={{ color: 'white' }}
                aria-hidden="true"
              />
            )}
          </li>
        </CustomiseTooltip>
      ) : null}

      <CustomiseTooltip title={t('player.controlBar.txtShare')} placement="Bottom">
        <li className="vjs--selector" onClick={() => handleButtonShare()}>
          <div className="vjs--selector__icons">
            <i className="fa fa-share" aria-hidden="true"></i>
          </div>
        </li>
      </CustomiseTooltip>
      <ButtonEpisode {...props} />

      {/* <CustomiseTooltip title="chromecast" placement="Bottom">
        <li className="vjs--selector">
          <div className="vjs--selector__icons">
            <CastIcon />
          </div>
        </li>
      </CustomiseTooltip> */}
      <CustomiseTooltip title={t('player.controlBar.txtDetail')} placement="Bottom">
        <li className="vjs--selector">
          <div className="vjs--selector__icons" onClick={() => setShowDetail(!isShowDetail)}>
            <i className="fa fa-info-circle" aria-hidden="true"></i>
          </div>
          <div
            className={classnames(
              'vjs--selector__tool-tip',
              isShowDetail && 'vjs--selector__tool-tip--active',
            )}
          >
            <p className="vjs--selector__tool-tip--legal">{t('player.controlBar.txtDetail')}</p>
            <p className="vjs--selector__tool-tip--title">
              {(showDetail && showDetail.title) || entityDetail.title}
            </p>
            <LineClampStyled className="vjs--selector__tool-tip--desscription line-clamp-5">
              {(showDetail && showDetail.description) || entityDetail.long_description}
            </LineClampStyled>
          </div>
        </li>
      </CustomiseTooltip>
      <ButtonSubtitle {...props} />
      <CustomiseTooltip title={t('player.controlBar.txtFullScreen')} placement="Bottom">
        <li className="vjs--selector" onClick={fullScreen}>
          <div className="vjs--selector__icons">
            <CropFreeIcon />
          </div>
        </li>
      </CustomiseTooltip>
      {status === 'live' &&
      !isRewatchCuratedLive &&
      (entityDetail.type === 5 || entityDetail.type === 7) ? (
        <li className="vjs--selector--liveui">
          <FiberManualRecordIcon />
          live
        </li>
      ) : null}
    </ul>
  );
});

export default GroupButton;
