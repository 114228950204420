import React, { useState, useRef, useEffect, useMemo } from 'react';
import RibbonItemHorizontal from '../component/items';
import DivStyled from '../styled';
import { withTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import { getTopTen } from './../services';
import pushAnalytics from './../../../services/analytics/Analytics';
import AdsBanner from '../../../components/adsBanner/views/index';
import { updateRibbonTarget } from '../../homepage/services';
import { updateListRibbon } from './../../youtubePage/services';
import { getRibbonDetail } from './../../ribbon/services';

import * as display from './../constants';
import queryString from 'query-string';
import SliderRibbon from '../component/slider';
const VerticalRibon = React.memo(props => {
  const {
    topTenLanding,
    dpGetTopTen,
    pageSlug,
    match,
    account,
    t,
    pageHome,
    dpUpdateRibbonTarget,
    pageYtb,
    youtube,
    listRibbonTarget,
    dpUpdateListRibbon,
    location,
    mobile,
    dpGetRibbon,
    isHasSubcription,
    language,
    target,
  } = props;
  const [topContents, setTopContents] = useState([]);
  const [ribbonsSide, setRibbonsSide] = useState([]);
  const ribbonsHome = (pageHome && pageHome.side_navigation_section) || [];
  const ribbonsYtb = (pageYtb && pageYtb.side_navigation_section) || [];
  const topContent = useRef(null);
  const params = match.params;
  let verticalRibbons = youtube ? ribbonsYtb : ribbonsHome;
  let loadingTopContent = false;
  let paramQuery = queryString.parse(location.search, { decode: false });
  let tenants = paramQuery.tenants || (params && params.slug);
  const fetData = () => {
    let slug = params.slug;
    if (topTenLanding) {
      slug = pageSlug;
      dpGetTopTen(slug)
        .then(res => {
          setTopContents(res);
        })
        .catch();
    }
  };

  const getTopRibbonSystem = () => {
    if (loadingTopContent) return;
    loadingTopContent = true;
    let params = {
      page: 0,
      limit: 10,
    };
    dpGetRibbon(pageSlug, 'system_top_10_weekly', params)
      .then(res => {
        setRibbonsSide([
          {
            ...res,
          },
        ]);
      })
      .catch(() => {})
      .finally(() => {});
  };

  const addCategory = items => {
    window.scrollTo(0, 0);
    if (!youtube) {
      return;
    }
    let isHas = listRibbonTarget.some(element => element.slug === items.slug);
    if (isHas) {
      return;
    }
    let idTarget = {
      checked: true,
      id: items.id,
      name: items.name,
      slug: items.slug,
    };
    dpUpdateListRibbon([...listRibbonTarget, idTarget]);
  };

  useMemo(() => fetData(), []);

  useEffect(() => {
    if (topTenLanding) return;
    let hasItemsRb =
      Array.isArray(verticalRibbons) && verticalRibbons.some(items => items.items.length > 0);
    if (verticalRibbons && verticalRibbons.length && hasItemsRb) {
      return setRibbonsSide(verticalRibbons);
    }
    if (!loadingTopContent && !ribbonsSide.length) return getTopRibbonSystem();
    return () => {};
  }, [verticalRibbons]);

  if (mobile) {
    return (
      <SliderRibbon
        topTenLanding={topTenLanding}
        topContents={topContents}
        RibbonsSide={ribbonsSide}
      />
    );
  }

  return (
    <DivStyled className="v-ribbon--vertical" props={{ isSvod: isHasSubcription }}>
      <div className="v-ribbon--ads" style={{ display: isHasSubcription && 'none' }}>
        <AdsBanner
          position={target === 'landing' ? 'medium_rectangle_landing' : 'medium_rectangle'}
          rezise
        />
      </div>
      <div className="v-ribbon--top-content" ref={topContent}>
        {topTenLanding ? (
          <>
            <p
              className="v-ribbon--top-content-title"
              style={{ display: !topTenLanding && 'none' }}
            >
              Top 10
            </p>
            {topContents.length > 0 ? (
              topContents.slice(0, 10).map((item, index) => {
                return (
                  <RibbonItemHorizontal item={item} key={index} ribbon={topContents} {...props} />
                );
              })
            ) : (
              <div className="v-ribbon--vertical--loading">
                <Loader type="Oval" color="#FFFFFF" height="30" width="30" />
              </div>
            )}
          </>
        ) : null}
        {!topTenLanding && ribbonsSide.length
          ? ribbonsSide.map((items, index) => {
              if (!items.items.length) return null;
              return (
                <div key={index} className="v-ribbon--wrap-content">
                  <p className="v-ribbon--top-content-title">{items.name}</p>
                  {items.type === display.TYPE_TOP_CONTENT || items.show_flag_odr ? (
                    items.items.slice(0, 10).map((item, index) => {
                      return (
                        <RibbonItemHorizontal item={item} key={index} ribbon={items} {...props} />
                      );
                    })
                  ) : (
                    <>
                      {items.items.slice(0, 4).map((item, index) => {
                        return (
                          <RibbonItemHorizontal item={item} key={index} ribbon={items} {...props} />
                        );
                      })}
                      <Link
                        to={{
                          pathname: youtube
                            ? `/${language}/youtube/${match.params.slug}`
                            : `/${language}/category/${items.slug}`,
                          search: youtube
                            ? `?category=${items.slug}&${pageYtb.search}&viewmore=true`
                            : `?tenants=${tenants}`,
                        }}
                        className="v-ribbon--top-content-view-more"
                        onClick={() => {
                          pushAnalytics('click', {
                            content_type: 'button',
                            item_name: 'See all',
                            widget: items.slug,
                          });
                          dpUpdateRibbonTarget(items.id);
                          addCategory(items);
                        }}
                      >
                        {t('ribbon.txtViewMore')}
                        <i className="fa fa-angle-down" aria-hidden="true" />
                      </Link>
                    </>
                  )}
                </div>
              );
            })
          : null}
      </div>
    </DivStyled>
  );
});

const mapStateToProps = state => ({
  pageYtb: state.youtubePage.page,
  pageSlug: state.home.pageSlug,
  pageHome: state.home.page,
  account: state.auth.account,
  listRibbonTarget: state.youtubePage.listRibbonTarget,
  paidContents: state.auth.paidContents,
  isHasSubcription: state.auth.isHasSubcription,
  language: state.root.language,
});

const mapDispatchToProps = {
  dpUpdateRibbonTarget: id => updateRibbonTarget(id),
  dpGetTopTen: pageSlug => getTopTen(pageSlug),
  dpGetRibbon: (pageSlug, ribbonSlug, search) => getRibbonDetail(pageSlug, ribbonSlug, search),
  dpUpdateListRibbon: data => updateListRibbon(data),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VerticalRibon)),
);
