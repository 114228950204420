import React from 'react';
import classnames from 'classnames';
import * as types from '../../constants';
import { isNode } from '~utils/ssr';

const Type = React.memo(props => {
  const { timePaidContents, account, item, blockUrl, trailer, payperview, paymentType } = props;
  const { type } = item;
  let premium = !item.has_free_content;
  if (
    !item.is_premium ||
    payperview ||
    timePaidContents ||
    (item.can_preview && blockUrl) ||
    trailer ||
    (account && account.profile && account.profile.subscription_plan_info) ||
    item.can_preview
  ) {
    premium = false;
  }
  const VipType = () => {
    if (
      (paymentType === types.TVOD || paymentType === 2) &&
      ((item.type === types.EPISODE_TYPE && !item.can_preview) ||
        item.has_free_content === false) &&
      (!timePaidContents || payperview === false)
    ) {
      return (
        <div className={classnames('ribbonTicketTvod')}>
          <img width="20" height="20" src={require('~img/icons/odv/viplvl2.svg').default} />
        </div>
      );
    }
    return (
      <div className={classnames('ribbonTicketVip')} style={{ display: premium ? null : 'none' }}>
        <i className="fa fa-star" aria-hidden="true" />
      </div>
    );
  };

  const YoutubeType = () => {
    if (item && item.video_source === 1 && !isNode()) {
      return (
        <div className="ribbonTicketYotube">
          <img src={require('~img/img/youtube.png').default} width="80" height="25" />
        </div>
      );
    }
    return null;
  };

  const LiveType = () => (
    <div className="ribbonTicket">
      <div className="ribbonTicketLive">
        <i className="fa fa-circle" aria-hidden="true" style={{ paddingRight: '0.5rem' }} />
        Live
      </div>
      <VipType />
    </div>
  );
  return (
    <>
      <YoutubeType />
      {type === types.CHANNEL_TYPE || type === types.CURATED_LIVE ? (
        <LiveType />
      ) : (
        <VipType premium={premium} />
      )}
    </>
  );
});

export default Type;
