import React, { useState, useRef, useEffect } from 'react';

import '../style/index.css';
import { connect, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import { Player } from '~components/player';
import { getViewContentsByIds } from '~features/homepage/services';
import _ from 'lodash';
import { LazyImage } from '~components';
import $ from 'jquery';
import { SVG } from '~components/svg';
import { getConfigByKey } from '~features/tenantConfig/services';
import MainText from '~features/auth/components/projectw/MainText';
import { getAccessToken } from '~utils/utils';
import {
  getBackdrop,
  getFirstTrailer,
  getTrailers,
  getIdsTrailers,
  getViewContentPlaying,
  getDrmLinkPlay,
} from '~core/method/contentMethod';
import { MainTextComponent } from '../style/homepage';
import { BANNER_DISPLAY } from '../constants';

const getFirstRibbonBannerTypeFromRibbonsList = (ribbons = []) => {
  let result = null;

  ribbons.some(ribbon => {
    if (ribbon.display_type === BANNER_DISPLAY && !result) {
      result = ribbon;
      return false;
    }
  });

  return result || [];
};

function VideoText(props) {
  const { className = '' } = props;
  const [linkPlay, setLinkPlay] = useState();
  const [record, setRecord] = useState({});
  const refPlayer = useRef(null);
  const home = useSelector(state => state.home);
  const [allContents, setAllContents] = useState([]);
  const [done, setDone] = useState(false);
  const [noRecord, setNoRecord] = useState(null);
  const [offset, setOffset] = useState(0);
  const [viewContentPlaying, setViewContentPlaying] = useState(null);
  const [geoBlocking, setGeoBlocking] = useState(false);
  const auth = useSelector(state => state.auth);

  const isMobileDetect = window.innerWidth < 992;
  useEffect(() => {
    if (!record || !record.id || linkPlay) {
      return;
    }

    // const trailer = getFirstTrailer.call(record)
    // if (trailer.geo_blocked) {
    //   setDone(false)
    //   return
    // }
    // if (trailer && trailer.link_play) {
    //   setLinkPlay(trailer.link_play)
    //   setDone(true)
    //   return
    // }

    const accessToken = getAccessToken(true, auth);

    if (_.isEmpty(accessToken) || _.isEmpty(record)) {
      return;
    }

    const ids = getIdsTrailers.call(record);

    getViewContentsByIds(ids)
      .then(res => {
        const viewById = _.keyBy(res, 'id');
        const newViewContentPlaying = getViewContentPlaying.call(record, viewById);

        if (!newViewContentPlaying) {
          return;
        }
        if (newViewContentPlaying.geo_blocked) {
          return;
        }
        setGeoBlocking(newViewContentPlaying.geo_blocked);
        setViewContentPlaying(newViewContentPlaying);
      })
      .finally(() => {
        setDone(true);
      });
  }, [record, auth]);

  useEffect(() => {
    if (_.isEmpty(viewContentPlaying)) {
      return;
    }
    setLinkPlay(getLinkPlay(viewContentPlaying));
  }, [viewContentPlaying]);

  useEffect(() => {
    if (allContents.length <= 0) {
      return;
    }
    setRecord(allContents[0]);
  }, [allContents]);

  useEffect(() => {
    if (noRecord === true) {
      setDone(true);
    }
  }, [noRecord]);

  useEffect(() => {
    if (!home || !home.page || !home.page.ribbons || !home.page.ribbons.length) {
      return;
    }

    const ribbon = getFirstRibbonBannerTypeFromRibbonsList(home.page.ribbons);
    if (ribbon && ribbon.items) {
      setAllContents(ribbon.items);
    }
    if (!ribbon || !ribbon.items || !ribbon.items.length) {
      setNoRecord(true);
    }
  }, [home]);

  const getLandingLink = record => {
    const { slug } = record;
    if (!slug) {
      return '#';
    }
    return `/${getConfigByKey('currentLanguage')}/landing/${slug}`;
  };

  const getLinkPlay = viewContentPlaying => getDrmLinkPlay.call(viewContentPlaying);

  const onPlay = time => {
    refPlayer.current && refPlayer.current.muted(true);
  };

  const onPlayerReady = player => {
    if (!refPlayer.current && record.id) {
      refPlayer.current = player;
      player.loop(true);
      player.play();
      player.muted(true);
    }
    player.on('pause', event => {
      player.play();
    });
  };

  useEffect(() => {
    const elementMainVideo = document.getElementById('videoMain');
    let mainComponentTitle = document.getElementById('MainComponent--Title');

    const wrapVideoZoom = elementMainVideo.querySelector('.wrap-video--zoom');
    const onScroll = event => {
      if (!mainComponentTitle) {
        mainComponentTitle = document.getElementById('MainComponent--Title');
      }
      const offset = window.pageYOffset;

      if (isMobileDetect) {
        return;
      }
      try {
        const zoomValue = $(elementMainVideo).width() + Math.ceil(offset * 3);

        $('html').css({ '--zoomMainVideo': zoomValue });
        // $(wrapVideoZoom).css({ width: zoomValue });
        const value = `translateY(min(55%,${zoomValue >= 1440 ? offset : 0}px))`;
        $(mainComponentTitle).css({
          '-moz-transform': value,
          '-ms-transform': value,
          '-o-transform': value,
          transform: value,
        });
      } catch (error) {}
    };
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });

    return () => {
      refPlayer.current && refPlayer.current.dispose();
      window.removeEventListener('scroll', onScroll);
      $('html').css({ '--zoomMainVideo': '' });
    };
  }, []);

  const getImage = record => getBackdrop.call(record);

  return (
    <MainTextComponent className={`${className} MainComponent ${isMobileDetect ? 'isMobile' : ''}`}>
      <MainText />

      <div
        id="videoMain"
        className={`MainComponent--Video ${isMobileDetect ? 'MainVideoMobile' : ''}`}
        style={_.isEmpty(record) ? { display: 'none' } : {}}
      >
        <div className="wrap-video">
          <div className="wrap-video--zoom">
            {done && !geoBlocking && record.id && !_.isEmpty(linkPlay) ? (
              <Player
                id={`main-video-${record.id}`}
                className="video"
                width="100%"
                height="100%"
                controls={false}
                // autoplay={'play'}
                muted
                onReady={player => onPlayerReady(player)}
                onPlay={onPlay}
                src={linkPlay}
                content={viewContentPlaying}
              />
            ) : (
              <div className="wrap-image">
                <div className="record__image">
                  <LazyImage isBlack className="record--img" src={getImage(record)} />
                </div>
              </div>
            )}
            {/* <video controls={false} width={1000} height={600} /> */}
            <div
              className="MainComponent--Video-Container"
              // style={_.isEmpty(linkPlay) ? { top: '75%' } : {}}
            >
              <Link style={{ textDecoration: 'none' }} to={getLandingLink(record)}>
                <div className="MainComponent--Video-Container-btnPlay">
                  <img src={require('~img/icons/projectw/player/btn-play.png').default} />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </MainTextComponent>
  );
}

const mapStateToProps = state => ({
  topTenContents: state.home.topTenContents,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(VideoText);
