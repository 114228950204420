import styled from 'styled-components';
const Select = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .select__value {
    width: 100%;
    height: 56px;
    border-radius: 10px;
    font-size: 18px;
    color: #9b9b9b;
    border: 1px solid #d8d8d8;
    background: unset;
    padding-left: 11px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    z-index: 1;
  }
  .select--container {
    position: relative;
    width: 32%;
    max-width: 107px;
    svg {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0%;
      right: 6px;
      transform: translateY(100%);
      color: #9b9b9b;
      z-index: 0;
    }
  }
  @media (min-width: 1280px) {
  }
  @media (min-width: 992px) {
    .select--container {
      max-width: 183px;
    }
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 576px) {
    .select--container {
      max-width: 100%;
    }
  }
`;

export default Select;
