import styled from 'styled-components';
import { Modal, Col, Row, Button } from 'react-bootstrap';

export const ModalW = styled(Modal)`
  @media (max-width: 991px) {
    &.project-w {
      padding-left: 0px !important;
      #close {
        display: block !important;
        font-size: 15px;
        width: 25px;
        height: 25px;
        position: relative;
        left: 90%;
        top: 10px;
      }
      .modal-dialog {
        /* animation: MobilemodalSlide 0.7s ease-out forwards !important; */
      }
      @keyframes MobilemodalSlide {
        from {
          transform: translateX(200%);
          opacity: 0;
        }
        to {
          transform: translateX(0%);
          opacity: 1;
        }
      }
      .project-w--titleSecond {
        font-size: 12px !important;
        padding: 0;
        span {
          font-size: 12px !important;
        }
      }
    }
  }
  @media (max-width: 360px) {
    &.project-w {
      .project-w--titleSecond {
        /* padding: 0 75px; */
      }
    }
  }
  &.project-w {
    font-family: var(--font);
    .modal-dialog {
      /* animation: modalSlide 0.7s ease-out forwards; */
      min-height: 100%;
      height: 100%;
      width: auto;
    }
    @keyframes modalSlide {
      from {
        transform: translateX(200%);
        opacity: 0;
      }

      to {
        transform: translateX(73%);
        opacity: 1;
      }
    }
    .modal-content {
      width: max-content;
      /* display: flex; */
    }
    @keyframes modalSlide {
      from {
        transform: translateX(500%);
        opacity: 0;
      }

      to {
        transform: translateX(0);
        opacity: 1;
      }
    }
    @keyframes modalSlide__V2 {
      from {
        transform: translateX(200%);
        opacity: 0;
      }

      to {
        transform: translateX(0);
        opacity: 1;
      }
    }

    @keyframes modalSlide__V3 {
      from {
        transform: translateX(0);
        opacity: 1;
      }

      to {
        transform: translateX(200%);
        opacity: 0;
      }
    }

    .modal-body {
      color: #000000 !important;
      padding: 80px 30px 0 !important;
      justify-content: flex-start;
      &.ppv {
        max-width: 320px;
        width: 45%;
        border-right: 0.5px solid #bfbfbf;
        background-color: #fff;
        /* animation: modalSlide 1s ease-out forwards; */
        &.ppv__account {
          max-width: 320px;
          width: 100%;
          /* animation: modalSlide 0.5s ease-out forwards; */
        }
      }
      &.signIn {
        max-width: 400px;
        width: 55%;
        /* animation: modalSlide__V2 1s ease-out forwards; */
        transition: 0.5s all ease;
        &.transitionModal {
          animation: modalSlide__V3 1.3s ease-out forwards;
        }
        &.display__none {
          display: none;
        }
      }
      .ppv__form,
      .signIn__form {
        flex-shrink: 0;
        max-width: 100%;
      }
      .ppv__form {
        width: 320px;
        /* width: 40%; */
      }
      .signIn__form {
        width: 370px;
        /* width: 60%; */
      }
      @media (max-width: 767px) {
        .ppv__form {
          width: 100%;
        }
        .signIn__form {
          width: 100%;
        }
      }
      .ppv__header {
        padding-bottom: 20px;
        text-align: left;
        &__title {
          font-size: 27px;
          font-weight: 500;
          color: #000;
          text-transform: uppercase;
        }
        &__thumbnail {
          width: calc(260 / 260 * 100%);
          position: relative;
          flex-shrink: 0;
          align-self: flex-start;
          &::before {
            content: '';
            width: 100%;
            display: block;
            padding-top: calc(146 / 260 * 100%);
          }
          &--image {
            position: absolute;
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
            top: 0;
            left: 0;
          }
        }
      }
      .ppv__body {
        text-align: left;
        &__gateway {
          &__title {
            font-size: 16px;
            font-weight: 500;
          }
          &__package {
            display: flex;
            .package_Subscription {
              border: 1px solid #aeaeb2;
              width: 100%;
              text-align: center;
              cursor: pointer;
              margin-bottom: unset;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 25px;
              &__info {
                font-size: 13px;
                font-weight: 300;
                .ticket {
                  font-size: 12px;
                  color: #ef4040;
                  font-weight: 300;
                  letter-spacing: 0.025em;
                }
                .availableTxt {
                  color: #5a5a5a;
                  font-size: 12px;
                  font-weight: 300;
                  letter-spacing: 0.025em;
                }
              }
              p {
                /* padding: 25px 10px; */
                font-size: 16px;
                font-weight: 500;
                color: #999999;
                margin-bottom: 0px;
                text-transform: uppercase;
              }
              span {
                display: block;
                text-transform: initial;
              }
              &.focus {
                background: #5a5a5a;
                .package_Subscription__info {
                  .availableTxt {
                    color: #fff;
                  }
                }
                p {
                  color: #fff;
                }
              }
            }
          }
          &__packageDescription {
            ul {
              list-style: disc;
              padding: 20px;
              & > li {
                font-size: 16px;
                font-weight: 400;
                color: #636366;
                word-break: keep-all;
              }
            }
          }
          &__packagePaymentMethod {
            .select__payment {
              display: flex;
              .MuiRadio-colorSecondary.Mui-checked {
                color: #000 !important;
              }
            }
            .selected {
              display: flex;
              align-items: center;
              img {
                max-width: 88px;
                max-height: 24px;
              }
            }
            .notedPayment {
              font-size: 12px;
              display: flex;
              text-decoration: underline;
              justify-content: center;
            }
            .description__payment {
              padding: 10px 15px;
              color: #000;
              font-size: 16px;
              font-weight: 300;
            }
            .confirm__privacy {
              padding: 10px;
              .MuiIconButton-label .MuiSvgIcon-root {
                width: calc(24px / var(--scaling));
                height: calc(24px / var(--scaling));
              }
              .MuiCheckbox-colorSecondary.Mui-checked {
                color: #000 !important;
              }
              .MuiFormControlLabel-root {
                margin-right: 0;
              }
              .MuiFormControlLabel-label {
                font-size: calc(13px / var(--scaling));
                font-weight: 300;
                font-family: var(--font);
              }
              color: #000;
              padding-left: 10px;
              line-height: 133.5%;
              .privacy__terms {
                line-height: 145%;
                span {
                  /* font-size: 13px; */
                  font-weight: 300;
                  .disable__textdecoration {
                    text-decoration: none;
                    color: #000;
                    &:hover,
                    &:focus,
                    &:visited {
                      text-decoration: none;
                      color: #000;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .modal-header {
      font-family: 'Neue Haas Grotesk Display Pro';
      /* padding: 80px 30px 0px; */
      border: none;
      z-index: 999;
    }
    .modal-content {
      min-height: 100%;
      border-radius: 0 !important;
      background: #fff !important;
      color: #000000 !important;
      /* max-width: 100%; */
      margin-left: auto;
      box-shadow: unset;
    }
  }
  .project-w {
    width: 100%;
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 12px;
    &--form {
      position: relative;
      &-confirm {
        text-align: start;
        padding: 0 7px;
        a:hover {
          text-decoration: none;
        }
        &:first-of-type {
          margin-top: 14px;
        }
        &:last-of-type {
          margin-bottom: 20px;
        }

        .MuiCheckbox-root {
          padding: 0 9px;
          color: #5a5a5a;
          &:hover {
            background-color: transparent;
          }
          svg {
            font-size: 27px;
            path {
              fill: #000;
              stroke: #fff;
            }
          }
        }
        .MuiFormControlLabel-label {
          font-family: var(--font);
          text-align: start;
          font-size: 13px;
          font-weight: 500;
          font-size: 13px;
          line-height: 133.5%;
          letter-spacing: 0.025em;
          color: #000000;
        }
      }
    }
    &--Body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      position: relative;
    }
    &--showPass {
      border: none;
      background: none;
      padding: 0;
      right: 0;
    }
    &--legal {
      text-transform: uppercase;
      font-size: 12px !important;
      color: #aeaeb2 !important;
      padding-left: 8px;
    }
    &--header {
      color: #000000 !important;
      font-family: 'Neue Haas Grotesk Display Pro';
      align-items: flex-start !important;
    }
    &--title {
      margin-bottom: 20px;
      text-align: left;
    }
    &--titleSecond {
      font-size: 13px !important;
      font-weight: 400 !important;
      margin: 20px 0 40px 0px;
      letter-spacing: 0.7px;
      max-width: 380px;
      width: 100%;
      text-align: left;
      span {
        color: #000;
        margin-top: 100px;
        font-size: 14px !important;
        border-bottom: 1.5px solid #000;
      }
    }
    &--or {
      margin: 60px 0 60px 0 !important;
      color: #aeaeb2 !important;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-weight: 400;
      &:after,
      &:before {
        position: absolute;
        content: '';
        height: 1px;
        background-color: #c7c7cc;
        width: 32%;
        top: 50%;
        left: 2em;
      }
    }
    &--forgotPassword {
      margin: 16px 10px;
      font-weight: 500;
      font-size: 12px;
      color: #000000;
      letter-spacing: 0.05em;
    }
    &--signIn {
      color: #c7c7cc;
      background-color: #e5e5ea;
      font-weight: 600;
      font-size: 13px;
      letter-spacing: 0.025em;
      text-transform: uppercase !important;
      border-radius: 0 !important;
      padding: 16.5px 0;
      height: 3.8em;
      width: 100%;
      border: none;
      margin-top: 5px;
      &:focus,
      &:active,
      &:hover {
        border: none;
        outline: none !important;
        border-color: white !important;
        pointer-events: none;
        background-color: #e5e5ea;
        color: #c7c7cc;
      }
      &:not(:disabled) {
        background: #000;
        color: #fff;
        pointer-events: all;
      }
    }
    &--watchmore {
      color: #fff;
      background-color: #000;
      font-weight: 600;
      font-size: 13px;
      letter-spacing: 0.025em;
      text-transform: uppercase !important;
      border-radius: 0 !important;
      padding: 16.5px 0;
      width: 100%;
      border: none;
      margin: 50px 0;
      &.paynow {
        margin-top: 18px;
      }
      &:focus,
      &:active,
      &:hover {
        border: none;
        background-color: #000 !important;
        color: #fff !important;
      }
      &:disabled {
        box-shadow: none;
        opacity: 0.65;
        cursor: none;
        pointer-events: none;
        &:focus,
        &:active,
        &:hover {
          border: none;
          background-color: #000 !important;
          color: #fff !important;
          opacity: 0.65;
        }
      }
      &.freeTicket {
        background-color: #fff;
        color: #000;
        border: 1px solid #000;
        &:focus,
        &:active,
        &:hover {
          background-color: #fff !important;
          color: #000 !important;
        }
      }
    }

    .Correct-True {
      background-color: #000;
    }
    &--btnFacebook {
      border-radius: 0 !important;
      color: #000000 !important;
    }
  }
  &.v-loginForm {
    &.project-w {
      padding-right: 0 !important;
      .modal-dialog {
        transform: translateX(100%);
        transition: transform 0.7s ease-out;
      }
      &.fade.in .modal-dialog {
        transform: translateX(0);
      }
    }
    z-index: 9999;
    font-size: 10px;
    /* .modal-dialog {
      max-width: 58.8em;
    } */
    .modal-content {
      background: #1e232a;
      border-radius: 0.8em;
      color: #fff;
    }
    .modal-body {
      padding: 3.5em 2.5em 6em;
    }
  }
  .v-loginForm {
    color: #fff;
    &--close {
      color: #9a9a9a;
      width: 1em;
    }
    &--row {
      display: flex;
      justify-content: center;
      text-align: center;
      flex-direction: column;
    }
    &--header {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      flex-direction: column;
    }
    &--title {
      font-size: 2.4em;
      font-weight: 500;
    }
    &--odm {
      font-size: 2.4em;
      font-weight: bolder;
      margin-bottom: 0.8em;
    }
    &--btn {
      background: ${({ theme }) => theme.primaryColor};
      color: rgb(255, 255, 255);
      font-size: 1.5rem;
      width: 100%;
      margin: 0.75rem 0px;
      padding: 1rem 0px;
      text-transform: capitalize;
      font-weight: 300;
      border: none;
      &__gray {
        background: #7d7d7d;
      }
    }
    &--or {
      color: #949494;
      position: relative;
      font-size: 1.4em;
      margin-bottom: 0.55em;
      &:after,
      &:before {
        position: absolute;
        content: '';
        height: 1px;
        border: 1px solid #e6ecef;
        width: 32%;
        top: 50%;
        left: 2em;
      }
      &:after {
        left: unset;
        right: 2em;
      }
    }
    &--authTemplate {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
    }
    &--legal {
      color: #b8b6b6;
      font-size: 1.4em;
      font-weight: 500;
    }
    &--showPass {
      position: absolute;
      right: 0.35em;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0.2em;
      font-size: 3em;
      color: #6e6e6e;
      cursor: pointer;
    }
    &--forgot {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.6em;
      &-checkbox {
        font-size: 1.4em;
        color: #b8b6b6;
        margin: 0;
        input {
          display: none;
          + span {
            position: relative;
            padding-left: 1rem;
            &:before,
            &:after {
              font: normal normal normal 14px/1 FontAwesome;
              display: inline-block;
              position: absolute;
              background-color: #f5f5f5;
              border: 1px solid ${({ theme }) => theme.primaryColor};
              content: '';
              width: 20px;
              height: 20px;
              top: 50%;
              right: 100%;
              transform: translate(0, -50%);
              border-radius: 2px;
            }
          }
        }
        input:checked {
          + span {
            &:before {
              border: 1px solid ${({ theme }) => theme.primaryColor};
            }
            &:after {
              color: ${({ theme }) => theme.primaryColor};
              background-color: #fff;
              content: '\f00c';
              font-size: 10px;
              line-height: 18px;
            }
          }
        }
      }
    }
    &--forgotPassword {
      display: flex;
      color: ${({ theme }) => theme.fourColor};
      padding: 0;
      font-size: 1.4em;
      font-weight: 400;
    }
    &--signIn {
      font-size: 1.8em;
      color: #fff;
      text-transform: capitalize;
      padding: 1em 0;
      border-radius: 10px;
      width: 100%;
      border: none;
      background: ${({ theme }) => theme.primaryColor};
      margin-top: 0.89em;
      &:focus,
      &:active,
      &:hover {
        background: ${({ theme }) => theme.primaryColor};
        border: none;
      }
    }
    &--signUp {
      width: 100%;
      margin-top: 47px;
      font-size: 1.8em;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &__btn {
        color: #fff;
        text-transform: capitalize;
        padding: 0.835em 0;
        border-radius: 10px;
        width: 100%;
        border: 1px solid #d8d8d8;
        background: unset;
        &:focus,
        &:active,
        &:hover {
          background: unset;
          border: 1px solid #d8d8d8;
        }
      }
    }
  }

  @media (min-width: 576px) {
    &.v-loginForm {
      .modal-dialog {
        margin: auto;
      }
    }
  }
  @media (min-width: 768px) {
    &.v-loginForm {
      &.project-w {
        .modal-dialog {
          margin-right: 0;
        }
      }
      .modal-body {
        padding: 3.5em 8em 6em;
      }
    }
    .v-loginForm {
      &--or {
        &:after,
        &:before {
          width: 38%;
        }
      }
    }
  }
  @media (min-width: 1440px) {
    &.v-loginForm {
      &.project-w {
        &.fade.in .modal-dialog {
          transform: translate(0);
          /* margin-right: calc((100vw - 1440px) / 2 - 10px); */
        }
      }
    }
  }
  @media (max-width: 767px) {
    &.v-loginForm {
      &.project-w {
        .project-w--titleSecond {
          padding: 0 !important;
        }
        .modal-dialog {
          margin: 0;
          padding: 1rem;
        }
        .modal-content {
          margin: 0;
          width: 100%;
        }
      }
    }
  }
`;

export const ContainerFragment = styled.div`
  .ppv {
    &-btn {
      font-family: var(--font);
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 133.5%;
      /* identical to box height, or 24px */

      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      color: #000000;
      padding: 15px;
      width: 100%;
      background: #ffffff;
      border: 1px solid #000000;
      &.active {
        color: #fff;
        background: #000000;
      }
      img {
        max-width: 88px;
        max-height: 24px;
      }
      &--buy {
        margin-bottom: 25px;
        font-weight: 500;
      }
    }
    &-payments {
      margin-top: 13px;

      button {
        border: 2px solid #000000;
        margin-bottom: 6px;
      }
      &--label {
        font-family: inherit;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 133.5%;
        letter-spacing: 0.05em;
        margin-left: 0;
        margin-right: 0;
      }
      &__pg {
        margin: 14px 0;
        padding-left: 23px;
        padding-right: 13px;
      }
    }
  }
  .MuiDivider-root {
    background-color: #000000;
  }
  @keyframes modalSlide {
    from {
      transform: translateX(500%);
      opacity: 0;
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes modalSlide_V2 {
    from {
      transform: translateX(0);
      opacity: 0;
    }

    to {
      transform: translateX(200%);
      opacity: 1;
    }
  }
  &.ModalBig {
    display: flex;
    animation: modalSlide 0.3s ease-out forwards;
    min-height: 100vh;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    &.ModalBig {
      .modal-body {
        &.ppv,
        &.signIn {
          max-width: 100%;
        }
      }
    }
  }
  @media (max-width: 767px) {
    &.ModalBig {
      position: relative;
      width: 100%;
      .modal-body {
        &.ppv,
        &.signIn {
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
        }
        &.ppv {
          z-index: 1;
          max-width: 100% !important;
          border: none !important;
          max-width: 100%;
        }
        &.signIn {
          z-index: 10;
          background-color: #fff;
          max-width: 100%;
        }
      }
    }
  }
`;
