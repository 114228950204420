// Get the Component base class from Video.js
import { pushEventFirebase } from './../components/pushEventFirebase';
import videojs from 'video.js';
import jquery from 'jquery';
import '../components/Warning_AgeRestrictions/style/style.scss';
var Button = videojs.getComponent('Button');

// The videojs.extend function is used to assist with inheritance. In
// an ES6 environment, `class SeekNext extends Component` would work
// identically.

var SeekNext = videojs.extend(Button, {
  // The constructor of a component receives two arguments: the
  // player it will be associated with and an object of options.
  constructor: function (player, options) {
    // It is important to invoke the superclass before anything else,
    // to get all the features of components out of the box!
    Button.apply(this, arguments);
    // If a `text` option was passed in, update the text content of
    // the component.
  },
  // The `createEl` function of a component creates its DOM element.
  createEl: function () {
    const btn = videojs.dom.createEl('button', {
      className: 'vjs-control vjs-button vjs-seek-next',
    });
    btn.innerHTML = '<i class="fa fa-repeat vjs-icon-placeholder" aria-hidden="true"></i>';
    return btn;
  },

  handleClick: function (event) {
    let duration = 15;
    const that = this.options_.component;
    const entityDetail = this.options_.entity;
    if (this.options_ && this.options_.duration && typeof this.options_.duration === 'number') {
      duration = this.options_.duration;
    }
    pushEventFirebase(that.entityDetail, 'fast-forward');
    const currentTime = this.player_.currentTime() + duration;
    this.player_.currentTime(currentTime);
  },
});

// Register the component with Video.js, so it can be used in players.
videojs.registerComponent('SeekNext', SeekNext);
