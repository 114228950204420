import React from 'react';
import { isNode } from '~utils/ssr';

const TopContents = React.memo(props => {
  const { item } = props;
  const index = item.top_index;
  if (index <= 9 && index >= 0 && !isNode()) {
    return (
      <div className="ribbonTicketTopContent">
        <img src={require(`~img/img/top${index + 1}.png`).default} />
      </div>
    );
  }
  return null;
});

export default TopContents;
