import React from 'react';
import { DialogStyled } from '~features/profile/projectw/components/style/TabRight';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { Box, Button, IconButton, Typography } from '@material-ui/core';

function ModalPaymentUnSuccess(props) {
  const { paymentError, setPaymentError } = props;

  const handleClose = () => {
    setPaymentError(false);
  };

  return (
    <DialogStyled
      open={paymentError}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="dialogStyled"
    >
      <DialogTitle className="title" id="alert-dialog-title">
        Something Went Error
      </DialogTitle>
      <DialogContent className="content">
        <DialogContentText
          className="content-Txt"
          id="alert-dialog-description"
          style={{ maxWidth: '340px' }}
        >
          Please try again
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="btnAction Confirm" onClick={handleClose}>
          OK
        </Button>
      </DialogActions>
    </DialogStyled>
  );
}

export default ModalPaymentUnSuccess;
