import styled from 'styled-components';
import { Button, Modal } from 'react-bootstrap';

const fb = require('../../../assets/icons/facebook.svg').default;
const tw = require('../../../assets/icons/twitter.svg').default;

export const ButtonStyled = styled(Button)`
  border-radius: 50%;
  color: #fff;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  background: none;
  border: 1px solid #fff;
  svg {
    font-size: 1rem;
  }
  :hover,
  :active,
  :focus {
    background: #fff;
    border: 1px solid ${({ theme }) => theme.landingPage.hoverButton};
    color: #000000;
  }
  :focus {
    outline: none;
    box-shadow: none;
  }
`;

export const ModalStyled = styled(Modal)`
  &.popup-share {
    font-size: 1rem;
    display: flex;
    .modal-dialog {
      width: auto;
    }
  }
  .modal-content {
    border-radius: 10px;
  }
  .modal-header {
    padding: 0;
    border: none;
    .close {
      z-index: 9;
      position: absolute;
      right: 1rem;
      top: 0.25rem;
      font-weight: 100;
      opacity: 1;
      font-size: 1.75rem;
    }
  }
  .modal-body {
    color: #666666;
    padding: 1rem 1.5rem;
    padding-bottom: 4rem;
  }
  .popup-share {
    &-thumbnail {
      padding-top: calc(9 / 16 * 100%);
      position: relative;
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: contain;
        top: 0;
        box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
        border-radius: 10px 10px 0 0;
      }
    }
    &--title {
      color: #2574d4;
      font-weight: 400;
      font-size: 1.5rem;
      border-bottom: 1px solid #666666;
      padding-bottom: 1rem;
      font-weight: 500;
    }
    &--description {
      margin-bottom: 1.5rem;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical !important;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: calc(1rem * 5 * 1.5);
      line-height: 1.35rem;
    }
    &__wrapClipboard {
      position: relative;
      &--label {
        font-size: 1.75rem;
        font-weight: bolder;
      }
      &--tooltip {
        position: absolute;
        top: 20%;
        right: 0;
      }
      &--copy {
        border: 1px solid #dbdbdb;
        border-radius: 5px;
        display: flex;
        padding: 0.1rem;
        justify-content: space-between;
        align-items: center;
        span {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical !important;
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: calc(1rem * 1.5);
          font-size: 1rem;
          line-height: 1.5rem;
          padding: 0 1rem;
        }
      }
      &--btn {
        background: #c2c0c0;
        color: #fff;
        padding: 4px 6px;
        i {
          padding: 0 0.25rem;
        }
        &:hover {
          background: #2574d4;
        }
      }
    }
    &--btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 0.5rem;
      align-items: center;
      font-size: 0.85rem;
    }
    &__wrap-share {
      a {
        color: #666666;
        text-decoration: none;
        &:hover {
          color: #666666;
        }
      }
      span {
        font-size: 0.825rem;
      }
      margin-top: 1rem;
      display: flex;
      justify-content: flex-start;
      &--logo-fb {
        background-image: url(${fb});
      }
      &--logo-twitter {
        background-image: url(${tw});
      }
      &--logo-twitter,
      &--logo-fb {
        background-repeat: no-repeat;
        height: 70px;
        width: 70px;
      }
    }
  }
  @media (min-width: 768px) {
    &.popup-share {
      top: 5%;
      /* transform: translateY(-35%); */
      .modal-dialog {
        width: 490px;
      }
    }
  }
`;
