import { ClickAwayListener, LinearProgress, Popper } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FavoriteIcon from '@material-ui/icons/Favorite';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LazyImage } from '~components';
import {
  getLandingLink,
  getProgressPercent,
  getThumbnail9_5,
  timeFormatDuration,
} from '~core/method/contentMethod';
import ModalDialog from '~features/profile/projectw/components/ModalDialog';

const RecordContentStyledMobile = styled.div`
  .isMobile {
    &-Link {
      text-decoration: none;
      &--ComponentItem {
        padding: 8px;
        display: flex;
        justify-content: space-around;
        border-bottom: 0.5px solid #000;
        .linear__progress {
          background-color: transparent;
          .MuiLinearProgress-bar {
            background-color: #e92121;
          }
        }
        &--wrapImage {
          position: relative;
          &:before {
            content: '';
            display: inline-block;
            padding-top: calc(5 / 9 * 100%);
          }
          max-width: 100%;
          width: 50%;
          position: relative;
          img {
            position: absolute;
            width: 100%;
            height: 100%;
            min-height: 95px;
            object-fit: cover;
            object-position: center;
            display: block;
            top: 0;
            left: 0;
          }
          .category {
            position: absolute;
            background: #fff;
            color: #000;
            font-weight: 400;
            padding: 3px;
            max-width: 100px;
            border: none;
            span {
              font-size: 12px;
            }
          }
        }
        &--Content {
          max-width: 100%;
          width: 50%;
          position: relative;
          display: flex;
          flex-direction: column;
          padding-left: 8px;
          .Title,
          .TimeAndType,
          .category {
            text-decoration: none !important;
            color: #000;
            font-weight: 400;
            &:not(:first-child) {
              margin-right: 10px;
            }
          }
          .SubContent {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            margin-top: auto;
            width: 100%;
            .vodlist {
              font-weight: 500;
            }
            .Around {
              margin: 0 6px;
              width: 5px;
              height: 5px;
              border-radius: 100%;
              background-color: #000;
              position: relative;
              bottom: 4px;
            }
          }
          .Title {
            display: -webkit-box;
            max-height: 3.2rem;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            -webkit-line-clamp: 2;
            line-height: 1.6rem;
            &:hover,
            &:active,
            &:visited,
            &:target,
            &:focus,
            &:focus-within,
            &:focus-visible {
              color: #000;
              text-decoration: none;
              outline: none;
            }
          }
          .favoritePage {
            display: flex;
            margin-left: auto;
            cursor: pointer;
            margin-bottom: 10px;
            .favorite-icon {
              font-size: 30px;
              & > path {
                color: #000;
              }
            }
          }
        }
      }
    }
  }
`;

const ButtonStyledFavorite = styled.div`
  background-color: #000;
  color: #fff;
  border-radius: 0;
  padding: 9px 10px;
  width: 100%;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 146%;
  text-align: center;
  letter-spacing: 0.025em;
  cursor: pointer;
`;

const CONINUE_RECORD = 'continue';

function RecordContentMobile(props) {
  const {
    item,
    index,
    favoritepage,
    handleAddFavorite = () => {},
    types,
    handleRemoveContentWatching,
    vodlist,
  } = props;
  const { i18n, t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModalConfirm, setOpenModalConfirm] = React.useState(false);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleClickOpen = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const timeFormat = item => {
    return timeFormatDuration.call(item);
  };

  const getLinkDetailContent = item => {
    return getLandingLink.call(item);
  };
  const handleOpenModalConfirm = () => {
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleConfirmRemoveContentWatching = contentId => {
    handleRemoveContentWatching(contentId);
    handleCloseModalConfirm();
  };

  if (types === CONINUE_RECORD) {
    return (
      <RecordContentStyledMobile className="isMobile-Link" key={index}>
        <div className="isMobile-Link--ComponentItem">
          <Link to={getLinkDetailContent(item)} className="isMobile-Link--ComponentItem--wrapImage">
            <LazyImage className="image" src={getThumbnail9_5.call(item)} />
            <LinearProgress
              className="linear__progress"
              variant="determinate"
              value={getProgressPercent.call(item)}
            />
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
            >
              {/* <div className="category">
                <span>
                  {!_.isEmpty(item.content_categories) &&
                    item.content_categories.map(cate => cate.name)}
                </span>
              </div> */}
            </div>
          </Link>
          <div className="isMobile-Link--ComponentItem--Content">
            <Link to={getLinkDetailContent(item)} className="Title">
              {item.title}
            </Link>
            <div className="SubContent">
              {/* {_.compact([timeFormat(item)]).map((cate, index) => (
                <>
                  {index === 0 ? null : <div className="Around"></div>}

                  <p className="category">{typeof cate === 'string' ? cate : cate.name}</p>
                </>
              ))} */}
              <div onClick={handleOpenModalConfirm} className="favoritePage">
                <DeleteOutlineIcon style={{ fontSize: '27px' }} className="favorite-icon" />
              </div>
              <ModalDialog
                open={openModalConfirm}
                close={handleCloseModalConfirm}
                actionConfirm={() => handleConfirmRemoveContentWatching(item.id)}
                title={t('projectw__MyPage.continueWatchingTab.titleConfirmRemove')}
                txtConfirm={t('projectw__MyPage.continueWatchingTab.yes')}
                txtCancel={t('projectw__MyPage.continueWatchingTab.no')}
                description={t('projectw__MyPage.continueWatchingTab.descriptionConfirmRemove')}
                favoritepage
              />
            </div>
          </div>
        </div>
      </RecordContentStyledMobile>
    );
  }
  return (
    <RecordContentStyledMobile className="isMobile-Link" key={index}>
      <div className="isMobile-Link--ComponentItem">
        <Link to={getLinkDetailContent(item)} className="isMobile-Link--ComponentItem--wrapImage">
          <LazyImage className="image" src={getThumbnail9_5.call(item)} />
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          >
            {/* <div className="category">
              <span>
                {!_.isEmpty(item.content_categories) &&
                  item.content_categories.map(cate => cate.name)}
              </span>
            </div> */}
          </div>
        </Link>
        <div className="isMobile-Link--ComponentItem--Content">
          <Link to={getLinkDetailContent(item)} className="Title">
            {item.title}
          </Link>
          <div className="SubContent">
            {vodlist && !_.isEmpty(item.content_categories) && (
              <p className="category vodlist">{item.content_categories.map(cate => cate.name)}</p>
            )}
            {!vodlist &&
              _.compact([timeFormat(item)]).map((cate, index) => (
                <>
                  {index === 0 ? null : <div className="Around"></div>}

                  <p className="category">{typeof cate === 'string' ? cate : cate.name}</p>
                </>
              ))}
            {favoritepage && (
              <>
                <div onClick={handleClickOpen} className="favoritePage">
                  <FavoriteIcon className="favorite-icon" />
                </div>
                <Popper id={id} open={open} anchorEl={anchorEl} placement={'top'}>
                  <ClickAwayListener onClickAway={() => setAnchorEl(false)}>
                    <ButtonStyledFavorite
                      onClick={() => {
                        handleAddFavorite(item);
                        setAnchorEl(false);
                      }}
                    >
                      Remove from Favorites
                    </ButtonStyledFavorite>
                  </ClickAwayListener>
                </Popper>
              </>
            )}
          </div>
        </div>
      </div>
    </RecordContentStyledMobile>
  );
}

export default RecordContentMobile;
