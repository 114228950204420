import styled from 'styled-components';
const FilterMobile = styled.div`
  &.filterMobile {
    /* z-index: 99999; */
    display: flex;
    align-items: center;
    color: #fff;
    width: 100%;
    &-youtube {
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      padding: 0 0 16px;
      .filterMobile--toggle {
        padding: 0.25rem 1rem 0.25rem 0.5rem;
      }
    }
  }
  .filterMobile {
    &--toggle {
      margin-top: 12px;
      background: #f2f2f2;
      cursor: pointer;
      font-size: 16px;
      color: #627280;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.35rem 1rem 0.35rem 0.5rem;
      border-radius: 8px;
      margin-left: 15px;
      margin-bottom: 15px;
      svg {
        width: 24px;
        height: 24px;
        padding-right: 0.5rem;
      }
    }
    &__wrap {
      position: fixed;
      top: 0;
      left: -100%;
      z-index: 70;
      width: 100%;
      height: 100%;
      border-right: 1px solid #1e232a;
      background-color: #141414;
      transition: left 0.3s;
      z-index: 999;
    }
    &--visible {
      top: 0;
      left: 0;
      display: block;
      overflow-y: auto;
      opacity: 1;
      visibility: visible;
    }
    &__head {
      display: flex;
      justify-content: space-between;
      padding: 1.25rem 1.785rem;
      span {
        font-size: 16px;
        color: #ffffff;
        text-decoration: none solid rgb(255, 255, 255);
      }
      /* box-shadow: 0 1px 0 0 #242424; */
    }
    &__closeButton {
      cursor: pointer;
      display: flex;
      svg {
        width: 1.71rem;
        height: 1.71rem;
        color: #9a9a9a;
      }
    }
    &--body {
      .v-select-filter {
        display: flex;
        flex-direction: column;
      }
      .v-select-filter__col {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 275px;
        width: 100%;
        &--actions {
          justify-content: space-between;
        }
        &--btn {
          width: auto;
          background: #ed5858;
          border: none;
          color: #fff;
          min-width: 125px;
          padding: 0.75rem 0;
          &-apply {
            background: ${({ theme }) => theme.primaryColor};
          }
        }
      }
      .v-select-filter {
        &-regions,
        &-languages,
        &-genres {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
      }
      .Select {
        &__control {
          background-color: #e5e5e5;
          border-radius: 38px;
          width: 100%;
          padding: 0 1.3rem;
        }
        &__menu {
          border-radius: none;
          box-shadow: none;
          transform: translateX(0%);
          width: 100%;
          background: none;
          .Select__option {
            width: 50% !important;
          }
          &-list {
            max-height: unset;
            overflow-y: unset;
            display: flex;
            flex-wrap: wrap;
            background: #141414;
            color: #627280;
          }
        }
        &__option {
          &--is-selected {
            background: none;
            color: #2574d4;
            font-weight: bold;
          }
        }
      }
    }
  }
`;
export { FilterMobile };
