import styled from 'styled-components';

const Ribbons = styled.div`
  &.ribbonContainer {
    width: 100%;
    display: block;
    float: left;
    overflow: hidden;
    background-color: #f5f5f5;
    font-size: 16px;
    .ribbonHead {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 0;
      h4.title {
        color: ${({ theme }) => theme.ribbon.titleColor};
        padding-left: 0px;
        max-width: 78%;
        z-index: 11;
        text-transform: capitalize;
        font-size: 20px;
        margin-right: 20px;
        font-weight: bold;
        margin-bottom: 13px;
      }
      h5.title_action {
        font-weight: 200;
        font-size: 16px;
        color: ${({ theme }) => theme.ribbon.viewAll};
        padding-right: 15px;
        cursor: pointer;
        white-space: nowrap;
        i {
          color: ${({ theme }) => theme.ribbon.viewAll};
          font-size: 1rem;
        }
      }
      a.viewAll {
        font-size: 1.125rem;
        color: #0095ff;
        padding-right: 0;
        margin-right: 0;
        float: right;
        text-decoration: none;
        z-index: 11;
      }
    }
    .see-more {
      .btn-see-more {
        width: 100%;
        position: absolute;
        height: 100%;
        top: 0;
        background: #8f8f8f;
        color: #dedede;
        text-transform: capitalize;
        transform: scale(1) !important;
        border: none;
        p {
          font-size: 1.5rem;
        }
        &:hover {
          background: #2574d4;
          color: #dedede;
        }
        &:focus {
          outline: none;
        }
      }
    }
    .prevButton {
      left: 0;
      background: linear-gradient(90deg, rgba(17, 18, 18, 0.7) 30%, rgba(17, 18, 18, 0));
    }
    .nextButton {
      background: linear-gradient(270deg, rgba(17, 18, 18, 0.7) 30%, rgba(17, 18, 18, 0));
      right: 0;
    }
    .customiseSlickBtn {
      height: calc(100% - 16px * 4) !important;
    }
    .prevButton,
    .nextButton {
      width: 30px;
      visibility: visible;
      position: absolute;
      z-index: 9;
      top: 0;
      cursor: pointer;
      text-decoration: none;
      opacity: 1;
      transition: visibility 1s linear 0.1s, opacity 0.1s linear;
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .prevButtonIcon,
      .nextButtonIcon {
        transition: font-size 0.5s ease-out 100ms;
        font-size: 1rem;
        color: #ffffff;
      }
    }
  }

  .sliderBlock {
    clear: both;
    width: 100%;
  }

  .icon_chevron_right {
    font-size: 16px;
    color: #3e3e3e;
    margin-left: 8px;
  }

  &.ribbonItemContainer {
    margin-right: 1rem;
    overflow: hidden;
    .loading-placeholder {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      span {
        width: 100%;
        height: 100%;
        display: flex;
      }
    }
    a {
      color: #444444;
      display: block;
      text-decoration: none;
    }
    &:hover {
      a .srcImg {
        transform: scale(1.1);
        overflow: hidden;
        -webkit-box-shadow: 0px 0px 19px -3px rgba(71, 71, 71, 1);
        -moz-box-shadow: 0px 0px 19px -3px rgba(71, 71, 71, 1);
        box-shadow: 0px 0px 19px -3px rgba(71, 71, 71, 1);
      }
      .description .text {
        display: block;
      }
      button.remove {
        opacity: 1;
        background-color: rgba(189, 2, 2, 0.8);
        z-index: 9;
        color: #fff;
      }
    }
    .description {
      display: block;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .text {
        clear: both;
        padding: 0px 0 14px;
        overflow: hidden;
        display: none;
      }
      .icon_play {
        margin-right: 10px;
        color: #959596;
      }
      .play {
        float: left;
        font-size: 14px;
        height: 16px;
        line-height: 16px;

        font-weight: 400;
      }
      .heart {
        font-size: 14px;
        float: right;
        height: 16px;
        line-height: 16px;
        /* margin-left: 3%; */

        font-weight: 400;
      }
      span.subTitle {
        color: #959596;
        font-size: 13px;
      }
    }
    h6 {
      font-size: 14px;
      /* padding: 14px 0; */

      font-weight: bold;
      color: ${({ theme }) => theme.ribbon.itemNameColor};
    }
    .imageWrapper {
      width: 100%;
      margin-bottom: 0.5rem;
      padding: 0;
      border: none;
      overflow: hidden;
      border-radius: 6px;
      position: relative;
      padding-top: calc(9 / 16 * 100%);
      z-index: 1;
      &.thumbnail {
        background-color: unset;
      }
      &.poster {
        padding-top: calc(40 / 27 * 100%);
      }
      .srcImg {
        display: block;
        width: 100%;
        transition: all 200ms ease-out;
        transform: scale(1);
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: top;
        &--noImg {
          transform: scale(2);
        }
      }
    }
    .remove {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      text-align: center;
      opacity: 0;
      border: none;
      background: none;
      transition: opacity 0.35s ease;
    }
    .ribbonItemContainer-icon-remove {
      &__favorite {
        .btnRemove {
          font-size: 24px;
          color: #fff;
          cursor: pointer;
          border-radius: 5px;
          padding: 5px;
          display: flex;
          width: 35px;
          height: 35px;
          &:hover {
            background: #bd0202;
          }
        }
      }
      display: flex;
      justify-content: space-between;
      /* max-height: 2.5rem; */
      font-size: 1rem;
      font-weight: 500;
      color: #333333;
      button {
        background: #000000;
        color: #fff;
        width: 1.75rem;
        max-height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: #000000;
        padding: 5px;
        font-size: 1rem;
        border-radius: 15px;
        i {
          color: #627280;
          font-size: 14;
          padding-right: '0.5rem';
        }
        &:hover {
          background: #bd0202;
          border: #bd0202;
          color: #fff;
          border-radius: 5px;
          i {
            color: #fff;
          }
        }
        &:focus {
          background: #000000;
          border: #000000;
          color: #fff;
          i {
            color: #fff;
          }
        }
      }
    }
    &.small {
      width: 90%;
    }
  }
  .ribbonItemContainer {
    &--subTille {
      font-size: 14px;
      color: #9b9b9b;
      display: flex;
      flex-wrap: wrap;
      a {
        font-size: 14px;
        color: #9b9b9b;
        padding-left: 6px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &.ribbonItemContainer {
    .ribbonTicketTopContent {
      position: absolute;
      right: 0;
      width: 28%;
      height: 100%;
      bottom: 0;
      img {
        min-height: 0;
        width: 100%;
        height: 100%;
        transform: scale(1) !important;
        &:hover {
          transform: scale(1);
        }
      }
    }
    .poster .ribbonTicketTopContent {
      width: 38%;
      height: 50%;
    }
    .ribbonTicketYotube {
      position: absolute;
      left: 0.75rem;
      width: 4.5rem;
      bottom: 0.75rem;
      img {
        min-height: 0;
        width: 100%;
        height: auto;
        transform: scale(1) !important;
        &:hover {
          transform: scale(1);
        }
      }
    }
    .ribbonTicket {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: absolute;
      top: 0.5rem;
      right: 0.75rem;
      width: 100%;
      flex-direction: row-reverse;
      .ribbonTicketLive {
        font-weight: 700;
        font-size: 0.75rem;
        padding: 0.025rem 0.5rem;
        text-transform: uppercase;
        color: #ff0000;
        background: #fff;
        border-radius: 20px;
        i {
          padding-right: 0.25rem;
        }
      }
      .ribbonTicketVip {
        margin-right: 0.3rem;
        position: unset;
        top: unset;
        right: unset;
        i {
          padding: 0.1rem;
        }
      }
    }

    .ribbonTicketVip {
      font-size: 10px;
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
      padding: 0.2rem;
      color: #fff;
      border-radius: 50%;
      background-color: #e8b600;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        padding: 0.125rem;
      }
    }
    .ribbonTicketTvod {
      font-size: 10px;
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
      padding: 0.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .ribbonTicketNoLive {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      top: 2%;
      color: #fff;
      .span {
        text-transform: none;
        font-size: 0.75rem;
        font-weight: 100;
        text-align: right;
      }
    }
  }

  &.ribbonContainer .sliderBlock .slick-slider {
    z-index: 100;
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  @media (min-width: 768px) {
    &.ribbonContainer {
      &:hover {
        .prevButton,
        .nextButton {
          visibility: visible;
          opacity: 0.9;
          transition-delay: 0s;
          /* background-color: rgba(69, 69, 69, 0.6); */
          text-decoration: none;
          &:hover {
            .prevButtonIcon,
            .nextButtonIcon {
              font-size: 1.85rem;
            }
          }
        }
      }
      .prevButton,
      .nextButton {
        width: 60px;
        visibility: hidden;
        opacity: 0;
        border-radius: 10px 0 0 10px;
      }
    }
  }
  @media (min-width: 992px) {
    &.ribbonItemContainer {
      .ribbonTicketYotube {
        width: 5rem;
      }
    }
    &.ribbonContainer {
      .ribbonHead {
        h4.title {
          font-size: 24px;
          margin-bottom: 11px;
        }
      }
    }
  }
`;

export default Ribbons;
