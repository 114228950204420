import * as actions from './actions';
import * as appAPIs from '../api/appAPIs';
import { store } from '../store/index';
import * as configApi from '../api/config';
import { isNode } from '~utils/ssr';
import * as types from '~features/tenantConfig/actions';
import { tenantEnvConfig } from '~constants/tenantConfig/tenantEnvConfig';

export { initialApp, changeLanguage, getMuxEnvKey, getRegionSubcription, getConfigTemplateContent };

function initialApp() {
  return dispatch => {
    dispatch(actions.initialApp());
  };
}

function changeLanguage(value) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(actions.changeLanguage(value));
      resolve();
    });
  };
}

function getMuxEnvKey() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      configApi
        .getMuxEnvKey()
        .then(response => {
          dispatch(actions.updateMuxKey(response.results));
          resolve(response);
        })
        .catch(errors => {
          reject(errors);
        });
    });
  };
}

function getRegionSubcription(req = null) {
  return dispatch => {
    const dpStore = req ? req.store.dispatch : dispatch;
    return new Promise((resolve, reject) => {
      configApi
        .getRegionSubcription()
        .then(response => {
          dpStore(actions.updateRegionSubcription(true));
          resolve(response);
        })
        .catch(errors => {
          dpStore(actions.updateRegionSubcription(false));
          if (isNode()) return resolve(false);
          reject(errors);
        });
    });
  };
}

const getConfigTemplateContent = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      configApi
        .getTenantPageTemplate()
        .then(response => {
          let envConfig = {
            ...tenantEnvConfig,
            platformConfig: {
              ...response,
            },
          };
          dispatch(types.getConfigsSuccess(envConfig));
          resolve(response);
        })
        .catch(errors => {
          reject(errors);
        });
    });
  };
};
