import { TENANT_NAME_ENUM } from '~core/constants';
import { getConfigByKey } from '~features/tenantConfig/services';
import _ from 'lodash';
import { IAuth } from '~core/models';

export function isLogged(this: IAuth) {
  const self: IAuth = this;
  try {
    return self.isLogin && !_.isEmpty(self.account);
  } catch (error) {}
  return false;
}

export function isSameAccount(this: IAuth, otherAuth: IAuth) {
  try {
    const self = this;
    const currentAccount = self.account;
    const otherAccount = otherAuth.account;
    const currentGuestAccount = self.guestAccount;
    const otherGuestAccount = otherAuth.guestAccount;

    if (currentAccount === null && otherAccount === null) {
      return true;
    }
    if (!_.isEmpty(currentGuestAccount) && !_.isEmpty(otherGuestAccount)) {
      return true;
    }

    if (currentAccount && otherAccount && currentAccount.profile && otherAccount.profile) {
      return currentAccount.profile.id === otherAccount.profile.id;
    }
  } catch (error) {}
  return false;
}

export function isProjectW() {
  try {
    if (getConfigByKey('ui_theme') === TENANT_NAME_ENUM.PROJECTW) {
      return true;
    }
  } catch (error) {
    return false;
  }
  return false;
}

export function isLoggedVerifiedEmail(this: IAuth) {
  const self: IAuth = this;

  try {
    const { profile = {} } = self.account || {};
    return self.isLogin && !_.isEmpty(self.account) && profile.email_confirmed;
  } catch (error) {}
  return false;
}

export function isLoggedAndPaidedContent(
  this: IAuth,
  {
    canWatch,
    hasToken,
  }: {
    canWatch: boolean;
    hasToken: boolean;
  },
) {
  const self: IAuth = this;

  try {
    const auth = self;
    if (canWatch && isLogged.call(auth) && !hasToken) {
      return true;
    }
  } catch (error) {
    return false;
  }
  return false;
}
