import moment from 'moment';
import jquery from 'jquery';

const initScubberThumbnail = component => {
  const player = component.videoPlayer;
  const entityDetail = component.entityDetail;
  const $player = jquery(player.player_.el_);
  const controlBar = player.getChild('controlBar');
  const progressControl = controlBar.getChild('ProgressControl');
  if (!entityDetail.scrubberThumbnail) {
    return;
  }
  const { url_pattern, alignment_distance } = entityDetail.scrubberThumbnail;
  $player.find('.vjs-mouse-display').append(`
  <div class='vjs-scubber-thumbnail'>
    <div class='lazyload-wrapper'>
      <img class='vjs-thumbnail-src' src=${
        url_pattern + '10.jpg'
      } transitionname="fade" transitionappeartimeout="500"/>
    </div>
  </div>`);
  progressControl.on('mousemove', () => {
    let currentTimeHover = $player.find('.vjs-mouse-display .vjs-time-tooltip').html();
    let iframe = 0;
    if (currentTimeHover.length === 5) {
      currentTimeHover = `0:${currentTimeHover}`;
    }
    currentTimeHover = moment.duration(currentTimeHover).asSeconds();
    iframe = parseInt(currentTimeHover / alignment_distance) * alignment_distance;
    $player
      .find('.vjs-mouse-display .vjs-thumbnail-src')
      .attr('src', `${url_pattern + iframe + '.jpg'}`);
  });
};

export { initScubberThumbnail };
