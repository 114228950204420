/* tslint:disable */
/* eslint-disable */
/**
 * Vimai Core Service UI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoProfileVprofileEnum = exports.VideoProfilePresetEnum = void 0;
exports.VideoProfilePresetEnum = {
    Ultrafast: 'ultrafast',
    Superfast: 'superfast',
    Veryfast: 'veryfast',
    Faster: 'faster',
    Fast: 'fast',
    Medium: 'medium',
    Slow: 'slow',
    Slower: 'slower',
    Veryslow: 'veryslow',
    Placebo: 'placebo'
};
exports.VideoProfileVprofileEnum = {
    Baseline: 'baseline',
    Main: 'main'
};
