import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form, FormGroup, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import TextField from '../../../../components/Input/views';
import { supportsContactUs } from './../../services';
import { connect } from 'react-redux';
import { verifyEmailFormat } from '../../../../utils/utils';
import * as Version from '../../../../constants/envConfig';
import pushAnalytics from './../../../../services/analytics/Analytics';
import Loader from 'react-loader-spinner';
import { getConfigByKey } from '~features/tenantConfig/services';
class PopupContactUs extends Component {
  static propTypes = {
    modalShow: PropTypes.bool,
    showModal: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.state = {
      resMessage: null,
      email: '',
      name: '',
      subject: '',
      contents: '',
      web_version: '',
      errMes: null,
      showModalSuccess: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    const { t, account, item } = this.props;
    if (account && item) {
      let { subscription_plan_info } = account.profile;
      let last_name = account.profile.last_name === null ? '' : account.profile.last_name;
      let first_name = account.profile.first_name === null ? '' : account.profile.first_name;
      this.setState({
        name: first_name + ' ' + last_name,
        email: account.profile.origin_email,
        web_version: Version.VERSION,
        subject: t('contactUs.txtContentSubject', {
          current_package_name: subscription_plan_info.name,
          new_package_name: item.name,
          account_code: account.profile.account_ref_code,
          account_email: account.profile.origin_email,
        }),
        contents: t('contactUs.txtContent', {
          current_package_name: subscription_plan_info.name,
          new_package_name: item.name,
          account_code: account.profile.account_ref_code,
          account_email: account.profile.origin_email,
        }),
      });
    }
  }
  _modalShow() {
    const { showModal } = this.props;
    pushAnalytics('click', {
      content_type: 'button',
      item_name: 'close',
      dialog: ' Learn more from one of our team members',
    });
    showModal('modalShow');
  }

  _onChangeText(key, e) {
    this.setState({
      [key]: e.target.value,
      resMessage: '',
      errMes: '',
    });
  }
  _actionShow() {
    this.setState({
      showModalSuccess: false,
    });
  }

  _renderPopup() {
    const { resMessage, showModalSuccess } = this.state;
    return (
      <Modal
        show={showModalSuccess}
        onHide={() => this._actionShow()}
        className="v-popupContactUsSucess"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header style={{ border: 'none', padding: 0 }}></Modal.Header>
        <Modal.Body>
          <Row>
            <Col
              md={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'center',
              }}
            >
              <i
                className={'fa fa-check-circle '}
                style={{ fontSize: '80px', color: '#04cc2f' }}
                aria-hidden="true"
              ></i>
              <div>
                <h4 style={{ color: '#696969' }}></h4>
                <p>{resMessage}</p>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
  _actionConfirm(e) {
    e.preventDefault();
    const { subject, contents, web_version, email, name } = this.state;
    const { t, showModal } = this.props;
    pushAnalytics('click', { content_type: 'button', item_name: 'Submit' });
    if (!name) {
      this.setState({
        errMes: t('contactUs.alert.nullName'),
      });
      return;
    }
    if (!email) {
      this.setState({
        errMes: t('contactUs.alert.nullEmail'),
      });
      return;
    }
    if (!verifyEmailFormat(email)) {
      this.setState({
        errMes: t('contactUs.alert.validEmail'),
      });
      return;
    }
    let data = {
      email,
      name,
      subject,
      comment: contents,
      web_version,
    };
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.props
          .dpPushContact(data)
          .then(res => {
            setTimeout(() => {
              this.setState({
                isLoading: false,
                resMessage: t('contactUs.alert.messageSucces'),
                alert: true,
                showModalSuccess: true,
              });
              showModal('modalShow');
            }, 1000);
          })
          .catch(error => {
            setTimeout(() => {
              this.setState({
                isLoading: false,
                errMes: error.message,
              });
            }, 1000);
          });
      },
    );
  }

  render() {
    const { modalShow, t, item } = this.props;
    const { resMessage, errMes, name, email, isLoading } = this.state;
    const content = {
      des: t('contactUs.popup.txtDes', {
        shortName: getConfigByKey('short_name'),
      }),
    };
    return (
      <React.Fragment>
        {this._renderPopup()}
        <Modal
          show={modalShow}
          onHide={() => this._modalShow()}
          className="v-popupContactUs"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="headerPopup">
              <h2 className="title">{t('contactUs.popup.txtTitle')}</h2>
              <p className="des" dangerouslySetInnerHTML={{ __html: content.des }}></p>
              <p className="phone">{t('contactUs.popup.txtPhone')}</p>
              <p style={{ color: '#596d79' }}>{t('contactUs.popup.txtOr')}</p>
            </div>
            <Form onSubmit={e => this._actionConfirm(e)} className="formContacUs">
              <FormGroup>
                <TextField
                  type="text"
                  label={t('contactUs.placeholder.txtContactName')}
                  onChange={e => this._onChangeText('name', e)}
                  value={name}
                  variant="filled"
                />
              </FormGroup>
              <FormGroup>
                <TextField
                  type="text"
                  label={t('contactUs.placeholder.email')}
                  onChange={e => this._onChangeText('email', e)}
                  variant="filled"
                  value={email}
                />
              </FormGroup>
              {!isLoading ? (
                <Button className="btnContacUs" onClick={e => this._actionConfirm(e)}>
                  Submit
                </Button>
              ) : (
                <div className="loadding">
                  <Loader type="Oval" color="#0095ff" height="1.5rem" width="1.5rem" />
                </div>
              )}
              <p
                style={{
                  textAlign: 'left',
                  color: '#ff0000',
                  marginTop: '10px',
                }}
              >
                {errMes}
              </p>
            </Form>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
PopupContactUs.defaultProps = {
  showModal: () => {},
  modalShow: false,
};
const mapDispatchToProps = {
  dpPushContact: data => supportsContactUs(data),
};

const mapStateToProps = state => ({
  account: state.auth.account,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PopupContactUs));
