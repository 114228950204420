import { store } from '~store';
import { startFetch } from './fetch';
import queryString from 'query-string';

import { updateTenantSlug } from '~app/actions';
import * as refreshToken from './refreshToken';
import { getConfigsSuccess } from '~features/tenantConfig/actions';
import {
  ROOT_PLATFORM_MENU_ID,
  ROOT_PLATFORM_MENU_ID_DEFAULT,
  TENANT_SLUG_DEFAULT,
  TENANT_SLUG,
  REACT_APP_TENANT_PLATFORM,
} from '~constants/apiString';
import { checkBlockCountry } from '~features/blockCountry/services';
import { getConfigByKey } from '~features/tenantConfig/services';
import { isNode } from '~utils/ssr';
import * as utils from '~root/utils/utils';
import { REACT_APP_BASE_DOMAIN } from '~constants/envConfig';

let buffers: any[] = [];
let isRequesting = false;

const ignorePaths = ['allowed_countries/'];

const isIgnoreRequest = (request: any) => {
  let result = false;
  // const blockCountryState = store.getState().blockCountryState
  // if (blockCountryState.loaded) {
  //   return false
  // }
  ignorePaths.some(path => {
    if (request.path.indexOf(path) >= 0) {
      result = true;
      return true;
    }
  });
  return result;
};

function setIsRequesting(value: boolean) {
  isRequesting = value;
}

export function addBuffer([buffer, { path, method, headers, body }]: any) {
  if (typeof buffer !== 'function') {
    return;
  }
  buffers.push([buffer, { path, method, headers, body }]);
}

const isRequestRequireTenant = (request: boolean) => {
  if (isNode()) {
    return false;
  }
  if (isIgnoreRequest(request)) {
    return false;
  }
  return true;
};

function resetBuffers() {
  buffers = [];
}

const fieldsGetDefault = ['email', 'url'];

function doneGetConfigs(response: any, error: any) {
  // if (response && !response.feature_advanced_web_multitenancy_enabled) {
  //   fieldsGetDefault.map((name) => {
  //     response[name] = defaultConfigs[name]
  //   })
  // }
  if (response !== null) {
    buffers.some(buffer => {
      const [fetchApi, options]: any = buffer;

      if (typeof fetchApi !== 'function') {
        return;
      }
      nextStepHandle(fetchApi, options);
    });
  }
  resetBuffers();
  setIsRequesting(false);
}

function nextStepHandle(buffer: any, options: any) {
  const req: any = utils.requestSsr;
  let newPath = '';
  const globalConstant: any = global;
  const { platform_slug = ROOT_PLATFORM_MENU_ID_DEFAULT, tenant_slug = TENANT_SLUG_DEFAULT } =
    getConfigByKey();
  newPath = options.path
    .replace(
      '{TENANT_SLUG}',
      (req && req.envConfig.tenant_slug) ||
        (globalConstant.envConfig && globalConstant.envConfig.tenant_slug),
    )
    .replace(ROOT_PLATFORM_MENU_ID, platform_slug)
    .replace(encodeURI(ROOT_PLATFORM_MENU_ID), platform_slug)
    .replace(TENANT_SLUG, tenant_slug)
    .replace(encodeURI(TENANT_SLUG), platform_slug);
  options = {
    ...options,
    path: newPath,
  };

  refreshToken.checkRefreshToken(buffer, options);
}

export function checkTenantConfigs(buffer: any, options: any) {
  if (typeof buffer !== 'function') {
    return;
  }
  const isRequire = isRequestRequireTenant(options);
  if (isRequire && !isRequesting) {
    setIsRequesting(true);
    store
      .dispatch(checkBlockCountry() as any)
      .then((response: any) => {
        doneGetConfigs(getConfigByKey(), null);
      })
      .catch((error: any) => {
        doneGetConfigs(null, error);
      });
  }
  if (isRequire && isRequesting) {
    addBuffer([buffer, options]);
    return;
  }
  nextStepHandle(buffer, options);
}
