import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Col, Row, Button } from 'react-bootstrap';
import { getConfigByKey } from '~features/tenantConfig/services';
import OverlayLoading from '~components/loading/overlayLoading';
import CloseIcon from '@material-ui/icons/Close';
import classnames from 'classnames';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { _showForgotPassModal } from '~root/app/functions/method-login';
import { loading } from '~features/loadingPage/services';
import Loader from 'react-loader-spinner';
import { setIsRegister } from '~components/global/globalServices';
import { returnUpBack, returnUpBackOutline } from 'ionicons/icons';
import { FormatListNumberedRtl } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { ModalStyled } from '../login/styled';
import loginFormId from '~constants/ids/loginFormId';
import LoginGoogle from '../login/loginGoogle';
import LoginFaceBook from '../login/loginFacebook';
import LoginKaKaoTalk from '../login/loginKaKaoTalk';
import LoginNaver from '../login/loginNaver';
import LoginEmail from '../login/loginEmail';
import PrivacyPolicy from '../../../box/components/privacyPolicyPopup';
import TermOfUse from '../../../box/components/termOfUsePopup';
import SignUpProjectW from '../registerFormW';
import pushAnalytics from '~root/services/analytics/Analytics';
import { verifyEmailFormat, verifyPhoneNumber } from '../../../../utils/utils';
import { ReactComponent as IconsPassword } from '../../../../assets/icons/eyeW.svg';
import { ReactComponent as IconsHidePassword } from '../../../../assets/icons/eyeWFlash.svg';
import { loginEmail } from '../../services';
import AppleLogin from '../login/AppleLogin';
import {
  setWatchNowClick,
  setLoginModalShow,
  setIsShowModal_Forgot,
  setIsShowSignInForm,
  setIsShowVerifyEmailModal,
  setIsShowVerifyEmailPPV,
} from '~components/global/globalSlice';
import { isLoggedVerifiedEmail } from '~core/method/authMethod';
import { wFirebaseEvents } from '~services/analytics';

const useStyles = makeStyles({
  projectw__terms_privacy: {
    color: '#8E8E93',
    fontSize: '12px',
    lineHeight: '133.5%',
    letterSpacing: '0.05em',
    borderBottom: '0.5px solid #8E8E93',
    marginTop: '120px',
    '&:focus,&:visited,&:hover,&:active': {
      color: '#8E8E93',
      textDecoration: 'none',
    },
  },
});

function SignInForm(props) {
  // const component = props.props
  const { checkYourPaidAccount, hiddenModalLogin, _resetState, openShowPPV } = props;
  // const params = null

  // const { param } = component
  const [isShow, setShowPassword] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState({
    email: '',
    password: '',
  });
  const classes = useStyles();
  const validEmail = verifyEmailFormat(userInfo.email);

  const [resMessage, setResMessage] = React.useState({
    alert: false,
    message: '',
    isLoading: false,
  });
  const location = useLocation();
  const [privacyPolicyModalShow, setPrivacyPolicyModalShow] = React.useState(false);
  const [termOfUseModalShow, setTermOfUseModalShow] = React.useState(false);
  const [registerModal, setRegisterModal] = React.useState(false);
  const pathnameSplits = location.pathname.split('/');
  const { t, i18n } = useTranslation();
  const isWatchNowClick = useSelector(state => state.global.userAction.watchNowClick);

  const account = useSelector(state => state.auth.account);

  const auth = useSelector(state => state.auth);

  const [checkInfo, setCheckInfo] = React.useState({
    checkEmail: false,
    checkPassword: false,
    checkSystems: false,
  });

  const dispatch = useDispatch();
  React.useEffect(() => {
    wFirebaseEvents.signInStartedEvent();

    if (pathnameSplits.length > 1 && pathnameSplits[1] === 'terms-of-use') {
      setTermOfUseModalShow(true);
    }
  }, []);

  React.useEffect(() => {
    if (!isWatchNowClick) {
      return;
    }
    ModalSignUp();
  }, [isWatchNowClick]);

  React.useEffect(() => () => {
    dispatch(setWatchNowClick(false));
  });

  const ModalSignUp = () => {
    dispatch(setLoginModalShow(false));
    dispatch(setIsShowSignInForm(true));
    setRegisterModal(true);
    resetState();
  };

  const onChangeText = (key, e) => {
    let value = '';
    value = e.target.value;
    setUserInfo({
      ...userInfo,
      [key]: value,
    });
    setResMessage({
      alert: false,
      message: '',
      isLoading: false,
    });
  };
  const onKeyDownEnter = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      SignInEmail();
    }
  };

  const resetState = () => {
    setUserInfo({
      email: '',
      password: '',
    });
  };
  const showForgotPassAction = () => {
    dispatch(setIsShowModal_Forgot(true));
  };
  const SignInEmail = () => {
    const { email, password } = userInfo;
    if (!email && !password) {
      setResMessage({
        ...resMessage,
        alert: true,
        message: 'Ensure email or current password not empty',
        isLoading: false,
      });
      setCheckInfo({
        ...checkInfo,

        checkEmail: false,
        checkPassword: false,
        checkSystems: true,
      });
      return;
    }
    if (!email || !validEmail) {
      setResMessage({
        ...resMessage,
        alert: true,
        message: 'Invalid e-mail address',
        isLoading: false,
      });
      setCheckInfo({
        ...checkInfo,

        checkEmail: true,
        checkPassword: false,
        checkSystems: false,
      });
      return;
    }
    if (password.length < 6 || !password) {
      setResMessage({
        ...resMessage,
        alert: true,
        message: 'Ensure this field has at least 6 characters.',
        isLoading: false,
      });
      setCheckInfo({
        ...checkInfo,
        checkEmail: false,
        checkPassword: true,
        checkSystems: false,
      });
      return;
    }
    setResMessage({
      ...resMessage,
      alert: false,
      message: '',
      isLoading: true,
    });
    setCheckInfo({
      ...checkInfo,
      checkEmail: false,
      checkPassword: false,
      checkSystems: false,
    });
    dispatch(loading(true));
    dispatch(loginEmail(email, password))
      .then(res => {
        // pushAnalytics('login', { method: 'email' });
        wFirebaseEvents.signInCompletedEvent({ email });
        dispatch(setLoginModalShow(true));
        if (!isLoggedVerifiedEmail(auth)) {
          if (!openShowPPV) {
            dispatch(setIsShowVerifyEmailModal(true));
          }
          dispatch(setIsShowVerifyEmailPPV(true));
        }
        dispatch(setIsShowVerifyEmailModal(false));
        dispatch(setIsShowVerifyEmailPPV(false));
        setResMessage({
          ...resMessage,
          alert: false,
          message: '',
          isLoading: false,
        });
      })
      .catch(error => {
        setResMessage({
          ...resMessage,
          alert: true,
          message: error.message,
          isLoading: false,
        });
        setCheckInfo({
          ...checkInfo,
          checkEmail: false,
          checkPassword: false,
          checkSystems: true,
        });
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };

  const { alert, isLoading, message } = resMessage;
  const { checkEmail, checkPassword, checkSystems } = checkInfo;

  return (
    <Row className="v-loginForm--row project-w--row">
      <Col sm={12} className="v-loginForm--header project-w--header">
        <p style={{ textTransform: 'uppercase' }} className="v-loginForm--title project-w--title">
          {t('projectw__modal.SignIn.title')}
        </p>
      </Col>
      <Col sm={12}>
        {/* <p
          style={{ textAlign: 'left' }}
          className="v-loginForm--legal project-w--legal"
        >
          {t('registerScreen.EmailAddress')}
        </p> */}
        <FormControlW
          type="text"
          style={resMessage.alert && checkEmail ? { color: '#E92121' } : {}}
          placeholder={t('projectw__modal.SignIn.email__placeholder')}
          name={t('loginScreen.placeholderEmail.projectw')}
          autoComplete="off"
          id="formLogin"
          value={userInfo.email}
          onChange={value => onChangeText('email', value)}
          onKeyDown={e => onKeyDownEnter(e)}
        />
        <p className="error-data project-W-error">{alert && checkEmail && message}</p>
        {/* <p
          style={{ textAlign: 'left' }}
          className="v-loginForm--legal project-w--legal"
        >
          {t('registerScreen.placeholderPassword')}
        </p> */}
        <div className="v-loginForm--authTemplate">
          <FormControlW
            type={`${isShow ? 'text' : 'password'}`}
            style={resMessage.alert && checkPassword ? { color: '#E92121' } : {}}
            className="input__password"
            placeholder={t('projectw__modal.SignIn.password__placeholder')}
            value={userInfo.password}
            name={t('loginScreen.placeholderPassword')}
            autoComplete="new-password"
            id="formLogin"
            onChange={value => onChangeText('password', value)}
            onKeyDown={e => onKeyDownEnter(e)}
          />
          <button
            className="v-loginForm--showPass project-w--showPass"
            onClick={e => {
              e.preventDefault();
              setShowPassword(!isShow);
            }}
          >
            {getConfigByKey('ui_theme') === 'projectw' &&
              (isShow ? <IconsPassword /> : <IconsHidePassword />)}
          </button>
        </div>
        {checkPassword && <p className="error-data project-W-error">{alert && message}</p>}
        <Button
          data-testid={loginFormId.loginBtn}
          onClick={SignInEmail}
          style={
            alert
              ? { background: '#e92121', color: '#fff' }
              : !userInfo.email || !userInfo.password
              ? {}
              : { background: '#000', color: '#fff', pointerEvents: 'all' }
          }
          className="project-w--signIn"
          bsSize="large"
        >
          {isLoading ? (
            <Loader type="Oval" color="white" height="20" width="20" />
          ) : (
            `${t('projectw__modal.SignIn.btn__login')}`
          )}
        </Button>
        <p style={{ paddingTop: '10px' }} className="error-data project-W-error">
          {alert && checkSystems && message}
        </p>
        <Button
          bsStyle="link"
          className={classnames(
            'v-loginForm--forgotPassword',
            getConfigByKey('tenant_slug') === 'projectw' ? 'project-w--forgotPassword' : '',
          )}
          // className="v-loginForm--forgotPassword"
          onClick={() => {
            pushAnalytics('click', {
              content_type: 'button',
              item_name: 'Forgot your password',
            });
            // _showForgotPassModal(Component)
            showForgotPassAction();
          }}
        >
          {t('projectw__modal.SignIn.fogot__password')}
        </Button>
      </Col>
      <div
        className={classnames(
          'v-loginForm--or project-w--or',
          !getConfigByKey('features.loginfb') && !getConfigByKey('features.loginGoogle') && 'hide',
        )}
      >
        {t('loginScreen.Or')}
      </div>
      <Col sm={12}>
        <LoginGoogle openShowPPV={openShowPPV} target="projectw" />
        {/* <LoginNaver setResMessage={setResMessage} /> */}
        <LoginKaKaoTalk setResMessage={setResMessage} />
        <AppleLogin setResMessage={setResMessage} />
        <LoginFaceBook {...props} />
      </Col>
      <Col sm={12}>
        <GroupAction>
          <p>{t('projectw__modal.SignIn.newhere')}</p>
          <ButtonSignUp data-testid={loginFormId.registerBtn} onClick={ModalSignUp} bsSize="large">
            {t('projectw__modal.SignIn.txtSignUp')}
          </ButtonSignUp>
        </GroupAction>
      </Col>

      <Col sm={12}>
        <GroupAction>
          <Link
            target="_blank"
            to={`/${i18n.language}/terms-of-service`}
            style={{
              cursor: 'pointer',
              fontWeight: 400,
              textDecoration: 'none',
              color: '#8E8E93',
            }}
            className={classes.projectw__terms_privacy}
          >
            {t('projectw__modal.SignUp.terms')}
          </Link>
          <span
            style={{
              cursor: 'pointer',
              fontWeight: 400,
              marginBottom: 'unset',
              padding: '0 3px',
            }}
          >
            &
          </span>
          <Link
            target="_blank"
            to={`/${i18n.language}/privacy-policy`}
            style={{
              cursor: 'pointer',
              fontWeight: 400,
              textDecoration: 'none',
              color: '#8E8E93',
            }}
            className={classes.projectw__terms_privacy}
          >
            {t('projectw__modal.SignUp.privacy')}
          </Link>
          {/* <PrivacyPolicy
            modalShow={privacyPolicyModalShow}
            showModal={() => setPrivacyPolicyModalShow(!privacyPolicyModalShow)}
          />
          <TermOfUse
            modalShow={termOfUseModalShow}
            showModal={() => setTermOfUseModalShow(!termOfUseModalShow)}
          /> */}
        </GroupAction>
      </Col>
    </Row>
  );
}
const GroupAction = styled.div`
  font-family: var(--font);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 133.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: #8e8e93;
    margin-top: 120px;
    letter-spacing: 2px;
    margin-left: 3px;
    &:nth-child(1),
    &:nth-child(3) {
      border-bottom: 1px solid #8e8e93;
    }
  }
`;

const ButtonSignUp = styled.p`
  font-weight: 600;
  padding-left: 10px;
  &:hover {
    cursor: pointer;
  }
`;

const FormControlW = styled.input`
  font-size: 17px;
  width: 100%;
  padding: 10px 8px;
  font-weight: 400;
  margin-bottom: 17px;
  border-radius: 0;
  color: #000000;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #c7c7cc;
  &.input__password {
    margin-bottom: 10px;
  }

  &::placeholder {
    color: #c7c7cc;
    opacity: 1;
    font-size: 18px;
  }
  &:hover,
  &:focus {
    background: #fff;
    color: #000000;
    outline: none !important;
  }
  input:focus {
    outline: none !important;
  }
`;

export default SignInForm;
