import AppleLoginReact from 'react-apple-login';
import { ReactComponent as IconApple } from '~img/icons/projectw/signin/apple.svg';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { loginWithApple } from '~features/auth/services';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { getConfigByKey } from '~features/tenantConfig/services';
import { GroupSocial, ButtonW } from './loginButtonStyled';

type FunctionAny = (...args: any[]) => any;
type Props = {
  setAction: FunctionAny;
  setResMessage: FunctionAny;
};

const payloadLogin = {
  redirect_uri: `https://${getConfigByKey('hostName')}/apple-callback`,
  client_id: 'net.illuon',
};
const AppleLogin = (props: Props) => {
  const { setResMessage, setAction } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCallback = (response: any) => {
    const {
      error,
      authorization: { id_token, code },
    } = response;
    if (!_.isEmpty(error)) {
      return;
    }

    (dispatch(loginWithApple({ id_token, code, ...payloadLogin })) as any).catch((err: any) => {
      setResMessage &&
        setResMessage({
          alert: true,
          message: err.message,
          isLoading: false,
        });
      setAction &&
        setAction({
          isLoading: false,
          resMessage: err.message,
          showAlert: true,
        });
    });
  };

  return (
    <AppleLoginReact
      clientId={payloadLogin.client_id}
      redirectURI={payloadLogin.redirect_uri}
      responseType="id_token code"
      responseMode="query"
      usePopup
      // scope="name email"
      callback={handleCallback}
      render={renderProps => (
        <GroupSocial>
          <IconApple />
          <ButtonW onClick={renderProps.onClick} bsSize="large">
            {t('loginScreen.signInApple')}
          </ButtonW>
        </GroupSocial>
      )}
    />
  );
};

export default AppleLogin;
