if (!window.gtmVar) {
  window.gtmVar = {};
}

export const gtmVar_setIsLoggedIn = (value: boolean) => {
  window.gtmVar.isLoggedIn = value;
};

export const gtmVar_setHasSubscription = (value: boolean) => {
  window.gtmVar.hasSubscription = value;
};
