import * as actions from './actions';
import * as appAPIs from '../../api/appAPIs';

export { confirmEmail, resendEmail };

function confirmEmail(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .confirmEmail(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function resendEmail() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .resend_email(1)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}
