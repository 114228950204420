import ReactDOM from 'react-dom';
import React from 'react';
import videojs from 'video.js';
import jquery from 'jquery';
import _ from 'lodash';
import { canWatchContent } from '~core/method/contentMethod';
import { Provider } from 'react-redux';
import { store, persistor } from '~store';
import { BrowserRouter, Router } from 'react-router-dom';
import { history } from '~store/history';
import { provideComponent } from '~root';
import DurationContent from './DurationContent';

const vjsComponent = videojs.getComponent('Component');

class DurationContentLiveEvent extends vjsComponent {
  constructor(player, options) {
    super(player, options);
    const $player = jquery(this.el());
    const { bigPlayButton } = player;
    player.on('ready', () => {
      // $player
      //   .parent()
      //   .find('.vjs-remaining-time')
      //   .addClass('hide')
    });
    this.mount();
    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }
  // TODO : Create class element for component render
  createEl() {
    const _DurationTime = videojs.createEl('div', {
      className: 'vjs-durationContent',
    });
    return _DurationTime;
  }

  //TODO : Render your component
  mount() {
    const { component, entity, detail, isTrailer, trailerActive: trailer = {} } = this.options_;
    provideComponent(
      <DurationContent
        player={this.player_}
        component={component}
        entity={entity}
        detail={detail}
        isTrailer={isTrailer}
        trailer={trailer}
      />,
      this.el(),
    );
  }
}

/**
 * Make sure to register the vjsComponent so Video JS knows it exists
 */
// TODO :  Add component into player
videojs.registerComponent('DurationContentLiveEvent', DurationContentLiveEvent);

export default DurationContentLiveEvent;
