import React from 'react';
import WatchNowButton from '~features/landingpage/projectw/components/WatchNowButton';
import ActionBar from './ActionBar';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { setIsShowTrailer } from '~components/global/globalSlice';

const ActionLiveEventStyled = styled.div`
  &.liveEvent__container {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    padding-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
    .liveEvent__container {
    }
    @media (max-width: 768px) {
      display: flex;
      padding-bottom: unset;
      flex-direction: column-reverse;
      align-items: flex-end;
    }
  }
`;

export default function ActionLiveEvent(props) {
  const { entity, detail } = props;
  const [state, setState] = React.useState({ entityDetail: detail });
  const isshowTrailerLiveEvent = useSelector(state => state.global.showTrailer.isShowTrailer);
  return (
    <ActionLiveEventStyled className="liveEvent__container">
      <ActionBar entity={entity} {...props} />
      {isshowTrailerLiveEvent && (
        <WatchNowButton liveState={'living'} state={state} content={detail} />
      )}
    </ActionLiveEventStyled>
  );
}
