import styled from 'styled-components';
export const DivStyled = styled.div`
  background: #141a23;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  font-size: 1rem;
  padding: 0 0 0 15px;
  width: 100%;
  overflow: hidden;
  flex-wrap: nowrap;
  top: 55px;
  height: 36px;
  z-index: auto;
  &.v-subCategory__youtubePage,
  &.v-subCategory__categoryPage {
    height: auto;
    top: 0;
    position: relative;
  }
  &.v-subCategory__youtubePage {
    min-height: 57px;
    padding: 0 15px;
  }

  /* margin-top: 70px; */
  .v-subCategory {
    &__header {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      color: #f7f4f3;
      width: 100%;
      &--close {
        color: #9a9a9a;
        font-size: 24px;
      }
    }
    &__tenant {
      cursor: pointer;
      margin: 0;
      font-size: 1.125rem;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #9b9b9b;
      display: block;
      padding: 0.9rem 1.25rem 0.9rem 0;
      text-decoration: none;
      i {
        padding-left: 9px;
      }
    }
    &--wrapSelect,
    &--container {
      display: none;
    }
    &__btn {
      border: none;
      color: #fff;
      padding-right: 23px;
      background: unset;
      &--next {
        padding-left: 23px;
        padding-right: 0;
      }
    }
    &--link {
      color: #d1d1d1;
      font-size: 14px;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
      padding: 7px 20px;
      height: 100%;
      display: flex;
      align-items: center;
      white-space: nowrap;
      position: relative;
      &:last-child {
        &:before {
          content: none;
        }
      }
      &:first-child {
        padding-left: 0;
      }
      &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 15px;
        right: 0;
        background: rgb(255, 255, 255, 0.1);
      }
      &:hover,
      &.active {
        text-decoration: none;
        color: ${({ theme }) => theme.primaryColor};
      }
    }
    &__dropdown {
      display: block;
      cursor: pointer;
      color: #fff;
    }
    &__selector {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 1.2rem 1.6rem;
      background-image: linear-gradient(180deg, rgba(30, 35, 42, 0.9), rgba(17, 18, 18, 0.9));
      overflow-y: scroll;
      max-height: calc(100vh);
      hr {
        width: 100%;
      }
      &--subcategory {
        padding: 0.85rem 0;
        color: #9b9b9b;
        font-size: 16px !important;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        cursor: pointer;
        a {
          text-decoration: none;
        }
        :hover {
          text-decoration: none;
          color: #fff;
          font-weight: bold;
        }
      }
    }
    &--selected {
      padding-left: 20px;
      color: #f7f4f3;
      cursor: pointer;
    }
    &__right {
      width: 60%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: ${props => `${props.props && props.props.youtube ? `0px` : `20px`}`};
    }
    &__left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 40%;
      padding-left: 9px;
      text-transform: capitalize;
      color: #fff;
    }
  }
  @media (min-width: 768px) {
    padding: 0 15px;
  }

  @media (min-width: 992px) {
    background: unset;
    &.v-subCategory__youtubePage,
    &.v-subCategory__categoryPage {
      z-index: 1;
    }
    &.v-subCategory--escondido {
      .v-subCategory--link {
        font-size: 16px;
      }
    }
    &.v-subCategory__youtubePage {
      width: calc(100% - 21rem);
      padding: 0;
      .v-subCategory--wrapSelect {
        width: 100%;
      }
      .v-subCategory--container {
        border: none;
      }
    }
    .v-subCategory {
      &--container {
        display: flex;
        width: 100%;
        border-top: 1px solid rgb(255, 255, 255, 0.1);
      }
      &--wrapSelect {
        display: flex;
        width: ${props =>
          `${props.props && props.props.isCategoryPage ? 'calc(100% - 25rem)' : `calc(75%)`}`};
        overflow: hidden;
      }
      &__tenant,
      &__dropdown {
        display: none;
      }
    }
  }
`;

export const RowStyled = styled.div`
  margin-top: 70px;
  .v-home {
    &__container {
      width: 100%;
      position: relative;
    }
    &__wrap {
      display: flex;
      padding-left: 15px;
      z-index: 1;
      &--only {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
      }
    }
    &__right-site {
      padding-right: 0;
      display: none;
    }
    &__left-site {
      &--error {
        color: ${({ theme }) => theme.body.color};
      }
      padding: 0;
      &--ads {
        height: auto;
      }
    }
  }

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
    .v-home {
      &__wrap {
        padding-left: 15px;
      }
    }
  }

  @media (min-width: 992px) {
    .v-home {
      &__wrap {
        padding: 0 15px;
        &--only {
          padding-right: 0;
        }
      }
      &__right-site {
        display: block;
        padding-left: 0px;
        margin-top: 0.5rem;
        &.col-lg-4 {
          width: 21em;
        }
      }
      &__left-site {
        &.col-lg-8 {
          width: calc(100% - 21em);
        }
      }
    }
  }

  @media (min-width: 1280px) {
  }
  @media (min-width: 1440px) {
  }
  @media (min-width: 1680px) {
  }
  @media (min-width: 1891px) {
    .v-home {
      &__leftAd,
      &__rightAd {
        width: 160px;
        margin: 80px 15px;
        height: auto;
        color: #8c8c8c;
        background-color: #dadada;
      }
    }
  }
`;
