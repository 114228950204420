import React, { Component } from 'react';
import YouTube from 'react-youtube';
import { connect } from 'react-redux';
import { SHOW_TYPE } from '../../constants';
import {
  onStateYoutubeChange,
  onYoutubeReady,
  youtubeEnded,
  youtubeStart,
  youtubePause,
} from '../../function/initEventYoutube';
import { pushEventFirebasePlayer } from '../pushEventFirebase';
import { withRouter } from 'react-router-dom';
import { initEpsTrailer } from '../../function/initControlBar';
import jquery from 'jquery';
class YoutubePlayer extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.error = false;
    this.el = React.createRef();
    this.originEntity = null;
    this.playerReady = true;
    this.state = {
      idYoutube: '',
      data: [],
      currentEpisode: null,
    };
  }
  componentWillMount() {
    const { entityDetail, component } = this.props;
    const { youtube_video_id } = entityDetail;
    const { entityTrailer } = component.state;
    let youtubeID = youtube_video_id;
    this.originEntity = entityDetail;
    if (entityTrailer) {
      youtubeID = entityTrailer.youtube_video_id;
    }
    this.setState({
      idYoutube: youtubeID,
    });
  }

  componentDidMount() {
    const { entityDetail } = this.props;
    const $player = jquery(this.el.current.container);
    $player.parent().addClass('v-youtube');
    this.getSeason(entityDetail);
  }

  componentWillUnmount() {
    const { component, entityDetail } = this.props;
    clearInterval(this.timer);
    youtubeEnded(component, this.originEntity);
    this.el.current &&
      this.el.current.internalPlayer.getCurrentTime().then(res => {
        component._updateProgress(entityDetail.id, parseInt(res));
      });
    this.originEntity = null;
  }

  getRecommend = entityDetail => {
    const { component } = this.props;
    component
      ._getRecommend(entityDetail.id)
      .then(res => {
        this.setState({
          data: res,
          currentEpisode: null,
        });
      })
      .catch(err => {
        console.log('err', err);
      });
  };

  getSeason = entityDetail => {
    const { component } = this.props;
    const { state } = component;
    const { detailPage, landingPage } = component.props;
    const { entityTrailer } = component.state;
    let episodes = landingPage.season.episodes || [];
    let currentEpisode = detailPage.episode || 1;
    let indexNextContent = currentEpisode;
    episodes = initEpsTrailer(component, { entityDetail });
    indexNextContent = episodes.length - currentEpisode;

    if (entityDetail.trailers) {
      indexNextContent = episodes.length - (currentEpisode + entityDetail.trailers.length) - 1;
    }

    if (entityTrailer) {
      indexNextContent = episodes.findIndex(element => element.slug === entityTrailer.slug) - 1;
    }
    if (episodes.length > 1 && indexNextContent >= 0) {
      return this.setState({
        data: episodes,
        currentEpisode: indexNextContent,
      });
    }
    if (state.infoShow) {
      let slugCurrent =
        component.entityDetail.current_season && component.entityDetail.current_season.slug;
      state.infoShow.seasons.length > 0 &&
        state.infoShow.seasons.map((item, index) => {
          if (item.slug === slugCurrent && index === state.infoShow.seasons.length - 1) {
            this.getRecommend(state.infoShow);
          }
        });
    } else {
      this.getRecommend(entityDetail);
    }
  };

  nextContent = item => {
    const { component, account, entityDetail, language } = this.props;
    clearInterval(this.timer);
    let slug = item.slug;
    if (item.type === SHOW_TYPE) {
      slug = item.current_season && item.current_season.current_episode.slug;
    }
    let premium = !item.has_free_content && item.is_premium ? true : false;
    const hasSubscription = account && account.profile.has_subscription;
    if (hasSubscription) {
      premium = false;
    }
    if (premium) {
      return component.props.history.push(`/${language}/landing/${item.slug}`);
    }
    let url = item.trailer
      ? `/${language}/detail/${entityDetail.slug}?trailer=${item.slug}`
      : `/${language}/detail/${slug}`;
    return component.props.history.replace(url);
  };

  _addEventView() {
    const { component, account, entityDetail } = this.props;
    component._updateProgress(entityDetail.id, entityDetail.progress || 1);
  }
  render() {
    const { component } = this.props;
    const opts = {
      height: '100%',
      width: '100%',
      allowFullScreen: 'allowFullScreen',
      playerVars: {
        autoplay: 1,
        rel: 0,
      },
    };
    const { idYoutube } = this.state;
    return (
      <div className="v-youtube-player">
        <YouTube
          ref={this.el}
          videoId={idYoutube}
          opts={opts}
          onReady={event => this._onReady(event)}
          onEnd={() => this._onEnd()}
          onPlay={() => youtubeStart(component, this.el)}
          onPause={() => youtubePause(component)}
          onError={event => this._onError(event)}
          onStateChange={event => this._onStateChange(event)}
          className="v-detailPage__youtube"
        />
      </div>
    );
  }

  _onReady(event) {
    const { entityDetail } = this.props;
    this.el.current && this.el.current.internalPlayer.seekTo(entityDetail.progress);
    event.target.playVideo();
    this._addEventView();
    onYoutubeReady(entityDetail, this.playerReady);
  }

  _onError(event) {
    const { entityDetail } = this.props;
    if (this.error) {
      return;
    }
    this.error = true;
    pushEventFirebasePlayer(entityDetail, 'videoPlayStartFail');
  }

  _onStateChange(event) {
    const { entityDetail } = this.props;
    this.el.current && this.el.current.internalPlayer.getCurrentTime(entityDetail.progress);
    if (this.error) {
      return;
    }
    const { component } = this.props;
    if (event.data !== 0) {
      clearInterval(this.timer);
    }
    onStateYoutubeChange(component, event.data);
  }

  _onEnd() {
    const { component } = this.props;
    youtubeEnded(component);
    const { data, currentEpisode } = this.state;
    let countdown = 5;
    let indexNextContent = currentEpisode || 0;
    if (data.length <= 0) {
      return;
    }
    this.timer = setInterval(() => {
      countdown -= 1;
      if (countdown <= 0) {
        clearInterval(this.timer);
        this.nextContent(data[indexNextContent]);
      }
    }, 1000);
  }
}

const mapStateToProps = state => ({
  account: state.auth.account,
  language: state.root.language,
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(YoutubePlayer));
