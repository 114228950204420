/* tslint:disable */
/* eslint-disable */
/**
 * Vimai Core Service UI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdsManagementApi = exports.AdsManagementApiFactory = exports.AdsManagementApiFp = exports.AdsManagementApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * AdsManagementApi - axios parameter creator
 * @export
 */
var AdsManagementApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Get ads.txt or app-ads.txt file for {WebDomain}/ads.txt and {WebDomain}/app-ads.txt
         * @summary Get ads.txt or app-ads.txt file for {WebDomain}/ads.txt and {WebDomain}/app-ads.txt
         * @param {string} adsFileName
         * @param {string} _var
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adsText: function (adsFileName, _var, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'adsFileName' is not null or undefined
                            (0, common_1.assertParamExists)('adsText', 'adsFileName', adsFileName);
                            // verify required parameter '_var' is not null or undefined
                            (0, common_1.assertParamExists)('adsText', '_var', _var);
                            localVarPath = "/web_assets/(P{ads_file_name}{var}ads.txt)"
                                .replace("{".concat("ads_file_name", "}"), encodeURIComponent(String(adsFileName)))
                                .replace("{".concat("var", "}"), encodeURIComponent(String(_var)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get list of ads banner
         * @summary Get list of ads banner
         * @param {string} tenantPlatformSlug
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {'TOP' | 'LEFT' | 'RIGHT' | 'BOTTOM' | 'MEDIUM_RECTANGLE'} [position] Position of ads banner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdsBanners: function (tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, position, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantPlatformSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getAdsBanners', 'tenantPlatformSlug', tenantPlatformSlug);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getAdsBanners', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/platforms/{tenant_platform_slug}/ads_banners"
                                .replace("{".concat("tenant_platform_slug", "}"), encodeURIComponent(String(tenantPlatformSlug)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (position !== undefined) {
                                localVarQueryParameter['position'] = position;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get video ads of content
         * @param {string} tenantPlatformSlug
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [contentId] Content id
         * @param {string} [adParams] Additional ad parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentVideoAds: function (tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, contentId, adParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantPlatformSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getContentVideoAds', 'tenantPlatformSlug', tenantPlatformSlug);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getContentVideoAds', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/platforms/{tenant_platform_slug}/content_video_ads/"
                                .replace("{".concat("tenant_platform_slug", "}"), encodeURIComponent(String(tenantPlatformSlug)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (contentId !== undefined) {
                                localVarQueryParameter['content_id'] = contentId;
                            }
                            if (adParams !== undefined) {
                                localVarQueryParameter['ad_params'] = adParams;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get VMAP ads tag
         * @param {string} contentId
         * @param {string} tenantPlatformSlug
         * @param {string} tenantSlug
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentVmapAdsTag: function (contentId, tenantPlatformSlug, tenantSlug, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getContentVmapAdsTag', 'contentId', contentId);
                            // verify required parameter 'tenantPlatformSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getContentVmapAdsTag', 'tenantPlatformSlug', tenantPlatformSlug);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getContentVmapAdsTag', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/platforms/{tenant_platform_slug}/contents/{content_id}/vmap_ads_tag.xml/"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)))
                                .replace("{".concat("tenant_platform_slug", "}"), encodeURIComponent(String(tenantPlatformSlug)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get current ads banner
         * @summary Get current ads banner
         * @param {string} tenantPlatformSlug
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentAdsBanner: function (tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantPlatformSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getCurrentAdsBanner', 'tenantPlatformSlug', tenantPlatformSlug);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getCurrentAdsBanner', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/platforms/{tenant_platform_slug}/ads_banners/current"
                                .replace("{".concat("tenant_platform_slug", "}"), encodeURIComponent(String(tenantPlatformSlug)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getListDisabledAds: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/cm/ads/disabled_list/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get current ads banner
         * @summary Get current ads banner
         * @param {string} tenantPlatformSlug
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [contentId] Content id
         * @param {string} [adParams] Additional ad parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlatformVideoAdsInfo: function (tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, contentId, adParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantPlatformSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getPlatformVideoAdsInfo', 'tenantPlatformSlug', tenantPlatformSlug);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getPlatformVideoAdsInfo', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/platforms/{tenant_platform_slug}/video_ads/"
                                .replace("{".concat("tenant_platform_slug", "}"), encodeURIComponent(String(tenantPlatformSlug)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (contentId !== undefined) {
                                localVarQueryParameter['content_id'] = contentId;
                            }
                            if (adParams !== undefined) {
                                localVarQueryParameter['ad_params'] = adParams;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.AdsManagementApiAxiosParamCreator = AdsManagementApiAxiosParamCreator;
/**
 * AdsManagementApi - functional programming interface
 * @export
 */
var AdsManagementApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.AdsManagementApiAxiosParamCreator)(configuration);
    return {
        /**
         * Get ads.txt or app-ads.txt file for {WebDomain}/ads.txt and {WebDomain}/app-ads.txt
         * @summary Get ads.txt or app-ads.txt file for {WebDomain}/ads.txt and {WebDomain}/app-ads.txt
         * @param {string} adsFileName
         * @param {string} _var
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adsText: function (adsFileName, _var, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.adsText(adsFileName, _var, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get list of ads banner
         * @summary Get list of ads banner
         * @param {string} tenantPlatformSlug
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {'TOP' | 'LEFT' | 'RIGHT' | 'BOTTOM' | 'MEDIUM_RECTANGLE'} [position] Position of ads banner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdsBanners: function (tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, position, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAdsBanners(tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, position, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get video ads of content
         * @param {string} tenantPlatformSlug
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [contentId] Content id
         * @param {string} [adParams] Additional ad parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentVideoAds: function (tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, contentId, adParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getContentVideoAds(tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, contentId, adParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get VMAP ads tag
         * @param {string} contentId
         * @param {string} tenantPlatformSlug
         * @param {string} tenantSlug
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentVmapAdsTag: function (contentId, tenantPlatformSlug, tenantSlug, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getContentVmapAdsTag(contentId, tenantPlatformSlug, tenantSlug, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get current ads banner
         * @summary Get current ads banner
         * @param {string} tenantPlatformSlug
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentAdsBanner: function (tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getCurrentAdsBanner(tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getListDisabledAds: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getListDisabledAds(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get current ads banner
         * @summary Get current ads banner
         * @param {string} tenantPlatformSlug
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [contentId] Content id
         * @param {string} [adParams] Additional ad parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlatformVideoAdsInfo: function (tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, contentId, adParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPlatformVideoAdsInfo(tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, contentId, adParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.AdsManagementApiFp = AdsManagementApiFp;
/**
 * AdsManagementApi - factory interface
 * @export
 */
var AdsManagementApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.AdsManagementApiFp)(configuration);
    return {
        /**
         * Get ads.txt or app-ads.txt file for {WebDomain}/ads.txt and {WebDomain}/app-ads.txt
         * @summary Get ads.txt or app-ads.txt file for {WebDomain}/ads.txt and {WebDomain}/app-ads.txt
         * @param {string} adsFileName
         * @param {string} _var
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adsText: function (adsFileName, _var, options) {
            return localVarFp.adsText(adsFileName, _var, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get list of ads banner
         * @summary Get list of ads banner
         * @param {string} tenantPlatformSlug
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {'TOP' | 'LEFT' | 'RIGHT' | 'BOTTOM' | 'MEDIUM_RECTANGLE'} [position] Position of ads banner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdsBanners: function (tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, position, options) {
            return localVarFp.getAdsBanners(tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, position, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get video ads of content
         * @param {string} tenantPlatformSlug
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [contentId] Content id
         * @param {string} [adParams] Additional ad parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentVideoAds: function (tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, contentId, adParams, options) {
            return localVarFp.getContentVideoAds(tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, contentId, adParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get VMAP ads tag
         * @param {string} contentId
         * @param {string} tenantPlatformSlug
         * @param {string} tenantSlug
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentVmapAdsTag: function (contentId, tenantPlatformSlug, tenantSlug, acceptLanguage, options) {
            return localVarFp.getContentVmapAdsTag(contentId, tenantPlatformSlug, tenantSlug, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get current ads banner
         * @summary Get current ads banner
         * @param {string} tenantPlatformSlug
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentAdsBanner: function (tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.getCurrentAdsBanner(tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getListDisabledAds: function (options) {
            return localVarFp.getListDisabledAds(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get current ads banner
         * @summary Get current ads banner
         * @param {string} tenantPlatformSlug
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [contentId] Content id
         * @param {string} [adParams] Additional ad parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlatformVideoAdsInfo: function (tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, contentId, adParams, options) {
            return localVarFp.getPlatformVideoAdsInfo(tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, contentId, adParams, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.AdsManagementApiFactory = AdsManagementApiFactory;
/**
 * AdsManagementApi - object-oriented interface
 * @export
 * @class AdsManagementApi
 * @extends {BaseAPI}
 */
var AdsManagementApi = /** @class */ (function (_super) {
    __extends(AdsManagementApi, _super);
    function AdsManagementApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get ads.txt or app-ads.txt file for {WebDomain}/ads.txt and {WebDomain}/app-ads.txt
     * @summary Get ads.txt or app-ads.txt file for {WebDomain}/ads.txt and {WebDomain}/app-ads.txt
     * @param {string} adsFileName
     * @param {string} _var
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdsManagementApi
     */
    AdsManagementApi.prototype.adsText = function (adsFileName, _var, options) {
        var _this = this;
        return (0, exports.AdsManagementApiFp)(this.configuration).adsText(adsFileName, _var, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get list of ads banner
     * @summary Get list of ads banner
     * @param {string} tenantPlatformSlug
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {'TOP' | 'LEFT' | 'RIGHT' | 'BOTTOM' | 'MEDIUM_RECTANGLE'} [position] Position of ads banner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdsManagementApi
     */
    AdsManagementApi.prototype.getAdsBanners = function (tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, position, options) {
        var _this = this;
        return (0, exports.AdsManagementApiFp)(this.configuration).getAdsBanners(tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, position, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get video ads of content
     * @param {string} tenantPlatformSlug
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [contentId] Content id
     * @param {string} [adParams] Additional ad parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdsManagementApi
     */
    AdsManagementApi.prototype.getContentVideoAds = function (tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, contentId, adParams, options) {
        var _this = this;
        return (0, exports.AdsManagementApiFp)(this.configuration).getContentVideoAds(tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, contentId, adParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get VMAP ads tag
     * @param {string} contentId
     * @param {string} tenantPlatformSlug
     * @param {string} tenantSlug
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdsManagementApi
     */
    AdsManagementApi.prototype.getContentVmapAdsTag = function (contentId, tenantPlatformSlug, tenantSlug, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdsManagementApiFp)(this.configuration).getContentVmapAdsTag(contentId, tenantPlatformSlug, tenantSlug, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get current ads banner
     * @summary Get current ads banner
     * @param {string} tenantPlatformSlug
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdsManagementApi
     */
    AdsManagementApi.prototype.getCurrentAdsBanner = function (tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdsManagementApiFp)(this.configuration).getCurrentAdsBanner(tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AdsManagementApi
     */
    AdsManagementApi.prototype.getListDisabledAds = function (options) {
        var _this = this;
        return (0, exports.AdsManagementApiFp)(this.configuration).getListDisabledAds(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get current ads banner
     * @summary Get current ads banner
     * @param {string} tenantPlatformSlug
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [contentId] Content id
     * @param {string} [adParams] Additional ad parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdsManagementApi
     */
    AdsManagementApi.prototype.getPlatformVideoAdsInfo = function (tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, contentId, adParams, options) {
        var _this = this;
        return (0, exports.AdsManagementApiFp)(this.configuration).getPlatformVideoAdsInfo(tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, contentId, adParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AdsManagementApi;
}(base_1.BaseAPI));
exports.AdsManagementApi = AdsManagementApi;
