import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import TitleUpComming from './title';
import ListUpComing from './listUpComing';
import { useSelector } from 'react-redux';
import { BANNER_DISPLAY, ILLUON_COLLECTION, ILLUON_SPOTLIGHT } from '../constants';

const getFirstRibbonNoBannerTypeFromRibbonsList = (ribbons = []) => {
  let result = [];

  result = ribbons.filter(ribbon => {
    return ribbon.display_type === ILLUON_COLLECTION;
  });

  return result || [];
};

const Root = styled.div`
  padding-top: 5px;
  padding-bottom: 2em;
  display: block;
  flex-direction: column;
  font-family: 'Neue Machina';
`;

const UpComing = props => {
  const { t, className = '' } = props;
  const home = useSelector(state => state.home);
  const [contents, setContents] = useState(props.contents || []);
  const ribbon = props.ribbon || {};

  useEffect(() => {
    if (!ribbon || !ribbon.items || !ribbon.items.length) {
      return;
    }

    setContents(ribbon.items);
  }, [ribbon]);

  if (!contents.length || !ribbon || ribbon.display_type !== ILLUON_COLLECTION) {
    return null;
  }

  return (
    <Root className={`${className}`}>
      <TitleUpComming titleCollection={ribbon.name || ''} contents={contents} />
      <ListUpComing contents={contents} />
    </Root>
  );
};

export default withTranslation()(UpComing);
