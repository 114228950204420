Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultEpisode = exports.defaultSeasonDetail = exports.defaultTrailer = exports.defaultContentViewInfo = exports.defaultContentDetail = exports.defaultMovieDetail = exports.defaultMovie = exports.defaultRibbonItem = exports.defaultBannerItem = exports.defaultMovieImages = exports.createSubscriptionPlan = exports.createSamsungOneMonthSubscriptionPlan = exports.createSamsungAnnualSubscriptionPlan = exports.createNewestRibbon = exports.createTvShowNavigation = exports.createTvGuidePage = exports.createHomePage = exports.createNullAxiosResponse = void 0;
var constants_1 = require("./constants");
var messages_1 = require("./messages");
var ribbon_utils_1 = require("./utils/ribbon-utils");
/**
 * HARDCODE THINGS
 * **/
function createNullAxiosResponse() {
    return {
        config: null,
        status: 200,
        statusText: 'OK',
        headers: null,
        request: null,
        data: null,
    };
}
exports.createNullAxiosResponse = createNullAxiosResponse;
function createHomePage() {
    return {
        sub_menu: [],
        banner_style: 'HOME_PAGE_STYLE',
        name: messages_1.Messages.get('homeMenuTitle'),
        slug: constants_1.ODXKey.homePageSlug,
        id: constants_1.ODXKey.homePageSlug,
        icon: null,
        display_style: 'NORMAL',
        page_options: {
            contain_sub_item: false,
            content_navigation_option: 'default',
        },
        sub_items: [],
    };
}
exports.createHomePage = createHomePage;
function createTvGuidePage() {
    return {
        sub_menu: [],
        banner_style: 'HOME_PAGE_STYLE',
        name: messages_1.Messages.get('tvGuideMenuTitle'),
        slug: constants_1.ODXKey.tvGuideSlug,
        id: constants_1.ODXKey.tvGuideSlug,
        icon: null,
        display_style: 'TV_GUIDE',
        page_options: {
            contain_sub_item: false,
            content_navigation_option: 'default',
        },
        sub_items: [],
    };
}
exports.createTvGuidePage = createTvGuidePage;
function createTvShowNavigation() {
    return {
        slug: constants_1.ODXKey.tvShowsSlug,
        type: 'category',
        ratio: 'landscape',
        title: messages_1.Messages.get('tvShowMenuTitle'),
        seo_title: '',
        seo_description: '',
        seo_keywords: '',
        og_image: '',
        background_image: '',
        link: '',
    };
}
exports.createTvShowNavigation = createTvShowNavigation;
function createNewestRibbon(pageSlug) {
    var ribbonType;
    var slug = (0, ribbon_utils_1.createRibbonSlugByForCategoryFilter)({
        type: 'category',
        categorySlug: pageSlug,
        sortSlug: 'recent-updated',
    });
    if (pageSlug === 'movies') {
        ribbonType = constants_1.RibbonType.POSTER;
    }
    else {
        ribbonType = constants_1.RibbonType.THUMBNAIL;
    }
    return {
        id: slug,
        slug: slug,
        type: ribbonType,
        name: messages_1.Messages.get('ribbonNewestTitle'),
        sub_name: messages_1.Messages.get('ribbonNewestTitle'),
        display_type: constants_1.RibbonDisplayStyle.THUMBNAIL,
        odr: 1,
        is_visible_in_ribbon_main_section: true,
        is_default_display: false,
        is_visible_in_side_navigation_section: false,
        is_visible_in_sub_navigation_bar: false,
        show_flag_odr: false,
        video_source: 0,
        items: [],
    };
}
exports.createNewestRibbon = createNewestRibbon;
function createSamsungAnnualSubscriptionPlan() {
    return {
        id: 'ODK-PREMIUM-1-YEAR',
        name: messages_1.Messages.get('textAnnual'),
        cost: 100.99,
        real_cost: 100.99,
        unit: 'year',
        text_unit: 'yr',
        currency: 'usd',
        is_trial: true,
        // @ts-ignore
        trial_period_days: 7,
        product_type: 'SERVICE',
        details: [],
        short_description: '',
        description: '',
        save_percent: 0,
        user_choice: 'NORMAL',
        purchase_method: 'RENT',
        status: 'AVAILABLE',
        title_image_url: '',
        price_image_url: '',
        choice_image_url: '',
        stb_device_limits: 0,
        iap_skus: null,
        product_ref_id: null,
        subscription_level: 0,
        supported_payment_methods: [],
    };
}
exports.createSamsungAnnualSubscriptionPlan = createSamsungAnnualSubscriptionPlan;
function createSamsungOneMonthSubscriptionPlan() {
    return {
        id: 'ODK-PREMIUM-1-MONTH',
        name: messages_1.Messages.get('textMonthly'),
        cost: 10.99,
        real_cost: 10.99,
        unit: 'month',
        text_unit: 'mo',
        currency: 'usd',
        is_trial: true,
        // @ts-ignore
        trial_period_days: 7,
        product_type: 'SERVICE',
        details: [],
        short_description: '',
        description: '',
        save_percent: 0,
        user_choice: 'NORMAL',
        purchase_method: 'RENT',
        status: 'AVAILABLE',
        title_image_url: '',
        price_image_url: '',
        choice_image_url: '',
        stb_device_limits: 0,
        iap_skus: null,
        product_ref_id: null,
        subscription_level: 0,
        supported_payment_methods: [],
    };
}
exports.createSamsungOneMonthSubscriptionPlan = createSamsungOneMonthSubscriptionPlan;
function createSubscriptionPlan(productCode) {
    return {
        id: productCode,
        name: '',
        cost: 0,
        real_cost: 0,
        text_unit: 'mo',
        unit: 'month',
        currency: 'usd',
        is_trial: false,
        // @ts-ignore
        trial_period_days: 7,
        product_type: 'SERVICE',
        details: [],
        short_description: '',
        description: '',
        save_percent: 0,
        user_choice: 'NORMAL',
        purchase_method: 'RENT',
        status: 'AVAILABLE',
        title_image_url: '',
        price_image_url: '',
        choice_image_url: '',
        stb_device_limits: 0,
        iap_skus: null,
        product_ref_id: null,
        subscription_level: 0,
        supported_payment_methods: [],
    };
}
exports.createSubscriptionPlan = createSubscriptionPlan;
//region Default MovieImages
exports.defaultMovieImages = {
    banner: '',
    poster: '',
    thumbnail: '',
    backdrop: '',
    banner_movie: '',
    banner_tv_show: '',
    poster_banner: '',
    banner_190_67_ratio: '',
    channel_logo: '',
    channel_wide_logo: '',
    title_image: '',
    rectangle_banner: '',
    banner_19_6_ratio: '',
    thumbnail_9_5_ratio: '',
};
//endregion
// region Default BannerItem
exports.defaultBannerItem = {
    title: '',
    id: '',
    slug: '',
    type: constants_1.ContentType.SHOW,
    long_description: '',
    images: {
        banner: '',
        thumbnail: '',
    },
    language: [],
    subtype: 'default',
    short_title: '',
    title_en: '',
    origin_title: '',
    episode_name: '',
    group: '',
    short_description: '',
    runtime: 0,
    release_year: null,
    release_date: null,
    publish_date: null,
    seasons: null,
    trailers: [],
    is_premium: false,
    payment_type: undefined,
    released_episode_count: 0,
    total_episodes: 0,
    people: [],
    num_first_episode_preview: 0,
    has_free_content: true,
    content_categories: [],
    video_source: 0,
    is_new_release: false,
    restriction: 0,
    price: null,
    metadata: {},
    display_option: {
        display_style: 'default',
    },
};
// endregion
//region Default RibbonItem
exports.defaultRibbonItem = {
    id: '',
    slug: '',
    type: constants_1.ContentType.SHOW,
    title: '',
    short_title: '',
    origin_title: '',
    short_description: '',
    long_description: '',
    runtime: 0,
    release_date: null,
    publish_date: null,
    total_episodes: 0,
    released_episode_count: 0,
    duration: 0,
    images: {
        thumbnail: '',
        poster: '',
        poster_banner: '',
    },
    on_air_time: null,
    is_premium: false,
    payment_type: 'free',
    has_free_content: true,
    category_slug: '',
    content_categories: [],
    video_source: 0,
    is_new_release: false,
    top_index: -1,
    restriction: 0,
    price: null,
    regions: [],
    trailers: [],
    display_option: {
        display_style: 'default',
    },
    genre: [],
    release_year: null,
    language: [],
    metadata: {
        content_ratings: [],
    },
    direct_play_content: null,
};
//endregion
//region Default Movie
exports.defaultMovie = {
    id: '',
    type: constants_1.ContentType.MOVIE,
    title: '',
    known_as: '',
    episode_name: '',
    slug: '',
    payment_type: 0,
    has_free_content: true,
    is_new_release: false,
    top_index: -1,
    group: '',
    short_description: '',
    long_description: '',
    resolution: 0,
    runtime: 0,
    share_urls: '',
    episode: 0,
    people: [],
    genre: [],
    released: null,
    production: [],
    revenue: '',
    budget: '',
    language: [],
    is_single_season: false,
    release_year: null,
    publish_date: null,
    release_date: null,
    favorites: 0,
    views: 0,
    images: {},
    is_watchable: true,
    is_watchlater: false,
    price: null,
    rating: null,
    category: null,
    link_play: '',
    is_favorite: false,
    image_collection: {},
    subtitles: [],
    category_slug: '',
    subtitle_selected_id: '',
    is_premium: false,
    total_episodes: 0,
    released_episode_count: 0,
    content_categories: [],
    video_source: 0,
    youtube_video_id: null,
    restriction: 0,
    regions: [],
    metadata: {},
};
//endregion
//region Default MovieDetail
exports.defaultMovieDetail = {
    id: '',
    group: '',
    type: 1,
    title: '',
    known_as: '',
    short_description: '',
    long_description: '',
    resolution: 0,
    runtime: 0,
    share_urls: '',
    slug: '',
    released: true,
    release_year: null,
    publish_date: null,
    total_seasons: 0,
    episode: 0,
    seasons: [],
    metadata: {
        genres: [],
        peoples: [],
        awards: [],
        sub_categories: [],
        content_ratings: [],
        localizations: [],
        regions: [],
    },
    people: [],
    genre: [],
    production: [],
    revenue: '',
    budget: '',
    language: [],
    episode_name: '',
    season_name: '',
    release_date: null,
    isdvr: 0,
    link_play: '',
    images: {},
    is_single_season: false,
    is_watched: false,
    play_info: {},
    user_rating: 0,
    favorites: 0,
    views: 0,
    is_favorite: false,
    is_watchlater: false,
    progress: 0,
    payment_type: 'free',
    default_episode: {},
    is_watchable: true,
    price: 0,
    category: 0,
    subtitles: [],
    image_collection: {},
    current_season: {},
    subtitle_selected_id: '',
    is_premium: false,
    total_episodes: 0,
    released_episode_count: 0,
    num_first_episode_preview: 0,
    category_slug: '',
    has_free_content: true,
    content_categories: [],
    youtube_video_id: '',
    video_source: 0,
    is_new_release: false,
    provider_slug: '',
    restriction: 0,
};
//endregion
//region Default ContentDetail
exports.defaultContentDetail = {
    id: '',
    slug: '',
    type: 1,
    subtype: 'default',
    short_title: '',
    title: '',
    title_en: '',
    origin_title: '',
    group: null,
    short_description: null,
    long_description: '',
    runtime: 0,
    release_year: null,
    release_date: null,
    publish_date: null,
    total_seasons: null,
    episode: null,
    released_episode_count: 0,
    total_episodes: 0,
    images: {
        banner: '',
        poster: '',
        thumbnail: '',
        backdrop: '',
        banner_movie: '',
        banner_tv_show: '',
        poster_banner: '',
        banner_190_67_ratio: '',
        channel_logo: '',
        channel_wide_logo: '',
        title_image: '',
        rectangle_banner: '',
        banner_19_6_ratio: '',
        thumbnail_9_5_ratio: '',
    },
    language: [],
    subtitles: [],
    is_premium: false,
    payment_type: 'free',
    can_preview: false,
    has_free_content: true,
    num_first_episode_preview: 0,
    content_categories: [],
    duration: 0,
    video_source: 0,
    regions: [],
    is_new_release: false,
    provider_slug: '',
    restriction: 0,
    prices: [],
    metadata: {
        peoples: [],
        genres: [],
        regions: [],
        awards: [],
        localizations: [],
        sub_categories: [],
        content_ratings: [],
    },
    film_code: null,
    production_company_name: null,
    introduction_info: {
        start_time: 0,
        end_time: 0,
    },
    display_option: {
        display_style: 'default',
    },
    start_on: null,
    air_info: {
        first_content_air: '',
        latest_content_air: '',
        air_status: 3,
        air_schedule_type: 'weekly',
        air_weekly_schedule: [],
        air_fixed_schedule: null,
        playable_time: '',
    },
    license_period: {},
    production: [],
    revenue: '$0',
    budget: '$0',
    people: [],
    genre: [],
    category: 1,
    category_slug: '',
    price: null,
    seasons: [],
    trailers: [],
    show_info: null,
};
//endregion
//region Default ContentViewInfo
exports.defaultContentViewInfo = {
    id: '',
    slug: '',
    short_title: '',
    title: '',
    origin_title: '',
    subtitles: [],
    isdvr: 0,
    link_play: '',
    is_favorite: false,
    liked: false,
    progress: 0,
    is_watchable: true,
    subtitle_selected_id: null,
    youtube_video_id: null,
    is_ads_disabled_when_playing: false,
    payment_infors: null,
    drm_session_info: null,
    refund: null,
    default_episode: null,
    current_season: null,
    trailers: [],
    show_info: null,
};
//endregion
//region Default Trailer
exports.defaultTrailer = {
    id: '',
    slug: '',
    title: '',
    images: exports.defaultMovieImages,
    is_premium: false,
    can_preview: true,
    video_source: 0,
    is_new_release: false,
    type: constants_1.ContentType.TRAILER,
    restriction: 0,
    link_play: '',
    runtime: 0,
    short_description: '',
    long_description: '',
    release_date: null,
    duration: 0,
};
//endregion
//region Default SeasonDetail
exports.defaultSeasonDetail = {
    id: '',
    type: constants_1.ContentType.SEASON,
    title: '',
    total_episodes: 0,
    episodes: [],
    slug: '',
    publish_date: null,
    release_date: null,
    is_premium: false,
    season: 1,
    is_new_release: false,
    runtime: 0,
};
//endregion
//region Default Episode
exports.defaultEpisode = {
    id: '',
    slug: '',
    type: constants_1.ContentType.EPISODE,
    short_title: '',
    title: '',
    origin_title: null,
    episode_name: null,
    short_description: null,
    long_description: null,
    runtime: 0,
    release_date: '',
    episode: 1,
    images: exports.defaultMovieImages,
    subtitles: [],
    is_premium: false,
    can_preview: true,
    content_categories: [],
    video_source: 0,
    is_new_release: false,
    restriction: 0,
    air_info: {
        first_content_air: '2022-03-07T06:49:01.053Z',
        latest_content_air: '2022-03-07T06:49:01.053Z',
        air_status: 3,
        air_schedule_type: 'weekly',
        air_weekly_schedule: [],
        air_fixed_schedule: null,
        playable_time: null,
    },
    category_slug: 'phim',
    price: null,
};
//endregion
