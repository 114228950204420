import { IAxiosResponseData } from '~core/models';
import { apiAdapter, config, axiosTakeDataFromResponse } from '~share';

const { documentService, adminDocumentService } = apiAdapter;

export { getFaqs, getIdFaqBlockAds };

function getFaqs(): Promise<IAxiosResponseData<typeof documentService.getPlatformFaqs>> {
  const { tenantSlug = '', platformSlug = '' } = config;
  return documentService.getPlatformFaqs(platformSlug, tenantSlug).then(axiosTakeDataFromResponse);
}

function getIdFaqBlockAds(): Promise<
  IAxiosResponseData<typeof adminDocumentService.getPlatformQnas>
> {
  const { tenantSlug = '', platformSlug = '' } = config;
  return adminDocumentService
    .getPlatformQnas(platformSlug, tenantSlug, true)
    .then(axiosTakeDataFromResponse);
}
