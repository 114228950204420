import * as actionTypes from './actionTypes';
const stripSubscription = {
  stripe_plan_id: '',
  stripe_payment_method_id: '',
  product: null,
  coupon_code: '',
};

const initAddress = {
  address: '',
  city: '',
  address_2: '',
  customer_name: '',
  state: '',
  zip_code: '',
};
const initialState = {
  form: {
    supcriptionPlan: null,
    selectBoxPage: {
      quantity: 0,
      purchaseOption: '',
      isAlready: false,
    },
  },
  stripSubscription,
  receivedAddress: initAddress,
  isLoading: false,
  previousLocation: null,
  sms: {
    transactionId: '',
  },
  total: 0,
};

const box = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PLANSELECTED:
      const subscription = {
        ...action.payload,
      };
      return {
        ...state,
        form: {
          ...state.form,
          supcriptionPlan: subscription,
        },
        stripSubscription: {
          ...state.stripSubscription,
          stripe_plan_id: subscription.id,
        },
      };
    case actionTypes.RESET_DATA_FORM_PLANSELECTED:
      return {
        ...state,
        form: {
          ...state.form,
          supcriptionPlan: null,
        },
        stripSubscription: {
          stripe_plan_id: stripSubscription.stripe_plan_id,
        },
        total: 0,
      };
    case actionTypes.UPDATE_DATA_BOX_SELECTED:
      const { payload } = action;
      const product = {
        stripe_product_id: payload.option,
        quantity: payload.quantity,
      };
      return {
        ...state,
        form: {
          ...state.form,
          selectBoxPage: {
            ...action.payload,
          },
        },
        stripSubscription: {
          ...state.stripSubscription,
          product,
        },
      };
    case actionTypes.RESET_DATA_FORM_BOX_SELECTED:
      return {
        ...state,
        form: {
          ...state.form,
          selectBoxPage: {
            ...initialState.form.selectBoxPage,
          },
        },
        stripSubscription: {
          ...state.stripSubscription,
          product: stripSubscription.product,
        },
        receivedAddress: initAddress,
      };
    case actionTypes.UPDATE_ADDRESS:
      return {
        ...state,
        receivedAddress: action.payload,
      };
    case actionTypes.RESET_ADDRESS:
      return {
        ...state,
        receivedAddress: initAddress,
      };
    case actionTypes.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actionTypes.SET_PREVIOUS_LOCATION:
      return {
        ...state,
        previousLocation: action.payload,
      };
    case actionTypes.SET_TRANSACTION_ID:
      return {
        ...state,
        sms: {
          ...state.sms,
          transactionId: action.payload,
        },
      };
    case actionTypes.SET_PREVIOUS_LOCATION:
      return {
        ...state,
        previousLocation: action.payload,
      };
    case actionTypes.SET_TRANSACTION_ID:
      return {
        ...state,
        sms: {
          ...state.sms,
          transactionId: action.payload,
        },
      };
    case actionTypes.SET_PREVIOUS_LOCATION:
      return {
        ...state,
        previousLocation: action.payload,
      };
    case actionTypes.SET_TRANSACTION_ID:
      return {
        ...state,
        sms: {
          ...state.sms,
          transactionId: action.payload,
        },
      };
    case actionTypes.SET_TOTAL:
      return {
        ...state,
        total: action.payload,
      };
    default:
      return state;
  }
};
export default box;
