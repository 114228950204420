import * as API_STRING from '~constants/apiString';

const track = (payload = {}) => {
  if (!window.ir) {
    return;
  }
  if (!Object.keys(payload).length) {
    return;
  }
  const def = {
    bid_e: API_STRING.REACT_APP_REFERRALS_ENCRYPTED_KEY,
    bid: API_STRING.REACT_APP_REFERRALS_BRAND_ID,
    t: 420,
  };
  const data = { ...def, ...payload };
  window.ir('track', data);
};

const registerEvent = (payload: {}) => {
  track({ ...payload, event: 'register' });
};

const saleEvent = (payload: {}) => {
  track({ ...payload, event: 'sale' });
};

export { track, registerEvent, saleEvent };
