import React, { useState } from 'react';
import { TooltipWrap } from '../actionButton';
import { connect } from 'react-redux';
import { postFavorite } from '../../../../../features/landingpage/services';
import { withTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import { getFavorite } from '../../../../../features/myList/services';

function AddList(props) {
  const { t, item, favoriteList, dispatchGetFavorite, account } = props;
  const isAdded = favoriteList.find(_ => _.id === item.id);
  const [loading, setLoading] = useState(false);
  const handleAddList = () => {
    setLoading(true);
    props
      .dpPostFavorite(item)
      .then(() => dispatchGetFavorite())
      .catch(err => console.log('dispatchGetFavorite error', err))
      .finally(() => setLoading(false));
  };
  if (!account) return null;
  return (
    <TooltipWrap
      title={isAdded ? t('ribbon.removeFromMyList') : t('ribbon.addToMyList')}
      arrow
      placement="top"
    >
      <div className="button" onClick={handleAddList}>
        <div className="button--wrap">
          {loading ? (
            <i
              className="fa fa-circle-o-notch fa-spin"
              style={{ color: 'white' }}
              aria-hidden="true"
            />
          ) : isAdded ? (
            <CheckIcon />
          ) : (
            <AddIcon />
          )}
        </div>
      </div>
    </TooltipWrap>
  );
}
const mapStateToProps = state => ({
  favoriteList: state.myList.favoriteList,
  account: state.auth.account,
});
const mapDispatchToProps = {
  dpPostFavorite: entity => postFavorite(entity),
  dispatchGetFavorite: () => getFavorite(),
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddList));
