import React from 'react';
import styled from 'styled-components';
import { ReactComponent as IconsPause } from '~img/icons/projectw/player/icon_live_pause.svg';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const ActionBarStyled = styled.div`
  padding: 10px;
  background-color: #fff;
  width: 40%;
  max-width: 440px;
  margin-left: 20px;
  @media (max-width: 768px) {
    max-width: unset;
    width: 100%;
    margin-left: unset;
  }
  &.actionBar {
    .actionBar {
      &__container {
        display: flex;
        align-items: center;
        .text__action {
          margin: 0;
          font-size: 13px;
          color: #000;
          font-weight: 600;
          text-transform: uppercase;
        }
        .icons__pause {
          margin-left: auto;
          cursor: pointer;
        }
      }
    }
  }
`;

export default function ActionBar(props) {
  const { player, entity } = props;
  const durationTrailer = (entity || {}).duration || '';
  const [played, setPlayed] = React.useState(false);
  const { t } = useTranslation();

  const handleActionPlayer = () => {
    if (player.paused()) {
      player.play();
      return;
    }
    player.pause();
  };

  React.useEffect(() => {
    const handlePlay = function () {
      setPlayed(false);
    };
    const handlePause = function () {
      setPlayed(true);
    };
    player && player.on('play', handlePlay);

    player && player.on('pause', handlePause);

    return () => {
      player && player.off('play', handlePlay);
      player && player.off('pause', handlePause);
    };
  }, [player]);

  const formatDuration = durationTrailer => {
    const duration = moment.duration(durationTrailer || 0, 'seconds');
    if (durationTrailer < 60) {
      return `${duration.format('s __')}`;
    }

    const roundedDuration = moment.duration(Math.round(duration.asMinutes()), 'minutes');

    return `${roundedDuration.format('m __')}`;
  };

  return (
    <ActionBarStyled className="actionBar">
      <div className="actionBar__container">
        <p className="text__action">
          {t('projectw__landingPage.txtPreview', {
            duration: formatDuration(durationTrailer),
          })}
        </p>
        <div onClick={handleActionPlayer} className="icons__pause">
          {played ? (
            <i
              style={{ color: '#000', lineHeight: 0 }}
              className="fa fa-play vjs-icon-placeholder"
              aria-hidden="true"
            ></i>
          ) : (
            <IconsPause style={{ width: 12, height: 12, lineHeight: 0 }} />
          )}
        </div>
      </div>
    </ActionBarStyled>
  );
}
