import React from 'react';
import styled from 'styled-components';
import { ConversationList } from '@chatscope/chat-ui-kit-react';

const ConversationListChatLiveStyled = styled(ConversationList)`
  && {
    background-color: transparent;
  }
`;
function ConversationListChatLive(props) {
  return <ConversationList />;
}

export default ConversationListChatLive;
