import { pushEventFirebasePlayer } from './../components/pushEventFirebase';
import { getConfigByKey } from '../../tenantConfig/services';
import { getTenantConfig } from './../../../utils/utils';
import jquery from 'jquery';
import { CHANNEL_TYPE } from '../constants';

const _addAdsVideo = (component, changeAdTag = null) => {
  const { entityDetail } = component;
  if (!entityDetail || entityDetail.type === CHANNEL_TYPE) {
    return;
  }
  const { isHasSubcription, infoShow, isRestrictions } = component.state;
  let isAdult = infoShow ? infoShow.restriction : entityDetail.restriction;
  if (
    isHasSubcription ||
    component.entityDetail.is_ads_disabled_when_playing ||
    (isAdult && !isRestrictions)
  )
    return;
  let player = component.videoPlayer;
  const $player = jquery(player.el_);
  let adsCuePoint = [];
  player.preload(true);

  // Populate additional ads params:
  try {
    const imaSettings = player.ima.controller.getSettings();

    imaSettings.adsWillAutoplay = true;
    imaSettings.adsWillPlayMuted = true;
  } catch (ex) {
    console.error('Error while changing IMA settings', ex);
  }
  try {
    player.ima.initializeAdDisplayContainer();
    player.ima.changeAdTag(entityDetail.tagAds);

    player.ima.requestAds();
  } catch (e) {
    // This is just a quick catch to resolve broken layout when there is ad blocker
    // TODO: We need to show a popup to tell user to disable ad blocker instead
    console.error('Error while requesting ads', e);
  }
  player.on('ads-manager', response => {
    let adsManager = response.adsManager;

    const tenantSlug = getConfigByKey('tenant_slug');
    const tenantConfig = getTenantConfig(tenantSlug);
    let adsCueTmp = [];
    Object.values(adsManager).map(item => {
      if (Array.isArray(item) && item.length > 2) adsCueTmp = item;
    });
    adsCuePoint =
      (adsManager.kb && Array.isArray(adsManager.kb) && adsManager.kb) ||
      (adsManager.nb && Array.isArray(adsManager.nb) && adsManager.nb) ||
      (adsManager.jb && Array.isArray(adsManager.jb) && adsManager.jb) ||
      adsCueTmp;
    if (!tenantConfig.CUE_POINT_ADS) {
      adsCuePoint = [];
      return;
    }
    player.on('loadeddata', event => {
      AddCuePoint(adsCuePoint, player, $player);
    });
    if (changeAdTag) {
      AddCuePoint(adsCuePoint, player, $player);
    }
  });

  player.on('contentresumed', response => {});

  player.on('ads-loader', response => {});
  player.on('ads-request', response => {});
  player.on('readyforpreroll', event => {
    RemoveCuePoint(adsCuePoint, player, $player);
    return;
    player.ads.startLinearAdMode();
    player.on('adended', event => {
      player.ads.endLinearAdMode();
    });
  });

  player.on('adsready', event => {});
  player.on('contentchanged', function (event) {
    // in a real plugin, you might fetch new ad inventory here
    player.trigger('adsready');
  });

  player.on('ads-ad-started', event => {
    if (!player.paused()) {
      player.pause();
    }
    checkContentresume(player);
    RemoveCuePoint(adsCuePoint, player, $player);
    pushEventFirebasePlayer(component.entityDetail, 'videoAdImpression'); // event video play ads
    component.isReadyAds = true;
  });

  player.on('ads-ad-ended', event => {});

  player.on('adstart', event => {
    component.subtitleRender = true;
    checkContentresume(player);
  });
  player.on('adskip', event => {
    component._renderNotify();
    !player.ended() && component._checkAutoplay();
  });
  player.on('adend', event => {
    player.ads.endLinearAdMode();
    component._renderNotify();
    if (!player.ended()) {
      component._checkAutoplay();
    }
  });
  player.on('adtimeout', event => {
    checkContentresume(player);
    component._renderNotify();
    component.isReadyAds = true;
  });
  player.on('adserror', event => {
    component._renderNotify();
    component.isReadyAds = true;
    // player.ads.endLinearAdMode()
    !player.ended() && component._checkAutoplay();
  });
};

const AddCuePoint = (adsCuePoint, player, $player) => {
  if (
    !adsCuePoint ||
    (Array.isArray(adsCuePoint) && adsCuePoint.length === 0) ||
    $player.hasClass('vjs-live') ||
    $player.hasClass('vjs-liveui')
  ) {
    return;
  }
  adsCuePoint.map((items, index) => {
    let elem = document.createElement('div');
    elem.className = 'vjs-marker';
    elem.id = `ad${index}`;
    let percent = (items / player.duration()) * 100;
    if (items === -1) {
      percent = 99.8;
      elem.className = 'vjs-marker vjs-marker-end';
    }
    if (percent > 100 || items === 0) {
      return;
    }
    elem.style.left = `${percent - 0.25}%`;
    $player.find('.vjs-progress-holder').append(elem);
  });
};

const RemoveCuePoint = (adsCuePoint, player, $player) => {
  if (adsCuePoint.length === 0) {
    return;
  }
  adsCuePoint.find((items, index) => {
    if (
      items <= player.currentTime() &&
      ((adsCuePoint[index + 1] && adsCuePoint[index + 1] > player.currentTime()) ||
        (adsCuePoint[index + 1] && adsCuePoint[index + 1] === -1))
    ) {
      $player.find(`#ad${index}`).remove();
      return items;
    }
  });
};

const checkContentresume = player => {
  if (player.ads.isContentResuming()) {
    player.ads.endLinearAdMode();
    player.trigger('adend');
    player.trigger('contentresumed');
    if (player.paused()) {
      player.play();
    }
  }
};

export default _addAdsVideo;
