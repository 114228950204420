import { Pagination, IAxiosResponseData } from '~core/models';
import { apiAdapter, config, axiosTakeDataFromResponse } from '~share';
import { ContentListingApiInterface } from '~vimai-api-adapter';

const { contentListingService } = apiAdapter;

const EPG_FEED_SELECT_FIELDS = [
  'end_at',
  'id',
  'is_blackout',
  'slug',
  'start_at',
  'title',
  'type',
  'link_play',
  'subtitles',
  'play_date',
  'description',
  'short_title',
];
const getEpgs = (
  params: { channel_id: string; schedule_date: string } & Pagination,
): Promise<IAxiosResponseData<ContentListingApiInterface['getChannelEpgs']>> => {
  const { channel_id, schedule_date, page, limit } = params;
  const select = JSON.stringify({
    EpgFeedResponse: ['items'],
    EPGFeed: EPG_FEED_SELECT_FIELDS,
  });
  const { acceptLanguage, timezone } = config;

  return contentListingService
    .getChannelEpgs(
      acceptLanguage,
      page,
      limit,
      select,
      schedule_date,
      undefined,
      undefined,
      channel_id,
      timezone,
    )
    .then(axiosTakeDataFromResponse);
};

export { getEpgs };
