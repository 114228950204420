var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRibbonDetailForOdxTvGuidePage = exports.getRibbonDetailForOdxCategoryPage = exports.getPageStaticRibbonsForOdxCategoryPage = exports.getPageStaticRibbonsForHomePage = exports.getPageStaticRibbonsForTvGuide = void 0;
var navigation_requests_1 = require("./navigation-requests");
var integration_service_1 = require("../integration-service");
var moment = require("moment");
var content_listing_parser_1 = require("../parser/content-listing-parser");
var api_adapter_vimai_1 = require("../../api-adapter-vimai");
var ribbon_utils_1 = require("../utils/ribbon-utils");
var mappers_1 = require("../mappers");
function getEpisodesInfo(episodeIds) {
    var tenantSlug = integration_service_1.OdxIntegration.currentProvider.get('tenantSlug');
    var acceptLanguage = integration_service_1.OdxIntegration.currentProvider.get('acceptLanguage');
    return Promise.all(episodeIds.map(function (id) {
        return integration_service_1.OdxIntegration.currentService.odxEpisodeApi
            .episodeRetrieve(id.toString(), tenantSlug, undefined, acceptLanguage)
            .then(function (e) { return e.data.result; })
            .catch(function () { return null; });
    }));
}
function getPageStaticRibbonsForTvGuide(client, tenantSlug, pageSlug, date, acceptLanguage) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    return __awaiter(this, void 0, void 0, function () {
        var tvGuide, dateReq, isToday, availableDates, defaultDate, programs, tomorrow, data, channelIndex;
        return __generator(this, function (_m) {
            switch (_m.label) {
                case 0: return [4 /*yield*/, client.odxTvGuideApi.tvGuideList(tenantSlug, acceptLanguage, undefined)];
                case 1:
                    tvGuide = _m.sent();
                    dateReq = date ? moment(new Date(date)) : moment(new Date());
                    isToday = moment(new Date()).isSame(dateReq, 'date');
                    availableDates = (_b = (_a = tvGuide === null || tvGuide === void 0 ? void 0 : tvGuide.data) === null || _a === void 0 ? void 0 : _a.result) === null || _b === void 0 ? void 0 : _b.date;
                    defaultDate = (availableDates === null || availableDates === void 0 ? void 0 : availableDates.find(function (d) { return moment(d).isSame(dateReq, 'date'); })) || availableDates[availableDates.length - 1];
                    return [4 /*yield*/, client.odxTvGuideApi.tvGuideProgramList(tenantSlug, acceptLanguage, undefined, defaultDate, undefined, {
                            params: {
                                is_today: isToday ? 'true' : 'false',
                            },
                        })];
                case 2:
                    programs = _m.sent();
                    if (client.environment === 'staging') {
                        tomorrow = moment().add(1, 'day').format('YYYY-MM-DD');
                        if (!((_e = (_d = (_c = tvGuide === null || tvGuide === void 0 ? void 0 : tvGuide.data) === null || _c === void 0 ? void 0 : _c.result) === null || _d === void 0 ? void 0 : _d.date) === null || _e === void 0 ? void 0 : _e.includes(tomorrow))) {
                            (_h = (_g = (_f = tvGuide === null || tvGuide === void 0 ? void 0 : tvGuide.data) === null || _f === void 0 ? void 0 : _f.result) === null || _g === void 0 ? void 0 : _g.date) === null || _h === void 0 ? void 0 : _h.push(tomorrow);
                        }
                    }
                    if (((_j = programs === null || programs === void 0 ? void 0 : programs.data) === null || _j === void 0 ? void 0 : _j.result) && !isToday) {
                        data = programs.data.result;
                        channelIndex = data.findIndex(function (c) { return c.slug === 'odk-channels'; });
                        if (channelIndex >= 0) {
                            data.splice(channelIndex, 1);
                        }
                    }
                    return [2 /*return*/, __assign(__assign({}, tvGuide), { data: (0, content_listing_parser_1.ParsePageDetailFromOdxTvGuide)((_k = tvGuide === null || tvGuide === void 0 ? void 0 : tvGuide.data) === null || _k === void 0 ? void 0 : _k.result, (_l = programs === null || programs === void 0 ? void 0 : programs.data) === null || _l === void 0 ? void 0 : _l.result) })];
            }
        });
    });
}
exports.getPageStaticRibbonsForTvGuide = getPageStaticRibbonsForTvGuide;
function getPageStaticRibbonsForHomePage(client, tenantSlug, pageSlug, acceptLanguage) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function () {
        var bannerSlug, rbSlug, pageData, coverData, episodeItems, episodeCoversData;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    bannerSlug = 'home-cover-default';
                    rbSlug = 'home-curation-default';
                    return [4 /*yield*/, Promise.all([
                            (0, navigation_requests_1.getNavigationInfo)(pageSlug, acceptLanguage),
                            client.odxCoverApi.coverRetrieve(bannerSlug, tenantSlug, undefined, acceptLanguage),
                            client.odxCurationApi.curationRetrieve(rbSlug, tenantSlug, undefined, acceptLanguage),
                        ])];
                case 1:
                    pageData = _c.sent();
                    coverData = pageData[1].data.result;
                    episodeItems = ((_b = (_a = coverData === null || coverData === void 0 ? void 0 : coverData.contents) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.filter(function (i) { return i.content_type === 'episode'; })) || [];
                    return [4 /*yield*/, getEpisodesInfo(episodeItems.map(function (e) { return e.episode_id; }))];
                case 2:
                    episodeCoversData = _c.sent();
                    return [2 /*return*/, __assign(__assign({}, pageData[1]), { data: (0, content_listing_parser_1.CombineCoverAndCurationToPageDetail)(pageData[0], coverData, pageData[2].data.result, episodeCoversData) })];
            }
        });
    });
}
exports.getPageStaticRibbonsForHomePage = getPageStaticRibbonsForHomePage;
function getPageStaticRibbonsForOdxCategoryPage(client, tenantSlug, pageSlug, acceptLanguage) {
    var _a, _b, _c, _d, _e;
    return __awaiter(this, void 0, void 0, function () {
        var coverSlug, data, coverData, episodeItems, episodeCoversData, pageData;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    coverSlug = "category-cover-".concat(pageSlug);
                    return [4 /*yield*/, Promise.all([
                            client.odxCoverApi.coverRetrieve(coverSlug, tenantSlug, undefined, acceptLanguage).catch(function () { return null; }),
                            client.odxCategoryApi.categoryRetrieve(pageSlug, tenantSlug, undefined, acceptLanguage),
                        ])];
                case 1:
                    data = _f.sent();
                    coverData = (_b = (_a = data[0]) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.result;
                    episodeItems = ((_d = (_c = coverData === null || coverData === void 0 ? void 0 : coverData.contents) === null || _c === void 0 ? void 0 : _c.items) === null || _d === void 0 ? void 0 : _d.filter(function (i) { return i.content_type === 'episode'; })) || [];
                    return [4 /*yield*/, getEpisodesInfo((episodeItems === null || episodeItems === void 0 ? void 0 : episodeItems.map(function (e) { return e.episode_id; })) || [])];
                case 2:
                    episodeCoversData = _f.sent();
                    pageData = data[1];
                    return [2 /*return*/, __assign(__assign({}, pageData), { data: (0, content_listing_parser_1.ParsePageDetailFromOdxCategory)(pageSlug, (_e = pageData.data) === null || _e === void 0 ? void 0 : _e.result, api_adapter_vimai_1.PageDetailDisplayStyleEnum.TabbedBase, coverData, episodeCoversData) })];
            }
        });
    });
}
exports.getPageStaticRibbonsForOdxCategoryPage = getPageStaticRibbonsForOdxCategoryPage;
function getRibbonDetailForOdxCategoryPage(client, pageSlug, ribbonId, tenantSlug, acceptLanguage, page, limit) {
    var _a, _b;
    if (page === void 0) { page = 0; }
    if (limit === void 0) { limit = 20; }
    return __awaiter(this, void 0, void 0, function () {
        var _c, categorySlug, sortSlug, genreSlug, providerSlug, subCategorySlug, ribbonData, pageData, contentData, ribbonName;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _c = (0, ribbon_utils_1.getFilterOptionsFromRibbonSlug)(ribbonId), categorySlug = _c.categorySlug, sortSlug = _c.sortSlug, genreSlug = _c.genreSlug, providerSlug = _c.providerSlug, subCategorySlug = _c.subCategorySlug;
                    return [4 /*yield*/, Promise.all([
                            client.odxCategoryApi.categoryRetrieve(categorySlug, tenantSlug, undefined, acceptLanguage),
                            client.odxProgramsApi.programsList(tenantSlug, acceptLanguage, undefined, undefined, subCategorySlug || categorySlug, undefined, genreSlug, undefined, undefined, providerSlug, sortSlug, undefined, !isNaN(page) ? page + 1 : undefined, limit),
                        ])];
                case 1:
                    ribbonData = _d.sent();
                    pageData = ribbonData[0];
                    contentData = ribbonData[1];
                    ribbonName = (0, ribbon_utils_1.findRibbonNameBySlugFromCategory)(ribbonId, (_a = pageData.data) === null || _a === void 0 ? void 0 : _a.result);
                    return [2 /*return*/, __assign(__assign({}, contentData), { data: (0, content_listing_parser_1.CombineRibbonInfoAndPageProgramsToRibbonDetailPaging)(ribbonId, ribbonName, (_b = contentData === null || contentData === void 0 ? void 0 : contentData.data) === null || _b === void 0 ? void 0 : _b.result) })];
            }
        });
    });
}
exports.getRibbonDetailForOdxCategoryPage = getRibbonDetailForOdxCategoryPage;
function getRibbonDetailForOdxTvGuidePage(client, pageSlug, ribbonId, date, tenantSlug, acceptLanguage) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var categorySlug, ribbonInfo, categoryInfo;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    categorySlug = (0, ribbon_utils_1.getFilterOptionsFromRibbonSlug)(ribbonId).categorySlug;
                    return [4 /*yield*/, client.odxTvGuideApi.tvGuideProgramList(tenantSlug, acceptLanguage, undefined, date, categorySlug)];
                case 1:
                    ribbonInfo = _b.sent();
                    categoryInfo = (_a = ribbonInfo === null || ribbonInfo === void 0 ? void 0 : ribbonInfo.data) === null || _a === void 0 ? void 0 : _a.result;
                    return [2 /*return*/, __assign(__assign({}, ribbonInfo), { data: (0, mappers_1.mapOdxTVGuideProgramItemToRibbonDetail)(categoryInfo === null || categoryInfo === void 0 ? void 0 : categoryInfo[0]) })];
            }
        });
    });
}
exports.getRibbonDetailForOdxTvGuidePage = getRibbonDetailForOdxTvGuidePage;
