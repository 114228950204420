import styled from 'styled-components';
import { Box } from '@material-ui/core';

export const RowStyled = styled(Box)`
  input {
    caret-color: ${({ theme }) => theme.input.color};
  }
  .MuiInputBase-input {
    color: ${({ theme }) => theme.input.color};
  }
  .checkbox:not(.checkbox-custom) {
    label {
      padding-left: 28px;
    }
  }
  .select-input {
    color: ${({ theme }) => theme.input.placeholder};
    > div {
      background-color: ${({ theme }) => theme.input.backgroundColor};
      + div {
        background-color: #fff;
      }
    }
    .css-1wa3eu0-placeholder {
      color: ${({ theme }) => theme.input.placeholder};
    }
    .css-1uccc91-singleValue {
      color: ${({ theme }) => theme.input.color};
    }
  }
  .txt {
    /* color: ${({ theme }) => theme.register.secondaryColor}; */
    font-size: 14px;
    color: #596d79;
    text-decoration: none solid rgb(89, 109, 121);
    &-link {
      color: ${({ theme }) => theme.register.link};
      text-decoration: underline;
    }
  }
  .btn {
    &--primary {
      color: ${({ theme }) => theme.secondaryColor};
    }
  }
  &.v-login {
    align-items: center;
    flex-grow: 0.5;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    margin: 0 16px;
    input[type='checkbox'] {
      left: 0;
      transform: scale(1.5);
      padding-right: 0.5rem;
      transform-origin: top left;
      margin: 0;
      &:checked:before {
        border: red;
        background-color: red;
      }
    }
    color: ${({ theme }) => theme.signin.secondaryColor};
  }
  .btn-login {
    color: ${({ theme }) => theme.primaryColor};
  }
  .v-login {
    &__custome {
      &--form {
        margin-bottom: 32px;
      }
      &--form2 {
        margin-bottom: 8px;
      }
    }
    &__remember {
      color: #283237;
    }
    &__policy {
      font-size: 12px !important;
      color: #949494;
      text-decoration: underline solid rgb(25, 127, 252);
      text-align: left;
      a {
        color: ${({ theme }) => theme.register.link};
        text-decoration: underline;
      }
    }
    &--row {
      margin: 0 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      align-items: center;
      max-width: 478px;
    }
    &--form {
      border-radius: 8px;
      margin-top: 33px;
      background: ${({ theme }) => theme.register.backgroundColor || '#fff'};
    }
    &--title {
      color: ${({ theme }) => theme.register.color || '#283237'};
      text-transform: uppercase;
      font-weight: 600;
      font-size: 3.25rem;
    }
    &--select {
      label {
        font-size: 14px;
        margin-bottom: 7px;
        font-weight: 100;
        color: #141a23;
      }
      margin-bottom: 20px;
      text-align: left;
      &:active,
      &:focus-within,
      &:hover {
        label {
          color: ${({ theme }) => theme.plans.thirdColor};
        }
      }
      &-dob {
        margin-bottom: 0.25em;
        .select--container {
          svg {
            z-index: 1;
            color: hsl(0, 0%, 80%);
            right: 8px;
          }
        }
      }
    }
    &--btn {
      background: ${({ theme }) => theme.plans.btn};
      color: ${({ theme }) => theme.register.buttonSave.color};
      width: 100%;
      margin: 16px 0 38px;
      padding: 16px 0;
      font-weight: 400;
      border: none;
      &-login,
      &-signup {
        margin-top: 13px;
        font-size: 18px;
        border-radius: 10px;
        background: unset;
        border: 1px solid #fff;
        padding: 15px;
        width: 100%;
        color: #fff;
        &:hover {
          background: ${({ theme }) => theme.plans.btn};
          border: 1px solid ${({ theme }) => theme.plans.btn};
        }
      }
      &-signin {
        margin-top: 18px;
        margin-bottom: 49px;
      }
      &-signup {
        color: #fff;
        margin-top: 6px;
      }
      &-forgot {
        color: ${({ theme }) => theme.plans.btn};
        font-size: 14px;
      }
      &-primary {
        background: #f7f8fa;
        color: #94a3ad;
        &.btn-default {
          &:hover {
            background: #f7f8fa;
          }
        }
      }
      &-fb {
        border: 1px solid;
        background: ${({ theme }) => theme.register.buttonFB.backgroundColor};
        color: ${({ theme }) => theme.register.buttonFB.color};
        position: relative;
        display: flex;
        border-radius: 10px;
        justify-content: center;
        align-items: center;
        padding: 14px 0;
        font-size: 18px;
        font-weight: bold;
        margin: 33px 0 16px;
      }
    }
    &--or {
      text-align: center;
      text-transform: capitalize;
      position: relative;
      margin-bottom: 23px;
      margin-top: 10px;
      &:before {
        position: absolute;
        content: '';
        height: 1px;
        border: 1px solid #e6ecef;
        width: 42%;
        top: 50%;
        left: 0;
      }
      &:after {
        position: absolute;
        content: '';
        height: 1px;
        border: 1px solid #e6ecef;
        width: 42%;
        top: 50%;
        right: 0;
      }
    }
    &--signup,
    &--signin {
      width: 100%;
      color: #fff;
      display: flex;
      flex-direction: column;
      padding: 0 17px;
      p {
        font-size: 14px;
        margin: 33px 0 0;
      }
    }
    &--signup {
      p {
        margin: 22px 0 0;
      }
    }
    &--datepicker {
      width: 100% !important;
      margin-bottom: 1.25rem;
      margin-top: -0.25rem !important;
      padding-top: 1.25rem !important;
      padding-bottom: 0.25rem !important;
      position: relative;
    }
    &--labelPicker {
      color: #2574d4;
      font-weight: 400;
      display: flex;
      top: 0;
      left: 15px;
      font-size: 12px;
      position: absolute;
      width: 100%;
    }
    &--age {
      width: 100%;
      color: #b3bec4;
      text-align: right;
      margin-top: -10px;
      font-size: 12px;
      font-style: italic;
    }
    &--dob {
      margin: 0 0 1.5rem 0;
      width: 100%;
      background-color: transparent;
      .MuiFormLabel-root {
        color: ${({ theme }) => theme.input.placeholder};
        &.Mui-focused {
          color: ${({ theme }) => theme.input.label};
        }
      }
      .MuiInputBase-root {
        &.Mui-focused {
          border-color: ${({ theme }) => theme.input.focus};
        }
      }
      .MuiButtonBase-root {
        background-color: transparent;
        color: ${({ theme }) => theme.input.placeholder};
      }
      div {
        background: none;
        border: 1px solid #b3bec4;
        border-radius: 12px;
        &:before,
        &:hover,
        &:focus,
        &:after {
          position: relative;
          transition: none;
          content: unset;
        }
        button {
          background-color: #fff;
        }
        input {
          padding-top: 22px;
        }
      }
      .MuiInputAdornment-root {
        border-color: transparent;
      }
      p.Mui-error {
        position: absolute;
        bottom: -20px;
      }
    }
    &--iconfb {
      font-size: 35px;
      padding-right: 11px;
    }
  }
  @media (min-width: 576px) {
    .v-login {
    }
  }

  @media (min-width: 768px) {
    .v-login {
      margin: 0;
      &--row {
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        width: 478px;
      }
      &--form {
        padding: 0 26px;
      }
      &--age {
        position: absolute;
        right: -39%;
        width: 33%;
        top: 20px;
        color: #ffffff;
      }
      &--btn {
        margin: 36px 0;
        &-fb {
          margin: 33px 0 16px;
        }
      }
      &--select {
        &-dob {
          margin-bottom: 2em;
        }
      }
      &--or {
        &:before,
        &:after {
          width: 45%;
        }
      }
      &--signup {
        padding: 0px 26px;
      }
    }
  }

  @media (min-width: 992px) {
    .v-login {
      padding-top: 0px;
    }
  }

  @media (min-width: 1200px) {
  }
`;
