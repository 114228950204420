import React, { useState } from 'react';
import { FormatDayTime } from '~root/utils/utils';
import Select from './styled';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

function GetYear() {
  let nowYear = new Date().getFullYear() - 13;
  const defaultYear = 1900;
  const e = React.createElement;
  let rows = [];
  for (nowYear; nowYear > defaultYear; nowYear--) {
    rows.push(e('option', { key: nowYear, value: nowYear }, nowYear));
  }
  return <React.Fragment>{rows.map(items => items)}</React.Fragment>;
}

function GetMonth() {
  let nowMonth = 1;
  const defaultMonth = 13;
  const e = React.createElement;
  let rows = [];
  for (nowMonth; nowMonth < defaultMonth; nowMonth++) {
    rows.push(e('option', { key: nowMonth, value: nowMonth }, nowMonth));
  }
  return <React.Fragment>{rows.map(items => items)}</React.Fragment>;
}

function GetDay({ day }) {
  return <React.Fragment>{day.map(items => items)}</React.Fragment>;
}

function SelectData({ id, method, value, children, disabled }) {
  return (
    <div className="select--container">
      <select
        className="select__value select__daytime"
        id={id}
        onChange={method}
        value={value}
        disabled={disabled}
      >
        {children}
      </select>
      <KeyboardArrowDownIcon />
    </div>
  );
}

function Birthday({ dob, disabled, setDob = null }) {
  const defaultDob = dob ? new Date(dob) : new Date();
  const [state, setState] = useState({
    year: dob ? defaultDob.getFullYear() : defaultDob.getFullYear() - 13,
    month: defaultDob.getMonth() + 1,
    day: defaultDob.getDate(),
    format: FormatDayTime(defaultDob),
  });
  const [dataDay, getDay] = useState([]);

  React.useEffect(() => {
    getDay(updateNumberOfDays());
  }, [state.month, state.year]);

  React.useEffect(() => {
    const { year, month, day } = state;

    setState({
      ...state,
      format: `${year}-${month}-${day}`,
    });
    setDob && setDob(`${year}-${month}-${day}`);
  }, [state.month, state.year, state.day]);

  const handleChange = (e, key) => {
    setState({
      ...state,
      [key]: e.target.value,
    });
  };
  const updateNumberOfDays = () => {
    const { year, month } = state;
    let days = daysInMonth(month, year);
    let rows = [];
    const e = React.createElement;

    function daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    }
    for (let i = 1; i < days + 1; i++) {
      rows.push(e('option', { key: i, value: i }, i));
    }
    return rows;
  };

  return (
    <Select>
      <SelectData
        id="days"
        method={e => handleChange(e, 'day')}
        value={state.day}
        disabled={disabled}
      >
        <GetDay day={dataDay} />
      </SelectData>
      <SelectData
        id="months"
        method={e => handleChange(e, 'month')}
        value={state.month}
        disabled={disabled}
      >
        <GetMonth />
      </SelectData>
      <SelectData
        id="years"
        method={e => handleChange(e, 'year')}
        value={state.year}
        disabled={disabled}
      >
        <GetYear />
      </SelectData>
    </Select>
  );
}

export default Birthday;
