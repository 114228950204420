import React from 'react';
import { ModalStyled } from './styled';
import { Modal, Col, Row, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setWatchNowClick, setLoginModalShow } from '~components/global/globalSlice';
import SignInForm from '../projectw/SignInForm';
import _ from 'lodash';
import VerifyEmailModal from './VerifyEmailModal';

const LoginProjectW = React.memo(props => {
  const component = props.props;
  const { hiddenModalLogin, _resetState } = props;
  const { modalShow } = component;
  const dispatch = useDispatch();

  const hiddenModalLogin_V2 = () => {
    dispatch(setLoginModalShow(true));
    hiddenModalLogin();
  };

  const isAccount = useSelector(state => state.auth.account);

  const isShowVerifyEmailModal = useSelector(state => state.global.isShowVerifyEmailModal);
  const isShowVerifyEmailPPV = useSelector(state => state.global.isShowVerifyEmailPPV);

  if (!_.isEmpty(isAccount) && (isShowVerifyEmailModal || isShowVerifyEmailPPV))
    return <VerifyEmailModal {...props} />;
  return (
    <ModalStyled
      show={modalShow}
      onHide={() => hiddenModalLogin_V2()}
      aria-labelledby="contained-modal-title-vcenter"
      className="v-loginForm project-w"
    >
      <i
        style={{ display: 'none' }}
        onClick={() => hiddenModalLogin_V2()}
        className="fa fa-times"
        id="close"
        aria-hidden="true"
      ></i>
      <Modal.Body>
        <SignInForm {...props} />
      </Modal.Body>
    </ModalStyled>
  );
});

export default LoginProjectW;
