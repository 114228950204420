import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { DFPSlotsProvider, AdSlot, DFPManager } from 'react-dfp';
import uuid from 'react-uuid';
import classnames from 'classnames';
import jquery from 'jquery';
import { isMobile } from 'react-device-detect';

class AdsBanner extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isShow: true,
      adsBanner: null,
      adsBannerType: 'SCRIPT',
      iframeLoaded: false,
      interval: null,
      adsVerical: false,
      displayError: false,
      timeRefreshAds: 45,
    };
    this.SCRIPT = 'SCRIPT';
    this.GOOGLE_DFP = 'GOOGLE_DFP';
    this._id = uuid();
    this._count = 0;
    this.interval = null;
  }

  componentWillMount() {
    const { auth, position } = this.props;
    if (
      auth &&
      auth.account &&
      auth.account.profile &&
      auth.account.profile.subscription_plan_info
    ) {
      this.setState({
        isShow: false,
      });
    }
    if (position === 'left' || position === 'right') {
      this.setState({
        adsVerical: true,
      });
    }
  }

  componentDidMount() {
    const { platformConfig, position } = this.props;
    const { isShow } = this.state;
    const that = this;
    if (!isShow) {
      return;
    }
    if (!platformConfig || (platformConfig && !Array.isArray(platformConfig.ads_banners))) return;
    const arrBanner = platformConfig.ads_banners;
    const adsBannerConfig = arrBanner.find(element => element.position === position.toUpperCase());
    let timer = setTimeout(() => {
      this.detectAdsBanner(adsBannerConfig).then(() => {
        DFPManager.load();
        jquery(window).on('resize', () => {
          that._autoScaleBanner();
        });
      });
      clearTimeout(timer);
    }, 1000);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      DFPManager.load();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.setState({ adsBanner: null });
  }

  detectAdsBanner = adsBannerConfig => {
    return new Promise((resolve, reject) => {
      if (jquery.isEmptyObject(adsBannerConfig)) {
        return;
      }
      this._getAdsBanner(adsBannerConfig);
      this._autoScaleBanner();
      resolve(adsBannerConfig);
    });
  };
  _autoScaleBanner = () => {
    const { position, rezise } = this.props;
    if ((position === 'medium_rectangle' && rezise) || !isMobile) {
      return;
    }
    if (this.state.interval) {
      clearInterval(this.state.interval);
    }
    const iframeInterval = setInterval(() => {
      const containerAdsBanner = jquery(`#${this._id}`);
      const iframes = jquery(`#${this._id} .adBox iframe`);
      this._count++;
      if (this._count > 50) {
        clearInterval(this.state.interval);
        return;
      }
      if (!iframes.length) {
        return;
      }

      iframes.map((index, iframe) => {
        const $iframe = jquery(iframe);
        const w = $iframe.attr('width');
        const h = $iframe.attr('height');

        const ctnW = containerAdsBanner.innerWidth();
        const ctnH = containerAdsBanner.innerHeight();

        let ratio = 1;
        let percent = 1;
        if (w > ctnW) {
          ratio = ctnW / w;
          percent = ratio;
        }
        const newW = w * ratio;
        const newH = h * ratio;

        if (newH > 0) {
          const ads = containerAdsBanner.find('> div').css({
            transform: `scale(${percent})`,
          });
          containerAdsBanner.height(newH);
          containerAdsBanner.width(newW);

          // $iframe.width(newW).height(newH)
          // const imageAd = $iframe.contents().find('img, .img_ad')
          // .css({
          //   'max-width' : '100%',
          //   height: newH,
          //   width: newW
          // })
        }
      });
      clearInterval(this.state.interval);
    }, 100);
    this.setState({
      interval: iframeInterval,
    });
  };

  render() {
    const { adsVerical, displayError, adsBanner } = this.state;
    const { position, nonMargin, customeClass, auth } = this.props;
    if (auth.isHasSubcription || !this.state.isShow) return null;
    return (
      <DivStyled
        className={classnames(
          'v-ads-banner',
          adsVerical ? 'v-detailPage__ads' : 'v-ads-banner__wrap',
          `v-ads-banner__${position}`,
          customeClass,
        )}
        visible={!!adsBanner}
        ads={{
          padding: position === 'medium_rectangle',
          margin: nonMargin,
          displayError: displayError,
        }}
      >
        <div id={this._id} className="v-ads-banner--ads">
          {this._renderAdsBanner()}
        </div>
      </DivStyled>
    );
  }
  _renderAdsBanner = () => {
    if (this.state.adsBannerType === this.SCRIPT) {
      jquery(`#${this._id}`).html(this.state.adsBanner);
      return null;
    }

    if (this.state.adsBannerType === this.GOOGLE_DFP) {
      return this.state.adsBanner;
    }

    return null;
  };
  _getSize = size => {
    let result = [];
    if (!size) {
      return result;
    }
    let sizeParse = size;
    if (typeof size === 'string') {
      sizeParse = JSON.parse(size);
    }
    result = sizeParse.map((object, index) => {
      return [parseInt(object.width), parseInt(object.height)];
    });
    return result;
  };

  displayError = eventData => {
    const { position } = this.props;
    const adsErr = eventData.event.isEmpty;
    const slotId = eventData.slotId;
    this.setState({
      displayError: adsErr,
    });
    if (adsErr) {
      let timout = setTimeout(() => {
        DFPManager.refresh(slotId);
        if (isMobile) {
          this._autoScaleBanner();
        }
        clearTimeout(timout);
      }, 500);
      if (position === 'bottom' || position === 'top') return;
    } else {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        DFPManager.refresh(slotId);
        if (isMobile) {
          this._autoScaleBanner();
        }
      }, this.state.timeRefreshAds * 1000);
    }
  };
  _getAdsBanner = res => {
    if (!res) {
      return;
    }
    const { ads_banner_url, ads_provider, position, sizes } = res;
    if (!ads_provider || jquery.isEmptyObject(ads_provider)) {
      this.setState({
        adsBanner: ads_banner_url,
        adsBannerType: this.SCRIPT,
      });
      return;
    }
    let ads = null;
    const { dfp_ad_unit, dfp_network_id, type } = ads_provider;
    if (type === this.SCRIPT) {
      this.setState({
        adsBanner: ads_banner_url,
        adsBannerType: this.SCRIPT,
      });
    }
    if (type === this.GOOGLE_DFP) {
      ads = (
        <DFPSlotsProvider
          dfpNetworkId={dfp_network_id}
          collapseEmptyDivs
          lazyLoad
          singleRequest
          // sizeMapping={[
          //   { viewport: [1024, 768], sizes: this._getSize(sizes)},
          //   { viewport: [900, 768], sizes: this._getSize(sizes) }
          // ]}
        >
          <AdSlot
            adUnit={dfp_ad_unit}
            slotId={position}
            sizes={this._getSize(sizes)}
            onSlotRender={eventData => this.displayError(eventData)}
          />
        </DFPSlotsProvider>
      );
      this.setState({
        adsBanner: ads,
        adsBannerType: this.GOOGLE_DFP,
      });
    }
  };
}
const DivStyled = styled.div`
  display: ${props => (props.visible ? 'flex' : 'none')};
  justify-content: center;
  height: 100%;
  position: relative;
  margin: ${props => (props.visible ? '1rem 0' : 0)};
  padding: ${props => (props.visible ? '0 20px' : 0)};
  min-height: ${props => (props.visible ? '1.5rem' : 0)};
  &.v-ads-banner__left,
  &.v-ads-banner__right {
    &:before {
      height: 600px;
    }
  }
  &:before {
    content: '\f127';
    background: ${props => `${props.ads.displayError ? `#dadada` : `none`}`};
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    font-family: FontAwesome;
    display: ${props => `${props.ads.displayError ? `flex` : `none`}`};
    justify-content: center;
    align-items: center;
    color: #8a8a8a;
    font-size: 1rem;
    opacity: 0.95;
  }
  .v-ads-banner {
    &--ads {
      z-index: 2;
      flex-basis: 100%;
      display: flex;
      justify-content: center;
      overflow: hidden;
      > div:first-of-type {
        transform-origin: top;
      }
    }
  }
  @media (min-width: 768px) {
    &.v-ads-banner__left,
    &.v-ads-banner__right {
      padding: 0;
    }
    padding: ${props => (props.ads.padding ? 0 : '0 35')}px;
  }
  @media (min-width: 992px) {
    .v-ads-banner {
      &--ads {
        overflow: unset;
      }
    }
  }

  @media (min-width: 1280px) {
  }
`;

const mapStateToProps = state => ({
  auth: state.auth,
  platformConfig: state.tenantConfig.platformConfig,
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdsBanner));
