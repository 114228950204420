// Get the Component base class from Video.js
import { _handleRewatchCuratedLive } from '../function/initCuratedLive';
import ReactDOM from 'react-dom';
import videojs from 'video.js';
import jquery from 'jquery';

var Button = videojs.getComponent('Button');

// The videojs.extend function is used to assist with inheritance. In
// an ES6 environment, `class SeekNext extends Component` would work
// identically.

var Next = videojs.extend(Button, {
  // The constructor of a component receives two arguments: the
  // player it will be associated with and an object of options.
  constructor: function (player, options) {
    this.autoNext = false;
    this.currentEpisode = null;
    this.timerOut = null;
    // It is important to invoke the superclass before anything else,
    // to get all the features of components out of the box!
    Button.apply(this, arguments);
    // If a `text` option was passed in, update the text content of
    // the component.

    player.on('loadeddata', () => {
      setTimeout(() => {
        this.handleHidden();
      }, 0);
    });
    player.on('error', () => {
      this.handleHidden();
    });
  },

  // The `createEl` function of a component creates its DOM element.
  createEl: function () {
    const btn = videojs.dom.createEl('button', {
      className: 'vjs-next-epg vjs-control vjs-button',
    });
    btn.innerHTML = '<i class="vjs-icon-next-item vjs-icon-placeholder" aria-hidden="true"></i>';
    return btn;
  },
  handleHidden: function () {
    const { entity, component } = this.options_;
    if (component.listEpg && component.listEpg.items.length > 0) {
      entity.items = component.listEpg.items;
    }
    let idWatching = component.isWatch && component.isWatch.id;
    let nextEps = '';
    const $player = jquery(this.player_.el_);
    if (entity.items.length > 0) {
      entity.items.map((item, index) => {
        if (item.id === idWatching) {
          this.currentEpisode = index;
        } else {
          $player.find(`[id='${item.id}']`).removeClass('rewatching');
        }
      });
      nextEps = this.currentEpisode + 1;
      $player.find('.vjs-prev-epg').addClass('vjs-button-next-open');
      $player.removeClass('vjs-button-nextepg-open');
      if (this.currentEpisode === 0) {
        $player.find('.vjs-prev-epg').removeClass('vjs-button-next-open');
      }
      if (
        entity.items[nextEps] &&
        $player.find(`[id='${entity.items[nextEps].id}']`).hasClass('active')
      ) {
        $player.find('.vjs-next-epg').removeClass('vjs-button-next-open');
        $player.addClass('vjs-button-nextepg-open');
      }
    }
  },
  handleClick: function (event) {
    const { entity, component } = this.options_;
    let next = this.currentEpisode + 1;
    const $player = jquery(this.player_.el_);
    if (entity.items[next] && $player.find(`[id='${entity.items[next].id}']`).hasClass('active')) {
      return;
    }
    if (entity.items.length > 0) {
      const episode = entity.items[next];
      const preEpisode = entity.items[this.currentEpisode];
      _handleRewatchCuratedLive(component, episode, false);
      if ($player.hasClass('vjs-rewatching') && $player.find(`[id='${episode.id}']`)) {
        $player.find(`[id='${preEpisode.id}']`).removeClass('rewatching');
        $player.find(`[id='${episode.id}']`).addClass('rewatching');
      }
    }
  },
});

// Register the component with Video.js, so it can be used in players.
videojs.registerComponent('NextLive', Next);
