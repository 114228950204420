import { IAxiosResponseData } from '~core/models';
import { apiAdapter, config, axiosTakeDataFromResponse } from '~share';

const { paymentService } = apiAdapter;

export { getPlans };

const getPlans = (
  plan: { plan_id: string } | undefined = undefined,
): Promise<IAxiosResponseData<typeof paymentService.getListSubscriptionPlan>> => {
  const { plan_id = '' } = plan || {};
  const { tenantSlug = '', authorization, platformSlug, environment, acceptLanguage } = config;
  return paymentService
    .getListSubscriptionPlan(
      tenantSlug,
      authorization,
      acceptLanguage,
      environment,
      platformSlug,
      plan_id,
    )
    .then(axiosTakeDataFromResponse);
};
