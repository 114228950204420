Object.defineProperty(exports, "__esModule", { value: true });
exports.Messages = void 0;
var integration_service_1 = require("./integration-service");
var messages = {
    tvShowMenuTitle: {
        en: 'TV Shows',
        ko: 'TV 쇼',
    },
    homeMenuTitle: {
        en: 'Home',
        ko: '홈',
    },
    tvGuideMenuTitle: {
        en: 'TV Guide',
        ko: 'TV 편성표',
    },
    ribbonNewestTitle: {
        en: 'Newest',
        ko: '최신',
    },
    textAll: {
        en: 'All',
        ko: '모두',
    },
    textMonthly: {
        en: 'Monthly',
        ko: '월간',
    },
    textAnnual: {
        en: 'Annual',
        ko: '연간',
    },
};
exports.Messages = {
    get: function (s) {
        var _a;
        var language = (_a = integration_service_1.OdxIntegration.currentProvider) === null || _a === void 0 ? void 0 : _a.get('acceptLanguage');
        if (!['en', 'ko'].includes(language)) {
            language = 'en';
        }
        return messages[s][language];
    },
};
