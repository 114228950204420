import React from 'react';
import styled from 'styled-components';
import { Panel } from 'react-bootstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../index.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { isMobile } from 'react-device-detect';
import homePageId from '../../../../constants/ids/homePageId';
import pushAnalytics from '../../../../services/analytics/Analytics';
import * as displayType from '../../constants';
import { contentTypes, getTenantConfig } from '../../../../utils/utils';
import { getConfigByKey } from '~features/tenantConfig/services';
import classnames from 'classnames';
import Heading from '../heading/headerSctv';
import Header from '../heading';
import PanelBody from '../body';
import { tenantEnvConfig } from '~constants/tenantConfig/tenantEnvConfig';

const RowStyled = styled.div`
  position: relative;
  .slick-slide > div {
    background-color: red;
    max-height: 100%;
    height: 100%;
  }
  &.v-slide {
    &--container {
      min-height: 25vh;
      ul {
        display: none !important;
        &.slick-dots li.slick-active {
          background: ${({ theme }) => theme.primaryColor};
        }
      }
      &.Covers__dot {
        ul {
          display: block !important;
          &.slick-dots {
            right: 3%;
            top: 92%;
            li.slick-active {
              background: ${({ theme }) => theme.primaryColor};
              height: 6px;
              width: 22px;
              position: relative;
              top: 0;
              border-radius: 5px;
            }
            li {
              width: 6px;
              height: 6px;
              border-radius: 50%;
              border: none;
              margin-left: 5px;
              button {
                width: 100%;
                height: 100%;
                padding: unset;
              }
            }
          }
        }
      }
      &.fullscreen {
        margin-bottom: -30vh;
        & .v-slide__thumbnail {
          height: 100vh;
        }
        & .v-slide--container {
          position: absolute;
        }
        & .vignette {
          background-image: linear-gradient(
            to bottom,
            rgba(6, 6, 6, 0) 0,
            rgba(6, 6, 6, 0.15) 15%,
            rgba(6, 6, 6, 0.35) 29%,
            rgba(6, 6, 6, 0.58) 44%,
            #000000 68%,
            #000000 100%
          );
          background-size: 100% 100%;
          background-position: 0 top;
          background-repeat: repeat-x;
          background-color: transparent;
          width: 100%;
          height: 14.7vw;
          top: auto;
          bottom: -1px;
          opacity: 1;
          &.vignette-layer {
            z-index: 1;
            position: absolute;
            left: 0;
            right: 0;
          }
        }
      }
    }
  }
  font-size: 1rem;
  p {
    margin: 0;
  }
  .v-slide {
    &--touchBanner {
      padding-top: calc(9 / 16 * 100%);
      visibility: visible;
      opacity: 1;
      & .prevButtonIcon,
      & .nextButtonIcon {
        color: ${({ theme }) => theme.primaryColor};
      }
    }
    &--slide {
      border-radius: 0;
      border: none;
      margin-bottom: 0;
      display: block !important;
      cursor: pointer;
    }
    &--fa {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    font-size: 1rem;
    .slick-slide > div {
      background-color: red;
      max-height: 100%;
      height: 100%;
    }
    &.v-slide {
      &--container {
        min-height: 30vh;
        ul {
          display: block !important;
        }
        &.fullscreen {
          .v-slide__body {
            bottom: 14.7vw;
            left: 30px;
          }
          .slick-dots {
            top: 70%;
          }
        }
      }
    }
    .v-slide {
      &--touchBanner {
        padding-top: calc(6 / 19 * 100%);
        visibility: hidden;
        opacity: 0;
      }
      &--info {
        padding: 0 2% 0 45px;
      }
      &--slide {
        position: relative;
        height: 100%;
      }
    }
  }

  @media (min-width: 1280px) {
  }
  @media (min-width: 1440px) {
  }
  @media (min-width: 1680px) {
  }
`;

const settings = {
  dots: true, // todo set to false if tenant is Project W
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  adaptiveHeight: false,
  autoplay: true,
  cssEase: 'linear',
  autoplaySpeed: 4000,
  prevArrow: <PrevButton />,
  nextArrow: <NextButton />,
};

class SliderSctv extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      banners: [],
      width: window.innerWidth,
    };
  }

  componentDidMount() {
    const { page } = this.props;
    if (page && page.banners) {
      this.setState({
        banners: page.banners,
      });
    }
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.page !== nextProps.page) {
      this.setState({
        banners: nextProps.page.banners,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  _hrefUrl(item, index) {
    const { history, language } = this.props;
    let statusText = contentTypes(item);
    if (item.bannerExtra) {
      const newWindow = window.open(
        'https://www.contentpromo.ondemandkorea.com/odv-tundra-vi',
        '_blank',
        'noopener,noreferrer',
      );
      if (newWindow) newWindow.opener = null;
      return;
    }
    let linkPlay = `/${language}/landing/${item.slug}`;
    pushAnalytics('select_content', {
      content_type: statusText,
      item_id: item.slug,
      widget: 'Header',
      order: index + 1,
    });
    if (item.video_source === 1 && item.type === displayType.SHOW_TYPE) {
      return history.push(linkPlay);
    }
    if (
      item.type === displayType.CHANNEL_TYPE ||
      item.type === displayType.CURATED_LIVE ||
      item.video_source === 1
    ) {
      return history.push(`/${language}/detail/${item.slug}`);
    }
    history.push(linkPlay);
  }

  render() {
    const { banners: orignBanners, width } = this.state;
    const bannerExtra = orignBanners.filter(banner => !banner.id);
    const banners =
      orignBanners.length >= 10 ? [...orignBanners.slice(0, 10), ...bannerExtra] : orignBanners;
    const { account, customeClass } = this.props;
    let img = '';

    const tenantConfig = getTenantConfig(getConfigByKey('tenant_slug'));
    if (banners && Array.isArray(banners) && !banners.length)
      return <Panel className="v-slide--slide" />;
    return (
      <RowStyled
        className={classnames(
          'topSlider_home v-slide--container',
          tenantConfig && tenantConfig.BANNER_STYLE,
          customeClass,
        )}
      >
        <Slider {...settings}>
          {banners.map((item, index) => {
            if (account && account.profile.subscription_plan_info) {
              item.is_premium = false;
              item.has_free_content = true;
            }
            const { banner, backdrop, poster, banner_190_67_ratio, rectangle_banner } = item.images;
            img = banner || backdrop;
            if (width <= 992) {
              img = backdrop;
            }
            const isSctvTenant = getConfigByKey('tenant_slug') === 'sctv';
            if (isSctvTenant) {
              img = backdrop;
              if (isMobile || width <= 992) {
                img = poster;
              }
            }
            const isOdv = getConfigByKey('tenant_slug') === 'odv';
            if (isOdv) {
              img = banner_190_67_ratio || banner;
              if (isMobile || width <= 992) img = rectangle_banner || img;
            }
            return (
              <Panel
                key={index}
                className="v-slide--slide"
                onClick={() => this._hrefUrl(item, index)}
              >
                {tenantEnvConfig.tenant_slug !== 'odv' ? (
                  <Heading img={img} data={item} newBanner={customeClass} />
                ) : (
                  <Header img={img} data={item} newBanner={customeClass} />
                )}
                <PanelBody data={item} index={index} showLoginAction={this.props.showLoginAction} />
              </Panel>
            );
          })}
        </Slider>
      </RowStyled>
    );
  }
}

SliderSctv.defaultProps = {
  showLoginAction: () => {},
};
SliderSctv.propTypes = {
  account: PropTypes.object,
  page: PropTypes.object,
  showLoginAction: PropTypes.func,
  isLoadingPage: PropTypes.any,
};

const mapStateToProps = state => ({
  page: state.home.page,
  account: state.auth.account,
  isLoadingPage: state.home.isLoadingPage,
  language: state.root.language,
});

export default withRouter(connect(mapStateToProps, null)(SliderSctv));

function PrevButton({ onClick }) {
  return (
    <div
      data-testid={homePageId.bannerId.prevContentBtn}
      onClick={() => {
        pushAnalytics('click', {
          content_type: 'button',
          item_name: 'left',
        });
      }}
      className="v-slide--prevButton v-slide--touchBanner"
    >
      <i className="fa fa-chevron-left prevButtonIcon" onClick={onClick} />
    </div>
  );
}

function NextButton({ onClick }) {
  return (
    <div
      data-testid={homePageId.bannerId.nextContentBtn}
      onClick={() => {
        pushAnalytics('click', {
          content_type: 'button',
          item_name: 'right',
        });
      }}
      className="v-slide--nextButton v-slide--touchBanner"
    >
      <i className="fa fa-chevron-right nextButtonIcon" onClick={onClick} />
    </div>
  );
}
