import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getConfigByKey } from '~features/tenantConfig/services';
import { FormControl, Button, Checkbox } from 'react-bootstrap';
import loginFormId from '~constants/ids/loginFormId';
import styled from 'styled-components';
import pushAnalytics from '~root/services/analytics/Analytics';
import { verifyEmailFormat, verifyPhoneNumber } from '../../../../utils/utils';
import classnames from 'classnames';
import OverlayLoading from '~components/loading/overlayLoading';
import Loader from 'react-loader-spinner';
import { ReactComponent as IconsPassword } from '../../../../assets/icons/eyeW.svg';
import { ReactComponent as IconsHidePassword } from '../../../../assets/icons/eyeWFlash.svg';

const LoginEmail = props => {
  const { t } = useTranslation();
  const { password, email, resMessage, isLoading } = props.state;
  const component = props.props;
  const { param, showForgotPassAction, rememberAccount } = component;
  const [checkbox, setCheckBox] = useState(rememberAccount.checkbox);
  const [isShow, setShowPassword] = useState(false);
  const validEmail = verifyEmailFormat(email);

  return (
    <React.Fragment>
      <div className="v-loginForm--authTemplate">
        <p className="v-loginForm--legal project-w--legal">{t(`loginScreen.EmailW`)}</p>
        {getConfigByKey('ui_theme') === 'projectw' ? (
          <FormControlW
            data-testid={loginFormId.emailTxt}
            type="text"
            style={resMessage ? { color: '#E92121' } : {}}
            placeholder={
              getConfigByKey('tenant_slug') === 'sctv'
                ? t(`loginScreen.placeholderEmail.sctv`)
                : getConfigByKey('ui_theme') === 'projectw'
                ? t(`loginScreen.placeholderEmail.projectw`)
                : t(`loginScreen.placeholderEmail.default`)
            }
            name={t(`loginScreen.placeholderEmail.projectw`)}
            autoComplete="off"
            id="formLogin"
            value={email}
            onChange={e => props._onChangeText('email', e)}
            onKeyDown={e => props._onKeyDownEnter(e)}
          />
        ) : (
          <FormControlStyled
            data-testid={loginFormId.emailTxt}
            type="text"
            placeholder={
              getConfigByKey('tenant_slug') === 'sctv'
                ? t(`loginScreen.placeholderEmail.sctv`)
                : t(`loginScreen.placeholderEmail.default`)
            }
            name="name"
            id="formLogin"
            value={email}
            onChange={e => props._onChangeText('email', e)}
            onKeyDown={e => props._onKeyDownEnter(e)}
          />
        )}
        {getConfigByKey('ui_theme') === 'projectw' && !validEmail && (
          <p className="error-data project-W-error">{resMessage}</p>
        )}
      </div>
      <div className="v-loginForm--authTemplate">
        <p className="v-loginForm--legal project-w--legal">
          {t('registerScreen.placeholderPassword')}
        </p>
        {getConfigByKey('ui_theme') === 'projectw' ? (
          <FormControlW
            data-testid={loginFormId.passwordTxt}
            type={`${isShow ? 'text' : 'password'}`}
            className="input__password"
            placeholder={t('loginScreen.placeholderPassword')}
            value={password}
            name={t('loginScreen.placeholderPassword')}
            autoComplete="new-password"
            id="formLogin"
            onChange={e => props._onChangeText('password', e)}
            onKeyDown={e => props._onKeyDownEnter(e)}
          />
        ) : (
          <FormControlStyled
            data-testid={loginFormId.passwordTxt}
            type={`${isShow ? 'text' : 'password'}`}
            className="input__password"
            placeholder={t('loginScreen.placeholderPassword')}
            value={password}
            name="password"
            id="formLogin"
            onChange={e => props._onChangeText('password', e)}
            onKeyDown={e => props._onKeyDownEnter(e)}
          />
        )}
        {getConfigByKey('ui_theme') === 'projectw' && validEmail && (
          <p className="error-data project-W-error">{resMessage}</p>
        )}
        <div
          className="v-loginForm--showPass"
          onClick={() => setShowPassword(!isShow)}
          style={getConfigByKey('ui_theme') === 'projectw' ? { display: 'none' } : {}}
        >
          <i className={classnames(isShow ? 'fa fa-eye' : 'fa fa-eye-slash')} aria-hidden="true" />
        </div>
        <button
          className="v-loginForm--showPass project-w--showPass"
          onClick={e => {
            e.preventDefault();
            setShowPassword(!isShow);
          }}
          style={
            getConfigByKey('ui_theme') === 'projectw'
              ? !resMessage
                ? { top: '50px', right: 0 }
                : resMessage && !validEmail
                ? { top: '50px', right: 0 }
                : { top: '50px', right: 0 }
              : { display: 'none' }
          }
        >
          {getConfigByKey('ui_theme') === 'projectw' &&
            (isShow ? <IconsPassword /> : <IconsHidePassword />)}
        </button>
      </div>
      <div className="v-loginForm--forgot">
        <Checkbox
          className="v-loginForm--forgot-checkbox"
          style={getConfigByKey('ui_theme') === 'projectw' ? { display: 'none' } : {}}
          onChange={e => setCheckBox(e.target.checked)}
          checked={checkbox}
        >
          <span>{t('loginScreen.cbRememberMe')}</span>
        </Checkbox>
        <Button
          data-testid={loginFormId.forgotPassword}
          bsStyle="link"
          style={getConfigByKey('ui_theme') === 'projectw' ? { display: 'none' } : {}}
          className="v-loginForm--forgotPassword"
          onClick={() => {
            pushAnalytics('click', {
              content_type: 'button',
              item_name: 'Forgot your password',
            });
            showForgotPassAction();
            param && param.payperview && param.method.hideModal();
          }}
        >
          {t('loginScreen.buttonTextForgotPass')}
        </Button>
      </div>

      <Button
        data-testid={loginFormId.loginBtn}
        onClick={() => props._signIn(checkbox)}
        className={classnames(
          'v-loginForm--signIn',
          getConfigByKey('ui_theme') === 'projectw' ? 'hide' : '',
        )}
        bsSize="large"
      >
        {getConfigByKey('ui_theme') === 'projectw' ? 'login' : t('loginScreen.buttonTextSignIn')}
      </Button>

      {getConfigByKey('ui_theme') === 'projectw' ? (
        <Button
          data-testid={loginFormId.loginBtn}
          onClick={() => props._signIn(checkbox)}
          style={
            resMessage
              ? { background: '#e92121', color: '#fff' }
              : !email || !password
              ? {}
              : { background: '#000', color: '#fff', pointerEvents: 'all' }
          }
          className="project-w--signIn"
          bsSize="large"
        >
          {isLoading ? (
            <Loader type={'Oval'} color="white" height="20" width="20" />
          ) : (
            `${t(`loginScreen.buttonLogIn`)}`
          )}
        </Button>
      ) : (
        ''
      )}
      {getConfigByKey('ui_theme') === 'projectw' ? (
        <Button
          data-testid={loginFormId.forgotPassword}
          bsStyle="link"
          className={classnames(
            'v-loginForm--forgotPassword',
            getConfigByKey('tenant_slug') === 'projectw' ? 'project-w--forgotPassword' : '',
          )}
          // className="v-loginForm--forgotPassword"
          onClick={() => {
            pushAnalytics('click', {
              content_type: 'button',
              item_name: 'Forgot your password',
            });
            showForgotPassAction();
            param && param.payperview && param.method.hideModal();
          }}
        >
          {t('loginScreen.buttonTextForgotPassW')}
        </Button>
      ) : (
        ''
      )}
      {getConfigByKey('ui_theme') !== 'projectw' && <p className="error-data">{resMessage}</p>}
    </React.Fragment>
  );
};

const FormControlStyled = styled(FormControl)`
  font-size: 18px;
  width: 100%;
  padding: 1.5em 1em;
  color: ${({ theme }) => theme.primaryColor};
  margin-bottom: 1.775em;
  border-radius: 10px;
  border: 1px solid #c8d4db;
  color: #949494;
  &.input__password {
    margin-bottom: 10px;
  }

  &::placeholder {
    color: #c8d4db;
    opacity: 1;
    font-size: 18px;
  }
  &:hover,
  &:focus {
    background: #fff;
    color: #949494;
  }
`;

const FormControlW = styled.input`
  font-size: 17px;
  width: 100%;
  padding: 10px 8px;
  font-weight: 400;
  margin-bottom: 17px;
  border-radius: 0;
  color: #000000;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #c7c7cc;
  &.input__password {
    margin-bottom: 10px;
  }

  &::placeholder {
    color: #c7c7cc;
    opacity: 1;
    font-size: 18px;
  }
  &:hover,
  &:focus {
    background: #fff;
    color: #000000;
    outline: none !important;
  }
  input:focus {
    outline: none !important;
  }
`;

export default LoginEmail;
