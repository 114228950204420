import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { timeFormatDurationHHMMSS } from '~core/method/contentMethod';
import _ from 'lodash';
const DurationTime = styled.div`
  font-family: 'Neue Haas Grotesk Display Pro';
  /* display: none; */
  position: absolute;
  bottom: 72px;
  right: 10px;
  margin-bottom: calc(10px / var(--scaling));
  .live__totalTime {
    color: #fff;
    font-size: 16px;
    line-height: 21.36px;
    font-weight: 400;
    letter-spacing: 0.025em;
    margin: 0;
    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
`;

function DurationContent(props) {
  const { entity, player, isTrailer, trailer } = props;
  return (
    <DurationTime classNam="live__durationContent">
      <p className="live__totalTime">
        {timeFormatDurationHHMMSS.call(isTrailer ? trailer : entity)}
      </p>
    </DurationTime>
  );
}

export default DurationContent;
