import * as accountApi from '~api/account';
export const updateNicknameChat = nickname => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      accountApi
        .updateNicknameChat({ nickname })
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
  };
};
