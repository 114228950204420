import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { Panel } from 'react-bootstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../index.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import pushAnalytics from '../../../../services/analytics/Analytics';
import * as displayType from '../../constants';
import { contentTypes } from '../../../../utils/utils';
import Heading from '../heading';
import PanelBody from '../body';
import { DivStyled } from '../../styles/slider';
import SliderSctv from './sliderSctv';
import { tenantEnvConfig } from '~constants/tenantConfig/tenantEnvConfig';
import { isNode } from '~utils/ssr';
import OverlayLoading from '~components/loading/overlayLoading';
import classnames from 'classnames';
import { getLinkToPlayer } from '~features/homepage/functions';

const SliderOdv = React.memo(props => {
  const { page, auth, isMobile, showLoginAction, history, isHasSubcription, language } = props;
  const [mainSlider, setMainSlider] = useState(null);
  const [subSlider, setSubSlider] = useState(null);
  const [loading, setLoading] = useState(false);
  const banners = (page && page.banners) || [];

  const settings = {
    dot: false,
    className: 'v-newSlider__sub--center',
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    centerMode: true,
    centerPadding: '0px',
    initialSlide: 0,
    infinite: true,
    vertical: true,
    focusOnSelect: true,
    verticalSwiping: true,
  };

  const settingsMainSlide = {
    fade: true,
    adaptiveHeight: false,
    cssEase: 'linear',
    className: 'v-newSlider__main',
  };

  const linkDetail = async (item, index) => {
    let statusText = contentTypes(item);
    const { has_free_content, id } = item;
    if (item.bannerExtra) {
      const newWindow = window.open(
        'https://www.contentpromo.ondemandkorea.com/odv-tundra-vi',
        '_blank',
        'noopener,noreferrer',
      );
      if (newWindow) newWindow.opener = null;
      return;
    }
    let linkPlay = `/landing/${item.slug}`;
    let search = '';
    if (item.type === displayType.SHOW_TYPE && has_free_content) {
      let obj = await getLinkToPlayer(id, { setLoading });
      if (obj) {
        search = obj.search;
        linkPlay = obj.linkPlay;
      }
    }
    pushAnalytics('select_content', {
      content_type: statusText,
      item_id: item.slug,
      widget: 'Header',
      order: index + 1,
    });
    if (
      item.type === displayType.CHANNEL_TYPE ||
      item.type === displayType.CURATED_LIVE ||
      item.video_source === 1 ||
      (item.type === displayType.MOVIE_TYPE && has_free_content)
    ) {
      linkPlay = `/detail/${item.slug}`;
    }
    history.push({
      pathname: `/${language}${linkPlay}`,
      search: search,
      state: { navigation: 'home' },
    });
  };

  if (!banners.length) return null;
  if (isMobile) {
    return <SliderSctv {...props} customeClass="Covers__dot" />;
  }
  return (
    <DivStyled
      className="v-newSlider v-newSlider-odv"
      props={{ isHasSubcription: isHasSubcription }}
    >
      {loading ? <OverlayLoading /> : null}

      <Slider {...settingsMainSlide} asNavFor={subSlider} ref={slider => setMainSlider(slider)}>
        {banners.slice(0, 10).map((item, index) => {
          let img = '';
          if (auth.isHasSubcription) {
            item.is_premium = false;
            item.has_free_content = true;
          }
          const { banner, backdrop, banner_190_67_ratio } = item.images;
          img = banner_190_67_ratio || banner || backdrop;
          return (
            <Panel
              key={index}
              className="v-slide--slide v-newSlider__slide"
              onClick={() => linkDetail(item, index)}
            >
              <Heading img={img} data={item} newBanner />
              <PanelBody data={item} index={index} showLoginAction={showLoginAction} />
            </Panel>
          );
        })}
      </Slider>
      <div
        className={classnames('v-newSlider__sub', isNode() && 'hide')}
        style={tenantEnvConfig.ui_theme !== 'projectw' ? {} : { bottom: '30px', right: '25px' }}
      >
        <Slider asNavFor={mainSlider} ref={slider => setSubSlider(slider)} {...settings}>
          {banners.slice(0, 10).map((item, index) => {
            const { thumbnail } = item.images;
            return (
              <Panel key={index} className="v-slide--slide v-newSlider__sub-wrapper">
                <Heading img={thumbnail} data={item} secondSlider />
              </Panel>
            );
          })}
        </Slider>
      </div>
    </DivStyled>
  );
});

SliderOdv.defaultProps = {
  showLoginAction: () => {},
};
SliderOdv.propTypes = {
  auth: PropTypes.any,
  page: PropTypes.object,
};

const mapStateToProps = state => ({
  page: state.home.page,
  auth: state.auth,
  isHasSubcription: state.auth.isHasSubcription,
  language: state.root.language,
});

const mapDispatchToProps = {};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SliderOdv));
