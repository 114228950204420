import GroupButtonReplay from '../playGroupButton';
import ReactDOM from 'react-dom';
import videojs from 'video.js';
import React from 'react';
import jquery from 'jquery';

import { pushEventFirebase } from '../../../components/pushEventFirebase';

const vjsComponent = videojs.getComponent('Component');

class listButtonPlay extends vjsComponent {
  constructor(player, options) {
    super(player, options);

    /* Bind the current class context to the mount method */
    this.mount = this.mount.bind(this);

    /* When player is ready, call method to mount React component */
    player.ready(() => {
      this.mount();
    });

    /* Remove React root when component is destroyed */
    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }

  createEl() {
    const div = videojs.dom.createEl('div', {
      className: 'vjs--escondido-wrapButtonReplay',
      id: 'vjs-epg-display',
    });
    return div;
  }

  getDuration = () => {
    let duration = 30;
    if (this.options_ && this.options_.duration && typeof this.options_.duration === 'number') {
      duration = this.options_.duration;
    }
    return duration;
  };

  seekPrev = event => {
    const duration = this.getDuration();
    pushEventFirebase(this.options_.entityDetail, 'rewind');
    const currentTime = this.player_.currentTime() - duration;
    this.player_.currentTime(currentTime);
  };

  seekNext = event => {
    const duration = this.getDuration();
    pushEventFirebase(this.options_.entityDetail, 'fast-forward');
    const currentTime = this.player_.currentTime() + duration;
    this.player_.currentTime(currentTime);
  };

  livePrev = () => {
    const $player = jquery(this.player_.el_);
    $player.find('.vjs-prev-epg').trigger('click');
  };

  liveNext = () => {
    const $player = jquery(this.player_.el_);
    $player.find('.vjs-next-epg').trigger('click');
  };

  epsPrev = () => {
    const $player = jquery(this.player_.el_);
    const prev = $player.find('.vjs-prev');
    if (prev.hasClass('vjs-episodes--hide')) return;
    prev.trigger('click');
  };

  epsNext = () => {
    const $player = jquery(this.player_.el_);
    const next = $player.find('.vjs-next');
    if (next.hasClass('vjs-episodes--hide')) return;
    next.trigger('click');
  };

  handlePlay = () => {
    if (this.player_.paused()) {
      this.player_.play();
    } else {
      this.player_.pause();
    }
    // this.player_.controlBar.playToggle.one('click', function (e) {
    //   console.log('e', e)
    // })
  };

  mount() {
    const { component, type } = this.options_;
    ReactDOM.render(
      <GroupButtonReplay
        component={component}
        type={type}
        player={component.videoPlayer}
        seekPrev={this.seekPrev}
        seekNext={this.seekNext}
        livePrev={this.livePrev}
        epsPrev={this.epsPrev}
        epsNext={this.epsNext}
        liveNext={this.liveNext}
        handlePlay={this.handlePlay}
        body="replay-ui"
      />,
      this.el(),
    );
  }
}

/**
 * Make sure to register the vjsComponent so Video JS knows it exists
 */
vjsComponent.registerComponent('listButtonPlay', listButtonPlay);

export default listButtonPlay;
