import ReactOneSignal from 'react-onesignal';
import * as API_STRING from '~constants/apiString';
import { getConfigByKey } from '~features/tenantConfig/services';

const initialize = (app: any) => {
  if ((window.OneSignal && window.OneSignal.initialized) || !getConfigByKey('features.onesignal')) {
    return;
  }
  const { app_id, safari_web_id } = getConfigByKey('integrations.onesignal');
  if (!app_id) {
    return;
  }
  const { t } = app.props;
  const options = {
    // allowLocalhostAsSecureOrigin: true,
    // requiresUserPrivacyConsent: true,
    // autoRegister: false,
    // autoResubscribe: true,
    appId: app_id,
    safari_web_id: safari_web_id,
    notifyButton: {
      enable: true,
    },
    allowLocalhostAsSecureOrigin: true,
    promptOptions: {
      slidedown: {
        prompts: [
          {
            text: {
              /* limited to 90 characters */
              actionMessage: t('oneSignalText.actionMessage'),
              /* acceptButton limited to 15 characters */
              acceptButton: t('oneSignalText.acceptButton'),
              /* cancelButton limited to 15 characters */
              cancelButton: t('oneSignalText.cancelButton'),
            },
          },
        ],
      },
    },
  };
  ReactOneSignal.init(options).then(() => {
    ReactOneSignal.showSlidedownPrompt().then(() => {
      // do other stuff
    });
  });

  ReactOneSignal.on('popoverShown', function () {
    const slidedownBodyMessage = window.document.querySelector('.slidedown-body-message');
    if (!slidedownBodyMessage) {
      return;
    }
    slidedownBodyMessage.innerHTML = t('oneSignalText.actionMessage');
  });
  // const interval = setInterval(() => {
  //   if (!ReactOneSignal.initialize || !window.OneSignal) {
  //     return
  //   }
  //   try {
  //     window.OneSignal.showSlidedownPrompt()
  //   } catch (e) {
  //     console.error(
  //       'Error while showing the OneSignal notification permission request',
  //       e,
  //     )
  //   }
  //   clearInterval(interval)
  // }, 2000)
};

const onsignalService = {
  initialize,
};

export default onsignalService;
