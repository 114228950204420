import React from 'react';
import { getConfigByKey } from '~features/tenantConfig/services';
import { useTranslation } from 'react-i18next';
import InfoContents from '../infoContents';
import YoutubePlayer from './youtube';
import { DivStyle } from '../../views/playerClipModeCss';
import Loader from 'react-loader-spinner';
import FullMode from './fullMode';
import Related from '../related';
import AdsBanner from '~components/adsBanner/views/index';
import { isMobile } from 'react-device-detect';
import { TENANT_NAME_ENUM } from '~core/constants';

const ClipMode = React.memo(props => {
  const { t } = useTranslation();
  const { entityDetail, width, infoShow, isHasSubcription, id, isLive, renderYoutube, isBlockAds } =
    props.state;
  const { component } = props;

  let adsVertical = (isHasSubcription && width >= 1024) || width < 1024 ? false : true;
  return (
    <DivStyle className={`v-detailPage ${getConfigByKey('tenant_slug')}`}>
      {!isHasSubcription ? (
        <AdsBanner position="left" customeClass={!adsVertical && 'hide'} />
      ) : null}
      <div className="v-detailPage__playerWrap" style={{ width: !adsVertical && '100%' }}>
        <div className="v-detailPage__player">
          <div
            className="v-detailPage__player--container"
            style={{ height: isHasSubcription && width >= 1024 && '100%' }}
          >
            <div className="v-detailPage__player--container-main">
              {renderYoutube ? (
                <YoutubePlayer entityDetail={entityDetail} component={component} id={id} />
              ) : (
                <FullMode isBlockAds={isBlockAds} component={component} state={props.state} />
              )}

              {![TENANT_NAME_ENUM.SCTV, TENANT_NAME_ENUM.PROJECTW].includes(
                getConfigByKey('ui_theme'),
              ) &&
                entityDetail && <Related entityDetail={entityDetail} infoShow={infoShow} />}
            </div>
            {!isHasSubcription && isMobile ? (
              <AdsBanner position="top" customeClass={adsVertical && 'hide'} />
            ) : null}
            {![TENANT_NAME_ENUM.PROJECTW].includes(getConfigByKey('ui_theme')) && entityDetail ? (
              entityDetail.group ? (
                infoShow ? (
                  <InfoContents
                    component={component}
                    entityDetail={entityDetail}
                    t={t}
                    infoShow={infoShow}
                  />
                ) : (
                  <div className="v-detailPage__info v-detailPage__loading">
                    <Loader type="Oval" color="#FFFFFF" height="30" width="30" />
                  </div>
                )
              ) : (
                !isLive && <InfoContents component={component} entityDetail={entityDetail} t={t} />
              )
            ) : null}
            {getConfigByKey('tenant_slug') === 'sctv' && entityDetail && (
              <Related entityDetail={entityDetail} infoShow={infoShow} />
            )}
            {!isHasSubcription && isMobile ? (
              <AdsBanner position="bottom" customeClass={adsVertical && 'hide'} />
            ) : null}
          </div>
        </div>
      </div>
      {!isHasSubcription ? (
        <AdsBanner position="right" customeClass={!adsVertical && 'hide'} />
      ) : null}
    </DivStyle>
  );
});

export default ClipMode;
