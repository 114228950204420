export const VERSION = process.env.REACT_APP_VERSION || '';
export const AVOD_REGISTRATION_FEATURE_ENABLED =
  process.env.REACT_APP_AVOD_REGISTRATION_FEATURE_ENABLED === 'true';
export const DEBUG = process.env.REACT_APP_DEBUG || false;
export const VERSION_PRELEASE = process.env.REACT_APP_VERSION_PRERELEASE || '';
export const REACT_APP_SSR = process.env.REACT_APP_SSR === 'true';
export const REACT_APP_BOOTPAY_APPLICATION_ID = process.env.REACT_APP_BOOTPAY_APPLICATION_ID || '';
export const REACT_APP_ROCKETCHAT_HOST = process.env.REACT_APP_ROCKETCHAT_HOST || '';
export const REACT_APP_ROCKETCHAT_URL = `https://${REACT_APP_ROCKETCHAT_HOST}`;
export const REACT_APP_ROCKETCHAT_WEBSOCKET = `wss://${REACT_APP_ROCKETCHAT_HOST}/websocket`;
export const REACT_APP_BASE_DOMAIN = process.env.REACT_APP_BASE_DOMAIN;
export const REACT_APP_NAVER_APPLICATION_ID = process.env.REACT_APP_NAVER_APPLICATION_ID;
export const REACT_APP_KAKAO_APPLICATION_ID = process.env.REACT_APP_KAKAO_APPLICATION_ID;
