/* tslint:disable */
/* eslint-disable */
/**
 * ODX/V3 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OdxV3UserInfoLanguageEnum = exports.OdxV3UserInfoGenderEnum = void 0;
exports.OdxV3UserInfoGenderEnum = {
    m: 'm',
    f: 'f',
    na: 'na',
    other: 'other',
    unknown_default_open_api: '11184809'
};
exports.OdxV3UserInfoLanguageEnum = {
    en: 'en',
    ko: 'ko',
    zh_hans: 'zh-hans',
    zh_hant: 'zh-hant',
    es: 'es',
    pt: 'pt',
    unknown_default_open_api: '11184809'
};
