import ControlBarEscondido from './controlbarEscondido';
import ControlBar from './controlbar';
import { tenantEnvConfig } from '~constants/tenantConfig/tenantEnvConfig';
import * as type from './../constants';

const addChilren = (player, component, data = {}) => {
  let Component = null;
  switch (tenantEnvConfig.ui_theme) {
    case 'escondido':
      Component = ControlBarEscondido(player, component, data);
      break;
    default:
      Component = ControlBar(player, component, data);
  }
  return Component;
};

const initEpsTrailer = (component, data = {}) => {
  const { landingPage } = component.props;
  const { infoShow } = component.state;
  const { entityDetail } = data;
  let episodes = landingPage.season.episodes || [];
  let newEpisodes = episodes.slice().reverse();
  let mergeTrailer = [];
  let trailers = component.entityDetail.trailers ? component.entityDetail.trailers : [];
  if (infoShow) {
    trailers = infoShow.trailers ? infoShow.trailers : [];
  }
  if (trailers.length > 0) {
    trailers.map(item => {
      item.can_preview = true;
      item.trailer = true;
    });
  }
  if (
    (entityDetail.group && entityDetail.type === type.EPISODE_TYPE) ||
    entityDetail.type === type.SHOW_TYPE
  ) {
    component.backUrl = infoShow && (infoShow.slug || entityDetail.slug);
    mergeTrailer = [...newEpisodes, ...trailers];
  }
  if (entityDetail.type === type.MOVIE_TYPE || entityDetail.type === type.CLIP) {
    if (trailers.length > 0) {
      trailers.map(item => {
        item.can_preview = true;
        item.trailer = true;
      });
    }
    mergeTrailer = [entityDetail, ...trailers];
  }
  return mergeTrailer;
};

export default addChilren;
export { initEpsTrailer };
