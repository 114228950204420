import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Button, Alert, Table } from 'react-bootstrap';
import TextField from '~root/components/Input/views';
import pushAnalytics from '~root/services/analytics/Analytics';
import * as boxService from '~features/box/services';
import { SHOW_TYPE } from '../constants';

const Coupon = props => {
  const { t, state = {}, showCoupon, content } = props;
  let entityDetail = state.infoShow ? state.infoShow : state.entityDetail || content;
  const [couponCode, setCouponCode] = useState('');
  const [couponResponse, setCouponResponse] = useState({
    errors: {},
  });
  const onChangeText = e => {
    setCouponCode(e.target.value);
  };

  const handleCoupon = event => {
    const { postStripSubscriptions, stripSubscription } = props;
    if (!event) {
      pushAnalytics('click', { content_type: 'button', item_name: 'Apply' });
    }
    let data = {
      stripe_plan_id: 'price_1IqYp4JA2uA5nDV017qecyVs',
    };
    if (couponCode) {
      data = {
        ...data,
        promo_code: couponCode,
      };
    }
    postStripSubscriptions({
      data,
      draft: true,
    })
      .then(response => {
        const subTotal = response.total_price || 0;
        const totalRes = response.final_price || 0;
        const discount = Math.abs(subTotal - totalRes).toFixed(2);
        const dfTotal = 0;
        let promoZero = '';
        let isCouponSuccess = true;
        if (Math.ceil(dfTotal) === 0) {
          promoZero = response.messages || '';
        }
        setCouponResponse({
          subTotal,
          total: totalRes,
          discount,
          errors: {},
          isCouponSuccess: isCouponSuccess,
          isLoading: false,
          promoZero,
          dataCoupon: response,
          messageSuccess: response.messages,
        });
      })
      .catch(error => {
        setCouponResponse({
          errors: {
            ...couponResponse.errors,
            couponPaypal: error.message,
            couponCode: error.message,
          },
        });
      });
  };

  return (
    <div className="v-payment--coupon">
      <Alert className="v-payment__alert">
        <h4 className="v-payment__alert-header">{t('subcriptions.txtOrderSummary')}</h4>
        <div className="tables">
          <Table style={{ marginBottom: '0.825rem' }}>
            <tbody>
              <tr>
                <td>
                  {entityDetail.type === SHOW_TYPE
                    ? t('payperview.plan.views.txtBuyDrama')
                    : t('payperview.plan.views.txtBuyMovie')}
                </td>
                <td>
                  {entityDetail.price.currency === 'usd' && '$'}
                  {entityDetail.price.price}{' '}
                  {entityDetail.price.currency !== 'usd' &&
                    entityDetail.price.currency.toUpperCase()}
                </td>
              </tr>
            </tbody>
          </Table>
          <hr />
          <Table>
            <tbody>
              {couponResponse.discount > 0 && (
                <React.Fragment>
                  <tr className="hr">
                    <td>{t('subcriptions.txtSubtotal')}</td>
                    <td>${couponResponse.subTotal}</td>
                  </tr>

                  <tr>
                    <td>{t('subcriptions.txtDiscount')}</td>
                    <td>-${couponResponse.discount}</td>
                  </tr>
                </React.Fragment>
              )}
              <tr>
                <td>{t('subcriptions.txtTotal')}</td>
                <td className="total">
                  {entityDetail.price.currency === 'usd' && '$'}
                  {entityDetail.price.price}{' '}
                  {entityDetail.price.currency !== 'usd' &&
                    entityDetail.price.currency.toUpperCase()}
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="v-payment--pro-msg">{couponResponse.promoZero}</div>
        </div>
        {showCoupon && (
          <div
            className={`form-group group-coupon ${couponResponse.errors.couponCode && 'has-error'}`}
            style={{ display: 'flex', alignItems: 'flex-start' }}
          >
            <TextField
              type="text"
              label={t('subcriptions.placeholderCouponCode')}
              variant="filled"
              style={{ width: '80%' }}
              onChange={e => onChangeText(e)}
              value={couponCode}
              className="v-input--bg-white"
            >
              {couponResponse.errors.couponCode && (
                <span className="help-block">{couponResponse.errors.couponCode}</span>
              )}
              {couponResponse.messageSuccess && (
                <span className="v-payment--success">{couponResponse.messageSuccess}</span>
              )}
            </TextField>
            <Button
              className="v-payment--btn v-payment--inline"
              onClick={() => handleCoupon(false)}
            >
              {t('subcriptions.btnApplyCoupon')}
            </Button>
          </div>
        )}
      </Alert>
      <div className="v-payment--stripeImage">
        <img style={{ width: '25%' }} alt="" src={require('~img/img/stripes.svg').default} />
        <img style={{ width: '40%' }} alt="" src={require('~img/img/secure.svg').default} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  account: state.auth.account,
});

const mapDispatchToProps = {
  postStripSubscriptions: data => boxService.postStripSubscriptions(data),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Coupon)));
