import moment from 'moment';
window.moment = moment;

const localeEn = moment.localeData('en');

moment.updateLocale('ko', {
  durationLabelsStandard: {
    ...(localeEn as any)._durationLabelsStandard,
    S: 'millisecond',
    SS: 'milliseconds',
    s: '초',
    ss: '초',
    m: '분',
    mm: '분',
    h: '시간',
    hh: '시간',
    d: '일',
    dd: '일',
    w: 'week',
    ww: 'weeks',
    M: '달',
    MM: '달',
    y: '년',
    yy: '년',
  },
  durationLabelsShort: {
    ...(localeEn as any)._durationLabelsShort,
    S: 'millisecond',
    SS: 'milliseconds',
    s: '초',
    ss: '초',
    m: '분',
    mm: '분',
    h: '시간',
    hh: '시간',
    d: '일',
    dd: '일',
    w: 'week',
    ww: 'weeks',
    M: '달',
    MM: '달',
    y: '년',
    yy: '년',
  },
  durationTimeTemplates: {
    HMS: 'h:mm:ss',
    HM: 'h:mm',
    MS: 'm:ss',
  },
  durationLabelTypes: [
    { type: 'standard', string: '__' },
    { type: 'short', string: '_' },
  ],
  durationPluralKey: function (token, integerValue, decimalValue) {
    // Singular for a value of `1`, but not for `1.0`.
    if (integerValue === 1 && decimalValue === null) {
      return token;
    }

    return token + token;
  },
});
