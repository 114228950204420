import React from 'react';

const LogoChanel = React.memo(props => {
  const { component } = props;
  const { entityDetail } = component;
  let logoCn = entityDetail.images.channel_logo;
  return <img className="vjs--selector--logoCn" src={logoCn} width="75" height="77" />;
});

export default LogoChanel;
