import { IAxiosResponseData, Pagination } from '~core/models';
import { apiAdapter, config, axiosTakeDataFromResponse } from '~share';
import camelcaseKeys from 'camelcase-keys';

const { configService, contentService, contentListingService } = apiAdapter;

const CONTENT_DETAIL_SELECT_FIELDS = [
  'category',
  'content_categories',
  'genre',
  'id',
  'images',
  'long_description',
  'people',
  'regions',
  'release_date',
  'short_description',
  'provider_slug',
  'restriction',
  'slug',
  'title',
  'total_episodes',
  'released_episode_count',
  'trailers',
  'seasons',
  'type',
  'video_source',
  'price',
  'metadata',
  'payment_type',
  'film_code',
  'production_company_name',
];

const postFirstFiveSecPlay = (
  contentId: string,
): Promise<IAxiosResponseData<typeof contentService.updateContentRealView> | boolean> => {
  const { tenantSlug = '', authorization } = config;
  if (!authorization) return Promise.resolve(true);

  return contentService
    .updateContentRealView(tenantSlug, contentId, authorization)
    .then(axiosTakeDataFromResponse);
};

const getLiveInfo = (
  channelId: string,
): Promise<IAxiosResponseData<typeof contentService.getChannelLiveInfo>> => {
  const { tenantSlug = '', timezone } = config;
  return contentService
    .getChannelLiveInfo(channelId, tenantSlug, timezone)
    .then(axiosTakeDataFromResponse);
};

const getRecommend = (
  contentId: string,
): Promise<IAxiosResponseData<typeof contentListingService.getRecommendedOfContent>> | void => {
  const { tenantSlug = '', authorization, imageResolutionScale } = config;
  if (!authorization) return;

  return contentListingService
    .getRecommendedOfContent(
      contentId,
      tenantSlug,
      authorization,
      undefined,
      undefined,
      imageResolutionScale,
    )
    .then(axiosTakeDataFromResponse);
};

const getScrubberThumbnail: (
  contentId: string,
) => Promise<IAxiosResponseData<typeof contentService.getContentScrubberTemplate>> = contentId => {
  const { tenantSlug = '' } = config;
  return contentService
    .getContentScrubberTemplate(contentId, tenantSlug)
    .then(axiosTakeDataFromResponse);
};

const getRelatedRibbons: (
  contentID: string,
) => Promise<IAxiosResponseData<typeof contentService.getContentRelatedRibbons>> = contentId => {
  const { tenantSlug = '', authorization } = config;
  return contentService
    .getContentRelatedRibbons(contentId, tenantSlug, authorization)
    .then(axiosTakeDataFromResponse);
};

const getGapContents: (
  channelId: string,
  gapDuration: number,
) => Promise<IAxiosResponseData<typeof contentService.searchGapContent>> = (
  channelId,
  gapDuration,
) => {
  const { tenantSlug = '', authorization, imageResolutionScale } = config;
  return contentService
    .searchGapContent(
      channelId,
      tenantSlug,
      gapDuration,
      authorization,
      undefined,
      imageResolutionScale,
    )
    .then(axiosTakeDataFromResponse);
};

const getProgramShow: (
  idContent: string,
) => Promise<IAxiosResponseData<typeof contentService.getParentProgramOfContent>> = idContent => {
  const select = JSON.stringify({
    Content: CONTENT_DETAIL_SELECT_FIELDS,
  });
  const {
    tenantSlug = '',
    authorization,
    imageResolutionScale,
    imageFormat,
    acceptLanguage,
  } = config;
  return contentService
    .getParentProgramOfContent(
      idContent,
      tenantSlug,
      authorization,
      acceptLanguage,
      select,
      imageResolutionScale,
      imageFormat,
    )
    .then(axiosTakeDataFromResponse);
};

const getConfigCalenderEpg: (
  idContent: string,
) => Promise<IAxiosResponseData<typeof configService.getPlatformConfig>> = idContent => {
  const { tenantSlug = '', platformSlug = '' } = config;
  return configService.getPlatformConfig(platformSlug, tenantSlug).then(axiosTakeDataFromResponse);
};

const getMoreLiveThis = (
  idContent: string,
  params: Partial<{
    expect_types: string;
    display_type: string;
    has_top_contents: boolean;
  }> &
    Pagination,
): Promise<IAxiosResponseData<typeof contentListingService.getRelatedContentsOfContent>> => {
  const { expectTypes, page, limit, displayType, hasTopContents } = camelcaseKeys(params);
  const { tenantSlug = '', authorization, imageResolutionScale, acceptLanguage } = config;
  return contentListingService
    .getRelatedContentsOfContent(
      idContent,
      tenantSlug,
      authorization,
      acceptLanguage,
      imageResolutionScale,
      undefined,
      page,
      limit,
      expectTypes,
      displayType,
      hasTopContents,
    )
    .then(axiosTakeDataFromResponse);
};

export {
  postFirstFiveSecPlay,
  getLiveInfo,
  getRecommend,
  getScrubberThumbnail,
  getRelatedRibbons,
  getGapContents,
  getProgramShow,
  getConfigCalenderEpg,
  getMoreLiveThis,
};
