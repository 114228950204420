// Get the Component base class from Video.js
import ReactDOM from 'react-dom';
import React from 'react';
import './linkEps';
import './buttonNext';
import './buttonPrev';
import './autoNextSpiner';
import { EPISODE_TYPE } from './../constants';
import { pushEventFirebase } from './../components/pushEventFirebase';
import videojs from 'video.js';
import jquery from 'jquery';
import { IonIcon } from '@ionic/react';
import { albums } from 'ionicons/icons';

var Button = videojs.getComponent('Button');

// The videojs.extend function is used to assist with inheritance. In
// an ES6 environment, `class ListEps extends Component` would work
// identically.

var ListEps = videojs.extend(Button, {
  // The constructor of a component receives two arguments: the
  // player it will be associated with and an object of options.
  constructor: function (player, options) {
    // It is important to invoke the superclass before anything else,
    // to get all the features of components out of the box!
    Button.apply(this, arguments);
    const { component } = this.options_;
    const controlBar = player.getChild('controlBar');
    controlBar.addChild('Next', { ...options }, 1);
    controlBar.addChild('Prev', { ...options }, 0);
    player.addChild('AutoNextSpiner', { component: component });
    // If a `text` option was passed in, update the text content of
    // the component.
  },

  // The `createEl` function of a component creates its DOM element.
  createEl: function () {
    const Eps = this.options_.episodes;
    const Translate = this.options_.translate;
    const entityDetail = this.options_.entity;
    const component = this.options_.component;
    const player = this.player_;
    const btn = videojs.dom.createEl('button', {
      className: 'vjs-control vjs-button vjs-list-eps vjs-eps-list-button',
    });

    ReactDOM.render(
      <div className="dropup">
        <span className="icon-placeholder">
          {' '}
          <IonIcon type="button" id="listEps" data-toggle="dropdown" icon={albums} />
        </span>
        <span className="ion-title">{Translate('video.eps')}</span>
        <ul className="dropdown-menu" aria-labelledby="listEps">
          <div className="linkEps" />
        </ul>
      </div>,
      btn,
    );
    player.on('userinactive', () => {
      const $el = jquery(this.el());
      const $player = jquery(player.el_);
      if ($player.hasClass('vjs-mobile') && $el.find('.dropup').hasClass('open')) {
        player.userActive(true);
      } else {
        $el.find('.dropup').removeClass('open');
      }
    });
    const LinkEps = videojs.getComponent('LinkEps');
    const episodes = this.options_.episodes;
    let dropdown = btn.getElementsByClassName('linkEps')[0];
    if ((entityDetail.type === EPISODE_TYPE || entityDetail.trailers) && episodes) {
      Eps.map((item, index) => {
        dropdown.appendChild(
          new LinkEps(this.player_, {
            item: item,
            t: Translate,
            entityDetail: entityDetail,
            component: component,
            index,
          }).el(),
        );
      });
    }
    jquery(window).click(function () {
      //Hide the menus if visible
      const $el = jquery(btn);
      $el.removeClass('open').find('.dropup').removeClass('open');
    });
    return btn;
  },
  handleClick: function (event) {
    event.stopPropagation();
    const entityDetail = this.options_.entity;
    pushEventFirebase(entityDetail, 'episode select');
    const $el = jquery(this.el());
    $el.toggleClass('open').find('.dropup').toggleClass('open');
    if (!$el.find('.dropup').hasClass('open')) {
      return;
    }
    const linkEps = $el.find('.linkEps');
    const linkActive = linkEps.find('.active');
    linkActive && linkEps.scrollTop(linkActive[0].offsetTop);
  },
});

// Register the component with Video.js, so it can be used in players.
videojs.registerComponent('ListEps', ListEps);
