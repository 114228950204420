import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from '@material-ui/core';
import { FormControl } from 'react-bootstrap';
import styled from 'styled-components';
import PopupSuccess from './PopupSuccess';
import { chatUserInfor } from '~features/landingpage/services';
import { updateNicknameChat } from './services';
import { connect } from 'react-redux';
import { setNicknameChatLive } from '~components/global/globalServices';
import { useTranslation } from 'react-i18next';

const DialogStyled = styled(Dialog)`
  && {
    .MuiDialog-paperWidthSm {
      border-radius: 0;
      padding: 68px 53px;
    }
    .content {
      padding: unset;
      overflow: unset;
      &-title {
        font-family: 'Neue Haas Grotesk Display Pro';
        font-style: normal;
        font-weight: 600;
        color: #000000;
        font-size: 23px;
        line-height: 28px;
        text-align: center;
        padding: unset;
        margin-bottom: 20px;
      }
      &-errorMessage {
        font-family: 'Neue Haas Grotesk Display Pro';
        font-style: normal;
        font-size: 13px;
        line-height: 14px;
        color: red;
        margin-top: 10px;
      }
      &-dialogAction {
        margin-top: 24px;
        flex-direction: column;
        justify-content: stretch;
        padding: unset;
        &-btnAction {
          /* padding: 16.5px 128px; */
          width: 100%;
          height: 50px;
          font-family: 'Neue Haas Grotesk Display Pro';
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 133.5%;
          text-align: center;
          letter-spacing: 0.025em;
          text-transform: uppercase;
          /* max-width: 275px; */
          border-radius: 0;
          &.Cancel {
            background: #e5e5ea;
            color: #000000;
            margin-top: 14.5px;
            margin-left: 0px;
          }
          &.Confirm {
            background-color: #000000;
            color: #ffffff;
          }
        }
      }
    }
  }
`;

const InputChangeNickname = styled(FormControl)`
  && {
    border: 1px solid #000;
    font-family: 'Neue Haas Grotesk Display Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    transform-origin: top left;
    transform: scale(0.75); //  12px / 16px
    line-height: 14px;
    color: #000000;
    border-radius: 0px;
    height: 43px;
    padding: 14px 13px;
    width: calc(100% * 0.75);
    /* max-width: 275px; */
    margin: 0 auto;
    width: calc(100% / 0.75);
    height: calc(43px / 0.75);
    margin: 0 auto;
    padding: calc(14px / 0.75) calc(13px / 0.75);
    margin-bottom: -20.75px;
    :focus {
      border-color: unset;
      box-shadow: unset;
    }
  }
`;

const DialogChangeNickname = props => {
  const {
    openNicknameDialog,
    handleCloseNicknameDialog,
    newNickname,
    setNewNickname,
    error,
    setError,
    dpUpdateNicknameChat,
    dpSetNicknameChatLive,
  } = props;
  const [openPopup, setOpenPopup] = useState(false);
  const { t } = useTranslation();
  const handleOpenSuccessPopup = () => {
    dpUpdateNicknameChat(newNickname)
      .then(() => {
        dpSetNicknameChatLive(newNickname);
      })
      .then(() => {
        handleCloseNicknameDialog();
        setOpenPopup(true);
      })
      .catch(error => {
        setError(error.message);
        setNewNickname('');
      });
  };

  const handleCloseSuccessPopup = () => {
    setOpenPopup(false);
  };
  const handleChangeNickname = e => {
    setNewNickname(e.target.value);
  };

  return (
    <>
      <DialogStyled
        open={openNicknameDialog}
        onClose={handleCloseNicknameDialog}
        aria-labelledby="form-dialog-title"
        className="dialogStyled"
      >
        <DialogContent className="content">
          <DialogTitle className="content-title" id="form-dialog-title">
            {t('projectw__modal.ChangeNickName.title')}
          </DialogTitle>
          <InputChangeNickname
            className="content-textField"
            variant="outlined"
            value={newNickname}
            onChange={handleChangeNickname}
          />
          {error !== '' && <Typography className="content-errorMessage">{error}</Typography>}
          <DialogActions className="content-dialogAction">
            <Button
              className="content-dialogAction-btnAction Confirm"
              onClick={handleOpenSuccessPopup}
            >
              {t('projectw__modal.ChangeNickName.btnOk')}
            </Button>
            <Button
              className="content-dialogAction-btnAction Cancel"
              onClick={handleCloseNicknameDialog}
            >
              {t('projectw__modal.ChangeNickName.btnCancel')}
            </Button>
          </DialogActions>
        </DialogContent>
      </DialogStyled>
      <PopupSuccess openPopup={openPopup} handleCloseSuccessPopup={handleCloseSuccessPopup} />
    </>
  );
};

const mapDispatchToProps = {
  dpChatUserInfo: () => chatUserInfor(),
  dpUpdateNicknameChat: nickname => updateNicknameChat(nickname),
  dpSetNicknameChatLive: nickname => setNicknameChatLive(nickname),
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DialogChangeNickname);
