import { PayloadAction } from '@reduxjs/toolkit';
import { Subset } from '~core/typing';
import * as types from './actionTypes';

export interface IAlert {
  id: string;
  isShow: boolean;
  options: {
    type: string;
    staticModal: boolean;
    buttonConfirm: boolean;
    buttonCancel: boolean;
    showIcon: boolean;
    buttonFluid: boolean;
  };
  content: {
    title: string;
    titleSuccess: string;
    titleWarning: string;
    description: string;
    buttonConfirmText: string;
    buttonCancelText: string;
  };
}

interface IAlertModal {
  default: IAlert;
  modals: { [key: string]: Subset<IAlert> };
}

const format: IAlert = {
  isShow: false,
  options: {
    type: 'success',
    staticModal: false,
    buttonConfirm: true,
    buttonCancel: false,
    showIcon: true,
    buttonFluid: false,
  },
  content: {
    title: '',
    titleSuccess: 'Thành công',
    titleWarning: 'Cảnh báo',
    description: '',
    buttonConfirmText: 'OK',
    buttonCancelText: 'Cancel',
  },
  id: '',
};

const initialState: IAlertModal = {
  default: format,
  modals: {},
};

export default function alertModal(state = initialState, action: PayloadAction<Subset<IAlert>>) {
  switch (action.type) {
    case types.UPDATE_IS_SHOW:
      return {
        ...state,
        isShow: action.payload.isShow,
      };
    case types.UPDATE_ALERT_MODAL:
      let newState = { ...state };
      if (action.payload.id && newState.modals[action.payload.id]) {
        const data = newState.modals[action.payload.id];
        newState.modals[action.payload.id] = {
          ...data,
          options: {
            ...data.options,
            ...action.payload.options,
          },
          content: {
            ...data.content,
            ...action.payload.content,
          },
          isShow:
            typeof action.payload.isShow === 'undefined' ? data.isShow : action.payload.isShow,
          id: action.payload.id ? action.payload.id : data.id,
        };
      }

      return newState;
    case types.ADD_MODAL:
      let newStateAddModal = { ...state };
      if (action.payload.id) {
        newStateAddModal.modals[action.payload.id] = {
          ...format,
          ...action.payload,
        };
      }
      return newStateAddModal;
    default:
      return state;
  }
}
