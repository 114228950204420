import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { HomePageProjectW } from '~features/homepage/projectw';
import { resetPageData, setTopTenContentsByPageSlug } from '~features/homepage/services';
import $ from 'jquery';

function HomeProjectw(props) {
  const { dpResetPageData } = props;

  useEffect(() => {
    const app = $('#app,body');
    app.addClass('white-background');
    return () => {
      app.removeClass('white-background');
      // dpResetPageData()
    };
  }, []);
  return <HomePageProjectW {...props} />;
}

const mapStateToProps = state => ({
  home: state.home,
});

const mapDispatchToProps = {
  dpSetTopTenContentsByPageSlug: pageSlug => setTopTenContentsByPageSlug(pageSlug),
  dpResetPageData: () => resetPageData(),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeProjectw));
