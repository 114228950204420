import React from 'react';
import styled from 'styled-components';
import { LineClampStyled } from '../../../features/landingpage/styles/line-clamp';
import classnames from 'classnames';

export default class ActorThumbnail extends React.Component {
  render() {
    // eslint-disable-next-line camelcase
    const {
      ribbon: { display_type, description, url, thumbnail },
      title,
      contentClass,
    } = this.props;
    // eslint-disable-next-line camelcase
    if (display_type !== 6) return null;
    return (
      <StyledDiv>
        <div className="image-wrapper">
          <img
            loading="lazy"
            src="http://d2ryy79pkcqyaa.cloudfront.net/odv-stag/tenants/cd99c164-11b0-4ce2-ada4-4b8c6dd2d4ad/auto_image_youtube_040cbc10_9a339c0d.jpg?width=1920&version=6&s3_origin=https%3A%2F%2Fodv-stag.s3-ap-southeast-1.amazonaws.com"
          />
        </div>
        <div className={classnames('content-wrapper', contentClass)}>
          <h3>{title}</h3>
          <LineClampStyled className="line-clamp-12 desc">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.
            Aliquam erat volutpat. Donec placerat nisl magna, et faucibus arcu condimentum sed.Lorem
            ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem. Aliquam
            erat volutpat. Donec placerat nisl magna, et faucibus arcu condimentum
            <br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.
            Aliquam erat volutpat. Donec placerat nisl magna, et faucibus arcu condimentum sed.
          </LineClampStyled>
        </div>
      </StyledDiv>
    );
  }
}

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  & .image-wrapper,
  & .image-wrapper img {
    border-radius: 10px;
  }
  & .image-wrapper {
    width: 37%;
    padding-top: calc(5 / 9 * 100%);
    box-shadow: 0 4px 2px rgba(0, 0, 0, 0.2);
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  & .content-wrapper {
    height: 100%;
    width: 63%;
    & h3 {
      font-size: 30px;
      font-weight: bold;
      color: #404040;
      text-decoration: none solid rgb(64, 64, 64);
      text-transform: uppercase;
    }
    & .desc {
      font-size: 14px;
    }
  }
`;
