import {
  ApiAdapterFactory,
  AdapterConfig,
  ApiAdapter,
  AdapterType,
  Configuration,
} from '~vimai-api-adapter';
import { axiosInstance } from './common';
import { config } from './common';

const debugEnabled = process.env.REACT_APP_DEBUG === 'true';

const parameterProvider = {
  injectList: [
    'tenantSlug',
    'acceptLanguage',
    'accountId',
    'userId',
    // 'authorization',
    'tenantPlatformSlug',
    'imageResolutionScale',
    'environment',
    'platformSlug',
    'tenantId',
  ],
  get: (name: string) => {
    switch (name) {
      case 'tenantId':
      case 'tenantSlug': {
        return config.tenantSlug;
      }
      case 'userId':
      case 'accountId': {
        return config.userId;
      }
      // case 'authorization': {
      //   return config.authorization;
      // }
      case 'acceptLanguage': {
        return config.acceptLanguage;
      }
      case 'platformSlug':
      case 'tenantPlatformSlug':
        return config.platformSlug;
      case 'imageResolutionScale':
        return config.imageResolutionScale;
      case 'environment':
        return config.environment;

      default:
        throw Error(`Unable to find ${name} in parameterProvider`);
    }
  },
};

const adapterConfig: AdapterConfig = {
  debug: debugEnabled,
  baseUrl: config.baseUrl,
  parameterProvider,
  axiosInstance,
  configuration: new Configuration(),
};

const apiAdapter: ApiAdapter = ApiAdapterFactory.create(AdapterType.VIMAI, adapterConfig);

export { apiAdapter, config };
