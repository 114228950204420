import { getConfigFromEnv } from './tenantEnvConfig';
import { REACT_APP_URL_PRIVACY_POLICY } from '~features/footer/constants';
import _ from 'lodash';

const stringsByTenantSlug = {
  default: {},
  odv: {
    name: 'OnDemandViet',
    short_name: 'ODV',
    phone_number_1: '+1 (855) 910 - 0922',
    phone_number_2: '+1 (714) 278 - 3915',
    email: 'help@ondemandviet.com',
    sologan: 'OnDemand',
    address: {
      copyright: `© 2020-${new Date().getFullYear()} ODK Media Inc.`,
      copyrightV2: `© 2020-${new Date().getFullYear()} ODK Media, Inc. OnDemandKorea & Design Reg. U.S. Pat. & Tm. Off.`,
      address: '221 N. Harbor Blvd., Suite E',
      state: 'Fullerton , California 92832, USA',
    },
    privacyPolicyLink: REACT_APP_URL_PRIVACY_POLICY,
  },
  sctv: {
    name: 'SCTV Online',
    sologan: 'SCTV',
    short_name: 'SCTV',
    phone_number_1: '19001878',
    email: 'sctvonline@sctv.vn',
    address: {
      copyright: 'Số ĐKKD: 0301463315 do Sở KHĐT TP.HCM cấp ngày 09/04/2010',
      address: '31-33 Đinh Công Tráng, P. Tân Định, Q.1, TP.HCM',
      state: '',
    },
    privacyPolicyLink: 'https://tv24.vn/chinh-sach-bao-mat',
    termsOfUseLink: 'https://tv24.vn/thoa-thuan-su-dung',
  },
  projectw: {
    name: 'Project W',
    sologan: 'What You See on Stage',
    phone_number_1: '02-749-0507',
    phone_number_contact: 'T. 070-8667-3844',
    do_you_need_help: 'Do you need help?',
    do_you_need_help_ko: '도움이 필요하신가요?',
    email: 'CS@illuon.net',
    address: {
      copyright: '© 2022 WizOnsen Co., Ltd.',
      copyrightV2: 'Mail-order business report number 2022-Seoul Gangnam-06782',
      address:
        'Representative Park Gwan-woo 5th floor, Sinsacheongho Building, 644-5, Sinsa-dong, Gangnam-gu, Seoul',
      state: '',
      copyright_ko: '© 2022 주식회사 위즈온센',
      copyrightV2_ko: '통신판매업 신고번호 제 2022-서울강남-06782호',
      address_ko: '대표 박관우 서울특별시 강남구 신사동 644-5, 신사청호빌딩 5층',
      address_layer_1: '© 2022 WYS EN SCÈNE Co., Ltd.',
      address_layer_2: 'CEO Kwanwoo Park, Dongwon Lee',
      address_layer_3: 'Sinsa Cheongho Bldg. 5th Floor',
      address_layer_4: 'Address : 16, Eonju-ro 170-gil, Seoul, South Korea',
      address_layer_5: 'Business ID: 736-87-01910',
      address_layer_6: 'E-Commerce ID: 2022-Seoul Gangnam-06782',
      address_layer_1_ko: '© 2022 주식회사 위즈온센',
      address_layer_2_ko: '대표이사 박관우, 이동원',
      address_layer_3_ko: '서울특별시 강남구 신사동 644-5, 신사청호빌딩 5층',
      address_layer_4_ko: '주소: 서울특별시 강남구 언주로 170길 16',
      address_layer_5_ko: '사업자등록증번호: 736-87-01910',
      address_layer_6_ko: '통신판매업 신고번호: 제2022-서울강남-06782호',
    },
    companyReg: 'Business Registration Number: 736-87-01910',
    companyReg_ko: '사업자등록증번호 736-87-01910',
    vat: 'VAT: 319656554',
    sign: '© 2022 WYS EN SCÈNE Co., Ltd.',
    socialLink: {
      facebook: 'https://www.facebook.com/%EC%9C%84%EC%A6%88%EC%98%A8%EC%84%BC-102489242239520',
      instagram: 'https://www.instagram.com/accounts/login/?next=/wys.en.scene/',
      twitter: 'https://twitter.com/wysenscene',
    },
    faqLink: 'https://wysenscene.notion.site/FAQ-71d873b5922849ccbc7d3b401a7fb875',
    titleFooter: 'WYS EN SCENE',
    CEO: 'CEO: Kwanwoo Park, Dongwon Lee',
    titleFooter_ko: '주식회사 위즈온센',
    CEO_ko: '대표: 박관우, 이동원',
  },
  escondido: {
    name: 'MuchoMasTV',
    short_name: 'MuchoMasTV',
    phone_number_1: '',
    phone_number_2: '',
    email: 'ayuda@muchomastv.com',
    sologan: 'MuchoMasTV',
    address: {
      copyright: new Date().getFullYear() + ' MuchoMasTV. All rights reserved',
      copyrightV2: '',
      address: 'MMTV Media, Inc. 221 N. Harbor Blvd.Suite E., Fullerton, CA 92832',
      state: 'California 92832, USA',
    },
  },
};

export let tenantStrings = {};

export const getTenantStrings = () => {
  if (!_.isEmpty(tenantStrings)) {
    return tenantStrings;
  }

  const tenantEnvConfig = getConfigFromEnv();
  const config =
    tenantEnvConfig.ui_theme in stringsByTenantSlug
      ? stringsByTenantSlug[tenantEnvConfig.ui_theme as keyof typeof stringsByTenantSlug]
      : stringsByTenantSlug['default'];
  tenantStrings = config;
  return tenantStrings;
};
