import videojs from 'video.js';
import ReactDOM from 'react-dom';
import AutoNext from '../components/autoNext';
import React from 'react';
import { isIOS, isSafari, isMacOs } from 'react-device-detect';
import jquery from 'jquery';

const vjsComponent = videojs.getComponent('Component');

class AutoNextSpiner extends vjsComponent {
  constructor(player, options) {
    super(player, options);
    this.mount = this.mount.bind(this);
    this.timer = 10;
    if (isIOS || isSafari || isMacOs) {
      this.timer = 5;
    }
    this.mount();
    const $player = jquery(player.el_);
    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
    player.on('ready', () => {
      jquery(
        $player.find('.circle').css({
          'animation-duration': this.timer + 's',
        }),
      );
    });
  }

  createEl() {
    const div = videojs.dom.createEl('div', {
      className: 'vjs-auto-next vjs-hidden',
    });
    return div;
  }

  mount() {
    const { component } = this.options_;
    ReactDOM.render(
      <AutoNext player={this.player_} component={component} timer={this.timer} />,
      this.el(),
    );
  }
}

// Register the component with Video.js, so it can be used in players.
videojs.registerComponent('AutoNextSpiner', AutoNextSpiner);
export default AutoNextSpiner;
