import React, { useEffect } from 'react';
import { Modal, Col, Row, Button } from 'react-bootstrap';
import { ModalStyled } from '~features/auth/components/login/styled';
import { connect, useSelector, useDispatch } from 'react-redux';
import { setIsLoadingConfirmPPV, setIsSuccess } from '~components/global/globalSlice';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

function ModalPaymentSuccess(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const isOpenModalSuccess = useSelector(state => {
    return state.global.modalPaymentSuccess.isSuccess;
  });

  const isCloseModalSuccess = () => {
    props.handleClose();
    dispatch(setIsSuccess(false));
    history.replace(location.pathname);
  };

  useEffect(() => {
    if (isOpenModalSuccess) {
      dispatch(setIsLoadingConfirmPPV(false));
    }
    return () => {
      dispatch(setIsSuccess(false));
    };
  }, []);

  return (
    <ModalStyled
      show={isOpenModalSuccess}
      onHide={isCloseModalSuccess}
      aria-labelledby="contained-modal-title-vcenter"
      className="v-loginForm project-w"
    >
      <Modal.Body className="project-w--Body">
        <Row className="v-loginForm--row project-w--row">
          <Col sm={12} className="v-loginForm--header project-w--header">
            <p
              className="v-loginForm--title project-w--title"
              style={{
                fontSize: '25px',
                fontWeight: '600',
              }}
            >
              {t('projectw__PPV.paymentxt.payment_success')}
            </p>
          </Col>
          <Col sm={12} className="v-loginForm--header project-w--header">
            <p
              style={{
                textTransform: 'uppercase',
                fontFamily: 'Neue Machina',
                fontWeight: 700,
                marginBottom: 44,
                marginTop: 44,
                fontSize: '20px',
              }}
              className="v-loginForm--title project-w--title"
            >
              {/* Thank you for trusting and using our services. */}
            </p>
          </Col>
          <Col sm={12}>
            <Button className="project-w--watchmore" bsSize="large" onClick={isCloseModalSuccess}>
              {t('projectw__PPV.paymentxt.payment_btn_big3')}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </ModalStyled>
  );
}

export default ModalPaymentSuccess;
