import { actions } from './authSlice';

const {
  loginSuccess,
  resetPassSuccess,
  resetPassFailure,
  requestCodeSuccess,
  requestCodeFailure,
  logout,
  updateAccountPaymentMethod,
  updateIP,
  setIsShowLogin,
  updateHasSupscription,
  updateAccountPaymentMethodPaypal,
  setPaidContents,
  resetPaymentMethod,
  rememberAccount,
  loginGuestAccount,
  removeGuestAccount,
  loginGuestSuccess,
  updateEmail,
  updateProfile,
} = actions;

export {
  loginSuccess,
  resetPassSuccess,
  resetPassFailure,
  requestCodeSuccess,
  requestCodeFailure,
  logout,
  updateAccountPaymentMethod,
  updateIP,
  setIsShowLogin,
  updateHasSupscription,
  updateAccountPaymentMethodPaypal,
  setPaidContents,
  resetPaymentMethod,
  rememberAccount,
  loginGuestAccount,
  removeGuestAccount,
  loginGuestSuccess,
  updateEmail,
  updateProfile,
};
