import styled from 'styled-components';

export const StyledChannelList = styled.div`
  height: calc(100vh - 442px);
  overflow-y: scroll;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  border-radius: 10px 0 0 0;
  color: white;
  background-color: rgba(255, 250, 232, 0.05);
  padding: 20px 0 0 40px;
  &.rb-channel-list {
    .rb--item {
      cursor: pointer;
      margin-bottom: 5px;
      .rb--name {
        font-size: 24px;
        &.active {
          color: ${({ theme }) => theme.primaryColor};
        }
      }
      &.active {
        & .rb--name {
          color: ${({ theme }) => theme.primaryColor};
          font-weight: 600;
        }
      }
      .channel {
        display: flex;
        padding-bottom: 10px;
        height: 100px;
        transition: height 0.3s ease;
        box-sizing: content-box;
        &--picture {
          width: 100px;
          height: 100%;
          padding: 6px;
          background-color: rgba(20, 20, 20, 0.85);
          border-radius: 5px;
          border-bottom-width: 1px;
          border-right-width: 1px;
          border-style: solid;
          border-color: #212121;
          margin: 0 10px 10px 0;
          display: flex;
          align-items: center;
          & img {
            width: 100%;
          }
        }
        &--row {
          width: 100%;
          display: flex;
          padding: 0 5px;
          &--container {
            background-color: rgba(20, 20, 20, 0.85);
            background-size: cover;
            &.empty {
              background-color: unset;
              border: unset;
            }
          }
          &:last-child {
            padding: 0;
          }
        }
        &:hover {
          height: 160px;
          .channel {
            &__programme {
              &--name {
                font-size: 29px;
              }
            }
          }
        }
      }
    }
  }
`;

export const StyledChannel = styled.div`
  &:hover {
    .channel--picture {
      background-color: ${({ bgColor, theme }) => bgColor || theme.thirdColor} !important;
    }
  }
  .channel--picture .active {
    background-color: ${({ bgColor, theme }) => bgColor || theme.thirdColor} !important;
  }
`;

export const StyledChannelNavigator = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-size: 14px;
  padding: 15px 0px 15px 140px;
  position: relative;
  & .arrows {
    position: absolute;
    display: flex;
    align-items: center;
    &--left {
      left: 0;
    }
    &--btn-right {
      position: absolute;
      right: 0;
      transform: translate(70px, -8px);
      top: 0;
    }
    &--visible {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--right {
      right: 25px;
    }
    img {
      fill: white;
    }
  }
  & .timeSchedule {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    &-items {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  & .MuiIconButton-root:hover {
    background-color: ${({ theme }) => theme.primaryColor};
  }
  .back-to-live {
    display: none;
    align-items: center !important;
    background-color: ${({ theme }) => theme.primaryColor};
    font-size: 16px;
    color: #ffffff;
    border-radius: 20px;
    padding: 2px 7px !important;
    height: 30px;
    &.visible {
      display: block;
    }
    .MuiIconButton-label {
      line-height: 0;
    }
  }
  @media (min-width: 1440px) {
    & .arrows {
      &--visible {
        transform: translate(115px, -8px);
      }
    }
  }
`;

export const StyledCurrentChannelInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 20px;
  .programme--container {
    width: calc(100% - 431px);
    max-width: 630px;
    position: relative;
  }
  & .programme-logo {
    position: absolute;
    top: -105px;
    & img {
      height: 100px;
    }
  }
  & .time-range {
    color: ${({ theme }) => theme.primaryColor};
    margin-bottom: 5px;
  }
  & .description {
    font-size: 16px;
    text-decoration: none solid rgb(199, 199, 199);
    color: #c7c7c7;
    line-height: 22px;
    height: 66px;
  }
`;

export const StyledChannelProgramme = styled.div`
  position: relative;
  box-sizing: content-box;
  &.channel--row--container {
    border-radius: 5px;
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: #212121;
    transition: transform 0.3s ease-out, width 0.3s ease-out;
    margin-right: 10px;
    &.last {
      margin-right: 0;
    }
    &.right .programme--detail {
      float: right;
      right: 0;
    }
    &.none {
      border-width: 0;
      margin-right: 0;
      .channel__programme {
        display: none;
      }
    }
    &:hover .programme--detail {
      display: flex;
      border: 3px solid ${({ theme }) => theme.primaryColor};
      .programme-hide {
        display: flex;
      }
    }
  }

  .channel__programme {
    position: absolute;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    overflow: hidden;
    &--name {
      font-size: 23px;
      margin-bottom: 10px;
    }
    &--info {
      display: flex;
      align-items: center;
      &--tag {
        text-transform: uppercase;
        width: fit-content;
        font-size: 14px;
        text-decoration: none solid rgb(255, 255, 255);
        background-color: rgba(218, 218, 218, 0.2);
        padding: 5px;
        margin-right: 10px;
      }
      &--time {
        font-size: 12px;
        margin-right: 10px;
        img {
          height: 16px;
          margin-left: 7px;
        }
      }
      img {
        height: 16px;
      }
    }
    &.programme--detail {
      width: 100%;
      min-width: 580px;
      border-radius: 5px;
      overflow: hidden;
      display: none;
      flex-direction: row;
      padding: 0;
      z-index: 2;
      background-color: #141414;
      .programme--thumbnail {
        max-width: 50%;
        img {
          width: 265px;
          height: 100%;
        }
      }
      .channel__programme {
        &--column {
          width: 100%;
          height: 100%;
          justify-content: space-between;
          display: flex;
          flex-direction: column;
          padding: 5px 0 10px 30px;
        }
        &--name {
          margin-bottom: 0;
        }
        &--info {
          &--description {
            padding-right: 20px;
            font-size: 14px;
            color: ${({ theme }) => theme.landingPage.description};
          }
        }
      }
    }
  }
  .programme-hide {
    display: none;
  }
`;

export const StyledLinearIndicator = styled.div`
  /* padding-left: 140px;
  position: absolute;
  top: 130px;
  height: calc(100vh - 380px);
  width: 100%; */
  &.indicator {
    &--container {
      height: 1px;
      width: calc(100% - 140px);
      position: absolute;
      top: 0;
    }
  }
  .indicator {
    &--container {
      /* position: relative; */
      &__item {
        /* height: 100%; */
        height: calc(100vh - 400px);
        top: 0;
        position: absolute;
        width: 1px;
        background-color: ${({ theme }) => theme.livePage.colorIndicator};
        z-index: 1;
        transition: 0.5s ease;
      }
    }
  }
`;

export const StyledButton = styled.div`
  display: flex;
  align-items: center;
  margin-right: 45px;
  .btn {
    &--volume {
      background: ${({ theme }) => theme.primaryColor};
      border-radius: 50%;
      margin-right: 20px;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: #fff;
      svg {
        font-size: 30px;
      }
      &:hover {
        border: 3px solid;
      }
    }
    &--watch {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      color: #ffffff;
      text-decoration: none solid rgb(255, 255, 255);
      text-transform: uppercase;
      padding: 5px 20px;
      border: 1px solid #ebebeb;
      font-weight: bold;
      border-radius: 8px;
      cursor: pointer;
      svg {
        font-size: 51px;
        margin-right: 13px;
      }
      &:hover {
        color: ${({ theme }) => theme.primaryColor};
        border-color: ${({ theme }) => theme.primaryColor};
      }
    }
  }
  @media (min-width: 1680px) {
    margin-right: 120px;
  }
`;
