export default {
  title: 'login-form-title',
  continueWithFacebookBtn: 'login-form-continue-with-facebook-btn',
  loginBtn: 'login-form-login-btn',
  registerBtn: 'login-form-register-btn',
  forgotPassword: 'login-form-forgot-password-btn',
  emailTxt: 'login-form-email-txt',
  passwordTxt: 'login-form-password-txt',
  errorMessageLbl: 'login-form-error-message-lbl',
  doNotHaveAnAccountLbl: 'login-form-do-not-have-an-account-lbl',
};
