import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Col, Row, FormGroup, HelpBlock, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import TextField from '~root/components/Input/views';
import { HelpCvc } from '~features/box/pages/popup/index';
import pushAnalytics from '~root/services/analytics/Analytics';
import * as referrals from '~root/services/referrals';
import * as payperviewService from '../services';
import CheckboxPolicy from './checkbox';
import { setDataPaySubcription } from '../functions';
import {
  StripeProvider,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
} from 'react-stripe-elements';
import { getStepPayperview } from '../functions';
import { verifyEmailFormat } from '~utils/utils';
import classnames from 'classnames';

const StripeForm = props => {
  const { t, setStatePolicy, account } = props;
  const [cardNumber, setCardNumber] = useState({});
  const [cardExpiry, setCardExpiry] = useState({});
  const [cardCvc, setCardCvc] = useState({});
  const [checked, setChecked] = useState(false);
  const [cardName, setNameCard] = useState('');
  const [emailInput, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [helpCvcShowPopup, setOpen] = useState(false);

  useEffect(() => {
    setEmail(account.profile.email || '');
  }, []);
  const createOptions = () => {
    return {
      style: {
        base: {
          fontSize: '16px',
          color: '#283237',
          width: '100%',
          fontFamily: 'Open Sans, sans-serif',
          letterSpacing: '0.025em',
          '::placeholder': {
            color: '#283237',
          },
        },
        invalid: {
          color: '#c23d4b',
        },
      },
    };
  };

  const getValidationState = objectName => {
    if (objectName && !objectName.error) return null;
    return 'error';
  };

  const onChange = (objectData, method) => {
    if (objectData.target) {
      setErrorEmail('');
      return method(objectData.target.value);
    }
    method(objectData);
    setErrorMessage('');
  };

  const getPaymentMethod = () => {
    const { stripe, account, elements, t } = props;
    const cardElement = elements.getElement('cardNumber');
    return new Promise((resolve, reject) => {
      if (!verifyEmailFormat(emailInput)) {
        reject();
        return setErrorEmail(
          !emailInput.length ? t('box.alertNullEmail') : t('alertWrongEmailFormat'),
        );
      }
      stripe
        .createPaymentMethod({
          type: 'card',
          card: {
            ...cardElement,
            name: cardName,
          },
          billing_details: {
            name: cardName || `${account.profile.first_name} ${account.profile.first_name}`,
            email: account.profile.email || emailInput,
          },
        })
        .then(({ paymentMethod }) => {
          resolve(paymentMethod);
        })
        .catch(err => {
          if (!cardNumber.complete || !cardExpiry.complete || !cardCvc.complete) {
            return;
          }
          setErrorMessage(
            err.message === `Cannot read property 'id' of undefined` ||
              `undefined is not an object (evaluating 'paymentMethod.id')`
              ? 'The card has been declined for an unknown reason'
              : err.message,
          );
          reject(err);
        });
    });
  };

  const postMethodPayment = paymentMethod => {
    const { postSubscriptionsPayperview, state, account, setLoading } = props;
    let contentId = state.infoShow ? state.infoShow.id : state.entityDetail.id;
    let nameCard = cardName || `${account.profile.first_name} ${account.profile.first_name}`;
    let data = {
      provider: 'stripe',
      contentId: contentId,
      nameStripe: nameCard.trim(),
      paymentMethod: paymentMethod,
      email: account.profile.email || emailInput,
    };
    data = setDataPaySubcription(data);
    return new Promise((resolve, reject) => {
      postSubscriptionsPayperview(data)
        .then(response => {
          referrals.saleEvent({
            purchaseValue: 0,
            email: account.profile.email,
            orderID: paymentMethod.id,
          });
          props.stepSuccessPopup();
          resolve(response);
        })
        .catch(err => {
          reject(err);
          setErrorMessage(err.message ? err.message : t('subcriptions.alertErrorNet'));
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const handleSubmit = async event => {
    const { stripe, setLoading } = props;
    event.preventDefault();
    pushAnalytics('click', {
      content_type: 'button',
      item_name: 'Complete Purchase',
    });
    if (true) {
      try {
        const stripeMethodResponse = await getPaymentMethod();
        if (!stripeMethodResponse) return;
        setLoading(true);
        await postMethodPayment(stripeMethodResponse);
      } catch (err) {
        setLoading(false);
        console.log('err....', err);
      }
    }
  };

  return (
    <form onSubmit={e => handleSubmit(e)}>
      <Row>
        <Col sm={12} className={classnames(account.profile.email && 'hide')}>
          <div className="form-group">
            <TextField
              type="text"
              label="Email"
              name="Email"
              variant="filled"
              onChange={e => onChange(e, setEmail)}
              value={emailInput}
              required
            />
            {(!verifyEmailFormat(emailInput) && emailInput.length) || errorEmail ? (
              <HelpBlock>{errorEmail || t('alertWrongEmailFormat')}</HelpBlock>
            ) : null}
          </div>
        </Col>
        <Col sm={12}>
          <TextField
            type="text"
            label={t('subcriptions.lblCardHolderName')}
            name="customerName"
            variant="filled"
            value={cardName}
            onChange={e => onChange(e, setNameCard)}
          />
        </Col>
        <Col sm={12}>
          <FormGroup validationState={getValidationState('cardNumber')}>
            <div className="v-group">
              <CardNumberElement
                className="v-input"
                {...createOptions()}
                onChange={e => onChange(e, setCardNumber)}
                // onKeyDown={e => _onKeyDownEnter(e)}
                placeholder={``}
              />
              <span className="v-highlight" />
              <span className={`v-bar`} />
              <label className={`v-label filled`}>{t('subcriptions.Card number')}</label>
            </div>

            {cardNumber.error && cardNumber.error.code && (
              <HelpBlock>{t(`localizedMessages.${cardNumber.error.code}`)}</HelpBlock>
            )}
          </FormGroup>
        </Col>
        <Col sm={12}>
          <Row>
            <Col sm={6} md={7} lg={6} className="">
              <FormGroup validationState={getValidationState('cardExpiry')}>
                <div className="v-group">
                  <CardExpiryElement
                    className="v-input"
                    {...createOptions()}
                    onChange={e => onChange(e, setCardExpiry)}
                    // onKeyDown={e => _onKeyDownEnter(e)}
                    placeholder=""
                  />
                  <span className="v-highlight" />
                  <span className={`v-bar`} />
                  <label className={`v-label filled`}>{'Exp Date (MM/YY)'}</label>
                </div>

                {cardExpiry.error && cardExpiry.error.code && (
                  <HelpBlock>{t(`localizedMessages.${cardExpiry.error.code}`)}</HelpBlock>
                )}
              </FormGroup>
            </Col>
            <Col sm={6} md={5} lg={6} className="">
              <FormGroup validationState={getValidationState('cardCvc')}>
                <div>
                  <div className="v-group">
                    <CardCVCElement
                      className="v-input"
                      {...createOptions()}
                      onChange={e => onChange(e, setCardCvc)}
                      // onKeyDown={e => _onKeyDownEnter(e)}
                      placeholder=""
                    />
                    <span className="v-highlight" />
                    <span className={`v-bar`} />
                    <label className={`v-label filled`}>{`CVC`}</label>
                  </div>

                  <div
                    className="v-stripe--helpcvc"
                    onClick={() => {
                      pushAnalytics('click', {
                        content_type: 'button',
                        item_name: 'CVC',
                      });
                      setOpen(true);
                    }}
                  >
                    <i
                      className="fa fa-question-circle"
                      style={{ fontSize: '1.875rem' }}
                      aria-hidden="true"
                    />
                  </div>
                </div>
                {cardCvc.error && cardCvc.error.code && (
                  <HelpBlock>{t(`localizedMessages.${cardCvc.error.code}`)}</HelpBlock>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Col sm={12} style={{ padding: '0' }}>
            <CheckboxPolicy {...props} setStatePolicy={{ setChecked, checked }} />
          </Col>

          <Button
            onClick={e => handleSubmit(e)}
            type="submit"
            className={`v-payment--submit v-payment--btn v-payment--btn_primary`}
            disabled={!checked}
          >
            {t('subcriptions.txtCompletePurchase')}
          </Button>
        </Col>
        <Col
          sm={12}
          className="error"
          style={{
            marginTop: '1rem',
          }}
          role="alert"
        >
          {errorMessage}
        </Col>
      </Row>
      <HelpCvc t={t} modalShow={helpCvcShowPopup} showModal={() => setOpen(!helpCvcShowPopup)} />
    </form>
  );
};

const InjectedCheckoutForm = injectStripe(StripeForm);

const mapStateToProps = state => ({
  account: state.auth.account,
});

const mapDispatchToProps = {
  postSubscriptionsPayperview: params => payperviewService.postSubscriptionsPayperview(params),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InjectedCheckoutForm)),
);
