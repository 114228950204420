import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as display from '../constants';
import { contentTypes, useCheckRouter } from '../../../utils/utils';
import { updateRibbonTarget } from '../../../features/homepage/services';
import Contents from './contents';

const RibbonItem = props => {
  let { item, ribbon, linkDetail, name, blockUrl, trailer, dpUpdateRibbonTarget, global } = props;
  let { title, slug, type, images } = item;
  let displayType = ribbon && ribbon.display_type;
  const displayTypeStr = display[`CLASS_${displayType || 1}`];
  let sourceURL = images;
  const [pageNavigation] = useCheckRouter();
  let backtoHome = false;
  if (!sourceURL) {
    sourceURL = images.thumbnail || images.poster || images.banner || '';
  }
  if (name) {
    title = name;
  }
  let linkTo =
    type === display.CHANNEL_TYPE ||
    type === display.CURATED_LIVE ||
    item.video_source === 1 ||
    linkDetail
      ? `/detail/${slug}`
      : `/landing/${slug}`;
  if (trailer && ribbon) {
    linkTo = `/detail/${ribbon.slug}`;
  }
  if (
    item.video_source === 1 &&
    (type === display.SHOW_TYPE || type === display.MOVIE_TYPE || type === display.CLIP) &&
    !linkDetail
  ) {
    linkTo = `/landing/${slug}`;
  }
  if (
    slug &&
    global.page.contentNavigation !== 'default' &&
    pageNavigation &&
    (type === display.MOVIE_TYPE || type === display.CLIP) &&
    item.has_free_content
  ) {
    linkTo = `/detail/${slug}`;
    backtoHome = true;
  }
  if (blockUrl || item.bannerExtra) {
    linkTo = null;
  }
  let contentType = contentTypes(item);
  let paymentType = props.paymentType || item.payment_type;
  return (
    <Contents
      {...props}
      linkTo={linkTo}
      displayTypeStr={displayTypeStr}
      contentType={contentType}
      type={type}
      backtoHome={backtoHome}
      dpUpdateRibbonTarget={dpUpdateRibbonTarget}
      title={title}
      pageNavigation={pageNavigation}
      sourceURL={sourceURL}
      paymentType={paymentType}
    />
  );
};

RibbonItem.defaultProps = {
  title: '',
  sourceURL: '',
  viewNumber: 0,
  favoritesNumber: 0,
  slug: '',
  type: 0,
};

const mapStateToProps = state => ({
  account: state.auth.account,
  page: state.home.page,
  global: state.global,
});

const mapDispatchToProps = {
  dpUpdateRibbonTarget: id => updateRibbonTarget(id),
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RibbonItem));
