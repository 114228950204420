import React, { useEffect, useRef, useState } from 'react';
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { MessageList, Message, MessageInput, Button } from '@chatscope/chat-ui-kit-react';
import { HeaderChatLive, ContainerChatLive } from './components';
import jquery from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import { ChatLiveStyled } from './styled';
import { useChatLiveWebSocket } from '~core/hook/chatLive/useChatLiveWebSocket';
import { isLiveEvent, isNewPPV__NoCache } from '~core/method/contentMethod';
import { isLogged, isLoggedAndPaidedContent } from '~core/method/authMethod';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { withStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { MESSAGE_ERROR_STATE } from '~core/constants';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { isEditedMessage } from '~core/method/message';
import { setIsSuccess, setOpenModalPPV } from '~components/global/globalSlice';
import $ from 'jquery';
import { useMediaQuery } from 'react-responsive';

const randomColor = require('randomcolor');

const LightTooltip = withStyles(theme => ({
  tooltip: {
    background: '#FFFFFF',
    borderRadius: '2px',
    color: '#000',

    fontFamily: 'Neue Haas Grotesk Display Pro',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '133.5%',
  },
}))(Tooltip);

const renderMessage = ({ model, resendMessage, nickName, userInfo }, color, messagesState) => {
  const { msg, u, _id } = model;
  const messageOfState = messagesState[_id] || null;
  const { username } = u;
  const messageStatus = messageOfState ? messageOfState.state : '';
  const message = messageOfState ? messageOfState.msg : msg;

  const getNameDisplay = () => {
    if ((userInfo.acocuntRefCode === username || `user_${userInfo.id}` === username) && nickName) {
      return nickName;
    }
    return u.name || u.username;
  };

  return (
    <Message key={_id} model={{ ...model }} className={`${messageStatus ? messageStatus : ''}`}>
      <Message.CustomContent>
        <span className="ms-sender-name" style={{ color: color }}>
          {getNameDisplay()}
        </span>
        <span className="ms-message">{`: ${message}`}</span>
        {messageStatus === MESSAGE_ERROR_STATE && (
          <LightTooltip
            title="Resend"
            placement="top"
            onOpen={event => {
              const target = event.target;
            }}
          >
            <IconButton aria-label="resend" className="resend" onClick={() => resendMessage(_id)}>
              <AutorenewIcon style={{ color: '#1DBBFF' }} />
            </IconButton>
          </LightTooltip>
        )}
      </Message.CustomContent>
    </Message>
  );
};

function ChatLive({ roomId, chatLiveToken, showLoginAction, canWatch, hasToken }) {
  const containerRef = useRef();
  const { t } = useTranslation();
  const open = useSelector(state => state.global.chatLive.open);
  const auth = useSelector(state => state.auth);
  const [scrollToBottom, setScrollToBottom] = React.useState(undefined);
  const msgListRef = useRef();
  const handleClickScrollBootm = value => {
    setScrollToBottom(value);
    msgListRef.current.scrollToBottom(value);
  };

  const dispatch = useDispatch();
  const [textInput, setTextInput] = useState('');
  const [userInfo, setUserInfo] = React.useState({
    id: '',
    acocuntRefCode: '',
  });
  // const [objectAuth, setObjectAuth] = React.useState({
  //   canWatch: canWatch,
  //   hasToken: hasToken,
  //   auth: auth,
  // });
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 768px)',
  });
  const pricesTickContentView = useSelector(
    state => !_.isEmpty(state.global.pricesView) && state.global.pricesView,
  );
  // React.useEffect(() => {
  //   setObjectAuth({
  //     canWatch: canWatch,
  //     hasToken: hasToken,
  //     auth: auth,
  //   });
  // }, [canWatch, hasToken, auth]);
  // const
  const {
    messageHistory = [],
    sendMessage,
    resendMessage,
    newMessages = [],
    messagesState = {},
    removedMessages,
    editedMessages,
    authRocketChat,
    error: rocketChatError,
  } = useChatLiveWebSocket({ roomId, token: chatLiveToken });

  const colorRef = useRef({});
  const landingPage = useSelector(state => state.landingPage);
  const { detail = {} } = landingPage;
  const account = useSelector(state => state.auth.account);
  React.useEffect(() => {
    if (_.isEmpty(account && account.profile)) {
      return;
    }
    setUserInfo({
      id: account.profile.id.split('-').slice(0, 1).join('-'),
      acocuntRefCode: account.profile.account_ref_code,
    });
  }, [account]);

  const nickName = useSelector(state => state.global.chatLive.nickname);

  const [messageList, setMessageList] = useState([]);

  const getColorByUserId = (userId, color) => {
    if (!colorRef.current[userId]) {
      colorRef.current[userId] = color || randomColor();
    }
    return colorRef.current[userId];
  };
  const onSend = (innerHtml, textContent, innerText) => {
    if (!_.isEmpty(rocketChatError)) {
      return;
    }
    handleClickScrollBootm('auto');
    sendMessage(textContent);
  };

  const handleOnchange = (innerHtml, textContent, innerText) => {
    if (!_.isEmpty(rocketChatError)) {
      return;
    }
    setTextInput(textContent);
  };

  const setTextButtonSend = (txt = 'Chat') => {
    if (!containerRef.current) {
      return;
    }
    const $container = jquery(containerRef.current);
    $container.find('.cs-button--send').text(txt);
  };

  const getAllMessage = () => {
    const allMessages = [...messageHistory, ...messageList].map(message => {
      if (!_.isEmpty(removedMessages[message._id])) {
        return null;
      }
      const editedMessage = editedMessages[message._id] || null;
      if (!_.isEmpty(editedMessage)) {
        return editedMessage;
      }
      return message;
    });
    return _.compact(allMessages);
  };

  useEffect(() => {
    setMessageList([...messageList, ...newMessages]);
  }, [newMessages]);

  useEffect(() => {
    const txt = textInput ? t('projectw__Livechat.btnSend') : t('projectw__Livechat.btnChat');
    setTextButtonSend(txt);
  });

  if (!open || !isLiveEvent.call(detail)) {
    return null;
  }

  return (
    <ChatLiveStyled className="chat-live" ref={containerRef}>
      <HeaderChatLive />

      <ContainerChatLive>
        <MessageList ref={msgListRef} scrollBehavior={scrollToBottom} autoScrollToBottom>
          {getAllMessage().map(messageModel =>
            renderMessage(
              { model: messageModel, resendMessage, userInfo, nickName },
              getColorByUserId(messageModel.u._id),
              messagesState,
            ),
          )}
        </MessageList>
        {isLoggedAndPaidedContent.call(auth, { canWatch, hasToken }) && (
          <MessageInput
            disabled={!_.isEmpty(rocketChatError)}
            className={`${textInput ? 'Send' : 'Chat'}`}
            attachButton={false}
            placeholder={t('projectw__Livechat.sendMessage')}
            onSend={onSend}
            onChange={handleOnchange}
          />
        )}
      </ContainerChatLive>
      {!isLoggedAndPaidedContent.call(auth, { canWatch, hasToken }) && (
        <Button
          className="sign-up-button"
          onClick={() => {
            isNewPPV__NoCache.call(pricesTickContentView) && !canWatch
              ? dispatch(setOpenModalPPV(true))
              : showLoginAction();
          }}
        >
          {t('projectw__Livechat.signInToChat')}
        </Button>
      )}
    </ChatLiveStyled>
  );
}

export default ChatLive;
