import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import VideoPlayer from '~components/player/Player';

import { Link } from 'react-router-dom';
import { getLinkPlayOfTopTenContent } from '~features/homepage/services';
import { getBanner19_6, getViewContentPlaying } from '~core/method/contentMethod';
import { LazyImage } from '~components';
import { SpotlighComponent } from '~components/projectw';
import lodash from 'lodash';

const timeFormat = record => {
  const { duration = 0 } = record;
  const time = moment.duration(duration, 'seconds');
  const hours = time.hours();
  const minutes = time.minutes();
  const hr = `${hours === 0 ? '' : `${hours}${hours > 1 ? 'hrs' : 'hr'}`}`;
  const min = `${minutes === 0 ? '' : `${minutes}${minutes > 1 ? 'mins' : 'min'}`}`;

  return `${hr ? `${hr}, ` : ''}${min}`;
};

const getDetailLink = record => {
  const { slug } = record;
  if (!slug) {
    return '#';
  }
  return `/detail/${slug}`;
};

const getLandingLink = record => {
  const { slug } = record;
  if (!slug) {
    return '#';
  }
  return `/landing/${slug}`;
};

const getImage = record => {
  return getBanner19_6.call(record);
};

const getTags = (record, metadata = 'genres') => {
  if (!record || !record.metadata) {
    return [];
  }
  return (record.metadata[metadata] || []).slice(0, 3);
};

const getFirstNWords = (str = '', suffix = '...', length = 45) => {
  const strSplit = str.split(' ');
  let result = strSplit.slice(0, length).join(' ');
  if (strSplit.length > length) {
    result += suffix;
  }
  return result;
};

function RecordRecommend(props) {
  const { record = {}, contentView = null, contentViewById = null } = props;

  return (
    <>
      <SpotlighComponent contentViewById={contentViewById} contentView={contentView} {...props} />
    </>
  );
}

const mapStateToProps = state => ({
  topTenContents: state.topTenContents,
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecordRecommend));
