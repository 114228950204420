import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default class SingleRibbon extends React.Component {
  render() {
    const { renderRibbonItem } = this.props;

    return (
      <Col sm={12}>
        <Slider {...this.props.sliderSettings}>{renderRibbonItem()}</Slider>
      </Col>
    );
  }
}

SingleRibbon.propTypes = {
  sliderSettings: PropTypes.object,
  ribbon: PropTypes.object,
  renderRibbonItem: PropTypes.func,
  index: PropTypes.number,
};
