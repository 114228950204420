import { CURATED_LIVE } from './../constants';
import moment from 'moment';
import * as init from './index';
import jquery from 'jquery';

const _handelLiveUi = component => {
  const $player = jquery(component.videoPlayer.player_.el_);
  const { isRewatchCuratedLive } = component.state;
  if (isRewatchCuratedLive) {
    $player.addClass('vjs-rewatching');
    $player.removeClass('vjs-live vjs-liveui');
  } else {
    $player.removeClass('vjs-rewatching');
    $player.addClass('vjs-live vjs-liveui vjs-none-linkplay');
    $player.find('.vjs-seek-to-live-control').addClass('vjs-at-live-edge');
  }
};

const handleCuratedLive = (component, entityDetail, response) => {
  const { isRewatchCuratedLive, isBlockAds } = component.state;
  if (isBlockAds) {
    return;
  }
  const $player = jquery(component.videoPlayer.player_.el_);
  const player = component.videoPlayer;
  let liveUi = $player.find('.vjs-curated-live').hasClass('vjs-live vjs-liveui');
  let reWatchLive = $player.find('.vjs-curated-live').hasClass('vjs-rewatching');
  if (entityDetail.type === CURATED_LIVE) {
    component.duration = component.videoPlayer.duration();
    if (response && response.current_live_cursor && !isRewatchCuratedLive) {
      component.isWatch = response.current_live_cursor;
      const { current_video_position, override_duration, real_duration } =
        response.current_live_cursor;
      init.initTextTrack(component, response.current_live_cursor, component.props.t);
      if (real_duration >= current_video_position) {
        handleSeekInitPlayer(component, current_video_position, override_duration);
      }
      player.on('timeupdate', () => {
        if (
          (!isRewatchCuratedLive && !liveUi && !reWatchLive) ||
          component.videoPlayer.duration() - component.videoPlayer.currentTime() < 60
        ) {
          $player.addClass('vjs-live vjs-liveui');
        }
      });
    }
    _handelLiveUi(component);
  }
};

const handleSeekInitPlayer = (component, currentVideoPosition, overrideDuration) => {
  const player = component.videoPlayer;
  let isPreroll = false;
  const { account } = component.props;
  let duration = currentVideoPosition;
  if (account && account.profile.has_subscription) {
    if (component.videoPlayer.duration()) {
      component.videoPlayer.currentTime(duration);
    }
    _resynchronizeCuratedLive(component, 5);
  } else {
    player.one('ads-ad-started', () => {
      isPreroll = true;
    });
    player.one('adend', event => {
      component._resumeVideo(duration);
      player.play();
    });
    player.one('adserror', event => {
      component._resumeVideo(duration);
    });
    setTimeout(() => {
      if (!isPreroll) {
        component._resumeVideo(duration);
      }
    }, 1000);
  }
};

const replayAds = (component, entityDetail, linkPlay) => {
  component.entityDetail.rewatchId = entityDetail.id;
  component.entityDetail.link_play = entityDetail.link_play;
  component.entityDetail.subtitles = entityDetail.subtitles;
  component._asyncGetVideo(entityDetail.slug, component.entityDetail);
};

const _handleRewatchCuratedLive = (component, entity, isNext, listEps = null) => {
  clearInterval(component.checkCurrentLive);
  const { current_live_cursor } = component.entityDetail;
  let rewatch = true;
  const { account } = component.props;

  component.isWatch = entity;
  if (!entity) {
    return;
  }
  let nowDay = current_live_cursor && moment(current_live_cursor.play_date).unix();
  let prevDay = moment(entity.play_date).unix();
  if (component.videoPlayer) {
    const $player = jquery(component.videoPlayer.player_.el_);
    $player.find('.vjs-cooldown-display').removeClass('vjs-cooldown-open');
    init.initTextTrack(component, entity, component.props.t);
  }
  if (listEps) {
    component.entityDetail = { ...component.entityDetail, items: listEps };
    component.listEpg = { items: listEps };
  }
  if (
    isNext ||
    (current_live_cursor && entity.id === current_live_cursor.id && nowDay === prevDay)
  ) {
    rewatch = false;
  }
  component.setState(
    {
      isRewatchCuratedLive: rewatch,
    },
    () => {
      replayAds(component, entity, entity.link_play);
    },
  );
};

const _resynchronizeCuratedLive = (component, timer) => {
  const { account } = component.props;
  clearInterval(component.checkCurrentLive);
  let longTime = timer * 60 * 1000;
  if (timer === 5) return;
  component.checkCurrentLive = setInterval(() => {
    component
      ._promiseGetCuratedLive(null, component.entityDetail)
      .then(response => {
        let currentTime = component.videoPlayer.currentTime();
        let currentVideoPosition;
        let timerDuration = '';
        if (response.current_live_cursor) {
          currentVideoPosition = response.current_live_cursor.current_video_position;
          timerDuration = currentVideoPosition - currentTime;
          if (timerDuration >= 30) {
            if (account && account.profile.has_subscription) {
              component._resumeVideo(currentVideoPosition);
            } else {
              component.videoPlayer.one('adend', event => {
                component._resumeVideo(currentVideoPosition);
                component.videoPlayer.play();
              });
            }
          }
        }
        component.entityDetail = { ...component.entityDetail, ...response };
        component.setState({
          entityDetail: component.entityDetail,
        });
      })
      .catch();
    if (longTime === 0) {
      clearInterval(component.checkCurrentLive);
    }
  }, longTime);
};

const getGapContent = (component, gapDuration) => {
  const player = component.videoPlayer;
  const $player = jquery(player.player_.el_);
  player.removeRemoteTextTrack(player.textTracks());
  component.props.dispatchUpdateStatusGetGapContens(true);
  let params = {
    liveId: null,
    rewatchId: null,
    channelId: null,
    detail: null,
  };
  component.props.dpUpdateCurrentLive(params);
  component.props
    .dpGetGapContents(component.entityDetail.id, gapDuration)
    .then(res => {
      if (!res || (res && !res.slug)) {
        return displayCooldown(component);
      }
      $player.find('.vjs-cooldown-display').removeClass('vjs-cooldown-open');
      $player.addClass('vjs-gapContent');
      component.props
        .dpGetInfoDetail(res.slug)
        .then(entity => {
          init.initTextTrack(component, entity, component.props.t);
        })
        .finally(() => {
          changeLinkplay(component, res.link_play);
        });
    })
    .catch(() => {
      displayCooldown(component);
    });
};

const displayCooldown = component => {
  const player = component.videoPlayer;
  const $player = jquery(player.player_.el_);
  $player.find('.vjs-cooldown-display').addClass('vjs-cooldown-open');
  _handelLiveUi(component);
  $player.removeClass('vjs-gapContent');
  changeLinkplay(component, '');
};

const changeLinkplay = (component, linkPlay) => {
  let player = component.videoPlayer;
  let typeVideo = 'application/x-mpegURL';
  if (linkPlay.includes('.mp4')) {
    typeVideo = 'video/mp4';
  }
  player.src({
    src: linkPlay,
    type: typeVideo,
  });
  if (!linkPlay) {
    return player.pause();
  }
  player.load();
  // component._checkAutoplay()
  let playPromise = player.play();
  if (playPromise !== undefined) {
    playPromise.then(_ => {
      jquery(`#${player.id_}`).focus();
    });
  }
  setTimeout(() => {
    jquery(`#${player.id_}`).focus();
    player.play();
  }, 100);
};

export {
  _handelLiveUi,
  _handleRewatchCuratedLive,
  handleCuratedLive,
  _resynchronizeCuratedLive,
  getGapContent,
};
