/* tslint:disable */
/* eslint-disable */
/**
 * Vimai Core Service UI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultitenancyApi = exports.MultitenancyApiFactory = exports.MultitenancyApiFp = exports.MultitenancyApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * MultitenancyApi - axios parameter creator
 * @export
 */
var MultitenancyApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Get list all menu
         * @summary Get list all menu
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [xhr] (0/1) check is web client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTenantPlatform: function (authorization, acceptLanguage, xhr, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/cm/menu/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (xhr !== undefined) {
                                localVarQueryParameter['xhr'] = xhr;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get config mux env key
         * @summary Get config mux env key
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMuxConfig: function (tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getMuxConfig', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/configs/mux_env_key/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get all menu detail
         * @summary Get all menu detail
         * @param {string} menuId id of menu
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [xhr] (0/1) check is web client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlatformDetail: function (menuId, authorization, acceptLanguage, xhr, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'menuId' is not null or undefined
                            (0, common_1.assertParamExists)('getPlatformDetail', 'menuId', menuId);
                            localVarPath = "/backend/cm/menu/{menu_id}/"
                                .replace("{".concat("menu_id", "}"), encodeURIComponent(String(menuId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (xhr !== undefined) {
                                localVarQueryParameter['xhr'] = xhr;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * [DA only] Get display value for constants
         * @summary [DA only] Get display value for constants
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemConstantsInfo: function (tenantSlug, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getSystemConstantsInfo', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/constants/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} domain
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getTenantDetail: function (domain, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'domain' is not null or undefined
                            (0, common_1.assertParamExists)('getTenantDetail', 'domain', domain);
                            localVarPath = "/backend/cm/tenant/{domain}/"
                                .replace("{".concat("domain", "}"), encodeURIComponent(String(domain)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get tenant slug from domain
         * @summary Get tenant slug from domain
         * @param {string} domain Domain
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantSlugFromDomain: function (domain, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'domain' is not null or undefined
                            (0, common_1.assertParamExists)('getTenantSlugFromDomain', 'domain', domain);
                            localVarPath = "/backend/cm/tenant_slug_from_domain";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (domain !== undefined) {
                                localVarQueryParameter['domain'] = domain;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getTrackingConfig: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/cm/tracking/config/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Ping
         * @summary Ping
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping: function (tenantSlug, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('ping', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/ping"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.MultitenancyApiAxiosParamCreator = MultitenancyApiAxiosParamCreator;
/**
 * MultitenancyApi - functional programming interface
 * @export
 */
var MultitenancyApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.MultitenancyApiAxiosParamCreator)(configuration);
    return {
        /**
         * Get list all menu
         * @summary Get list all menu
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [xhr] (0/1) check is web client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTenantPlatform: function (authorization, acceptLanguage, xhr, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getListTenantPlatform(authorization, acceptLanguage, xhr, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get config mux env key
         * @summary Get config mux env key
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMuxConfig: function (tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMuxConfig(tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get all menu detail
         * @summary Get all menu detail
         * @param {string} menuId id of menu
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [xhr] (0/1) check is web client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlatformDetail: function (menuId, authorization, acceptLanguage, xhr, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPlatformDetail(menuId, authorization, acceptLanguage, xhr, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * [DA only] Get display value for constants
         * @summary [DA only] Get display value for constants
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemConstantsInfo: function (tenantSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSystemConstantsInfo(tenantSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} domain
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getTenantDetail: function (domain, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTenantDetail(domain, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get tenant slug from domain
         * @summary Get tenant slug from domain
         * @param {string} domain Domain
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantSlugFromDomain: function (domain, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTenantSlugFromDomain(domain, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getTrackingConfig: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTrackingConfig(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Ping
         * @summary Ping
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping: function (tenantSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.ping(tenantSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.MultitenancyApiFp = MultitenancyApiFp;
/**
 * MultitenancyApi - factory interface
 * @export
 */
var MultitenancyApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.MultitenancyApiFp)(configuration);
    return {
        /**
         * Get list all menu
         * @summary Get list all menu
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [xhr] (0/1) check is web client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTenantPlatform: function (authorization, acceptLanguage, xhr, options) {
            return localVarFp.getListTenantPlatform(authorization, acceptLanguage, xhr, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get config mux env key
         * @summary Get config mux env key
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMuxConfig: function (tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.getMuxConfig(tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get all menu detail
         * @summary Get all menu detail
         * @param {string} menuId id of menu
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [xhr] (0/1) check is web client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlatformDetail: function (menuId, authorization, acceptLanguage, xhr, options) {
            return localVarFp.getPlatformDetail(menuId, authorization, acceptLanguage, xhr, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * [DA only] Get display value for constants
         * @summary [DA only] Get display value for constants
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemConstantsInfo: function (tenantSlug, options) {
            return localVarFp.getSystemConstantsInfo(tenantSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} domain
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getTenantDetail: function (domain, options) {
            return localVarFp.getTenantDetail(domain, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get tenant slug from domain
         * @summary Get tenant slug from domain
         * @param {string} domain Domain
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantSlugFromDomain: function (domain, options) {
            return localVarFp.getTenantSlugFromDomain(domain, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getTrackingConfig: function (options) {
            return localVarFp.getTrackingConfig(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Ping
         * @summary Ping
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping: function (tenantSlug, options) {
            return localVarFp.ping(tenantSlug, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.MultitenancyApiFactory = MultitenancyApiFactory;
/**
 * MultitenancyApi - object-oriented interface
 * @export
 * @class MultitenancyApi
 * @extends {BaseAPI}
 */
var MultitenancyApi = /** @class */ (function (_super) {
    __extends(MultitenancyApi, _super);
    function MultitenancyApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get list all menu
     * @summary Get list all menu
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [xhr] (0/1) check is web client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultitenancyApi
     */
    MultitenancyApi.prototype.getListTenantPlatform = function (authorization, acceptLanguage, xhr, options) {
        var _this = this;
        return (0, exports.MultitenancyApiFp)(this.configuration).getListTenantPlatform(authorization, acceptLanguage, xhr, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get config mux env key
     * @summary Get config mux env key
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultitenancyApi
     */
    MultitenancyApi.prototype.getMuxConfig = function (tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.MultitenancyApiFp)(this.configuration).getMuxConfig(tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get all menu detail
     * @summary Get all menu detail
     * @param {string} menuId id of menu
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [xhr] (0/1) check is web client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultitenancyApi
     */
    MultitenancyApi.prototype.getPlatformDetail = function (menuId, authorization, acceptLanguage, xhr, options) {
        var _this = this;
        return (0, exports.MultitenancyApiFp)(this.configuration).getPlatformDetail(menuId, authorization, acceptLanguage, xhr, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * [DA only] Get display value for constants
     * @summary [DA only] Get display value for constants
     * @param {string} tenantSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultitenancyApi
     */
    MultitenancyApi.prototype.getSystemConstantsInfo = function (tenantSlug, options) {
        var _this = this;
        return (0, exports.MultitenancyApiFp)(this.configuration).getSystemConstantsInfo(tenantSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} domain
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MultitenancyApi
     */
    MultitenancyApi.prototype.getTenantDetail = function (domain, options) {
        var _this = this;
        return (0, exports.MultitenancyApiFp)(this.configuration).getTenantDetail(domain, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get tenant slug from domain
     * @summary Get tenant slug from domain
     * @param {string} domain Domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultitenancyApi
     */
    MultitenancyApi.prototype.getTenantSlugFromDomain = function (domain, options) {
        var _this = this;
        return (0, exports.MultitenancyApiFp)(this.configuration).getTenantSlugFromDomain(domain, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MultitenancyApi
     */
    MultitenancyApi.prototype.getTrackingConfig = function (options) {
        var _this = this;
        return (0, exports.MultitenancyApiFp)(this.configuration).getTrackingConfig(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Ping
     * @summary Ping
     * @param {string} tenantSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultitenancyApi
     */
    MultitenancyApi.prototype.ping = function (tenantSlug, options) {
        var _this = this;
        return (0, exports.MultitenancyApiFp)(this.configuration).ping(tenantSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return MultitenancyApi;
}(base_1.BaseAPI));
exports.MultitenancyApi = MultitenancyApi;
