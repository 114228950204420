import * as types from './actionTypes';

export function updateListRibbon(payload) {
  return {
    type: types.UPDATE_LIST_RIBBON,
    payload,
  };
}

export function updatePageYoutube(payload) {
  return {
    type: types.UPDATE_PAGE_YOUTUBE,
    payload,
  };
}

export function updateSeach(queryParams) {
  return {
    type: types.UPDATE_SEARCH,
    queryParams,
  };
}

export function updateHistoryFilter(payload) {
  return {
    type: types.UPDATE_HISTORY,
    payload,
  };
}
