import React, { useState } from 'react';
import { TooltipWrap } from '../actionButton';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import { reactContent } from '~api/reactContent';
import { getReactedContents } from '~features/homepage/services';
import { connect } from 'react-redux';

function Like(props) {
  const {
    t,
    item,
    reactedContents,
    dpGetReactedContents,
    account,
    target,
    component,
    customeClass,
  } = props;
  let contents = item;
  if (target === 'landing') contents = component.state.entityDetail;
  const [loading, setLoading] = useState(false);
  const isLiked = reactedContents && reactedContents.find(c => c.content_id === contents.id);
  const handleLike = () => {
    setLoading(true);
    reactContent({
      react_type: 1,
      content_id: contents.id,
    })
      .then(() => dpGetReactedContents())
      .finally(() => setLoading(false));
  };
  if (!account) return null;

  return (
    <TooltipWrap title={isLiked ? t('ribbon.rated') : t('ribbon.like')} arrow placement="top">
      <div
        className={classnames('button', isLiked && 'liked', customeClass)}
        onClick={() => handleLike()}
      >
        <i
          className={classnames('fa', loading ? 'fa-circle-o-notch fa-spin' : 'fa-thumbs-up')}
          aria-hidden="true"
        />
      </div>
    </TooltipWrap>
  );
}

const mapStateToProps = state => ({
  reactedContents: state.home.reactedContents,
  account: state.auth.account,
});
const mapDispatchToProps = {
  dpGetReactedContents: params => getReactedContents(params),
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Like));
