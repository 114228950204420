import styled from 'styled-components';
import { Box } from '@material-ui/core';

export const RibbonsTitle = styled.div`
  &.Option {
    /* font-size: 109px; */
    z-index: 9 !important;
    position: relative !important;
    top: 0;
    transition: all 0.5s ease-in;
    cursor: pointer;
  }
  &.Ribbons-Default {
    cursor: pointer;
    display: flex;
    font-size: 135px;
    font-family: 'Neue Machina';
    font-weight: 500;
    line-height: 88%;
    color: #000;
    z-index: 10;
    padding: 20px 20px;
    border-bottom: 0.5px solid #000;
    position: relative;
    background-color: #fff;
    transition: 0.2s all ease-in-out 0s;
    &:hover {
      color: #fff;
      path {
        fill: #fff;
      }
    }
    &:before {
      content: ' ';
      position: absolute;
      bottom: 0;
      top: unset;
      left: 0;
      width: 100%;
      height: 0;
      background-color: #000;
      transition: 0.1s all ease-in-out 0s;
    }
    &:hover:before {
      bottom: unset;
      top: 0;
      height: 100%;
    }
    .Option {
      &--NumberItem {
        font-size: 135px;
      }
    }
    .Ribbons-Default,
    .Option {
      &--NumberItem {
        z-index: 99;
        width: 14%;
      }
      &--NameItem {
        padding-left: 160px;
        padding-top: 4px;
        z-index: 99;
        max-width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &--IconsArrow {
        margin-left: auto;
        z-index: 99;
        background-color: none;
        background: none;
        border: none;
        #acc {
          transition: transform ease-in-out 0.5s !important;
          transform: rotate(90deg);
        }
        .openClick {
          transition: transform ease-in-out 1s !important;
          transform: rotate(-225deg) !important;
        }
        .unOpenClick {
          transition: transform ease 1s !important;
          animation: Around 1s ease-out forwards;
        }
      }
    }
  }

  @media (max-width: 767px) {
    &.Ribbons-Default {
      font-size: 43px;
      align-items: center;
      padding: 0 10px;
      .Ribbons-Default,
      .Option {
        font-size: 43px;
        &--IconsArrow {
          transform: scale(0.5);
          width: 20%;
        }
        &--NameItem {
          padding-left: 30px;
          padding-top: 0px;
          font-size: 30.5px;
        }
        &--NumberItem {
          font-size: 43px !important;
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 990px) {
    &.Ribbons-Default {
      font-size: 80px;
      align-items: center;
      .Ribbons-Default,
      .Option {
        &--NumberItem,
        &--NameItem {
          font-size: 80px;
        }
        &--NameItem {
          padding-left: 60px;
        }
        &--IconsArrow {
          transform: scale(0.85);
        }
      }
    }
  }
`;

export const RibbonsItem = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* border-right: ${props => (props.types === 'continue' ? '0.5px solid #000' : '')}; */
  .Items {
    align-items: flex-start;
    width: 50%;
    /* border-top: 0.5px solid #000; */
    border-bottom: 0.5px solid #000;
    /* border-right: 0.5px solid #000; */
    display: flex;
    text-decoration: none;
    font-family: 'Neue Haas Grotesk Display Pro';
    color: #000;
    font-weight: 400;
    background-color: #fff;
    /* &:last-child:nth-child(2n + 1) {
      border-right: 0.5px solid #000;
    } */
    /* &:nth-child(2n) {
      border-left: 0.5px solid #000;
    } */
    &:nth-child(2n + 2) {
      border-right: ${props => (props.types === 'continue' ? '0.5px solid #000' : '')};
    }
    &:nth-child(2n + 1) {
      border-inline-end: 0.5px solid #000;
      .Items--Image {
        width: calc(50% + 0.5px);
      }
    }
    &:nth-last-child(2):nth-child(2n + 1),
    &:nth-last-child(1) {
      border-bottom: none;
    }
    &--Image {
      background-color: #fff;
      max-width: 100%;
      width: 50%;
      position: relative;
      &::before {
        padding-top: calc(5 / 9 * 100%);
        display: block;
        content: '';
      }
      img {
        display: block;
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        min-height: 95px;
        object-fit: cover;
        /* object-position: center top; */
      }
      .category {
        position: absolute;
        background: #fff;
        padding: 3px;
        max-width: 100px;
        border: none;
        span {
          font-family: 'Neue Haas Grotesk Display Pro';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 133.5%;
          /* identical to box height, or 16px */

          letter-spacing: 0.05em;

          /* Light/gray/0 */

          color: #000000;
        }
      }
    }
    &--Content {
      background-color: #fff;
      max-width: 100%;
      max-height: 100%;
      width: 50%;
      height: 100%;
      padding: 15px 15px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &--title {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        /* identical to box height */
        letter-spacing: -0.005em;
        letter-spacing: -0.005em;
        display: -webkit-box;
        max-height: 58px;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-line-clamp: 2;
        /* line-height: 1.6rem; */
      }
      &--desc {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 146%;
        /* or 25px */
        display: -webkit-box;
        max-height: 3.2rem;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-line-clamp: 2;
        line-height: 1.6rem;
        letter-spacing: 0.025em;
        word-break: keep-all;
      }
      &--hour {
        /* position: absolute; */
        /* bottom: 0; */
        /* margin-top: auto; */
        font-family: 'Neue Haas Grotesk Display Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 133.5%;
        /* identical to box height, or 16px */

        letter-spacing: 0.05em;

        /* Light/gray/0 */

        color: #000000;
      }
    }
  }
`;
