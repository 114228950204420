import { CheckOut, Subcriptions, RegisterPopup, Successful } from '../components';
import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { getConfigByKey } from '~features/tenantConfig/services';
import { TENANT_NAME_ENUM } from '~core/constants';

let subComponent = null;
const getActiveComponent = (component, setStep) => {
  if (!subComponent) {
    return <Subcriptions {...component} setStep={() => setStep()} />;
  }
  return subComponent;
};

const getStepPayperview = (step, component, setStep, method = null) => {
  if (method) {
    component = { ...component, showLoginAction: method.login };
  }

  setStep(step);
  switch (step) {
    case 0:
      if (method && method.setHideRegister) {
        method.setHideRegister(false);
      }
      subComponent = <RegisterPopup {...component} setStep={setStep} method={method} />;
      break;
    case 1:
      subComponent = <Subcriptions {...component} setStep={setStep} />;
      break;
    case 2:
      subComponent = <CheckOut {...component} setStep={setStep} />;
      break;
    case 3:
      subComponent = <Successful {...component} setStep={setStep} />;
      break;
    default:
      subComponent = <Subcriptions {...component} setStep={setStep} />;
      break;
  }
};

const setDataPaySubcription = params => {
  let txtUrl = window.location.href;
  if (getConfigByKey('ui_theme') === TENANT_NAME_ENUM.PROJECTW) {
    txtUrl = `${window.location.origin}` + window.location.pathname;
  }
  let data = {
    payment_provider: params.provider,
    content_id: params.contentId,
    payment_method_id: params.paymentMethod ? params.paymentMethod.id : '',
    customer_name: params.nameStripe || '',
    email: params.email,
  };
  if (params.provider === 'paypal' || params.provider === 'bootpay') {
    const { tvod_type = undefined, price_id = undefined, contentId: content_id } = params;
    const searchStringify = queryString.stringify({
      tvod_type,
      price_id,
      content_id,
    });
    const backUrl = `${txtUrl}${searchStringify ? `?${searchStringify}` : ''}`;
    data = {
      ...data,
      price_id,
      success_url: backUrl,
      fail_url: backUrl,
    };
  }
  return data;
};

export { getStepPayperview, getActiveComponent, setDataPaySubcription };
