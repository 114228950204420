import styled from 'styled-components';

export const DivStyle = styled.div`
  .vjs {
    &--escondido {
      /* videojs */
      .vjs-progress-control {
        padding: 0 25px;
        &.vjs-control {
          display: unset;
          top: 0;
          .vjs-load-holder {
            background-color: ${({ theme }) => theme.player.progress.loadHolder};
            &:hover {
              height: 50%;
            }
          }
          .vjs-load-progress {
            background: ${({ theme }) => theme.player.progress.loadHolder};
          }
          .vjs-time-tooltip {
            color: ${({ theme }) => theme.primaryColor};
          }
          .vjs-play-progress,
          .vjs-play-progress:before {
            background: ${({ theme }) => theme.primaryColor};
          }
        }
      }
      .vjs-auto-next-spiner--svg .circle {
        stroke: ${({ theme }) => theme.primaryColor};
      }
      .vjs-loading-spinner {
        filter: ${({ theme }) => theme.filterColor};
      }
      .vjs-report-display {
        .panel-body-alert--question.active,
        .panel-body-alert--question[aria-expanded='true'],
        .panel-footer--btn {
          background: ${({ theme }) => theme.primaryColor};
        }
      }
      .vjs-seek-next {
        display: block !important;
      }
      &-wrapButtonReplay {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;
      }
      .vjs-control-bar {
        background-color: unset;
        padding-top: 15px;
        height: 100px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
        align-items: flex-start;
        .vjs-subs-caps-button,
        .vjs-next-epg,
        .vjs-prev-epg,
        .vjs-fullscreen-control,
        .vjs-epg-button,
        .vjs-play-control,
        .vjs-seek-to-live-control,
        .vjs-current-time,
        .vjs-remaining-time,
        .vjs-prev,
        .vjs-next,
        .vjs-list-eps .dropup {
          display: none !important;
        }
      }
      .vjs-volume-panel {
        border-radius: 50%;
        margin-left: 40px !important;
        width: 45px !important;
        height: 45px;
        &.vjs-hover {
          .vjs-volume-control {
            bottom: 15px !important;
            width: 45px;
          }
          .vjs-mute-control {
            background: ${({ theme }) => theme.primaryColor};
          }
        }
        .vjs-mute-control {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          display: flex;
          z-index: 9;
          justify-content: center;
          align-items: center;
        }
        .vjs-icon-placeholder::before {
          font-size: 27px;
        }
      }

      button.vjs-fullscreen-control {
        margin-right: 37px;
        span {
          width: 25px;
          height: 25px;
          &:before {
            font-size: 2em !important;
          }
        }
      }
      .vjs-slider-vertical .vjs-volume-level::before {
        color: ${({ theme }) => theme.primaryColor};
      }

      /* code */
      .vjs-duration {
        display: block;
        position: absolute;
        right: 35px;
        top: -30px;
        padding: 0;
        margin: 0;
        min-width: unset;
        line-height: unset;
      }

      &-wrapEpisodes {
        border: none;
        border-radius: 4px;
        background: #1c1b1b;
        width: 300px;
        max-height: 30vh;
        overflow-y: scroll;
        padding: 0 1rem;
        .notVip {
          display: none;
        }
        .isVip {
          color: #e8b600;
          font-weight: 400;
          font-size: 0.85rem;
          text-transform: uppercase;
          display: flex;
          i {
            display: block;
            padding-right: 0.2rem;
          }
        }
        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px #353535;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #676767;
          // outline: 1px solid slategrey;
          border-radius: 10px;
        }
        button {
          border-bottom: 1px solid #545454;
          width: 100%;
          padding: 1.5rem 0;
          color: #ffffff;
          &:hover {
            text-shadow: 0 0 1em #fff;
            text-decoration: none;
          }
          &.active {
            text-shadow: 0 0 1em #fff;
            i {
              display: block;
            }
          }
          i {
            display: none;
          }
        }
        button:last-child {
          border-bottom: none;
        }
      }

      &-back {
        position: absolute;
        top: 40px;
        left: 30px;
        display: flex;
        align-items: center;
        justify-self: center;
        z-index: 99;
        &__btn {
          margin-right: 24px;
          font-size: 22px;
          width: 35px;
          height: 35px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          transition: 0.3s ease;
          &:hover {
            background: ${({ theme }) => theme.primaryColor};
          }
        }
        &__logo {
          width: 80px;
          height: auto;
        }
      }
      .vjs-control-bar {
        &-live,
        &-replay {
          display: flex;
        }
        &-live {
          margin-right: 10px;
        }
      }
      .vjs--selector {
        margin-right: 15px;
        position: relative;
        width: 45px;
        height: 45px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        svg {
          width: 32px;
          height: 32px;
        }
        ion-icon {
          width: 27px;
          height: 27px;
        }
        i {
          &.fa-info-circle {
            font-size: 28px;
          }
          font-size: 24px;
        }
        &:hover {
          background-color: ${({ theme }) => theme.primaryColor};
        }
        &__tool-tip {
          transition: 1s ease;
          background: #0a0a0a;
          opacity: 0;
          position: absolute;
          margin-top: 10px;
          left: 0;
          font-size: 12px;
          color: #d1d1d1;
          font-weight: bold;
          text-transform: capitalize;
          top: 0;
          width: 426px;
          max-height: 400px;
          transform: translate(-90%, -120%);
          padding: 17px 29px 17px 29px;
          transition: 0.2s all;
          overflow: auto;
          visibility: hidden;
          &--active {
            opacity: 0.8;
            visibility: unset;
          }
          &--legal {
            font-size: 14px;
            color: #a3a3a3;
            margin-bottom: 8px;
          }
          &--title {
            font-size: 29px;
            color: #fff;
            margin-bottom: 12px;
          }
          &--desscription {
            font-size: 14px;
            color: #a3a3a3;
            font-weight: unset;
          }
        }
        &--logoCn {
          height: auto;
          display: flex;
          object-fit: cover;
        }
        &--liveui {
          position: absolute;
          right: 35px;
          top: 0;
          transform: translateY(-100%);
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(56, 56, 56, 0.2);
          text-transform: capitalize;
          height: 30px;
          width: 70px;
          border-radius: 5px;
          svg {
            color: #ff0000;
            width: 8px;
            height: 8px;
            font-size: 8px;
            margin-right: 7px;
          }
          span {
            font-size: 16px;
            color: #fff;
          }
        }
        &__subtitle {
          padding: 7px 0;
          color: #a3a3a3;
          cursor: pointer;
          &-active {
            color: #fff;
            font-weight: bold;
          }
        }
      }

      //default
      &.vjs-live {
        .vjs-duration {
          display: none;
        }
        .vjs-progress-control {
          display: none;
          padding-right: 25px;
        }
        .vjs--logo__chanel {
          display: block;
        }
      }
      &.vjs-liveui {
        .vjs-progress-control.vjs-control {
          top: 0;
          .vjs-load-progress {
            background: ${({ theme }) => theme.primaryColor};
          }
          .vjs-play-progress {
            background: ${({ theme }) => theme.primaryColor};
          }
        }
      }
      .vjs-disable-offlive {
        &.vjs-button-nextepg-open {
          .vjs--selector[title='Next'] {
            color: #555555;
            cursor: pointer;
            pointer-events: none;
          }
        }
      }
      &.vjs-episode-begin {
        .vjs--selector__prev {
          cursor: pointer;
          pointer-events: none;
          color: #555555;
        }
      }
      &.vjs-episode-last {
        .vjs--selector__next {
          pointer-events: none;
          color: #555555;
        }
      }
    }
  }
`;
