import styled from 'styled-components';

export const DivStyle = styled.div`
  &.v-detailPage {
    display: flex;
    justify-content: space-between;
    background: #0f0f0f;
    height: 100%;
    position: relative;
    overflow: auto;
    flex-direction: column;
  }
  .v-detailPage {
    &__err {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 1.5rem;
      text-transform: uppercase;
    }
    &__ads {
      min-height: 600px;
      margin: 0;
      padding: 0;
      width: 160px;
      &-square {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        &-wrap {
          transform: scale(1);
          max-height: 300px;
          max-width: 350px;
          min-width: 336px;
          height: 280px;
          .v-ads-banner__wrap {
            padding: 0;
          }
        }
      }
    }
    &__youtube,
    #boxplayerWrapper .video-js {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    &__player {
      position: relative;
      width: 100%;
      display: flex;
      min-height: 100%;
      padding-top: 0;
      justify-content: space-between;
      flex-direction: column;
      flex-shrink: 0;
      padding-bottom: 1rem;
      &--container {
        width: 100%;
        min-height: 100%;
        height: auto;
        display: block;
        /* position: absolute; */
        &-main {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
      .v-youtube-player {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin-bottom: 1.5rem;
      }
      .v-youtube,
      #boxplayerWrapper {
        &.vjs-landscape {
          padding-top: calc(100% * 9 / 16);
        }
        &.player-live {
          padding-top: calc(100vh - 80px);
        }
        display: block;
        position: relative;
        padding-top: calc((100% * 9 / 16) * 2);
        height: auto;
        width: 100%;
      }
      /* .v-youtube {
        padding-top: calc(100% * 9 / 16);
      } */
    }
    &__playerWrap {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;
      height: auto;
      /* min-height: 100vh; */
    }
    &__info {
      width: 100%;
      margin-bottom: 0;
      background: linear-gradient(#1a1a1a 100%, #030303 95%);
      color: #fff;
      border: none;
      font-size: 1rem;
      padding: 0;
      overflow: unset;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
      &--header {
        border: none;
        color: #fff;
        background: #3b3b3b;
        font-size: 1rem;
        text-align: justify;
        span {
          font-weight: 600;
        }
        span:last-child {
          font-weight: 100;
        }
      }
    }
    &__loading {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 27vh;
      p {
        color: #fff;
      }
    }
    &--category {
      color: #7d7d7d;
      font-size: 1.125rem;
    }
    &--title {
      display: flex;
      justify-content: space-between;
      font-size: 1.5rem;
      text-transform: uppercase;
      h1 {
        font-size: 1.5rem;
      }
    }
    &--duration {
      font-size: 1rem;
      span {
        color: ${({ theme }) => theme.primaryColor};
        font-weight: 500;
      }
      color: #7d7d7d;
    }
    &--people {
      font-size: 1rem;
      font-weight: 500;
      span,
      a {
        &:first-child {
          color: #7d7d7d;
        }
      }
      a {
        color: ${({ theme }) => theme.primaryColor};
        &:hover {
          text-decoration: none;
        }
      }
      color: ${({ theme }) => theme.primaryColor};
      &.projectw {
        a {
          color: #fff;
        }
      }
    }
    &--desscription {
      word-break: break-word;
      font-size: 1rem;
      font-weight: 100;
      text-align: justify;
      max-height: auto;
      overflow: auto;
      margin-bottom: 2.5rem;
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #fff;
        width: 5px;
      }
    }
    &__recommand {
      margin-top: 1rem;
      z-index: 1;
      width: calc(100vw - 30px);
      height: auto;
      position: absolute;
      bottom: -1px;
      transform: translateY(100%);
      flex-shrink: 0;
      .ribbonItemContainer-icon-remove {
        div.texttruncate {
          color: #fff;
        }
      }
      .ribbonContainer {
        background: #0f0f0f;
      }
    }
  }
  @media (min-width: 576px) {
    .v-detailPage {
      &__player {
        .v-youtube,
        #boxplayerWrapper,
        #boxplayerWrapper.vjs-landscape {
          display: block;
          position: relative;
          padding-top: calc(100% * 9 / 16);
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  @media (min-width: 1024px) {
    &.v-detailPage {
      flex-direction: row;
    }
    .v-detailPage {
      &__recommand {
        width: auto;
      }
      &--desscription {
        max-height: 10rem;
      }
      &__ads {
        &-square {
          &-wrap {
            transform: scale(0.9);
          }
        }
      }
      &__player {
        flex-direction: row;
        flex-shrink: 0;
        &--container {
          display: flex;
          &-main {
            width: 70%;
          }
        }
        #boxplayerWrapper {
          &.player-live {
            padding-top: calc(70% * 9 / 16);
            width: 100%;
            video,
            .vjs-control-bar,
            .v-reportContents,
            .ima-ad-container {
              width: 70%;
            }
            .ima-ad-container {
              div:first-child {
                width: 100% !important;
              }
              iframe {
                width: 100%;
              }
            }
            .vjs-epg-display {
              position: absolute;
              max-width: 30%;
              height: calc(100% - 16px);
              top: 0;
              transition: right 0.5s;
              z-index: 999;
              right: 0;
            }
            .vjs-report,
            .vjs-top-policy {
              right: 30%;
            }
          }
        }
      }
      &__recommand {
        position: relative;
        bottom: 1px;
        transform: translateY(0%);
      }
      &__playerWrap {
        width: calc(100% - 163 * 2px);
      }
      &__info {
        width: 30%;
        height: auto;
        padding: 0.75rem;
        overflow: auto;
        &--header {
          background: none;
          border-bottom: 1px solid #666666;
        }
      }
    }
    &.v-detailPage.sctv {
      justify-content: start;
      .v-detailPage {
        &__player {
          &--container {
            display: block;
            height: auto;
            &-main {
              width: 100%;
            }
          }
        }
        &__recommand {
          position: absolute;
        }
        &__info {
          width: 100%;
        }
      }
    }
  }
  @media (orientation: landscape) and (max-width: 1024px) {
    .v-detailPage {
      &__info {
        &--header {
          font-size: 0.815rem;
        }
      }
      &--category {
        font-size: 1rem;
      }
      &--title {
        h1 {
          font-size: 1.125rem;
        }
      }
      &--people,
      &--desscription,
      &--duration {
        font-size: 0.8125rem;
      }
      &--desscription {
        max-height: 5.5rem;
        margin-bottom: 0.25rem;
      }
    }
  }
  @media (min-width: 1280px) {
    .v-detailPage {
      &__ads {
        &-square {
          &-wrap {
            transform: scale(1);
          }
        }
      }
    }
  }
  @media (min-width: 1440px) {
    .v-detailPage {
      &__player {
        &--container {
          &-main {
            width: 75%;
          }
        }
        #boxplayerWrapper {
          &.player-live {
            padding-top: calc(75% * 9 / 16);
            video,
            .vjs-control-bar,
            .v-reportContents,
            .ima-ad-container {
              width: 75%;
            }
            .vjs-epg-display {
              max-width: 25%;
            }
            .vjs-report,
            .vjs-top-policy {
              right: 25%;
            }
          }
        }
      }
      &__info {
        width: 25%;
      }
    }
  }
`;
