import React, { Component } from 'react';
import jquery from 'jquery';
import moment from 'moment';
import ReactDOM from 'react-dom';

class AutoNext extends Component {
  constructor(props) {
    super(props);
    this.timerOut = null;
    this.state = {};
  }

  componentWillUnmount() {
    clearTimeout(this.timerOut);
  }

  componentDidMount() {
    const { player, timer } = this.props;
    const $player = jquery(player.el_);
    player.on('contentchanged', function () {
      $player.find('.vjs-auto-next').addClass('vjs-hidden');
    });
    player.on('ended', event => {
      if ($player.hasClass('vjs-live') || $player.hasClass('vjs-button-nextepg-open')) {
        $player.find('.vjs-auto-next').addClass('vjs-hidden');
        return;
      }

      $player.find('.vjs-auto-next').removeClass('vjs-hidden');

      jquery(
        $player.find('.circle').css({
          'animation-duration': timer + 's',
        }),
      );
      this.handleAutoNext();
    });
    player.on('timeupdate', event => {
      $player.find('.vjs-auto-next:not(.vjs-hidden)').addClass('vjs-hidden');
    });
    player.on('seeked', event => {
      $player.find('.vjs-auto-next').addClass('vjs-hidden');

      if (this.timerOut) {
        clearTimeout(this.timerOut);
        this.timerOut = null;
      }
    });
  }

  handleNext() {
    clearTimeout(this.timerOut);
    let { player, timer, component } = this.props;

    const $player = jquery(player.el_);
    const nextRewatchLive = $player.find('.vjs-next-epg');

    const { isHasSubcription } = component.state;

    if (
      (player && !player.ended()) ||
      (!isHasSubcription && player.ads && player.ads.isAdPlaying())
    ) {
      return;
    }
    if ($player.hasClass('vjs-rewatching') && nextRewatchLive.length) {
      nextRewatchLive.click();
      return;
    }
    const btnNext = $player.find('.vjs-next');
    if (btnNext.length) {
      btnNext.trigger('click');
      return;
    }
    this.handleNextRewatchEpg($player);
  }

  handleCancel() {
    const { player } = this.props;
    const $player = jquery(player.el_);

    if (this.timerOut) {
      clearTimeout(this.timerOut);
      this.timerOut = null;
      $player.find('.vjs-auto-next').addClass('vjs-hidden');
      player.preload(false);
      jquery(
        $player.find('.circle').css({
          'animation-duration': 0 + 's',
        }),
      );
    }
  }

  handleNextRewatchEpg($player) {
    const $vjsList = $player.find('.vjs-epg-rewatching').first();

    if (!$vjsList.length) {
      return;
    }
    const $currentEpg = $vjsList.find('.rewatching').first();
    const $nextEpg = $currentEpg.next();

    if (!$currentEpg.length || !$nextEpg.length) {
      return;
    }

    $nextEpg.trigger('click');
  }

  handleAutoNext() {
    let { player, timer, component } = this.props;
    const { isHasSubcription } = component.state;
    const $player = jquery(player.el_);
    timer = timer * 1000;
    const nextRewatchLive = $player.find('.vjs-next-epg');
    if (this.timerOut) {
      clearTimeout(this.timerOut);
    }
    $player.find('.vjs-next').addClass('is-auto-next');
    this.timerOut = setTimeout(
      () => {
        if (
          (player && !player.ended()) ||
          (!isHasSubcription && player.ads && player.ads.isAdPlaying && player.ads.isAdPlaying())
        ) {
          return;
        }
        if ($player.hasClass('vjs-rewatching')) {
          nextRewatchLive.click();
        } else {
          $player.find('.vjs-next').trigger('click');
        }
      },
      timer,
      player,
    );
  }

  render() {
    const { t } = this.props.component.props;
    return (
      <>
        <button className="vjs-auto-next-spiner" onClick={() => this.handleNext()}>
          <svg
            className="vjs-auto-next-spiner--svg"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlSpace="preserve"
          >
            <circle className="circle" cx="34" cy="34" r="30" />
          </svg>
          <i className="timer fa fa-step-forward" aria-hidden="true" />
        </button>
        <button
          className="vjs-auto-next-spiner vjs-auto-next-cancel"
          onClick={() => this.handleCancel()}
        >
          {t('cancel')}
        </button>
      </>
    );
  }
}

export default AutoNext;
