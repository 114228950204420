// Get the Component base class from Video.js
import { pushEventFirebase } from './../components/pushEventFirebase';
import videojs from 'video.js';
import jquery from 'jquery';
import '../components/Warning_AgeRestrictions/style/style.scss';
var Button = videojs.getComponent('Button');

// The videojs.extend function is used to assist with inheritance. In
// an ES6 environment, `class SeekPrev extends Component` would work
// identically.
var SeekPrev = videojs.extend(Button, {
  // The constructor of a component receives two arguments: the
  // player it will be associated with and an object of options.
  constructor: function (player, options) {
    // It is important to invoke the superclass before anything else,
    // to get all the features of components out of the box!
    Button.apply(this, arguments);
  },

  // The `createEl` function of a component creates its DOM element.
  createEl: function () {
    const btn = videojs.dom.createEl('button', {
      className: 'vjs-control vjs-button vjs-seek-prev',
    });
    btn.innerHTML = '<i class="fa fa-undo vjs-icon-placeholder" aria-hidden="true"></i>';
    return btn;
  },

  // This function could be called at any time to update the text
  // contents of the component.
  updateTextContent: function (text) {
    // If no text was provided, default to "Title Unknown"
    text = '';

    // Use Video.js utility DOM methods to manipulate the content
    // of the component's element.
    videojs.dom.emptyEl(this.el());
    videojs.dom.appendContent(this.el(), text);
  },
  handleClick: function (event) {
    let duration = 15;
    const that = this.options_.component;
    const entityDetail = this.options_.entity;
    if (this.options_ && this.options_.duration && typeof this.options_.duration === 'number') {
      duration = this.options_.duration;
    }
    pushEventFirebase(that.entityDetail, 'rewind');
    const currentTime = this.player_.currentTime() - duration;
    this.player_.currentTime(currentTime);
    // that._updateProgress(entityDetail.id, currentTime > 0 ? currentTime : 1)
  },
});

// Register the component with Video.js, so it can be used in players.
videojs.registerComponent('SeekPrev', SeekPrev);
