import React from 'react';
import styled from 'styled-components';
import { Box, Grid, Icon } from '@material-ui/core';
import { TitleRecommendStyled } from '../style/homepage';

function TitleRecommend(props) {
  const { title, ribbon = {}, prefix = '' } = props;
  return (
    <TitleRecommendStyled>
      <div className="start" xs={3} spacing={2}>
        <span>{prefix || ''}</span>
      </div>
      <div className="tail">
        <div className="center" xs={6} spacing={2} style={{ margin: '0px' }}>
          <span>{title}</span>
        </div>
      </div>
    </TitleRecommendStyled>
  );
}

export default TitleRecommend;
