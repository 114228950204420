import LanguageDetect from 'languagedetect';
import i18n from 'i18next';
import { getConfigByKey } from '~features/tenantConfig/services';

export * from './string';

export const detectLanguageByText = (txt: string) => {
  try {
    const lngDetector = new LanguageDetect();
    lngDetector.setLanguageType('iso2');
    const matches = lngDetector.detect(txt, 10);

    const [lng = ''] =
      matches.find(([lngItem]) => getConfigByKey('locales').includes(lngItem)) || [];

    return lng;
  } catch (error) {
    return i18n.language;
  }
};

export const getClassFont = (txt: string) => {
  return detectLanguageByText(txt) + '-font';
};
