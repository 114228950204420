/* tslint:disable */
/* eslint-disable */
/**
 * ODX/V3 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OdxV1EpisodeListVideoCcLanguagesEnum = exports.OdxV1EpisodeListKindEnum = void 0;
exports.OdxV1EpisodeListKindEnum = {
    series: 'series',
    main: 'main',
    trailer: 'trailer',
    additional: 'additional',
    clip: 'clip',
    unknown_default_open_api: '11184809'
};
exports.OdxV1EpisodeListVideoCcLanguagesEnum = {
    ko: 'ko',
    en: 'en',
    es: 'es',
    zh_Hans: 'zh-Hans',
    zh_Hant: 'zh-Hant',
    zh_hans: 'zh-hans',
    zh_hant: 'zh-hant',
    pt: 'pt',
    unknown_default_open_api: '11184809'
};
