import styled from 'styled-components';
import Select from 'react-select';

const ReactSelectElement = styled(Select)`
  .Select {
    &__control {
      padding: 0 0.5rem 0 0.85rem;
      background: #e5e5e5;
    }
    &__menu {
      box-shadow: 0px 0px 5px 0px #ededed;
      border-radius: 10px;
      z-index: 999;
      background: #e5e5e5;
      margin-top: 0;
      &-list {
        padding: 22px 0;
        &:-webkit-scrollbar {
          width: 6px;
        }
      }
    }
    &__placeholder {
      white-space: nowrap;
      color: #627280;
      font-size: 14px;
    }
    &__indicator-separator {
      display: none;
    }
    &__value-container {
      cursor: text;
    }
    &__single-value {
      color: #627280;
      font-size: 1rem;
      .v-season__active {
        display: none;
      }
    }
    &__option {
      color: #627280;
      cursor: pointer;
      padding: 4px 24px;
      font-size: 14px;
      &--is-selected {
        background: none;
        color: #2574d4;
      }
      &--is-focused {
        background: none;
        color: #627280;
      }
    }
    &__dropdown-indicator {
      i {
        color: #627280;
        transition: transform 0.3s;
      }
    }
    &__control--menu-is-open {
      .Select__dropdown-indicator {
        i {
          transform: rotate(180deg);
        }
      }
    }
  }
  &.v-select-filter-genres {
    .Select__indicator-separator {
      display: none;
    }
    .Select__menu {
      transform: translateX(-50%);
      left: 0;
      width: 90vw;
      .Select__menu-list {
        display: flex;
        flex-wrap: wrap;
        .Select__option {
          width: 33%;
        }
      }
    }
  }
  @media (min-width: 768px) {
    .Select__placeholder {
      font-size: 0.875rem;
    }
    &.v-select-filter-genres {
      .Select__menu {
        transform: translateX(-10%);
        width: 80vw;
      }
    }
  }
  @media (min-width: 992px) {
    &.v-select-filter-genres {
      .Select__menu {
        transform: translateX(0%);
        max-width: 700px;
        .Select__menu-list {
          display: flex;
          flex-wrap: wrap;
          .Select__option {
            width: 25%;
          }
        }
      }
    }
  }
`;

export { ReactSelectElement };
