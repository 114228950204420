import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { getConfigByKey } from '~features/tenantConfig/services';
import { ReactComponent as IconNaver } from '~img/icons/projectw/signin/NaverNew.svg';
import NaverLogin from 'react-naver-login';
import { loginNaver } from '~features/auth/services';
import { loading } from '~features/loadingPage/services';
import { REACT_APP_NAVER_APPLICATION_ID } from '~constants/envConfig';
import { GroupSocial, ButtonW } from './loginButtonStyled';

const LoginNaver = props => {
  const { setResMessage, setAction } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleAuthentication = ({ accessToken }) => {
    dispatch(loading(true));
    dispatch(loginNaver({ accessToken: accessToken.accessToken }, 'naver'))
      .then(res => {})
      .catch(err => {
        setResMessage &&
          setResMessage({
            alert: true,
            message: err.message,
            isLoading: false,
          });
        setAction &&
          setAction({
            isLoading: false,
            resMessage: err.message,
            showAlert: true,
          });
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
  if (!getConfigByKey('features.loginNaver')) return null;
  return (
    <NaverLogin
      clientId={REACT_APP_NAVER_APPLICATION_ID}
      callbackUrl={`https://${getConfigByKey('hostName')}/naver-callback`}
      render={props => (
        <GroupSocial>
          <IconNaver />

          <ButtonW onClick={props.onClick} bsSize="large">
            {t('loginScreen.signIn-Naver')}
          </ButtonW>
        </GroupSocial>
      )}
      onSuccess={handleAuthentication}
      onFailure={error => console.error(error)}
    />
  );
};

const mapDispatchToProps = {};

const mapStateToProps = state => ({
  account: state.auth.account,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginNaver);
