import { PayloadAction } from '@reduxjs/toolkit';
import { SEOBasic } from '~vimai-api-adapter';
import * as types from './actionTypes';

const initialState: SEOBasic = {};

export default function metaTags(state = initialState, action: PayloadAction<SEOBasic>) {
  switch (action.type) {
    case types.UPDATE_SEO:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
}
