import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { Modal, Col, Radio } from 'react-bootstrap';
import { ModalStyled, RowStyled, ColStyled } from '../styles/styled';
import InjectedCheckoutForm from './stripeForm';
import { StripeProvider, Elements } from 'react-stripe-elements';
import * as APIString from '~root/constants/apiString';
import '~features/box/pages/checkoutPage/styles/styles.scss';
import Paypal from './paypal';
import Coupon from './coupon';
import queryString from 'query-string';
import OverlayLoading from '~components/loading/overlayLoading';
import PopupPaypal from '~features/box/pages/popup/popupPaypal';
import * as payperviewService from '../services';
import { getStepPayperview } from '../functions';
import { getConfigByKey } from '../../tenantConfig/services';
import Bootpay from './Bootpay';
import { BoxStyled as FormStyled } from '~components/Input/views/style';
import { tenantEnvConfig } from '~constants/tenantConfig/tenantEnvConfig';
import CloseIcon from '@material-ui/icons/Close';

const PPV_PAYMENT_GATEWAY_PAYPAL = 'paypal';
const PPV_PAYMENT_GATEWAY_STRIP = 'strip';
const PPV_PAYMENT_GATEWAY_BOOTPAY = 'bootpay';

const CheckOut = props => {
  const ppvPaymentPaypal = getConfigByKey('features.ppvPaymentPaypalFeature');
  const ppvPaymentStrip = getConfigByKey('features.ppvPaymentStripFeature');
  const ppvPaymentBootpay = getConfigByKey('features.ppvPaymentBootpayFeature');
  const {
    t,
    hideModal,
    param,
    location,
    paymentMethod,
    state = {},
    postConfirmPaypalPayperview,
    getPaymentMethod,
    setStep,
    history,
    content,
  } = props;
  const paymentMethodGateWay = paymentMethod.map(item => Object.values(item)[0] || []);
  const paymentMethodKorean = paymentMethodGateWay.includes('bootpay');
  const [hide, setHide] = useState(false);
  const [selectMethod, setMethodPayment] = useState(
    ppvPaymentStrip ? PPV_PAYMENT_GATEWAY_STRIP : '',
  );
  const [confirmTokenPaypal, setConfirmTokenPaypal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [payment, setPayment] = useState({});
  const [paypalSuccess, setPaypalSuccess] = useState(false);
  const [checked, setChecked] = useState(false);
  let paramQuery = queryString.parse(location.search, { decode: false });
  let contentId =
    state && state.infoShow
      ? state.infoShow.id
      : (state.entityDetail && state.entityDetail.id) || content.id;
  useEffect(() => {
    if (paramQuery && paramQuery.token) {
      setConfirmTokenPaypal(true);
      setLoading(true);
      setMethodPayment(PPV_PAYMENT_GATEWAY_PAYPAL);
      let data = {
        content_id: contentId,
        order_id: paramQuery.token,
        payment_provider: PPV_PAYMENT_GATEWAY_PAYPAL,
      };
      confirmPaypal(data);
    }
  }, []);

  useEffect(() => {
    paymentMethod_Region();
  }, []);

  const onChangeMethodPayment = (key, e) => {
    setMethodPayment(key);
  };
  const hideModalUpgrade = () => {
    if (isLoading) return;
    setHide(true);
    if (param) {
      return param.method.hideModal();
    }
    hideModal();
  };

  const confirmSuccess = () => {
    setPaypalSuccess(true);
    stepSuccessPopup();
  };

  const confirmFail = () => {
    setConfirmTokenPaypal(true);
    setPaypalSuccess(false);
  };
  const paymentMethod_Region = () => {
    getPaymentMethod()
      .then(response => {
        setPayment(response);
      })
      .catch(() => {});
  };
  const confirmPaypal = paramQuery => {
    postConfirmPaypalPayperview(paramQuery)
      .then(() => {
        setPaypalSuccess(true);
        stepSuccessPopup();
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const stepSuccessPopup = () => {
    setHide(true);
    getStepPayperview(3, props, setStep);
  };
  return (
    <ModalStyled
      show={!hide}
      onHide={() => hideModalUpgrade()}
      aria-labelledby="contained-modal-title-vcenter"
      className="payperview-checkout"
      bsSize="large"
    >
      <Modal.Header>
        <CloseIcon className="payperview-close" onClick={() => hideModalUpgrade()} />
      </Modal.Header>
      <FormStyled>
        <RowStyled className="v-payment">
          <Col sm={12}>
            <h2 className={`v-payment--title`}>{t('subcriptions.titleCheckout')}</h2>
          </Col>
          <Modal.Body>
            <ColStyled sm={12} xs={12} md={6} lg={6} className="v-group v-stripe">
              <p className={`v-payment--title-small`}>{t('subcriptions.txtPayment')}</p>
              <Col sm={12} className="v-stripe--select">
                {ppvPaymentPaypal && (
                  <Radio
                    onChange={() => onChangeMethodPayment(PPV_PAYMENT_GATEWAY_PAYPAL)}
                    value="paypal"
                    checked={selectMethod === PPV_PAYMENT_GATEWAY_PAYPAL}
                  >
                    <p className="v-payment--name">PayPal</p>
                    <div className="v-payment__img">
                      <img
                        alt=""
                        className="v-payment__img--paypal"
                        src={require('~img/img/paypal.png').default}
                      />
                    </div>
                  </Radio>
                )}
                {ppvPaymentStrip && (
                  <Radio
                    onChange={() => onChangeMethodPayment(PPV_PAYMENT_GATEWAY_STRIP)}
                    value="visa"
                    checked={selectMethod === PPV_PAYMENT_GATEWAY_STRIP}
                  >
                    <p className="v-payment--name">{t('subcriptions.Debit/ credit card')}</p>
                    <img alt="" src={require('~img/img/visa.png').default} />
                    <div className="v-payment__img">
                      <img
                        alt=""
                        className="v-payment__img--mastercard"
                        src={require('~img/img/mastercard.png').default}
                      />
                    </div>
                  </Radio>
                )}
                {ppvPaymentBootpay && !paymentMethodKorean ? (
                  <Radio
                    onChange={() => onChangeMethodPayment(PPV_PAYMENT_GATEWAY_BOOTPAY)}
                    value="bootpay"
                    checked={selectMethod === PPV_PAYMENT_GATEWAY_BOOTPAY}
                  >
                    <p className="v-payment--name">bootpay</p>
                    <img
                      className="bootpay-logo"
                      style={{
                        width: '5rem',
                        filter: 'invert(1)',
                        objectFit: 'cover',
                      }}
                      alt=""
                      src={require('~img/img/projectw/bootpay-white.png').default}
                    />
                  </Radio>
                ) : (
                  ppvPaymentBootpay && (
                    <Radio
                      onChange={() => onChangeMethodPayment(PPV_PAYMENT_GATEWAY_PAYPAL)}
                      value="paypal"
                      checked={selectMethod === PPV_PAYMENT_GATEWAY_PAYPAL}
                    >
                      <p className="v-payment--name">PayPal</p>
                      <img className="paypal" alt="" src={require('~img/img/paypal.png').default} />
                    </Radio>
                  )
                )}
              </Col>
              {ppvPaymentStrip && selectMethod === PPV_PAYMENT_GATEWAY_STRIP && (
                <StripeProvider apiKey={APIString.STRIPE_PUBLIC_KEY}>
                  <Elements>
                    <InjectedCheckoutForm
                      {...props}
                      setLoading={setLoading}
                      stepSuccessPopup={stepSuccessPopup}
                    />
                  </Elements>
                </StripeProvider>
              )}
              {ppvPaymentPaypal && selectMethod === PPV_PAYMENT_GATEWAY_PAYPAL && (
                <Paypal
                  {...props}
                  setLoading={setLoading}
                  couponCode
                  setStatePolicy={{ setChecked, checked }}
                />
              )}
              {ppvPaymentBootpay &&
              !paymentMethodKorean &&
              selectMethod === PPV_PAYMENT_GATEWAY_BOOTPAY ? (
                <Bootpay
                  {...props}
                  setStatePolicy={{ setChecked, checked }}
                  confirmSuccess={confirmSuccess}
                  setLoading={setLoading}
                  confirmFail={confirmFail}
                />
              ) : ppvPaymentBootpay && selectMethod === PPV_PAYMENT_GATEWAY_PAYPAL ? (
                <Paypal
                  {...props}
                  setLoading={setLoading}
                  couponCode
                  setStatePolicy={{ setChecked, checked }}
                />
              ) : null}
            </ColStyled>
            <Col sm={12} xs={12} md={6} lg={6}>
              <Coupon
                {...props}
                showCoupon={
                  selectMethod &&
                  tenantEnvConfig.ui_theme !== 'odv' &&
                  tenantEnvConfig.ui_theme !== 'escondido' &&
                  selectMethod !== PPV_PAYMENT_GATEWAY_BOOTPAY
                }
              />
            </Col>
          </Modal.Body>
        </RowStyled>
      </FormStyled>
      {isLoading && <OverlayLoading />}
      {!isLoading && confirmTokenPaypal ? (
        <PopupPaypal
          component={this}
          open={confirmTokenPaypal}
          success={paypalSuccess}
          pg={selectMethod}
          ppv
        />
      ) : null}
    </ModalStyled>
  );
};

const mapStateToProps = state => ({
  account: state.auth.account,
  paymentMethod: state.payperview.paymentMethod,
});

const mapDispatchToProps = {
  postConfirmPaypalPayperview: params => payperviewService.postConfirmPaypalPayperview(params),
  getPaymentMethod: payload => payperviewService.getPaymentMethod(payload),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CheckOut)),
);
