import ReportContents from '../components/reportContents';
import ReactDOM from 'react-dom';
import React from 'react';
import videojs from 'video.js';
const vjsComponent = videojs.getComponent('Component');

class Reports extends vjsComponent {
  constructor(player, options) {
    super(player, options);

    /* Bind the current class context to the mount method */
    this.mount = this.mount.bind(this);

    /* When player is ready, call method to mount React component */
    player.on('ready', () => {
      // if (!($player.hasClass('vjs-live') || $player.hasClass('vjs-liveui'))) {
      //   return
      // }
      this.mount();
    });

    /* Remove React root when component is destroyed */
    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }

  createEl() {
    const btn = videojs.dom.createEl('div', {
      className: 'vjs-report-display',
      id: 'vjs-report-display',
    });
    return btn;
  }

  /**
   * We will render out the React EpisodeList component into the DOM element
   * generated automatically by the VideoJS createEl() method.
   *
   * We fetch that generated element using `this.el()`, a method provided by the
   * vjsComponent class that this class is extending.
   */
  mount() {
    const { component } = this.options_;
    ReactDOM.render(<ReportContents player={this.player_} Player={component} />, this.el());
  }
}

/**
 * Make sure to register the vjsComponent so Video JS knows it exists
 */
vjsComponent.registerComponent('Reports', Reports);

export default Reports;
