import React from 'react';
import { Transition } from 'react-transition-group';
import Skeleton from 'react-loading-skeleton';
import LazyLoad from 'react-lazyload';
import classnames from 'classnames';
import { isNode } from '~utils/ssr';

const Image = React.memo(props => {
  const { sourceURL, lazyLoad, imgDefault, displayTypeStr } = props;
  const imgUrl = sourceURL[displayTypeStr] || sourceURL.thumbnail || imgDefault;

  const loading = () => {
    if (isNode()) {
      return (
        <div className="loading-placeholder">
          <img src={imgUrl} loading="lazy" />
        </div>
      );
    }
    return (
      <div className="loading-placeholder">
        <Skeleton />
      </div>
    );
  };

  if (!imgUrl) return <div className={classnames('srcImg', 'srcImg--noImg')}></div>;
  if (lazyLoad) {
    return (
      <LazyLoad offset={400} once placeholder={loading()}>
        <Transition timeout={400}>
          <img className={classnames('srcImg')} src={imgUrl} />
        </Transition>
      </LazyLoad>
    );
  }
  return <img className={classnames('srcImg')} src={imgUrl} />;
});

export default Image;
