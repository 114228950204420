import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import { ModalStyled } from './styles/styled';
import { getProfile } from '../../../profile/services';
import CloseIcon from '@material-ui/icons/Close';

const ButtonSuccess = props => {
  const { success, db_getProfile, t, language, history, ppv } = props.data;
  const { setHide } = props;
  useEffect(() => {
    if (success) {
      db_getProfile();
    }
  }, []);
  const retry = () => {
    const $link = window.jQuery('#linkPaypal');
    $link.trigger('click');
    setHide(true);
  };

  const backHome = () => {
    if (!ppv) return history.push('/');
  };

  if (success) {
    return (
      <Link to={`/${language}/`} style={{ display: 'flex', justifyContent: 'center' }}>
        <Button className="popup-paypal__button--btn popup-paypal__button--success">
          {t('subcriptions.paypal.btnBackHomePage')}
        </Button>
      </Link>
    );
  }
  return (
    <>
      <Button className="popup-paypal__button--btn" onClick={() => retry()}>
        {t('subcriptions.paypal.btnTryAgain')}
      </Button>
      <Link to={`/${language}/`}>
        <Button className="popup-paypal__button--btn popup-paypal__button--back">
          {t('subcriptions.paypal.btnBackHomePage')}
        </Button>
      </Link>
    </>
  );
};
const PopupPaypal = props => {
  const { open, success, t, history, pg, language } = props;
  const [hide, setHide] = useState(true);

  useEffect(() => {
    if (open) {
      setHide(false);
    }
  }, []);

  const hidenPopup = () => {
    setHide(true);
  };

  const checkSuccess = () => {
    if (success) {
      return `/${language}/`;
    }
  };
  return (
    <ModalStyled
      show={!hide}
      onHide={() => hidenPopup()}
      aria-labelledby="contained-modal-title-vcenter"
      className="popup-paypal"
    >
      <Modal.Body>
        <div className="popup-paypal__container">
          <Link to={checkSuccess} onClick={hidenPopup}>
            <CloseIcon className="popup-paypal__close" onClick={() => hidenPopup()} />
          </Link>
          <div className="popup-paypal__image">
            {pg == 'bootpay' && (
              <img src={require('~img/img/projectw/bootpay-white.png').default} />
            )}
            {pg == 'paypal' && (
              <img src={require('./../../../../assets/img/odv/paypal.png').default.default} />
            )}
          </div>
          <div className="popup-paypal__description">
            <p className="popup-paypal__description--title">
              {!success
                ? `${t('subcriptions.paypal.txtFail')}`
                : `${t('subcriptions.paypal.txtSuccess')}`}
            </p>
            <p className="popup-paypal__description--subtitle">
              {!success
                ? `${t('subcriptions.paypal.txtFail2')}`
                : `${t('subcriptions.paypal.txtSuccess2')}`}
            </p>
          </div>
          <div className="popup-paypal__button">
            <ButtonSuccess data={props} setHide={setHide} />
          </div>
        </div>
      </Modal.Body>
    </ModalStyled>
  );
};

const mapStateToProps = state => ({
  language: state.root.language,
});

const mapDispatchToProps = {
  db_getProfile: () => getProfile(),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PopupPaypal)),
);
