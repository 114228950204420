import React, { useState, useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const GroupButtonReplay = React.memo(props => {
  const {
    player,
    livePrev,
    seekPrev,
    seekNext,
    liveNext,
    handlePlay,
    type,
    epsPrev,
    epsNext,
    component,
  } = props;

  const { t } = component.props;
  const [isPlaying, setPlay] = useState(false);

  React.useEffect(() => {
    player.on('pause', function () {
      setPlay(false);
    });
    player.on('play', function () {
      setPlay(true);
    });
  }, []);

  const handleClickPlay = () => {
    setPlay(!isPlaying);
    handlePlay();
  };

  const CustomiseTooltip = withStyles({
    tooltip: {
      color: 'lightblue',
      backgroundColor: 'unset',
      marginTop: 5,
      fontSize: 18,
      color: '#d1d1d1',
      textTransform: 'capitalize',
    },
  })(Tooltip);
  return (
    <ul className="vjs-control-bar-replay">
      <CustomiseTooltip title={t('player.controlBar.txtPrevious')} placement="Bottom">
        <li
          className={classnames(
            'vjs--selector',
            type === 'movie' && 'hide',
            type === 'show' && 'vjs--selector__prev',
          )}
          onClick={type === 'show' ? epsPrev : livePrev}
        >
          <div className="vjs--selector__icons">
            <SkipPreviousIcon />
          </div>
        </li>
      </CustomiseTooltip>
      <CustomiseTooltip title={t('player.controlBar.txt30sAgo')} placement="Bottom">
        <li className="vjs--selector" onClick={seekPrev}>
          <div className="vjs--selector__icons">
            <RotateLeftIcon />
          </div>
        </li>
      </CustomiseTooltip>
      <CustomiseTooltip
        title={isPlaying ? t('player.controlBar.txtPause') : t('player.controlBar.txtPlay')}
        placement="Bottom"
      >
        <li className="vjs--selector" onClick={() => handleClickPlay()}>
          <div className="vjs--selector__icons">
            {!isPlaying ? <PlayArrowIcon /> : <PauseIcon />}
          </div>
        </li>
      </CustomiseTooltip>
      <CustomiseTooltip title={t('player.controlBar.txt30sLater')} placement="Bottom">
        <li className="vjs--selector" onClick={seekNext}>
          <div className="vjs--selector__icons">
            <RotateRightIcon />
          </div>
        </li>
      </CustomiseTooltip>
      <CustomiseTooltip title={t('player.controlBar.txtNext')} placement="Bottom">
        <li
          className={classnames(
            'vjs--selector',
            type === 'movie' && 'hide',
            type === 'show' && 'vjs--selector__next',
          )}
          onClick={type === 'show' ? epsNext : liveNext}
        >
          <div className="vjs--selector__icons">
            <SkipNextIcon />
          </div>
        </li>
      </CustomiseTooltip>
    </ul>
  );
});

export default GroupButtonReplay;
