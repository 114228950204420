/* tslint:disable */
/* eslint-disable */
/**
 * ODX/V3 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecurlyCouponRetrieve200ResponseAllOfResultFreeTrialUnitEnum = exports.RecurlyCouponRetrieve200ResponseAllOfResultDiscountTypeEnum = exports.RecurlyCouponRetrieve200ResponseAllOfResultTemporalUnitEnum = exports.RecurlyCouponRetrieve200ResponseAllOfResultDurationEnum = void 0;
exports.RecurlyCouponRetrieve200ResponseAllOfResultDurationEnum = {
    forever: 'forever',
    single_use: 'single_use',
    temporal: 'temporal',
    unknown_default_open_api: '11184809'
};
exports.RecurlyCouponRetrieve200ResponseAllOfResultTemporalUnitEnum = {
    day: 'day',
    month: 'month',
    week: 'week',
    year: 'year',
    unknown_default_open_api: '11184809'
};
exports.RecurlyCouponRetrieve200ResponseAllOfResultDiscountTypeEnum = {
    fixed: 'fixed',
    percent: 'percent',
    free_trial: 'free_trial',
    unknown_default_open_api: '11184809'
};
exports.RecurlyCouponRetrieve200ResponseAllOfResultFreeTrialUnitEnum = {
    day: 'day',
    month: 'month',
    week: 'week',
    unknown_default_open_api: '11184809'
};
