import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as display from './../../constants';
import pushAnalytics from './../../../../services/analytics/Analytics';
import { updateRibbonTarget } from '../../../../features/homepage/services';
import Ribbons from './../../styled';
import { getConfigByKey } from '~features/tenantConfig/services';
import { getTenantConfig, contentTypes } from '../../../../utils/utils';
import classnames from 'classnames';
import { getTimePaidContent } from '../../functions/getTimePaidContent';
import TimePaid from '../timePaid';
import Type from './type';
import Name from './name';
import TopContents from './topContents';
import Image from './image';
import NewRelase from './newRelease';
import { getLinkToPlayer } from '~features/homepage/functions';
import { withRouter } from 'react-router';

class Contents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _renderEpName() {
    const tenantConfig = getTenantConfig(getConfigByKey('tenant_slug'));
    const { t, index, epNameVisible, item } = this.props;
    if (tenantConfig && tenantConfig.RIBBON_RENDER_EP_NAME && epNameVisible) {
      return <div style={{ color: '#959596' }}>{`${t('video.eps')} ${item.episode}`}</div>;
    }
  }

  handleClick = async (e, timePaidContents) => {
    const {
      ribbon,
      index,
      contentType,
      dpUpdateRibbonTarget,
      language,
      history,
      item,
      pageNavigation,
      global,
      linkTo,
      isHasSubcription,
      blockUrl,
    } = this.props;
    const { type, id, slug, has_free_content, payment_type } = item;

    if (blockUrl) {
      e.preventDefault();
    }
    if (!ribbon) {
      return;
    }
    if (item.bannerExtra) {
      e.preventDefault();
      const newWindow = window.open(
        'https://www.contentpromo.ondemandkorea.com/odv-tundra-vi',
        '_blank',
        'noopener,noreferrer',
      );
      if (newWindow) newWindow.opener = null;
      return;
    }

    pushAnalytics('select_content', {
      content_type: contentType,
      item_id: slug,
      widget: ribbon.slug,
      order: index + 1,
    });
    dpUpdateRibbonTarget(ribbon.id);
    if (
      global.page.contentNavigation !== 'default' &&
      pageNavigation &&
      type === display.SHOW_TYPE &&
      (has_free_content || timePaidContents)
    ) {
      e.preventDefault();
      let isCanPlay =
        (payment_type === display.TVOD && timePaidContents) ||
        (payment_type === display.SVOD && (isHasSubcription || timePaidContents)) ||
        false;
      const obj = await getLinkToPlayer(id, null, isCanPlay);
      if (!obj) {
        return history.push({
          pathname: `/${language}${linkTo}`,
        });
      }
      let search = obj.search;
      let linkPlay = obj.linkPlay;
      return history.push({
        pathname: `/${language}${linkPlay}`,
        search: search,
        state: { navigation: 'home' },
      });
    }
  };

  render() {
    const {
      linkTo,
      item,
      ribbon,
      index,
      displayTypeStr,
      trailer,
      paidContents,
      language,
      backtoHome,
    } = this.props;
    const { type, id, is_premium, slug } = item;
    const tenantConfig = getTenantConfig(getConfigByKey('tenant_slug'));
    const ids = item.type === display.EPISODE_TYPE ? ribbon.id : id;
    const timePaidContents = getTimePaidContent(paidContents, ids);
    return (
      <Ribbons
        className={classnames(
          'ribbonItemContainer',
          tenantConfig && tenantConfig.POSTER_SIZE,
          `${display[`DISPLAY_${ribbon ? ribbon.type : 1}`]}`,
          is_premium ? 'contentSvod' : 'contentAvod',
        )}
        data-testid={`ribbon-detail-${index}`}
        data-type={contentTypes(type)}
        style={{ position: 'relative' }}
      >
        <div className={classnames('imageWrapper', displayTypeStr)}>
          <NewRelase {...this.props} />
          <Link
            to={
              linkTo
                ? {
                    pathname: `/${language}${linkTo}`,
                    state: {
                      type: type,
                      navigation: backtoHome ? 'home' : null,
                    },
                    search: trailer && `?trailer=${slug}`,
                  }
                : '#'
            }
            onClick={async e => this.handleClick(e, timePaidContents)}
          >
            <Image {...this.props} />
            <TopContents {...this.props} />
            <Type timePaidContents={timePaidContents} {...this.props} />
          </Link>
        </div>
        {this._renderEpName()}
        <Name {...this.props} />
        {item.type !== display.EPISODE_TYPE && timePaidContents && (
          <TimePaid timePaidContents={timePaidContents} />
        )}
      </Ribbons>
    );
  }
}

const mapStateToProps = state => ({
  account: state.auth.account,
  language: state.root.language,
  paidContents: state.auth.paidContents,
  global: state.global,
  isHasSubcription: state.auth.isHasSubcription,
});

const mapDispatchToProps = {
  dpUpdateRibbonTarget: id => updateRibbonTarget(id),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Contents)),
);
