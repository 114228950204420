import React from 'react';
import RibbonItem from './ribbonItem';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { RibbonContainer } from './styled';
import homePageId from '../../../../constants/ids/homePageId';
import { withTranslation } from 'react-i18next';
import pushAnalytics from '../../../../services/analytics/Analytics';
import { updateRibbonTarget } from '../../../../features/homepage/services';
import { connect } from 'react-redux';
import * as display from '../../constants';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import queryString from 'query-string';
import { settingSlide } from '../../functions/initSlide';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getRibbonDetail } from '../../services';

class RibbonEscondido extends React.Component {
  constructor(props) {
    super(props);
    this.sliderRef = React.createRef();
    this.state = {
      viewMore: 24,
      ribbonItems: [],
      page: 1,
      hasMoreItems: true,
    };
  }

  componentWillMount() {
    if (isMobile) {
      this.setState({
        viewMore: 15,
      });
    }
  }

  componentDidMount() {
    const { ribbon } = this.props;
    this.setState({
      ribbonItems: ribbon.items || [],
    });
  }

  componentWillReceiveProps(nextProps) {
    const { ribbon } = nextProps;
    if (ribbon.id !== this.props.ribbon.id) {
      this.setState({
        ribbonItems: ribbon.items || [],
      });
    }
  }

  handleLazyLoad(curentSilde = null) {
    const { dpGetRibbonDetail, ribbon } = this.props;
    const { ribbonItems, page, hasMoreItems, viewMore } = this.state;
    let paramQuery = queryString.parse(this.props.search, { decode: false });
    const { slug } = paramQuery;
    let search = `?${this.props.search}&page=${page}`;
    const { innerSlider } = this.sliderRef.current;
    const total = ribbonItems.length;
    const slideScroll = innerSlider.props.slidesToScroll;
    let restSilde = total - (slideScroll + curentSilde);
    if (!hasMoreItems || restSilde > 3) {
      return;
    }

    dpGetRibbonDetail(slug, ribbon.slug, search)
      .then(res => {
        const data = ribbonItems;
        data.push(...res.items);
        if (res.items.length <= 0 || data.length >= viewMore) {
          return this.setState({
            hasMoreItems: false,
          });
        }
        let timer = setTimeout(() => {
          this.setState({
            ribbonItems: data,
            page: page + 1,
          });
          clearTimeout(timer);
        }, 500);
      })
      .catch(() => {})
      .finally(() => {});
  }

  handleRemoveRecent(data) {
    const { ribbonItems } = this.state;
    let index = ribbonItems.findIndex(item => item.id === data.items.id);
    ribbonItems.splice(index, 1);
    this.setState({
      ribbonItems: ribbonItems,
    });
  }

  handleActiveRibbon(slidesToShow) {
    const { ribbon } = this.props;
    /**
     * Remove the start and the last active slide in ribbon
     */
    const ribbonIdQuery = `#ribbon--${ribbon.id}`;
    document
      .querySelectorAll(`${ribbonIdQuery} .slick-slide .ribbonItemContainer`)
      .forEach(item => {
        item.classList.remove('start-item');
        item.classList.remove('last-item');
      });

    /**
     * Find and define the new start and last active slide in ribbon
     */
    const activeItems = document.querySelectorAll(`${ribbonIdQuery} .slick-active`);
    const lastItem = activeItems[activeItems.length - 1];
    lastItem.querySelector('.ribbonItemContainer').classList.add('last-item');

    let firstItem = lastItem;
    for (let i = 1; i < slidesToShow; i++) {
      firstItem = firstItem.previousSibling;
    }
    firstItem && firstItem.querySelector('.ribbonItemContainer').classList.add('start-item');
  }

  _renderRibbonItem(ribbon, ribbonIndex, slideToShow) {
    const { ribbonItems, viewMore } = this.state;
    return ribbonItems
      .slice(0, viewMore)
      .map((item, index) => (
        <RibbonItem
          item={item}
          ribbon={ribbon}
          ribbonIndex={ribbonIndex}
          index={index}
          key={index}
          slideToShow={slideToShow}
          ribbonItems
          handleRemoveRecent={v => this.handleRemoveRecent(v)}
        />
      ));
  }

  _renderHead() {
    const { dpUpdateRibbonTarget, isAllButtonDisplay, t, ribbon, search, title, index, language } =
      this.props;
    const ribbonItems = ribbon.items || [];
    let paramQuery = queryString.parse(search, { decode: false });

    return (
      <div className="ribbonHead">
        <h4 data-testid={`${homePageId.ribbonId.titleLbl}-${index}`} className="title">
          {title}
        </h4>
        {isAllButtonDisplay && ribbonItems.length > 0 && (
          <Link
            data-testid={`${homePageId.ribbonId.showAllBtn}-${index}`}
            className="viewAll"
            to={{
              pathname: `/${language}/category/${ribbon.slug}`,
              search: `tenants=${paramQuery.slug}&${search || ''}`,
              state: { ribbon: paramQuery.slug },
            }}
            onClick={() => {
              pushAnalytics('click', {
                content_type: 'button',
                item_name: 'See all',
                widget: ribbon.slug,
              });
              window.scrollTo(0, 0);
              dpUpdateRibbonTarget(ribbon.id);
            }}
          >
            <h5 className="title_action">{t('ribbon.txtViewMore')}</h5>
          </Link>
        )}
      </div>
    );
  }

  render() {
    const { index, ribbon } = this.props;
    const sliderSettings = settingSlide(this);
    const ribbonItems = ribbon.items || [];
    if (!ribbonItems.length) return null;
    return (
      <StyledRibbons
        className="ribbonContainer"
        data-test={`${display[`DISPLAY_${ribbon.type || 1}`]}`}
        id={'ribbon--' + ribbon.id}
      >
        {this._renderHead(index)}
        <div className={`sliderBlock slider-${display[`CLASS_${ribbon.display_type || 1}`]}`}>
          <Slider
            {...sliderSettings}
            ref={this.sliderRef}
            afterChange={() => this.handleActiveRibbon(sliderSettings.slidesToShow)}
          >
            {this._renderRibbonItem(ribbon, index, sliderSettings.slidesToShow)}
          </Slider>
        </div>
      </StyledRibbons>
    );
  }
}

const StyledRibbons = styled(RibbonContainer)`
  margin-bottom: 40px;
  .slick-list {
    overflow: unset;
  }
  @media (min-width: 992px) {
    margin-bottom: 48px;
  }
`;

RibbonEscondido.defaultProps = {
  title: '',
  ribbon: [],
  isAllButtonDisplay: true,
  onPressViewAll: () => {},
};

const mapStateToProps = state => ({
  account: state.auth.account,
  language: state.root.language,
});

const mapDispatchToProps = {
  dpUpdateRibbonTarget: id => updateRibbonTarget(id),
  dpGetRibbonDetail: (pageSlug, ribbonSlug, search) =>
    getRibbonDetail(pageSlug, ribbonSlug, search),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RibbonEscondido)),
);
