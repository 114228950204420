import React, { useState, useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Popover from '@material-ui/core/Popover';
import { IonIcon } from '@ionic/react';
import { albums } from 'ionicons/icons';
import { getConfigByKey } from '~features/tenantConfig/services';
import jquery from 'jquery';

const Episodes = ({ item, entityDetail, component, handleClickEps }) => {
  const { account, t } = component.props;
  const { entityTrailer, isHasSubcription } = component.state;
  let vipbage = 'isVip';
  let active = 'deactive';
  if (
    isHasSubcription ||
    item.can_preview ||
    item.is_watchable ||
    (account && account.profile.has_subscription)
  ) {
    vipbage = 'notVip';
  }
  if (
    (item.trailer && entityTrailer && entityTrailer.id === item.id) ||
    (!entityTrailer && entityDetail.id === item.id)
  ) {
    active = 'active';
  }

  const isSctvTenant = getConfigByKey('tenant_slug') === 'sctv';
  const title = isSctvTenant
    ? t('laddingPage.episodes', { episodes: item.episode })
    : item.title || t('laddingPage.episodes', { episodes: item.episode });
  return (
    <button
      className={`vjs-control vjs-button vjs-list-eps ${active} `}
      id={item.id}
      onClick={() => handleClickEps(item.id)}
    >
      <div className="vjs-list-container">
        <span className="vjs-list-title-eps">{title}</span>
        <div className="vjs-list-vipbage">
          <span className={vipbage}>
            <i className="fa fa-star"></i> Vip
          </span>
          <i className="fa fa-check" aria-hidden="true"></i>
        </div>
      </div>
    </button>
  );
};

const ButtonEpisode = React.memo(props => {
  const { episodes, component, handleClickEps, status } = props;
  const { entityDetail } = component;
  const { t } = component.props;

  const containerRef = useRef(null);

  const CustomiseTooltip = withStyles({
    tooltip: {
      backgroundColor: 'unset',
      marginTop: 15,
      fontSize: 18,
      color: '#d1d1d1',
      textTransform: 'capitalize',
    },
  })(Tooltip);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  if (status === 'live' || (episodes && !episodes.length) || !episodes) return null;
  return (
    <React.Fragment>
      <li
        className="vjs--selector"
        id="subtitles"
        type="button"
        data-state="subtitles"
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        ref={containerRef}
      >
        <CustomiseTooltip title={t('player.controlBar.txtMoreEpisodes')} placement="Bottom">
          <div className="vjs--selector__icons">
            <IonIcon type="svg" data-toggle="dropdown" icon={albums} />
          </div>
        </CustomiseTooltip>
      </li>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        container={containerRef.current}
      >
        <div className="vjs--escondido-wrapEpisodes">
          {episodes.map((item, index) => {
            return (
              <Episodes
                item={item}
                entityDetail={entityDetail}
                component={component}
                index={index}
                handleClickEps={handleClickEps}
              />
            );
          })}
        </div>
      </Popover>
    </React.Fragment>
  );
});

export default ButtonEpisode;
