import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PageContentFilter } from '~vimai-api-adapter';

interface IFilter {
  filterData: PageContentFilter[];
  isOpen: boolean;
  activeIDs: {
    [key: string]: {
      [key: string]: boolean;
    };
  };
}
const initialState: IFilter = {
  filterData: [],
  isOpen: false,
  activeIDs: {},
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    resetFilter(state) {
      state = {
        filterData: [],
        isOpen: false,
        activeIDs: {},
      };
    },
    setFilterData(state, action: PayloadAction<IFilter['filterData']>) {
      const filterData = action.payload;
      state.filterData = filterData;
    },
    setActiveIDs(state, action: PayloadAction<IFilter['activeIDs']>) {
      state.activeIDs = {
        ...state.activeIDs,
        ...action.payload,
      };
    },
    resetActiveIDs(state) {
      state.activeIDs = {};
    },
  },
});

export const { setFilterData, setActiveIDs, resetActiveIDs } = filterSlice.actions;

export default filterSlice.reducer;
