Object.defineProperty(exports, "__esModule", { value: true });
exports.CombineNavigationToPlatformConfigs = void 0;
var multitenancy_parser_1 = require("./multitenancy-parser");
var CombineNavigationToPlatformConfigs = function (navigationResult, clientResult) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var tenantPages = (0, multitenancy_parser_1.ParseListMenuItemDetail)(navigationResult);
    var resources = [];
    var promotionImage = (_d = (_c = (_b = (_a = clientResult === null || clientResult === void 0 ? void 0 : clientResult.splash_screen) === null || _a === void 0 ? void 0 : _a.after_splash) === null || _b === void 0 ? void 0 : _b.find(function (s) { return s.screen_type === 'promotion'; })) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.landscape;
    if (!promotionImage) {
        promotionImage = (_f = (_e = clientResult === null || clientResult === void 0 ? void 0 : clientResult.splash_screen) === null || _e === void 0 ? void 0 : _e.splash) === null || _f === void 0 ? void 0 : _f.find(function (s) { return s.landscape !== ''; });
    }
    if (promotionImage) {
        resources.push({
            type: 'image-promotion',
            url: promotionImage,
        });
    }
    var settings = {};
    // @ts-ignore
    var configs = (_g = clientResult === null || clientResult === void 0 ? void 0 : clientResult.client_config) === null || _g === void 0 ? void 0 : _g.app_config;
    if (configs) {
        for (var key in configs) {
            if (configs.hasOwnProperty(key)) {
                settings[key] = (_h = configs[key]) === null || _h === void 0 ? void 0 : _h.value;
            }
        }
    }
    return {
        ads_banners: [],
        account_benefit_intro: [],
        app_icon_url: '',
        app_logo_url: '',
        content_seo_template: {},
        curated_channel_max_playback_days: 14,
        curated_channel_max_preview_days: 7,
        default_locale: 'ko',
        id: 'odx-unknown-platform',
        live_navigate_secs: 0,
        locales: ['ko', 'en'],
        tenant_pages: tenantPages,
        resources: resources,
        settings: settings,
    };
};
exports.CombineNavigationToPlatformConfigs = CombineNavigationToPlatformConfigs;
