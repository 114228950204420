import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getConfigByKey } from '~features/tenantConfig/services';

const BlockCountryWStyled = styled.div`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 40px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  flex-direction: column;
  justify-content: center;

  color: #ffffff;
  height: calc(100vh - 85px);
  .small-title,
  .big-title {
    margin: 0;
  }

  .small-title {
    font-size: 33px;
    margin-bottom: 10px;
    font-weight: 400;
  }
  .big-title {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  .contract {
    font-size: 22px;
    line-height: 26px;
  }
  .email {
    a {
      color: #00a3ff;
      &:hover {
        text-decoration: none;
      }
    }
  }
  @media (max-width: 767px) {
    height: calc(100vh - 45px);
  }
`;
function BlockCountryW() {
  const { t } = useTranslation();
  return (
    <BlockCountryWStyled className="block-conutry">
      <h4 className="small-title">{t('projectw__BlockCountry.smallTitle')}</h4>
      <h2 className="big-title">{t('projectw__BlockCountry.bigTitle')}</h2>
      <p className="contract">
        {t('projectw__BlockCountry.contact')}:{' '}
        <span className="email">
          <a href={`mailto:${getConfigByKey('email')}`}>{getConfigByKey('email')}</a>
        </span>
      </p>
    </BlockCountryWStyled>
  );
}

export default BlockCountryW;
