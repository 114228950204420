import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ChatContainer } from '@chatscope/chat-ui-kit-react';

const ContainerChatLiveStyled = styled(ChatContainer)`
  && {
    width: 100%;
    height: -webkit-fill-available;
    display: flex;
    flex-grow: 1;

    background-color: transparent;
    /* box-shadow: inset 1px 0px 0px rgba(51, 51, 51, 0.82); */
    @media (max-width: 768px) {
      height: unset;
    }
    .cs-message {
      max-width: 100%;
    }
    .cs-message-list,
    .cs-message-input,
    .cs-message-input__content-editor,
    .cs-message-input__content-editor-wrapper,
    .cs-message-input__content-editor-container {
      background-color: transparent;
      border: none;
    }
    .ps__rail-y {
      display: none;
    }
    .cs-message-list .scrollbar-container {
      position: absolute;
    }
    .cs-message-list__scroll-wrapper {
      padding: 0;
    }
    .cs-message-list {
      /* max-height: calc(100% - 41px); */
    }

    .cs-message-input .cs-message-input__content-editor-wrapper:first-child {
      margin-left: 0;
    }

    .cs-message-input__content-editor-wrapper {
      padding: 8px 10px;
      margin: 0;
    }
    .cs-message-input {
      background-color: #3a3a3c;
      border-radius: 6px;
      padding: 0;
      margin-top: 0;
      padding-right: 6px;
      margin: 8px;
      &.cs-message-input--disabled {
        .cs-message-input__content-editor-container,
        .cs-button--send {
          color: #8e8e93;
        }
        .cs-message-input__tools .cs-button--send,
        .cs-message-input__tools .cs-button--send:disabled,
        .cs-message-input__content-editor-wrapper {
          background-color: #3a3a3c;
        }
        .cs-button--send {
          border: 1px solid #2c2c2e;
        }
      }
    }

    .cs-message-input__content-editor {
      font-family: 'Neue Haas Grotesk Display Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 133.5%;
      /* or 21px */

      letter-spacing: 0.025em;

      /* Dark/gray/1 */

      color: #fff;
    }
    .cs-message-input__content-editor[data-placeholder]:empty:before {
      color: #8e8e93;
    }

    .cs-message__content-wrapper {
      flex-direction: row;
      width: 100%;
      display: block;
      position: relative;
      .cs-message__content {
        background-color: transparent;
        font-family: 'Neue Haas Grotesk Display Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 133.5%;
        /* or 21px */

        letter-spacing: 0.025em;
        padding: 0;
        padding-left: 17px;
      }
      .cs-message__custom-content {
        width: 100%;
        position: relative;
        font-family: 'Neue Haas Grotesk Display Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 133.5%;
        /* or 21px */

        letter-spacing: 0.025em;

        color: #ffffff;
        .ms-sender-name {
        }
        .ms-sender-message {
          color: #ffffff;
        }
        .resend {
          position: absolute;
          bottom: 0;
          right: 0;
          padding: 0;
          display: none;
        }
      }
    }
    .cs-message {
      margin-top: 18px;
      &.error {
        .cs-message__content-wrapper {
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 5px;
            height: 100%;
            width: 3px;
            background: #ff1d1d;
            border-radius: 3px;
          }
          .resend {
            display: inline-block;
          }
        }
      }
    }
    .cs-message-list__scroll-wrapper > .cs-message:nth-last-child(4) {
      margin-bottom: unset;
    }
    .cs-message-input__tools .cs-button--send {
      font-family: 'Neue Haas Grotesk Display Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 143.5%;
      letter-spacing: 0.015em;
      background: #000;
      border-radius: 6px;
      padding: 6px;
      width: 100%;
      height: 30px;
      color: #f1edea;
      align-self: center;
      transition: background 0.25s;
      &:disabled {
        opacity: 1;
        background: #2c2c2e;
      }
    }
    @media (max-width: 768px) {
      .cs-message-input__tools .cs-button--send {
        line-height: 0;
      }
    }
  }
`;

function ContainerChatLive(props) {
  return <ContainerChatLiveStyled {...props} />;
}

export default ContainerChatLive;
