import Recommend from '../components/recommendation';
import ReactDOM from 'react-dom';
import React from 'react';
import * as types from './../constants';
import videojs from 'video.js';
import jquery from 'jquery';

const vjsComponent = videojs.getComponent('Component');
class Recommendation extends vjsComponent {
  constructor(player, options) {
    super(player, options);

    /* Bind the current class context to the mount method */
    this.mount = this.mount.bind(this);

    /* When player is ready, call method to mount React component */
    player.on('loadeddata', () => {
      this.mount(options.channelId);
    });
    if (options.component.state.isLive) {
      return;
    }
    // player.on('seeked', () => {
    //   $player.find('.vjs-recommend-display').removeClass('vjs-recommend-open')
    // })
    /* Remove React root when component is destroyed */
    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }

  createEl() {
    const btn = videojs.dom.createEl('div', {
      className: 'vjs-recommend-display',
      id: 'vjs-recommend-display',
    });
    return btn;
  }
  mount(channelId) {
    const { component, episodes } = this.options_;
    const type = component.entityDetail.type;
    const $this = jquery(this.el());
    const parentControlbar = $this.parent();
    const active = parentControlbar.find('.vjs-list-eps.active');
    const indexEpisode = parseInt(active.attr('data-index')) + 1;
    let isLive = false;
    if (type === types.CURATED_LIVE || type === types.CHANNEL_TYPE) {
      isLive = true;
    }
    if (episodes && indexEpisode < episodes.length) {
      return;
    }
    if (!channelId || isLive) {
      return;
    }
    ReactDOM.render(
      <Recommend channelId={channelId} player={this.player_} component={component} />,
      this.el(),
    );
  }
}

/**
 * Make sure to register the vjsComponent so Video JS knows it exists
 */
vjsComponent.registerComponent('Recommendation', Recommendation);

export default Recommendation;
