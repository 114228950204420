import videojs from 'video.js';
import jquery from 'jquery';
import { tenantEnvConfig } from '~constants/tenantConfig/tenantEnvConfig';

// Get the Component base class from Video.js
var Button = videojs.getComponent('Button');

// The videojs.extend function is used to assist with inheritance. In
// an ES6 environment, `class SeekNext extends Component` would work
// identically.

var EPG = videojs.extend(Button, {
  // The constructor of a component receives two arguments: the
  // player it will be associated with and an object of options.
  constructor: function (player, options) {
    // It is important to invoke the superclass before anything else,
    // to get all the features of components out of the box!
    Button.apply(this, arguments);
    // If a `text` option was passed in, update the text content of
    // the component.
    const $player = jquery(this.player_.el_);
    this.interval = null;
    this.isShow = false;
    const { account } = this.options_.component.props;
    player.one('loadeddata', () => {
      if (!($player.hasClass('vjs-live') || $player.hasClass('vjs-liveui'))) {
        return;
      }
      if (tenantEnvConfig.ui_theme !== 'escondido') {
        clearInterval(this.interval);
        this.hadleOpenEpg();
      }
    });

    player.on('useractive', () => {
      // this.handleUserActive()
    });
    player.on('userinactive', () => {
      // this.handleUserUnactive()
    });
  },

  // The `createEl` function of a component creates its DOM element.
  createEl: function () {
    const btn = videojs.dom.createEl('button', {
      className: 'vjs-epg-button vjs-control vjs-button',
    });
    btn.innerHTML = `<i class='fa fa-list-alt vjs-epg-button--fa'></i><span class="vjs-epg-button--text" aria-hidden="true">${this.options_.text}</span>`;
    return btn;
  },
  handleUserActive: function () {
    const $player = jquery(this.player_.el_);
    $player.addClass('vjs-epg-open').parent().addClass('vjs-epg-open');
  },
  handleUserUnactive: function () {
    const $player = jquery(this.player_.el_);
    if ($player.parent().hasClass('vjs-epg-open-first')) {
      return;
    }
    $player.removeClass('vjs-epg-open').parent().removeClass('vjs-epg-open');
  },
  handleClick: function (event) {
    this.options_.component.setState({
      resetEpg: !this.options_.component.state.resetEpg,
    });
    const $player = jquery(this.player_.el_);
    $player.toggleClass('vjs-epg-open').parent().toggleClass('vjs-epg-open');
  },
  hadleOpenEpg: function () {
    let clearTimeoutFirst = null;
    let clearTimeoutSecond = null;
    const $player = jquery(this.player_.el_);
    const parent = $player.parent();
    const clss = ['vjs-open', 'vjs-black-out', 'vjs-ad-playing'];
    this.interval = setInterval(() => {
      let isNotOpen = false;
      clss.forEach(cls => {
        if (parent.attr('class').indexOf(cls) > -1 || $player.attr('class').indexOf(cls) > -1) {
          isNotOpen = true;
          return false;
        }
      });
      if (isNotOpen) {
        return;
      }
      if (this.isShow) {
        clearInterval(this.interval);
        clearTimeout(clearTimeoutFirst);
      }
      clearInterval(this.interval);
      this.isShow = true;
      clearTimeoutFirst = setTimeout(() => {
        $player.parent().addClass('vjs-epg-open-first');
        this.el().click();
        clearTimeout(clearTimeoutFirst);
      }, 100);

      clearTimeoutSecond = setTimeout(() => {
        const el = jquery(this.el());
        $player.parent().removeClass('vjs-epg-open-first');
        if (
          $player.hasClass('vjs-useractive') ||
          !$player.parent().hasClass('vjs-epg-open') ||
          el.parents('.player-wrapper').find('.vjs-epg').hasClass('hover')
        ) {
          return;
        }
        el.click();
        clearTimeout(clearTimeoutSecond);
      }, 5000);
    }, 3000);
  },
});

// Register the component with Video.js, so it can be used in players.
videojs.registerComponent('EPG', EPG);
