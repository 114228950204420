import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import { ModalStyled } from '../styles/styled';
import { getStepPayperview } from '../functions';
import { SHOW_TYPE } from '../constants';
import { getConfigByKey } from '~features/tenantConfig/services';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

const Plan = props => {
  const {
    select,
    account,
    match,
    setHide,
    setStep,
    hideModal,
    state = {},
    history,
    language,
    t,
    isFollowSubcription,
    content,
  } = props;
  let entityDetail = state.infoShow ? state.infoShow : state.entityDetail;
  if (!entityDetail) {
    entityDetail = content;
  }
  const showLoginAction = props.showLoginAction || props.props.showLoginAction;

  const tvodFeature = getConfigByKey('features.tvodFeature') && entityDetail.price;
  const svodFeature = getConfigByKey('features.subscriptionFeature');

  // React.useEffect(() => {
  //   if (select && tvodFeature && !svodFeature) {
  //     handleClick()
  //   }
  //   if (!select && !tvodFeature && svodFeature) {
  //     history.push(`/${language}/box/offers`)
  //   }
  // }, [])

  const handleClick = () => {
    if (getConfigByKey('ui_theme') === 'odv' && !isFollowSubcription) {
      return history.push(`/${language}/box/offers`);
    }
    if (!isFollowSubcription && !tvodFeature && !svodFeature)
      return history.push(`/${language}/box/offers`);
    const params = match.params;
    let param = {
      upgrade: true,
      callbackUrl: `/landing/${params.slug}`,
      payperview: true,
      props: { ...props, payperview: true },
      method: {
        setStep: setStep,
        login: showLoginAction,
        hideModal: hideModal,
      },
    };
    setHide(true);
    if (!account) return showLoginAction(param);
    return getStepPayperview(2, props, setStep);
  };

  const hrefUrl = () => {
    let callbackUrlPlayer = '';
    if (state && props.target === 'player') {
      callbackUrlPlayer = state.redirect;
    }
    let param = {
      upgrade: true,
      callbackUrl: callbackUrlPlayer
        ? callbackUrlPlayer
        : entityDetail.type === SHOW_TYPE
        ? `/landing/${entityDetail.slug || entityDetail.id}`
        : `/detail/${entityDetail.slug || entityDetail.id} `,
    };
    return history.push(`/${language}/box/offers`, param);
  };
  if (select && !entityDetail.price) return null;
  if (select) {
    let rentDuration = entityDetail.price.rent_duration;
    let unit =
      rentDuration > 48
        ? t('payperview.day')
        : rentDuration === 1
        ? t('payperview.hour')
        : t('payperview.hours');
    let timePaid = rentDuration > 48 ? Math.round(rentDuration / 24) : rentDuration;
    return (
      <Col
        sm={12}
        xs={12}
        md={6}
        lg={6}
        className="payperview-plan__container-show"
        onClick={() => handleClick()}
      >
        <div className="payperview-plan__wrap">
          <p className="payperview-plan--category">
            {entityDetail.type === SHOW_TYPE
              ? t('payperview.plan.views.txtBuyDrama')
              : t('payperview.plan.views.txtBuyMovie')}
          </p>
          <p className="payperview-plan--title">{entityDetail.title}</p>
          <p className="payperview-plan--dolar">
            {entityDetail.price.currency === 'usd' && '$'}
            {entityDetail.price.price}{' '}
            <span className="payperview-plan--currency">
              {entityDetail.price.currency !== 'usd' && entityDetail.price.currency}
            </span>
          </p>
          <p className="payperview-plan--description">
            {t('payperview.plan.views.timePaid', {
              timePaid: timePaid,
              unit: unit,
            })}
          </p>
        </div>
        <Button
          className="payperview-plan--btn"
          data-testid="payperview-btn--content"
          onClick={() => handleClick()}
        >
          {t('payperview.plan.views.btn')}
        </Button>
      </Col>
    );
  }
  return (
    <Col
      sm={12}
      xs={12}
      md={6}
      lg={6}
      className="payperview-plan__container-offer"
      onClick={() => hrefUrl()}
    >
      <div className="payperview-plan__wrap">
        <p className="payperview-plan--title payperview-plan--title__offer">
          {t('payperview.plan.month.txtPlan')}
        </p>
        <p className="payperview-plan--description">{t('payperview.plan.month.txtDes')}</p>
        <ul className="payperview-plan--description payperview-plan__ul">
          <li>{t('payperview.plan.month.txtOption1')}</li>
          <li>{t('payperview.plan.month.txtOption2')}</li>
        </ul>
      </div>

      <Button className="payperview-plan--btn" data-testid="payperview-btn--month">
        {t('payperview.plan.month.btn')}
      </Button>
    </Col>
  );
};
const Subcriptions = props => {
  const { t } = useTranslation();
  const tvodFeature = getConfigByKey('features.tvodFeature');
  const svodFeature = getConfigByKey('features.subscriptionFeature');
  const [hide, setHide] = useState(true);
  const { open, hideModal, paymentType } = props;
  useEffect(() => {
    if (open) {
      setHide(false);
    }
  }, [open]);
  const hideModalUpgrade = () => {
    setHide(true);
    hideModal();
  };

  return (
    <ModalStyled
      show={!hide}
      onHide={() => hideModalUpgrade()}
      aria-labelledby="contained-modal-title-vcenter"
      className="payperview-plan"
      bsSize="large"
    >
      <Modal.Header>
        <CloseIcon className="payperview-close" onClick={() => hideModalUpgrade()} />
      </Modal.Header>
      <Modal.Body>
        {getConfigByKey('ui_theme') === 'projectw' && (
          <Row>
            <Col xs="12">
              <p
                dangerouslySetInnerHTML={{ __html: t('ppvRefundCondition') }}
                className="txt txt--white"
              ></p>
            </Col>
          </Row>
        )}
        <Row className="payperview-plan__container">
          {tvodFeature && <Plan select {...props} setHide={setHide} />}
          {svodFeature && paymentType !== 'tvod' && <Plan {...props} />}
        </Row>
        {/* {
          getConfigByKey('ui_theme') === 'projectw' &&
          <Row>
            <Col xs="12">
              <p className="txt txt--ChangeColor">{t('ppvDescription')}</p>
            </Col>
          </Row>
        } */}
      </Modal.Body>
    </ModalStyled>
  );
};

const mapStateToProps = state => ({
  account: state.auth.account,
  language: state.root.language,
  isFollowSubcription: state.root.isFollowSubcription,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Subcriptions)),
);
