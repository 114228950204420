export function getHeaders() {
  try {
    return this.headers;
  } catch (error) {
    return {};
  }
}

export function hasNoCache() {
  try {
    const headers = getHeaders.call(this);
    const cacheControl = headers['cache-control'] || '';

    return cacheControl === 'no-cache';
  } catch (error) {}
  return false;
}
