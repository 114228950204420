import { IAxiosResponseData } from '~core/models';
import { apiAdapter, config, axiosTakeDataFromResponse } from '~share';

const { userService, userSurveyService } = apiAdapter;

const getSurveys = (
  pageSlug?: string,
): Promise<IAxiosResponseData<typeof userSurveyService.getOptionsInSurveyService>> => {
  const { tenantSlug = '', authorization, acceptLanguage } = config;
  return userSurveyService
    .getOptionsInSurveyService(tenantSlug, undefined, undefined, authorization, acceptLanguage)
    .then(axiosTakeDataFromResponse);
};

const postSurveys = (
  params: any = {},
): Promise<IAxiosResponseData<typeof userService.sendUserSurvey>> => {
  const { ...paramsRest } = params;
  const survey_content = JSON.stringify(paramsRest);
  const payload = { survey_content };
  const { accountId = '', tenantSlug = '', authorization } = config;
  return userService
    .sendUserSurvey(accountId, tenantSlug, payload, authorization)
    .then(axiosTakeDataFromResponse);
};

export { getSurveys, postSurveys };
