import * as init from './index';
import { isIOS, isMobile } from 'react-device-detect';
import jquery from 'jquery';
import '../videoComponents/buttonPlay';

export const checkAutoPlay = async (player, component) => {
  let autoplayAllowed = false;
  let autoplayRequiresMuted = false;
  if (!player || !component.playerRef.current) {
    return;
  }
  try {
    await checkAutoplaySupport();
    // await onAdsManagerLoaded()
  } catch {}

  function checkAutoplaySupport() {
    if (!player || !component.playerRef.current) {
      return;
    }
    const playPromise = player.play();
    if (playPromise !== undefined) {
      playPromise.then(onAutoplayWithSoundSuccess).catch(onAutoplayWithSoundFail);
    }
  }

  function onAutoplayWithSoundSuccess() {
    console.log('If we make it here, unmuted autoplay works.');
    if (!player || !component.playerRef.current) {
      return;
    }
    player.play();
    autoplayAllowed = true;
    autoplayRequiresMuted = false;
    playAds();
  }

  function onAutoplayWithSoundFail() {
    console.log('Unmuted autoplay failed. Now try muted autoplay.');
    checkMutedAutoplaySupport();
  }

  function checkMutedAutoplaySupport() {
    var playPromise = player.play();
    if (playPromise !== undefined) {
      playPromise.then(onMutedAutoplaySuccess).catch(onMutedAutoplayFail);
    }
  }

  function onMutedAutoplaySuccess() {
    console.log('If we make it here, muted autoplay works but unmuted autoplay does not.');
    if (!player || !component.playerRef.current) {
      return;
    }
    player.pause();
    autoplayAllowed = true;
    autoplayRequiresMuted = true;
    autoplayChecksResolved();
  }

  function onMutedAutoplayFail() {
    console.log('Both muted and unmuted autoplay failed. Fall back to click to play.');
    try {
      if (!player || !component.playerRef.current) {
        return;
      }
      if (player.paused()) {
        player.pause();
      }
      autoplayAllowed = false;
      autoplayRequiresMuted = false;
      autoplayChecksResolved();
    } catch (error) {}
  }

  function autoplayChecksResolved() {
    const { isBlackOut } = component.state;
    const $player = jquery(player.el_);
    if (!player.paused() && $player.hasClass('vjs-has-started')) {
      return playAds();
    }
    if (isBlackOut) return;
    player.addChild('ButtonPlay', { component: component });
  }

  function playAds() {
    seekToProcess();
    return;
    try {
      init._addAdsVideo(component, 1);
    } catch (adError) {}
  }

  function seekToProcess() {
    if (component.progress) {
      component._resumeVideo(component.progress);
      player.one('canplaythrough', function () {
        component._resumeVideo(component.progress);
      });
    }
  }
};
