import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { REACT_APP_URL_PRIVACY_POLICY } from '~features/footer/constants';
import TermOfUse from '~features/box/components/termOfUsePopup';
import PrivacyPolicy from '~features/box/components/privacyPolicyPopup';
import { getConfigByKey } from '~features/tenantConfig/services';

import queryString from 'query-string';

const privacyPolicyLink = getConfigByKey('privacyPolicyLink');
const termsOfUseLink = getConfigByKey('termsOfUseLink');

const CheckboxPolicy = props => {
  const [termOfUseModalShow, setTermOfUseModalShow] = useState(false);
  const [privacyPolicyModalShow, setPrivacyPolicyModalShow] = useState(false);

  const [valueChecked, setChecked] = useState(false);
  const { t, setStatePolicy } = props;

  useEffect(() => {
    let paramQuery = queryString.parse(props.location.search, {
      decode: false,
    });
    let disabled = setStatePolicy.checked || false;
    if (paramQuery && paramQuery.token) disabled = true;
    setChecked(disabled);
  }, []);

  const onChange = e => {
    let checked = e.target.checked;
    setChecked(checked);
    setStatePolicy.setChecked(checked);
  };

  return (
    <>
      <div className="v-payment--checkBoxAge">
        <input type="checkbox" onChange={e => onChange(e)} checked={valueChecked} />
        <div className="v-payment--checkBoxAge--title">
          <span>{t('subcriptions.txtOverAge')}</span>
          <a
            style={getConfigByKey('ui_theme') === 'projectw' ? { color: '#fff' } : {}}
            target="_blank"
            href={privacyPolicyLink || '#'}
            onClick={event => {
              if (privacyPolicyLink) {
                return;
              }
              event.preventDefault();
              setPrivacyPolicyModalShow(true);
            }}
          >
            {' '}
            {t('subcriptions.privacy policy')}{' '}
          </a>
          <span>{t('boxLogin.txtAnd')}</span>
          <a
            style={getConfigByKey('ui_theme') === 'projectw' ? { color: '#fff' } : {}}
            href={termsOfUseLink || '#'}
            onClick={event => {
              if (termsOfUseLink) {
                return;
              }
              event.preventDefault();
              setTermOfUseModalShow(true);
            }}
          >
            {t('subcriptions.footer.termsOfUse')}
          </a>
        </div>
      </div>
      <TermOfUse
        modalShow={termOfUseModalShow}
        showModal={() => setTermOfUseModalShow(!termOfUseModalShow)}
      />
      <PrivacyPolicy
        modalShow={privacyPolicyModalShow}
        showModal={() => setPrivacyPolicyModalShow(!privacyPolicyModalShow)}
      />
    </>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CheckboxPolicy)),
);
