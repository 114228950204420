import React from 'react';
import { isMobile } from 'react-device-detect';
import { EPISODE_TYPE } from '../../constants';
import { tenantEnvConfig } from '~constants/tenantConfig/tenantEnvConfig';
import BlockAds from '../blockAds';
import classnames from 'classnames';
import { DivStyle } from '../../styles/escondido';

const VideoJs = React.forwardRef((props, ref) => {
  const { entityDetail, isLoginDisplay, id } = props.state;
  const { component, isBlockAds } = props;
  const isLoginDisplayClass = isLoginDisplay && entityDetail && !entityDetail.is_watchable;
  const getClsVideo = clses => {
    const { popupUpgrade, isBlackOut, isHideErrorDefault, width, height, entityDetail } =
      props.state;
    const classIsMobile = isMobile ? 'vjs-mobile' : '';
    const classEpisodes =
      entityDetail.type === EPISODE_TYPE || entityDetail.trailers ? 'vjs-episodes' : '';
    let tmpCls = [`vjs--${tenantEnvConfig.ui_theme}`];
    if (width > height) {
      tmpCls.push('vjs-landscape');
    }
    if (component.class) {
      tmpCls.push(component.class);
    }
    if (isBlackOut) {
      tmpCls.push('vjs-black-out');
    }
    if (popupUpgrade) {
      tmpCls.push('vjs-popup-upgrade');
    }
    if (isHideErrorDefault) {
      tmpCls.push('vjs-hide-error');
    }
    if (!clses) {
      tmpCls.push('video-js');
      tmpCls.push('vjs-default-skin');
      tmpCls.push(classEpisodes);
      tmpCls.push(classIsMobile);
    }
    return tmpCls.join(' ');
  };

  let clsVideo = getClsVideo();
  let clses = getClsVideo(true);
  if (!id) return;
  return (
    <DivStyle
      id="boxplayerWrapper"
      className={classnames(clses, 'player-wrapper', isLoginDisplayClass && 'vjs-open')}
    >
      <video
        preload="auto"
        id={id}
        className={clsVideo}
        ref={ref}
        webkit-playsinline="true"
        playsInline
        closed-captions="none"
      />
      {isBlockAds && <BlockAds />}
    </DivStyle>
  );
});

export default VideoJs;
