/* eslint-disable import/prefer-default-export */
/* eslint-disable no-use-before-define */
export function muxConfig({ envKey, component }) {
  if (!envKey) {
    return { mux: {} };
  }
  const { account } = component.props;
  const { entityDetail } = component;
  return {
    mux: {
      debug: false,
      data: {
        env_key: envKey, // required
        // Site Metadata
        viewer_user_id: account && account.profile && account.profile.id,
        // Player Metadata
        player_name: 'player', // ex: 'My Main Player'
        player_version: '7.5.5', // ex: '1.0.0'
        player_init_time: Date.now(), // ex: 1451606400000
        // Video Metadata
        video_id: entityDetail.id, // ex: 'abcd123'
        video_title: entityDetail.title, // ex: 'My Great Video'
        video_series: '', // ex: 'Weekly Great Videos'
        video_duration: '', // in milliseconds, ex: 120000
        video_stream_type: contentType(entityDetail), // 'live' or 'on-demand'
        video_cdn: '', // ex: 'Fastly', 'Akamai'
      },
    },
  };
}

const contentType = item => {
  let statusText = 'Movie';
  if (item) {
    switch (item.type) {
      case 2:
        statusText = 'Show';
        break;
      case 3:
        statusText = 'Season';
        break;
      case 4:
        statusText = 'Episode';
        break;
      case 5:
        statusText = 'Channel';
        break;
      case 6:
        statusText = 'Clip';
        break;
      case 7:
        statusText = 'Curated live';
        break;
    }
  }
  return statusText;
};
