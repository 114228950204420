import '../../videoComponents/escondido/registerComponent/groupButtonPlay';
import '../../videoComponents/spaceControlBar';
import '../../videoComponents/escondido/registerComponent/groupButtonLive';
import { getEps } from '../getEps';

export const controlShow = (controlBar, component, data, player) => {
  const { entityDetail } = data;
  const { detailPage, t } = component.props;
  const { entityTrailer } = component.state;
  let episodes = getEps(component, data);
  let currentEpisode = entityTrailer || detailPage.episode || 1;
  let options = {
    component: component,
    entity: entityDetail,
    translate: t,
    currentEpisode,
    episodes: episodes,
  };
  controlBar.addChild('listEps', options, 8);
  player.addChild('AutoNextSpiner', { component: component });
  controlBar.addChild(
    'listButtonPlay',
    { component: component, entityDetail: entityDetail, type: 'show' },
    5,
  );
  controlBar.addChild('SpaceControlBar', {}, 6);
  controlBar.addChild(
    'listButtonLive',
    {
      component: component,
      entityDetail: entityDetail,
      status: 'offlive',
      type: 'show',
      episodes: episodes,
    },
    7,
  );
  controlBar.addChild('fullscreenToggle', { component: component }, 8);
  return;
};
