import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios';
import { config } from './config';
import ProxyPolyfillBuilder from 'proxy-polyfill/src/proxy';
import { store } from '~store';
import moment from 'moment';
import { decodeToken } from 'react-jwt';
import { getAccessToken, getRefreshToken } from '~utils/utils';
import * as homeActions from '~features/homepage/actions';
import { setIsShowLogin } from '~features/auth/actions';
import { history } from '~store/history';
import { logout } from '~features/auth/services';
import { hasNoCache } from '~server/method/requestMethod';
import { isNode } from '~utils/ssr';
import _ from 'lodash';
import { checkBlockCountry } from '~features/blockCountry/services';
import { getConfigByKey } from '~features/tenantConfig/services';

axios.defaults.adapter = require('axios/lib/adapters/http');
const dispatch: any = store.dispatch;
const REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED';
const REFRESH_TOKEN_INVALID = 'REFRESH_TOKEN_INVALID';
const AUTHENTICATION_FAILED = 'authentication_failed';

let ProxyInstance: any;
let refreshTokenExec: any = null;
let checkAllowedCountries: any = null;
let allowCountry: null | boolean = null;

if ((window as any).Proxy) {
  ProxyInstance = ProxyInstance = Proxy;
} else {
  ProxyInstance = ProxyPolyfillBuilder();
}

function hasAuthorization(headers: any = {}) {
  const { Authorization, authorization } = headers;
  return !!(Authorization || authorization);
}

function expireToken(accessToken: string) {
  const durationTime = 60 * 5;
  const timestamp = moment().unix();
  const dcToken: any = decodeToken(accessToken);
  const exp = dcToken.exp || 0;
  const restExpireTime = exp - timestamp;
  if (!(restExpireTime < durationTime)) {
    return false;
  }
  return true;
}

function refreshTokenApi(refreshToken: string) {
  const path = `${config.baseUrl}/backend/cas/refresh/`;
  return new Promise((resolve, reject) => {
    axios
      .post(path, null, { headers: { Authorization: refreshToken } })
      .then(axiosTakeDataFromResponse)
      .then(async res => {
        await handleRefreshTokenSuccess(res);
        resolve(res);
      })
      .catch(async error => {
        const errorData = axiosTakeDataFromError(error);
        const newGuestAccount = await handleRefreshTokenFailure(errorData);
        if (newGuestAccount) {
          resolve(newGuestAccount);
        }
        reject(errorData);
      });
  });
}

function handleRefreshTokenSuccess(res: any) {
  const state: any = store.getState();
  const auth = state.auth;
  const { account, guestAccount } = auth;
  let actions = 'LOGIN_SUCCESS';
  if (guestAccount) {
    actions = 'LOGIN_GUEST_SUCCESS';
  }
  dispatch({ type: actions, account: res });
  return res;
}

async function handleRefreshTokenFailure(error: any) {
  const isRefreshFailed = !!(
    error &&
    (error.error_code === REFRESH_TOKEN_EXPIRED ||
      error.error_code === REFRESH_TOKEN_INVALID ||
      error.error_code === AUTHENTICATION_FAILED)
  );

  if (!isRefreshFailed) {
    return null;
  }
  dispatch(homeActions.getFinal());
  dispatch(setIsShowLogin(true));
  history.push('/');
  try {
    const newGuestAccount = await dispatch(logout());

    return newGuestAccount;
  } catch (error) {
    return null;
  }
}

const axiosTakeDataFromResponse = <T>(response: AxiosResponse<T>): T => response.data;

const axiosTakeDataFromError = <T>(error: any): T => {
  if (!error || !error.response) {
    return error;
  }
  const { data } = error.response;
  return errorCustom(data) || error;
};

const getCacheControlHeader = () => {
  return isNode() && hasNoCache.call((global as any).request)
    ? { 'cache-control': 'no-cache' }
    : {};
};

const ignorePaths = ['allowed_countries/'];

const isIgnoreRequest = (checkingPath: string) => {
  let result = false;

  ignorePaths.some(path => {
    if (checkingPath.indexOf(path) >= 0) {
      result = true;
      return true;
    }
  });
  return result;
};

const isRequestRequireTenant = (path: string) => {
  if (isNode()) {
    return false;
  }
  if (isIgnoreRequest(path)) {
    return false;
  }
  return true;
};

const makeProxyFunction = (target: any, prop: string) => {
  return new ProxyInstance(target[prop as keyof typeof target], {
    apply: async (tg: any, thisArg: any, argumentsList: any[]) => {
      const [options = {}] = argumentsList;

      const { headers = {}, url = '' } = options;

      if (allowCountry === null && !checkAllowedCountries) {
        checkAllowedCountries = checkTenantConfigs(url);
      }

      if (checkAllowedCountries !== null && !isIgnoreRequest(url)) {
        try {
          await checkAllowedCountries.then((res: any) => {
            checkAllowedCountries = null;
          });

          allowCountry = true;
        } catch (error) {
          return Promise.reject(error);
        }
      }

      const { Authorization, authorization, ...restOldHeaders } = headers;
      const accessToken = Authorization || authorization;

      let newHeaders = {
        ...restOldHeaders,
        authorization: accessToken,
        'accept-language': config.acceptLanguage,
        ...getCacheControlHeader(),
      };
      const refreshToken = getRefreshToken();
      if (!isNode() && hasAuthorization(headers) && expireToken(accessToken)) {
        if (!refreshTokenExec) {
          refreshTokenExec = refreshTokenApi(refreshToken);
        }
        if (refreshTokenExec) {
          try {
            const newAccount = await refreshTokenExec.then((res: any) => {
              return res;
            });
            const { access_token } = newAccount;
            newHeaders = {
              ...newHeaders,
              authorization: access_token,
            };
            refreshTokenExec = null;
          } catch (error) {}
        }
      }

      const newArgumentsList = [{ ...options, headers: _.omitBy(newHeaders, _.isNil) }];

      return Reflect.apply(tg, thisArg, newArgumentsList);
    },
  });
};

const axiosInstance: AxiosInstance = new Proxy(
  axios.create({
    headers: {
      // Note that browsers are overriding all user-agent headers
      // To test this value use non-browsers environment
      'Content-Type': 'application/json',
    },
  }),
  {
    get: function (target: any, prop: string) {
      if (typeof target[prop] === 'function') {
        return makeProxyFunction(target, prop);
      }

      return target[prop];
    },
  },
);
const errorCustom = (error: any) => {
  if (!error) {
    return null;
  }
  let msg: any = error.message;
  if (msg && typeof msg !== 'string' && Object.values(error.message).length) {
    msg = Object.values(error.message as object)
      .reduce((result, current) => {
        if (Array.isArray(current)) {
          current.map(message => {
            result.push(message);
          });
        } else {
          result.push(current);
        }

        return result;
      }, [])
      .join('\n');
    error.message = msg;
  }

  return error;
};
// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    // Do something before request is sent

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response: AxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    const { status, data } = response;

    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(axiosTakeDataFromError(error));
  },
);

function checkTenantConfigs(path: string) {
  const isRequire = isRequestRequireTenant(path);

  if (!isRequire) {
    return Promise.resolve();
  }
  return dispatch(checkBlockCountry() as any);
}

export { axiosInstance, axiosTakeDataFromResponse };
