import videojs from 'video.js';
import { getEndAt, getStartAt } from '~core/method/contentMethod';
import moment from 'moment';
import { CONTENT_TYPE } from '~core/constants';

function overiddeHandleMouseDownSeekBar(player) {
  const controlBar = player.getChild('controlBar');
  const progressControl = controlBar.getChild('progressControl');
  const seekBar = progressControl.getChild('seekBar');
  const { start_on } = player.vod;
  const startAt = moment(start_on);

  seekBar.handleMouseDown__ = seekBar.handleMouseDown.bind(seekBar);
  seekBar.handleMouseMove__ = seekBar.handleMouseMove.bind(seekBar);

  seekBar.handleMouseDown = function (event) {
    const now = moment();
    const maxSeekableDuration = now.clone().diff(startAt, 'seconds');

    const distance = this.calculateDistance(event);

    const newTime = distance * this.player_.duration();
    if (maxSeekableDuration <= 0) {
      return;
    }

    if (newTime >= maxSeekableDuration) {
      player.rewindMode = false;
      this.userSeek_(maxSeekableDuration);
      return;
    }
    player.rewindMode = true;
    this.handleMouseDown__(event);
  };
  seekBar.handleMouseMove = function (event, mouseDown = false) {
    if (!mouseDown && !this.player_.scrubbing()) {
      this.player_.scrubbing(true);
    }

    const now = moment();
    const maxSeekableDuration = now.clone().diff(startAt, 'seconds');

    const distance = this.calculateDistance(event);

    const newTime = distance * this.player_.duration();
    if (maxSeekableDuration <= 0) {
      return;
    }

    if (newTime >= maxSeekableDuration) {
      player.rewindMode = false;
      this.userSeek_(maxSeekableDuration);
      return;
    }
    player.rewindMode = true;
    this.handleMouseMove__(event, mouseDown);
  };
}

const Plugin = videojs.getPlugin('plugin');

class SimulatedLiveEvent extends Plugin {
  constructor(player, options) {
    super(player, options);
    const { content } = options;
    if (content.type !== CONTENT_TYPE.SIMULATED_LIVE_EVENT) {
      return;
    }
    player.vod = content;
    player.rewindMode = false;

    this.differentDuration = 5 * 60; // seconds
    this.backendCurrentTime = null;
    this.player = player;
    this.content = content;
    this.on(player, ['timeupdate'], this.updateTimeHandle);
    this.on(player, ['backend-update-current-time'], this.handleBackEndUpdateCurrentTime);
    // this.on(player, ['subtitlechanged'], this.subtitleChanged)

    this.on(player, ['ready'], () => overiddeHandleMouseDownSeekBar(player));

    // this.trigger('auto-seek-current-time')
    this.countTimeUpdate = 0;
  }

  dispose() {
    super.dispose();
  }

  handleBackEndUpdateCurrentTime(event, currentTime) {
    this.backendCurrentTime = currentTime;
    this.syncPositionCurrentTime();
  }

  addClass() {
    if (this.content.type !== CONTENT_TYPE.SIMULATED_LIVE_EVENT) {
      return;
    }
    const player = this.player;
    const classList = ['vjs-simulated-live-event', 'vjs-liveui', 'vjs-live'];
    classList.map(cls => {
      if (player.hasClass(cls)) {
        return null;
      }
      player.addClass(cls);
    });
  }

  calcCurrentLiveTime() {
    if (this.backendCurrentTime) {
      return this.backendCurrentTime;
    }
    const startAt = getStartAt.call(this.content);
    const endAt = getEndAt.call(this.content);
    const now = moment();
    if (!startAt || !endAt) {
      return null;
    }

    const nowDiffStartAt = now.clone().diff(startAt.clone());
    const nowDiffEndAt = now.clone().diff(endAt.clone());

    if (nowDiffStartAt <= 0) {
      return 0;
    }
    if (nowDiffEndAt <= 0) {
      nowDiffStartAt.asSeconds();
    }
    if (nowDiffEndAt > 0) {
      return this.player.duration();
    }
    return null;
  }

  seekToCurrentLive(currentTime) {
    this.player.currentTime(currentTime);
  }

  handleAutoSeekCurrentTime() {
    if (this.content.type !== CONTENT_TYPE.SIMULATED_LIVE_EVENT) {
      return;
    }
    this.player.trigger('check-backend-current-time');
    return;

    const currentTime = this.calcCurrentLiveTime();
    if (currentTime === null) {
      return;
    }
    this.seekToCurrentLive(currentTime);
  }

  syncPositionCurrentTime() {
    const currentTime = this.player.currentTime();
    const currentLiveTime = this.calcCurrentLiveTime() || currentTime;

    const diff = Math.abs(currentLiveTime - currentTime);
    if (diff <= this.differentDuration) {
      return;
    }
    this.player.currentTime(currentLiveTime);
    this.backendCurrentTime = null;
  }

  updateTimeHandle(event) {
    const player = this.player;
    this.addClass();
    if (player.rewindMode) {
      return;
    }
    this.countTimeUpdate++;
    if (this.countTimeUpdate % 100 !== 0) {
      return;
    }
    this.countTimeUpdate = 0;
    this.player.trigger('check-backend-current-time');

    // this.syncPositionCurrentTime()
  }
}

videojs.registerPlugin('simulatedLiveEvent', SimulatedLiveEvent);
