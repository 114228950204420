import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import PopupShare from '../components/popup';
import { ButtonStyled } from './styled';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { getConfigByKey } from '../../tenantConfig/services';
import ShareIcon from '@material-ui/icons/Share';
const ButtonShare = props => {
  const [open, setOpen] = useState(false);
  const { entityDetail, t, target, entityTrailer } = props;
  if (!getConfigByKey('features.deep_link')) {
    return null;
  }
  return (
    <React.Fragment>
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id="button-tooltip-2" className={`${isMobile && 'hide'}`}>
            {t('share.txtShare')}
          </Tooltip>
        }
        delayHide={150}
        trigger={['hover', 'focus', 'click']}
      >
        <ButtonStyled onClick={() => setOpen(!open)}>
          <ShareIcon />
        </ButtonStyled>
      </OverlayTrigger>
      <PopupShare
        entityDetail={entityDetail}
        open={open}
        setOpen={value => setOpen(value)}
        target={target}
        entityTrailer={entityTrailer}
      />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ButtonShare)),
);
