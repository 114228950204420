import React from 'react';
import SliderOdv from './component/slider/sliderOdv';
import SliderEscondido from './component/slider/sliderEscondido';
import SliderSctv from './component/slider/sliderSctv';

import { tenantEnvConfig } from '../../constants/tenantConfig/tenantEnvConfig';

export default class PanelBody extends React.PureComponent {
  render() {
    let Component;
    switch (tenantEnvConfig.ui_theme) {
      case 'sctv':
        Component = SliderSctv;
        break;
      case 'escondido':
        Component = SliderEscondido;
        break;
      default:
        Component = SliderOdv;
    }
    return <Component {...this.props} />;
  }
}
