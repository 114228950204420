Object.defineProperty(exports, "__esModule", { value: true });
exports.VimaiApiAdapter = void 0;
var api_adapter_vimai_1 = require("../../api-adapters/api-adapter-vimai");
var api_service_proxy_1 = require("../utils/api-service-proxy");
var VimaiApiAdapter = /** @class */ (function () {
    function VimaiApiAdapter(configs) {
        if (configs === void 0) { configs = {}; }
        this.configuration = configs.configuration;
        this.debug = configs.debug;
        this.baseUrl = configs.baseUrl;
        this.axiosInstance = configs.axiosInstance;
        this.parameterProvider = configs.parameterProvider;
        this.initializeServices();
    }
    VimaiApiAdapter.prototype.create = function (cls) {
        var provider = this.parameterProvider;
        var s = new cls(this.configuration, this.baseUrl, this.axiosInstance);
        return provider ? (0, api_service_proxy_1.applyProxy)(s, provider, this.debug) : s;
    };
    VimaiApiAdapter.prototype.initializeServices = function () {
        this.authenticationService = this.create(api_adapter_vimai_1.AuthenticationApi);
        this.contentListingService = this.create(api_adapter_vimai_1.ContentListingApi);
        this.contentService = this.create(api_adapter_vimai_1.ContentApi);
        this.clientEventService = this.create(api_adapter_vimai_1.ClientEventsApi);
        this.documentService = this.create(api_adapter_vimai_1.DocumentApi);
        this.geoBlockingService = this.create(api_adapter_vimai_1.GeoBlockingApi);
        this.adsManagementService = this.create(api_adapter_vimai_1.AdsManagementApi);
        this.multitenancyService = this.create(api_adapter_vimai_1.MultitenancyApi);
        this.paymentService = this.create(api_adapter_vimai_1.PaymentApi);
        this.appVersionService = this.create(api_adapter_vimai_1.AppVersionApi);
        this.userContentService = this.create(api_adapter_vimai_1.UserContentApi);
        this.userService = this.create(api_adapter_vimai_1.UserApi);
        this.configService = this.create(api_adapter_vimai_1.ConfigApi);
        this.userSurveyService = this.create(api_adapter_vimai_1.UserSurveyApi);
        this.adminContentService = this.create(api_adapter_vimai_1.AdminContentApi);
        this.adminDocumentService = this.create(api_adapter_vimai_1.AdminDocumentApi);
    };
    return VimaiApiAdapter;
}());
exports.VimaiApiAdapter = VimaiApiAdapter;
