import BlackoutR from '../components/Blackout';
import ReactDOM from 'react-dom';
import React from 'react';
import videojs from 'video.js';
import jquery from 'jquery';

// Get the Component base class from Video.js
const ModalDialog = videojs.getComponent('Component');

// The videojs.extend function is used to assist with inheritance. In
// an ES6 environment, `class SeekNext extends Component` would work
// identically.

const BlackOut = videojs.extend(ModalDialog, {
  // The constructor of a component receives two arguments: the
  // player it will be associated with and an object of options.
  constructor: function (player, options) {
    // It is important to invoke the superclass before anything else,
    // to get all the features of components out of the box!
    ModalDialog.apply(this, arguments);
    // If a `text` option was passed in, update the text content of
    // the component.
    const $player = jquery(this.player_.el_);
    const that = this;
    this.mount = this.mount.bind(this);
    this.mount(options.channelId);
    /* Remove React root when component is destroyed */
    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });

    player.on('loadeddata', function () {
      if (!that.isBlackOut($player)) {
        return;
      }
      player.pause();
    });
    player.on('pause', function () {
      that.show();
    });
    player.on('play', function () {
      if (!that.isBlackOut($player)) {
        return;
      }
      player.pause();
    });
  },

  // The `createEl` function of a component creates its DOM element.
  createEl: function () {
    const btn = videojs.dom.createEl('div', {
      className: 'vjs-black-out-display',
    });
    return btn;
  },
  handleClick: function (event) {},
  isBlackOut: function ($player) {
    const parent = $player.parent();
    let isBlackOut = false;
    if (!($player.hasClass('vjs-live') || $player.hasClass('vjs-liveui'))) {
      return false;
    }
    const clss = ['vjs-black-out'];
    clss.forEach(cls => {
      if (parent.attr('class').indexOf(cls) > -1 || $player.attr('class').indexOf(cls) > -1) {
        isBlackOut = true;
        return false;
      }
    });
    return isBlackOut;
  },
  mount(channelId) {
    const { component, isRender } = this.options_;
    // if (!channelId) {
    //   return;
    // }
    ReactDOM.render(
      <BlackoutR
        channelId={channelId}
        player={this.player_}
        component={component}
        isRender={isRender}
      />,
      this.el(),
    );
  },
});

// Register the component with Video.js, so it can be used in players.
videojs.registerComponent('BlackOut', BlackOut);

export default BlackOut;
