export const getTimePaidContent = (paidContents, idContent, paymentInfors = null) => {
  if (!paidContents && !Array.isArray(paidContents)) return;
  let foundPaidContents = paidContents.filter(element => idContent === element.content_id) || [];
  if (!paymentInfors && !foundPaidContents.length) return null;
  let seconds =
    (paymentInfors && paymentInfors.remaining_time) ||
    (foundPaidContents.length && foundPaidContents[0].remaining_time);
  let hour = Math.round(seconds / 60 / 60);
  let day = null;
  if (hour === 0 && seconds > 0) hour = 1;
  if (hour > 48) {
    day = Math.round(hour / 24);
  }
  return {
    hour,
    day,
  };
};
