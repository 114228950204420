import React from 'react';
import { connect } from 'react-redux';
import videojs from 'video.js';
import VREPlayer from 'videojs-react-enhanced';
import 'video.js/dist/video-js.css';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import '~features/detailpage/videoComponents';
import 'videojs-contrib-quality-levels';
import { drmEme } from '~features/detailpage/function/drmEme';
import { drmShaka } from '~features/detailpage/function/drmShaka';
import { isDrmContent } from '~core/method/contentMethod';

const vhsConfigs = {
  overrideNative: !videojs.browser.IS_SAFARI,
  useDevicePixelRatio: true,
  limitRenditionByPlayerDimensions: false,
  // smoothQualityChange: true,
  bandwidth: 6194304,
};

export const Player = props => {
  const { i18n, t } = useTranslation();
  const {
    src = '',
    controls = true,
    autoplay = !isMobile ? 'muted' : false,
    muted = true,
    onReady,
    id,
    content,
  } = props;

  const playerOptions = {
    src: isDrmContent.call(content) ? '' : src,
    controls,
    autoplay,
    muted,
    id,
    playsinline: true,
    'webkit-playsinline': true,
  };
  const videojsOptions = {
    language: i18n.language,
    html5: {
      nativeTextTracks: true,
      vhs: { ...vhsConfigs },
      nativeAudioTracks: false,
      nativeVideoTracks: false,
    },
    controlBar: {
      pictureInPictureToggle: false,
      subsCapsButton: false,
    },
  };
  const handleOnReady = player => {
    player.on('play', function () {});

    // let qualityLevels = player.qualityLevels()
    // qualityLevels.on('change', function () {
    //   console.log('Quality Level changed!')
    //   console.log('New level:', qualityLevels[qualityLevels.selectedIndex])
    // })

    player.maxQualitySelector({
      autoLabel: `${t('projectw__player.qualityAuto')}`,
    });

    if (isDrmContent.call(content)) {
      // drmShaka(player, content);
      drmEme(player, content);
    }
    onReady(player);
  };

  return (
    <VREPlayer
      videojsOptions={videojsOptions}
      playerOptions={playerOptions}
      {...props}
      onReady={handleOnReady}
    />
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Player);
