var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.OdxGeoBlockingApi = void 0;
var integration_service_1 = require("../integration-service");
var OdxGeoBlockingApi = /** @class */ (function (_super) {
    __extends(OdxGeoBlockingApi, _super);
    function OdxGeoBlockingApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OdxGeoBlockingApi.prototype.checkAllowedCountry = function (tenantSlug, authorization, acceptLanguage, checkPermission, clientIp, options) {
        return Promise.reject('Method not implemented');
    };
    OdxGeoBlockingApi.prototype.checkAllowedCountry_1 = function (tenantSlug, authorization, acceptLanguage, checkPermission, clientIp, options) {
        return Promise.reject('Method not implemented');
    };
    OdxGeoBlockingApi.prototype.getGeoInfo = function (tenantSlug, authorization, acceptLanguage, clientIp, options) {
        return Promise.reject('Method not implemented');
    };
    return OdxGeoBlockingApi;
}(integration_service_1.OdxIntegration));
exports.OdxGeoBlockingApi = OdxGeoBlockingApi;
