import { Filter, IAxiosResponseData, Pagination } from '~core/models';
import { apiAdapter, config, axiosTakeDataFromResponse } from '~share';
import camelcaseKeys from 'camelcase-keys';
import { getConfigByKey } from '~features/tenantConfig/services';
import { isNode } from '~utils/ssr';
import {
  ContentApiInterface,
  ContentListingApiInterface,
  UserApiInterface,
  UserContentApiInterface,
} from '~vimai-api-adapter';
import { store } from '~store';

const { userService, contentService, contentListingService, userContentService } = apiAdapter;

const CONTENT_RIBBON_SELECT_FIELDS = [
  'id',
  'slug',
  'has_free_content',
  'is_new_release',
  'is_premium',
  'has_free_content',
  'content_categories',
  'total_episodes',
  'regions',
  'release_date',
  'released_episode_count',
  'images',
  'title',
  'video_source',
  'type',
  'top_index',
  'metadata',
  'long_description',
  'short_description',
  'duration',
  'runtime',
  'duration',
  'progress',
  'default_episode',
  'payment_type',
  'release_year',
  'trailers',
  'popover_data',
  'question',
  'answer',
];

export { CONTENT_RIBBON_SELECT_FIELDS };
export const CONTENT_METADATA = ['regions', 'genres'];

export const CONTENT_RIBBON_BANNER_SELECT_FIELDS = [
  'num_first_episode_preview',
  'slug',
  'id',
  'is_premium',
  'type',
  'is_watchable',
  'long_description',
  'short_description',
  'is_new_release',
  'title',
  'has_free_content',
  'images',
  'metadata',
  'payment_type',
];

export const CONTENT_RIBBON_DETAIL_SELECT_FIELDS = [
  'display_type',
  'id',
  'items',
  'name',
  'odr',
  'show_flag_odr',
  'slug',
  'type',
  'is_visible_in_ribbon_main_section',
  'is_default_display',
  'sub_name',
  'sub_name_ko',
  'sub_name_vi',
  'payment_type',
];

export function getViewContentsByIds(
  ids: string[] = [],
): Promise<IAxiosResponseData<ContentApiInterface['getViewInfoOfContents']>> {
  const select = JSON.stringify({
    Content: [
      'link_play',
      'id',
      // 'trailer_urls',
      // 'program_urls',
      'geo_blocked',
      'drm_session_info',
      'play_info',
      'is_watchable',
    ],
  });

  const { tenantSlug = '', authorization, imageResolutionScale, imageFormat } = config;
  return contentService
    .getViewInfoOfContents(
      tenantSlug,
      ids,
      authorization,
      undefined,
      select,
      imageResolutionScale,
      imageFormat,
    )
    .then(axiosTakeDataFromResponse);
}

export const channelInfo = (
  contentId: string,
): Promise<IAxiosResponseData<UserApiInterface['getOrCreateChannelInfo']>> => {
  const { tenantSlug = '' } = config;
  return userService.getOrCreateChannelInfo(contentId, tenantSlug).then(axiosTakeDataFromResponse);
};

export const getRibbons = (
  params: {
    id: string;
    slug: string;
  } & Filter &
    Pagination,
): Promise<IAxiosResponseData<ContentListingApiInterface['getPageStaticRibbons']>> => {
  const select = JSON.stringify({
    Content: CONTENT_RIBBON_SELECT_FIELDS,
    Banner: CONTENT_RIBBON_BANNER_SELECT_FIELDS,
    RibbonDetail: CONTENT_RIBBON_DETAIL_SELECT_FIELDS,
    ContentMetadata: CONTENT_METADATA,
  });

  const {
    id,
    slug,
    limit,
    page,
    genreSlug,
    languageSlug,
    regionSlug,
    peopleSlugs,
    providerSlug,
    categorySlug,
  } = camelcaseKeys(params);

  let limitDefault = limit || 10;
  if (!limit && getConfigByKey('ui_theme') === 'projectw') {
    limitDefault = -1;
  }

  const { tenantSlug = '', imageResolutionScale, imageFormat, platformSlug } = config;

  return contentListingService
    .getPageStaticRibbons(
      tenantSlug,
      slug || id,
      undefined,
      select,
      page,
      limitDefault,
      imageResolutionScale,
      platformSlug,
      genreSlug,
      languageSlug,
      regionSlug,
      peopleSlugs,
      providerSlug,
      categorySlug,
      imageFormat,
    )
    .then(axiosTakeDataFromResponse);
};

export const getPersonalizedRibbons = (
  params: {
    id: string;
    slug: string;
  } & Filter &
    Pagination,
): Promise<IAxiosResponseData<ContentListingApiInterface['getPagePersonalizedRibbons']> | void> => {
  const select = JSON.stringify({
    PageDetail: ['ribbons', 'display_style', 'seo', 'name'],
    Content: CONTENT_RIBBON_SELECT_FIELDS,
  });
  const {
    id,
    slug,
    limit,
    page,
    genreSlug,
    languageSlug,
    regionSlug,
    peopleSlugs,
    providerSlug,
    categorySlug,
  } = camelcaseKeys(params);
  let limitDefault = limit || 10;
  if (!limit && getConfigByKey('ui_theme') === 'projectw') {
    limitDefault = -1;
  }

  if (isNode()) return Promise.resolve();

  const {
    accountId = '',
    tenantSlug = '',
    authorization,
    imageResolutionScale,
    imageFormat,
    platformSlug,
  } = config;

  return contentListingService
    .getPagePersonalizedRibbons(
      accountId,
      tenantSlug,
      slug || id,
      authorization,
      undefined,
      select,
      imageResolutionScale,
      platformSlug,
      page,
      limitDefault,
      genreSlug,
      languageSlug,
      regionSlug,
      peopleSlugs,
      providerSlug,
      categorySlug,
      imageFormat,
    )
    .then(axiosTakeDataFromResponse);
};

export const getFavoriteContents = (params: {
  contentIds: string;
}): Promise<IAxiosResponseData<UserContentApiInterface['getAccountInteractedContents']> | void> => {
  const { account } = (store.getState() as any).auth;
  if (!account) {
    return Promise.resolve();
  }
  const { accountId = '', tenantSlug = '', acceptLanguage, authorization } = config;
  const { contentIds } = params;

  return userContentService
    .getAccountInteractedContents(
      accountId,
      tenantSlug,
      authorization,
      acceptLanguage,
      undefined,
      undefined,
      undefined,
      undefined,
      contentIds,
    )
    .then(axiosTakeDataFromResponse);
};

export const RemoveContiueWatching = (
  contentId: string,
): Promise<IAxiosResponseData<UserContentApiInterface['removeContentFromWatchingList']> | void> => {
  const { account } = (store.getState() as any).auth;
  if (!account) {
    return Promise.resolve();
  }
  const { accountId = '', tenantSlug = '', acceptLanguage, authorization } = config;
  return userContentService
    .removeContentFromWatchingList(accountId, contentId, tenantSlug, authorization, acceptLanguage)
    .then(axiosTakeDataFromResponse);
};
