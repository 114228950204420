import _uniqueBy from 'lodash/uniqBy';
import * as actions from './actions';
import { addHours } from 'date-fns';
import * as liveScheduleApi from '../../api/live-schedule';
import { getConfigByKey } from '~features/tenantConfig/services';

export function getLiveSchedules(pageSlug) {
  return dispatch => {
    const currentDate = new Date();
    const currentBlocks = Math.floor(currentDate.getHours() / 6);
    const arrayBlocks = [
      currentBlocks - 4,
      currentBlocks - 3,
      currentBlocks - 2,
      currentBlocks - 1,
      currentBlocks,
      currentBlocks + 1,
      currentBlocks + 2,
      currentBlocks + 3,
      currentBlocks + 4,
    ];
    return Promise.all(arrayBlocks.map(block => getLiveScheduleByBlock(pageSlug, block))).then(
      ([block1, block2, block3, block4, block5, block6, block7, block8, block9]) => {
        const ribbons = [...block1.ribbons].map(({ channels, ...args }) => args);
        const channels = {};
        const episodes = {};
        block1.ribbons.forEach(
          rb => (channels[rb.id] = rb.channels.map(({ epgs, ...args }) => args)),
        );
        const blocks = [block1, block2, block3, block4, block5, block6, block7, block8, block9];
        blocks.forEach(block => {
          block.ribbons.forEach(rb => {
            rb.channels.forEach(channel => {
              const epgs = episodes[channel.id]
                ? episodes[channel.id].concat(channel.epgs)
                : channel.epgs;
              episodes[channel.id] = _uniqueBy(epgs, 'id');
            });
          });
        });

        // Adding empty block to the head
        Object.keys(episodes).forEach(cnId => {
          if (episodes[cnId][0]) {
            episodes[cnId].unshift({
              start_at: new Date().setHours(0, 0, 0, 0) / 1e3,
              end_at: episodes[cnId][0].start_at,
            });
          }
        });
        dispatch(
          actions.getLiveSchedule({
            ribbons,
            channels,
            episodes,
          }),
        );
      },
    );
  };
}

function getLiveScheduleByBlock(pageSlug, timeBlock = 0) {
  const start = new Date();
  const startHour = timeBlock * 6;
  start.setHours(startHour, 0, 0, 0);
  const end = addHours(start, 6);
  return liveScheduleApi.getLiveSchedules(pageSlug, {
    start_time: Math.floor(start.getTime() / 1e3),
    end_time: Math.round(end.getTime() / 1e3),
    timezone: getConfigByKey('timezone'),
  });
}

export function updateCurrentLive(id) {
  return dispatch => {
    dispatch(actions.updateCurrentLive(id));
  };
}

export const setActiveRibbonId = ribbonId => {
  return dispatch => {
    dispatch(actions.setActiveRibbonId(ribbonId));
  };
};

export const updateLivePage = data => {
  return dispatch => {
    dispatch(actions.updateLivePage(data));
  };
};

export const setVolume = value => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(actions.setVolume(value));
      resolve(true);
    });
  };
};
