/* eslint-disable arrow-body-style */
import { Subset } from '~core/typing';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuth } from '~core/models';
import _ from 'lodash';

const initialState: IAuth = {
  isLogin: false,
  account: null,
  resetpass: {},
  methodLogin: null,
  locale: {},
  isShowLogin: false,
  paidContents: [],
  isHasSubcription: false,
  rememberAccount: {
    email: null,
    checkbox: false,
  },
  guestAccount: null,
  paymentMethod: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<IAuth['account']>) => {
      const { payload: account } = action;
      const profile: any = account ? account?.profile : null;
      return {
        ...state,
        guestAccount: null,
        account: !_.isEmpty(account) ? account : null,
        methodLogin: account ? account.login_method || null : null,
        isLogin: true,
        isShowLogin: false,
        isHasSubcription: profile ? !!profile.has_subscription : false,
      };
    },
    resetPassSuccess: (state, action: PayloadAction<Subset<IAuth['resetpass']>>) => {
      const { payload } = action;
      return {
        ...state,
        resetpass: {
          otp: payload.otp,
          reset: payload.reset,
        },
      };
    },
    // eslint-disable-next-line arrow-body-style
    resetPassFailure: state => {
      return {
        ...state,
        resetpass: {
          otp: undefined,
          reset: false,
        },
      };
    },
    requestCodeSuccess: (state, action: PayloadAction<IAuth['resetpass']['otp']>) => {
      const { payload } = action;
      return {
        ...state,
        resetpass: {
          otp: payload,
        },
      };
    },
    requestCodeFailure: (state, action: PayloadAction<IAuth['resetpass']['otp']>) => {
      const { payload } = action;
      return {
        ...state,
        resetpass: {
          otp: payload,
        },
      };
    },
    logout: state => {
      return {
        ...state,
        account: null,
        isLogin: false,
        paidContents: [],
        isHasSubcription: false,
      };
    },
    updateAccountPaymentMethod: (state, action: PayloadAction<IAuth['paymentMethod']>) => {
      return {
        ...state,
        account: {
          ...state.account,
          paypalMethod: [],
          paymentMethod: action.payload,
        },
      };
    },
    updateAccountPaymentMethodPaypal: (state, action: PayloadAction<IAuth['paymentMethod']>) => {
      return {
        ...state,
        account: {
          ...state.account,
          paymentMethod: [],
          paypalMethod: action.payload,
        },
      };
    },
    updateIP: (state, action: PayloadAction<IAuth['locale']['ip']>) => {
      return {
        ...state,
        locale: {
          ip: action.payload,
        },
      };
    },
    setIsShowLogin: (state, action: PayloadAction<IAuth['isShowLogin']>) => {
      return {
        ...state,
        isShowLogin: action.payload,
      };
    },
    updateHasSupscription: (
      state,
      action: PayloadAction<NonNullable<IAuth['account']>['profile']>,
    ) => {
      const { payload } = action;
      return {
        ...state,
        account: {
          ...state.account,
          profile: {
            ...state.account?.profile,
            has_subscription: payload ? !!payload.has_subscription : false,
          },
        },
        isHasSubcription: payload ? !!payload.has_subscription : false,
      };
    },
    setPaidContents: (state, action: PayloadAction<IAuth['paidContents']>) => {
      return {
        ...state,
        paidContents: action.payload,
      };
    },
    resetPaymentMethod: state => {
      return {
        ...state,
        account: null,
      };
    },
    rememberAccount: (state, action: PayloadAction<IAuth['rememberAccount']>) => {
      return {
        ...state,
        rememberAccount: action.payload,
      };
    },
    loginGuestAccount: (state, action: PayloadAction<IAuth['guestAccount']>) => {
      return {
        ...state,
        account: null,
        isLogin: false,
        paidContents: [],
        isHasSubcription: false,
        guestAccount: action.payload,
      };
    },
    removeGuestAccount: state => {
      return {
        ...state,
        guestAccount: null,
      };
    },
    loginGuestSuccess: (state, action: PayloadAction<IAuth['guestAccount']>) => {
      const { payload } = action;
      return {
        ...state,
        guestAccount: typeof payload === 'object' ? payload : null,
      };
    },
    updateProfile: (state, action: PayloadAction<NonNullable<IAuth['account']>['profile']>) => {
      const { payload } = action;
      return {
        ...state,
        account: {
          ...state.account,
          profile: payload,
        },
      };
    },
    updateEmail: (
      state,
      action: PayloadAction<NonNullable<NonNullable<IAuth['account']>['profile']>['email']>,
    ) => {
      const { payload } = action;
      return {
        ...state,
        account: {
          ...(state.account ?? {}),
          profile: {
            ...(state?.account?.profile ?? {}),
            email: payload,
            email_confirmed: false,
          },
        },
      };
    },
    updateFirstName: (
      state,
      action: PayloadAction<NonNullable<NonNullable<IAuth['account']>['profile']>['first_name']>,
    ) => {
      const { payload } = action;
      return {
        ...state,
        account: {
          ...state.account,
          profile: {
            ...(state?.account?.profile ?? {}),
            first_name: payload,
          },
        },
      };
    },
    updateAccountSubscription: (
      state,
      action: PayloadAction<NonNullable<NonNullable<IAuth['account']>['profile']>>,
    ) => {
      const { payload } = action;
      const { account } = state;
      if (account) {
        return {
          ...state,
          account: null,
          isHasSubcription: false,
        };
      }
      return {
        ...state,
        account: {
          ...state.account,
          profile: {
            ...state?.account?.profile,
            has_subscription: Boolean(payload.has_subscription),
          },
        },
        isHasSubcription: Boolean(payload.has_subscription),
      };
    },
  },
});

export default authSlice.reducer;

export const { actions } = authSlice;
