import pushAnalytics from './../../../services/analytics/Analytics';
import { slugify, getCategories } from './../../../utils/utils';

const pushEventFirebase = (entityDetail, itemName, events = 'click') => {
  if (entityDetail.type === 4) {
    entityDetail = {
      ...entityDetail,
      type: 2,
    };
  }
  let btn = itemName === 'subtitle' ? 'menu' : 'button';
  let programName = slugify(
    entityDetail.current_season ? entityDetail.current_season.slug : entityDetail.slug,
  );
  let category = getCategories(entityDetail);
  let contentProvider = entityDetail && entityDetail.provider_slug;
  if (entityDetail.infoShow) {
    programName = entityDetail.infoShow.slug;
  }
  let data = {
    content_type: btn,
    item_name: itemName,
    episode_name: `${programName}`,
    program_name: programName,
    content_provider: contentProvider,
    category: category,
  };
  if (entityDetail.track) {
    data = { ...data, item_value: entityDetail.track.label };
  }
  if (entityDetail.type === 2) {
    data = {
      ...data,
      episode_name: `${programName}-eps${entityDetail.episode}`,
    };
  }
  if (entityDetail.entityTrailer) {
    data = { ...data, episode_name: `${entityDetail.entityTrailer.slug}` };
  }
  pushAnalytics(events, data);
};

const pushEventFirebasePlayer = (entityDetail, events, timer = null) => {
  if (!entityDetail) return;
  if (entityDetail.type === 4) {
    entityDetail = {
      ...entityDetail,
      type: 2,
    };
  }

  let programName = slugify(
    entityDetail.current_season ? entityDetail.current_season.slug : entityDetail.slug,
  );
  let category = getCategories(entityDetail);
  let contentProvider = entityDetail && entityDetail.provider_slug;
  if (entityDetail.infoShow) {
    programName = entityDetail.infoShow.slug;
  }
  let data = {
    episode_name: `${programName}`,
    program_name: programName,
    content_provider: contentProvider,
    category: category,
  };
  if (timer) {
    data = { ...data, viewhour_seconds: timer };
  }
  if (entityDetail.type === 2) {
    data = {
      ...data,
      episode_name: `${programName}-eps${entityDetail.episode}`,
    };
  }
  if (entityDetail.entityTrailer) {
    data = { ...data, episode_name: `${entityDetail.entityTrailer.slug}` };
  }
  pushAnalytics(events, data);
};

export { pushEventFirebase, pushEventFirebasePlayer };
