import queryString from 'query-string';

import camelcaseKeys from 'camelcase-keys';
import * as landingPageApi from './landdingPageApi';
import { apiAdapter, config, axiosTakeDataFromResponse } from '~share';
import { Filter, Pagination, IAxiosResponseData } from '~core/models';

const { contentService, contentListingService, userContentService } = apiAdapter;

const CONTENT_RIBBON_DETAIL_SELECT_FIELDS = ['display_type', 'id', 'name', 'type', 'items', 'seo'];

const CONTENT_RIBBON_DETAIL_ITEMS_SELECT_FIELDS = [
  'category_slug',
  'content_categories',
  'images',
  'is_new_release',
  'is_premium',
  'has_free_content',
  'id',
  'slug',
  'released_episode_count',
  'title',
  'top_index',
  'type',
  'video_source',
  'regions',
  'release_date',
  'released_episode_count',
  'total_episodes',
  'metadata',
  'runtime',
  'duration',
  'progress',
  'default_episode',
  'short_description',
  'long_description',
  'payment_type',
  'question',
  'answer',
];

const CONTENT_VIEWS_SELECT_FIELDS = ['current_season'];
export const CONTENT_METADATA = ['regions', 'genres', 'sub_categories'];

const CONTENT_FAVORITE_SELECT_FIELDS = [
  'type',
  'released_episode_count',
  'total_episodes',
  'video_source',
  'images',
  'id',
  'slug',
  'title',
  'regions',
  'release_date',
  'released_episode_count',
  'top_index',
  'metadata',
  'duration',
  'short_description',
];

const CONTENT_SEARCH_YOUTUBE_PAGE = ['items', 'suggestion_items', 'keyword'];
const SEARCH_PAGE_CONTENT_ITEMS_SELECT_FIELDS = [
  'can_preview',
  'content_categories',
  'has_free_content',
  'id',
  'images',
  'is_new_release',
  'is_premium',
  'is_watchable',
  'num_first_episode_preview',
  'slug',
  'title',
  'type',
  'video_source',
  'payment_type',
];
const CONTENT_DETAIL_SELECT_FIELDS = [
  'short_description',
  'long_description',
  'images',
  'genre',
  'content_categories',
  'title',
  'title_en',
  'type',
  'eps',
  'metadata',
  'provider_slug',
];
const CONTENT_RIBBON_VOD = [
  'banners',
  'ribbons',
  'top_contents',
  'banner_style',
  'display_style',
  'side_navigation_section',
  'ribbons_in_subcategory_section',
  'seo',
  'name',
];

function getRibbonDetail(
  pageSlug: string,
  ribbonSlug: string,
  search = '',
): Promise<IAxiosResponseData<typeof contentListingService.getRibbonDetail>> {
  const params: Filter & Pagination = queryString.parse(search);
  const {
    page,
    limit,
    genreSlug,
    languageSlug,
    regionSlug,
    peopleSlugs,
    providerSlug,
    categorySlug,
  } = camelcaseKeys(params);
  const select = JSON.stringify({
    RibbonDetailPaging: CONTENT_RIBBON_DETAIL_SELECT_FIELDS,
    Content: CONTENT_RIBBON_DETAIL_ITEMS_SELECT_FIELDS,
    ContentMetadata: CONTENT_METADATA,
  });

  const {
    tenantSlug = '',
    authorization,
    imageResolutionScale,
    imageFormat,
    platformSlug,
    acceptLanguage,
  } = config;
  return contentListingService
    .getRibbonDetail(
      pageSlug,
      ribbonSlug,
      tenantSlug,
      authorization,
      acceptLanguage,
      page,
      limit,
      imageResolutionScale,
      platformSlug,
      select,
      genreSlug,
      languageSlug,
      regionSlug,
      peopleSlugs,
      providerSlug,
      categorySlug,
      undefined,
      imageFormat,
    )
    .then(axiosTakeDataFromResponse);
}

function searchYoutubePage(
  params: { keyword: string } & Pagination,
  pageSlug: string,
): Promise<IAxiosResponseData<typeof contentService.searchContentInPage>> {
  const select = JSON.stringify({
    Search: CONTENT_SEARCH_YOUTUBE_PAGE,
    YOUTUBE: SEARCH_PAGE_CONTENT_ITEMS_SELECT_FIELDS,
  });
  const { keyword, page, limit } = params;
  const { tenantSlug = '', authorization, imageResolutionScale, imageFormat } = config;
  return contentService
    .searchContentInPage(
      pageSlug,
      tenantSlug,
      keyword,
      authorization,
      select,
      imageResolutionScale,
      imageFormat,
      page,
      limit,
    )
    .then(axiosTakeDataFromResponse);
}

function getRibbonDetailRecent(
  pageSlug: string,
  ribbonSlug: string,
  search = '',
): Promise<IAxiosResponseData<typeof contentListingService.getPersonalizedRibbonDetail>> {
  const params: Filter & Pagination = queryString.parse(search);
  const {
    page,
    limit,
    genreSlug,
    languageSlug,
    regionSlug,
    peopleSlugs,
    providerSlug,
    categorySlug,
  } = camelcaseKeys(params);
  const select = JSON.stringify({
    RibbonDetailPaging: CONTENT_RIBBON_DETAIL_SELECT_FIELDS,
    Content: CONTENT_RIBBON_DETAIL_ITEMS_SELECT_FIELDS,
  });
  const {
    accountId = '',
    tenantSlug = '',
    authorization,
    imageResolutionScale,
    imageFormat,
    platformSlug,
    acceptLanguage,
  } = config;

  return contentListingService
    .getPersonalizedRibbonDetail(
      accountId,
      pageSlug,
      ribbonSlug,
      tenantSlug,
      authorization,
      acceptLanguage,
      select,
      imageResolutionScale,
      platformSlug,
      page,
      limit,
      genreSlug,
      languageSlug,
      regionSlug,
      peopleSlugs,
      providerSlug,
      categorySlug,
      imageFormat,
    )
    .then(axiosTakeDataFromResponse);
}

function deleteProgress(
  contentId: string,
): Promise<IAxiosResponseData<typeof userContentService.removeContentFromHistory>> | void {
  const { authorization, acceptLanguage } = config;

  if (!authorization) {
    return;
  }

  return userContentService
    .removeContentFromHistory(contentId, authorization, acceptLanguage)
    .then(axiosTakeDataFromResponse);
}

function getTopTen(
  pageSlug: string,
): Promise<IAxiosResponseData<typeof contentService.getTopContents>> {
  const select = JSON.stringify({
    Content: CONTENT_FAVORITE_SELECT_FIELDS,
    ContentMetadata: CONTENT_METADATA,
  });
  const { tenantSlug = '', imageResolutionScale, imageFormat, acceptLanguage } = config;
  return contentService
    .getTopContents(tenantSlug, pageSlug, acceptLanguage, select, imageResolutionScale, imageFormat)
    .then(axiosTakeDataFromResponse);
}

function getDetailContentById(
  contentId: string,
): Promise<IAxiosResponseData<typeof landingPageApi.viewDetail>> {
  return landingPageApi.viewDetail(contentId);
}

export function getViewContentsByIds(
  ids: string[] = [],
): Promise<IAxiosResponseData<typeof contentService.getViewInfoOfContents>> {
  const select = JSON.stringify({
    Content: [
      'link_play',
      'id',
      // 'trailer_urls',
      // 'program_urls',
      'geo_blocked',
      'drm_session_info',
      'play_info',
    ],
  });
  const {
    tenantSlug = '',
    authorization,
    imageResolutionScale,
    imageFormat,
    acceptLanguage,
  } = config;

  return contentService
    .getViewInfoOfContents(
      tenantSlug,
      ids,
      authorization,
      acceptLanguage,
      select,
      imageResolutionScale,
      imageFormat,
    )
    .then(axiosTakeDataFromResponse);
}

function getRibbonVOD(
  pageSlug: string,
): Promise<IAxiosResponseData<typeof contentListingService.getPageStaticRibbons>> {
  const select = JSON.stringify({
    PageDetail: CONTENT_RIBBON_VOD,
    RibbonDetailPaging: CONTENT_RIBBON_DETAIL_SELECT_FIELDS,
    Content: CONTENT_RIBBON_DETAIL_ITEMS_SELECT_FIELDS,
    ContentMetadata: CONTENT_METADATA,
  });
  const {
    tenantSlug = '',
    imageResolutionScale,
    imageFormat,
    platformSlug,
    acceptLanguage,
  } = config;
  return contentListingService
    .getPageStaticRibbons(
      tenantSlug,
      pageSlug,
      acceptLanguage,
      select,
      undefined,
      undefined,
      imageResolutionScale,
      platformSlug,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      imageFormat,
    )
    .then(axiosTakeDataFromResponse);
}

function getRibbonVOD_Personalized(
  pageSlug: string,
): Promise<IAxiosResponseData<typeof contentListingService.getPagePersonalizedRibbons>> {
  const select = JSON.stringify({
    PageDetail: CONTENT_RIBBON_VOD,
    RibbonDetailPaging: CONTENT_RIBBON_DETAIL_SELECT_FIELDS,
    Content: CONTENT_RIBBON_DETAIL_ITEMS_SELECT_FIELDS,
    ContentMetadata: CONTENT_METADATA,
  });
  const {
    accountId = '',
    tenantSlug = '',
    authorization,
    imageResolutionScale,
    imageFormat,
    platformSlug,
    acceptLanguage,
  } = config;

  return contentListingService
    .getPagePersonalizedRibbons(
      accountId,
      tenantSlug,
      pageSlug,
      authorization,
      acceptLanguage,
      select,
      imageResolutionScale,
      platformSlug,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      imageFormat,
    )
    .then(axiosTakeDataFromResponse);
}

function getViews(
  slug: string,
): Promise<IAxiosResponseData<typeof contentService.getContentViewInfo>> {
  const select = JSON.stringify({
    Content: CONTENT_VIEWS_SELECT_FIELDS,
  });
  const { tenantSlug = '', authorization, imageResolutionScale, acceptLanguage } = config;

  return contentService
    .getContentViewInfo(
      slug,
      tenantSlug,
      authorization,
      acceptLanguage,
      select,
      imageResolutionScale,
    )
    .then(axiosTakeDataFromResponse);
}

export {
  getRibbonDetail,
  deleteProgress,
  getRibbonDetailRecent,
  getTopTen,
  searchYoutubePage,
  getDetailContentById,
  getRibbonVOD,
  getRibbonVOD_Personalized,
  getViews,
};
