import React, { useState } from 'react';
import styled from 'styled-components';
import RibbonList from './ribbonList';
import RibbonChannelList from './ribbonChannelList';
import RibbonCurrentChannel from './ribbonCurrentChannel';
import LineIndicator from './lineIndicator';
import RibbonChannelNavigator from './ribbonChannelNavigator';
export default function () {
  const today = new Date();
  const landmarkActive = 12 - Math.floor(today.getHours() % 2);
  const [landmark, setLandmark] = useState(landmarkActive);
  const setHourLandmark = () => {
    let tmp = Math.floor(new Date().getHours());
    let arr = [];
    if (tmp % 2 !== 0) {
      tmp += 1;
    }
    for (let i = -12; i < 12; i++) {
      let hour = i * 2;
      arr.push(new Date().setHours(tmp + hour, 0));
    }
    return arr;
  };
  // const hourLandmarks = [
  //   new Date().setHours(0, 0),
  //   new Date().setHours(2, 0),
  //   new Date().setHours(4, 0),
  //   new Date().setHours(6, 0),
  //   new Date().setHours(8, 0),
  //   new Date().setHours(10, 0),
  //   new Date().setHours(12, 0),
  //   new Date().setHours(14, 0),
  //   new Date().setHours(16, 0),
  //   new Date().setHours(18, 0),
  //   new Date().setHours(20, 0),
  //   new Date().setHours(22, 0),
  // ]
  const hourLandmarks = setHourLandmark();
  let padding = 15;
  if (window.innerWidth >= 1680) padding = 70;
  const widthSchedule = window.innerWidth - 250 - 140 - padding;

  return (
    <StyledList>
      <RibbonList />
      <div style={{ position: 'relative' }}>
        <RibbonCurrentChannel />
        <RibbonChannelNavigator
          landmark={landmark}
          hourLandmarks={hourLandmarks}
          onFirstPage={() => setLandmark(0)}
          onChange={v => setLandmark(landmark + v)}
        >
          <LineIndicator timelineFrom={hourLandmarks[landmark]} widthSchedule={widthSchedule} />
        </RibbonChannelNavigator>
        <RibbonChannelList timelineFrom={hourLandmarks[landmark]} widthSchedule={widthSchedule}>
          {/* <LineIndicator timelineFrom={hourLandmarks[landmark]} /> */}
        </RibbonChannelList>
      </div>
    </StyledList>
  );
}

const StyledList = styled.div`
  display: grid;
  grid-template-columns: 250px auto;
  margin-top: 300px;
  padding-left: 15px;
  @media (min-width: 1680px) {
    padding-left: 70px;
  }
`;
