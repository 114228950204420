var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSeasonDetailRequest = exports.getContentViewInfoForChannel = exports.getContentViewInfoForChildrenContent = exports.getContentViewInfoForProgram = exports.getContentDetailForLiveChannel = exports.getContentDetailForChildrenContent = exports.getContentDetailForProgram = void 0;
var mappers_1 = require("../mappers");
var integration_service_1 = require("../integration-service");
var parser_1 = require("../parser");
var content_utils_1 = require("../utils/content-utils");
var utils_1 = require("../utils");
var cacheable_requests_1 = require("./cacheable-requests");
function getTrailersForProgram(client, contentId, contentSlug, tenantSlug, acceptLanguage) {
    return __awaiter(this, void 0, void 0, function () {
        var trailerData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, cacheable_requests_1.CacheableRequests.getProgramTrailers(contentSlug, tenantSlug, acceptLanguage)
                        .then(function (r) { var _a; return (_a = r.data.result) === null || _a === void 0 ? void 0 : _a.results; })
                        .catch(function () { return []; })];
                case 1:
                    trailerData = _a.sent();
                    return [2 /*return*/, (trailerData === null || trailerData === void 0 ? void 0 : trailerData.map(function (item) { return (0, mappers_1.mapOdxEpisodeListToTrailer)(item); })) || []];
            }
        });
    });
}
function getEpisodesOfProgram(client, programSlug, tenantSlug, acceptLanguage, page, pageSize) {
    var _a, _b, _c, _d;
    return __awaiter(this, void 0, void 0, function () {
        var pageResult, base, total;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0: return [4 /*yield*/, cacheable_requests_1.CacheableRequests.getProgramEpisodes(programSlug, tenantSlug, acceptLanguage, page, pageSize).catch(function () { return null; })];
                case 1:
                    pageResult = _e.sent();
                    if (!!pageResult) return [3 /*break*/, 3];
                    return [4 /*yield*/, cacheable_requests_1.CacheableRequests.getProgramEpisodes(programSlug, tenantSlug, acceptLanguage, 1, 1).catch(function () { return null; })];
                case 2:
                    base = _e.sent();
                    _e.label = 3;
                case 3:
                    total = ((_b = (_a = pageResult === null || pageResult === void 0 ? void 0 : pageResult.data) === null || _a === void 0 ? void 0 : _a.result) === null || _b === void 0 ? void 0 : _b.count) || ((_d = (_c = base.data) === null || _c === void 0 ? void 0 : _c.result) === null || _d === void 0 ? void 0 : _d.count);
                    return [2 /*return*/, pageResult
                            ? pageResult.data
                            : __assign(__assign({}, base.data), { result: __assign(__assign({}, base.data.result), { count: total, page_next: null, page_previous: page - 1 || null, page_size: pageSize, results: [] }) })];
            }
        });
    });
}
function getContentDetailForProgram(client, contentId, contentSlug, tenantSlug, acceptLanguage) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function () {
        var trailers, contentData, program, playback;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    trailers = [];
                    return [4 /*yield*/, cacheable_requests_1.CacheableRequests.getProgramInformation(contentSlug, tenantSlug, acceptLanguage)];
                case 1:
                    contentData = _d.sent();
                    program = (_a = contentData === null || contentData === void 0 ? void 0 : contentData.data) === null || _a === void 0 ? void 0 : _a.result;
                    playback = null;
                    if (!(0, content_utils_1.hasTrailer)(program)) return [3 /*break*/, 3];
                    return [4 /*yield*/, getTrailersForProgram(client, contentId, contentSlug, tenantSlug, acceptLanguage)];
                case 2:
                    trailers = _d.sent();
                    _d.label = 3;
                case 3:
                    if (!((0, content_utils_1.isMovie)(program) && ((_b = program === null || program === void 0 ? void 0 : program.direct_play_episode) === null || _b === void 0 ? void 0 : _b.id))) return [3 /*break*/, 5];
                    return [4 /*yield*/, cacheable_requests_1.CacheableRequests.getEpisodePlayback((_c = program.direct_play_episode) === null || _c === void 0 ? void 0 : _c.id, tenantSlug, acceptLanguage)
                            .then(function (r) { return r.data.result; })
                            .catch(function () { return null; })];
                case 4:
                    playback = _d.sent();
                    _d.label = 5;
                case 5: return [2 /*return*/, __assign(__assign({}, contentData), { data: (0, parser_1.CombineProgramAndTrailersToContentDetail)(program, trailers, playback) })];
            }
        });
    });
}
exports.getContentDetailForProgram = getContentDetailForProgram;
function getEpisodeInfo(client, tenantSlug, acceptLanguage, episodeId, raiseForPlayback, adParams) {
    var _a, _b, _c, _d;
    if (raiseForPlayback === void 0) { raiseForPlayback = false; }
    if (adParams === void 0) { adParams = ''; }
    return __awaiter(this, void 0, void 0, function () {
        var xAdParams, isLat, did, adid, data;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    xAdParams = integration_service_1.OdxIntegration.currentProvider.get('adParams');
                    isLat = (0, utils_1.getValueOfKeyPairString)(xAdParams, 'is_lat');
                    did = (0, utils_1.getValueOfKeyPairString)(xAdParams, 'did');
                    adid = (0, utils_1.getValueOfKeyPairString)(xAdParams, 'adid');
                    return [4 /*yield*/, Promise.all([
                            cacheable_requests_1.CacheableRequests.getEpisodeInformation(episodeId, tenantSlug, acceptLanguage),
                            client.odxPlaybackApi
                                .playbackRetrieve(episodeId.toString(), tenantSlug, undefined, acceptLanguage, isLat ? decodeURIComponent(isLat) : undefined, did ? decodeURIComponent(did) : undefined, adid ? decodeURIComponent(adid) : undefined)
                                .catch(function (e) { return (raiseForPlayback ? (0, utils_1.raiseForPlaybackErrorResponse)(e, true) : null); }),
                        ])];
                case 1:
                    data = _e.sent();
                    return [2 /*return*/, {
                            episodeInfo: (_b = (_a = data[0]) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.result,
                            episodePlayback: (_d = (_c = data[1]) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.result,
                        }];
            }
        });
    });
}
function getContentDetailForChildrenContent(client, contentIdentity, tenantSlug, acceptLanguage) {
    return __awaiter(this, void 0, void 0, function () {
        var id, _a, episodeInfo, episodePlayback, result;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    id = (0, content_utils_1.getIdInfoFromContentIdentity)(contentIdentity).id;
                    return [4 /*yield*/, getEpisodeInfo(client, tenantSlug, acceptLanguage, id)];
                case 1:
                    _a = _b.sent(), episodeInfo = _a.episodeInfo, episodePlayback = _a.episodePlayback;
                    result = (0, parser_1.ParseEpisodeDetailFromOdxEpisodeAndOdxPlayback)(episodeInfo, episodePlayback);
                    return [2 /*return*/, {
                            status: 200,
                            config: null,
                            headers: null,
                            data: result,
                            statusText: 'OK',
                            request: null,
                        }];
            }
        });
    });
}
exports.getContentDetailForChildrenContent = getContentDetailForChildrenContent;
function getContentDetailForLiveChannel(client, contentId, tenantSlug, acceptLanguage) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function () {
        var xAdParams, isLat, did, adid, channelData, channel;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    xAdParams = integration_service_1.OdxIntegration.currentProvider.get('adParams');
                    isLat = (0, utils_1.getValueOfKeyPairString)(xAdParams, 'is_lat');
                    did = (0, utils_1.getValueOfKeyPairString)(xAdParams, 'did');
                    adid = (0, utils_1.getValueOfKeyPairString)(xAdParams, 'adid');
                    return [4 /*yield*/, client.odxEpgApi.epg(tenantSlug, acceptLanguage, undefined, parseInt(contentId, 10), undefined, undefined, undefined, undefined, undefined, undefined, isLat ? decodeURIComponent(isLat) : undefined, did ? decodeURIComponent(did) : undefined, adid ? decodeURIComponent(adid) : undefined)];
                case 1:
                    channelData = _d.sent();
                    channel = (_c = (_b = (_a = channelData.data) === null || _a === void 0 ? void 0 : _a.result) === null || _b === void 0 ? void 0 : _b.results) === null || _c === void 0 ? void 0 : _c[0];
                    if (!channel) {
                        throw new Error('Channel not found.');
                    }
                    return [2 /*return*/, __assign(__assign({}, channelData), { data: (0, mappers_1.mapOdxChannelsToContentDetail)(channel) })];
            }
        });
    });
}
exports.getContentDetailForLiveChannel = getContentDetailForLiveChannel;
function findProgramPreviousWatchedEpisode(programId) {
    var _a, _b, _c, _d;
    return __awaiter(this, void 0, void 0, function () {
        var currentUserId, tenantSlug, profileId, isPrimaryProfile, lastWatchedEpisodePromise;
        return __generator(this, function (_e) {
            currentUserId = (_a = integration_service_1.OdxIntegration.currentProvider) === null || _a === void 0 ? void 0 : _a.get('userId');
            tenantSlug = (_b = integration_service_1.OdxIntegration.currentProvider) === null || _b === void 0 ? void 0 : _b.get('tenantSlug');
            profileId = (_c = integration_service_1.OdxIntegration.currentProvider) === null || _c === void 0 ? void 0 : _c.get('profileId');
            isPrimaryProfile = (_d = integration_service_1.OdxIntegration.currentProvider) === null || _d === void 0 ? void 0 : _d.get('isPrimaryProfile');
            lastWatchedEpisodePromise = null;
            if (currentUserId) {
                lastWatchedEpisodePromise = integration_service_1.OdxIntegration.currentCwService.odxCW.cwV3GetCwByProfileProgram(tenantSlug, parseInt(currentUserId || '0', 10), parseInt(profileId || '0', 10), programId, isPrimaryProfile ? 'true' : 'false');
            }
            return [2 /*return*/, lastWatchedEpisodePromise];
        });
    });
}
function getContentViewInfoForProgram(client, contentIdentity, tenantSlug, acceptLanguage) {
    var _a, _b, _c, _d, _e, _f, _g;
    return __awaiter(this, void 0, void 0, function () {
        var playbackInfo, _h, id, slug, data, contentData, watchedHistory, previousWatchedEpisodeId, error, xAdParams, isLat, did, adid, e_1, result;
        return __generator(this, function (_j) {
            switch (_j.label) {
                case 0:
                    _h = (0, content_utils_1.getIdInfoFromContentIdentity)(contentIdentity), id = _h.id, slug = _h.slug;
                    return [4 /*yield*/, Promise.all([
                            client.odxProgramApi.programRetrieve(slug, tenantSlug, undefined, acceptLanguage),
                            findProgramPreviousWatchedEpisode(id),
                        ])];
                case 1:
                    data = _j.sent();
                    contentData = data[0].data.result;
                    watchedHistory = (_a = data === null || data === void 0 ? void 0 : data[1]) === null || _a === void 0 ? void 0 : _a.data;
                    previousWatchedEpisodeId = (_b = contentData.direct_play_episode) === null || _b === void 0 ? void 0 : _b.id;
                    if ((0, content_utils_1.isShow)(contentData)) {
                        previousWatchedEpisodeId = ((_d = (_c = watchedHistory === null || watchedHistory === void 0 ? void 0 : watchedHistory.results) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.episode_id) || previousWatchedEpisodeId;
                        if ((_e = contentData.direct_play_episode) === null || _e === void 0 ? void 0 : _e.id) {
                            contentData.direct_play_episode.id = previousWatchedEpisodeId;
                            contentData.direct_play_episode.slug = '__truncated__';
                        }
                    }
                    error = null;
                    _j.label = 2;
                case 2:
                    _j.trys.push([2, 4, , 5]);
                    xAdParams = integration_service_1.OdxIntegration.currentProvider.get('adParams');
                    isLat = (0, utils_1.getValueOfKeyPairString)(xAdParams, 'is_lat');
                    did = (0, utils_1.getValueOfKeyPairString)(xAdParams, 'did');
                    adid = (0, utils_1.getValueOfKeyPairString)(xAdParams, 'adid');
                    return [4 /*yield*/, client.odxPlaybackApi
                            .playbackRetrieve(previousWatchedEpisodeId === null || previousWatchedEpisodeId === void 0 ? void 0 : previousWatchedEpisodeId.toString(), tenantSlug, undefined, acceptLanguage, isLat ? decodeURIComponent(isLat) : undefined, did ? decodeURIComponent(did) : undefined, adid ? decodeURIComponent(adid) : undefined)
                            .catch(function (e) { return (0, utils_1.raiseForPlaybackErrorResponse)(e, false); })];
                case 3:
                    playbackInfo = _j.sent();
                    return [3 /*break*/, 5];
                case 4:
                    e_1 = _j.sent();
                    playbackInfo = null;
                    error = {
                        name: e_1.name || 'membership-blocked',
                        info: e_1.info,
                    };
                    return [3 /*break*/, 5];
                case 5:
                    result = (0, parser_1.CombineProgramAndPlaybackToContentViewInfo)(contentData, (_f = playbackInfo === null || playbackInfo === void 0 ? void 0 : playbackInfo.data) === null || _f === void 0 ? void 0 : _f.result, error);
                    result.properties = {
                        source: contentData,
                        playback: (_g = playbackInfo === null || playbackInfo === void 0 ? void 0 : playbackInfo.data) === null || _g === void 0 ? void 0 : _g.result,
                    };
                    return [2 /*return*/, __assign(__assign({}, data[0]), { data: result })];
            }
        });
    });
}
exports.getContentViewInfoForProgram = getContentViewInfoForProgram;
function getContentViewInfoForChildrenContent(client, contentIdentity, tenantSlug, acceptLanguage, adParams) {
    return __awaiter(this, void 0, void 0, function () {
        var id, _a, episodeInfo, episodePlayback, result;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    id = (0, content_utils_1.getIdInfoFromContentIdentity)(contentIdentity).id;
                    return [4 /*yield*/, getEpisodeInfo(client, tenantSlug, acceptLanguage, id, true, adParams)];
                case 1:
                    _a = _b.sent(), episodeInfo = _a.episodeInfo, episodePlayback = _a.episodePlayback;
                    result = (0, parser_1.CombineOdxEpisodeAndPlaybackToContentViewInfo)(episodeInfo, episodePlayback);
                    result.properties = {
                        source: episodeInfo,
                        playback: episodePlayback,
                    };
                    return [2 /*return*/, {
                            status: 200,
                            config: null,
                            headers: null,
                            data: result,
                            statusText: 'OK',
                            request: null,
                        }];
            }
        });
    });
}
exports.getContentViewInfoForChildrenContent = getContentViewInfoForChildrenContent;
function getContentViewInfoForChannel(client, contentId, tenantSlug, acceptLanguage, adParams) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function () {
        var xAdParams, isLat, did, adid, channelData, channel;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    xAdParams = integration_service_1.OdxIntegration.currentProvider.get('adParams');
                    isLat = (0, utils_1.getValueOfKeyPairString)(xAdParams, 'is_lat');
                    did = (0, utils_1.getValueOfKeyPairString)(xAdParams, 'did');
                    adid = (0, utils_1.getValueOfKeyPairString)(xAdParams, 'adid');
                    return [4 /*yield*/, client.odxEpgApi.epg(tenantSlug, acceptLanguage, undefined, parseInt(contentId, 10), undefined, undefined, undefined, undefined, undefined, undefined, isLat ? decodeURIComponent(isLat) : undefined, did ? decodeURIComponent(did) : undefined, adid ? decodeURIComponent(adid) : undefined)];
                case 1:
                    channelData = _d.sent();
                    channel = (_c = (_b = (_a = channelData.data) === null || _a === void 0 ? void 0 : _a.result) === null || _b === void 0 ? void 0 : _b.results) === null || _c === void 0 ? void 0 : _c[0];
                    if (!channel) {
                        throw new Error('Channel not found.');
                    }
                    return [2 /*return*/, __assign(__assign({}, channelData), { data: (0, mappers_1.mapOdxChannelsToContentViewInfo)(channel) })];
            }
        });
    });
}
exports.getContentViewInfoForChannel = getContentViewInfoForChannel;
function getSeasonDetailRequest(client, seasonId, tenantSlug, acceptLanguage, page, limit, identity) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var actualPage, data, seasonData, episodeData;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    actualPage = !isNaN(page) ? page + 1 : undefined;
                    return [4 /*yield*/, Promise.all([
                            !identity
                                ? client.odxProgramApi.programRetrieve(seasonId, tenantSlug, undefined, acceptLanguage)
                                : Promise.resolve(null),
                            getEpisodesOfProgram(client, seasonId, tenantSlug, acceptLanguage, actualPage, limit),
                        ])];
                case 1:
                    data = _b.sent();
                    seasonData = data[0];
                    episodeData = data[1];
                    return [2 /*return*/, __assign(__assign({}, seasonData), { data: (0, parser_1.CombineOdxProgramAndOdxEpisodeListToSeasonDetail)((_a = seasonData === null || seasonData === void 0 ? void 0 : seasonData.data) === null || _a === void 0 ? void 0 : _a.result, episodeData === null || episodeData === void 0 ? void 0 : episodeData.result, identity) })];
            }
        });
    });
}
exports.getSeasonDetailRequest = getSeasonDetailRequest;
