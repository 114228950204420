import React, { Component, useState } from 'react';
import { Button, Modal, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import DivStyled from '../../features/youtubePage/views/styled';
import { deleteProgress } from '../../features/youtubePage/services';
import Loader from 'react-loader-spinner';

function RemovePopup(props) {
  const { handleRemoveRecent } = props;

  const [errorMessage, serErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  function Cancel() {
    const { hideModal } = props;
    hideModal('modalShow');
  }

  function Accept(data) {
    setLoading(true);
    if (data.removeFavorite) {
      return data
        ._removeItems()
        .then(() => {
          Cancel();
        })
        .catch(err => {
          serErrorMessage(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    props
      .dpDeleteProgress(data)
      .then(res => {
        handleRemoveRecent(data);
        Cancel();
      })
      .catch(err => {
        serErrorMessage(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const { modalShow, t, data } = props;
  let text = {
    title: t('youtube.txtRemoveTitle'),
    des: t('youtube.txtRemoveDescription'),
  };
  if (data.removeFavorite) {
    text = {
      title: t('ribbon.removeFavorite.txtTitle'),
      des: t('ribbon.removeFavorite.txtDes'),
    };
  }
  return (
    <Modal
      show={modalShow}
      onHide={() => Cancel()}
      aria-labelledby="contained-modal-title-vcenter"
      style={{ top: '22%' }}
    >
      <Modal.Header closeButton style={{ border: 'none', paddingBottom: 0 }}></Modal.Header>
      <Modal.Body style={{ paddingBottom: '4rem' }}>
        <DivStyled className="row v-ytbPage--remove">
          <Col xs={10} sm={10} md={8} style={{ textAlign: 'center' }}>
            <p className="v-ytbPage--remove-title">{text.title}</p>
            <p className="v-ytbPage--remove-desciption">{text.des}</p>
          </Col>
          <Col xs={8} sm={10} md={8}>
            <Button className="v-ytbPage--remove-btn" onClick={() => Cancel()} disabled={loading}>
              {t('youtube.btnCancel')}
            </Button>
          </Col>
          <Col xs={8} sm={10} md={8}>
            <Button
              className="v-ytbPage--remove-btn"
              onClick={() => Accept(data)}
              disabled={loading}
            >
              {t('youtube.btnRemove')}
            </Button>
          </Col>
          {loading && (
            <div className="loadingOverlayContainer">
              <div className="loadingCenterFull">
                <Loader type="Oval" color="#0095ff" height="30" width="30" />
              </div>
            </div>
          )}
          <p className="v-ytbPage--messageError">{errorMessage}</p>
        </DivStyled>
      </Modal.Body>
    </Modal>
  );
}

RemovePopup.defaultProps = {
  hideModal: () => {},
  modalShow: false,
};
const mapStateToProps = state => ({
  account: state.auth.account,
  language: state.root.language,
});

const mapDispatchToProps = {
  dpDeleteProgress: idContent => deleteProgress(idContent),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RemovePopup)),
);
