import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { StyledChannelList } from './styled';
import { withRouter } from 'react-router-dom';

const RibbonChannel = React.lazy(() => import('./ribbonChannel'));

let timeout;

function RibbonChannelList(props) {
  const { schedule, timelineFrom, activeRibbonId, currentLive, language, widthSchedule } = props;
  const DOM = document.querySelector('.rb-channel-list');
  const secToNavigate = 60;

  const handleCountdownNavigate = () => {
    if (!currentLive.channelId) return;
    timeout = setTimeout(() => {
      if (!currentLive.channelId) return clearTimeout(timeout);
      props.history.push(`/${language}/detail/${currentLive.channelId}`, currentLive);
    }, secToNavigate * 1000);
  };

  useEffect(() => {
    if (!DOM) return;
    DOM.onmousemove = () => {
      clearTimeout(timeout);
      handleCountdownNavigate();
    };
  }, [DOM]);

  useEffect(() => {
    return function willUnmount() {
      return clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    handleCountdownNavigate();
  }, [props.history.location.pathname]);

  return (
    <StyledChannelList className="rb-channel-list">
      {props.children}
      {schedule.ribbons.map(rb => (
        <section className={classnames('rb--item')} key={rb.id} id={rb.slug}>
          <h2 className={classnames('rb--name', rb.id === activeRibbonId && 'active')} id={rb.id}>
            {rb.name}
          </h2>
          {schedule.channels[rb.id].map(cn => {
            if (schedule.epgs[cn.id].length === 0) return null;
            return (
              <RibbonChannel
                key={cn.id}
                isActive={cn.id === currentLive.channelId}
                timelineFrom={timelineFrom}
                channel={cn}
                widthSchedule={widthSchedule}
              />
            );
          })}
        </section>
      ))}
    </StyledChannelList>
  );
}

const mapStateToProps = state => ({
  schedule: state.liveSchedule.schedule,
  currentLive: state.liveSchedule.currentLive,
  activeRibbonId: state.liveSchedule.activeRibbonId,
  language: state.root.language,
});

export default withRouter(connect(mapStateToProps)(RibbonChannelList));
