import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getIdFaqBlockAds } from './../services';
import { withTranslation } from 'react-i18next';

function BlockAds(props) {
  const [dataBlockAds, setIdBlockAds] = useState({});
  const { t, language } = props;
  let linkFaq = `/${language}/faq`;
  function fetData() {
    const { dpGetIdFaqBlockAds } = props;
    dpGetIdFaqBlockAds().then(res => {
      setIdBlockAds(...res);
    });
  }

  useEffect(() => {
    fetData();
  }, [props.id]);
  if (dataBlockAds && dataBlockAds.slug) {
    linkFaq = `/${language}/faq/${dataBlockAds.slug}`;
  }
  return (
    <div className="vjs-ads-blocker" id="vjs-ads-blocker">
      <div className="vjs-ads-blocker--container row">
        <div className="col-xs-12 col-sm-10 col-md-8 col-lg-6 col-xlg">
          <p className="vjs-ads-blocker--title">{t('player.blockAds.txtTitle')}</p>
          <p className="vjs-ads-blocker--des">{t('player.blockAds.txtDes')}</p>
          <Link to={linkFaq}>
            <button className="vjs-ads-blocker--btn">{t('player.blockAds.txtButton')}</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  language: state.root.language,
});

const mapDispatchToProps = {
  dpGetIdFaqBlockAds: () => getIdFaqBlockAds(),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BlockAds)),
);
