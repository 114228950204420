import * as API_STRING from '~constants/apiString';
import { store } from '~store';
import * as refreshToken from './refreshToken';
import { checkTenantConfigs } from './getSlugDomain';
import isomorphicFetch from 'isomorphic-fetch';
import { tenantEnvConfig } from '~constants/tenantConfig/tenantEnvConfig';
import { isNode } from '~utils/ssr';
import * as utils from '~root/utils/utils';
import { hasNoCache } from '~server/method/requestMethod';

import i18n from 'i18next';

export const controller = !isNode() ? new AbortController() : null;
const errorCustom = (error: any) => {
  let msg = error.message;
  if (typeof msg !== 'string' && Object.values(error.message).length) {
    msg = (Object.values as any)(error.message)
      .reduce((result: any, current: any) => {
        if (Array.isArray(current)) {
          current.map(message => {
            result.push(message);
          });
        } else {
          result.push(current);
        }

        return result;
      }, [])
      .join('\n');
    error.message = msg;
  }

  return error;
};

const getXTraceId = (headers: any) => {
  const { account } = (store.getState() as any).auth;
  let traceId = 'anonymous';
  if (account && account.profile && account.profile.id) {
    traceId = account.profile.id;
  }
  return Object.assign(headers, {
    'X-Trace-Account-Id': traceId,
  });
};

export default function FETCH(path: any, method: any, headers: any, body: any) {
  if (headers.Authorization === null || headers.Authorization === '')
    headers.Authorization = undefined;
  return new Promise(function (resolve, reject) {
    const startF = ({ path, method, headers, body }: any) => {
      startFetch(path, method, headers, body)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    };
    checkTenantConfigs(startF, { path, method, headers, body });
    // refreshToken.checkRefreshToken(startF, { path, method, headers, body })
  });
}

function pathUrl(path: string) {
  const { tenantSlug } = (store.getState() as any).root;
  const newPath = new RegExp(/tenants\/?(\/.*)?$/gm);
  let checkPath = newPath.test(path);
  let arrayPath = path.split('/');
  if (checkPath) {
    arrayPath.find((item, index) => {
      if (item === API_STRING.TENANT_SLUG) {
        arrayPath[index] = tenantSlug.tenant_slug;
        path = arrayPath.join('/');
        return true;
      }
    });
  }
  return path;
}
function startFetch(path: any, method: any, headers: any, body: any) {
  return new Promise((resolve, reject) => {
    let { language } = (store.getState() as any).root;
    const regLink = new RegExp('^https?.+');
    const utilsNew: any = utils;
    const globalNew: any = global;
    const ssrHeader = (utilsNew.requestSsr && utilsNew.requestSsr.customeHeader) || null;
    const HEADERS = ssrHeader
      ? ssrHeader
      : {
          'Accept-Language': language || tenantEnvConfig.default_locale,
        };
    const timeout = setTimeout(() => {
      reject(new Error(i18n.t('requestApi.txtTimeOut')));
    }, 60000);

    const fetchURL = regLink.test(path) ? path : `${API_STRING.BASE_URL}${path}`;
    isomorphicFetch(fetchURL, {
      ...(!isNode() && controller ? { signal: controller.signal } : {}),
      method,
      headers: {
        ...HEADERS,
        ...headers,
        ...(isNode() && hasNoCache.call(globalNew.request) ? { 'cache-control': 'no-cache' } : {}),
      },
      body: body || null,
    })
      .then((response: any) => {
        clearTimeout(timeout);
        if (response.status >= 400 && response.status < 500) {
          return response.json().then((data: any) => {
            if (data && data.message) {
              const error = errorCustom(data);
              if (data.error_code === 'country_blocking_exception') {
                throw data;
              } else if (data.error_code === 'REFRESH_TOKEN_EXPIRED') {
                throw data;
              } else {
                reject(error);
                throw error;
              }
            } else {
              throw new Error(i18n.t('requestApi.txtError'));
            }
            // throw new Error(JSON.stringify(data));
          });
        }
        if (response.status >= 500 && !response.ok) {
          reject(response);
        }
        return response.json();
      })
      .then((responseJson: any) => {
        if (responseJson.error) {
          reject(responseJson);
        } else {
          resolve(responseJson);
        }
      })
      .catch((error: any) => {
        if (error.message === 'Failed to fetch') {
          error.message = i18n.t('requestApi.txtError');
        }
        reject(error);
      });
  });
}

export { pathUrl, startFetch };
