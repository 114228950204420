import { EPISODE_TYPE } from '~core/constants';
import React, { useState } from 'react';
import { AgeRatingBLock } from './style/style.js';

export default function ContentAgeRating(props) {
  const { component = null, entity = null } = props;
  const { entityDetail = null } = component || {};
  const [detailItem, setDetailItem] = React.useState([]);
  const [isDisplay, setIsDisplay] = useState(true);

  const [metaData, setMetaData] = React.useState([]);
  const [contentRating, setContentRating] = React.useState([]);

  const [filmCode, setFilmCode] = React.useState([]);
  const [codePlayer, setCodePlayer] = React.useState({
    filmCode: '',
    productionCompany: '',
  });

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setIsDisplay(false);
    }, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  React.useEffect(() => {
    if (!entityDetail && !entity) {
      return;
    }

    setDetailItem(entity || entityDetail);
  }, [component, entity, entityDetail]);

  React.useEffect(() => {
    if (detailItem && detailItem.type === EPISODE_TYPE && detailItem.infoShow) {
      setMetaData(detailItem.infoShow.metadata);
      return;
    }
    if (!detailItem || detailItem.metadata === null) {
      return;
    }
    setMetaData(detailItem.metadata);
  }, [detailItem]);

  React.useEffect(() => {
    if (!metaData) {
      return;
    }
    setContentRating(metaData.content_ratings);
  }, [metaData]);

  React.useEffect(() => {
    if (!detailItem) {
      return;
    }
    if (detailItem.type === EPISODE_TYPE && detailItem.infoShow) {
      setCodePlayer({
        filmCode: detailItem.infoShow.film_code || '',
        productionCompany: detailItem.infoShow.production_company_name || '',
      });
      return;
    }
    setCodePlayer({
      filmCode: detailItem.film_code || '',
      productionCompany: detailItem.production_company_name || '',
    });
  }, [detailItem]);

  if (!isDisplay) {
    return null;
  }

  return (
    <AgeRatingBLock className="vjs-ageRating-container">
      <div className="vjs-ageRating-container--groupRating">
        {contentRating &&
          contentRating.map((item, index) => (
            <img
              key={item.id}
              className="vjs-ageRating-container--groupRating__images"
              src={item.image_url}
            />
          ))}
        {detailItem && codePlayer && (
          <p className="vjs-ageRating-container--groupRating__codeODK">
            {codePlayer.filmCode || ''}{' '}
            {codePlayer.filmCode && codePlayer.productionCompany ? '-' : ''}{' '}
            {codePlayer.productionCompany || ''}
          </p>
        )}
      </div>
    </AgeRatingBLock>
  );
}
