import styled from 'styled-components';

const background = require('~img/img/escondido/background.png').default;

export const LiveStyled = styled.div`
  .livePage {
    &__player {
      z-index: 1;
    }
    &__schedule {
      z-index: 9;
    }
    &__gradient {
      /* background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.88) -4%,
        rgba(0, 0, 0, 0) 30%
      ); */
      background-image: url(${background});
      background-repeat: no-repeat;
      background-size: 100% 100%;
      z-index: 2;
      position: fixed;
      width: 100%;
      height: 100%;
    }
  }
`;
