import React, { useState, useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Popover from '@material-ui/core/Popover';
const ButtonSubtitle = React.memo(props => {
  const { component } = props;
  const { t } = component.props;
  const { entityDetail } = component;
  const [closeSubtitle, setCloseSub] = useState(false);
  const tracks = component.videoPlayer.textTracks() || null;
  const containerRef = useRef(null);

  const CustomiseTooltip = withStyles({
    tooltip: {
      backgroundColor: 'unset',
      marginTop: 15,
      fontSize: 18,
      color: '#d1d1d1',
      textTransform: 'capitalize',
    },
  })(Tooltip);

  const CustomisePopover = withStyles(theme => ({
    root: {},
    paper: {
      backgroundColor: '#0a0a0a',
      opacity: '0.8 !important',
      borderRadius: 10,
      padding: '20px 40px',
      transform: 'translateY(-25px)!important',
    },
  }))(Popover);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickSubtitle = (item = null) => {
    let key = (item && item.language) || 'off';
    for (let i = 0; i < tracks.length; i++) {
      let track = tracks[i];
      track.mode = 'hidden';
      if (track.kind === 'subtitles' && track.language === key) {
        track.mode = 'showing';
        track.subtitleRender = true;
      }
    }
    setCloseSub(item ? false : true);
    handleClose();
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  if (entityDetail && Array.isArray(entityDetail.subtitles) && !entityDetail.subtitles.length)
    return null;
  return (
    <React.Fragment>
      <li
        className="vjs--selector"
        id="subtitles"
        type="button"
        data-state="subtitles"
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        ref={containerRef}
      >
        <CustomiseTooltip title={t('player.controlBar.txtSubtitle')} placement="Bottom">
          <div className="vjs--selector__icons">
            <i className="fa fa-cc" aria-hidden="true" />
          </div>
        </CustomiseTooltip>
      </li>
      <CustomisePopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        // anchorReference="anchorPosition"
        // anchorPosition={{ top: 720, left: 907 }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        container={containerRef.current}
      >
        <div
          className={classnames(
            'vjs--selector__subtitle',
            closeSubtitle && 'vjs--selector__subtitle-active',
          )}
          onClick={() => handleClickSubtitle()}
        >
          {t('video.off')}
        </div>
        {tracks.tracks_.map((item, index) => {
          if (item.kind === 'subtitles') {
            return (
              <div
                className={classnames(
                  'vjs--selector__subtitle',
                  item.mode === 'showing' && 'vjs--selector__subtitle-active',
                )}
                onClick={() => handleClickSubtitle(item)}
              >
                {item.label}
              </div>
            );
          }
        })}
      </CustomisePopover>
    </React.Fragment>
  );
});

export default ButtonSubtitle;
