import * as types from './actionTypes';
import { getConfigByKey } from '~features/tenantConfig/services';
import { getTenantFeatureFlagsWithSsr } from '~constants/tenantConfig/tenantFeatureFlags';
import * as utils from '~root/utils/utils';
import { isNode } from '~utils/ssr';
import * as APIString from '~constants/apiString';

export const initialState = {
  menu: [],
  isHomePage: false,
  page: null,
  pageSlug: null,
  isLoadingPage: 0,
  isRefreshPage: false,
  ribbonTarget: '',
  removeProcess: {
    isOpen: false,
    items: {},
    removeId: '',
  },
  topTenContents: [],
  reactedContents: [],
};

const SLUG_TENANTS = [];
const SLUG_RIBBON_PAGE = [];

export default function home(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_MENU:
      return {
        ...state,
        menu: Array.isArray(action.menu) ? action.menu : [{}],
      };
    case types.GET_PAGE:
      let isHomePage = false;
      let bannerExtra = [];
      let topTenExtra = [];
      const ribbonCustomize = (action.page && action.page.ribbons) || [];
      const ribbonVerticalCustomize = (action.page && action.page.side_navigation_section) || [];
      const tenantFeatureFlagsWithSsr = getTenantFeatureFlagsWithSsr(utils);
      if (state.menu.length > 0 && action.page && state.menu[0].slug === action.page.pageSlug) {
        isHomePage = true;
      }
      const pageSlug = action.page && action.page.pageSlug;
      if (
        ((isHomePage || SLUG_TENANTS.includes(pageSlug)) &&
          getConfigByKey('ui_theme') === 'odv' &&
          getConfigByKey('features.extraBannerAds')) ||
        (tenantFeatureFlagsWithSsr.extraBannerAds &&
          (pageSlug === 'home_page' || SLUG_TENANTS.includes(pageSlug)) &&
          isNode())
      ) {
        const obj = {
          images: {
            banner: SLUG_TENANTS.includes(pageSlug)
              ? '/app/assets/img/odv/Toyota_Summer_herobanner-page.png'
              : '/app/assets/img/odv/Toyota_Summer_herobanner.png',
            thumbnail: '/app/assets/img/odv/Toyota_Summer_thumbnail.png',
            banner_190_67_ratio: SLUG_TENANTS.includes(pageSlug)
              ? '/app/assets/img/odv/Toyota_Summer_herobanner-page.png'
              : '/app/assets/img/odv/Toyota_Summer_herobanner.png',
            rectangle_banner: '/app/assets/img/odv/toyota_summer.jpg',
          },
          bannerExtra: true,
        };
        bannerExtra = [obj];
        topTenExtra = [
          {
            ...obj,
            duration: null,
            has_free_content: true,
            id: '',
            is_new_release: false,
            is_premium: false,
            runtime: 0,
            slug: '',
            title: '',
            type: 1,
            video_source: 0,
          },
        ];
      }
      ribbonVerticalCustomize.forEach(element => {
        if (SLUG_RIBBON_PAGE.includes(element.slug)) {
          element.items = [...topTenExtra, ...element.items];
        }
      });
      ribbonCustomize.forEach(element => {
        if ((pageSlug === 'home_page' || isHomePage) && SLUG_TENANTS.includes(element.slug)) {
          element.items = [...topTenExtra, ...element.items];
        }
      });

      if (isHomePage && getConfigByKey('ui_theme') === 'sctv') {
        bannerExtra = [
          {
            images: {
              banner: require('~img/img/sctv/momo.jpg').default,
            },
          },
        ];
      }

      if (isHomePage && getConfigByKey('ui_theme') === 'sctv') {
        bannerExtra = [
          {
            images: {
              banner: require('~img/img/sctv/banner.jpg').default,
              poster: require('~img/img/sctv/posterBanner.jpg').default,
            },
          },
        ];
      }
      return {
        ...state,
        page: {
          ...action.page,
          banners:
            getConfigByKey('ui_theme') === 'sctv'
              ? [...bannerExtra, ...(action.page.banners || [])]
              : [...(action.page.banners || [])],
        },
        // isLoadingPage: false,
        isHomePage,
        pageSlug: action.page.pageSlug,
      };
    case types.GET_PAGE_REQUEST:
      return {
        ...state,
        isLoadingPage: state.isLoadingPage + 1,
      };
    case types.REFRESH_PAGE:
      return {
        ...state,
        isRefreshPage: action.isRefreshPage,
      };
    case types.GET_FINAL:
      const newLoading = state.isLoadingPage - 1;
      return {
        ...state,
        isLoadingPage: newLoading > 0 ? newLoading : 0,
      };
    case types.GET_RIBBON_TARGET:
      return {
        ...state,
        ribbonTarget: action.idRibbonTarget,
      };
    case types.UPDATE_STATUS_REMOVE_PROCESS:
      return {
        ...state,
        removeProcess: action.payload,
      };
    case types.UPDATE_SEARCH:
      return {
        ...state,
        page: {
          ...state.page,
          search: action.queryParams,
        },
      };
    case types.UPDATE_PAGE_SLUG:
      return {
        ...state,
        pageSlug: action.payload,
      };
    case types.SET_TOP_TEN_CONTENTS:
      return {
        ...state,
        topTenContents: action.payload,
      };
    case types.UPDATE_REACTED_CONTENT:
      return {
        ...state,
        reactedContents: action.payload,
      };
    case types.UPDATE_SSR:
      return {
        ...state,
        isSsr: action.payload,
      };
    case types.UPDATE_RIBBONS:
      return {
        ...state,
        page: {
          ...state.page,
          ribbons: action.ribbons,
        },
      };
    case types.RESET_PAGE_DATA:
      return {
        ...state,
        page: initialState.page,
      };
    default:
      return state;
  }
}
