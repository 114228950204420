import styled from 'styled-components';
export const DivStyled = styled.div`
  &.v-newSlider {
    position: relative;
    .slick-arrow {
      &.slick-next,
      &.slick-prev {
        display: none !important;
      }
    }
    &-odv {
      .v-newSlider__sub {
        bottom: 15px;
        &-wrapper {
          margin: 0;
        }
      }
    }
  }
  .v-newSlider {
    &__slide {
      position: relative;
      border: none;
      margin: 0;
    }
    &__sub {
      position: absolute;
      bottom: 10px;
      right: 15px;
      height: 100%;
      display: none;
      &-wrapper {
        margin-bottom: 10px;
        border: 2px solid #fff;
        background-color: unset;
      }
      &--center {
        width: 100%;
      }
      .slick-slider {
        .slick-list {
          padding: 4px 0 !important;
        }
      }
      .slick-slide {
        border: none;
        &.slick-active {
          opacity: 1;
          &.slick-center {
            margin: 0;
            .v-slide__header {
              transition: 1s all;
              &--cover {
                &:after {
                  background: unset;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
    .v-newSlider {
      &__sub {
        width: 15%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: auto;
      }
    }
  }

  @media (min-width: 1280px) {
    .v-newSlider {
      &__sub {
        width: 16.67%;
        .slick-slider {
          .slick-list {
            padding: 0 !important;
          }
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .v-newSlider {
      &__sub {
        .slick-slider {
          .slick-list {
            padding: 6px 0 !important;
          }
        }
      }
    }
  }

  @media (min-width: 1680px) {
    .v-newSlider {
      &__sub {
        .slick-slider {
          .slick-list {
            padding: 2px 0 !important;
          }
        }
      }
    }
  }

  @media (min-width: 1920px) {
    .v-newSlider {
      &__sub {
        .slick-slider {
          .slick-list {
            padding: ${props =>
              `${props.props && props.props.isHasSubcription ? `7px 0` : `2px 0`}!important`};
          }
        }
      }
    }
  }
`;
