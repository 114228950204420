// Those environment config is injected dynamically upon /js/tenantConfig.js file rendering.
// And is available statically at runtime of our SPA application.

import _ from 'lodash';
import { IEnvConfig } from '~core/typing';

export let tenantEnvConfig: any = {};

export const getConfigFromEnv = () => {
  if (!_.isEmpty(tenantEnvConfig)) {
    return tenantEnvConfig;
  }
  const config = window.envConfig || global.envConfig || {};
  tenantEnvConfig = config;
  return tenantEnvConfig;
};
const {
  tenant_slug,
  platform_slug,
  ui_theme, // Not available on backend yet
  environment,
  ...others
} = getConfigFromEnv();

export const setTenantEnvConfig = (newEnvConfig: IEnvConfig) => {
  tenantEnvConfig = newEnvConfig;
};
