/* tslint:disable */
/* eslint-disable */
/**
 * ODX/V3 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BadgesBottomLeftEnum = exports.BadgesTopRightEnum = exports.BadgesTopLeftEnum = void 0;
exports.BadgesTopLeftEnum = {
    premium: 'content-premium',
    _24hrs_free: 'content-24hrs-free',
    ppv: 'content-ppv',
    unknown_default_open_api: '11184809'
};
exports.BadgesTopRightEnum = {
    content_live: 'content-live',
    popular_top_1: 'popular-top-1',
    popular_top_2: 'popular-top-2',
    popular_top_3: 'popular-top-3',
    popular_top_4: 'popular-top-4',
    popular_top_5: 'popular-top-5',
    popular_top_6: 'popular-top-6',
    popular_top_7: 'popular-top-7',
    popular_top_8: 'popular-top-8',
    popular_top_9: 'popular-top-9',
    popular_top_10: 'popular-top-10',
    unknown_default_open_api: '11184809'
};
exports.BadgesBottomLeftEnum = {
    content_new_episode: 'content-new-episode',
    age_rating_adult: 'age-rating-adult',
    unknown_default_open_api: '11184809'
};
