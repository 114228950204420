import React, { useState, useEffect } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Button } from 'react-bootstrap';
import queryString from 'query-string';
import * as payperviewService from '../services';
import { setDataPaySubcription } from '../functions';
import BootPayPG from '@bootpay/client-js';

import uuid from 'react-uuid';
import qs from 'query-string';
import { debug } from '~root/utils/utils';
import { REACT_APP_BOOTPAY_APPLICATION_ID } from '~root/constants/envConfig';
import { loading } from '~features/loadingPage/services';
import { isPPV, isNewPPV__NoCache } from '~core/method/contentMethod';
import { wFirebaseEvents } from '~services/analytics';
import { BOOTPAY_METHOD_ENUM, BOOTPAY_PG_ENUM } from '~core/constants/bootpay';

const Loading = props => {
  return (
    <div className="bootpay--loading">
      <CircularProgress />
    </div>
  );
};

const Bootpay = props => {
  const { dpLoading = () => {} } = props;
  const {
    postSubscriptionsPayperview,
    postConfirmPaypalPayperview,
    t,
    location,
    state,
    setStatePolicy,
    userProfile,
    confirmSuccess,
    confirmFail,
    setLoading = dpLoading,
    confirmPrivacy,
    openModalSuccess,
    typePayment = undefined,
    pricePayment = 0,
    idPrices = undefined,
    setPaymentError,
    selectedPrice,
    pricesTickContentView = [],
    priceFocus = {},
    bootpayMethod,
  } = props;

  const paymentMethod = 'bootpay';
  const payloadFirebase = {
    content_id: contentId,
    email: userProfile.email,
    purchase_amount: priceFocus.price,
    currency_unit: priceFocus.currency,
    payment_method: priceFocus.type,
    purchase_item_name: priceFocus.name,
  };

  const params = qs.parse(props.location.search);
  const content = state.infoShow || state.entityDetail;
  const [paymentId, setPaymentId] = useState('');

  let contentId = state.infoShow ? state.infoShow.id : state.entityDetail.id;
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState({
    loading: true,
    disabled: false,
  });
  useEffect(() => {
    let paramQuery = queryString.parse(location.search, { decode: false });
    let disabled = false;
    if (paramQuery && paramQuery.token) disabled = true;
    setStatus({
      loading: false,
      disabled: disabled,
    });
  }, []);

  useEffect(() => {
    if (!paymentId) {
      return;
    }
    paymentBootpay();
  }, [paymentId]);

  const confirmPayment = bootpayResponse => {
    const { receipt_id } = bootpayResponse;
    const { tvod_type = priceFocus.type, price_id = priceFocus.id } = props;
    wFirebaseEvents.ppvPaymentProcessingEvent(payloadFirebase);
    const params = {
      ...setDataPaySubcription({
        provider: 'bootpay',
        contentId: contentId,
      }),
      payment_id: paymentId,
      confirm_token: receipt_id,
      tvod_type,
      price_id,
      pg: BOOTPAY_PG_ENUM.KCP,
      method: bootpayMethod,
    };
    setLoading(true);
    postConfirmPaypalPayperview(params)
      .then(res => {
        wFirebaseEvents.ppvPaymentCompletedEvent(payloadFirebase);
        confirmSuccess && confirmSuccess();
        openModalSuccess();
      })
      .catch(err => {
        confirmFail && confirmFail();
        setPaymentError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const paymentBootpay = async () => {
    const { user_token, application_id = REACT_APP_BOOTPAY_APPLICATION_ID } = params || {};
    if (!priceFocus.price) {
      return;
    }
    debug(() => {
      console.log('REACT_APP_BOOTPAY_APPLICATION_ID', REACT_APP_BOOTPAY_APPLICATION_ID);
    });
    setMessage('');

    try {
      const response = await BootPayPG.requestPayment({
        price: priceFocus.price,
        application_id,
        order_name: content.title,
        show_agree_window: 0,
        pg: BOOTPAY_PG_ENUM.KCP,
        method: bootpayMethod,
        order_id: uuid(),

        user: {
          username: `${userProfile.first_name || ''} ${userProfile.last_name || ''}`,
          email: userProfile.email || '',
          addr: userProfile.address || '',
          phone: userProfile.phone_number || '',
        },
        items: [
          {
            id: content.id,
            name: content.title,
            qty: 1,
            price: priceFocus.price,
          },
        ],
        extra: {
          open_type: 'popup',
          quota: '3',
          theme: 'purple',
          custom_background: '#00a086',
          custom_font_color: '#ffffff',
        },
      });
      switch (response.event) {
        case 'issued':
          // 가상계좌 입금 완료 처리
          break;
        case 'done':
          debug(() => {
            console.log(response.data);
          });
          confirmPayment(response.data);

          break;
        default:
          break;
      }
    } catch (error) {
      switch (error.event) {
        case 'cancel':
          // 사용자가 결제창을 닫을때 호출
          setMessage(error.message);
          debug(() => {
            console.log(error.message);
          });
          break;
        case 'error':
          setMessage(error.message);
          debug(() => {
            console.log(error);
          });
          break;
      }
    }
  };

  const getLinkBootpay = () => {
    setMessage('');
    setStatus({
      ...status,
      loading: true,
    });
    let data = {
      provider: 'bootpay',
      contentId: contentId,
      tvod_type: priceFocus.type || undefined,
      price_id: priceFocus.id || undefined,
    };
    data = setDataPaySubcription(data);

    wFirebaseEvents.ppvPaymentStartedEvent(payloadFirebase);
    setPaymentId('');
    postSubscriptionsPayperview(data)
      .then(res => {
        // window.location.href = res.redirect_url
        const { payment_id } = res;
        setPaymentId(payment_id);
      })
      .catch(err => {
        setMessage(err.message);
      })
      .finally(() => {
        setStatus({
          ...status,
          loading: false,
        });
      });
  };
  if (!isNewPPV__NoCache.call(pricesTickContentView)) {
    return null;
  }

  return (
    <DivStyle className="bootpay">
      <React.Fragment>
        {status.loading ? (
          <Loading />
        ) : (
          <Button
            id="linkBootpay"
            className={`project-w--watchmore paynow`}
            disabled={!confirmPrivacy}
            onClick={() => getLinkBootpay()}
          >
            {t('projectw__PPV.paymentxt.payment_button')}
          </Button>
        )}
      </React.Fragment>
      {message && <p className="bootpay--err">{message}</p>}
    </DivStyle>
  );
};

const DivStyle = styled.div`
  .bootpay {
    font-family: 'Neue Haas Grotesk Display Pro';
    color: #fff;
    &--title {
      font-size: 1rem;
      font-weight: 100;
      margin-bottom: 2rem;
      color: #fff;
      br {
        content: '';
        margin-bottom: 1rem;
        display: block;
        font-size: 1rem;
      }
    }
    &--loading {
      text-align: center;
    }
    &--btn {
      margin-left: 0;
      text-transform: initial;
    }
    &--err {
      color: #f23b46;
    }
  }
`;
const mapStateToProps = state => ({
  userProfile: state.auth.account.profile,
});

const mapDispatchToProps = {
  postSubscriptionsPayperview: params => payperviewService.postSubscriptionsPayperview(params),
  postConfirmPaypalPayperview: params => payperviewService.postConfirmPaypalPayperview(params),
  dpLoading: value => loading(value),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Bootpay)));
