import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SHOW_TYPE } from './../constants';
class PopupUpgrade extends Component {
  static propTypes = {
    modalShow: PropTypes.bool,
  };

  _hideModel() {
    const { hideModal } = this.props;
    hideModal('modalShow');
  }

  hrefUrl() {
    const { history, language, entity } = this.props;
    let param = entity
      ? {
          upgrade: true,
          callbackUrl:
            entity.type === SHOW_TYPE
              ? `/${language}/landing/${entity.slug || entity.id}`
              : `/${language}/detail/${entity.slug || entity.id} `,
        }
      : { upgrade: true, callbackUrl: `/` };
    return history.push(`/${language}/box/offers`, param);
  }

  render() {
    const { modalShow, t } = this.props;
    return (
      <Modal
        show={modalShow}
        onHide={() => this._hideModel()}
        aria-labelledby="contained-modal-title-vcenter"
        className="v-popupUpgrade"
        style={{ top: '22%' }}
      >
        <Modal.Header closeButton style={{ border: 'none', paddingBottom: 0 }}></Modal.Header>
        <Modal.Body style={{ paddingBottom: '4rem' }}>
          <DivStyled
            className="row v-alertPayment"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Col xs={10} sm={12} md={10} style={{ textAlign: 'center' }}>
              <h2 style={{ margin: 0 }} className="v-alertPayment--title">
                {t('laddingPage.alert.txtTitle')}
              </h2>
            </Col>
            <Col xs={9} sm={12} md={9} style={{ marginTop: '2.5rem' }}>
              <Button className="v-alertPayment--btn" onClick={() => this.hrefUrl()}>
                {t('laddingPage.alert.btnUpgrade')}
              </Button>
            </Col>
            <Col xs={9} sm={12} md={9} style={{ marginTop: '1.5rem' }}>
              <Button
                style={{
                  padding: '1rem 2.5rem',
                  background: '#0472cc',
                  color: '#fff',
                  fontSize: '1.25rem',
                  width: '100%',
                }}
                onClick={() => this._hideModel()}
              >
                {t('laddingPage.alert.btnCancel')}
              </Button>
            </Col>
          </DivStyled>
        </Modal.Body>
      </Modal>
    );
  }
}

const DivStyled = styled.div`
  &.v-alertPayment {
    .v-alertPayment {
      &--btn {
        padding: 1rem 2.5rem;
        border: 1px solid #fff;
        box-shadow: #f5f5f5;
        color: #fff;
        background: #e8b600;
        font-size: 1rem;
        width: 100%;
        &:hover {
          color: #fff;
          border: 1px solid #e8b600;
        }
      }
      &--title {
        margin: 0;
        font-size: 1.25rem;
      }
    }
  }
  @media screen and (min-width: 992px) {
    .v-alertPayment {
      &--btn {
        font-size: 1.25rem;
      }
      &--title {
        font-size: 1.85rem;
      }
    }
  }
`;

PopupUpgrade.defaultProps = {
  hideModal: () => {},
  modalShow: false,
};
const mapStateToProps = state => ({
  account: state.auth.account,
  language: state.root.language,
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, null)(withTranslation()(PopupUpgrade)));
