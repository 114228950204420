import { Filter, IAxiosResponseData, Pagination } from '~core/models';
import { apiAdapter, config, axiosTakeDataFromResponse } from '~share';
import camelcaseKeys from 'camelcase-keys';
import * as contentApi from './content';
import { PROJECTW } from '~core/constants';

const { contentService } = apiAdapter;

const getFilterData = (
  pageSlug = '',
  params: {
    filter_for_side_navigation_section?: boolean;
  } = {},
): Promise<IAxiosResponseData<typeof contentService.getPageContentFilter>> => {
  const select = JSON.stringify({
    PageContentFilter: ['regions', 'genres', 'languages'],
  });
  const { filterForSideNavigationSection = false } = camelcaseKeys(params);
  const { tenantSlug = '', acceptLanguage } = config;

  if (tenantSlug === PROJECTW) {
    return Promise.resolve({});
  }
  return contentService
    .getPageContentFilter(
      pageSlug,
      tenantSlug,
      acceptLanguage,
      select,
      undefined,
      filterForSideNavigationSection,
    )
    .then(axiosTakeDataFromResponse);
};

const getRibbons = (
  params: {
    id: string;
    slug: string;
  } & Filter &
    Pagination,
): ReturnType<typeof contentApi.getRibbons> => {
  return contentApi.getRibbons(params);
};

const getPersonalizedRibbons = (
  params: {
    id: string;
    slug: string;
  } & Filter &
    Pagination,
): Promise<IAxiosResponseData<typeof contentApi.getPersonalizedRibbons>> => {
  return contentApi.getPersonalizedRibbons(params);
};

export { getFilterData, getRibbons, getPersonalizedRibbons };
