import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import { Panel } from 'react-bootstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../index.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import pushAnalytics from '../../../../services/analytics/Analytics';
import * as displayType from '../../constants';
import { contentTypes } from '../../../../utils/utils';
import Heading from '../heading';
import PanelBody from '../body';
import { DivStyled } from '../../styles/slider';
import SliderSctv from './sliderSctv';

const SliderEscondido = React.memo(props => {
  const { page, auth, isMobile, showLoginAction, isLoadingPage, history, language } = props;
  const slider = useRef();

  const [mainSlider, setMainSlider] = useState(0);
  const [banners, setBanner] = useState([]);

  React.useEffect(() => {
    const tmp = (page && page.banners) || [];
    setBanner(tmp);
  }, [isLoadingPage]);

  const settingsMainSlide = {
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    fade: true,
    adaptiveHeight: false,
    cssEase: 'linear',
    afterChange: current => setMainSlider(current),
  };

  const linkDetail = (item, index) => {
    let statusText = contentTypes(item);
    let linkPlay = `/${language}/landing/${item.slug}`;
    pushAnalytics('select_content', {
      content_type: statusText,
      item_id: item.slug,
      widget: 'Header',
      order: index + 1,
    });
    if (
      item.type === displayType.CHANNEL_TYPE ||
      item.type === displayType.CURATED_LIVE ||
      item.video_source === 1
    ) {
      return history.push(`/${language}/detail/${item.slug}`);
    }
    history.push(linkPlay);
  };

  if (!banners.length) return null;
  if (isMobile) {
    return <SliderSctv {...props} customeClass="Covers__dot" />;
  }
  return (
    <DivStyled className="v-newSlider">
      <Slider {...settingsMainSlide} ref={slider}>
        {banners.slice(0, 3).map((item, index) => {
          let img = '';
          if (auth.isHasSubcription) {
            item.is_premium = false;
            item.has_free_content = true;
          }
          const { banner, backdrop, banner_190_67_ratio } = item.images;
          img = banner_190_67_ratio || banner || backdrop;
          return (
            <Panel
              key={index}
              className="v-slide--slide v-newSlider__slide"
              onClick={() => linkDetail(item, index)}
            >
              <Heading img={img} data={item} newBanner />
              <PanelBody data={item} index={index} showLoginAction={showLoginAction} />
            </Panel>
          );
        })}
      </Slider>
      <div className="v-newSlider__sub">
        {banners.slice(0, 3).map((item, index) => {
          const { thumbnail } = item.images;
          return (
            <Panel
              key={index}
              className="v-slide--slide v-newSlider__sub-wrapper"
              onMouseEnter={() => slider.current.slickGoTo(index)}
            >
              <Heading img={thumbnail} data={item} secondSlider active={mainSlider === index} />
            </Panel>
          );
        })}
      </div>
    </DivStyled>
  );
});

SliderEscondido.defaultProps = {
  showLoginAction: () => {},
};
SliderEscondido.propTypes = {
  auth: PropTypes.any,
  page: PropTypes.object,
};

const mapStateToProps = state => ({
  page: state.home.page,
  auth: state.auth,
  isHasSubcription: state.auth.isHasSubcription,
  isLoadingPage: state.home.isLoadingPage,
  language: state.root.language,
});
export default withRouter(connect(mapStateToProps, null)(SliderEscondido));
