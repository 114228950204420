import React from 'react';
import * as display from './../../constants';
import styled from 'styled-components';

const NewRelase = React.memo(props => {
  const { item, type } = props;

  if (item.is_new_release) {
    return (
      <DivStyle
        className={`ribbonNewRelease ${
          (type === display.SHOW_TYPE || type === display.EPISODE_TYPE) && 'ribbonNewRelease--epg'
        }`}
        theme={{ main: 'Episode' }}
      >
        <p>New</p>
      </DivStyle>
    );
  }
  return null;
});

export default NewRelase;

const DivStyle = styled.div`
  &.ribbonNewRelease {
    position: absolute;
    top: 0;
    z-index: 2;
    width: 8rem;
    height: 4rem;
    background: #00b2ff;
    margin-left: -1.75rem;
    transform: rotate(333deg);
    margin-top: -2rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    left: -1.25rem;
    color: #fff;
    font-weight: 600;
    p {
      margin: 0 0 5px;
    }
    &--epg {
      &::before {
        content: '${props => props.theme.main}';
        position: absolute;
        bottom: -0.35rem;
        width: 45%;
        font-size: 0.5625rem;
        height: auto;
        background: #fff;
        right: 0;
        transform: translateX(-23%);
        color: #2574d4;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;
