import * as payApi from '../../api/payperview';
import * as actions from './actions';
import * as payperview from '../../api/payperview';
export { postSubscriptionsPayperview, postConfirmPaypalPayperview, getPaymentMethod };

function postSubscriptionsPayperview(params) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      payApi
        .postSubscriptionsPayperview(params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function postConfirmPaypalPayperview(params) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      payApi
        .postConfirmPaypalPayperview(params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

const getPaymentMethod = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      payperview
        .getPaymentMethod()
        .then(response => {
          dispatch(actions.getPaymentMethod(response));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};

export const getTimesOfPackage = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      payperview
        .getTimesOfPackage()
        .then(response => {
          dispatch(actions.getPaymentMethod(response));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};

export const applyCoupons = data => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      payperview
        .applyCoupon(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};
