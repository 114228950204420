import { actions } from './authSlice';
import * as appAPIs from '../../api/appAPIs';
import * as auth from '../../api/auth';
import * as constants from './constants';
import * as referrals from '../../services/referrals';
import * as filterActions from '../../components/filter/filterSlice';
import { setUserProperties } from '../../services/analytics/SetUserProperties';
import { getConfigByKey } from '../tenantConfig/services';
import { isNode } from '~utils/ssr';

export {
  loginEmail,
  requestCodeOtp,
  resetPasswordEmail,
  logout,
  registerEmail,
  registerFB,
  getAccountPaymentMethod,
  updateAccountPaymentMethod,
  loginSSO,
  setIsShowLogin,
  getPaidContent,
  resetPaymentMethod,
  loginGoogle,
  rememberAccount,
  loginGuestAccount,
  methodLoginGuestAccount,
  loginKaKaoTalk,
};

function loginEmail(email, password) {
  const loginMethod = getConfigByKey('features.loginPhoneNumber')
    ? appAPIs.loginPhoneNumber
    : appAPIs.login_email;
  return dispatch =>
    new Promise((resolve, reject) => {
      loginMethod(email, password)
        .then(response => {
          // dispatch(actions.removeGuestAccount());
          dispatch(actions.loginSuccess(response));
          setUserProperties();
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

function requestCodeOtp(email, url, phoneNumber) {
  return dispatch =>
    new Promise((resolve, reject) => {
      appAPIs
        .requestCode(email, url, phoneNumber)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          dispatch(actions.requestCodeFailure(error));
          reject(error);
        });
    });
}

function resetPasswordEmail(email, otp, new_password) {
  return dispatch =>
    new Promise((resolve, reject) => {
      appAPIs
        .resetPassword(email, otp, new_password)
        .then(response => {
          if (response) {
            dispatch(actions.resetPassSuccess(response));
            resolve(response);
          } else {
            dispatch(actions.resetPassFailure());
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
}

function logout() {
  return dispatch => {
    const promise1 = new Promise(resolve => {
      // dispatch(actions.logout());
      resolve(true);
    });

    const promise2 = methodLoginGuestAccount();
    return new Promise((resolve, reject) => {
      let newGuestAccount = null;
      Promise.all([promise1, promise2]).then(values => {
        values.map((items, i) => {
          if (!i) {
            dispatch(filterActions.resetActiveIDs());
            setTimeout(() => {
              setUserProperties();
            }, 1000);
          }
          if (i) {
            dispatch(actions.loginGuestAccount(items));
            newGuestAccount = items;
          }
        });
        resolve(newGuestAccount);
      });
    });
  };
}

function registerEmail(params) {
  return dispatch =>
    new Promise((resolve, reject) => {
      appAPIs
        .register_email(params)
        .then(response => {
          referrals.registerEvent({
            email: params.email,
            orderID: params.email,
          });
          setUserProperties();
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

function registerFB(token, platform, model, device_id, mac_address) {
  return dispatch =>
    new Promise((resolve, reject) => {
      appAPIs
        .fbLogin(token, platform, model, device_id, mac_address)
        .then(response => {
          const { email, id } = response.profile;
          referrals.registerEvent({ email, orderID: email });
          dispatch(actions.loginSuccess(response));
          setUserProperties();
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

function getAccountPaymentMethod() {
  return dispatch =>
    new Promise((resolve, reject) => {
      appAPIs
        .getAccountPaymentMethod()
        .then(response => {
          dispatch(actions.updateAccountPaymentMethod(response));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}
function updateAccountPaymentMethod(payload) {
  return dispatch => {
    dispatch(actions.updateAccountPaymentMethod(payload));
  };
}

function loginSSO(data = {}) {
  return dispatch =>
    new Promise((resolve, reject) => {
      auth
        .loginSSO(data)
        .then(response => {
          const payload = {
            ...response,
            methodLogin: constants.METHOD_LOGIN_SSO,
          };
          dispatch(actions.loginSuccess(payload));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

function setIsShowLogin(payload = false) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch(actions.setIsShowLogin(payload));
      resolve(payload);
    });
}

function getPaidContent() {
  return dispatch =>
    new Promise((resolve, reject) => {
      auth
        .getPaidContent()
        .then(response => {
          dispatch(actions.setPaidContents(response));
          resolve(response);
        })
        .catch(errors => {
          reject(errors);
        });
    });
}

function resetPaymentMethod() {
  return dispatch => {
    dispatch(actions.resetPaymentMethod());
  };
}

function loginGoogle(data) {
  return dispatch =>
    new Promise((resolve, reject) => {
      auth
        .loginGoogle(data)
        .then(response => {
          const { email, id } = response.profile;
          referrals.registerEvent({ email, orderID: email });
          dispatch(actions.loginSuccess(response));
          setUserProperties();
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

function rememberAccount(payload) {
  return dispatch => {
    dispatch(actions.rememberAccount(payload));
  };
}

function methodLoginGuestAccount() {
  const promise = new Promise((resolve, reject) => {
    auth
      .loginGuestAccount()
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
  return promise;
}

function loginGuestAccount() {
  return dispatch =>
    methodLoginGuestAccount().then(response => {
      if (isNode()) return Promise.resolve(null);
      dispatch(actions.loginGuestAccount(response));
    });
}

function loginKaKaoTalk(data, snType) {
  return dispatch =>
    new Promise((resolve, reject) => {
      auth
        .loginKaKaoTalk(data, snType)
        .then(response => {
          const { email, id } = response.profile;
          referrals.registerEvent({ email, orderID: email });
          dispatch(actions.loginSuccess(response));
          setUserProperties();
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

export function loginNaver({ accessToken } = {}, snType) {
  return dispatch =>
    new Promise((resolve, reject) => {
      auth
        .loginNaver({ token: accessToken }, snType)
        .then(response => {
          const { email, id } = response.profile;
          // referrals.registerEvent({ email: email, orderID: email });
          dispatch(actions.loginSuccess(response));
          setUserProperties();
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

export const loginWithApple =
  (payload = {}) =>
  dispatch =>
    new Promise((resolve, reject) => {
      auth
        .loginWithApple(payload)
        .then(response => {
          dispatch(actions.loginSuccess(response));
          setUserProperties();
          resolve(response);
        })
        .catch(reject);
    });
