import { UserApiInterface } from '~vimai-api-adapter';
import { IAxiosResponseData } from '~core/models';
import { apiAdapter, config, axiosTakeDataFromResponse } from '~share';

const { userService } = apiAdapter;

export const drmCCU = (params: {
  action: string;
  user_id: string;
  session: string;
}): Promise<IAxiosResponseData<UserApiInterface['limitCcuApi_5']> | void> => {
  const { accountId = '', tenantSlug = '', authorization, acceptLanguage } = config;

  if (!authorization) {
    return Promise.resolve();
  }

  const { action = 'ping', user_id, session } = params;
  const dataCcu = { user_id, session };
  return userService
    .limitCcuApi_5(accountId, action, tenantSlug, dataCcu, authorization, acceptLanguage)
    .then(axiosTakeDataFromResponse);
};
