Object.defineProperty(exports, "__esModule", { value: true });
exports.getListFilterOptionsAndFilterPrefix = exports.findRibbonNameBySlugFromCategory = exports.getFilterOptionsFromRibbonSlug = exports.createRibbonSlugByForCategoryFilter = void 0;
var messages_1 = require("../messages");
var constants_1 = require("../constants");
var createRibbonSlugByForCategoryFilter = function (filterOptions) {
    return '?' + new URLSearchParams(filterOptions).toString();
};
exports.createRibbonSlugByForCategoryFilter = createRibbonSlugByForCategoryFilter;
var getFilterOptionsFromRibbonSlug = function (ribbonSlug) {
    if (ribbonSlug === null || ribbonSlug === void 0 ? void 0 : ribbonSlug.startsWith('?')) {
        var params = new URLSearchParams(ribbonSlug);
        return {
            type: params.get('type') || 'category',
            categorySlug: params.get('categorySlug'),
            genreSlug: params.get('genreSlug') || undefined,
            providerSlug: params.get('providerSlug') || undefined,
            subCategorySlug: params.get('subCategorySlug') || undefined,
            sortSlug: params.get('sortSlug') || undefined,
        };
    }
    else {
        return {
            type: 'category',
            categorySlug: '',
            genreSlug: '',
            providerSlug: '',
            subCategorySlug: '',
            sortSlug: '',
        };
    }
};
exports.getFilterOptionsFromRibbonSlug = getFilterOptionsFromRibbonSlug;
var findRibbonNameBySlugFromCategory = function (slug, categoryData) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var filter = (0, exports.getFilterOptionsFromRibbonSlug)(slug);
    if (filter.providerSlug) {
        return (_c = (_b = (_a = categoryData.filter_options) === null || _a === void 0 ? void 0 : _a.providers) === null || _b === void 0 ? void 0 : _b.find(function (x) { return x.slug === filter.providerSlug; })) === null || _c === void 0 ? void 0 : _c.title;
    }
    if (filter.genreSlug) {
        return (_f = (_e = (_d = categoryData.filter_options) === null || _d === void 0 ? void 0 : _d.genres) === null || _e === void 0 ? void 0 : _e.find(function (x) { return x.slug === filter.genreSlug; })) === null || _f === void 0 ? void 0 : _f.title;
    }
    if (filter.subCategorySlug) {
        return (_j = (_h = (_g = categoryData.filter_options) === null || _g === void 0 ? void 0 : _g.sub_categories) === null || _h === void 0 ? void 0 : _h.find(function (x) { return x.slug === filter.subCategorySlug; })) === null || _j === void 0 ? void 0 : _j.title;
    }
    if (filter.sortSlug) {
        return (_m = (_l = (_k = categoryData.filter_options) === null || _k === void 0 ? void 0 : _k.sort_by) === null || _l === void 0 ? void 0 : _l.find(function (x) { return x.slug === filter.sortSlug; })) === null || _m === void 0 ? void 0 : _m.title;
    }
    return messages_1.Messages.get('textAll');
};
exports.findRibbonNameBySlugFromCategory = findRibbonNameBySlugFromCategory;
var getListFilterOptionsAndFilterPrefix = function (category) {
    var _a, _b, _c;
    var listFilter, prefix;
    var d = { slug: '', title: messages_1.Messages.get('textAll') };
    var providers = ((_a = category.filter_options) === null || _a === void 0 ? void 0 : _a.providers) || [];
    var genres = ((_b = category.filter_options) === null || _b === void 0 ? void 0 : _b.genres) || [];
    var subCategories = ((_c = category.filter_options) === null || _c === void 0 ? void 0 : _c.sub_categories) || [];
    var maxLength = Math.max(providers.length, genres.length, subCategories.length);
    if (providers.length === maxLength) {
        prefix = constants_1.RibbonSlugFilter.PROVIDER;
        listFilter = providers;
    }
    else if (subCategories.length === maxLength) {
        prefix = constants_1.RibbonSlugFilter.SUB_CATEGORY;
        listFilter = subCategories;
    }
    else {
        prefix = constants_1.RibbonSlugFilter.GENRE;
        listFilter = genres;
    }
    if (!listFilter.length) {
        listFilter = [d];
    }
    return { listFilter: listFilter, prefix: prefix };
};
exports.getListFilterOptionsAndFilterPrefix = getListFilterOptionsAndFilterPrefix;
