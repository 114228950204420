import styled from 'styled-components';
export const AgeRatingBLock = styled.div`
  &.vjs-ageRating-container {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    padding-left: 20px;
    .vjs-ageRating-container {
      &--groupRating {
        /* margin-left: auto; */
        /* padding-top: 1.2rem; */
        /* right: 8%; */
        position: relative;
        padding-top: 20px;
        /* left: 2%; */
        &__images {
          max-width: 60px;
          max-height: 60px;
          object-fit: cover;
          object-position: center;
          margin-left: 11px;
          &:first-child {
            margin: 0;
          }
        }
        &__codeODK {
          font-size: 20px;
          color: #d6d6d6;
          text-decoration: none solid rgb(214, 214, 214);
          padding: 16px 0;
          font-family: 'Neue Haas Grotesk Display Pro';
          font-weight: 300;
        }
      }
    }
  }
  @media (min-width: 2559px) {
    .vjs-ageRating-container {
      &--groupRating {
        &__images {
          max-width: 100px !important;
          max-height: 100px !important;
        }
        &__codeODK {
          font-size: 35px !important;
        }
      }
    }
  }
  @media (min-width: 4095px) {
    .vjs-ageRating-container {
      &--groupRating {
        &__images {
          max-width: 120px !important;
          max-height: 120px !important;
        }
      }
    }
  }
  @media (max-width: 992px) {
    &.vjs-ageRating-container {
      .vjs-ageRating-container {
        &--groupRating {
          /* right: 10%; */
          /* padding-top: 0.8rem; */
          max-width: 100%;
          /* left: 2%; */
          &__images {
            max-width: 60px;
            max-height: 60px;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    &.vjs-ageRating-container {
      padding-left: 8px;

      .vjs-ageRating-container {
        &--groupRating {
          max-width: 250px;
          padding-top: 0px;
          /* left: 3%; */
          &__images {
            margin-left: 5px;
            padding: 5px;
            max-width: 45px;
            max-height: 45px;
          }
          &__codeODK {
            font-size: 14px !important;
            padding: 8px 0;
          }
        }
      }
    }
  }
`;
