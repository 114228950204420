import * as types from './actionTypes';

export { getPaymentMethod };

function getPaymentMethod(payload) {
  return {
    type: types.SET_PAYMENT_METHOD,
    payload,
  };
}
