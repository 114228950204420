import DefaultBackdrop from '~img/img/projectw/default_image/default-Backdrop.jpeg';
import DefaultBanner from '~img/img/projectw/default_image/default-banner.jpeg';
import DefaultBanner_16_9 from '~img/img/projectw/default_image/default-Banner(16-9).jpeg';
import DefaultThumbnail from '~img/img/projectw/default_image/default-Thumbnail.jpeg';
import DefaultThumbnail_9_5 from '~img/img/projectw/default_image/default-Thumbnail(9-5).jpeg';
import { isNode } from '~utils/ssr';

function supportFormatWebp() {
  if (isNode()) {
    return false;
  }
  const elem = document.createElement('canvas');

  if (!!(elem.getContext && elem.getContext('2d'))) {
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
  }
  return false;
}

export const RESOLUTION_SCALE_HD = 'web@hd';
export const RESOLUTION_SCALE_SD = 'web@sd';
export const RESOLUTION_SCALE_FHD = 'web@fhd';
export const RESOLUTION_SCALE_QHD = 'web@qhd';

export const IMAGE_THUMBNAIL_TYPE = 'thumbnail';
export const IMAGE_POSTER_TYPE = 'poster';
export const IMAGE_BANNER_TYPE = 'banner';
export const IMAGE_BACKDROP_TYPE = 'backdrop';

export const DEFAULT_BACKDROP = DefaultBackdrop;
export const DEFAULT_BANNER = DefaultBanner;
export const DEFAULT_BANNER_16_9 = DefaultBanner_16_9;
export const DEFAULT_THUMBNAIL = DefaultThumbnail;
export const DEFAULT_THUMBNAIL_9_5 = DefaultThumbnail_9_5;

export const IMAGE_TYPE_WIDTH_MATRIX = [
  [
    null, //0
    RESOLUTION_SCALE_SD, //1
    RESOLUTION_SCALE_HD, //2
    RESOLUTION_SCALE_FHD, //3
    RESOLUTION_SCALE_QHD, //4
  ],
  [IMAGE_THUMBNAIL_TYPE, 384, 384, 1280, 1280],
  [IMAGE_POSTER_TYPE, 384, 384, 1280, 1280],
  [IMAGE_BANNER_TYPE, 384, 384, 1920, 1920],
  [IMAGE_BACKDROP_TYPE, 427, 427, 2560, 2560],
];

export const SUPPORT_FORMAT_WEBP = supportFormatWebp();
