import {
  YOUTUBE_UNSTARTED,
  YOUTUBE_ENDED,
  YOUTUBE_PLAYING,
  YOUTUBE_PAUSED,
  YOUTUBE_BUFFERING,
  YOUTUBE_CUED,
} from './../constants';
import { pushEventFirebase, pushEventFirebasePlayer } from './../components/pushEventFirebase';

const handlePushTimeEvent = (component, player) => {
  const entityDetail = component.entityDetail;
  const { dispatchPostFirstFiveSecPlay } = component.props;
  const curenntTime = (player && player.current && player.current.currentTime) || 5;
  component.timePushEvent = setInterval(() => {
    component.viewHour += 1;
    if (component.viewHour === 5) {
      pushEventFirebasePlayer(entityDetail, 'videoFiveSecPlay');
      component._updateProgress(entityDetail.id, curenntTime);
      if (!component.watched) {
        dispatchPostFirstFiveSecPlay(entityDetail.id);
        component.watched = true;
      }
    }
    if (component.viewHour % 60 === 0) {
      pushEventFirebasePlayer(entityDetail, 'viewMinuteLog', 60);
    }
    if (component.viewHour % 300 === 0) {
      pushEventFirebasePlayer(entityDetail, 'viewhourLog', 300);
    }
  }, 1000);
};

const onStateYoutubeChange = (component, playerStatus) => {
  switch (playerStatus) {
    case YOUTUBE_UNSTARTED:
      break;
    case YOUTUBE_ENDED:
      break;
    case YOUTUBE_PLAYING:
      break;
    case YOUTUBE_PAUSED:
      break;
    case YOUTUBE_BUFFERING:
      clearInterval(component.timePushEvent);
      break;
    case YOUTUBE_CUED:
      break;
    default:
  }
};

const onYoutubeReady = (entityDetail, isRender) => {
  if (isRender) {
    pushEventFirebasePlayer(entityDetail, 'playerLoad');
    pushEventFirebasePlayer(entityDetail, 'videoPlayStart');
    pushEventFirebasePlayer(entityDetail, 'videoFirstPlay');
    isRender = false;
  }
};

const youtubeEnded = (component, originEntityDetail = null) => {
  let entityDetail = originEntityDetail || component.entityDetail;
  let lessTime = component.viewHour % 300;
  let restMinuteTime = component.viewHour % 60;
  if (restMinuteTime !== 0) {
    pushEventFirebasePlayer(entityDetail, 'viewMinuteLog', restMinuteTime);
  }
  if (lessTime) {
    pushEventFirebasePlayer(entityDetail, 'viewhourLog', lessTime);
  }
  clearInterval(component.timePushEvent);
  component.viewHour = 0;
};

const youtubeStart = (component, player) => {
  let entityDetail = component.entityDetail;
  clearInterval(component.timePushEvent);
  pushEventFirebase(entityDetail, 'play');
  handlePushTimeEvent(component, player);
};

const youtubePause = component => {
  let entityDetail = component.entityDetail;
  pushEventFirebase(entityDetail, 'pause');
  clearInterval(component.timePushEvent);
};

export { onStateYoutubeChange, onYoutubeReady, youtubeEnded, youtubeStart, youtubePause };
