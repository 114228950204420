/* tslint:disable */
/* eslint-disable */
/**
 * Vimai Core Service UI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentApi = exports.ContentApiFactory = exports.ContentApiFp = exports.ContentApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * ContentApi - axios parameter creator
 * @export
 */
var ContentApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create user report player
         * @summary Create user report player
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {UserReportRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {'ios' | 'android' | 'web' | 'stb' | 'atv'} [platform] platform
         * @param {string} [version] App version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserContentReport: function (contentId, tenantSlug, data, authorization, acceptLanguage, platform, version, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('createUserContentReport', 'contentId', contentId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('createUserContentReport', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('createUserContentReport', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/contents/{content_id}/report"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (platform !== undefined) {
                                localVarQueryParameter['platform'] = platform;
                            }
                            if (version !== undefined) {
                                localVarQueryParameter['version'] = version;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete review by review id
         * @summary Delete review by review id
         * @param {string} reviewId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContentReviewDetail: function (reviewId, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'reviewId' is not null or undefined
                            (0, common_1.assertParamExists)('deleteContentReviewDetail', 'reviewId', reviewId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('deleteContentReviewDetail', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/reviews/{review_id}/detail"
                                .replace("{".concat("review_id", "}"), encodeURIComponent(String(reviewId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * DRM Licence Callback
         * @summary DRM Licence Callback
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drmLicenceCallback: function (tenantSlug, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('drmLicenceCallback', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/drm_today_license"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get live info of channel
         * @summary Get live info of channel
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {string} [timezone] timezone of your current location
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelLiveInfo: function (channelId, tenantSlug, timezone, select, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('getChannelLiveInfo', 'channelId', channelId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getChannelLiveInfo', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/channels/{channel_id}/live_info/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (timezone !== undefined) {
                                localVarQueryParameter['timezone'] = timezone;
                            }
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get content current streaming
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentCurrentStreaming: function (contentId, tenantSlug, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getContentCurrentStreaming', 'contentId', contentId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getContentCurrentStreaming', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/contents/{content_id}/current_streaming"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get content deep link
         * @summary Get content deep link
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentDeepLink: function (contentId, tenantSlug, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getContentDeepLink', 'contentId', contentId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getContentDeepLink', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/contents/{content_id}/deep_link/"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get content detail
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [platform] web, android, ios
         * @param {string} [dataType] Data type for content
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentDetail: function (contentId, tenantSlug, acceptLanguage, select, imageResolutionScale, platform, dataType, imageFormat, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getContentDetail', 'contentId', contentId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getContentDetail', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/contents/{content_id}/detail"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (imageResolutionScale !== undefined) {
                                localVarQueryParameter['image_resolution_scale'] = imageResolutionScale;
                            }
                            if (platform !== undefined) {
                                localVarQueryParameter['platform'] = platform;
                            }
                            if (dataType !== undefined) {
                                localVarQueryParameter['data_type'] = dataType;
                            }
                            if (imageFormat !== undefined) {
                                localVarQueryParameter['image_format'] = imageFormat;
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get content detail
         * @summary Get content detail
         * @param {string} contentId Id of content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [resolution] 1x, 2x, 3x
         * @param {string} [size] small, medium, large
         * @param {string} [platform] web, android, ios
         * @param {string} [xhr] (0/1) is web platform
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getContentDetailById: function (contentId, authorization, acceptLanguage, resolution, size, platform, xhr, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getContentDetailById', 'contentId', contentId);
                            localVarPath = "/backend/cm/content/{content_id}/"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (resolution !== undefined) {
                                localVarQueryParameter['resolution'] = resolution;
                            }
                            if (size !== undefined) {
                                localVarQueryParameter['size'] = size;
                            }
                            if (platform !== undefined) {
                                localVarQueryParameter['platform'] = platform;
                            }
                            if (xhr !== undefined) {
                                localVarQueryParameter['xhr'] = xhr;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get content navigation option
         * @summary Get content navigation option
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentNavigationOption: function (tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getContentNavigationOption', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/content_navigation_option/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get related ribbons for content
         * @summary Get related ribbons for content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentRelatedRibbons: function (contentId, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getContentRelatedRibbons', 'contentId', contentId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getContentRelatedRibbons', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/contents/{content_id}/related_ribbons"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List content report template
         * @summary List content report template
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [locale] Locale (vi/en)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentReportTemplates: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, locale, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getContentReportTemplates', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/report_templates/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (search !== undefined) {
                                localVarQueryParameter['search'] = search;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (locale !== undefined) {
                                localVarQueryParameter['locale'] = locale;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get a review by review id
         * @summary Get a review by review id
         * @param {string} reviewId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentReviewDetail: function (reviewId, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'reviewId' is not null or undefined
                            (0, common_1.assertParamExists)('getContentReviewDetail', 'reviewId', reviewId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getContentReviewDetail', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/reviews/{review_id}/detail"
                                .replace("{".concat("review_id", "}"), encodeURIComponent(String(reviewId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get scrubber thumbnail template of content
         * @summary Get scrubber thumbnail template of content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentScrubberTemplate: function (contentId, tenantSlug, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getContentScrubberTemplate', 'contentId', contentId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getContentScrubberTemplate', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/contents/{content_id}/scrubber_template/"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get preview images (scrubber) for content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentScrubberThumbnails: function (contentId, tenantSlug, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getContentScrubberThumbnails', 'contentId', contentId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getContentScrubberThumbnails', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/contents/{content_id}/scrubber_thumbnails/"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get list reviews by content
         * @summary Get list reviews by content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentUserReviews: function (contentId, tenantSlug, authorization, acceptLanguage, page, limit, select, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getContentUserReviews', 'contentId', contentId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getContentUserReviews', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/contents/{content_id}/reviews/"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get content dynamic detail
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [dataType] Data type for content
         * @param {string} [adParams] Additional ad parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentViewInfo: function (contentId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, dataType, adParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getContentViewInfo', 'contentId', contentId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getContentViewInfo', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/contents/{content_id}/view"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (imageResolutionScale !== undefined) {
                                localVarQueryParameter['image_resolution_scale'] = imageResolutionScale;
                            }
                            if (dataType !== undefined) {
                                localVarQueryParameter['data_type'] = dataType;
                            }
                            if (adParams !== undefined) {
                                localVarQueryParameter['ad_params'] = adParams;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get content detail
         * @summary Get content detail
         * @param {string} contentId Id of content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [resolution] 1x, 2x, 3x
         * @param {string} [size] small, medium, large
         * @param {string} [platform] web, android, ios
         * @param {string} [xhr] (0/1) is web platform
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDetailByContentId: function (contentId, authorization, acceptLanguage, resolution, size, platform, xhr, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getDetailByContentId', 'contentId', contentId);
                            localVarPath = "/backend/cm/detail/{content_id}/"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (resolution !== undefined) {
                                localVarQueryParameter['resolution'] = resolution;
                            }
                            if (size !== undefined) {
                                localVarQueryParameter['size'] = size;
                            }
                            if (platform !== undefined) {
                                localVarQueryParameter['platform'] = platform;
                            }
                            if (xhr !== undefined) {
                                localVarQueryParameter['xhr'] = xhr;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Home page api
         * @summary Home page api
         * @param {string} platformSlug
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [regionSlug] Filter by region
         * @param {string} [genreSlug] Filter by genre
         * @param {string} [languageSlug] Filter by language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomePage: function (platformSlug, tenantSlug, authorization, acceptLanguage, regionSlug, genreSlug, languageSlug, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'platformSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getHomePage', 'platformSlug', platformSlug);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getHomePage', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/tenant_platforms/{platform_slug}/home_page"
                                .replace("{".concat("platform_slug", "}"), encodeURIComponent(String(platformSlug)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (regionSlug !== undefined) {
                                localVarQueryParameter['region_slug'] = regionSlug;
                            }
                            if (genreSlug !== undefined) {
                                localVarQueryParameter['genre_slug'] = genreSlug;
                            }
                            if (languageSlug !== undefined) {
                                localVarQueryParameter['language_slug'] = languageSlug;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get page content filter
         * @summary Get page content filter
         * @param {string} pageSlug
         * @param {string} tenantSlug
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {'en' | 'vi'} [locale] Language
         * @param {boolean} [filterForSideNavigationSection] Filter for side navigation section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageContentFilter: function (pageSlug, tenantSlug, acceptLanguage, select, locale, filterForSideNavigationSection, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'pageSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getPageContentFilter', 'pageSlug', pageSlug);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getPageContentFilter', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/tenant_pages/{page_slug}/content_filter"
                                .replace("{".concat("page_slug", "}"), encodeURIComponent(String(pageSlug)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (locale !== undefined) {
                                localVarQueryParameter['locale'] = locale;
                            }
                            if (filterForSideNavigationSection !== undefined) {
                                localVarQueryParameter['filter_for_side_navigation_section'] = filterForSideNavigationSection;
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get program for content
         * @summary Get program for content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentProgramOfContent: function (contentId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, imageFormat, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getParentProgramOfContent', 'contentId', contentId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getParentProgramOfContent', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/contents/{content_id}/program"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (imageResolutionScale !== undefined) {
                                localVarQueryParameter['image_resolution_scale'] = imageResolutionScale;
                            }
                            if (imageFormat !== undefined) {
                                localVarQueryParameter['image_format'] = imageFormat;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get Roku Feed Schema Json Format.
         * @summary Get Roku Feed Schema Json Format.
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRokuFeedSchemaJsonFormat: function (tenantSlug, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getRokuFeedSchemaJsonFormat', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/roku_directed_feed_schema"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get search result
         * @param {string} tenantSlug
         * @param {string} q Search keyword
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchContents: function (tenantSlug, q, authorization, acceptLanguage, page, limit, select, imageResolutionScale, imageFormat, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getSearchContents', 'tenantSlug', tenantSlug);
                            // verify required parameter 'q' is not null or undefined
                            (0, common_1.assertParamExists)('getSearchContents', 'q', q);
                            localVarPath = "/tenants/{tenant_slug}/search/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (imageResolutionScale !== undefined) {
                                localVarQueryParameter['image_resolution_scale'] = imageResolutionScale;
                            }
                            if (q !== undefined) {
                                localVarQueryParameter['q'] = q;
                            }
                            if (imageFormat !== undefined) {
                                localVarQueryParameter['image_format'] = imageFormat;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get season detail
         * @param {string} seasonId
         * @param {string} tenantSlug
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeasonDetail: function (seasonId, tenantSlug, page, limit, select, imageResolutionScale, imageFormat, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'seasonId' is not null or undefined
                            (0, common_1.assertParamExists)('getSeasonDetail', 'seasonId', seasonId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getSeasonDetail', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/seasons/{season_id}/"
                                .replace("{".concat("season_id", "}"), encodeURIComponent(String(seasonId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (imageResolutionScale !== undefined) {
                                localVarQueryParameter['image_resolution_scale'] = imageResolutionScale;
                            }
                            if (imageFormat !== undefined) {
                                localVarQueryParameter['image_format'] = imageFormat;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get content detail (SHOW)
         * @summary Get content detail(SHOW)
         * @param {string} contentId id of content
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [resolution] 1x,2x,3x
         * @param {string} [size] small, medium, large
         * @param {string} [platform] web, android , ios
         * @param {string} [xhr] (1/0) is web platform
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getStreamcallOfUser: function (contentId, authorization, acceptLanguage, resolution, size, platform, xhr, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getStreamcallOfUser', 'contentId', contentId);
                            localVarPath = "/backend/cm/streamcall/{content_id}/"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (resolution !== undefined) {
                                localVarQueryParameter['resolution'] = resolution;
                            }
                            if (size !== undefined) {
                                localVarQueryParameter['size'] = size;
                            }
                            if (platform !== undefined) {
                                localVarQueryParameter['platform'] = platform;
                            }
                            if (xhr !== undefined) {
                                localVarQueryParameter['xhr'] = xhr;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get top content in the tenant page or the system
         * @summary Get top content in the tenant page or the system
         * @param {string} tenantSlug
         * @param {string} [tenantPageSlug] id of page
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopContents: function (tenantSlug, tenantPageSlug, acceptLanguage, select, imageResolutionScale, imageFormat, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getTopContents', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/top_contents/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (tenantPageSlug !== undefined) {
                                localVarQueryParameter['tenant_page_slug'] = tenantPageSlug;
                            }
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (imageResolutionScale !== undefined) {
                                localVarQueryParameter['image_resolution_scale'] = imageResolutionScale;
                            }
                            if (imageFormat !== undefined) {
                                localVarQueryParameter['image_format'] = imageFormat;
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get content dynamic detail list
         * @summary Get content dynamic detail list
         * @param {string} tenantSlug
         * @param {Array<string>} ids Ids of contents
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewInfoOfContents: function (tenantSlug, ids, authorization, acceptLanguage, select, imageResolutionScale, imageFormat, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getViewInfoOfContents', 'tenantSlug', tenantSlug);
                            // verify required parameter 'ids' is not null or undefined
                            (0, common_1.assertParamExists)('getViewInfoOfContents', 'ids', ids);
                            localVarPath = "/tenants/{tenant_slug}/contents/view"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (ids) {
                                localVarQueryParameter['ids'] = ids.join(base_1.COLLECTION_FORMATS.csv);
                            }
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (imageResolutionScale !== undefined) {
                                localVarQueryParameter['image_resolution_scale'] = imageResolutionScale;
                            }
                            if (imageFormat !== undefined) {
                                localVarQueryParameter['image_format'] = imageFormat;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Search content in tenant page
         * @summary Search content in tenant page
         * @param {string} tenantPageSlug
         * @param {string} tenantSlug
         * @param {string} q Search keyword
         * @param {string} [authorization] Access token
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [imageFormat] Image type
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchContentInPage: function (tenantPageSlug, tenantSlug, q, authorization, select, imageResolutionScale, imageFormat, page, limit, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantPageSlug' is not null or undefined
                            (0, common_1.assertParamExists)('searchContentInPage', 'tenantPageSlug', tenantPageSlug);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('searchContentInPage', 'tenantSlug', tenantSlug);
                            // verify required parameter 'q' is not null or undefined
                            (0, common_1.assertParamExists)('searchContentInPage', 'q', q);
                            localVarPath = "/tenants/{tenant_slug}/tenant_page/{tenant_page_slug}/search_contents/"
                                .replace("{".concat("tenant_page_slug", "}"), encodeURIComponent(String(tenantPageSlug)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (q !== undefined) {
                                localVarQueryParameter['q'] = q;
                            }
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (imageResolutionScale !== undefined) {
                                localVarQueryParameter['image_resolution_scale'] = imageResolutionScale;
                            }
                            if (imageFormat !== undefined) {
                                localVarQueryParameter['image_format'] = imageFormat;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Search gap content
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {number} gapDuration Requested gap duration
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGapContent: function (channelId, tenantSlug, gapDuration, authorization, acceptLanguage, imageResolutionScale, select, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'channelId' is not null or undefined
                            (0, common_1.assertParamExists)('searchGapContent', 'channelId', channelId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('searchGapContent', 'tenantSlug', tenantSlug);
                            // verify required parameter 'gapDuration' is not null or undefined
                            (0, common_1.assertParamExists)('searchGapContent', 'gapDuration', gapDuration);
                            localVarPath = "/tenants/{tenant_slug}/channels/{channel_id}/search_gap_content/"
                                .replace("{".concat("channel_id", "}"), encodeURIComponent(String(channelId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (imageResolutionScale !== undefined) {
                                localVarQueryParameter['image_resolution_scale'] = imageResolutionScale;
                            }
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (gapDuration !== undefined) {
                                localVarQueryParameter['gap_duration'] = gapDuration;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Static Image API
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        staticImageApi: function (tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('staticImageApi', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/static_images/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update real view content
         * @summary Update real view content
         * @param {string} tenantSlug
         * @param {string} contentId Id of content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentRealView: function (tenantSlug, contentId, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('updateContentRealView', 'tenantSlug', tenantSlug);
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('updateContentRealView', 'contentId', contentId);
                            localVarPath = "/tenants/{tenant_slug}/contents/{content_id}/view"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)))
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update a review by review id
         * @summary Update a review by review id
         * @param {string} reviewId
         * @param {string} tenantSlug
         * @param {UserContentReviewsRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentReviewDetail: function (reviewId, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'reviewId' is not null or undefined
                            (0, common_1.assertParamExists)('updateContentReviewDetail', 'reviewId', reviewId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('updateContentReviewDetail', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateContentReviewDetail', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/reviews/{review_id}/detail"
                                .replace("{".concat("review_id", "}"), encodeURIComponent(String(reviewId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Verify complete upload multipart file
         * @summary Verify complete upload multipart file
         * @param {string} tenantSlug
         * @param {CompleteMultipartUploadRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyCompleteUploadMultipartFile: function (tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('verifyCompleteUploadMultipartFile', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('verifyCompleteUploadMultipartFile', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/files/client_complete_multipart_upload"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ContentApiAxiosParamCreator = ContentApiAxiosParamCreator;
/**
 * ContentApi - functional programming interface
 * @export
 */
var ContentApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ContentApiAxiosParamCreator)(configuration);
    return {
        /**
         * Create user report player
         * @summary Create user report player
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {UserReportRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {'ios' | 'android' | 'web' | 'stb' | 'atv'} [platform] platform
         * @param {string} [version] App version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserContentReport: function (contentId, tenantSlug, data, authorization, acceptLanguage, platform, version, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createUserContentReport(contentId, tenantSlug, data, authorization, acceptLanguage, platform, version, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Delete review by review id
         * @summary Delete review by review id
         * @param {string} reviewId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContentReviewDetail: function (reviewId, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteContentReviewDetail(reviewId, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * DRM Licence Callback
         * @summary DRM Licence Callback
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drmLicenceCallback: function (tenantSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.drmLicenceCallback(tenantSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get live info of channel
         * @summary Get live info of channel
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {string} [timezone] timezone of your current location
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelLiveInfo: function (channelId, tenantSlug, timezone, select, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getChannelLiveInfo(channelId, tenantSlug, timezone, select, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get content current streaming
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentCurrentStreaming: function (contentId, tenantSlug, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getContentCurrentStreaming(contentId, tenantSlug, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get content deep link
         * @summary Get content deep link
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentDeepLink: function (contentId, tenantSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getContentDeepLink(contentId, tenantSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get content detail
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [platform] web, android, ios
         * @param {string} [dataType] Data type for content
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentDetail: function (contentId, tenantSlug, acceptLanguage, select, imageResolutionScale, platform, dataType, imageFormat, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getContentDetail(contentId, tenantSlug, acceptLanguage, select, imageResolutionScale, platform, dataType, imageFormat, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get content detail
         * @summary Get content detail
         * @param {string} contentId Id of content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [resolution] 1x, 2x, 3x
         * @param {string} [size] small, medium, large
         * @param {string} [platform] web, android, ios
         * @param {string} [xhr] (0/1) is web platform
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getContentDetailById: function (contentId, authorization, acceptLanguage, resolution, size, platform, xhr, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getContentDetailById(contentId, authorization, acceptLanguage, resolution, size, platform, xhr, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get content navigation option
         * @summary Get content navigation option
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentNavigationOption: function (tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getContentNavigationOption(tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get related ribbons for content
         * @summary Get related ribbons for content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentRelatedRibbons: function (contentId, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getContentRelatedRibbons(contentId, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * List content report template
         * @summary List content report template
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [locale] Locale (vi/en)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentReportTemplates: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, locale, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getContentReportTemplates(tenantSlug, search, page, pageSize, authorization, acceptLanguage, locale, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get a review by review id
         * @summary Get a review by review id
         * @param {string} reviewId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentReviewDetail: function (reviewId, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getContentReviewDetail(reviewId, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get scrubber thumbnail template of content
         * @summary Get scrubber thumbnail template of content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentScrubberTemplate: function (contentId, tenantSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getContentScrubberTemplate(contentId, tenantSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get preview images (scrubber) for content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentScrubberThumbnails: function (contentId, tenantSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getContentScrubberThumbnails(contentId, tenantSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get list reviews by content
         * @summary Get list reviews by content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentUserReviews: function (contentId, tenantSlug, authorization, acceptLanguage, page, limit, select, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getContentUserReviews(contentId, tenantSlug, authorization, acceptLanguage, page, limit, select, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get content dynamic detail
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [dataType] Data type for content
         * @param {string} [adParams] Additional ad parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentViewInfo: function (contentId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, dataType, adParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getContentViewInfo(contentId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, dataType, adParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get content detail
         * @summary Get content detail
         * @param {string} contentId Id of content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [resolution] 1x, 2x, 3x
         * @param {string} [size] small, medium, large
         * @param {string} [platform] web, android, ios
         * @param {string} [xhr] (0/1) is web platform
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDetailByContentId: function (contentId, authorization, acceptLanguage, resolution, size, platform, xhr, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getDetailByContentId(contentId, authorization, acceptLanguage, resolution, size, platform, xhr, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Home page api
         * @summary Home page api
         * @param {string} platformSlug
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [regionSlug] Filter by region
         * @param {string} [genreSlug] Filter by genre
         * @param {string} [languageSlug] Filter by language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomePage: function (platformSlug, tenantSlug, authorization, acceptLanguage, regionSlug, genreSlug, languageSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getHomePage(platformSlug, tenantSlug, authorization, acceptLanguage, regionSlug, genreSlug, languageSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get page content filter
         * @summary Get page content filter
         * @param {string} pageSlug
         * @param {string} tenantSlug
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {'en' | 'vi'} [locale] Language
         * @param {boolean} [filterForSideNavigationSection] Filter for side navigation section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageContentFilter: function (pageSlug, tenantSlug, acceptLanguage, select, locale, filterForSideNavigationSection, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPageContentFilter(pageSlug, tenantSlug, acceptLanguage, select, locale, filterForSideNavigationSection, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get program for content
         * @summary Get program for content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentProgramOfContent: function (contentId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, imageFormat, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getParentProgramOfContent(contentId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, imageFormat, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get Roku Feed Schema Json Format.
         * @summary Get Roku Feed Schema Json Format.
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRokuFeedSchemaJsonFormat: function (tenantSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getRokuFeedSchemaJsonFormat(tenantSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get search result
         * @param {string} tenantSlug
         * @param {string} q Search keyword
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchContents: function (tenantSlug, q, authorization, acceptLanguage, page, limit, select, imageResolutionScale, imageFormat, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSearchContents(tenantSlug, q, authorization, acceptLanguage, page, limit, select, imageResolutionScale, imageFormat, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get season detail
         * @param {string} seasonId
         * @param {string} tenantSlug
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeasonDetail: function (seasonId, tenantSlug, page, limit, select, imageResolutionScale, imageFormat, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSeasonDetail(seasonId, tenantSlug, page, limit, select, imageResolutionScale, imageFormat, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get content detail (SHOW)
         * @summary Get content detail(SHOW)
         * @param {string} contentId id of content
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [resolution] 1x,2x,3x
         * @param {string} [size] small, medium, large
         * @param {string} [platform] web, android , ios
         * @param {string} [xhr] (1/0) is web platform
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getStreamcallOfUser: function (contentId, authorization, acceptLanguage, resolution, size, platform, xhr, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getStreamcallOfUser(contentId, authorization, acceptLanguage, resolution, size, platform, xhr, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get top content in the tenant page or the system
         * @summary Get top content in the tenant page or the system
         * @param {string} tenantSlug
         * @param {string} [tenantPageSlug] id of page
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopContents: function (tenantSlug, tenantPageSlug, acceptLanguage, select, imageResolutionScale, imageFormat, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTopContents(tenantSlug, tenantPageSlug, acceptLanguage, select, imageResolutionScale, imageFormat, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get content dynamic detail list
         * @summary Get content dynamic detail list
         * @param {string} tenantSlug
         * @param {Array<string>} ids Ids of contents
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewInfoOfContents: function (tenantSlug, ids, authorization, acceptLanguage, select, imageResolutionScale, imageFormat, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getViewInfoOfContents(tenantSlug, ids, authorization, acceptLanguage, select, imageResolutionScale, imageFormat, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Search content in tenant page
         * @summary Search content in tenant page
         * @param {string} tenantPageSlug
         * @param {string} tenantSlug
         * @param {string} q Search keyword
         * @param {string} [authorization] Access token
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [imageFormat] Image type
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchContentInPage: function (tenantPageSlug, tenantSlug, q, authorization, select, imageResolutionScale, imageFormat, page, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.searchContentInPage(tenantPageSlug, tenantSlug, q, authorization, select, imageResolutionScale, imageFormat, page, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Search gap content
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {number} gapDuration Requested gap duration
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGapContent: function (channelId, tenantSlug, gapDuration, authorization, acceptLanguage, imageResolutionScale, select, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.searchGapContent(channelId, tenantSlug, gapDuration, authorization, acceptLanguage, imageResolutionScale, select, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Static Image API
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        staticImageApi: function (tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.staticImageApi(tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update real view content
         * @summary Update real view content
         * @param {string} tenantSlug
         * @param {string} contentId Id of content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentRealView: function (tenantSlug, contentId, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateContentRealView(tenantSlug, contentId, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update a review by review id
         * @summary Update a review by review id
         * @param {string} reviewId
         * @param {string} tenantSlug
         * @param {UserContentReviewsRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentReviewDetail: function (reviewId, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateContentReviewDetail(reviewId, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Verify complete upload multipart file
         * @summary Verify complete upload multipart file
         * @param {string} tenantSlug
         * @param {CompleteMultipartUploadRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyCompleteUploadMultipartFile: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.verifyCompleteUploadMultipartFile(tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.ContentApiFp = ContentApiFp;
/**
 * ContentApi - factory interface
 * @export
 */
var ContentApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ContentApiFp)(configuration);
    return {
        /**
         * Create user report player
         * @summary Create user report player
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {UserReportRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {'ios' | 'android' | 'web' | 'stb' | 'atv'} [platform] platform
         * @param {string} [version] App version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserContentReport: function (contentId, tenantSlug, data, authorization, acceptLanguage, platform, version, options) {
            return localVarFp.createUserContentReport(contentId, tenantSlug, data, authorization, acceptLanguage, platform, version, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete review by review id
         * @summary Delete review by review id
         * @param {string} reviewId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContentReviewDetail: function (reviewId, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.deleteContentReviewDetail(reviewId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * DRM Licence Callback
         * @summary DRM Licence Callback
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drmLicenceCallback: function (tenantSlug, options) {
            return localVarFp.drmLicenceCallback(tenantSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get live info of channel
         * @summary Get live info of channel
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {string} [timezone] timezone of your current location
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelLiveInfo: function (channelId, tenantSlug, timezone, select, options) {
            return localVarFp.getChannelLiveInfo(channelId, tenantSlug, timezone, select, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get content current streaming
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentCurrentStreaming: function (contentId, tenantSlug, acceptLanguage, options) {
            return localVarFp.getContentCurrentStreaming(contentId, tenantSlug, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get content deep link
         * @summary Get content deep link
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentDeepLink: function (contentId, tenantSlug, options) {
            return localVarFp.getContentDeepLink(contentId, tenantSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get content detail
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [platform] web, android, ios
         * @param {string} [dataType] Data type for content
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentDetail: function (contentId, tenantSlug, acceptLanguage, select, imageResolutionScale, platform, dataType, imageFormat, options) {
            return localVarFp.getContentDetail(contentId, tenantSlug, acceptLanguage, select, imageResolutionScale, platform, dataType, imageFormat, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get content detail
         * @summary Get content detail
         * @param {string} contentId Id of content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [resolution] 1x, 2x, 3x
         * @param {string} [size] small, medium, large
         * @param {string} [platform] web, android, ios
         * @param {string} [xhr] (0/1) is web platform
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getContentDetailById: function (contentId, authorization, acceptLanguage, resolution, size, platform, xhr, options) {
            return localVarFp.getContentDetailById(contentId, authorization, acceptLanguage, resolution, size, platform, xhr, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get content navigation option
         * @summary Get content navigation option
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentNavigationOption: function (tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.getContentNavigationOption(tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get related ribbons for content
         * @summary Get related ribbons for content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentRelatedRibbons: function (contentId, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.getContentRelatedRibbons(contentId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List content report template
         * @summary List content report template
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [locale] Locale (vi/en)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentReportTemplates: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, locale, options) {
            return localVarFp.getContentReportTemplates(tenantSlug, search, page, pageSize, authorization, acceptLanguage, locale, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get a review by review id
         * @summary Get a review by review id
         * @param {string} reviewId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentReviewDetail: function (reviewId, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.getContentReviewDetail(reviewId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get scrubber thumbnail template of content
         * @summary Get scrubber thumbnail template of content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentScrubberTemplate: function (contentId, tenantSlug, options) {
            return localVarFp.getContentScrubberTemplate(contentId, tenantSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get preview images (scrubber) for content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentScrubberThumbnails: function (contentId, tenantSlug, options) {
            return localVarFp.getContentScrubberThumbnails(contentId, tenantSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get list reviews by content
         * @summary Get list reviews by content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentUserReviews: function (contentId, tenantSlug, authorization, acceptLanguage, page, limit, select, options) {
            return localVarFp.getContentUserReviews(contentId, tenantSlug, authorization, acceptLanguage, page, limit, select, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get content dynamic detail
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [dataType] Data type for content
         * @param {string} [adParams] Additional ad parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentViewInfo: function (contentId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, dataType, adParams, options) {
            return localVarFp.getContentViewInfo(contentId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, dataType, adParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get content detail
         * @summary Get content detail
         * @param {string} contentId Id of content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [resolution] 1x, 2x, 3x
         * @param {string} [size] small, medium, large
         * @param {string} [platform] web, android, ios
         * @param {string} [xhr] (0/1) is web platform
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDetailByContentId: function (contentId, authorization, acceptLanguage, resolution, size, platform, xhr, options) {
            return localVarFp.getDetailByContentId(contentId, authorization, acceptLanguage, resolution, size, platform, xhr, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Home page api
         * @summary Home page api
         * @param {string} platformSlug
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [regionSlug] Filter by region
         * @param {string} [genreSlug] Filter by genre
         * @param {string} [languageSlug] Filter by language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomePage: function (platformSlug, tenantSlug, authorization, acceptLanguage, regionSlug, genreSlug, languageSlug, options) {
            return localVarFp.getHomePage(platformSlug, tenantSlug, authorization, acceptLanguage, regionSlug, genreSlug, languageSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get page content filter
         * @summary Get page content filter
         * @param {string} pageSlug
         * @param {string} tenantSlug
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {'en' | 'vi'} [locale] Language
         * @param {boolean} [filterForSideNavigationSection] Filter for side navigation section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageContentFilter: function (pageSlug, tenantSlug, acceptLanguage, select, locale, filterForSideNavigationSection, options) {
            return localVarFp.getPageContentFilter(pageSlug, tenantSlug, acceptLanguage, select, locale, filterForSideNavigationSection, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get program for content
         * @summary Get program for content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentProgramOfContent: function (contentId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, imageFormat, options) {
            return localVarFp.getParentProgramOfContent(contentId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, imageFormat, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get Roku Feed Schema Json Format.
         * @summary Get Roku Feed Schema Json Format.
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRokuFeedSchemaJsonFormat: function (tenantSlug, options) {
            return localVarFp.getRokuFeedSchemaJsonFormat(tenantSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get search result
         * @param {string} tenantSlug
         * @param {string} q Search keyword
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchContents: function (tenantSlug, q, authorization, acceptLanguage, page, limit, select, imageResolutionScale, imageFormat, options) {
            return localVarFp.getSearchContents(tenantSlug, q, authorization, acceptLanguage, page, limit, select, imageResolutionScale, imageFormat, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get season detail
         * @param {string} seasonId
         * @param {string} tenantSlug
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeasonDetail: function (seasonId, tenantSlug, page, limit, select, imageResolutionScale, imageFormat, options) {
            return localVarFp.getSeasonDetail(seasonId, tenantSlug, page, limit, select, imageResolutionScale, imageFormat, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get content detail (SHOW)
         * @summary Get content detail(SHOW)
         * @param {string} contentId id of content
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [resolution] 1x,2x,3x
         * @param {string} [size] small, medium, large
         * @param {string} [platform] web, android , ios
         * @param {string} [xhr] (1/0) is web platform
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getStreamcallOfUser: function (contentId, authorization, acceptLanguage, resolution, size, platform, xhr, options) {
            return localVarFp.getStreamcallOfUser(contentId, authorization, acceptLanguage, resolution, size, platform, xhr, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get top content in the tenant page or the system
         * @summary Get top content in the tenant page or the system
         * @param {string} tenantSlug
         * @param {string} [tenantPageSlug] id of page
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopContents: function (tenantSlug, tenantPageSlug, acceptLanguage, select, imageResolutionScale, imageFormat, options) {
            return localVarFp.getTopContents(tenantSlug, tenantPageSlug, acceptLanguage, select, imageResolutionScale, imageFormat, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get content dynamic detail list
         * @summary Get content dynamic detail list
         * @param {string} tenantSlug
         * @param {Array<string>} ids Ids of contents
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewInfoOfContents: function (tenantSlug, ids, authorization, acceptLanguage, select, imageResolutionScale, imageFormat, options) {
            return localVarFp.getViewInfoOfContents(tenantSlug, ids, authorization, acceptLanguage, select, imageResolutionScale, imageFormat, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Search content in tenant page
         * @summary Search content in tenant page
         * @param {string} tenantPageSlug
         * @param {string} tenantSlug
         * @param {string} q Search keyword
         * @param {string} [authorization] Access token
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [imageFormat] Image type
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchContentInPage: function (tenantPageSlug, tenantSlug, q, authorization, select, imageResolutionScale, imageFormat, page, limit, options) {
            return localVarFp.searchContentInPage(tenantPageSlug, tenantSlug, q, authorization, select, imageResolutionScale, imageFormat, page, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Search gap content
         * @param {string} channelId
         * @param {string} tenantSlug
         * @param {number} gapDuration Requested gap duration
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGapContent: function (channelId, tenantSlug, gapDuration, authorization, acceptLanguage, imageResolutionScale, select, options) {
            return localVarFp.searchGapContent(channelId, tenantSlug, gapDuration, authorization, acceptLanguage, imageResolutionScale, select, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Static Image API
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        staticImageApi: function (tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.staticImageApi(tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update real view content
         * @summary Update real view content
         * @param {string} tenantSlug
         * @param {string} contentId Id of content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentRealView: function (tenantSlug, contentId, authorization, acceptLanguage, options) {
            return localVarFp.updateContentRealView(tenantSlug, contentId, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update a review by review id
         * @summary Update a review by review id
         * @param {string} reviewId
         * @param {string} tenantSlug
         * @param {UserContentReviewsRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentReviewDetail: function (reviewId, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.updateContentReviewDetail(reviewId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Verify complete upload multipart file
         * @summary Verify complete upload multipart file
         * @param {string} tenantSlug
         * @param {CompleteMultipartUploadRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyCompleteUploadMultipartFile: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.verifyCompleteUploadMultipartFile(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ContentApiFactory = ContentApiFactory;
/**
 * ContentApi - object-oriented interface
 * @export
 * @class ContentApi
 * @extends {BaseAPI}
 */
var ContentApi = /** @class */ (function (_super) {
    __extends(ContentApi, _super);
    function ContentApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create user report player
     * @summary Create user report player
     * @param {string} contentId
     * @param {string} tenantSlug
     * @param {UserReportRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {'ios' | 'android' | 'web' | 'stb' | 'atv'} [platform] platform
     * @param {string} [version] App version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.createUserContentReport = function (contentId, tenantSlug, data, authorization, acceptLanguage, platform, version, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).createUserContentReport(contentId, tenantSlug, data, authorization, acceptLanguage, platform, version, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete review by review id
     * @summary Delete review by review id
     * @param {string} reviewId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.deleteContentReviewDetail = function (reviewId, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).deleteContentReviewDetail(reviewId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * DRM Licence Callback
     * @summary DRM Licence Callback
     * @param {string} tenantSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.drmLicenceCallback = function (tenantSlug, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).drmLicenceCallback(tenantSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get live info of channel
     * @summary Get live info of channel
     * @param {string} channelId
     * @param {string} tenantSlug
     * @param {string} [timezone] timezone of your current location
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.getChannelLiveInfo = function (channelId, tenantSlug, timezone, select, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).getChannelLiveInfo(channelId, tenantSlug, timezone, select, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get content current streaming
     * @param {string} contentId
     * @param {string} tenantSlug
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.getContentCurrentStreaming = function (contentId, tenantSlug, acceptLanguage, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).getContentCurrentStreaming(contentId, tenantSlug, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get content deep link
     * @summary Get content deep link
     * @param {string} contentId
     * @param {string} tenantSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.getContentDeepLink = function (contentId, tenantSlug, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).getContentDeepLink(contentId, tenantSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get content detail
     * @param {string} contentId
     * @param {string} tenantSlug
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
     * @param {string} [platform] web, android, ios
     * @param {string} [dataType] Data type for content
     * @param {string} [imageFormat] Image type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.getContentDetail = function (contentId, tenantSlug, acceptLanguage, select, imageResolutionScale, platform, dataType, imageFormat, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).getContentDetail(contentId, tenantSlug, acceptLanguage, select, imageResolutionScale, platform, dataType, imageFormat, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get content detail
     * @summary Get content detail
     * @param {string} contentId Id of content
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [resolution] 1x, 2x, 3x
     * @param {string} [size] small, medium, large
     * @param {string} [platform] web, android, ios
     * @param {string} [xhr] (0/1) is web platform
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.getContentDetailById = function (contentId, authorization, acceptLanguage, resolution, size, platform, xhr, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).getContentDetailById(contentId, authorization, acceptLanguage, resolution, size, platform, xhr, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get content navigation option
     * @summary Get content navigation option
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.getContentNavigationOption = function (tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).getContentNavigationOption(tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get related ribbons for content
     * @summary Get related ribbons for content
     * @param {string} contentId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.getContentRelatedRibbons = function (contentId, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).getContentRelatedRibbons(contentId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List content report template
     * @summary List content report template
     * @param {string} tenantSlug
     * @param {string} [search] A search term.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [locale] Locale (vi/en)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.getContentReportTemplates = function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, locale, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).getContentReportTemplates(tenantSlug, search, page, pageSize, authorization, acceptLanguage, locale, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get a review by review id
     * @summary Get a review by review id
     * @param {string} reviewId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.getContentReviewDetail = function (reviewId, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).getContentReviewDetail(reviewId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get scrubber thumbnail template of content
     * @summary Get scrubber thumbnail template of content
     * @param {string} contentId
     * @param {string} tenantSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.getContentScrubberTemplate = function (contentId, tenantSlug, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).getContentScrubberTemplate(contentId, tenantSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get preview images (scrubber) for content
     * @param {string} contentId
     * @param {string} tenantSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.getContentScrubberThumbnails = function (contentId, tenantSlug, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).getContentScrubberThumbnails(contentId, tenantSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get list reviews by content
     * @summary Get list reviews by content
     * @param {string} contentId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.getContentUserReviews = function (contentId, tenantSlug, authorization, acceptLanguage, page, limit, select, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).getContentUserReviews(contentId, tenantSlug, authorization, acceptLanguage, page, limit, select, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get content dynamic detail
     * @param {string} contentId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
     * @param {string} [dataType] Data type for content
     * @param {string} [adParams] Additional ad parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.getContentViewInfo = function (contentId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, dataType, adParams, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).getContentViewInfo(contentId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, dataType, adParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get content detail
     * @summary Get content detail
     * @param {string} contentId Id of content
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [resolution] 1x, 2x, 3x
     * @param {string} [size] small, medium, large
     * @param {string} [platform] web, android, ios
     * @param {string} [xhr] (0/1) is web platform
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.getDetailByContentId = function (contentId, authorization, acceptLanguage, resolution, size, platform, xhr, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).getDetailByContentId(contentId, authorization, acceptLanguage, resolution, size, platform, xhr, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Home page api
     * @summary Home page api
     * @param {string} platformSlug
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [regionSlug] Filter by region
     * @param {string} [genreSlug] Filter by genre
     * @param {string} [languageSlug] Filter by language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.getHomePage = function (platformSlug, tenantSlug, authorization, acceptLanguage, regionSlug, genreSlug, languageSlug, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).getHomePage(platformSlug, tenantSlug, authorization, acceptLanguage, regionSlug, genreSlug, languageSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get page content filter
     * @summary Get page content filter
     * @param {string} pageSlug
     * @param {string} tenantSlug
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {'en' | 'vi'} [locale] Language
     * @param {boolean} [filterForSideNavigationSection] Filter for side navigation section
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.getPageContentFilter = function (pageSlug, tenantSlug, acceptLanguage, select, locale, filterForSideNavigationSection, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).getPageContentFilter(pageSlug, tenantSlug, acceptLanguage, select, locale, filterForSideNavigationSection, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get program for content
     * @summary Get program for content
     * @param {string} contentId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
     * @param {string} [imageFormat] Image type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.getParentProgramOfContent = function (contentId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, imageFormat, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).getParentProgramOfContent(contentId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, imageFormat, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get Roku Feed Schema Json Format.
     * @summary Get Roku Feed Schema Json Format.
     * @param {string} tenantSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.getRokuFeedSchemaJsonFormat = function (tenantSlug, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).getRokuFeedSchemaJsonFormat(tenantSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get search result
     * @param {string} tenantSlug
     * @param {string} q Search keyword
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
     * @param {string} [imageFormat] Image type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.getSearchContents = function (tenantSlug, q, authorization, acceptLanguage, page, limit, select, imageResolutionScale, imageFormat, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).getSearchContents(tenantSlug, q, authorization, acceptLanguage, page, limit, select, imageResolutionScale, imageFormat, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get season detail
     * @param {string} seasonId
     * @param {string} tenantSlug
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
     * @param {string} [imageFormat] Image type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.getSeasonDetail = function (seasonId, tenantSlug, page, limit, select, imageResolutionScale, imageFormat, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).getSeasonDetail(seasonId, tenantSlug, page, limit, select, imageResolutionScale, imageFormat, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get content detail (SHOW)
     * @summary Get content detail(SHOW)
     * @param {string} contentId id of content
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [resolution] 1x,2x,3x
     * @param {string} [size] small, medium, large
     * @param {string} [platform] web, android , ios
     * @param {string} [xhr] (1/0) is web platform
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.getStreamcallOfUser = function (contentId, authorization, acceptLanguage, resolution, size, platform, xhr, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).getStreamcallOfUser(contentId, authorization, acceptLanguage, resolution, size, platform, xhr, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get top content in the tenant page or the system
     * @summary Get top content in the tenant page or the system
     * @param {string} tenantSlug
     * @param {string} [tenantPageSlug] id of page
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
     * @param {string} [imageFormat] Image type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.getTopContents = function (tenantSlug, tenantPageSlug, acceptLanguage, select, imageResolutionScale, imageFormat, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).getTopContents(tenantSlug, tenantPageSlug, acceptLanguage, select, imageResolutionScale, imageFormat, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get content dynamic detail list
     * @summary Get content dynamic detail list
     * @param {string} tenantSlug
     * @param {Array<string>} ids Ids of contents
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
     * @param {string} [imageFormat] Image type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.getViewInfoOfContents = function (tenantSlug, ids, authorization, acceptLanguage, select, imageResolutionScale, imageFormat, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).getViewInfoOfContents(tenantSlug, ids, authorization, acceptLanguage, select, imageResolutionScale, imageFormat, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Search content in tenant page
     * @summary Search content in tenant page
     * @param {string} tenantPageSlug
     * @param {string} tenantSlug
     * @param {string} q Search keyword
     * @param {string} [authorization] Access token
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
     * @param {string} [imageFormat] Image type
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.searchContentInPage = function (tenantPageSlug, tenantSlug, q, authorization, select, imageResolutionScale, imageFormat, page, limit, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).searchContentInPage(tenantPageSlug, tenantSlug, q, authorization, select, imageResolutionScale, imageFormat, page, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Search gap content
     * @param {string} channelId
     * @param {string} tenantSlug
     * @param {number} gapDuration Requested gap duration
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.searchGapContent = function (channelId, tenantSlug, gapDuration, authorization, acceptLanguage, imageResolutionScale, select, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).searchGapContent(channelId, tenantSlug, gapDuration, authorization, acceptLanguage, imageResolutionScale, select, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Static Image API
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.staticImageApi = function (tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).staticImageApi(tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update real view content
     * @summary Update real view content
     * @param {string} tenantSlug
     * @param {string} contentId Id of content
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.updateContentRealView = function (tenantSlug, contentId, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).updateContentRealView(tenantSlug, contentId, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update a review by review id
     * @summary Update a review by review id
     * @param {string} reviewId
     * @param {string} tenantSlug
     * @param {UserContentReviewsRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.updateContentReviewDetail = function (reviewId, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).updateContentReviewDetail(reviewId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Verify complete upload multipart file
     * @summary Verify complete upload multipart file
     * @param {string} tenantSlug
     * @param {CompleteMultipartUploadRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    ContentApi.prototype.verifyCompleteUploadMultipartFile = function (tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.ContentApiFp)(this.configuration).verifyCompleteUploadMultipartFile(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ContentApi;
}(base_1.BaseAPI));
exports.ContentApi = ContentApi;
