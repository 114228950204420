import React, { Component } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import jquery from 'jquery';
import { SHOW_TYPE, EPISODE_TYPE } from '../constants';

class Recommend extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      data: [],
      timer: 20,
    };
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  componentDidMount() {
    const { component, player } = this.props;
    const $player = jquery(player.el_);
    component
      ._getRecommend()
      .then(res => {
        this.setState(
          {
            data: res,
          },
          () => {
            if (player.duration() === player.currentTime()) {
              this.autoNext();
            }
          },
        );
      })
      .catch(err => {
        console.log('err', err);
      });
    player.on('ended', () => {
      $player.find('.vjs-recommend-display').addClass('vjs-recommend-open');
      this.autoNext();
    });
    player.on('seeking', () => {
      $player.find('.vjs-recommend-display').removeClass('vjs-recommend-open');
      clearInterval(this.timer);
      this.setState({
        timer: 20,
      });
    });
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  autoNext = () => {
    const { data, timer } = this.state;
    const { player } = this.props;
    if (!player) return;
    const $player = player.player_;
    const controlBar = player.getChild('controlBar');
    const playToggle = controlBar.getChild('playToggle').el();

    function fnPlayToggle() {
      const isPause = this.getAttribute('title');
      if (isPause === 'Replay') {
        clearInterval(this.timer);
      }
    }
    let countdown = timer;

    if (data.length <= 0) {
      return;
    }
    this.timer = setInterval(() => {
      countdown -= 1;
      if (countdown === 0) {
        clearInterval(this.timer);
        this.nextContent(data[0]);
      }
      this.setState({
        timer: countdown,
      });
      if ($player && $player.hasClass('vjs-report-open')) {
        countdown += 1;
      }

      playToggle.addEventListener('click', fnPlayToggle);
    }, 1000);
  };

  nextContent = item => {
    const { component } = this.props;
    const { match } = component.props;
    const regex = new RegExp('/:lg/((?:landing)|(?:detail))/:slug', 'g');
    const matchUrl = regex.exec(match.path || '');
    const page = matchUrl.length === 2 ? matchUrl[1] : 'detail';
    clearInterval(this.timer);
    let { slug } = item;
    if (item.type === SHOW_TYPE) {
      slug = item.current_season && item.current_season.current_episode.slug;
    }
    let premium = !!(!item.has_free_content && item.is_premium);
    const { isHasSubcription } = component.props.auth;
    if (isHasSubcription) {
      premium = false;
    }
    if (premium) {
      return component.props.history.push(`/${component.props.language}/landing/${item.slug}`);
    }
    return component.props.history.push(`/${component.props.language}/${page}/${slug}`);
  };

  _renderContents = () => {
    const { data } = this.state;
    const { component } = this.props;
    const { isHasSubcription } = component.props.auth;
    if (data.length > 0) {
      return data.map((item, index) => {
        let isTimer = true;
        const isYoutube = item.video_source;
        if (item.type === SHOW_TYPE || isYoutube === 1) {
          isTimer = false;
        }
        let premium = !!(!item.has_free_content && item.is_premium);
        if (isHasSubcription) {
          premium = false;
        }

        return (
          <div className="v-recommend--items" onClick={() => this.nextContent(item)} key={index}>
            <div className="v-recommend--thumbnail">
              <img src={item.images.thumbnail || item.images.poster || item.images.banner || ''} />
            </div>
            {isYoutube === 1 && (
              <div className="v-recommend--youtube">
                <img
                  src={require('../../../assets/img/youtube.png').default}
                  width="80"
                  height="25"
                />
              </div>
            )}
            <div className="v-recommend--vip" style={{ display: premium ? null : 'none' }}>
              <i className="fa fa-star" aria-hidden="true" style={{ paddingRight: '0.5rem' }} />
              VIP
            </div>
            <div className="v-recommend--description">
              <p className="v-recommend--name" style={{ width: !isTimer && '100%' }}>
                {item.title}
              </p>
              {/* {(isTimer && item.duration) &&
                <div className='v-recommend--timer'>
                  {moment.utc(item.duration * 1000).format('HH:mm:ss')}
                </div>
              } */}
            </div>
          </div>
        );
      });
    }
  };

  render() {
    const { t, component } = this.props;
    const { data, timer } = this.state;
    const { entityDetail, state } = component;
    const { infoShow } = state;
    if (data.length > 0) {
      return (
        <RowStyle className="v-recommend">
          <Col sm={12} xs={12} md={10} lg={10} className="v-recommend--head">
            <div className="v-recommend--title">
              {t('recommedation.txtComing', {
                name: entityDetail.type === EPISODE_TYPE ? infoShow.title : entityDetail.title,
              })}
              <i className="fa fa-angle-double-right" aria-hidden="true" />
            </div>
          </Col>
          <Col sm={12} xs={12} md={10} lg={10} className="v-recommend--body">
            {this._renderContents()}
          </Col>
          <Col sm={12} xs={12} md={10} lg={10} className="v-recommend--footer">
            <p>
              {t('recommedation.txtStart')}
              <span>
                {timer} {t('profile.second')}
              </span>
            </p>
          </Col>
        </RowStyle>
      );
    }
    return null;
  }
}

export const RowStyle = styled(Row)`
  &.v-recommend {
    background: #000000;
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 0.8125rem;
    color: #ffffff;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;
  }
  .v-recommend {
    &--title {
      font-size: 1.75rem;
      font-style: italic;
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;
      i {
        padding-left: 1rem;
      }
      &:after {
        content: '';
        height: 1px;
        margin-top: 0.125rem;
        border: 1px solid #666666;
        flex: auto;
        margin-right: 0.5rem;
      }
    }
    &--body {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 2rem;
    }
    &--footer {
      margin-top: 1rem;
      font-size: 1rem;
      color: #fff;
      font-style: italic;
      span {
        font-size: 1.5rem;
        font-weight: 600;
        font-style: none;
      }
    }
    &--items {
      width: 48%;
      outline: none;
      position: relative;
      cursor: pointer;
      /* overflow: hidden; */
      &:hover img {
        opacity: 0.8;
        /* transform: scale(1.2);
        box-shadow: 0 0 19px -3px #474747; */
      }
    }
    &--thumbnail {
      width: 100%;
      padding-top: calc(9 / 16 * 100%);
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        display: block;
        object-fit: cover;
        transition: all 0.2s ease-out;
        transform: scale(1);
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        border: 1px solid #9edfff;
      }
      &:before {
        content: ' ';
        height: 100%;
        width: 100%;
        position: absolute;
        right: 0.75rem;
        bottom: 0.75rem;
        border: 1px solid #2574d4;
      }
    }
    &--description {
      position: absolute;
      bottom: 0.5rem;
      left: 0;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    &--vip {
      font-weight: bolder;
      font-size: 0.8125rem;
      position: absolute;
      top: 10%;
      right: 0;
      padding: 0.75rem 1rem;
      color: #e8b600;
      border-radius: 2px;
      background-color: rgba(0, 0, 0, 0.6);
    }
    &--youtube {
      position: absolute;
      top: 10%;
      left: 1rem;
      width: 4rem;
      img {
        min-height: 0;
        width: 100%;
        height: auto;
        transform: scale(1) !important;
        &:hover {
          transform: scale(1);
        }
      }
    }
    &--name {
      padding: 0 0.5rem;
      font-size: 1rem;
      font-weight: 400;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-height: 1rem;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 65%;
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.8);
    }
    &--timer {
      font-weight: normal;
      font-size: 0.8125rem;
      color: #fff;
      padding: 0.5rem 1rem;
      border-radius: 2px;
      background-color: rgba(0, 0, 0, 0.7);
      display: flex;
      align-items: center;
      position: absolute;
      right: 1px;
      bottom: 0.75rem;
    }
  }
  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
    .v-recommend {
      &--youtube {
        width: 6rem;
      }
    }
  }

  @media (min-width: 992px) {
    .v-recommend {
      &--title {
        margin-bottom: 2.5rem;
      }
      &--items {
        width: 45%;
      }
      &--timer {
        padding: 0.5 1rem;
      }
      &--name {
        font-size: 1rem;
      }
      &--youtube {
        width: 6.5rem;
      }
    }
  }
  @media (max-width: 1024px) and (orientation: landscape) {
  }
`;

export default withTranslation()(Recommend);
