import React from 'react';
import { Panel } from 'react-bootstrap';
import styled from 'styled-components';
import moment from 'moment';
import * as epgApi from '../../../api/epg';
import i18n from 'i18next';
import { EPISODE_TYPE, CHANNEL_TYPE, SHOW_TYPE, CURATED_LIVE } from './../constants';
import Loader from 'react-loader-spinner';
import { _handleRewatchCuratedLive } from '../function/initCuratedLive';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import jquery from 'jquery';
import { getConfigByKey } from '~features/tenantConfig/services';

class EPG extends React.Component {
  constructor(props) {
    super(props);
    this.nowTime = moment();
    this.rewatchSrc = '';
    this.daypicker = React.createRef();
    this.orginDate = '';
    this.orginShowDate = '';
    this.orginEpgs = [];
    this.state = {
      startDate: this.nowTime.format('YYYY-MM-DD'), // can be any of these ['dayjs()', '', null, new Date(2018,12,1)]
      showDate: '',
      epgs: [],
      nowTime: this.nowTime,
      durationDay: 7,
      durationPreDay: 14,
      epgActive: null,
      rewatchingId: '',
      openPicker: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateLocale();
    this.blackList = [];
    this.isFirst = true;
  }
  componentWillMount() {
    const { locale } = this.props;
    if (!locale) {
      return;
    }
    moment.tz.setDefault(locale.ip.timezone);
  }

  updateLocale = () => {
    const locale = {
      vi: {
        longDateFormat: {
          ...moment().localeData()._longDateFormat,
          LLLL: 'dddd - Do MMMM',
        },
        weekdays: ['Chủ nhật', 'Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7'],
      },
      en: {
        longDateFormat: {
          ...moment().localeData()._longDateFormat,
          LLLL: 'dddd - Do MMMM',
        },
      },
    };
    moment.updateLocale(moment.locale(), locale[moment.locale()]);
  };

  handleChangeFullscreen = () => {
    const { epgRef } = this.refs;
    const { player } = this.props;

    epgRef.addEventListener('scroll', event => {
      epgRef.setAttribute('data-scroll-top', epgRef.scrollTop);
    });
    player.on('fullscreenchange', () => {
      // this.handleScrollTop(null, epgRef.getAttribute('data-scroll-top'))
    });
  };

  getEpgActive = rewatch => {
    const { component } = this.props;
    const { epgs } = component.state;
    const result = epgs.filter(element => element.id === rewatch);
    if (result.length) {
      component.isWatch = result[0];
    }
  };

  handleActiveProgram = () => {
    const { epgRef } = this.refs;
    const { component } = this.props;
    if (!epgRef) {
      return;
    }
    const limitTime = 10 * 60;
    let startCall = moment();
    let timeOut = 3000;

    const intervalActiveProgram = timeOut => {
      return setInterval(() => {
        const nowTime = moment();
        // if (this.state.startDate !== nowTime.format('YYYY-MM-DD')) {
        //   this.setState({
        //     startDate: nowTime.format('YYYY-MM-DD')
        //   }, () => {

        //   })
        // }
        const epgActive = epgRef.querySelector('.vjs-epg--item.active');
        const startedEl = epgRef.querySelectorAll('.vjs-epg--item.started');

        if (nowTime.unix() - startCall.unix() > limitTime) {
          this.isFirst = true;
          startCall = nowTime.clone();
          this.changeEpgs(nowTime.format('YYYY-MM-DD')).then(() => {
            this.handleBlackOut();
          });
        } else {
          this.handleBlackOut();
        }

        if (!epgActive && startedEl.length) {
          epgActive = startedEl[startedEl.length - 1];
        }

        if (!epgActive) {
          return;
        }
        const epgNext = epgActive.nextSibling;
        if (!epgNext) {
          return;
        }
        const startAt = epgNext.getAttribute('data-start'),
          endAt = epgNext.getAttribute('data-end');

        if (parseInt(startAt) <= nowTime.unix() && parseInt(endAt) > nowTime.unix()) {
          jquery(epgActive).removeClass('active').addClass('before');
          jquery(epgNext).removeClass('after').addClass('active').addClass('started');
          this.handleScrollTop(epgNext);
          return;
        }

        const durationNext = parseInt(epgActive.getAttribute('data-end')) - nowTime.unix();
        if (durationNext > 3) {
          timeOut = durationNext * 1000;
          clearInterval(this.intervalActiveProgram);
          this.intervalActiveProgram = intervalActiveProgram(timeOut);
          return;
        }
      }, timeOut);
    };

    this.intervalActiveProgram = intervalActiveProgram(timeOut);
  };

  handleBlackOut = () => {
    const { blackList } = this;
    let limitTime = 5 * 60;
    if (blackList.length <= 0) {
      return;
    }

    const nowTime = moment();
    let epgItem = null;

    blackList.forEach(epg => {
      if (!(parseInt(epg.start_at) <= nowTime.unix() && parseInt(epg.end_at) > nowTime.unix())) {
        return;
      }
      epgItem = epg;
    });
    this.displayBlockOut(epgItem);
  };

  displayBlockOut = epg => {
    const { player, component } = this.props;

    let isBlackOut = false;
    if (!player) {
      return;
    }
    if (epg && epg.is_blackout) {
      isBlackOut = true;
    }
    const $pl = jquery(player.el_);
    if (isBlackOut) {
      $pl.parent().addClass('vjs-black-out');
      let params = {
        liveId: null,
        rewatchId: null,
        channelId: null,
        detail: null,
      };
      component.props.dpUpdateCurrentLive(params);
      player.pause();
    } else {
      $pl.parent().removeClass('vjs-black-out');
      player.play();
    }
  };

  componentWillMount() {
    // this.handleBlackOut()
  }

  componentDidMount() {
    const pickDate = this.refs.pickDate;
    const { isRender, player, component } = this.props;
    this.rewatchSrc = player.src();
    const $player = jquery(player.el());
    jquery(pickDate).find('input').prop('readonly', true);
    this.handleActiveProgram();
    this.orginDate = moment(component.daySelected).format('YYYY-MM-DD');
    this.orginShowDate = moment(component.daySelected).format('LLLL');
    this.orginEpgs = component.entityDetail.items;
    this.setState(
      {
        showDate: this.orginShowDate,
      },
      () => {
        this.setState(
          {
            epgs: this.orginEpgs,
            startDate: this.orginDate,
          },
          () => {
            this.listBlackOut(this.state.epgs);
            player.on('loadeddata', () => {
              setTimeout(() => {
                this.handleScrollTop();
              }, 1000);
            });
          },
        );
      },
    );
    component.props
      .dpGetConfigCalenderEpg()
      .then(res => {
        this.setState({
          durationDay: res.curated_channel_max_preview_days,
          durationPreDay: res.curated_channel_max_playback_days,
        });
      })
      .catch();
    this.handleChangeFullscreen();
    this.originSrc = player.src();
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    clearInterval(this.intervalActiveProgram);
    clearInterval(this.intervalBlackOut);
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (this.daypicker && !this.daypicker.current.contains(event.target)) {
      this.setState({
        openPicker: false,
      });
      if (!this.props.component.state.resetEpg) {
        this.resetEpg();
      }
    }
  };

  listBlackOut = epgs => {
    if (!this.isFirst) {
      return;
    }
    let blackList = [];
    epgs.map(epg => {
      if (epg.is_blackout === 1) {
        blackList.push(epg);
      }
    });
    this.blackList = blackList;
    this.isFirst = false;
  };

  changeEpgs = (date = '') => {
    const hasDate = date ? true : false;
    if (!hasDate) {
      date = this.state.startDate;
    }
    const { player } = this.props;
    this.rewatchSrc = '';
    const params = {
      channel_id: this.props.channelId,
      schedule_date: moment(date).format('YYYY-MM-DD'),
    };
    return new Promise((resolve, reject) => {
      epgApi
        .getEpgs(params)
        .then(res => {
          let response = res;
          const epgs = response.items;
          if (!hasDate) {
            this.setState(
              {
                epgs,
                loading: false,
              },
              () => {
                setTimeout(() => {
                  this.handleScrollTop();
                }, 1000);
              },
            );
          }
          resolve();
          this.listBlackOut(epgs);
        })
        .catch(err => {
          this.handleScrollTop();
          this.setState({
            loading: false,
          });
          reject(err);
        });
    });
  };

  handleChange(date) {
    this.setState(
      {
        startDate: date,
        showDate: moment(date).format('LLLL'),
        loading: true,
        openPicker: !this.state.openPicker,
      },
      () => {
        this.changeEpgs();
      },
    );
  }

  handleScrollTop = (epgActive = null, scrollTop = false) => {
    const { epgRef } = this.refs;
    if (!epgRef) {
      return;
    }
    if (scrollTop !== false) {
      epgRef.scrollTop = epgRef.getAttribute('data-scroll-top');
      return;
    }
    if (!epgActive) {
      epgActive = epgRef.querySelector('.vjs-epg--item.rewatching');
    }
    if (!epgActive) {
      epgActive = epgRef.querySelector('.vjs-epg--item.active');
    }

    if (!epgActive) {
      return;
    }
    epgRef.scrollTop = epgActive.offsetTop;
  };

  handleOnHover = e => {
    const epgWrapRef = this.refs.epgWrapRef;
    epgWrapRef.classList.add('hover');
  };
  handleOnBlur = e => {
    const epgWrapRef = this.refs.epgWrapRef;
    epgWrapRef.classList.remove('hover');
  };

  resetEpg = () => {
    this.setState(
      {
        showDate: this.orginShowDate,
        epgs: this.orginEpgs,
        startDate: this.orginDate,
      },
      () => {
        this.handleScrollTop();
      },
    );
  };

  handlePlayLink = (epgClicked = '', event = null) => {
    const currentEPGNext = epgClicked;
    // const CATCHUP_DOMAIN = "https://e5.endpoint.cdn.sctvonline.vn";
    // const linkPlayEPG = `${CATCHUP_DOMAIN}/3a5532f4-38fe-4544-86cf-fb9ac7168940/${currentEPGNext.start_at}_${currentEPGNext.end_at}.m3u8`;
    const { epgs, showDate, startDate } = this.state;
    const entityDetail = this.props.component.entityDetail;
    let linkPlay = epgClicked.link_play;
    if (!linkPlay) {
      return;
    }
    const $el = jquery(event.target);

    const currentEpgStartTime = $el.attr('data-start');
    const currentEpgEndTime = $el.attr('data-end');
    let activeProgram = false;
    const nowTime = moment();
    if (parseInt(currentEpgStartTime) > nowTime.unix()) {
      return;
    }
    if (parseInt(currentEpgEndTime) >= nowTime.unix()) {
      linkPlay = entityDetail.link_play;
      $el.addClass('active');
      activeProgram = true;
    } else {
      $el.removeClass('active').addClass('before');
    }
    const parentEPG = $el.parent();
    const player = this.props.player;
    const component = this.props.component;
    const $player = jquery(player.el());
    component.daySelected = epgClicked.play_date;
    let typeRewatchByTenant = [CURATED_LIVE];
    if (getConfigByKey('features.rewatchChannelFeature')) {
      typeRewatchByTenant.push(CHANNEL_TYPE);
    }
    if (!typeRewatchByTenant.includes(entityDetail.type)) {
      return;
    }
    if (!player) {
      return;
    }
    let typeVideo = 'application/x-mpegURL';
    this.orginDate = startDate;
    this.orginShowDate = showDate;
    this.orginEpgs = epgs;
    if (linkPlay.includes('.mp4')) {
      typeVideo = 'video/mp4';
    }
    // parentEPG.find('.active').addClass('active-rewatcing').removeClass('active')
    parentEPG.find('.rewatching').removeClass('rewatching');
    if (
      $player.hasClass('vjs-rewatching') &&
      $player.find(`[data-link='${this.rewatchSrc}']`) &&
      linkPlay !== this.originSrc
    ) {
      $player.find(`[data-link='${this.rewatchSrc}']`).removeClass('rewatching');
      this.rewatchSrc = '';
    }
    if ($el.hasClass('blackout')) {
      $player.addClass('vjs-black-out');
    } else {
      $player.removeClass('vjs-black-out');
    }
    if (!$el.hasClass('active')) {
      this.setState({
        rewatchingId: $el.attr('id'),
      });
      $el.addClass('rewatching');
      $player.addClass('vjs-rewatching');
      parentEPG.addClass('vjs-epg-rewatching');
      // player.src({
      //   src: linkPlay,
      //   type: typeVideo
      // })
    } else {
      // $el.addClass('active').removeClass('active-rewatcing')
      this.setState({
        rewatchingId: '',
      });
      $player.removeClass('vjs-rewatching');
      parentEPG.removeClass('vjs-epg-rewatching');
    }
    if (component.entityDetail.type === CURATED_LIVE) {
      _handleRewatchCuratedLive(component, epgClicked, false, epgs);
    } else {
      if (!linkPlay) {
        return;
      }
      player.src({
        src: linkPlay,
        type: typeVideo,
      });
      try {
        player.play();
        clearInterval(this.intervalActiveProgram);
        activeProgram && this.handleActiveProgram();
      } catch (error) {}
    }
    player.trigger('title-control-bar-change', {
      type: 'rewatch',
      data: epgClicked.title,
    });
  };

  handleRenderEpgs = (epgs = []) => {
    if (!Array.isArray(epgs)) {
      return epgs;
    }
    const nowTime = this.nowTime;
    const { player, component } = this.props;

    const { isRewatchCuratedLive } = component.state;
    const $player = jquery(player.el_);
    let idWatching = component.entityDetail.rewatchId && component.entityDetail.rewatchId;
    let hasActive = false;
    const renderEpgs = epgs.map((epg, index) => {
      const startAt = moment.unix(epg.start_at).format('hh:mm A'),
        endAt = moment.unix(epg.end_at).format('HH:mm');

      let classes = [];
      if (epg.link_play) {
        classes.push('can-rewatch');
      }
      if (epg.start_at <= nowTime.unix()) {
        classes.push('started');
      }
      if (epg.start_at <= nowTime.unix() && epg.end_at > nowTime.unix()) {
        classes.push('active');
        hasActive = true;
        player &&
          player.trigger('title-control-bar-change', {
            type: 'active',
            data: epg.title,
          });
      }
      if (epg.start_at > nowTime.unix()) {
        classes.push('after');
      }
      if (epg.end_at < nowTime.unix()) {
        classes.push('before');
      }
      if (epg.is_blackout === 1) {
        classes.push('blackout');
      }
      if (epg.id === idWatching && (isRewatchCuratedLive || $player.hasClass('vjs-rewatching'))) {
        classes.push('rewatching');
        $player.find('.vjs-epg--list').addClass('vjs-epg-rewatching');
      }

      return (
        <div
          className={`vjs-epg--item ${classes.join(' ')}`}
          key={`epg-${index}`}
          data-start={epg.start_at}
          data-end={epg.end_at}
          data-link={epg.link_play || ''}
          onClick={event => this.handlePlayLink(epg || '', event)}
          id={epg.id}
        >
          <div className="vjs-epg--wrap-icon">
            <span className="vjs-epg--item-live">
              <span>live</span>
            </span>
            <span className="vjs-epg--item-rewatch-icon">
              <img
                src={require('~img/icons/sctv/rewatch-icon.png').default}
                width={30}
                height={26}
              />
            </span>
          </div>

          <div className="vjs-epg--item-detail">
            <p className="vjs-epg--item-programme">{epg.title || ''}</p>
            <p className="vjs-epg--item-time">{`${startAt}`}</p>
          </div>
        </div>
      );
    });
    if (!hasActive) {
      player.trigger('title-control-bar-change', {
        type: 'active',
        data: '',
      });
    }
    return renderEpgs;
  };

  render() {
    const {
      startDate,
      showDate,
      epgs,
      nowTime,
      openPicker,
      durationDay,
      durationPreDay,
      rewatchingId,
      loading,
    } = this.state;
    const { player, component } = this.props;

    const renderEpgs = this.handleRenderEpgs(epgs || []);

    return (
      <EPGStyled
        id="vjs-epg"
        className="vjs-epg"
        onMouseEnter={this.handleOnHover}
        onMouseLeave={this.handleOnBlur}
        ref="epgWrapRef"
      >
        <Panel className="vjs-epg--wrap">
          <Panel.Heading className="vjs-epg--heading">
            {/* <div className='vjs-epg--note'>
              <span>{i18n.t('player.txtMind')}</span>
              <span dangerouslySetInnerHTML={{ __html: i18n.t('video.messagePopup.txtPolicy')}}></span>
            </div> */}
            <p className="vjs-epg--title">{i18n.t('broadcastSchedule')}</p>
            <div className="vjs-epg--pick-date" ref={this.daypicker}>
              <div
                className="vjs-epg--show"
                onClick={() => this.setState({ openPicker: !openPicker })}
              >
                <div className="vjs-epg--show-date">
                  <span>{showDate}</span>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>
                </div>
              </div>
              <PickerStyled className={`vjs-epg--date ${openPicker && 'vjs-epg--date--open'}`}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    disableToolbar
                    autoOk
                    orientation="landscape"
                    variant="static"
                    openTo="date"
                    clearable
                    value={startDate}
                    maxDate={this.nowTime.clone().add(durationDay, 'days').format('YYYY-MM-DD')}
                    minDate={this.nowTime
                      .clone()
                      .subtract(durationPreDay, 'days')
                      .format('YYYY-MM-DD')}
                    onChange={date => this.handleChange(date)}
                  />
                </MuiPickersUtilsProvider>
              </PickerStyled>
            </div>
            <hr></hr>
          </Panel.Heading>
          <Panel.Body className="vjs-epg--body">
            <div className="vjs-epg--list" ref="epgRef">
              {loading ? (
                <Loader type="Oval" color="#FFFFFF" height="30" width="30" />
              ) : renderEpgs.length > 0 ? (
                renderEpgs
              ) : (
                <div className="not-data">{i18n.t('noBroadcastSchedule')}</div>
              )}
            </div>
          </Panel.Body>
        </Panel>
      </EPGStyled>
    );
  }
}

const EPGStyled = styled.div`
  background-image: linear-gradient(0deg, #1a1a1a 0%, rgba(3, 3, 3, 0.95) 100%);
  opacity: 0.9;
  font-size: 1rem;
  width: 100%;
  left: 100%;
  height: 100%;
  padding-top: 1em;
  p {
    margin-bottom: 0.125em;
  }
  .vjs-epg {
    &--list {
      position: relative;
      flex-grow: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      transition: all 0.25s;
      scroll-behavior: smooth;
      .not-data {
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
      &.vjs-epg-rewatching {
        .vjs-epg--item {
          &.active {
            pointer-events: auto;
            &:before,
            .vjs-epg--item-live {
              opacity: 1;
              visibility: inherit;
              /* background-color: #2574d4; */
            }
            &:before {
              background-color: black;
            }
          }
        }
      }
      .active {
        pointer-events: none;
      }
    }
    &--wrap,
    &--heading,
    &--body {
      background-color: transparent;
      border: none;
      hr {
        width: 80%;
      }
    }
    &--wrap {
      display: flex;
      flex-direction: column;
      height: 100%;
      margin: 0;
    }
    &--body {
      flex-grow: 1;
      padding: 0;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      /* height: 0px; */
    }
    &--title {
      font-size: 1.825em;
      color: white;
      text-align: center;
      margin-bottom: 0.5em;
    }
    &--pick-date {
      position: relative;
      cursor: pointer;
    }
    &--show {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--show-date {
      width: fit-content;
      height: 100%;
      color: #999999;
      border-radius: 5px;
      text-align: center;
      font-size: 1.125em;
      max-width: 100%;
      border: 1px solid #c9c9c9;
      display: flex;
      align-items: center;
      padding: 1em;
      white-space: nowrap;
      span {
        margin-right: 1em;
      }
      i {
        transform: rotate(90deg);
      }
    }
    &--wrap-icon {
      position: relative;
      align-self: stretch;
      display: flex;
      align-items: center;
    }
    &--item {
      display: flex;
      align-items: center;
      padding: 0.5em 1.5em;
      width: 100%;
      flex-shrink: 0;
      position: relative;
      margin-bottom: 0.5em;

      &-rewatch-icon {
        position: absolute;
        top: 0;
        left: calc(50% - 0.5em);
        width: 30px;
        height: 26px;
        display: none;
        transform: translateX(-50%);
      }
      &.before.can-rewatch {
        .vjs-epg--item-rewatch-icon {
          display: inline;
        }
      }
      span,
      div,
      p {
        pointer-events: none;
      }
      &.before,
      &.active {
        cursor: pointer;
      }
      &.after,
      &.blackout {
        pointer-events: none;
      }
      &:first-of-type {
        padding-top: 0;
      }
      &:before {
        display: inline-block;
        opacity: 0;
        visibility: hidden;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 0.5em;
        background-color: transition;
        transition: all 0.25s;
      }
      &.active,
      &.rewatching {
        &:before,
        .vjs-epg--item-live {
          opacity: 1;
          visibility: visible;
          /* background-color: #2574d4; */
        }
        &:before {
          background-color: #2574d4;
        }
      }
      &.rewatching {
        .vjs-epg--item-live {
          visibility: hidden;
          opacity: 0;
        }
      }
      &.blackout {
        background-color: transparent;
        .vjs-epg--item-live {
          background-color: transparent;
          position: relative;
          opacity: 1;
          visibility: visible;
          span,
          &:before {
            visibility: hidden;
            opacity: 0;
          }
          &:after {
            position: absolute;
            content: '\f05e';
            background-color: transparent;
            font-family: 'FontAwesome';
            font-size: 2.5em;
            color: #c7c7c7;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      &.after
      /* , &.blackout  */ {
        .vjs-epg--item-programme,
        .vjs-epg--item-time {
          transition: all 0.25s;
          color: #5c5c5c;
        }
      }

      &-live {
        color: #ff0000;
        text-transform: uppercase;
        /* border-radius: 1.825em; */
        /* padding: 0.25em 0.7em; */
        /* background-color: rgba(255, 255, 255, 0.5); */
        display: flex;
        align-items: center;
        margin-right: 1em;
        font-weight: bold;
        opacity: 0;
        visibility: hidden;
        transition: all 0.25s;
        font-size: 22px;

        text-decoration: none solid rgb(255, 0, 0);
        text-shadow: 0px 1px 2px rgb(0 0 0 / 20%);
        background-color: #fff;
        padding: 4px 13px;

        border-radius: 30px;
        span {
          margin-top: 1px;
        }

        &:before {
          content: '';
          display: inline-block;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #ff0000;
          margin-right: 0.25em;
        }
      }
      &-detail {
        font-size: 1.5em;
      }
      &-programme {
        color: #fff;
        margin-bottom: 0.25em;
        line-height: 1.25em;
      }
      &-time {
        color: #a3a3a3;
      }
    }
  }
  @media (min-width: 992px) {
    /* width: 35%; */
    height: 100%;
    font-size: 0.8125rem;
  }
  @media (orientation: landscape) and (max-width: 1024px) {
    &.vjs-epg {
      font-size: 0.75rem;
    }
    .vjs-epg {
      &--date {
        + div {
          transform: scale(0.7);
          transform-origin: top center;
        }
      }
    }
  }
`;

const PickerStyled = styled.div`
  &.vjs-epg--date {
    padding: 0.125em 0;
    cursor: pointer;
    position: absolute;
    display: none;
    left: 50%;
    z-index: 999;
    transform: translateX(-50%);
    + div {
      right: 0;
      z-index: 9;
      &:focus {
        background-color: white;
      }
    }
    &--open {
      display: block;
    }
    .MuiPickersDay {
      &-current {
        color: #3f51b5;
        font-weight: 600;
      }
      &-daySelected {
        color: #fff;
        background-color: #3f51b5;
      }
      &-dayDisabled {
        color: rgba(0, 0, 0, 0.38);
      }
    }
  }
`;

export default EPG;
