export const BANNER = 0;
export const THUMBNAIL = 1;
export const POSTER = 2;
export const CLASS_0 = 'banner';
export const CLASS_1 = 'thumbnail';
export const CLASS_2 = 'poster';

export const MOVIE_TYPE = 1;
export const SHOW_TYPE = 2;
export const SEASON_TYPE = 3;
export const EPISODE_TYPE = 4;
export const CHANNEL_TYPE = 5;
export const CLIP = 6;
export const CURATED_LIVE = 7;

export const TYPE_MASTER_THUMBNAIL = 0;
export const TYPE_THUMBNAIL = 2;
export const TYPE_NEW_RELEASE = 4;
export const TYPE_RECENT_MOVIE = 5;
export const TYPE_TOP_CONTENT = 6;
export const TYPE_REPLICATED = 7;
export const TYPE_AUTOMATIC = 8;
