import videojs from 'video.js';
const Component = videojs.getComponent('Component');
const SpaceControlBar = videojs.extend(Component, {
  constructor: function (player, options) {
    Component.apply(this, arguments);
  },

  createEl: function () {
    const el = videojs.dom.createEl('div', {
      className: 'vjs-control vjs-space-control-bar',
    });

    return el;
  },
});

// Register the component with Video.js, so it can be used in players.
videojs.registerComponent('SpaceControlBar', SpaceControlBar);
