import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { LineClampStyled } from '../../../features/landingpage/styles/line-clamp';
import { withTranslation } from 'react-i18next';

class SecondaryBanner extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <StyledRow>
        <Col sm={12} md={6}>
          <div className="image-wrapper">
            <img
              loading="lazy"
              src="http://d2ryy79pkcqyaa.cloudfront.net/odv-stag/tenants/cd99c164-11b0-4ce2-ada4-4b8c6dd2d4ad/auto_image_youtube_040cbc10_9a339c0d.jpg?width=1920&version=6&s3_origin=https%3A%2F%2Fodv-stag.s3-ap-southeast-1.amazonaws.com"
            />
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="content-wrapper">
            <h3>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porimentum sed.
            </h3>
            <LineClampStyled className="line-clamp-12 desc">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.
              Aliquam erat volutpat. Donec placerat nisl magna, et faucibus arcu condimentum
              sed.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.
              Aliquam erat volutpat. Donec placerat nisl magna, et faucibus arcu condimentum sed.
              <br />
              <br />
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.
              Aliquam erat volutpat. Donec placerat nisl magna, et faucibus arcu condimentum
              sed.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.
              Aliquam erat volutpat. Donec placerat nisl magna, et faucibus arcu condimentum
              sed.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.
              Aliquam erat volutpat. Donec placerat nisl magna, et faucibus arcu condimentum sed.
            </LineClampStyled>
            <button data-testid={`1`} className="sec-banner--btn" style={{ marginRight: 10 }}>
              <div className="sec-banner--actionBtn btn-primary">
                <svg
                  viewBox="0 0 46 49"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M43.331,21.237L7.233,0.397c-0.917-0.529-2.044-0.529-2.96,0c-0.916,0.528-1.48,1.505-1.48,2.563v41.684 c0,1.058,0.564,2.035,1.48,2.563c0.458,0.268,0.969,0.397,1.48,0.397c0.511,0,1.022-0.133,1.48-0.397l36.098-20.84 c0.918-0.529,1.479-1.506,1.479-2.564S44.247,21.767,43.331,21.237z" />
                </svg>
              </div>
              <span>{t('ribbon.slide.watch')}</span>
            </button>
          </div>
        </Col>
      </StyledRow>
    );
  }
}

const StyledRow = styled(Row)`
  display: flex;
  align-items: center;
  margin: 0 auto 55px;
  max-width: 1590px;
  & .image-wrapper,
  & .image-wrapper img {
    border-radius: 10px;
  }
  & .image-wrapper {
    padding-top: calc(6 / 11 * 100%);
    position: relative;
    box-shadow: 0 4px 2px rgba(0, 0, 0, 0.2);
    & img {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  & .content-wrapper {
    padding-left: 40px;
    & h3 {
      font-size: 30px;
      font-weight: bold;
      color: #404040;
      text-decoration: none solid rgb(64, 64, 64);
      text-transform: uppercase;
    }
    & .desc {
      font-size: 14px;
    }
  }
  & .sec-banner--btn {
    float: right;
    background-image: linear-gradient(90deg, #ffffff 22%, #e3e1e1 100%);
    font-size: 1.2em;
    text-transform: uppercase;
    margin-top: 1.25rem;
    border-radius: 5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    box-shadow: 0 4px 2px rgba(0, 0, 0, 0.2);
    .sec-banner--actionBtn {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin: 3px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      &.btn-primary {
        background-color: ${({ theme }) => theme.primaryColor};
      }
      svg {
        width: 55%;
        height: auto;
        fill: white;
      }
    }
    span {
      color: #404040;
      margin: 0 10px;
    }
  }
`;

export default withTranslation()(SecondaryBanner);
