import '../../videoComponents/index';
import * as type from '../../constants';
import jquery from 'jquery';
import '../../videoComponents';

export const controlLive = (controlBar, component, data, player) => {
  const { entityDetail, renderLive } = data;
  const $player = jquery(player.el_);
  const { t } = component.props;
  component.isDisplayCooldown = player.addChild('CoolDown', {
    component: component,
    channelId: entityDetail.id,
    isRender: renderLive,
  });
  player.addChild('AutoNextSpiner', { component: component });
  player.addChild('BlackOut', {});
  if (entityDetail.type === type.CURATED_LIVE) {
    controlBar.addChild('NextLive', { component: component, entity: component.entityDetail }, 1);
    controlBar.addChild('PrevLive', { component: component, entity: component.entityDetail }, 0);
  }
  controlBar.addChild('EPG', { text: t('broadcastSchedule'), component: component }, 15);
  component.isDisplayEPG = player.addChild('EPGList', {
    component: component,
    channelId: entityDetail.id,
    isRender: renderLive,
  });
  if (entityDetail.type === type.CURATED_LIVE) {
    controlBar.addChild('NextLive', { component: component, entity: component.entityDetail }, 1);
    controlBar.addChild('PrevLive', { component: component, entity: component.entityDetail }, 0);
  }
  if (component.state.isLive) {
    // controlBar.addChild('playToggle', {}, 9)
    player.addChild('Logo', { component: component });
    controlBar.addChild('SpaceControlBar', {}, 6);
    controlBar.addChild(
      'listButtonLive',
      { component: component, entityDetail: entityDetail, status: 'live' },
      7,
    );
    controlBar.addChild('fullscreenToggle', { component: component }, 8);
  }
  if (component.state.isRewatchCuratedLive || !component.state.isLive) {
    controlBar.addChild('listButtonPlay', { component: component, entityDetail: entityDetail }, 5);
  }
  player.childrenAdded = true;
  window.addEventListener('orientationchange', async () => {
    if (!component.videoPlayer || $player.hasClass('vjs-curated-live')) {
      return;
    }
    if (window.screen.orientation && window.screen.orientation.angle === 90) {
      $player.parent().addClass('vjs-landscape');
    } else {
      $player.parent().removeClass('vjs-landscape');
    }
  });
};
