import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import pushAnalytics from '../../../../services/analytics/Analytics';
import './styles.scss';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
class HelpCvc extends Component {
  static propTypes = {
    modalShow: PropTypes.bool,
    showModal: PropTypes.func,
  };

  _modalShow() {
    const { showModal } = this.props;
    showModal();
    pushAnalytics('click', {
      content_type: 'button',
      item_name: 'Close',
      dialog: ` What's CVC? `,
    });
  }

  render() {
    const { modalShow, t } = this.props;

    return (
      <ModalStyled
        show={modalShow}
        onHide={() => this._modalShow()}
        className="v-stripe helpcvc"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header style={{ border: 'none' }}>
          <CloseIcon className="helpcvc__close" onClick={() => this._modalShow()} />
        </Modal.Header>
        <Modal.Body>
          <h3>{t('subcriptions.txtWhatsCVC')}</h3>
          <p>{t('subcriptions.txtCVCDescription')}</p>
          <img src={require('../../../../assets/img/cvc1.svg').default} alt="cvc1" />
          <img src={require('../../../../assets/img/cvc2.svg').default} alt="cvc2" />
        </Modal.Body>
      </ModalStyled>
    );
  }
}

HelpCvc.defaultProps = {
  showModal: () => {},
  modalShow: false,
};

const ModalStyled = styled(Modal)`
  &.helpcvc {
    font-size: 10px;
    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #495963;
      margin-bottom: 50px;
      h3 {
        margin-top: 0;
        font-size: 2em;
        margin-bottom: 34px;
        color: #283237;
        font-weight: bold;
      }
      p {
        text-align: center;
        color: #495963;
        font-size: 1.4em;
        margin-bottom: 1em;
      }
      img {
        width: 100%;
        max-width: 300px;
        height: auto;
        margin-bottom: 1em;
      }
    }
  }
  .helpcvc {
    &__close {
      position: absolute;
      top: 2%;
      right: 2%;
      cursor: pointer;
      color: #283237;
    }
  }
  @media (min-width: 768px) {
    &.helpcvc {
      .modal-dialog {
        width: 470px;
      }
      .modal-body {
        h3 {
          font-size: 2.4em;
        }
        p {
          padding: 0.5em 1em;

          font-size: 1.6em;
        }
      }
    }
  }
`;
const mapDispatchToProps = {};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HelpCvc);
