import { EPISODE_TYPE } from '~core/constants';
import React from 'react';
import styled from 'styled-components';

const BigTitleStyled = styled.div`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 124%;
  letter-spacing: 0.005em;
  color: #ffffff;
  max-width: 30%;
  padding-left: 20px;
  padding-right: 20px;

  ~ .vjs-ageRating-container {
    .vjs-ageRating-container--groupRating {
      padding-top: 0;
    }
  }
  @media (max-width: 767px) {
    font-size: 15px;
    max-width: 80%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;
function BigTitle(props) {
  const { component, player, entity = null } = props;
  const { entityDetail: content = entity } = component || {};

  const getTitle = content => {
    if (content.type !== EPISODE_TYPE) {
      return content.title;
    }
    return content.short_title || '';
  };

  if (!content) {
    return null;
  }
  return <BigTitleStyled className="big-title">{getTitle(content)}</BigTitleStyled>;
}

export default BigTitle;
