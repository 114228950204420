import * as actions from './actions';
import * as appAPIs from '../../api/appAPIs';
import * as apiDetail from '../../api/detailPage';
import { MOVIE_TYPE, EPISODE_TYPE } from './constants';
import * as epgApi from '../../api/epg';
import * as landdingPageApi from '../../api/landdingPageApi';
import * as configApi from '../../api/config';
import * as faqApi from '../../api/faq';

export {
  getMenu,
  getPage,
  getRibbon,
  getPageMenu,
  getDetail,
  getSeason,
  refreshPage,
  getRelate,
  getEpg,
  postProgress,
  getAdsVideo,
  updateDisclaimer,
  getEpglist,
  getDetails,
  getReportTemplates,
  uploadImage,
  setVolume,
  setCurrentLink,
  postFirstFiveSecPlay,
  getMuxEnvKey,
  getLiveInfo,
  getRecommend,
  getInfoDetail,
  getScrubberThumbnail,
  getRelatedRibbons,
  getGapContents,
  getIdFaqBlockAds,
  getProgramShow,
  getConfigCalenderEpg,
  getMoreLiveThis,
  updateStatusGetGapContens,
};

function getMenu() {
  return dispatch => {
    appAPIs
      .get_menu()
      .then(response => {
        dispatch(actions.getMenu(response));
      })
      .catch(err => {
        console.log('response', err);
      });
  };
}

function getPage(uuid, isRefresh = false) {
  return dispatch => {
    if (isRefresh) {
      dispatch(actions.refreshPage(true));
    }

    appAPIs
      .get_page()
      .then(response => {
        dispatch(actions.getPage(response));

        if (isRefresh) {
          setTimeout(() => {
            dispatch(actions.refreshPage(false));
          }, 1000);
        }
      })
      .catch(err => {
        console.log('response', err);
      });
  };
}

function getPageMenu(id, uuid) {
  return dispatch =>
    new Promise((resolve, reject) => {
      appAPIs
        .get_page_menu(id, uuid)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

function getRibbon(id, page) {
  return dispatch =>
    new Promise((resolve, reject) => {
      appAPIs
        .get_ribbon(id, page)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

function getDetail(slug) {
  return dispatch =>
    new Promise((resolve, reject) => {
      appAPIs
        .get_detail(slug)
        .then(response => {
          dispatch(actions.getDetail(response));
          if (response && (response.type === MOVIE_TYPE || response.type === EPISODE_TYPE)) {
            dispatch(actions.updateDetailPage(response));
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

function getDetails(slug, isDispatch = true, contentData = null) {
  return dispatch => {
    if (contentData) {
      dispatch(actions.getDetail(contentData));
      return Promise.resolve(contentData);
    }
    return new Promise(async (resolve, reject) => {
      const promises = [
        new Promise((resolve, reject) => {
          landdingPageApi
            .viewDetail(slug)
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        }),
        new Promise((resolve, reject) => {
          landdingPageApi
            .getDetail(slug)
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        }),
      ];
      try {
        const data = await Promise.all(promises);
        const result = {};
        data.map(value => {
          Object.assign(result, value);
        });

        if (isDispatch) {
          dispatch(actions.getDetail(result));
        }
        resolve(result);
      } catch (error_2) {
        reject(error_2);
      }
    });
  };
}

function getSeason(seasonId) {
  return dispatch =>
    new Promise((resolve, reject) => {
      appAPIs
        .get_season(seasonId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

function refreshPage(isRefreshPage) {
  return dispatch => {
    dispatch(actions.refreshPage(isRefreshPage));
  };
}

function getRelate(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      appAPIs
        .get_relate(id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

function getEpg(params) {
  return dispatch =>
    new Promise((resolve, reject) => {
      appAPIs
        .get_epg(params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

function postProgress(contentId, progress) {
  return dispatch =>
    new Promise((resolve, reject) => {
      appAPIs
        .post_progress(contentId, progress)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

function getAdsVideo(params = {}) {
  return dispatch =>
    new Promise((resolve, reject) => {
      appAPIs
        .getAdsVideo(params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

function updateDisclaimer(value) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch(actions.updateDisclaimer(value));
      resolve();
    });
}

function getEpglist(params = {}) {
  return dispatch =>
    new Promise((resolve, reject) => {
      const epgApiGet = (params = {}) => {
        epgApi
          .getEpgs(params)
          .then(response => {
            resolve(response);
            dispatch(actions.updateEpgList(response.items));
          })
          .catch(err => reject(err));
      };

      epgApiGet(params);
    });
}

function getReportTemplates() {
  return dispatch =>
    new Promise((resolve, reject) => {
      appAPIs
        .reportTemplates()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

function uploadImage(data) {
  return dispatch =>
    new Promise((resolve, reject) => {
      appAPIs
        .uploadImage(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

function setVolume(value) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch(actions.setVolume(value));
      resolve(true);
    });
}

function setCurrentLink(value) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch(actions.setCurrentLink(value));
      resolve(true);
    });
}

function postFirstFiveSecPlay(contentId) {
  return dispatch =>
    new Promise((resolve, reject) => {
      apiDetail
        .postFirstFiveSecPlay(contentId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

function getMuxEnvKey() {
  return dispatch =>
    new Promise((resolve, reject) => {
      configApi
        .getMuxEnvKey()
        .then(response => {
          resolve(response);
        })
        .catch(errors => {
          reject(errors);
        });
    });
}

function getLiveInfo(idChannel) {
  return dispatch =>
    new Promise((resolve, reject) => {
      apiDetail
        .getLiveInfo(idChannel)
        .then(response => {
          dispatch(actions.updateCuratedLive(response));
          resolve(response);
        })
        .catch(errors => {
          reject(errors);
        });
    });
}

function getRecommend(contentId) {
  return dispatch =>
    new Promise((resolve, reject) => {
      apiDetail
        .getRecommend(contentId)
        .then(response => {
          resolve(response);
        })
        .catch(errors => {
          reject(errors);
        });
    });
}

function getInfoDetail(slug) {
  return dispatch =>
    new Promise((resolve, reject) => {
      landdingPageApi
        .getDetail(slug)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

function getScrubberThumbnail(contentId) {
  return dispatch =>
    new Promise((resolve, reject) => {
      apiDetail
        .getScrubberThumbnail(contentId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

function getRelatedRibbons(contentId) {
  return dispatch =>
    new Promise((resolve, reject) => {
      apiDetail
        .getRelatedRibbons(contentId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

function getGapContents(idChannel, param) {
  return dispatch =>
    new Promise((resolve, reject) => {
      apiDetail
        .getGapContents(idChannel, param)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

function getIdFaqBlockAds() {
  return dispatch =>
    new Promise((resolve, reject) => {
      faqApi
        .getIdFaqBlockAds()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

function getProgramShow(idContent) {
  return dispatch =>
    new Promise((resolve, reject) => {
      apiDetail
        .getProgramShow(idContent)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

function getConfigCalenderEpg() {
  return dispatch =>
    new Promise((resolve, reject) => {
      apiDetail
        .getConfigCalenderEpg()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

function getMoreLiveThis(idContent, params) {
  return dispatch =>
    new Promise((resolve, reject) => {
      apiDetail
        .getMoreLiveThis(idContent, params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}

function updateStatusGetGapContens(payload) {
  return dispatch => {
    dispatch(actions.getGapContents(payload));
  };
}
