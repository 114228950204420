import React, { Component } from 'react';
import Loader from 'react-loader-spinner';

class Loadding extends Component {
  _renderLoading() {
    const { isLoading } = this.props;

    if (isLoading) {
      return (
        <div style={styles.overlayLoading}>
          <Loader type="Oval" color="#FFFFFF" height="30" width="30" />
        </div>
      );
    }

    return null;
  }
  render() {
    return <React.Fragment>{this._renderLoading()}</React.Fragment>;
  }
}
const styles = {
  overlayLoading: {
    width: '100%',
    height: 'calc(100% + 70px)',
    position: 'absolute',
    backgroundColor: '#000000',
    zIndex: 999,
    opacity: 0.7,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    top: '-70px',
    left: 0,
  },
};

export default Loadding;
