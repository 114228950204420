import { Box, Grid } from '@material-ui/core';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const TitleUpCommingStyled = styled(Grid)`
  display: flex;
  font-size: 26px;
  justify-content: flex-start;
  padding-left: 16px;
  flex-wrap: nowrap;
  flex-direction: column;
  /* line-height: normal; */
  margin-top: 16px;
  padding-bottom: 5px;
  margin-bottom: 8px;
  .Icons {
    width: 30px;
  }
  @media (min-width: 375px) {
    /* font-size: 43px; */
    border-bottom: 0.5px solid #000;
  }
  @media (min-width: 576px) {
  }

  /* @media (width: 768px) {
    font-size: 43px;
    justify-content: unset;
    .center {
      padding-left: 11px;
    }
    .start {
      padding-left: 10px;
    }
    .end {
      margin-left: auto;
      margin-right: 16px;
    }
  } */

  @media (min-width: 768px) {
    font-size: 30px;
    /* justify-content: space-between; */
    flex-direction: row;
    .Icons {
      width: 70%;
    }
    .center {
      /* padding-left: 45px; */
    }
    .start {
      width: calc(315px - 16px);
      /* padding-left: 11px; */
    }
  }

  @media (min-width: 1024px) {
    font-size: 106px;
    justify-content: space-between;
    .Icons {
      width: 70%;
    }
    .center {
      padding-left: 20px;
    }
  }

  @media (min-width: 1440px) {
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 80px;
    line-height: 88%;
    border-bottom: unset;
    .Icons {
      width: 100%;
    }
    &.handleZoom {
      margin-top: 200px;
    }
    /* identical to box height, or 54px */
    letter-spacing: -0.03em;
    /* text-transform: uppercase; */
    color: #000000;
    padding: 0 0px 0 16px;
    margin: 70px 0 31px 0px;
    .start {
      width: calc(315px - 16px);
    }
    .center {
      justify-self: flex-start;
      flex-grow: 1;
      padding-left: unset;
    }
    .end {
      padding-right: 83px;
      flex-shrink: 0;
    }
  }

  @media (min-width: 1280px) {
  }

  /* @media (min-width: 1024px) and (max-width: 1179px) {
    font-size: 120px;
  } */
`;

export const Root = styled(Col)`
  display: flex;
  text-decoration: none;
  padding: 0 !important;
  border-top: 0.5px solid #000;
  //padding-top: 5px;
  .image-hidden {
    display: none;
  }
  .btnPagingDisable {
    background-color: red !important;
    path {
      stroke: red;
    }
  }
  .wrap-image {
    /* width: 315px; */
    /* height: 187px; */
    .image {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
  .list {
    position: relative;
    &--draft,
    &--show {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &--draft {
      position: static;
      display: none;
      z-index: -1;
      visibility: hidden;
      opacity: 0;
    }
    &--show {
      position: static;
      &.paging {
        min-height: 763px;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-right: 16px;
    .see-more {
      margin-top: 15px;
    }
  }
`;

export const WatchMoreStyled = styled(Box)`
  font-family: 'Neue Machina';
  font-style: normal;
  font-weight: 500;
  line-height: 88%;
  min-height: 140px;
  display: flex;
  font-size: 35px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  /* identical to box height, or 52px */
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  padding: 36px 0;
  color: #000;
  .MuiSvgIcon-root {
    font-size: 35px;
    margin-top: -3%;
  }
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
  @media (min-width: 320px) {
    font-size: 43px;
    padding: 36px 0;
    .MuiSvgIcon-root {
      font-size: 23px;
    }
  }
  @media (min-width: 768px) {
    font-size: 50px;
    min-height: 100px;
    .MuiSvgIcon-root {
      font-size: 55px;
    }
  }
  @media (min-width: 992px) {
    /* font-size: 135px; */
    font-size: 69px;
    .MuiSvgIcon-root {
      font-size: 77px;
    }
    /* min-height: 300px; */
    /* padding: 126px 0; */
  }
`;
export const MainTextComponent = styled.div`
  margin-top: 83px;
  max-width: 100%;
  width: 100%;
  /* overflow-x: hidden; */
  /* overflow-y: visible; */
  /* overflow: hidden; */
  position: relative;
  z-index: 1;
  @media (max-width: 768px) {
    margin-top: 21px;
  }
  @media (max-width: 1024px) {
    margin-top: 15px;
  }
  @media (max-width: 1440px) {
    margin-top: 27px;
  }
  .MainComponent--Video {
    z-index: 1;
  }
  .wrap-video {
    position: relative;
    width: 100%;
    &::before {
      content: '';
      display: inline-block;
      padding-top: calc(569 / 1012 * 100%);
    }
    &--zoom {
      position: absolute;
      width: var(--zoomMainVideo, 100%);
      transition: transform 0.25s ease;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      &::before {
        content: '';
        display: inline-block;
        padding-top: calc(569 / 1012 * 100%);
      }
    }
    .wrap-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .record__image {
      width: 100%;
      height: 100%;
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
      }
    }
  }

  &.isMobile {
    /* margin-top: 245px; */
    min-height: auto;
  }
  & .MainComponent {
    &--Title {
      position: relative;
      display: flex;
      width: 100%;
      justify-content: center;
      /* padding: 0 6px; */
      margin-bottom: 57px;
      transition: transform 0.25s;
      @media (max-width: 1440px) {
        margin-bottom: 27px;
      }
      @media (max-width: 1024px) {
        margin-bottom: 15px;
      }
      @media (max-width: 768px) {
        margin-bottom: 0;
      }
      /* &-Text {
        font-family: 'Neue Haas Grotesk Display Pro';
        font-style: normal;
        font-weight: 300;
        font-size: 5.5rem;
        line-height: 105%;
        text-align: center;
        letter-spacing: -0.005em;
        span {
          font-weight: 500;
          font-style: normal;
        }
      } */
      svg {
        width: 100%;
        height: auto;
      }
      &-Text {
        width: 100%;
        &-Img {
          width: 100%;
          height: auto;
          text-align: center;
          font-family: 'Neue Machina';
          font-size: min(4vw, 65px);
          letter-spacing: -0.065em;
          line-height: 133%;
          color: #000;
          font-weight: 500;
          @media (max-width: 1439px) {
            font-size: min(4vw, 50px);
          }
          @media (max-width: 1023px) {
            font-size: min(4vw, 35px);
          }
          @media (max-width: 768px) {
            font-size: min(7vw, 58px);
          }
          @media (max-width: 375px) {
            font-size: min(7vw, 30px);
          }
          .parent {
            position: relative;
            overflow: hidden;
            &:before {
              position: absolute;
              content: '';
              top: 100%;
              left: -25%;
              background-color: #fff;
              width: 132%;
              height: 305%;
              transform-origin: left top;
              z-index: 2;
              animation: mymove 1s ease 0s;
              &.eleSecond {
                animation: mymove 0.8s ease 0.2s;
              }
              &.eleLast {
                animation: mymove 0.6s ease 0.4s;
              }
            }
            @keyframes mymove {
              from {
                transform: rotate(-15deg);
              }
              to {
                transform: rotate(0deg);
              }
            }
            .parentContainer {
              position: relative;
              z-index: 1;
              animation: moveText 1s ease 0s;
              &.eleSecond {
                animation: moveText 0.8s ease 0.2s;
              }
              &.eleLast {
                animation: moveText 0.6s ease 0.4s;
              }
            }
            @keyframes moveText {
              from {
                top: 100px;
              }
              to {
                top: 0px;
              }
            }
          }
          .cenila-trial {
            font-family: 'Canela-Trial';
            &.thin {
              font-weight: 100;
              font-style: italic;
              letter-spacing: -0.055em;
            }
            &.regular {
              font-weight: 400;
              font-style: italic;
            }
            &.light {
              font-weight: 300;
              font-style: italic;
            }
          }
          .helvetica-neue {
            font-family: 'HelveticaNeue';
            font-weight: 400;
          }
        }
      }
    }
    &--Video {
      display: flex;
      justify-content: center;
      position: relative;
      /* padding: 30px 0px 0px; */
      width: 70%;
      /* height: 600px; */
      transition: transform 1s ease;
      transform: scale(1);
      margin: 0 auto;
      &.ZoomIn {
        transition: transform 1s ease;
        /* transform: scale(1.5); */
        .wrap-video {
          &--zoom {
            /* width: 100vw; */
          }
        }
      }
      &-Container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        &-btnPlay {
          display: inline-flex;
          border-radius: 4px;
          font-family: 'Neue Haas Grotesk Display Pro';
          /* max-width: 200px; */
          /* width: 170px;
          height: 66px; */
          font-weight: bold;

          /* padding: 2px 16px; */
          text-transform: uppercase;
          font-size: 3.8rem;
          /* background-color: #fff; */
          img {
            width: 100%;
            border-radius: 4px;
          }
          line-height: normal;
          color: #000;
          transition: transform 0.5s;
          box-sizing: content-box;
          width: 192px;
          .MuiButton-label {
            position: relative;
            top: 3px;
            display: inline;
          }
          &:hover {
            transform: scale(1.2);
            /* background-color: #fff; */
          }
        }
      }
      &.MainVideoMobile {
        max-width: 100%;
        max-height: 500px;
      }
    }
  }
  @media (max-width: 767px) {
    & .MainComponent {
      &--Video {
        width: 100%;
        &-Container-btnPlay {
          width: 131px;
        }
      }
    }
  }
  @media (min-width: 768px) {
    & .MainComponent {
      &--Title {
        /* padding: 0 67px; */
        /* margin: 0 -5px; */
        &-Text {
          width: var(--zoomMainVideo, 70%);
          transition: width 0.05s;
          transition-timing-function: linear;
          .Mobile {
            margin-bottom: 3.5rem;
            /* transform: scale(1.5); */
          }
        }
      }
    }
  }
`;

export const ListUpMobile = styled.div`
  p {
    margin: 0;
  }
  font-family: var(--font);
  @media (min-width: 768px) {
    font-size: 20px;
    img {
      min-height: 200px !important;
    }
  }
  .isMobile {
    &-Link {
      text-decoration: none;
      &--ComponentItem {
        padding: 8px;
        display: flex;
        justify-content: space-around;
        border-bottom: 0.5px solid #000;
        &--wrapImage {
          position: relative;
          &:before {
            content: '';
            display: inline-block;
            padding-top: calc(5 / 9 * 100%);
          }
          max-width: 100%;
          width: 50%;
          position: relative;
          img {
            position: absolute;
            width: 100%;
            height: 100%;
            min-height: 95px;
            object-fit: cover;
            object-position: center;
            display: block;
            top: 0;
            left: 0;
          }
          .category {
            position: absolute;
            background: #fff;
            color: #000;
            font-weight: 400;
            padding: 3px;
            max-width: 100px;
            border: none;
            span {
              font-size: 12px;
            }
          }
        }
        &--Content {
          max-width: 100%;
          width: 50%;
          position: relative;
          display: flex;
          flex-direction: column;
          padding-left: 8px;
          .Title,
          .TimeAndType,
          .category {
            text-decoration: none !important;
            color: #000;
            font-weight: 400;
            &:not(:first-child) {
              margin-right: 10px;
            }
          }
          .SubContent {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            margin-top: auto;
            width: 100%;
            .Around {
              margin: 0 6px;
              width: 5px;
              height: 5px;
              border-radius: 100%;
              background-color: #000;
              position: relative;
              bottom: 4px;
            }
          }
          .Title {
            display: -webkit-box;
            max-height: 3.2rem;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            -webkit-line-clamp: 2;
            line-height: 1.6rem;
          }
        }
      }
    }
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    .MuiButtonBase-root {
      width: auto;
      height: auto;
    }
    .see-more .MuiBox-root {
      font-size: 20px;
      min-height: unset;
      padding: 0 0 0 0;
    }
    svg {
      width: 32px;
    }
  }
`;
export const TitleRecommendStyled = styled(Grid)`
  display: flex;
  justify-content: space-between;
  font-family: 'Neue Machina';
  font-style: normal;
  font-weight: 500;
  padding: 0 0px 0 16px;
  font-size: 26px;
  flex-wrap: nowrap;
  flex-direction: column;
  /* line-height: normal; */
  .tail {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  .start {
    width: 100%;
  }
  .center {
    display: block;
    flex-grow: 1;
  }
  .end {
    display: flex;
    align-items: center;
    padding-right: 20px;
    flex-shrink: 0;
    .Icons {
      width: 35px;
      /* vertical-align: text-bottom; */
      transform: translateY(-3px);
    }
  }
  @media (min-width: 768px) {
    font-size: 30px;
    padding-bottom: 16px;
    padding-top: 40px;
    flex-direction: row;
    .end {
      margin-left: auto;
      padding-right: unset;
      .Icons {
        width: 70%;
      }
    }
    .start {
      width: calc(50% - 8px);
      /* padding-left: 11px; */
    }
  }
  @media (min-width: 1024px) {
    font-size: 54px;
    .end {
      margin-left: auto;
      padding-right: unset;
      .Icons {
        width: 70%;
      }
    }
    .start {
      width: calc(315px - 16px);
    }
  }
  @media (min-width: 1440px) {
    font-size: 80px;
    justify-content: unset;
    .end {
      margin-left: auto;
      /* margin-right: 83px; */
      padding-right: 83px;
      .Icons {
        width: 100%;
      }
    }
    .start {
      /* width: calc(315px - 16px); */
    }
  }

  /* @media (min-width: 1024px) and (max-width: 1179px) {
    font-size: 50px;
    .start {
      width: calc(373px - 16px);
    }
    .center {
      padding: 20px 0 0;
    }
    .end {
      .Icons {
        transform: scale(0.8);
      }
    }
  } */
  /* @media (min-width: 1180px) and (max-width: 1440px) {
    font-size: 50px;
    .start {
      width: calc(300px - 16px);
    }
    .center {
      padding: 0;
    }
    .end {
      .Icons {
        transform: scale(0.8);
      }
    }
  } */
`;
