import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BoxStyled, RowStyled } from './style';
import classnames from 'classnames';
class TextField extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {
      showPass: false,
    };
  }
  _showPassword() {
    this.setState({
      showPass: !this.state.showPass,
    });
  }

  render() {
    const {
      required,
      value,
      label,
      className,
      style,
      type,
      onChange,
      onKeyDown,
      showPassWord,
      variant,
      autocomplete,
      max,
      min,
      name,
    } = this.props;
    const { showPass } = this.state;
    if (variant === 'dob')
      return (
        <RowStyled className={classnames('input__form', className)}>
          <label className="input__form--label">
            {label}
            {/* <span style={{ display: required && 'none' }}>*</span> */}
          </label>
          {this.props.children}
        </RowStyled>
      );
    if (variant === 'standard')
      return (
        <RowStyled className={classnames('input__form', className)}>
          <label className="input__form--label">
            {label}
            {/* <span style={{ display: required && 'none' }}>*</span> */}
          </label>
          <input
            ref={this.props.inputRef || null}
            type={showPass ? 'text' : type}
            className="input__form--input"
            placeholder={name}
            onChange={onChange ? event => onChange(event) : null}
            value={value}
            autoComplete={autocomplete || null}
            onKeyDown={onKeyDown ? (event, key = null) => onKeyDown(event, key) : null}
          />
          {type === 'password' ? (
            <div className="input__form--showPass" onClick={() => this._showPassword()}>
              <i
                className={classnames(showPass ? 'fa fa-eye' : 'fa fa-eye-slash')}
                aria-hidden="true"
              />
            </div>
          ) : null}
        </RowStyled>
      );
    return (
      <BoxStyled>
        <div className={`v-group ${className || ''}`} style={style}>
          <input
            ref={this.props.inputRef || null}
            className={`${this.props.className} v-input ${variant || ''}`}
            type={showPass ? 'text' : type}
            required
            value={value}
            max={max}
            min={min}
            name={name}
            autoComplete={autocomplete || null}
            onChange={onChange ? event => onChange(event) : null}
            onKeyDown={onKeyDown ? (event, key = null) => onKeyDown(event, key) : null}
          />
          <span className="v-highlight"></span>
          <span className={`${variant || 'v-bar'}`}></span>
          <span style={{ top: '35%', position: 'absolute', right: 15 }}>
            <i
              className={!showPass ? 'fa fa-eye-slash' : 'fa fa-eye '}
              style={{
                display: showPassWord ? 'block' : 'none',
                cursor: 'pointer',
                color: '#94a3ad',
              }}
              onClick={() => this._showPassword()}
            ></i>
          </span>
          <label className={`v-label ${variant || ``} `}>{label}</label>
          {this.props.children}
        </div>
      </BoxStyled>
    );
  }
}

TextField.defaultProps = {
  required: false,
  value: null,
  label: null,
  classes: null,
  style: {},
  type: 'text',
  onChange: null,
  onKeyDown: null,
  showPassWord: false,
};

export default TextField;
