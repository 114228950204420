import styled from 'styled-components';

const DivStyled = styled.div`
  &.v-ribbon {
    &--vertical {
      margin-top: ${props => `${props.props.isSvod ? `0` : `2.3rem`}`};
    }
  }
  .v-ribbon {
    &--vertical {
      &--loading {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    }
    &--ads {
      width: 336px;
      height: ${props => `${props.props.isSvod ? `1rem` : `280px`}`};
    }
    &--wrap {
      &-content {
        margin-bottom: 1rem;
        margin-top: ${props => `${props.props.isSvod ? `0` : ` 0.55rem`}`};
      }
    }

    &--top-content {
      height: 99%;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
      &-title {
        font-size: 1rem;
        text-transform: capitalize;
        color: ${({ theme }) => theme.ribbon.titleColor};
        margin-bottom: ${props => `${props.props.isSvod ? `0.55rem` : ` 0px`}`};
      }
      &-view-more {
        color: #9b9b9b;
        font-size: 1rem;
        cursor: pointer;
      }
    }
  }
`;

export default DivStyled;
