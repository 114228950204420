import '../../videoComponents/escondido/registerComponent/groupButtonPlay';
import '../../videoComponents/spaceControlBar';
import '../../videoComponents/escondido/registerComponent/groupButtonLive';

export const controlMovie = (controlBar, component, entityDetail) => {
  controlBar.addChild(
    'listButtonPlay',
    { component: component, entityDetail: entityDetail, type: 'movie' },
    5,
  );
  controlBar.addChild('SpaceControlBar', {}, 6);
  controlBar.addChild(
    'listButtonLive',
    { component: component, entityDetail: entityDetail, status: 'offlive' },
    7,
  );
  controlBar.addChild('fullscreenToggle', { component: component }, 8);
  return;
};
