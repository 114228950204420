import ReactDOM from 'react-dom';
import React from 'react';
import videojs from 'video.js';
import TopLeftContainer from './TopLeftContainer';
import jquery from 'jquery';
const vjsComponent = videojs.getComponent('Component');

class TopLeftContainerVjs extends vjsComponent {
  constructor(player, options) {
    super(player, options);
    this.mount();
    const $player = jquery(this.el());

    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }
  // TODO : Create class element for component render
  createEl() {
    const topLeft = videojs.createEl('div', {
      className: 'vjs-top-left-container',
    });
    return topLeft;
  }

  //TODO : Render your component
  mount() {
    const { component, entity } = this.options_;
    ReactDOM.render(
      <TopLeftContainer player={this.player_} component={component} entity={entity} />,
      this.el(),
    );
  }
}

/**
 * Make sure to register the vjsComponent so Video JS knows it exists
 */
// TODO :  Add component into player
videojs.registerComponent('TopLeftContainerVjs', TopLeftContainerVjs);

export default TopLeftContainerVjs;
