import React, { useEffect, useState, Fragment, useCallback, useMemo } from 'react';
import { Modal, Col, Row, Button } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import SignInForm from '~features/auth/components/projectw/SignInForm';
import SignUpForm from '~features/auth/components/projectw/SignUpForm';
import Paypal from '~features/payperview/components/paypal';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { withTranslation, useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { postConfirmPaypalPayperview, getTimesOfPackage } from '~features/payperview/services';
import _ from 'lodash';

import { loading } from '~features/loadingPage/services';
import VerifyEmailForm from '~features/auth/components/projectw/VerifyEmailForm';
import { LazyImage } from '~components';
import moment from 'moment';
import { getAccountInfo } from '~features/profile/services';
import PaymentBig3 from './PaymentBig3';
import ModalPaymentUnSuccess from './ModalPaymentUnSuccess';
import { ModalW, ContainerFragment } from './style';
import { isLoggedVerifiedEmail } from '~core/method/authMethod';
import { wFirebaseEvents } from '~services/analytics';
import {
  getPriceById,
  getThumbnail9_5,
  convertCurrency_String,
  isPPV,
  isNewPPV__NoCache,
  isLiveEvent,
  getDescriptionPricePackage,
} from '~core/method/contentMethod';
import { LIVE_EVENT, SIMULATED_LIVE_EVENT } from '~core/constants';
import ModalPaymentSuccess from './ModalPaymentSuccess';
import {
  setIsLoadingConfirmPPV,
  setIsSuccess,
  setOpenModalPPV,
} from '~components/global/globalSlice';
import Bootpay from '../Bootpay';
import PaymentMethod from './PaymentMethod';
import PaymentFreeTicket from './PaymentFreeTicket';

const PPV_BOOTPAY = 'bootpay';
const PPV_PAYPAL = 'paypal';
const PPV_PAYMENT_GATEWAY_PAYPAL = 'paypal';
const STRING_REPLACE_SPECIAL = /[`~!@#$%^&*()_|+\-=?;'"<>\{\}\[\]\\\/]/gi;
const CONTENT_LIVE_AVAILABLE = [LIVE_EVENT, SIMULATED_LIVE_EVENT];
const PRICE_CREDIT = 'tvod_credit';
const PRICE_NORMAL = 'normal';

const getPriceByType = (type, listTvodCredit) => {
  const lstPrice = listTvodCredit.find(price => price.type === type);
  return lstPrice;
};

function ModalPPV(props) {
  // TODO : Init State
  const { content = {}, token, open: openPPV, userProfile } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const account = useSelector(state => state.auth.account);

  const pricesTickContentView = useSelector(
    state => !_.isEmpty(state.global.pricesView) && state.global.pricesView,
  );

  const listTvodCredit = pricesTickContentView;
  const priceEarly = getPriceByType(PRICE_NORMAL, pricesTickContentView || []);
  const priceBig3 = getPriceByType(PRICE_CREDIT, pricesTickContentView || []);

  const openModalPPV = useSelector(state => state.global.openModalPPV);
  const priceCurrencyPackageBig3 = convertCurrency_String.call(priceBig3);
  const isShowVerifyEmailPPV = useSelector(state => state.global.isShowVerifyEmailPPV);
  const { t } = useTranslation();
  const [totalContent, setTotalContent] = useState('');
  const [paymentCountry, setPaymentCountry] = useState(0);

  const [active, setActive] = useState(false);

  const [confirmPayment, setConfirmPayment] = useState(false);
  const [confirmPaymentPayPal, setConfirmPaymentPayPal] = useState(false);

  const [hasBig3, setHasBig3] = useState(0);
  const [paymentProvider, setPaymentProvider] = useState('');

  const [paymentError, setPaymentError] = useState(false);
  const [priceFocus, setPriceFocus] = useState({});
  const [openPayments, setOpenPayments] = useState(false);
  const [bootpayMethod, setBootpayMethod] = useState('');
  const [freeTicket, setFreeTicket] = React.useState({
    numberTicket: 0,
    type: 'coupon',
  });
  const isOpenSignIn = useSelector(state => state.global.formSignIn.isOpenSignInForm);
  const isshowTrailerLiveEvent = useSelector(state => state.global.showTrailer.isShowTrailer);
  const isPaymentSuccess = useSelector(state => state.global.modalPaymentSuccess.isSuccess);
  const auth = useSelector(state => state.auth);

  const isBuyNowUI = !content?.applicable_for_bign_ticket;

  const desPackageBig3_TotalContent = [
    {
      id: 0,
      des: `${t('projectw__PPV.Description.package_credit.ss6', {
        totalContent,
      })}`,
    },
    {
      id: 1,
      des: `${t('projectw__PPV.Description.package_credit.ss1', {
        totalContent,
        priceCurrencyPackageBig3,
      })}`,
    },
    {
      id: 2,
      des: `${t('projectw__PPV.Description.package_credit.ss5', {
        totalContent,
      })}`,
    },
  ];
  // TODO : Function
  const checkBuyNewUI = (priceType, numberTicket = 0, remainingPurchases = 0) => {
    if (isBuyNowUI) {
      return isBuyNowUI;
    }
    if (priceType === PRICE_NORMAL && numberTicket > 0) {
      return false;
    }
    if (priceType !== PRICE_NORMAL && remainingPurchases > 0) {
      return false;
    }
    return true;
  };
  const confirmPaypal = paramQuery => {
    const { content_id, order_id, payment_provider, price_id, tvod_type } = paramQuery;
    const paidPrice = getPriceById.call(content, price_id);
    const payloadFirebase = {
      content_id,
      email: userProfile.email,
      purchase_amount: paidPrice.price,
      currency_unit: paidPrice.currency,
      payment_method: 'paypal',
      purchase_item_name: paidPrice.name,
    };
    wFirebaseEvents.ppvPaymentProcessingEvent(payloadFirebase);
    dispatch(setIsLoadingConfirmPPV(true));
    dispatch(postConfirmPaypalPayperview(paramQuery))
      .then(() => {
        openModalSuccess();
        wFirebaseEvents.ppvPaymentCompletedEvent(payloadFirebase);
        if (paidPrice.type === 'tvod_credit') {
          wFirebaseEvents.ppvIlluonCreditPaymentCompletedEvent({
            content_id: payloadFirebase.content_id,
            email: payloadFirebase.email,
          });
        }
      })
      .catch(() => {
        setPaymentError(true);
      })
      .finally(() => {
        dispatch(setIsLoadingConfirmPPV(false));
      });
  };

  const openModalSuccess = () => {
    setPaymentError(false);
    dispatch(setIsSuccess(true));
  };

  const getRemaTime = callbackFn => {
    return dispatch(getTimesOfPackage()).then(callbackFn);
  };

  const checkRenderPopup = () => {
    if (!account) {
      if (!isOpenSignIn) {
        return <SignInForm openShowPPV {...props} />;
      }
      return <SignUpForm openShowPPV {...props} />;
    }
    if (account && !verifiedEmail) {
      return <VerifyEmailForm openShowPPV {...props} />;
    }
  };

  const handleClose = () => {
    dispatch(setOpenModalPPV(false));
  };

  const handleOnClickFocus = item => {
    setPriceFocus(item);
    const payloadFireBase = {
      content_id: content.id,
      email: userProfile.email,
      purchase_amount: item.price,
      currency_unit: item.currency,
      purchase_item_name: item.name,
    };
    wFirebaseEvents.ppvPackageSelectionChanged(payloadFireBase);
  };

  const renderDescription = () => {
    if (_.isEmpty(getDescriptionPricePackage.call(priceFocus))) {
      return;
    }
    if (![LIVE_EVENT, SIMULATED_LIVE_EVENT].includes(content.type)) {
      return (
        <ul>
          {descriptionHasBig3()}
          {descriptionTVOD()}
        </ul>
      );
    }
    return <ul>{descriptionLiveEvent()}</ul>;
  };

  const descriptionHasBig3 = () => {
    if (!account || priceFocus?.type === PRICE_NORMAL || isBuyNewUIForPriceFocusing) {
      return null;
    }
    return (
      <li style={{ color: '#EF4040' }}>
        {desPackageBig3_TotalContent.map(res => res.id === 0 && res.des)}
      </li>
    );
  };

  const descriptionLiveEvent = () =>
    getDescriptionPricePackage.call(priceFocus).map((des, index) => (
      <li key={index}>
        {/* {des.replace('{{unpublished_time}}', unPublish_Time)} */}
        {des.replace(STRING_REPLACE_SPECIAL, '')}
      </li>
    ));

  const descriptionTVOD = () => {
    const replaceDesciprtion = {
      '{{price}}': convertCurrency_String.call(priceFocus),
      '{{quantity}}': priceFocus.quantity,
    };
    const packageTvodAvailable =
      priceFocus?.type === PRICE_CREDIT && account && !isBuyNewUIForPriceFocusing;
    if (packageTvodAvailable) {
      return getDescriptionPricePackage.call(priceFocus).map(
        (des, index) =>
          index !== 0 && (
            <li key={`decriptionTVOD${index}`}>
              {/* {des.replace(/({{price}}|{{quantity}})/gi, matched => replaceDesciprtion[matched])} */}
              {des.replace(STRING_REPLACE_SPECIAL, '')}
            </li>
          ),
      );
    }
    return getDescriptionPricePackage.call(priceFocus).map((des, index) => (
      <li key={`${index}-getDescriptionPricePackage`}>
        {/* {des.replace(/({{price}}|{{quantity}})/gi, matched => replaceDesciprtion[matched])} */}
        {des.replace(STRING_REPLACE_SPECIAL, '')}
      </li>
    ));
  };

  const isBuyNewUIForPriceFocusing = checkBuyNewUI(
    priceFocus?.type,
    freeTicket.numberTicket,
    hasBig3,
  );

  const debounceGetRemaTime = useCallback(_.debounce(getRemaTime, 500), []);

  useEffect(() => {
    if (!account?.profile || !priceBig3) {
      return;
    }

    const callbackFn = res => {
      const packagePuchases = res;
      const packageTvod = (packagePuchases || []).find(
        packagePuchase => packagePuchase.id === priceBig3?.id,
      );

      if (_.isEmpty(packageTvod)) {
        setTotalContent(priceBig3?.quantity);
        setHasBig3(0);
        setPaymentProvider('');
        return;
      }

      setTotalContent(packageTvod.remaining_purchases);
      setHasBig3(packageTvod.remaining_purchases);
      setPaymentProvider(packageTvod.payment_provider);
    };

    debounceGetRemaTime(callbackFn);
  }, [account, content]);

  useEffect(() => {
    if (!account && priceBig3) {
      setTotalContent(priceBig3.quantity);
    }
  }, [priceBig3, account, listTvodCredit]);

  useEffect(() => {
    if (account && hasBig3 <= 0 && priceBig3) {
      setTotalContent(priceBig3.quantity);
    }
  }, [priceBig3, account, hasBig3]);

  useEffect(() => {
    if (!account) {
      return;
    }
    setTimeout(() => {
      setActive(true);
    }, 700);
  }, [account]);

  useEffect(() => {
    if (_.isEmpty(content)) {
      return;
    }
    const paramQuery = queryString.parse(location.search, { decode: false });
    const {
      price_id = undefined,
      tvod_type = undefined,
      content_id = '',
      token,
      PayerID,
    } = paramQuery;

    if (token && content_id && PayerID) {
      const data = {
        content_id,
        order_id: paramQuery.token,
        payment_provider: PPV_PAYMENT_GATEWAY_PAYPAL,
        price_id,
        tvod_type,
      };
      confirmPaypal(data);
    }
  }, [content]);

  useEffect(() => {
    const paramQuery = queryString.parse(location.search, { decode: false });

    const { content_id = '', token, PayerID } = paramQuery;

    if ((token && content_id && PayerID) || !openModalPPV || _.isEmpty(content)) {
      return;
    }

    wFirebaseEvents.ppvStartedEvent({ content_id: content.id });
  }, [openModalPPV, content]);

  useEffect(() => {
    if (!account) {
      setPriceFocus(priceEarly ?? priceBig3);
      return;
    }

    if (account && priceBig3 && hasBig3 > 0) {
      setPriceFocus(priceBig3);
      return;
    }
    setPriceFocus(priceEarly ?? priceBig3);
  }, [account, priceEarly, priceBig3, hasBig3]);

  useEffect(() => () => handleClose(), []);

  function getProfile() {
    dispatch(getAccountInfo())
      .then(res => {
        setFreeTicket({
          numberTicket: res.num_tickets,
          type: 'coupon',
        });
      })
      .catch(err => {})
      .finally(() => {});
  }

  React.useEffect(() => {
    getProfile();
  }, [account?.profile?.id]);

  const scrollToEndElement = () => {
    const ele = document.getElementById('bottom');
    setTimeout(() => {
      ele.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  };

  const verifiedEmail = isLoggedVerifiedEmail.call(auth) && !isShowVerifyEmailPPV;
  const accountAccepted = account && verifiedEmail;
  if (isPaymentSuccess) return <ModalPaymentSuccess handleClose={handleClose} {...props} />;
  if (paymentError)
    return (
      <ModalPaymentUnSuccess
        paymentError={paymentError}
        setPaymentError={setPaymentError}
        handleClose={handleClose}
      />
    );
  return (
    <>
      <ModalW
        show={openModalPPV}
        onHide={() => handleClose()}
        aria-labelledby="contained-modal-title-vcenter"
        className="v-loginForm project-w ppv"
        // id="bottom"
      >
        <i
          style={{ display: 'none' }}
          onClick={() => handleClose()}
          className="fa fa-times"
          id="close"
          aria-hidden="true"
        />
        <ContainerFragment className="ModalBig">
          <Modal.Body className={`project-w--Body ppv ${account && 'ppv__account'} `}>
            <div className="ppv__form">
              <div className="ppv__header">
                <p className="ppv__header__title">{t('projectw__PPV.title')}</p>
                <div className="ppv__header__thumbnail">
                  <LazyImage
                    src={getThumbnail9_5.call(content)}
                    alt=""
                    className="ppv__header__thumbnail--image"
                  />
                </div>
              </div>
              <div className="ppv__body">
                <div className="ppv__body__gateway">
                  <p className="ppv__body__gateway__title">
                    {t('projectw__PPV.paymentxt.txtSelectPackage')}
                  </p>
                  <div className="ppv__body__gateway__package">
                    {!_.isEmpty(listTvodCredit) &&
                      listTvodCredit.map(item => {
                        const { type, name } = item;
                        const { numberTicket } = freeTicket;
                        const isBuyNew = checkBuyNewUI(item?.type, numberTicket, hasBig3);
                        const isFreeTicket = type === PRICE_NORMAL && !isBuyNew;

                        return (
                          <div
                            key={item.id}
                            onClick={() => handleOnClickFocus(item)}
                            className={`package_Subscription ${
                              type === priceFocus?.type && 'focus'
                            }`}
                          >
                            <p>
                              {isFreeTicket ? 'Use Ticket' : name}{' '}
                              {isBuyNew && <span>{convertCurrency_String.call(item)}</span>}
                              {!isBuyNew && (
                                <span className="package_Subscription__info">
                                  {type === PRICE_NORMAL ? (
                                    `${numberTicket} remaning`
                                  ) : (
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: t(
                                          'projectw__PPV.Description.package_credit.renmainingTicket',
                                          { totalContent },
                                        ),
                                      }}
                                    />
                                  )}
                                </span>
                              )}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                  <div className="ppv__body__gateway__packageDescription">
                    {renderDescription()}
                  </div>
                  {isBuyNewUIForPriceFocusing && accountAccepted && (
                    <button
                      className="ppv-btn ppv-btn--buy"
                      onClick={() => {
                        scrollToEndElement();
                        setOpenPayments(true);
                      }}
                    >
                      {t('projectw__PPV.buy now')}
                    </button>
                  )}

                  {account && verifiedEmail && openPayments && (
                    <>
                      {isBuyNewUIForPriceFocusing && (
                        <div className="ppv__body__gateway__packagePaymentMethod">
                          <PaymentMethod
                            content={content}
                            confirmPayment={confirmPayment}
                            setConfirmPayment={setConfirmPayment}
                            paymentCountry={paymentCountry}
                            setPaymentCountry={setPaymentCountry}
                            setConfirmPaymentPayPal={setConfirmPaymentPayPal}
                            confirmPaymentPayPal={confirmPaymentPayPal}
                            pricesTickContentView={pricesTickContentView}
                            priceFocus={priceFocus}
                            setBootpayMethod={setBootpayMethod}
                            bootpayMethod={bootpayMethod}
                            scrollToEndElement={scrollToEndElement}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              {account && isNewPPV__NoCache.call(pricesTickContentView) && verifiedEmail && (
                <Row id="confirmPayment" className="v-loginForm--row project-w--row">
                  {isBuyNewUIForPriceFocusing && (
                    <Col sm={12}>
                      {paymentCountry === PPV_BOOTPAY && (
                        <Bootpay
                          {...props}
                          openModalSuccess={() => openModalSuccess()}
                          confirmPrivacy={confirmPayment}
                          pricesTickContentView={pricesTickContentView}
                          handleClose={handleClose}
                          priceFocus={priceFocus}
                          setBootpayMethod={setBootpayMethod}
                          bootpayMethod={bootpayMethod}
                        />
                      )}
                      {paymentCountry === PPV_PAYPAL && (
                        <Paypal
                          {...props}
                          confirmPrivacy={confirmPayment}
                          submitTxt="Pay now"
                          hasDescription={false}
                          confirmPaymentPayPal={confirmPaymentPayPal}
                          handleClose={handleClose}
                          confirmPayment={confirmPayment}
                          priceFocus={priceFocus}
                        />
                      )}
                    </Col>
                  )}
                  {priceFocus?.type === PRICE_CREDIT &&
                    !isBuyNewUIForPriceFocusing &&
                    verifiedEmail && (
                      <Col sm={12}>
                        <PaymentBig3
                          getDetailContent={props.getDetailContent}
                          confirmPaypal={() => confirmPaypal()}
                          openModalSuccess={() => openModalSuccess()}
                          paymentProvider={paymentProvider}
                          setPaymentError={setPaymentError}
                          handleClose={handleClose}
                          priceFocus={priceFocus}
                          {...props}
                        />
                      </Col>
                    )}
                  {priceFocus?.type === PRICE_NORMAL &&
                    !isBuyNewUIForPriceFocusing &&
                    verifiedEmail && (
                      <Col sm={12}>
                        <PaymentFreeTicket
                          getDetailContent={props.getDetailContent}
                          confirmPaypal={() => confirmPaypal()}
                          openModalSuccess={() => openModalSuccess()}
                          paymentProvider={paymentProvider}
                          setPaymentError={setPaymentError}
                          handleClose={handleClose}
                          priceFocus={priceFocus}
                          {...props}
                        />
                      </Col>
                    )}
                </Row>
              )}
            </div>
            <div id="bottom" />
          </Modal.Body>
          <Modal.Body
            className={`project-w--Body signIn ${account && verifiedEmail && 'transitionModal'} ${
              active && verifiedEmail && 'display__none'
            } `}
          >
            <div className="signIn__form">{checkRenderPopup()}</div>
          </Modal.Body>
        </ContainerFragment>
      </ModalW>
    </>
  );
}

const mapStateToProps = state => ({
  userProfile: !_.isEmpty(state.auth.account) && state.auth.account.profile,
  content: state.landingPage.detail,
});

const mapDispatchToProps = {};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalPPV)),
);
