/* tslint:disable */
/* eslint-disable */
/**
 * ODX/V3 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OdxV3CurationContentBaseLogicOrderingEnum = exports.OdxV3CurationContentBaseRatioEnum = exports.OdxV3CurationContentBaseTypeEnum = void 0;
exports.OdxV3CurationContentBaseTypeEnum = {
    episode: 'episode',
    program: 'program',
    unknown_default_open_api: '11184809'
};
exports.OdxV3CurationContentBaseRatioEnum = {
    portrait: 'portrait',
    landscape: 'landscape',
    unknown_default_open_api: '11184809'
};
exports.OdxV3CurationContentBaseLogicOrderingEnum = {
    episode_recent_updated: 'episode-recent-updated',
    episode_recent_updated_in_24h: 'episode-recent-updated-in-24h',
    episode_recent_updated_except_news_and_daily_life: 'episode-recent-updated-except-news-and-daily-life',
    episode_24h_free: 'episode-24h-free',
    episode_recent_updated_by_date: 'episode-recent-updated-by-date',
    episode_popular_by_data: 'episode-popular-by-data',
    episode_recommendation: 'episode-recommendation',
    episode_continue_watching: 'episode-continue-watching',
    program_recent_updated: 'program-recent-updated',
    program_popular: 'program-popular',
    program_custom_drama: 'program-custom-drama',
    program_custom_variety: 'program-custom-variety',
    program_custom_documentary: 'program-custom-documentary',
    program_custom_movie: 'program-custom-movie',
    program_custom_news: 'program-custom-news',
    program_custom_kids: 'program-custom-kids',
    program_favorite: 'program-favorite',
    unknown_default_open_api: '11184809'
};
