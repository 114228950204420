Object.defineProperty(exports, "__esModule", { value: true });
exports.OdxApiAdapter = void 0;
var api_adapter_odx_1 = require("../../api-adapters/api-adapter-odx");
var api_service_proxy_1 = require("../utils/api-service-proxy");
var adapter_type_1 = require("../adapter-type");
var OdxApiAdapter = /** @class */ (function () {
    function OdxApiAdapter(configs) {
        if (configs === void 0) { configs = {}; }
        this.configuration = configs.configuration;
        this.baseUrl = configs.baseUrl;
        this.axiosInstance = configs.axiosInstance;
        this.parameterProvider = configs.parameterProvider;
        this.debug = configs.debug;
        this.initializeServices();
    }
    OdxApiAdapter.prototype.create = function (cls) {
        var provider = this.parameterProvider;
        var s = new cls(this.configuration, this.baseUrl, this.axiosInstance, provider);
        return provider ? (0, api_service_proxy_1.applyProxy)(s, provider, this.debug, adapter_type_1.AdapterType.ODX) : s;
    };
    OdxApiAdapter.prototype.initializeServices = function () {
        this.authenticationService = this.create(api_adapter_odx_1.OdxAuthenticationApi);
        this.adsManagementService = this.create(api_adapter_odx_1.OdxAdsManagementApi);
        this.appVersionService = this.create(api_adapter_odx_1.OdxAppVersionApi);
        this.configService = this.create(api_adapter_odx_1.OdxConfigApi);
        this.contentListingService = this.create(api_adapter_odx_1.OdxContentListingApi);
        this.contentService = this.create(api_adapter_odx_1.OdxContentApi);
        this.clientEventService = this.create(api_adapter_odx_1.OdxClientEventsApi);
        this.documentService = this.create(api_adapter_odx_1.OdxDocumentApi);
        this.geoBlockingService = this.create(api_adapter_odx_1.OdxGeoBlockingApi);
        this.multitenancyService = this.create(api_adapter_odx_1.OdxMultitenancyApi);
        this.paymentService = this.create(api_adapter_odx_1.OdxPaymentApi);
        this.userContentService = this.create(api_adapter_odx_1.OdxUserContentApi);
        this.userService = this.create(api_adapter_odx_1.OdxUserApi);
    };
    return OdxApiAdapter;
}());
exports.OdxApiAdapter = OdxApiAdapter;
