import React, { Component, PureComponent } from 'react';
import { Panel } from 'react-bootstrap';
import moment from 'moment';
import { contentTypes, FormatDayTime } from '~root/utils/utils';
import { MOVIE_TYPE, SHOW_TYPE, EPISODE_TYPE } from './../constants';
import Select from 'react-select';
import { initEpsTrailer } from './../function/initControlBar';
import { getConfigByKey } from '../../tenantConfig/services';
import AdsBanner from '~components/adsBanner/views/index';
import ButtonShare from '~features/share/views';
import { Link } from 'react-router-dom';
class InfoContents extends PureComponent {
  constructor(props) {
    super(props);
    this.target = 'player';
    this.state = {
      directors: [],
      actor: [],
      entity: '',
      eps: [],
      optionsEps: [],
      epsSelects: null,
      loadingShare: true,
    };
  }

  componentWillMount() {
    let { entityDetail, infoShow } = this.props;

    if (entityDetail && entityDetail.group && infoShow) {
      entityDetail = { entityDetail, ...infoShow };
    }
    if (entityDetail.type === EPISODE_TYPE || entityDetail.type === SHOW_TYPE) {
      this._renderShowYoutube(entityDetail);
    } else {
      this._renderMovie(entityDetail);
    }
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    let { entityDetail, infoShow } = nextProps;
    const { component } = this.props;
    const { entity } = this.state;
    let slug = infoShow
      ? entity.entityDetail && (entity.entityDetail.slug || entity.slug)
      : entity.slug;
    if (entityDetail.slug !== slug) {
      if (entityDetail && entityDetail.group && infoShow) {
        entityDetail = { entityDetail, ...infoShow };
      }
      this.setState(
        {
          loadingShare: true,
          entity: entityDetail,
        },
        () => {
          if (entityDetail.type === EPISODE_TYPE || entityDetail.type === SHOW_TYPE) {
            this._renderShowYoutube(entityDetail);
          } else {
            this._renderMovie(entityDetail);
          }
        },
      );
    }
  }

  handleChange = epsSelects => {
    const { component, entityDetail } = this.props;
    const { history, language } = component.props;

    let url = epsSelects.trailer
      ? `/${language}/detail/${entityDetail.slug}?trailer=${epsSelects.slug}`
      : `/${language}/detail/${epsSelects.slug}`;
    this.setState(
      {
        epsSelects,
      },
      () => {
        history.replace(url);
      },
    );
  };

  _renderShowYoutube(entityDetail) {
    if (!entityDetail.video_source) {
      this.setState({
        entity: entityDetail,
        loadingShare: false,
      });
      return;
    }
    let { component } = this.props;
    const { landingPage } = component.props;
    let episodes = landingPage.season.episodes || [];
    let epsSelected = {
      value: entityDetail.entityDetail.id,
      label: entityDetail.entityDetail.title,
      slug: entityDetail.entityDetail.slug,
    };
    this._renderTrailer(entityDetail, episodes, epsSelected);
  }

  _renderTrailer(entityDetail, episodes, epsSelected) {
    let { component } = this.props;
    const { entityTrailer } = component.state;
    if (entityTrailer) {
      let label = entityDetail.trailers.filter(items => items.slug === entityTrailer.slug);
      epsSelected = {
        value: entityTrailer.id,
        label: label.length && label[0].title,
        slug: entityTrailer.slug,
      };
    }
    this.setState(
      {
        entity: entityDetail,
        eps: episodes,
        epsSelects: epsSelected,
      },
      () => {
        episodes = initEpsTrailer(component, { entityDetail });
        const options = episodes.map(items => {
          return {
            ...items,
            value: items.id,
            label: items.title,
            slug: items.slug,
          };
        });
        this.setState({
          optionsEps: options,
          loadingShare: false,
        });
      },
    );
  }

  _renderMovie(entityDetail) {
    if (!entityDetail.video_source) {
      this.setState({
        entity: entityDetail,
        loadingShare: false,
      });
      return;
    }
    let epsSelected = {
      value: entityDetail.id,
      label: entityDetail.title,
      slug: entityDetail.slug,
    };
    this._renderTrailer(entityDetail, entityDetail, epsSelected);
  }

  _renderType(entity) {
    let typesContent = contentTypes(entity);
    const { content_categories } = entity;
    if (content_categories.length > 0) {
      typesContent = content_categories[0].name;
    }
    return <p className="v-detailPage--category"> {typesContent}</p>;
  }

  _renderNamePeople(name, data, metadata) {
    if (!data.length) return;
    const { language } = this.props.component.props;
    return (
      <p className={`v-detailPage--people ${getConfigByKey('ui_theme')}`}>
        <span>{name}</span>
        {data.map(function (items, i) {
          return (
            <Link key={i} to={`/${language}/${metadata}/${items.slug}`}>
              {items.name}
              {i < data.length - 1 ? ', ' : null}
            </Link>
          );
        })}
      </p>
    );
  }

  _renderPeople() {
    const { t } = this.props;
    const { language } = this.props.component.props;
    const { entity } = this.state;
    let directors = [];
    let actor = [];
    let writer = [];
    let isYoutube = false;
    if (entity) {
      isYoutube = entity.video_source;
    }
    const listRegions = (entity.metadata && entity.metadata.regions) || [];
    const listGenres = (entity.metadata && entity.metadata.genres) || [];

    const metadata = (entity.metadata && entity.metadata.peoples) || [];
    if (metadata.length > 0) {
      metadata.map(function (item) {
        if (item.role === 'DIRECTOR') {
          directors.push(item);
        }
        if (item.role === 'ACTOR') {
          actor.push(item);
        }
        if (item.role === 'WRITER') {
          writer.push(item);
        }
      });
    }
    if (isYoutube) {
      return this._renderNamePeople(t('laddingPage.author'), writer, 'producers');
    }
    return (
      <React.Fragment>
        <p className={`v-detailPage--people ${getConfigByKey('ui_theme')}`}>
          {listRegions.length > 0
            ? listRegions.map(function (items, i) {
                return (
                  <Link key={i} to={`/${language}/nationalities/${items.slug}`}>
                    {items.name}
                    {i < listRegions.length - 1 ? ', ' : ' | '}
                  </Link>
                );
              })
            : null}
          {entity && listGenres.length > 0
            ? listGenres.map(function (items, i) {
                return (
                  <Link key={i} to={`/${language}/genres/${items.slug}`}>
                    {items.name}
                    {i < listGenres.length - 1 ? ', ' : null}
                  </Link>
                );
              })
            : null}
        </p>
        {this._renderNamePeople(t('laddingPage.directors'), directors, 'directors')}
        {this._renderNamePeople(t('laddingPage.actor'), actor, 'casts')}
      </React.Fragment>
    );
  }
  render() {
    const { entity, optionsEps, epsSelects, loadingShare } = this.state;
    const { t, component } = this.props;
    const { entityTrailer, isHasSubcription } = component.state;
    const colourStyles = {
      control: styles => ({
        ...styles,
        display: optionsEps.length <= 1 ? 'none' : 'flex',
        padding: '0.25rem',
        background: '#3b3939',
        border: 'none',
        margin: '0.5rem 0',
        cursor: 'pointer',
        color: '#fff',
      }),
      indicatorSeparator: style => ({
        ...style,
        backgroundColor: '#3b3939',
      }),
      singleValue: style => ({
        ...style,
        color: '#fff',
      }),
      menu: styles => ({
        ...styles,
        background: '#3b3939',
        zIndex: 9,
      }),
      option: (styles, { isSelected }) => {
        return {
          ...styles,
          textAlign: 'left',
          fontSize: 14,
          cursor: 'pointer',
          zIndex: 2,
          backgroundColor: isSelected ? '#fff' : '#3b3939',
          color: isSelected ? 'black' : '#fff',
          ':active': {
            ...styles[':active'],
            backgroundColor: '#fff',
            color: 'black',
          },
          ':hover': {
            ...styles[':hover'],
            backgroundColor: '#2574d4',
            color: '#fff',
          },
        };
      },
    };
    if (entity) {
      let isYoutube = entity.video_source;
      let dateRelease =
        isYoutube !== 1
          ? moment(entity.release_date, 'YYYY-MM-DD').format(`YYYY`)
          : FormatDayTime(entity.release_date);
      return (
        <Panel className="v-detailPage__info">
          <Panel.Heading
            className="v-detailPage__info--header"
            style={{ display: isYoutube !== 1 && 'none' }}
          >
            <span>{t('player.txtMind')}</span>
            <span
              dangerouslySetInnerHTML={{
                __html: t('player.txtContent', {
                  tenantSlug: getConfigByKey('tenant_slug', false).toUpperCase(),
                }),
              }}
            />
          </Panel.Heading>
          <Panel.Body>
            {this._renderType(entity)}
            <div className="v-detailPage--title">
              <h1>{entity.title}</h1>
              {!loadingShare && (
                <ButtonShare
                  entityDetail={entity}
                  target={this.target}
                  entityTrailer={entityTrailer}
                />
              )}
            </div>
            <Select
              isSearchable={false}
              options={Array.isArray(optionsEps) && optionsEps.reverse()}
              value={epsSelects}
              className={isYoutube !== 1 && 'vjs-hidden'}
              onChange={this.handleChange}
              styles={colourStyles}
              placeholder=""
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#fff',
                  primary: 'black',
                },
              })}
            />
            <p className="v-detailPage--duration">
              {entity.type === SHOW_TYPE || entity.type === EPISODE_TYPE ? (
                <React.Fragment>
                  <span>
                    {t('laddingPage.duration')}{' '}
                    {t('laddingPage.TxtTotalEps', {
                      episodes: `${entity.released_episode_count}/${entity.total_episodes}`,
                    })}{' '}
                  </span>{' '}
                  {entity.release_date && `| ${dateRelease}`}
                </React.Fragment>
              ) : (
                entity.release_date && `${dateRelease}`
              )}
            </p>
            {this._renderPeople()}
            <p
              className="v-detailPage--desscription"
              style={{ maxHeight: isHasSubcription && 'unset' }}
            >
              {entity.long_description || entity.short_description}
            </p>
            {!isHasSubcription && (
              <div className="v-detailPage__ads-square">
                <div className="v-detailPage__ads-square-wrap">
                  <AdsBanner position="medium_rectangle_player" nonMargin />
                </div>
              </div>
            )}
          </Panel.Body>
        </Panel>
      );
    }
    return null;
  }
}
export default React.memo(InfoContents);
