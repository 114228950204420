import styled from 'styled-components';

export const ChatLiveStyled = styled.div`
  height: calc(100vh - 85px);
  background: #131313;
  box-shadow: inset 1px 0px 0px rgba(51, 51, 51, 0.82);
  /* padding: 8px; */
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    height: 100%;
  }
  .sign-up-button {
    font-family: 'Neue Haas Grotesk Display Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 133.5%;
    /* or 21px */

    letter-spacing: 0.025em;

    color: #131313;
    background-color: #ffffff;
    padding: 8px;
    border-radius: 6px;
    margin: 8px;
    @media (max-width: 768px) {
      margin: 10px 19px;
      margin-bottom: 20px;
    }
  }

  .sending,
  .error {
    .ms-message {
      color: #898989;
      margin-right: 20px;
    }
  }

  @media (max-width: 767px) {
    /* display: none; */
  }
`;
