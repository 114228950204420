// import 'url-change-event';
import videojs from 'video.js';
// import * as eme from 'videojs-contrib-eme'
import _ from 'lodash';
import $ from 'jquery';
import { localStorage as ls } from 'js-storage';

import { getDrmLinkPlay } from '~core/method/contentMethod';
import { setClosePlayer } from './drm';
import { drmCCU } from '~api/drmApi';
import { TRAILER_TYPE } from '~core/constants';

const eme = require('videojs-contrib-eme').default;
const mime = require('mime-types');

const stName = 'ccuSessionDrmEme';

export const stringToUint16Array = string => {
  // 2 bytes for each char
  const buffer = new ArrayBuffer(string.length * 2);
  const array = new Uint16Array(buffer);

  for (let i = 0; i < string.length; i++) {
    array[i] = string.charCodeAt(i);
  }

  return array;
};

export const uint8ArrayToString = array =>
  String.fromCharCode.apply(null, new Uint8Array(array.buffer || array));

export const uint16ArrayToString = array =>
  String.fromCharCode.apply(null, new Uint16Array(array.buffer || array));

const addNewSessionToStorage = session => {
  const sessionCreated = ls.get(stName) || [];
  ls.set(stName, [...sessionCreated, session]);
};

const removeSessionFromStorage = session => {
  const sessionCreated = ls.get(stName) || [];
  ls.set(
    stName,
    sessionCreated.filter(ss => ss.session !== session.session),
  );
};

const endAllBeforeSession = () => {
  const sessionCreated = ls.get(stName) || [];

  if (_.isEmpty(sessionCreated)) {
    return Promise.resolve();
  }
  return Promise.allSettled(
    sessionCreated.map(ss =>
      drmCCU({ ...ss, action: 'end' }).then(() => removeSessionFromStorage(ss)),
    ),
  );
};

const messageErrorTitle = 'Exceed Simultaneous Watch Limit';
const messageErrorSmallTitle = `You have reached the limit for simultaneous watch. In order to watch on this device,
please close the player in other devices first and refresh this page.`;
const excludeContentTypes = [TRAILER_TYPE];
const sessionEnded = {};
let timeInterval = null;
let drmEnded = false;

const handlePingFailure = (error, player) => {
  const modal = player.createModal();

  const $contentEl = $(modal.contentEl_);
  $contentEl.append(
    $(`
    <p class='vjs-drm-error--title'>${messageErrorTitle}</p>
    <p class='vjs-drm-error--small-title'>${messageErrorSmallTitle}</p>
  `),
  );
  modal.addClass('vjs-drm-error');
  player.src('');
  clearInterval(timeInterval);
};

const handleDrmEnd = payload => {
  const { session } = payload;
  if (sessionEnded[session]) {
    return;
  }
  sessionEnded[session] = true;
  clearInterval(timeInterval);
  addNewSessionToStorage(payload);

  drmCCU({ ...payload, action: 'end' });
};

export async function drmEme(player, content) {
  const { drm_session_info } = content;
  const link_play = getDrmLinkPlay.call(content);

  if (_.isEmpty(drm_session_info)) {
    return;
  }
  await endAllBeforeSession();

  const {
    fairplay_cert_url,
    fairplay_license_path,
    merchant,
    operator_id,
    playready_license_path,
    session,
    session_id,
    user_id,
    widevide_license_path,
    auth_token,
  } = drm_session_info;

  const drmTodayData = {
    merchant,
    userId: user_id,
    sessionId: session,
  };
  let requestDrm = null;

  if (content.type && !excludeContentTypes.includes(content.type)) {
    try {
      requestDrm = await drmCCU({ user_id, session });
      drmEnded = false;
    } catch (error) {
      handlePingFailure(error, player);
      return;
    }

    timeInterval = setInterval(() => {
      drmCCU({ user_id, session }).catch(error => {
        handlePingFailure(error, player);
      });
    }, 60 * 1000);

    // player.tech(true).on('licenserequestattempted', function (event) {
    // })
    // player.tech(true).on('keystatuschange', function (event) {
    // })
    // player.tech().on('keysessioncreated', function (event) {
    // })

    player.on(['dispose'], event => {
      handleDrmEnd({ user_id, session });
    });
    $(window).on('urlchangeevent', event => {
      handleDrmEnd({ user_id, session });
      $(window).off('urlchangeevent');
    });
  }

  const drmTodayDataB = btoa(JSON.stringify(drmTodayData));

  const headers = {
    'x-dt-auth-token': auth_token,
    'x-dt-custom-data': drmTodayDataB,
  };

  player.eme = eme;
  player.eme();
  player.src({
    src: link_play,
    // Use this manifest for Fairplay protected content
    // src: "https://demo.cf.castlabs.com/media/fps/prog_index.m3u8",
    type: mime.lookup(link_play),
    keySystems: {
      'com.widevine.alpha': {
        url: widevide_license_path,
        licenseHeaders: headers,
      },
      'com.microsoft.playready': {
        url: playready_license_path,
        licenseHeaders: headers,
      },
      'com.apple.fps.1_0': {
        getContentId(emeOptions, initData) {
          return uint16ArrayToString(initData);
        },
        getCertificate(emeOptions, callback) {
          videojs.xhr(
            {
              url: fairplay_cert_url,
              method: 'GET',
              responseType: 'arraybuffer',
            },
            (err, response, responseBody) => {
              callback(null, new Uint8Array(responseBody));
            },
          );
        },
        getLicense(emeOptions, contentId, keyMessage, callback) {
          videojs.xhr(
            {
              url: fairplay_license_path,
              method: 'POST',
              responseType: 'text',
              body: keyMessage,
              headers: {
                ...headers,
              },
            },
            (err, response, responseBody) => {
              const rawLicenseString = atob(responseBody);

              const data = new Uint8Array(new ArrayBuffer(rawLicenseString.length));
              for (let i = 0; i < rawLicenseString.length; ++i) {
                data[i] = rawLicenseString.charCodeAt(i);
              }
              const key = data.buffer;
              callback(null, key);
            },
          );
        },
      },
    },
  });
  player.play();
}
