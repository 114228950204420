var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParseEpisodeDetailFromOdxEpisodeAndOdxPlayback = exports.CombineOdxProgramAndOdxEpisodeListToSeasonDetail = exports.CombineOdxEpisodeAndPlaybackToContentViewInfo = exports.CombineProgramAndTrailersToContentDetail = exports.CombineProgramAndPlaybackToContentViewInfo = exports.ParseSearchFromOdxSearch = void 0;
var mappers_1 = require("../mappers");
var factory_1 = require("../factory");
var content_utils_1 = require("../utils/content-utils");
var constants_1 = require("../constants");
var image_utils_1 = require("../utils/image-utils");
var getSubtitleInfoFromPlayback = function (playback) {
    var _a, _b, _c;
    var subtitles = [];
    var subtitle_source = 'external';
    if ((_a = playback === null || playback === void 0 ? void 0 : playback.manifests) === null || _a === void 0 ? void 0 : _a.length) {
        subtitle_source = 'stream-embedded';
    }
    else {
        subtitles = (_c = (_b = playback === null || playback === void 0 ? void 0 : playback.text_tracks) === null || _b === void 0 ? void 0 : _b.filter(content_utils_1.filterSupportedTextTrack)) === null || _c === void 0 ? void 0 : _c.map(mappers_1.mapOdxPlaybackTextTracksInnerToContentSubtitleResponse);
    }
    return {
        subtitles: subtitles,
        subtitle_source: subtitle_source,
    };
};
var getVideoSourceFromPlayback = function (playback) {
    var _a, _b, _c, _d, _e;
    var originalSources = (playback === null || playback === void 0 ? void 0 : playback.sources) || [];
    var sources = (playback === null || playback === void 0 ? void 0 : playback.manifests) || [];
    if (!(sources === null || sources === void 0 ? void 0 : sources.length)) {
        sources = originalSources;
    }
    var linkPlay = sources ? (_a = sources[sources.length - 1]) === null || _a === void 0 ? void 0 : _a.url : '';
    var dashLinkPlay = (_b = sources === null || sources === void 0 ? void 0 : sources.find(function (x) { return x.type === 'widevine'; })) === null || _b === void 0 ? void 0 : _b.url;
    var hlsLinkPlay = (_c = sources === null || sources === void 0 ? void 0 : sources.find(function (x) { return x.type === 'fairplay'; })) === null || _c === void 0 ? void 0 : _c.url;
    if (!dashLinkPlay) {
        dashLinkPlay = (_d = originalSources === null || originalSources === void 0 ? void 0 : originalSources.find(function (x) { return x.type === 'widevine'; })) === null || _d === void 0 ? void 0 : _d.url;
    }
    if (!hlsLinkPlay) {
        hlsLinkPlay = (_e = originalSources === null || originalSources === void 0 ? void 0 : originalSources.find(function (x) { return x.type === 'fairplay'; })) === null || _e === void 0 ? void 0 : _e.url;
    }
    return {
        linkPlay: linkPlay,
        dashLinkPlay: dashLinkPlay,
        hlsLinkPlay: hlsLinkPlay,
    };
};
var ParseSearchFromOdxSearch = function (keyword, result) {
    var _a;
    return {
        keyword: keyword,
        items: (_a = result === null || result === void 0 ? void 0 : result.results) === null || _a === void 0 ? void 0 : _a.map(mappers_1.mapOdxSearchToSearchItem),
        metadata: {
            page: (result.page_previous || 1) - 1,
            limit: result.page_size,
            total: result.count,
        },
        suggested_items: [],
    };
};
exports.ParseSearchFromOdxSearch = ParseSearchFromOdxSearch;
var CombineProgramAndPlaybackToContentViewInfo = function (program, playback, error) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var identity = (0, content_utils_1.buildContentIdentity)({
        id: program.id,
        slug: program.slug,
        type: (0, content_utils_1.isShow)(program) ? 'show' : 'movie',
    });
    var defaultSeasonIdentity = (0, content_utils_1.buildContentIdentity)({ id: program.id, slug: program.slug, type: 'season' });
    var defaultEpisodeIdentity = (0, content_utils_1.buildContentIdentity)({
        id: (_a = program.direct_play_episode) === null || _a === void 0 ? void 0 : _a.id,
        slug: (_b = program.direct_play_episode) === null || _b === void 0 ? void 0 : _b.slug,
        type: 'episode',
    });
    var cw = playback === null || playback === void 0 ? void 0 : playback.cw;
    var progress = (cw === null || cw === void 0 ? void 0 : cw.last_position) || (((cw === null || cw === void 0 ? void 0 : cw.total_play_time) || 0) > 0 ? 1 : 0);
    var defaultSeason;
    if ((0, content_utils_1.isShow)(program)) {
        defaultSeason = __assign(__assign({}, factory_1.defaultContentViewInfo.current_season), { id: defaultSeasonIdentity, total_episodes: (_c = program.meta) === null || _c === void 0 ? void 0 : _c.episode_total, total_released_episodes: (_d = program.meta) === null || _d === void 0 ? void 0 : _d.episode_total, season_index: 1, season_name: '', current_episode: {
                id: defaultEpisodeIdentity,
                slug: defaultEpisodeIdentity,
                progress: progress,
                episode: ((_e = playback === null || playback === void 0 ? void 0 : playback.episode) === null || _e === void 0 ? void 0 : _e.number) || 1,
                episode_name: '',
                episode_index: ((_f = playback === null || playback === void 0 ? void 0 : playback.episode) === null || _f === void 0 ? void 0 : _f.number) || 1,
            } });
    }
    else {
        defaultSeason = null;
    }
    var _q = getVideoSourceFromPlayback(playback), linkPlay = _q.linkPlay, dashLinkPlay = _q.dashLinkPlay, hlsLinkPlay = _q.hlsLinkPlay;
    var isPaid = (_h = (_g = program.user_payment) === null || _g === void 0 ? void 0 : _g.pay_per_view) === null || _h === void 0 ? void 0 : _h.is_purchased;
    var paymentExpiry = (_k = (_j = program.user_payment) === null || _j === void 0 ? void 0 : _j.pay_per_view) === null || _k === void 0 ? void 0 : _k.expired_at;
    var paymentInfo = null;
    if (isPaid && paymentExpiry) {
        paymentInfo = {
            remaining_time: Math.ceil((new Date(paymentExpiry).getTime() - Date.now()) / 1000),
            end_time: (_m = (_l = program.user_payment) === null || _l === void 0 ? void 0 : _l.pay_per_view) === null || _m === void 0 ? void 0 : _m.expired_at,
            time_unit: 'seconds',
        };
    }
    var _r = getSubtitleInfoFromPlayback(playback), subtitles = _r.subtitles, subtitle_source = _r.subtitle_source;
    return __assign(__assign({}, factory_1.defaultContentViewInfo), { id: identity, slug: identity, short_title: program.title, title: program.title, origin_title: program.title, is_favorite: program.is_favorite, link_play: linkPlay, current_season: defaultSeason, subtitles: subtitles, subtitle_source: subtitle_source, progress: progress, 
        // @ts-ignore
        blocking_error_code: error === null || error === void 0 ? void 0 : error.name, blocking_error_detail: error === null || error === void 0 ? void 0 : error.info, payment_infors: paymentInfo, payment_description: {
            subscription: {
                allow_trial: (_p = (_o = program.user_payment) === null || _o === void 0 ? void 0 : _o.subscription) === null || _p === void 0 ? void 0 : _p.can_apply_trial,
            },
        }, play_info: {
            detail: '',
            error: 0,
            time: new Date().getTime(),
            data: {
                link_play: linkPlay,
                hls_link_play: hlsLinkPlay,
                dash_link_play: dashLinkPlay,
            },
        }, drm_session_info: (0, mappers_1.mapOdxPlaybackDRMToDRMSessionInfo)(playback === null || playback === void 0 ? void 0 : playback.drm), instruction: {
            cwl_ping_interval: playback === null || playback === void 0 ? void 0 : playback.cwl_call_cycle,
        } });
};
exports.CombineProgramAndPlaybackToContentViewInfo = CombineProgramAndPlaybackToContentViewInfo;
var CombineProgramAndTrailersToContentDetail = function (program, trailers, playback) {
    var info = (0, mappers_1.mapOdxProgramToContentDetail)(program);
    var subtitleInfo = getSubtitleInfoFromPlayback(playback);
    return __assign(__assign(__assign({}, info), subtitleInfo), { trailers: trailers });
};
exports.CombineProgramAndTrailersToContentDetail = CombineProgramAndTrailersToContentDetail;
var CombineOdxEpisodeAndPlaybackToContentViewInfo = function (episode, playback) {
    var _a;
    var _b = getVideoSourceFromPlayback(playback), linkPlay = _b.linkPlay, dashLinkPlay = _b.dashLinkPlay, hlsLinkPlay = _b.hlsLinkPlay;
    var cw = playback === null || playback === void 0 ? void 0 : playback.cw;
    var drmInfo = playback === null || playback === void 0 ? void 0 : playback.drm;
    var nextEpisode = (_a = playback === null || playback === void 0 ? void 0 : playback.episode) === null || _a === void 0 ? void 0 : _a.next_episode;
    var nextContent = null;
    if (nextEpisode) {
        var nextContentIdentity = (0, content_utils_1.buildContentIdentity)({
            id: nextEpisode.id,
            slug: nextEpisode.slug,
            type: 'episode',
        });
        nextContent = {
            id: nextContentIdentity,
            slug: nextContentIdentity,
            type: constants_1.ContentType.EPISODE,
            title: nextEpisode.title,
        };
    }
    var _c = getSubtitleInfoFromPlayback(playback), subtitles = _c.subtitles, subtitle_source = _c.subtitle_source;
    return __assign(__assign({}, (0, mappers_1.mapOdxEpisodeListToContentViewInfo)(episode)), { link_play: linkPlay, progress: (cw === null || cw === void 0 ? void 0 : cw.last_position) || (((cw === null || cw === void 0 ? void 0 : cw.total_play_time) || 0) > 0 ? 1 : 0), subtitles: subtitles, subtitle_source: subtitle_source, next_content: nextContent, play_info: {
            detail: '',
            error: 0,
            time: new Date().getTime(),
            data: {
                link_play: linkPlay,
                hls_link_play: hlsLinkPlay,
                dash_link_play: dashLinkPlay,
            },
        }, drm_session_info: (0, mappers_1.mapOdxPlaybackDRMToDRMSessionInfo)(drmInfo), instruction: {
            cwl_ping_interval: playback === null || playback === void 0 ? void 0 : playback.cwl_call_cycle,
        } });
};
exports.CombineOdxEpisodeAndPlaybackToContentViewInfo = CombineOdxEpisodeAndPlaybackToContentViewInfo;
var CombineOdxProgramAndOdxEpisodeListToSeasonDetail = function (program, episodes, seasonIdentity) {
    var _a, _b;
    var identity = seasonIdentity
        ? seasonIdentity
        : (0, content_utils_1.buildContentIdentity)({
            id: program.id,
            slug: program.slug,
            type: 'season',
        });
    return __assign(__assign({}, factory_1.defaultSeasonDetail), { id: identity, slug: identity, title: (program === null || program === void 0 ? void 0 : program.title) || '', total_episodes: ((_a = program === null || program === void 0 ? void 0 : program.meta) === null || _a === void 0 ? void 0 : _a.episode_total) || (episodes === null || episodes === void 0 ? void 0 : episodes.count) || 5, episodes: ((_b = episodes === null || episodes === void 0 ? void 0 : episodes.results) === null || _b === void 0 ? void 0 : _b.map(function (episode) { return (0, mappers_1.mapOdxEpisodeListToEpisode)(episode); })) || [] });
};
exports.CombineOdxProgramAndOdxEpisodeListToSeasonDetail = CombineOdxProgramAndOdxEpisodeListToSeasonDetail;
var ParseEpisodeDetailFromOdxEpisodeAndOdxPlayback = function (episode, playback) {
    var _a, _b, _c;
    var type = (0, content_utils_1.getTypeIDFromEpisodeKind)(episode.kind);
    var contentType = (0, content_utils_1.getContentTypeFromTypeID)(type);
    var identity = (0, content_utils_1.buildContentIdentity)({ id: episode.id, slug: episode.slug, type: type });
    var subtitleInfo = getSubtitleInfoFromPlayback(playback);
    return __assign(__assign(__assign({}, factory_1.defaultContentDetail), { id: identity, slug: identity, title: episode.title, type: contentType, images: {
            thumbnail: playback ? (0, image_utils_1.getResizedUrl)('thumbnail', (_b = (_a = playback.episode) === null || _a === void 0 ? void 0 : _a.images) === null || _b === void 0 ? void 0 : _b.thumbnail) : null,
        }, is_premium: !playback, has_free_content: !!playback, runtime: playback ? Math.ceil((playback.duration || 0) / 1000 / 60) : 0, duration: playback ? Math.ceil((playback.duration || 0) / 1000) : 0, release_date: episode.release_date, episode: playback ? ((_c = playback.episode) === null || _c === void 0 ? void 0 : _c.number) || 1 : 1 }), subtitleInfo);
};
exports.ParseEpisodeDetailFromOdxEpisodeAndOdxPlayback = ParseEpisodeDetailFromOdxEpisodeAndOdxPlayback;
