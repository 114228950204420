// Get the Component base class from Video.js
import videojs from 'video.js';
var Button = videojs.getComponent('Button');

// The videojs.extend function is used to assist with inheritance. In
// an ES6 environment, `class SeekNext extends Component` would work
// identically.

var BtnShare = videojs.extend(Button, {
  // The constructor of a component receives two arguments: the
  // player it will be associated with and an object of options.
  constructor: function (player, options) {
    // It is important to invoke the superclass before anything else,
    // to get all the features of components out of the box!
    Button.apply(this, arguments);
    // If a `text` option was passed in, update the text content of
    // the component.
  },

  // The `createEl` function of a component creates its DOM element.
  createEl: function () {
    const btn = videojs.dom.createEl('button', {
      className: 'vjs-btn-share vjs-button',
    });
    const { t } = this.options_.component.props;
    btn.innerHTML = `<i class="fa fa-share-alt" aria-hidden="true"></i><span>${t(
      'share.txtShare',
    )}</span>`;
    return btn;
  },
  handleClick: function (event) {
    const { component } = this.options_;
    component.setState({
      isOpenPopupShare: true,
    });
  },
});

// Register the component with Video.js, so it can be used in players.
videojs.registerComponent('BtnShare', BtnShare);
