import ReactDOM from 'react-dom';
import React from 'react';
import videojs from 'video.js';
import ContentAgeRating from './ContentAgeRating';
import jquery from 'jquery';
const vjsComponent = videojs.getComponent('Component');

class AgeRating extends vjsComponent {
  constructor(player, options) {
    super(player, options);
    const $player = jquery(this.el());
    player.on('ready', () => {
      if ($player.hasClass('vjs-ageRating')) {
        setTimeout(() => {
          $player.addClass('hide');
        }, 5000);
      }
      this.mount();
    });

    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }
  // TODO : Create class element for component render
  createEl() {
    const _ageRating = videojs.createEl('div', {
      className: 'vjs-ageRating',
    });
    return _ageRating;
  }

  //TODO : Render your component
  mount() {
    const { component, entity } = this.options_;
    ReactDOM.render(
      <ContentAgeRating player={this.player_} component={component} entity={entity} />,
      this.el(),
    );
  }
}

/**
 * Make sure to register the vjsComponent so Video JS knows it exists
 */
// TODO :  Add component into player
videojs.registerComponent('AgeRating', AgeRating);

export default AgeRating;
