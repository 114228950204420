Object.defineProperty(exports, "__esModule", { value: true });
exports.filterSupportedTextTrack = exports.getBadgeSerializer = exports.getProgramAirStatus = exports.isMovie = exports.isShow = exports.hasTrailer = exports.getContentTypeFromTypeID = exports.getTypeIDFromEpisodeKind = exports.getIdentityTypeFromContentType = exports.getContentTypeFromKindsOfEpisodes = exports.getIdInfoFromContentIdentity = exports.buildContentIdentity = void 0;
var constants_1 = require("../constants");
var buildContentIdentity = function (info) {
    var inf = info;
    // Some API does not return episode slug
    // __truncated__ is marker for empty slug
    if (info.type === 'episode' && inf.slug === '__truncated__') {
        inf.slug = '';
    }
    Object.keys(inf).forEach(function (v) {
        if (typeof inf[v] === 'number') {
            inf[v] = inf[v].toString();
        }
    });
    return '?' + new URLSearchParams(inf).toString();
};
exports.buildContentIdentity = buildContentIdentity;
var getIdInfoFromContentIdentity = function (id) {
    var info = new URLSearchParams(id);
    var getInt = function (i) { return parseInt(info.get(i) || '0', 10) || 0; };
    return {
        id: getInt('id'),
        slug: info.get('slug') || id,
        type: info.get('type') || 'movie',
    };
};
exports.getIdInfoFromContentIdentity = getIdInfoFromContentIdentity;
var getContentTypeFromKindsOfEpisodes = function (k) {
    return (k || []).indexOf('series') >= 0 ? constants_1.ContentType.SHOW : constants_1.ContentType.MOVIE;
};
exports.getContentTypeFromKindsOfEpisodes = getContentTypeFromKindsOfEpisodes;
var getIdentityTypeFromContentType = function (t) {
    switch (t) {
        case constants_1.ContentType.SHOW:
            return 'show';
        case constants_1.ContentType.MOVIE:
            return 'movie';
        case constants_1.ContentType.EPISODE:
            return 'episode';
        case constants_1.ContentType.LIVE_CHANNEL:
            return 'live_channel';
        case constants_1.ContentType.TRAILER:
            return 'trailer';
        default:
            return 'movie';
    }
};
exports.getIdentityTypeFromContentType = getIdentityTypeFromContentType;
var getTypeIDFromEpisodeKind = function (kind) {
    switch (kind) {
        case 'trailer':
            return 'trailer';
        case 'series':
            return 'episode';
        case 'main':
            return 'movie';
        default:
            return 'movie';
    }
};
exports.getTypeIDFromEpisodeKind = getTypeIDFromEpisodeKind;
var getContentTypeFromTypeID = function (typeId) {
    switch (typeId) {
        case 'season':
            return constants_1.ContentType.SEASON;
        case 'episode':
            return constants_1.ContentType.EPISODE;
        case 'movie':
            return constants_1.ContentType.MOVIE;
        case 'trailer':
            return constants_1.ContentType.TRAILER;
        case 'live_channel':
            return constants_1.ContentType.LIVE_CHANNEL;
        case 'show':
            return constants_1.ContentType.SHOW;
        default:
            return constants_1.ContentType.MOVIE;
    }
};
exports.getContentTypeFromTypeID = getContentTypeFromTypeID;
var hasTrailer = function (i) {
    var _a;
    return ((_a = i === null || i === void 0 ? void 0 : i.kinds_of_episodes) === null || _a === void 0 ? void 0 : _a.indexOf('trailer')) >= 0;
};
exports.hasTrailer = hasTrailer;
var isShow = function (i) {
    var _a;
    return ((_a = i === null || i === void 0 ? void 0 : i.kinds_of_episodes) === null || _a === void 0 ? void 0 : _a.indexOf('series')) >= 0;
};
exports.isShow = isShow;
var isMovie = function (i) {
    return !(0, exports.isShow)(i);
};
exports.isMovie = isMovie;
var getProgramAirStatus = function (i) {
    var _a, _b, _c;
    if (((_a = i === null || i === void 0 ? void 0 : i.kinds_of_episodes) === null || _a === void 0 ? void 0 : _a.includes('series')) || ((_b = i === null || i === void 0 ? void 0 : i.kinds_of_episodes) === null || _b === void 0 ? void 0 : _b.includes('main'))) {
        if ((i === null || i === void 0 ? void 0 : i.release_status) === 'completed') {
            return constants_1.ContentAirStatus.AIR_STATUS_AIRED;
        }
        else if ((i === null || i === void 0 ? void 0 : i.release_status) === 'on-air') {
            return constants_1.ContentAirStatus.AIR_STATUS_ON_AIR;
        }
        return constants_1.ContentAirStatus.AIR_STATUS_COMING_SOON;
    }
    if ((_c = i === null || i === void 0 ? void 0 : i.kinds_of_episodes) === null || _c === void 0 ? void 0 : _c.includes('trailer')) {
        return constants_1.ContentAirStatus.AIR_STATUS_NOT_AIRED;
    }
    return constants_1.ContentAirStatus.AIR_STATUS_COMING_SOON;
};
exports.getProgramAirStatus = getProgramAirStatus;
var getBadgeSerializer = function (i) {
    var _a;
    var normalized = i ? [i.top_right, i.top_left, i.bottom_right, i.bottom_left].join(',') : '';
    var isPPV = normalized.includes('content-ppv');
    var is24hFree = normalized.includes('content-24hrs-free');
    var isPremium = isPPV || is24hFree || normalized.includes('content-premium');
    var restricted = normalized.includes('age-rating-adult');
    var isNewRelease = normalized.includes('content-new-episode');
    var topIndex = (parseInt((_a = normalized.match('popular-top-([0-9]+)')) === null || _a === void 0 ? void 0 : _a[1], 10) || 0) - 1;
    var getPaymentType = function () { return (isPPV ? 'tvod' : isPremium ? 'svod' : 'free'); };
    var getPlayableTime = function () {
        var desired = is24hFree ? Date.now() - 60000 : Date.now() - 86400000;
        return new Date(desired).toISOString();
    };
    var asVimaiBadges = function () {
        var badges = [];
        if (is24hFree) {
            badges.push('content-temporary-free');
        }
        else if (isPPV) {
            badges.push('content-ppv');
        }
        else if (isPremium) {
            badges.push('content-premium');
        }
        if (isNewRelease) {
            badges.push('content-new-release');
        }
        if (restricted) {
            badges.push('content-adult');
        }
        if (topIndex > -1) {
            badges.push("content-top-".concat(topIndex + 1));
        }
        return badges;
    };
    return {
        isPremium: isPremium,
        is24hFree: is24hFree,
        restricted: restricted,
        topIndex: topIndex,
        isNewRelease: isNewRelease,
        isPPV: isPPV,
        getPaymentType: getPaymentType,
        getPlayableTime: getPlayableTime,
        asVimaiBadges: asVimaiBadges,
    };
};
exports.getBadgeSerializer = getBadgeSerializer;
var filterSupportedTextTrack = function (track) {
    return track.codec === 'vtt';
};
exports.filterSupportedTextTrack = filterSupportedTextTrack;
