import React, { useEffect, useRef } from 'react';

function getImageLoaderObserver(ioOptions = {}) {
  const { threshold = 0, rootMargin = '50%' } = ioOptions;

  // cache key is dependent of io args
  const CACHE_KEY = `__LAZY_IMAGE_IO_${JSON.stringify(ioOptions)}`;

  if (typeof IntersectionObserver !== 'function') {
    return null;
  }

  // return the cached observer for performance
  if (typeof window[CACHE_KEY] !== 'undefined') {
    return window[CACHE_KEY];
  }

  // create a new observer and cache it on the window
  window[CACHE_KEY] = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const img = entry.target;
          const dataSrc = img.getAttribute('data-src');
          if (dataSrc) {
            const parent = img.parentNode;
            if (parent.nodeName && parent.nodeName === 'PICTURE') {
              // handles special <picture><source> case
              Array.from(parent.getElementsByTagName('source')).forEach(source => {
                const dataSrcSet = source.getAttribute('data-srcset');
                if (!img.hasAttribute('srcset') && dataSrcSet) {
                  source.setAttribute('srcset', dataSrcSet);
                }
              });
            }
            if (!img.hasAttribute('src') || dataSrc !== img.getAttribute('src')) {
              img.setAttribute('src', dataSrc);
              img.setAttribute('data-src', '');
            }
          }
        }
      });
    },
    {
      ...ioOptions,
      rootMargin,
      threshold,
    },
  );

  return window[CACHE_KEY];
}
const LazyImage = ({
  className,
  src,
  alt,
  ioOptions = {},
  errorSrc,
  onError,
  onClick,
  style,
  isBlack = false,
  ...props
}) => {
  const imgRef = useRef(null);
  const { onLoad = () => {} } = props;
  const handleError = () => {
    // manually attach fallback src to img on Error
    if (imgRef.current && errorSrc) {
      imgRef.current.src = errorSrc;
      onError && onError();
    }
  };

  const isDefaultImage = (imageUrl = '') => {
    return imageUrl.match(/\/default.+\.jpeg$/);
  };

  useEffect(() => {
    const observer = getImageLoaderObserver(ioOptions);
    const target = imgRef.current;
    if (observer && target) {
      observer.observe(target);
    }
    return () => {
      if (observer && target) {
        observer.unobserve(target);
      }
    };
  }, [src]);

  return (
    <img
      className={className}
      data-src={src}
      ref={imgRef}
      onError={handleError}
      alt={alt}
      onClick={onClick}
      style={isBlack && isDefaultImage(src) ? { ...style, filter: 'brightness(0)' } : style}
      onLoad={() => onLoad && onLoad(false)}
    />
  );
};

export default LazyImage;
