import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { registerFB } from '../../../../auth/services';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import * as APIString from '../../../../../constants/apiString';
import loginFormId from '../../../../../constants/ids/loginFormId';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import pushAnalytics from '../../../../../services/analytics/Analytics';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { getStepPayperview } from '~features/payperview/functions';
import { getConfigByKey } from '~features/tenantConfig/services';

class SignUpFb extends Component {
  static propTypes = {
    dispatchRegisterFB: PropTypes.func,
    param: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  _loginFb(renderProps) {
    let itemName = 'Sign Up With Facebook';
    if (this.props.signIn) {
      itemName = 'Sign In With Facebook';
    }
    pushAnalytics('click', { content_type: 'button', item_name: itemName });
    renderProps.onClick();
  }

  _responseFacebook(response) {
    const { language, location, history, planSelected, popup, setHideRegister, setStep } =
      this.props;
    if (response.status == 'unknown') {
      return;
    }
    const mac_address = '';
    const model = '';
    const device_id = '';
    const platform = 'web';
    const token = response.accessToken.toString();

    this.props
      .dispatchRegisterFB(token, platform, model, device_id, mac_address)
      .then(res => {
        if (popup && setHideRegister) {
          setHideRegister(true);
          return getStepPayperview(2, this.props.param.props, setStep);
        }
        if (this.props.signIn) {
          pushAnalytics('login', { method: 'facebook' });
        } else {
          pushAnalytics('sign_up', { method: 'facebook' });
        }
        setTimeout(() => {
          this.setState({
            isLoading: false,
            resMessage: '',
            showAlert: false,
          });
        }, 1000);
        if (planSelected && planSelected.upgrade.login)
          return history.push(`/${language}/box/confirm`, {
            upgrade: planSelected.upgrade,
          });
        if (location.state.session)
          history.push(`/${language}/box/payment${location.search}`, {
            session: true,
          });
        else history.push(`/${language}/box/offers`);
      })
      .catch(error => {
        this.setState({
          isLoading: false,
          resMessage: error.message,
          showAlert: true,
        });
      });
  }

  render() {
    const { t, signIn } = this.props;
    if (!getConfigByKey('features.loginfb')) return null;
    return (
      <React.Fragment>
        <FacebookLogin
          appId={APIString.FACEBOOK_APP_ID}
          callback={response => this._responseFacebook(response)}
          render={renderProps => (
            <Button
              data-testid={loginFormId.continueWithFacebookBtn}
              onClick={() => this._loginFb(renderProps)}
              className="v-login--btn v-login--btn-fb"
              bsSize="large"
            >
              <i className="fa fa-facebook-square v-login--iconfb" aria-hidden="true"></i>
              <span>
                {signIn
                  ? t('loginScreen.buttonTextSignInFacebook')
                  : t('loginScreen.buttonTextSignUpFacebook')}
              </span>
            </Button>
          )}
        />
      </React.Fragment>
    );
  }
}

SignUpFb.defaultProps = {
  param: null,
};

const mapDispatchToProps = {
  dispatchRegisterFB: (token, platform, model, device_id, mac_address) =>
    registerFB(token, platform, model, device_id, mac_address),
};

const mapStateToProps = state => ({
  language: state.root.language,
  planSelected: state.box.form.supcriptionPlan,
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SignUpFb)),
);
