import * as homeActions from '~features/homepage/actions';
import * as youtubeActions from '~features/youtubePage/actions';
import * as liveActions from '~features/live-schedule/actions';
import queryString from 'query-string';
import { setNavigationContents } from '~components/global/globalServices';
import _ from 'lodash';
import i18n from 'i18next';
import * as filterApi from '../../api/filter';
import * as filterActions from './filterSlice';
import * as metaTagActions from '~components/metaTags/actions';
import { unique } from '~root/utils/utils';
import * as actions from '~components/global/globalSlice';
import { setNewNamePage } from '~components/global/globalSlice';
import { getHomePageSelector, getFilterDataSelector } from './selector';
import { isNode } from '~utils/ssr';
import { getConfigFromEnv } from '~constants/tenantConfig/tenantEnvConfig';

let homePageSSR = i18n.language === getConfigFromEnv().currentLanguage;
let filterDataSSR = homePageSSR;

const getFilterDataSSR = (pageSlug, param = {}) => {
  if (!isNode()) {
    const filterData = getFilterDataSelector(window.__STATE__);
    if (!_.isEmpty(filterData) && filterDataSSR) {
      filterDataSSR = false;
      return Promise.resolve(filterData);
    }
  }

  return filterApi.getFilterData(pageSlug, param);
};

const getFilterData =
  (pageSlug, param = {}) =>
  dispatch =>
    new Promise((resolve, reject) => {
      getFilterDataSSR(pageSlug, param)
        .then(response => {
          dispatch(filterActions.setFilterData(response));
          resolve({
            ...response,
            pageSlug,
          });
        })
        .catch(error => {
          reject(error);
        });
    });

const setActiveIDs = activeIDs => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(filterActions.setActiveIDs(activeIDs));
    resolve(activeIDs);
  });

const resetActiveIDs = () => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(filterActions.resetActiveIDs());
    resolve();
  });

const getRibbonsSSR = params => {
  if (!isNode()) {
    const homePage = getHomePageSelector(window.__STATE__);
    if (!_.isEmpty(homePage) && homePageSSR) {
      homePageSSR = false;
      return Promise.resolve(homePage);
    }
  }

  return filterApi.getRibbons(params);
};

const getPage =
  (params = {}) =>
  dispatch => {
    dispatch(homeActions.getPageRequest());
    return new Promise((resolve, reject) => {
      const promises = [getRibbonsSSR(params), filterApi.getPersonalizedRibbons(params)];
      return Promise.allSettled(promises)
        .then(data => {
          let result = {};
          const queryParams = queryString.stringify(params);
          dispatch(setNewNamePage(data));
          data.map(values => {
            const { value } = values;
            if (!value || (value && values.reason)) return;
            if (typeof value === 'object' && value !== null) {
              result = {
                ...result,
                display_style: value.display_style,
                name: value.name,
              };
            }
            Object.keys(value).forEach(keyValue => {
              if (Array.isArray(value[keyValue])) {
                result = {
                  ...result,
                  [keyValue]: [...value[keyValue], ...(result[keyValue] ? result[keyValue] : [])],
                };
              } else if (typeof value[keyValue] === 'object') {
                result = {
                  ...result,
                  [keyValue]: {
                    ...value[keyValue],
                    ...(result[keyValue] ? result[keyValue] : {}),
                  },
                };
              }
            });
          });
          const sortOdr = sortData(result.ribbons);
          result = {
            ...result,
            ribbons: sortOdr,
            search: queryParams,
            name: result.name || '',
          };
          dispatch(homeActions.updateSlugPage(params.slug));
          dispatch(
            actions.setTargerPlayer(
              result.page_options && result.page_options.content_navigation_option,
            ),
          );
          if (result.display_style !== 'YOUTUBE') {
            dispatch(metaTagActions.updateSeo(result.seo || {}));
          }
          if (result.display_style === 'YOUTUBE') {
            dispatch(youtubeActions.updatePageYoutube(result));
          }
          if (result.display_style === 'LIVE') {
            dispatch(liveActions.updateLivePage(result));
          } else {
            dispatch(
              homeActions.getPage({
                ...result,
                pageSlug: params.slug,
              }),
            );
          }
          resolve(result);
        })
        .catch(error => {
          const param = {
            banners: [],
            ribbons: [],
            search: '',
            name: '',
          };
          dispatch(homeActions.getPage(param));
          dispatch(youtubeActions.updatePageYoutube(param));
          dispatch(liveActions.updateLivePage(param));
          reject(error);
        })
        .finally(() => {
          dispatch(homeActions.getFinal());
        });
    });
  };

const updateSearch =
  (params = {}) =>
  dispatch =>
    new Promise((resolve, reject) => {
      const queryParams = queryString.stringify(params);
      // dispatch(homeActions.updateSeach(queryParams))
      dispatch(youtubeActions.updateSeach(queryParams));
      resolve();
    });

const getPersonalizedRibbons = (params = {}, data) => {
  if (!data.page) {
    return getPage(params);
  }
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch(homeActions.getPageRequest());
      filterApi
        .getPersonalizedRibbons(params)
        .then(result => {
          if (!data.page) {
            dispatch(homeActions.updateRibbons([...result.ribbons]));
            return resolve();
          }
          const { ribbons } = data.page;
          const tmpRibbons = [...ribbons, ...result.ribbons];
          const sortOdr = sortData(tmpRibbons);
          dispatch(homeActions.updateRibbons([...sortOdr]));
          resolve();
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          dispatch(homeActions.getFinal());
        });
    });
};

const sortData = ribbons => {
  const ribbonTmp = unique(ribbons).sort((a, b) => {
    // if (a.type === 5 || b.type === 5) return
    if (a.odr > b.odr) return 1;
    return -1;
  });
  return ribbonTmp;
};

export {
  getFilterData,
  setActiveIDs,
  getPage,
  resetActiveIDs,
  updateSearch,
  getPersonalizedRibbons,
};
