import React from 'react';
import { ModalStyled } from './styled';
import { useTranslation } from 'react-i18next';
import { Modal, Col, Row, Button } from 'react-bootstrap';
import loginFormId from '~constants/ids/loginFormId';
import { getConfigByKey } from '~features/tenantConfig/services';
import LoginGoogle from './loginGoogle';
import LoginFaceBook from './loginFacebook';
import LoginEmail from './loginEmail';
import OverlayLoading from '~components/loading/overlayLoading';
import CloseIcon from '@material-ui/icons/Close';
import classnames from 'classnames';

const LoginOdv = React.memo(props => {
  const component = props.props;
  const { checkYourPaidAccount, hiddenModalLogin } = props;
  const { param, modalShow, hideModal } = component;
  const { isLoading } = props.state;
  const { t } = useTranslation();
  return (
    <ModalStyled
      show={modalShow}
      onHide={() => hiddenModalLogin()}
      aria-labelledby="contained-modal-title-vcenter"
      className="v-loginForm"
    >
      <Modal.Header>
        <CloseIcon className="v-loginForm--close" onClick={() => hiddenModalLogin()} />
      </Modal.Header>
      <Modal.Body>
        {isLoading && <OverlayLoading />}
        <Row className="v-loginForm--row">
          <Col sm={12} className="v-loginForm--header">
            <p data-testid={loginFormId.title} className="v-loginForm--title">
              {t('loginScreen.title')}
            </p>
            <span className="v-loginForm--odm">{getConfigByKey('site_name')}</span>
            <LoginFaceBook {...props} />
            <LoginGoogle
              checkfollowPayperviews={(param, account) => checkYourPaidAccount(param, account)}
              PpvParams={param}
              target="login"
            />
          </Col>
          <div
            className={classnames(
              'v-loginForm--or',
              !getConfigByKey('features.loginfb') &&
                !getConfigByKey('features.loginGoogle') &&
                'hide',
            )}
          >
            {t('loginScreen.Or')}
          </div>
          <Col sm={12}>
            <LoginEmail {...props} />
            <div className="v-loginForm--signUp">
              <p data-testid={loginFormId.doNotHaveAnAccountLbl}>
                {t('loginScreen.textNotAccount')}
              </p>
              <Button
                data-testid={loginFormId.registerBtn}
                onClick={() => {
                  props._hrefSupcription();
                  hideModal('showLoginModal');
                }}
                className="v-loginForm--signUp__btn"
                bsSize="large"
              >
                {t('loginScreen.buttonTextRegis')}
              </Button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </ModalStyled>
  );
});

export default LoginOdv;
