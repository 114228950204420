import { IAxiosResponseData } from '~core/models';
import { apiAdapter, config, axiosTakeDataFromResponse } from '~share';
import {
  ConfigApiInterface,
  MultitenancyApiInterface,
  PaymentApiInterface,
} from '~vimai-api-adapter';

const { configService, multitenancyService, paymentService } = apiAdapter;

function getMuxEnvKey(): Promise<IAxiosResponseData<MultitenancyApiInterface['getMuxConfig']>> {
  const { tenantSlug = '', authorization } = config;
  return multitenancyService
    .getMuxConfig(tenantSlug, authorization)
    .then(axiosTakeDataFromResponse);
}

function getRegionSubcription(): Promise<
  IAxiosResponseData<PaymentApiInterface['checkCountryAllowedSubscription']>
> {
  const { tenantSlug = '', authorization } = config;
  return paymentService
    .checkCountryAllowedSubscription(tenantSlug, authorization)
    .then(axiosTakeDataFromResponse);
}

function getTenantPageTemplate(): Promise<
  IAxiosResponseData<ConfigApiInterface['getPlatformConfig']>
> {
  const select = JSON.stringify({
    PlatformConfig: ['tenant_pages', 'ads_banners'],
  });
  const { tenantSlug = '', platformSlug = '' } = config;
  return configService
    .getPlatformConfig(platformSlug, tenantSlug, select)
    .then(axiosTakeDataFromResponse);
}

export { getMuxEnvKey, getRegionSubcription, getTenantPageTemplate };
