import React from 'react';
import OpenCloseChatLive from './OpenCloseChatLive';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ButtonChangeNickname } from './changeNickname';
import { connect } from 'react-redux';

const HeaderChatLiveStyled = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  padding: 18px;
  box-shadow: 0px 0.5px 0px #414141;
  margin-bottom: 2px;
  button {
    padding: 0;
  }
  .title {
    flex-grow: 1;
    justify-content: center;
    font-family: 'Neue Haas Grotesk Display Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 133.5%;
    /* or 17px */

    text-align: center;
    letter-spacing: 0.025em;
    text-transform: uppercase;

    /* Dark/gray/0 */

    color: #ffffff;
    @media (max-width: 768px) {
      font-size: 21px;
    }
  }
`;

function HeaderChatLive(props) {
  const { t } = useTranslation();
  const { account, isDesktopOrLaptop } = props;
  return (
    <HeaderChatLiveStyled className="chat-live__header">
      <OpenCloseChatLive isDesktopOrLaptop={isDesktopOrLaptop} isToggle={false} />
      <span className="title">{t('projectw__Livechat.title')}</span>
      {account && <ButtonChangeNickname />}
    </HeaderChatLiveStyled>
  );
}

const mapStateToProps = state => ({
  account: state.auth.account,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderChatLive);
