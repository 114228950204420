import React from 'react';
import styled from 'styled-components';
import { timeFormatDurationHHMMSS } from '~core/method/contentMethod';
import moment from 'moment';
import { connect } from 'react-redux';
import { getCurrentTimeBE } from '~features/landingpage/services';
import _ from 'lodash';

const TimeLiveEvent = styled.div`
  font-family: 'Neue Haas Grotesk Display Pro';
  /* display: none; */
  position: absolute;
  bottom: 72px;
  z-index: 999;
  left: 10px;
  margin-bottom: calc(10px / var(--scaling));
  .timelive {
    color: #fff;
    font-size: 16px;
    line-height: 21.36px;
    font-weight: 400;
    letter-spacing: 0.025em;
    margin: 0;
    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
`;

function InLiveTime(props) {
  const { entity, player, dpGetTimeCurrent } = props;
  const { start_on = null } = entity;
  const [inLiveTime, setInLiveTime] = React.useState({
    duration: 0,
  });

  const getCurrentTimeBE = () => {
    if (_.isEmpty(entity && entity.id)) {
      return;
    }
    const contentId = entity.id;
    dpGetTimeCurrent(contentId)
      .then(res => {
        setInLiveTime({
          duration: res.progress,
        });
        player.currentTime(res.progress);
      })
      .catch(error => {});
  };
  React.useEffect(() => {
    getCurrentTimeBE();
  }, []);

  React.useEffect(() => {
    const setInTime = setInterval(() => {
      if (inLiveTime.duration === player.currentTime().toFixed(0)) {
        return;
      }
      setInLiveTime({
        duration: player.currentTime().toFixed(0),
      });
    }, 1000);
    return () => {
      clearInterval(setInTime);
    };
  }, [inLiveTime.duration]);

  return (
    <TimeLiveEvent classNam="inTimeLive">
      <p className="timelive">{timeFormatDurationHHMMSS.call(inLiveTime)}</p>
    </TimeLiveEvent>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  dpGetTimeCurrent: contentId => getCurrentTimeBE(contentId),
};

export default connect(mapStateToProps, mapDispatchToProps)(InLiveTime);
