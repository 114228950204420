import { getConfigByKey } from '~features/tenantConfig/services';
import { CURATED_LIVE, CHANNEL_TYPE } from './../constants';

export function hotkeysConfig(component, $body) {
  let originTrack = null;
  const entityDetail = component.entityDetail;
  const { isRewatchCuratedLive } = component.state;
  const { isPending } = component.props;
  if (
    (entityDetail.type === CURATED_LIVE || entityDetail.type === CHANNEL_TYPE) &&
    !isRewatchCuratedLive
  ) {
    return {
      volumeStep: 0.1,
      enableNumbers: false,
      enableModifiersForNumbers: false,
      enableVolumeScroll: false,
      playPauseKey: function (event, player) {
        return false;
      },
      rewindKey: function (event, player) {
        return false;
      },
      forwardKey: function (event, player) {
        return false;
      },
      customKeys: {
        keyC: {
          key: function (event) {
            return event.which === 67;
          },
          handler: function (player, options, event) {
            let tracks = player.textTracks();
            for (let i = 0; i < tracks.length; i++) {
              let track = tracks[i];
              if (originTrack === track.id) {
                track.mode = 'showing';
                originTrack = null;
                return;
              }
              if (track.mode === 'showing') {
                track.mode = 'hidden';
                originTrack = track.id;
                return;
              }
            }
            return tracks;
          },
        },
      },
    };
  }

  if (getConfigByKey('ui_theme') === 'projectw') {
    return {
      volumeStep: 0.1,
      seekStep: function () {
        if ($body.hasClass('vjs-live-event-after')) {
          return 5;
        }
        return 0;
      },
      forwardKey: function (event, player) {
        if (!$body.hasClass('vjs-live-event-after')) {
          return false;
        }
        return event.which === 39;
      },
      rewindKey: function (event, player) {
        if (!$body.hasClass('vjs-live-event-after')) {
          return false;
        }
        return event.which === 37;
      },
      enableModifiersForNumbers: false,
      enableMute: true,
      enableVolumeScroll: false,
      playPauseKey: function (event, player) {
        return false;
      },
      customKeys: {
        keyC: {
          key: function (event) {
            return event.which === 67;
          },
          handler: function (player, options, event) {
            let tracks = player.textTracks();
            for (let i = 0; i < tracks.length; i++) {
              let track = tracks[i];
              if (originTrack === track.id) {
                track.mode = 'showing';
                originTrack = null;
                return;
              }
              if (track.mode === 'showing') {
                track.mode = 'hidden';
                originTrack = track.id;
                return;
              }
            }
            return tracks;
          },
        },
        keyEnter: {
          key: function (e) {
            return e.which === 13;
          },
          handler: function (player, options, e) {
            if (isPending) {
              player.pause();
            }
            if (!isPending) {
              if (player.paused()) {
                player.play();
              } else {
                player.pause();
              }
            }
          },
        },
      },
    };
  }

  return {
    volumeStep: 0.1,
    seekStep: 5,
    enableModifiersForNumbers: false,
    enableMute: true,
    enableVolumeScroll: false,
    customKeys: {
      keyC: {
        key: function (event) {
          return event.which === 67;
        },
        handler: function (player, options, event) {
          let tracks = player.textTracks();
          for (let i = 0; i < tracks.length; i++) {
            let track = tracks[i];
            if (originTrack === track.id) {
              track.mode = 'showing';
              originTrack = null;
              return;
            }
            if (track.mode === 'showing') {
              track.mode = 'hidden';
              originTrack = track.id;
              return;
            }
          }
          return tracks;
        },
      },
      keyEnter: {
        key: function (e) {
          return e.which === 13;
        },
        handler: function (player, options, e) {
          if (player.paused()) {
            player.play();
          } else {
            player.pause();
          }
        },
      },
      keyLeftAndRight: {
        key: function (e) {
          return e.which === 37 && e.which === 39;
        },
        handler: function (player, options, e) {
          player.play();
        },
      },
    },
  };
}
