import React from 'react';
import { ModalStyled } from './styled';
import { Modal, Col, Row, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  setWatchNowClick,
  setLoginModalShow,
  setIsShowVerifyEmailModal,
} from '~components/global/globalSlice';
import SignInForm from '../projectw/SignInForm';
import VerifyEmailForm from '../projectw/VerifyEmailForm';
import WatchMoreModal from '../watchMoreModal';

function VerifyEmailModal(props) {
  const component = props.props;
  const { hiddenModalLogin, _resetState } = props;
  const { modalShow } = component;
  const dispatch = useDispatch();
  const isShowVerifyEmailModal = useSelector(state => state.global.isShowVerifyEmailModal);
  const isShowWelcome = useSelector(state => state.global.modal.isWelcome);

  const hiddenModalLogin_V2 = () => {
    dispatch(setLoginModalShow(true));
    dispatch(setIsShowVerifyEmailModal(false));
    hiddenModalLogin();
  };

  if (isShowWelcome) return <WatchMoreModal hiddenModalLogin_V2={hiddenModalLogin_V2} {...props} />;
  return (
    <ModalStyled
      show={isShowVerifyEmailModal}
      onHide={() => hiddenModalLogin_V2()}
      aria-labelledby="contained-modal-title-vcenter"
      className="v-loginForm project-w"
    >
      <i
        style={{ display: 'none' }}
        onClick={() => hiddenModalLogin_V2()}
        className="fa fa-times"
        id="close"
        aria-hidden="true"
      ></i>
      <Modal.Body>
        <VerifyEmailForm {...props} />
      </Modal.Body>
    </ModalStyled>
  );
}

export default VerifyEmailModal;
