import React from 'react';
import { MainTextComponent } from '~features/homepage/projectw/style/homepage';
import { Typography, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { SVG } from '~components';

function MainTextMobile() {
  return (
    <div className="MainComponent--Title-Text-Img">
      <div className="parent">
        <div className="parentContainer">
          <SVG src={require('~img/img/projectw/mobile/row-1.svg').default} />
        </div>
        {/* <span className="parentContainer">
          ILLUON is
          <span style={{ display: 'block' }}>
            an <span className="cenila-trial thin">Ever-changing</span> curation
          </span>
        </span> */}
      </div>
      <div className="parent">
        <div className="parentContainer">
          <SVG src={require('~img/img/projectw/mobile/row-2.svg').default} />
        </div>
        {/* <span className="parentContainer" style={{ display: 'block' }}>
          of captivating <span className="helvetica-neue">and</span>{' '}
          <span className="cenila-trial light">incredible</span>
        </span>{' '} */}
      </div>
      <div className="parent">
        <div className="parentContainer">
          <SVG src={require('~img/img/projectw/mobile/row-3.svg').default} />
        </div>
        {/* <span className="parentContainer">
          <span className="helvetica-neue"> stories</span> on{' '}
          <span className="cenila-trial regular">stage</span>
          <span className="helvetica-neue">.</span>
        </span> */}
      </div>
      <div className="parent">
        <div className="parentContainer">
          <SVG src={require('~img/img/projectw/mobile/row-4.svg').default} />
        </div>
        {/* <span className="parentContainer">
          <span className="helvetica-neue"> stories</span> on{' '}
          <span className="cenila-trial regular">stage</span>
          <span className="helvetica-neue">.</span>
        </span> */}
      </div>
    </div>
  );
}

function MainTextDesktop() {
  return (
    <div id="MainText" className="MainComponent--Title-Text-Img">
      <div className="parent">
        {/* <span className="parentContainer">
          ILLUON is an <span className="cenila-trial thin">Ever-changing</span> curation
        </span> */}
        <div className="parentContainer">
          <SVG src={require('~img/img/projectw/desktop/row-1.svg').default} />
        </div>
      </div>
      <div className="parent eleSecond">
        {/* <span className="parentContainer eleSecond" style={{ display: 'block' }}>
          of captivating <span className="helvetica-neue">and</span>{' '}
          <span className="cenila-trial light">incredible</span>
        </span>{' '} */}
        <div className="parentContainer eleSecond">
          <SVG src={require('~img/img/projectw/desktop/row-2.svg').default} />
        </div>
      </div>
      <div className="parent eleLast">
        {/* <span className="parentContainer eleLast">
          <span className="helvetica-neue"> stories</span>{' '}
          <span style={{ fontSize: '55px' }}>on</span>{' '}
          <span className="cenila-trial regular">stage</span>
          <span className="helvetica-neue">.</span>
        </span> */}
        <div className="parentContainer eleLast">
          <SVG src={require('~img/img/projectw/desktop/row-3.svg').default} />
        </div>
      </div>
    </div>
  );
}

function MainText() {
  const isDeskTopDetect = useMediaQuery({
    query: '(min-width: 768px)',
  });
  const isHomePage = useSelector(state => state.global.page.isHomePage);
  if (!isHomePage) return null;
  return (
    <MainTextComponent className="main-text">
      <div id="MainComponent--Title" className="MainComponent--Title">
        <Typography component="div" className="MainComponent--Title-Text">
          {isDeskTopDetect ? <MainTextDesktop /> : <MainTextMobile />}
        </Typography>
      </div>
    </MainTextComponent>
  );
}

export default MainText;
