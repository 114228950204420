import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import styled from 'styled-components';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import jquery from 'jquery';
import { _handleRewatchCuratedLive, getGapContent } from '../function/initCuratedLive';

class CoolDownLive extends Component {
  constructor(props) {
    super(props);
    this.timer = 0;
    this.gapDuration = 0;
    this.changeAdTag = false;
    this.state = {
      time: {},
      seconds: 0,
      isDisplay: true,
      nextEpg: true,
      gapContent: 0,
    };
  }

  componentDidMount() {
    const { component } = this.props;
    const { next_content, current_live_cursor, id } = component.entityDetail;
    if (next_content) {
      this.handelGetGapContent();
      this.handelSetSecond(next_content, current_live_cursor);
    }
    if (current_live_cursor && !next_content) {
      this.handelGetGapContent(true);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  handelGetGapContent = (lastEpgs = null) => {
    const { player, component } = this.props;
    const { isRewatchCuratedLive } = component.state;
    const { current_live_cursor } = component.entityDetail;
    player.on('ended', () => {
      if (lastEpgs) {
        const nowTime = moment();
        const endTime = moment.unix(current_live_cursor.end_at);
        const duration = moment.duration(endTime.diff(nowTime));
        this.gapDuration = duration.asSeconds();
      }
      if (!isRewatchCuratedLive) {
        component._setDuration(-1);
        getGapContent(component, this.gapDuration);
      }
    });
  };

  handelSetSecond = (next_content, current_live_cursor) => {
    if ((!next_content && !current_live_cursor) || (current_live_cursor && !next_content)) {
      return this.setState({
        isDisplay: false,
      });
    }
    const nowTime = moment();
    let endTime = moment.unix(next_content.start_at);
    let duration = moment.duration(endTime.diff(nowTime));
    if (current_live_cursor && next_content) {
      const { play_date, end_at } = current_live_cursor;
      const dayStart = moment(play_date).unix();
      const dayNextStart = moment(next_content.play_date).unix();
      let newDuration = 0;
      endTime = moment.unix(end_at);
      if (dayNextStart > dayStart) {
        this.setState({
          nextEpg: false,
        });
        newDuration = moment.duration(endTime.diff(nowTime));
        if (newDuration.asSeconds() > 0) {
          duration = newDuration;
        }
      }
    }
    this.setState(
      {
        seconds: duration.asSeconds(),
      },
      () => {
        this.handelCooldown(next_content, current_live_cursor);
      },
    );
  };

  handelCooldown = (next_content, current_live_cursor) => {
    const { seconds } = this.state;
    const timeLeftVar = this.secondsToTime(seconds);
    this.setState(
      {
        time: timeLeftVar,
      },
      () => {
        this.startTimer(next_content, current_live_cursor);
      },
    );
  };

  secondsToTime(secs) {
    const hours = Math.floor(secs / (60 * 60));

    const divisor_for_minutes = secs % (60 * 60);
    const minutes = Math.floor(divisor_for_minutes / 60);

    const divisor_for_seconds = divisor_for_minutes % 60;
    const seconds = Math.ceil(divisor_for_seconds);

    const obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  startTimer(next_content, current_live_cursor) {
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      this.countDown(next_content, current_live_cursor);
    }, 1000);
  }

  countDown(next_content, current_live_cursor) {
    const { component } = this.props;
    const { player } = this.props;
    const $player = jquery(player.el_);
    const { seconds, nextEpg } = this.state;
    player.one('timeupdate', event => {
      if (player.currentTime() < player.duration()) {
        $player.find('.vjs-cooldown-display').removeClass('vjs-cooldown-open');
      }
    });
    const tmp = seconds - 1;
    this.gapDuration = tmp;
    this.setState(
      {
        time: this.secondsToTime(tmp),
        seconds: parseInt(tmp),
      },
      () => {
        if (seconds === 0) {
          component.isDisplayEPG.el().remove();
          component.isDisplayEPG = component.videoPlayer.addChild('EPGList', {
            component,
            channelId: component.entityDetail.id,
            isRender: true,
          });
          clearInterval(this.timer);
          if ($player.hasClass('vjs-rewatching')) {
            return component
              ._promiseGetCuratedLive(null, component.entityDetail)
              .then(res => {
                const { next_content, current_live_cursor } = res;
                component.entityDetail = { ...component.entityDetail, ...res };
                this.handelSetSecond(next_content, current_live_cursor);
              })
              .catch(err => {});
          }
          if (nextEpg) {
            $player.find('.vjs-cooldown-display').removeClass('vjs-cooldown-open');
            _handleRewatchCuratedLive(component, next_content, true);
          } else {
            $player.find('.vjs-cooldown-display').addClass('vjs-cooldown-open');
            $player.addClass('.vjs-none-linkplay');
            player.pause();
            player.currentTime(current_live_cursor.real_duration);
            this.setState({
              nextEpg: true,
            });
            this.handelSetSecond(next_content, current_live_cursor);
          }
        }
      },
    );
  }

  render() {
    const { next_content } = this.props.component.entityDetail;
    const { time, isDisplay } = this.state;
    const { t } = this.props;
    if (!isDisplay || time.s < 0) {
      return null;
    }
    return (
      <PanelStyled className="v-cooldown">
        <div className="v-cooldown--body">
          <img
            className="v-cooldown--img"
            alt="cooldown"
            src={require('../../../assets/img/backgroundCooldown.png').default}
          />
          <div className="v-cooldown--description">
            <p className="v-cooldown--label">{t('cooldown.program')}</p>
            <p className="v-cooldown--name">
              {t('cooldown.nameEpg', {
                name: next_content && next_content.title,
              })}
            </p>
            <p className="v-cooldown--timer">
              {t('cooldown.timeStart')}
              <span>
                {time.h > 0 && `${time.h}${t('cooldown.hours')}`}{' '}
                {time.m >= 0 && `${time.m}${t('cooldown.minute')}`} {time.s}
                {t('cooldown.second')}{' '}
              </span>
            </p>
          </div>
        </div>
      </PanelStyled>
    );
  }
}

export const PanelStyled = styled(Panel)`
  &.v-cooldown {
    background: #000000;
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 1rem;
    color: #ffffff;
    border: none;
    z-index: 1;
  }
  .v-cooldown {
    &--body {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100% - 96px);
    }
    &--img {
      width: 375px;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      opacity: 0.2;
      left: -12.5rem;
      right: 0;
      text-align: center;
      transform: rotate(123deg);
    }
    &--description {
      max-width: 60%;
    }
    &--label {
      font-size: 1.125rem;
      font-weight: 100;
    }
    &--name {
      font-size: 1.875rem;
      font-weight: 600;
      line-height: 2.5rem;
    }
    &--timer {
      margin-top: 2rem;
      font-size: 1.125rem;
      font-weight: 100;
      font-style: italic;
      span {
        font-size: 1.875rem;
        font-weight: 600;
        font-style: normal;
      }
    }
  }
  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
  }
  @media (max-width: 1024px) and (orientation: landscape) {
  }
`;

export default withTranslation()(CoolDownLive);
