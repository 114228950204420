import React from 'react';
import PropTypes from 'prop-types';
import Ribbons from '../../styled';
import * as display from '../../constants';
import Loader from 'react-loader-spinner';

export default class LazyLoadRibbon extends React.Component {
  render() {
    const { ribbon } = this.props;
    return (
      <div className={`see-more`}>
        <Ribbons className="ribbonItemContainer" style={{ position: 'relative' }}>
          <div className={`imageWrapper ${display[`CLASS_${ribbon.display_type || 1}`]}`}>
            <div className="srcImg btn-see-more">
              <Loader type="Bars" color="#FFFFFF" height="30" width="30" />
            </div>
          </div>
        </Ribbons>
      </div>
    );
  }
}

LazyLoadRibbon.propTypes = {
  ribbon: PropTypes.object,
  displayLoad: PropTypes.bool,
};
