import { SEOBasic } from '~vimai-api-adapter';
import * as types from './actionTypes';

export { updateSeo };

function updateSeo(payload: SEOBasic) {
  return {
    type: types.UPDATE_SEO,
    payload,
  };
}
