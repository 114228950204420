import { getViews } from '~features/homepage/services';
import { controller } from '~utils/fetch';

export default async function getLinkToPlayer(id, method, isCanPlay = false) {
  try {
    method && method.setLoading(true);
    controller && controller.abort();
    const detailEps = await getViews(id);
    const { current_episode } = detailEps.current_season;
    if (
      current_episode &&
      (current_episode.can_preview || (!current_episode.can_preview && isCanPlay))
    ) {
      let episodeIndex = current_episode.episode_index;
      if (episodeIndex < 10) {
        episodeIndex = `0${episodeIndex}`;
      }
      let search = `?eps=${episodeIndex}`;
      let linkPlay = `/detail/${current_episode.slug}`;
      return {
        search,
        linkPlay,
      };
    }
  } catch (error) {
    return null;
  } finally {
    method && method.setLoading(false);
  }
}
