import videojs from 'video.js';
import jquery from 'jquery';

// Get the Component base class from Video.js
var Component = videojs.getComponent('Component');

// The videojs.extend function is used to assist with inheritance. In
// an ES6 environment, `class SeekNext extends Component` would work
// identically.

var TopTitle = videojs.extend(Component, {
  // The constructor of a component receives two arguments: the
  // player it will be associated with and an object of options.
  constructor: function (player, options) {
    // It is important to invoke the superclass before anything else,
    // to get all the features of components out of the box!
    Component.apply(this, arguments);
    // If a `text` option was passed in, update the text content of
    // the component.
    if (options.text) {
      this.updateTextContent(options);
    }
  },

  // The `createEl` function of a component creates its DOM element.
  createEl: function () {
    const btn = videojs.dom.createEl('div', {
      className: 'vjs-top-title',
    });
    btn.innerHTML = `
      <p class="vjs-top-title--title js-vjs-top-title-title"></p>
      <p class="vjs-top-title--info js-vjs-top-title-info"></p>
    `;
    return btn;
  },

  handleClick: function (event) {
    console.log('top title click');
  },

  updateTextContent: function (options) {
    // If no text was provided, default to "Title Unknown"
    if (typeof options.text !== 'string') {
      options.text = 'Title Unknown';
    }
    const { currentEpisode, totalEpisodes, translations } = options;
    const $el = jquery(this.el());
    $el.find('.js-vjs-top-title-title').text(options.text);

    $el
      .find('.js-vjs-top-title-info')
      .text(`${translations.episode} ${currentEpisode}/${totalEpisodes}`);
  },
});

// Register the component with Video.js, so it can be used in players.
videojs.registerComponent('TopTitle', TopTitle);
