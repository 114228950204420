import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Checkbox, Button } from 'react-bootstrap';
import { verifyEmailFormat } from '../../../../../utils/utils';
import { registerEmail, loginEmail } from '../../../../auth/services';
import { changeProfile } from '~features/profile/services';
import moment from 'moment';
import registerFormId from '../../../../../constants/ids/registerFormId';
import { withTranslation } from 'react-i18next';
import TextField from '../../../../../components/Input/views';
import { RowStyled } from '../styles/style';
import Loadding from '../../../newComponent/loadding';
import SignUpFb from './signUpFb';
import TermOfUse from '../../../components/termOfUsePopup';
import pushAnalytics from '../../../../../services/analytics/Analytics';
import { withRouter } from 'react-router';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getConfigByKey } from '~features/tenantConfig/services';
import { getStepPayperview } from '~features/payperview/functions';
import PrivacyPolicy from '~features/box/components/privacyPolicyPopup';
import LoginGoogle from '~features/auth/components/login/loginGoogle';
import classnames from 'classnames';
import Birthday from '~components/birthdaySelect';
import { SelectGenres } from '../styles/styledSelect';

const validateFieldByTenant = {
  dob: ['odv', 'escondido'],
};

const tenantSlug = getConfigByKey('tenant_slug');
const privacyPolicyLink = getConfigByKey('privacyPolicyLink');
const termsOfUseLink = getConfigByKey('termsOfUseLink');

class Register extends Component {
  static propTypes = {
    modalShow: PropTypes.bool,
    showLoginAction: PropTypes.func,
    hideModal: PropTypes.func,
    showModal: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.birthdayRef = React.createRef();
    this.state = {
      isLoading: false,
      resMessage: '',
      email: '',
      password: '',
      firstname: '',
      lastname: '',
      phone: '',
      birthday: null,
      gender: null,
      termOfUseModalShow: false,
      privacyPolicyModalShow: false,
      repassword: '',
      btnDisabled: false,
      maxDay: null,
      minDay: null,
    };
    this.errorDob = false;
  }
  componentWillMount() {
    const { account, planSelected, location, history } = this.props;

    if (account && planSelected) {
      return history.push(`/${this.props.language}/box/payment${location.search}`);
    }
  }

  componentDidMount() {
    const { account, planSelected } = this.props;
    this.setState({
      maxDay: moment().subtract(13, 'years').format('YYYY-MM-DD'),
      minDay: moment().subtract(100, 'years').format('YYYY-MM-DD'),
    });
    if (account && !planSelected) {
      return (window.location.href = `/${this.props.language}/box/offers`);
    }
  }

  _acceptPolicy(e) {
    this.setState({
      btnDisabled: !e.target.checked,
    });
  }

  _onChangeText(key, e) {
    this.setState({
      resMessage: '',
    });
    const { t } = this.props;
    let value = '';
    if (key === 'birthday') {
      value = e;
    } else if (key === 'phone') {
      value = e.target.value.replace(/[a-zA-Z/.=;:'"/?><\|{}]/, '');
    } else if (key === 'gender') {
      value = e.value;
    } else {
      value = e.target.value;
    }
    this.setState({
      [key]: value,
    });
  }
  _onKeyDownEnter(e, key = null) {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (!this.state.btnDisabled) this._signUp();
    }
  }

  _checkNull() {
    const { email, firstname, password, gender, birthday, btnDisabled, repassword } = this.state;
    if (!email || !firstname || !password || !gender || !birthday || btnDisabled || !repassword)
      return false;
    return true;
  }

  _signUp() {
    const { email, firstname, lastname, phone, password, gender, birthday, repassword } =
      this.state;
    const { t, guestAccount, dispatchUpdateProfile } = this.props;
    pushAnalytics('click', { content_type: 'button', method: 'Sign Up' });

    if (
      !email ||
      !firstname ||
      // !lastname ||
      !password ||
      !gender ||
      !birthday
    ) {
      this.setState({
        isLoading: false,
        resMessage: t('registerScreen.alertNull'),
        showAlert: true,
      });
      return;
    }
    if (firstname.length < 2) {
      this.setState({
        isLoading: false,
        resMessage: t('changeInformation.alert.nullFirstName'),
        showAlert: true,
      });
      return;
    }

    // if (lastname.length < 2) {
    //   this.setState({
    //     isLoading: false,
    //     resMessage: t('changeInformation.alert.nullLastName'),
    //     showAlert: true,
    //   })
    //   return
    // }

    if (!verifyEmailFormat(email)) {
      this.setState({
        isLoading: false,
        resMessage: t('changeInformation.alert.validEmail'),
        showAlert: true,
      });
      return;
    }

    if (password.length < 6) {
      this.setState({
        isLoading: false,
        resMessage: t('forgotPasswordScreen.alert.valid'),
        showAlert: true,
      });
      return;
    }
    if (password != repassword) {
      this.setState({
        isLoading: false,
        resMessage: t('forgotPasswordScreen.alert.wrongs'),
        showAlert: true,
      });
      return;
    }

    if (this.errorDob) {
      this.setState({
        isLoading: false,
        resMessage: t('changeInformation.alert.validbod'),
        showAlert: true,
      });
      return;
    }
    if (!gender) {
      this.setState({
        isLoading: false,
        resMessage: t('changeInformation.alert.nullgender'),
        showAlert: true,
      });
      return;
    }
    const dob = moment(birthday, 'YYYY-MM-DD').format(`YYYY-MM-DD`);

    this.setState(
      {
        isLoading: true,
        showAlert: false,
        resMessage: '',
      },
      () => {
        let params = {
          email,
          firstName: firstname,
          lastName: lastname,
          phoneNumber: phone,
          password,
          gender,
          dob,
        };
        if (guestAccount) {
          return dispatchUpdateProfile(params)
            .then(res => {
              pushAnalytics('sign_up', { method: 'email' });
              this._loginEmail(email, password);
              if (typeof Storage !== 'undefined') localStorage.removeItem('email');
            })
            .catch(error => {
              setTimeout(() => {
                this.setState({
                  isLoading: false,
                  resMessage: error.message,
                  showAlert: true,
                });
              }, 1000);
            });
        }
        this.props
          .dispatchRegisterEmail(params)
          .then(res => {
            pushAnalytics('sign_up', { method: 'email' });
            this._loginEmail(email, password);
            if (typeof Storage !== 'undefined') localStorage.removeItem('email');
          })
          .catch(error => {
            setTimeout(() => {
              this.setState({
                isLoading: false,
                resMessage: error.message,
                showAlert: true,
              });
            }, 1000);
          });
      },
    );
  }

  _signIn() {
    const { location, history, popup, showLoginAction, setHideRegister } = this.props;
    if (popup && setHideRegister) {
      let param = {
        ...this.props.param,
        method: { ...this.props.param.method, setHideRegister },
      };
      setHideRegister(true);
      return showLoginAction(param);
    }
    pushAnalytics('click', {
      content_type: 'button',
      item_name: 'Goto Sign In',
    });
    history.push(`/${this.props.language}/box/regform${location.search}`, {
      session: true,
    });
  }

  _loginEmail(email, password) {
    const { popup, setHideRegister, setStep } = this.props;
    this.props
      .dispatchLoginEmail(email, password)
      .then(res => {
        if (popup && setHideRegister) {
          setHideRegister(true);
          return getStepPayperview(2, this.props.param.props, setStep);
        }
        this.checkPermission(res);
      })
      .catch(error => {
        setTimeout(() => {
          this.setState({
            isLoading: false,
            resMessage: error.message,
            showAlert: true,
          });
        }, 1000);
      });
  }
  _handelErrorDob(error, values) {
    if (error) {
      this.errorDob = true;
    } else {
      this.errorDob = false;
    }
  }
  checkPermission(account) {
    const { history, planSelected, location } = this.props;
    if (planSelected && planSelected.upgrade.login) {
      if (account.profile.has_subscription) return history.push(`/${this.props.language}/`);
      history.push(`/${this.props.language}/box/confirm`, {
        upgrade: planSelected.upgrade,
      });
    } else {
      if (account.profile.has_subscription) history.push(`/${this.props.language}/profile`);
      else if (!planSelected) history.push(`/${this.props.language}/box/offers`);
      else
        history.push(`/${this.props.language}/box/payment${location.search}`, {
          session: true,
        });
      this.setState({
        isLoading: false,
        resMessage: '',
        showAlert: false,
      });
    }
  }

  _renderBirthday() {
    const { t } = this.props;
    const { birthday, minDay, maxDay } = this.state;
    let formarDate = t('formatDob').toUpperCase();
    return (
      <div style={{ position: 'relative', color: '#b3bec4' }}>
        <Birthday dob={birthday} setDob={value => this.setState({ birthday: value })} />
        {validateFieldByTenant.dob.includes(tenantSlug) && (
          <div className="v-login--age">{t('registerScreen.msgAgeWarning')}</div>
        )}
      </div>
    );
    return (
      <div style={{ position: 'relative', color: '#b3bec4' }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            value={birthday}
            format={t('formatDob')}
            inputVariant="filled"
            label={t('registerScreen.lblBirthday') + '( ' + formarDate + ' )'}
            className="v-login--dob"
            invalidDateMessage={t('changeInformation.alert.validbod')}
            maxDateMessage={t('registerScreen.msgAgeWarning')}
            minDateMessage={t('changeInformation.alert.validbod')}
            onChange={date => this._onChangeText('birthday', date)}
            onKeyDown={e => this._onKeyDownEnter(e, 'birthday')}
            minDate={minDay}
            maxDate={maxDay}
            onError={(error, values) => this._handelErrorDob(error, values)}
          />
        </MuiPickersUtilsProvider>
        {validateFieldByTenant.dob.includes(tenantSlug) && (
          <div className="v-login--age">{t('registerScreen.msgAgeWarning')}</div>
        )}
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const {
      email,
      phone,
      password,
      firstname,
      repassword,
      btnDisabled,
      showAlert,
      resMessage,
      termOfUseModalShow,
      privacyPolicyModalShow,
      isLoading,
      lastname,
    } = this.state;
    const GENDER = [
      { label: t('registerScreen.gender.male'), value: '1' },
      { label: t('registerScreen.gender.female'), value: '2' },
      { label: t('registerScreen.gender.underfind'), value: '0' },
    ];
    return (
      <React.Fragment>
        <Loadding isLoading={isLoading} />
        <RowStyled className="v-login">
          <TermOfUse
            modalShow={termOfUseModalShow}
            showModal={() => this.setState({ termOfUseModalShow: !termOfUseModalShow })}
          />
          <PrivacyPolicy
            modalShow={privacyPolicyModalShow}
            showModal={() => this.setState({ privacyPolicyModalShow: !privacyPolicyModalShow })}
          />
          <div className="v-login--row">
            <Col xs={12}>
              <h3 data-testid={registerFormId.title} className="v-box--title">
                {t('registerScreen.signUp')}
              </h3>
            </Col>
            <Col xs={12} className="v-login--form">
              <SignUpFb signUp={true} />
              <LoginGoogle
                followSubcriptions={account => this.checkPermission(account)}
                target="subcription"
              />
              <div
                className={classnames(
                  'v-login--or',
                  !getConfigByKey('features.loginfb') &&
                    !getConfigByKey('features.loginGoogle') &&
                    'hide',
                )}
              >
                <span className="txt">{t('loginScreen.Or')}</span>
              </div>
              <form onSubmit={() => this._signUp()} autoComplete="off">
                <TextField
                  data-testid={registerFormId.txtName}
                  variant="standard"
                  type="text"
                  label={<span>{t('profile.shortName')}</span>}
                  name={t('profile.shortName')}
                  value={firstname}
                  onChange={value => this._onChangeText('firstname', value)}
                  onKeyDown={e => this._onKeyDownEnter(e)}
                />
                {/* <TextField
                  data-testid={registerFormId.txtName}
                  variant="standard"
                  type="text"
                  label={
                    <span>
                      {t('changeInformation.lastName')}
                    </span>
                  }
                  name="lastName"
                  value={lastname}
                  onChange={value => this._onChangeText('lastname', value)}
                  onKeyDown={e => this._onKeyDownEnter(e)}
                /> */}
                <TextField
                  data-testid={registerFormId.emailTxt}
                  variant="standard"
                  type="text"
                  label={<span>{t('registerScreen.placeholderEmail')}</span>}
                  name="Email"
                  autocomplete="off"
                  value={email}
                  onChange={value => this._onChangeText('email', value)}
                  onKeyDown={e => this._onKeyDownEnter(e)}
                />
                {getConfigByKey('tenant_slug') === 'sctv' && (
                  <TextField
                    variant="standard"
                    type="text"
                    label={<span>{t('registerScreen.placeholderPhone')}</span>}
                    name={t('registerScreen.placeholderPhone')}
                    autocomplete="off"
                    value={phone}
                    onChange={value => this._onChangeText('phone', value)}
                    onKeyDown={e => this._onKeyDownEnter(e)}
                  />
                )}

                <TextField
                  data-testid={registerFormId.passwordTxt}
                  type="password"
                  variant="standard"
                  showPassWord={true}
                  label={<span>{t('registerScreen.placeholderPassword')}</span>}
                  autocomplete="new-password"
                  name={t('registerScreen.placeholderPassword')}
                  value={password}
                  onChange={value => this._onChangeText('password', value)}
                  onKeyDown={e => this._onKeyDownEnter(e)}
                />
                <TextField
                  data-testid={registerFormId.passwordTxt}
                  type="password"
                  showPassWord={true}
                  variant="standard"
                  label={<span>{t('registerScreen.txtRePassword')}</span>}
                  name={t('registerScreen.txtRePassword')}
                  value={repassword}
                  onChange={value => this._onChangeText('repassword', value)}
                  onKeyDown={e => this._onKeyDownEnter(e)}
                />
                <TextField
                  data-testid={registerFormId.passwordTxt}
                  type="text"
                  variant="dob"
                  label={<span>{t('registerScreen.lblBirthday')}</span>}
                  name={t('registerScreen.lblBirthday')}
                  className="v-login--select-dob"
                >
                  {this._renderBirthday()}
                </TextField>
                <div className="v-login--select">
                  <label>
                    <span>{t('registerScreen.placeholderGender')}</span>
                  </label>
                  <SelectGenres
                    classNamePrefix={'Select'}
                    className="select-input"
                    isSearchable={false}
                    options={GENDER}
                    // menuIsOpen={true}
                    onChange={value => this._onChangeText('gender', value)}
                    onKeyDown={e => this._onKeyDownEnter(e)}
                    placeholder={t('registerScreen.placeholderGender')}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    height: 'auto',
                    marginTop: 10,
                    textAlign: 'left',
                  }}
                >
                  {showAlert ? (
                    <h6
                      data-testid={registerFormId.errorMessageLbl}
                      style={{
                        color: !showAlert ? '#0095ff' : '#ff0000',
                        fontSize: 15,
                      }}
                    >
                      {resMessage}
                    </h6>
                  ) : null}
                </div>
                <Checkbox
                  onChange={e => this._acceptPolicy(e)}
                  checked={!btnDisabled}
                  className="checkbox-custom v-login__policy"
                >
                  <span style={{ color: '#ff0000' }}></span>
                  <span>{t('boxLogin.txtCheckbox')}</span>
                  <>
                    <a
                      style={{
                        cursor: 'pointer',
                        textTransform: 'lowercase',
                      }}
                      target="_blank"
                      href={privacyPolicyLink}
                      onClick={event => {
                        if (privacyPolicyLink) {
                          return;
                        }
                        event.preventDefault();
                        this.setState({ privacyPolicyModalShow: true });
                      }}
                    >
                      {' '}
                      {t('subcriptions.privacy policy')}
                    </a>{' '}
                    {t('boxLogin.txtAnd')}{' '}
                    <a
                      className="txt-link"
                      style={{
                        cursor: 'pointer',
                        textTransform: 'lowercase',
                      }}
                      target="_blank"
                      href={termsOfUseLink}
                      onClick={event => {
                        if (termsOfUseLink) {
                          return;
                        }
                        event.preventDefault();
                        this.setState({ termOfUseModalShow: true });
                      }}
                    >
                      {t('subcriptions.footer.termsOfUse')}
                    </a>
                  </>
                </Checkbox>
              </form>
              <Button
                data-testid={registerFormId.registerBtn}
                onClick={() => this._signUp()}
                bsSize="large"
                disabled={btnDisabled}
                className={`form_submit v-login--btn ${
                  this._checkNull() ? null : 'v-login--btn-primary'
                }`}
              >
                {t('registerScreen.register')}
              </Button>
            </Col>
            <div className="v-login--signup">
              <p>{t('registerScreen.textHaveAccount')}</p>
              <Button
                onClick={() => this._signIn()}
                bsSize="large"
                className={`v-login--btn-login`}
              >
                {t('subcriptions.sign in')}
                {/* {t('registerScreen.signIn')} */}
              </Button>
            </div>
          </div>
        </RowStyled>
      </React.Fragment>
    );
  }
}

const styles = {
  flex_column: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
  },
};

Register.defaultProps = {
  modalShow: false,
  showLoginAction: param => {},
  hideModal: () => {},
  showModal: () => {},
};

const mapDispatchToProps = {
  dispatchRegisterEmail: params => registerEmail(params),
  dispatchLoginEmail: (email, password) => loginEmail(email, password),
  dispatchUpdateProfile: params => changeProfile(params),
};

const mapStateToProps = state => ({
  planSelected: state.box.form.supcriptionPlan,
  selectBoxPage: state.box.form.selectBoxPage,
  account: state.auth.account,
  guestAccount: state.auth.guestAccount,
  language: state.root.language,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Register)),
);
