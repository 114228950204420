export default {
  title: 'register-form-title',
  emailTxt: 'register-form-email-txt',
  passwordTxt: 'register-form-password-txt',
  lastNameTxt: 'register-form-last-name-txt',
  firstNameTxt: 'register-form-first-name-txt',
  phoneNumberTxt: 'register-form-phone-number-txt',
  genderTxt: 'register-form-gender-txt',
  birthdateTxt: 'register-form-birthdate-txt',
  genderCbx: 'register-form-gender-cbx',
  registerBtn: 'register-form-register-btn',
  loginBtn: 'register-form-login-btn',
  closeBtn: 'register-form-close-btn',
  errorMessageLbl: 'register-form-error-message-lbl',
  successPopupId: {
    title: 'register-form-success-popup-title',
    closeBtn: 'register-form-sucess-popup-close-btn',
  },
};
