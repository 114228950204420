import WarningAlert from '../components/Warning_AgeRestrictions/WarningAlert';
import ReactDOM from 'react-dom';
import React from 'react';
import videojs from 'video.js';
import jquery from 'jquery';
import { pushEventFirebase } from './../components/pushEventFirebase';
import { CHANNEL_TYPE, CURATED_LIVE } from '../constants';
import { _addAdsVideo } from '../function';
import { tenantEnvConfig } from '~constants/tenantConfig/tenantEnvConfig';
import { getConfigByKey } from '~features/tenantConfig/services';

const vjsComponent = videojs.getComponent('Component');

class WarningScreen extends vjsComponent {
  constructor(player, options) {
    super(player, options);
    //* Bind the current class context to the mount method */
    this.mount = this.mount.bind(this);
    //* When player is ready, call method to mount React component */
    const $player = jquery(this.el());
    player.on('ready', () => {
      $player.parent().find('.vjs-control-bar').addClass('disable');
      this.mount();
    });
    player.on('play', () => {
      if ($player.hasClass('vjs-age-restrictions')) {
        jquery(`#${player.id_}`).focus();
        player.pause();
      }
    });
    //* Remove React root when component is destroyed */
    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }
  createEl() {
    if (getConfigByKey('ui_theme') === 'projectw') return null;
    const div = videojs.createEl('div', {
      className: 'vjs-age-restrictions',
      id: 'vjs-age-restrictions',
    });
    return div;
  }
  //TODO : Click Confirm
  ClickConfirm = () => {
    const $player = jquery(this.el());
    const { component } = this.options_;
    $player.removeClass('vjs-age-restrictions');
    $player.addClass('hide');
    component.setState(
      {
        isRestrictions: true,
      },
      () => {
        $player.parent().find('.vjs-control-bar').removeClass('disable');
        $player.parent().find('.vjs-report').removeClass('disable');
        if (component.state.isHasSubcription) {
          if (component.progress) {
            component._resumeVideo(component.progress);
            this.player_.one('canplaythrough', function () {
              component._resumeVideo(component.progress);
            });
          }
          return this.player_.play();
        }
        _addAdsVideo(component, 1);
      },
    );
  };
  ClickUnConfirm = () => {
    const $player = jquery(this.el());
    switch (tenantEnvConfig.ui_theme) {
      case 'escondido':
        $player.parent().find('.vjs--escondido-back__btn').trigger('click');
        break;
      default:
        $player.parent().find('.vjs-top-navigation').trigger('click');
    }
  };

  mount() {
    const { component } = this.options_;
    ReactDOM.render(
      <WarningAlert
        player={this.player_}
        component={component}
        ClickConfirm={this.ClickConfirm}
        ClickUnConfirm={this.ClickUnConfirm}
      />,
      this.el(),
    );
  }
}

/**
 * Make sure to register the vjsComponent so Video JS knows it exists
 */
vjsComponent.registerComponent('WarningScreen', WarningScreen);

export default WarningScreen;
