import * as types from './actionTypes';
import { getConfigByKey } from './services';

const initialState = {
  ...getConfigByKey(),
};

export default function home(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_CONFIGS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
