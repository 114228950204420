import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import lodash from 'lodash';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import VideoPlayer from '~components/player/Player';
import { LazyImage } from '~components';
import {
  getBanner19_6,
  timeFormatDuration,
  getLandingLink,
  getDrmLinkPlay,
  getViewContentPlaying,
} from '~core/method/contentMethod';
import { truncateText } from '~core/utils';
import { useMediaQuery } from 'react-responsive';
import { getClassFont } from '~core/utils';

const RecordContentSpotlightStyled = styled.div`
  display: flex;
  font-family: Neue Haas Grotesk Display Pro;
  border-bottom: 0.5px solid #000;
  padding-bottom: 32px;
  &:nth-child(2) {
    border-top: 0.5px solid #000000;
  }
  &.player-cover {
    .vjs-tech {
      object-fit: cover;
      object-position: center;
    }
  }
  .record {
    &__image,
    &__video {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    &__left {
      flex-basis: 72.38%;
      flex-shrink: 0;
      &:hover {
        cursor: pointer;
      }
    }
    &__right {
      padding: 8px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      font-family: var(--font);
    }
    &__tags {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: auto;
      &--item {
        padding-right: 24px;
        font-style: normal;
        font-size: 13px;
        line-height: 124%;
        font-weight: 500;
        letter-spacing: 0.075em;
        text-transform: uppercase;
        color: #000000;
        &:last-child {
          padding-right: 0;
        }
      }
    }
    &--hidden {
      /* display: none; */
      visibility: hidden;
      z-index: -1;
      opacity: 0;
    }
    &--shown {
      /* display: block; */
      visibility: visible;
      z-index: auto;
      opacity: 1;
    }
    &--title-answer {
      color: #000;
      font-weight: 700;
      font-size: 25px;
      display: -webkit-box;
      max-height: 5rem;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      -webkit-line-clamp: 2;
      @media (max-width: 1024px) {
        -webkit-line-clamp: 1;
      }
      @media (max-width: 768px) {
        font-size: 22px;
      }
    }
    &--description {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 21px;
      display: flex;
      align-items: flex-start;
      letter-spacing: 0.05em;
      word-break: keep-all;
      overflow: hidden;
      /* gray / 5 */

      color: #5a5a5a;
      margin-bottom: 10px;
    }
    &--img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
    &--media-ratio {
      position: relative;
      padding-top: calc(6 / 19 * 100%);
    }
    &--media-inside {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &__title {
      position: absolute;
      top: 17px;
      left: 22px;
      right: 22px;
      bottom: 17px;
      z-index: 1;
      &--txt {
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 124%;
        /* or 37px */

        letter-spacing: -0.005em;

        /* gray / 6 */

        color: #f9f9f8;
      }
      &--duration {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 124%;
        letter-spacing: 0.04em;
        color: #cfdde4;
        margin-top: 5px;
        overflow: hidden;
      }
    }
  }
  @media (max-width: 767px) {
    display: block;
  }
  @media (max-width: 767px) {
    .record {
      &__image {
      }
      &--description {
        /* margin-top: 25px; */
        display: -webkit-box;
        max-height: 42px;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-line-clamp: 2;
        line-height: calc(17px / var(--scaling));
      }
    }
  }

  @media (min-width: 1024px) {
    .record {
      &--description {
      }
    }
  }

  @media (min-width: 768px) {
    padding-bottom: 0;

    .record {
      &__right {
        flex-grow: 1;
        padding: 30px 22px;
      }
      &__image {
      }
      &--description {
        display: block;
        margin-top: 0;
        flex-grow: 1;
      }
    }
  }
`;

const timeFormat = record => timeFormatDuration.call(record);

const getImage = record => getBanner19_6.call(record);

const getTags = (record, metadata = 'genres') => {
  try {
    if (metadata === 'content_categories') {
      return (record[metadata] || []).slice(0, 3);
    }
    if (!record || !record.metadata) {
      return [];
    }
    return (record.metadata[metadata] || []).slice(0, 3);
  } catch (error) {
    return [];
  }
};

const getFirstNWords = (str = '', suffix = '...', length = 45) => {
  const lengthText = window.innerWidth ? 10 : length;
  const strSplit = str.split(' ');
  let result = strSplit.slice(0, lengthText).join(' ');
  if (strSplit.length > lengthText) {
    result += suffix;
  }
  return result;
};

function RecordContentSpotLight(props) {
  const { record = {}, contentView = null, hoverState, contentViewById } = props;
  const [linkPlay, setLinkPlay] = useState();
  const refPlayer = useRef(null);
  const [viewContentPlaying, setViewContentPlaying] = useState(null);
  const [hasQA, setHasQA] = useState(false);
  const descriptionRef = useRef(null);
  const mediaRef = useRef(null);
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)',
  });

  const handleResize = () => {
    const text = getFirstNWords((hasQA ? record.answer : record.short_description) || '');
    if (!text) {
      return;
    }
    if (descriptionRef.current && mediaRef.current) {
      const $mediaRatio = $(mediaRef.current);

      const $description = $(descriptionRef.current);
      const $parentRecord = $mediaRatio.parents('.record');
      if (window.innerWidth < 768) {
        $description.text(text);
        $parentRecord.css({ height: 'auto' });
        return;
      }

      $parentRecord.css({ height: `${$mediaRatio.outerHeight()}px` });
      truncateText($description, hasQA ? record.answer : record.short_description);
    }
  };

  useEffect(() => {
    handleResize(record);
    if (lodash.isEmpty(record) || lodash.isEmpty(contentViewById)) {
      return;
    }

    if (linkPlay) {
      return;
    }
    const newViewContentPlaying = getViewContentPlaying.call(record, contentViewById);

    newViewContentPlaying && setViewContentPlaying(newViewContentPlaying);
  }, [record, contentViewById]);

  useEffect(() => {
    if (lodash.isEmpty(viewContentPlaying) || viewContentPlaying.geo_blocked) {
      return;
    }
    setTimeout(() => {
      setLinkPlay(getLinkPlay(viewContentPlaying));
    }, 0);
  }, [viewContentPlaying]);

  useEffect(() => {
    $(window).on('resize', () => handleResize(record));

    return () => {
      $(window).off('resize');
    };
  }, [record, contentViewById]);

  useEffect(
    () => () => {
      refPlayer.current && refPlayer.current.dispose();
    },
    [],
  );

  const getLinkPlay = viewContentPlaying => getDrmLinkPlay.call(viewContentPlaying);

  const onPlayerReady = (player, record) => {
    if (!refPlayer.current && record && record.id) {
      refPlayer.current = player;
      player.muted(true);
      player.loop(true);
      hoverState ? player.play() : player.pause();
    }
  };

  const checkHasQuestionAndAnswer = () => {
    const _ = lodash;
    if (_.isEmpty(record)) {
      return;
    }
    const { answer, question } = record;
    if (_.isEmpty(answer) || _.isEmpty(question)) {
      setHasQA(false);
      return;
    }
    setHasQA(true);
  };

  const InfoContentDesktop = () => {
    return (
      <>
        <p className="record--title-answer">{(hasQA ? record.question : record.title) || ''}</p>
        <p className="record--description">
          {getFirstNWords((hasQA ? record.answer : record.short_description) || '')}
        </p>
      </>
    );
  };

  const InfoContentMobile = () => {
    return (
      <>
        <p className="record--description">
          {getFirstNWords((hasQA ? record.question : record.short_description) || '')}
        </p>
      </>
    );
  };

  useEffect(() => {
    checkHasQuestionAndAnswer();
  }, [record]);

  const isShowPlayerHover = () => hoverState && linkPlay;
  return (
    <RecordContentSpotlightStyled className="record player-cover">
      <Link className="record__left" to={getLandingLink.call(record)}>
        <div ref={mediaRef} className="record--media-ratio">
          <div className="record--media-inside">
            <div className="record__title">
              {/* <h6 className="record__title--txt">{record.title}</h6> */}
              {/* <p className="record__title--duration">{timeFormat(record)}</p> */}
            </div>
            <div className="record__image ">
              <LazyImage className="record--img" src={getImage(record)} />
            </div>
            <div
              className={`record__video record--hidden ${
                isShowPlayerHover() ? 'record--shown' : ''
              }`}
              id={record.id}
            >
              {linkPlay && (
                <VideoPlayer
                  muted
                  width="100%"
                  height="100%"
                  controls={false}
                  onReady={player => onPlayerReady(player, record)}
                  id={record.id}
                  src={linkPlay}
                  content={viewContentPlaying}
                />
              )}
            </div>
          </div>
        </div>
      </Link>
      <div className="record__right">
        {isDesktop ? <InfoContentDesktop /> : <InfoContentMobile />}
        <div className="record__tags">
          {getTags(record, 'content_categories').map(tag => (
            <span key={tag.slug} className={`${getClassFont(tag.name)} record__tags--item`}>
              {tag.name}
            </span>
          ))}
        </div>
      </div>
    </RecordContentSpotlightStyled>
  );
}

export default RecordContentSpotLight;
