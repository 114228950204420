import { pushEventFirebase, pushEventFirebasePlayer } from './../components/pushEventFirebase';
import { CURATED_LIVE, CHANNEL_TYPE } from './../constants';
// import { detectAdBlocker } from './initPlayer'
import jquery from 'jquery';
import { localStorage as ls } from 'js-storage';
import { debug } from '~utils/utils';

const handlePushTimeEvent = component => {
  const entityDetail = component.entityDetail;
  const { account } = component.props;
  const { dispatchPostFirstFiveSecPlay } = component.props;
  const player = component.videoPlayer;
  component.timePushEvent = setInterval(() => {
    if (!component.videoPlayer || (component.videoPlayer && component.videoPlayer.paused())) {
      return;
    }
    component.viewHour += 1;
    if (component.viewHour === 5) {
      pushEventFirebasePlayer(entityDetail, 'videoFiveSecPlay');
      component._updateProgress(entityDetail.id, component.videoPlayer.currentTime());
      if (!component.watched) {
        dispatchPostFirstFiveSecPlay(entityDetail.id);
        component.watched = true;
      }
    }
    if (component.viewHour % 60 === 0) {
      pushEventFirebasePlayer(entityDetail, 'viewMinuteLog', 60);
    }
    if (component.viewHour % 300 === 0) {
      // detectAdBlocker(component, account)
      component._updateProgress(entityDetail.id, component.videoPlayer.currentTime());
      pushEventFirebasePlayer(entityDetail, 'viewhourLog', 300);
    }
  }, 1000);
};
const handelEvent = (component, isRewatch) => {
  let eventLive = false;
  let seekToLive = true;
  let timerSeekToLive = null;
  const { isLive } = component.state;
  const { disableControl } = component.props;
  const player = component.videoPlayer;
  const entityDetail = component.entityDetail;
  const $player = jquery(component.videoPlayer.player_.el_);
  component.isInitedEvents = true;
  const controlBar = component.videoPlayer.getChild('controlBar');
  const playToggle = controlBar.getChild('playToggle').el();
  const volumePanel = controlBar.getChild('volumePanel').el();
  const subsCapsButton = controlBar.getChild('subsCapsButton').el();
  let timerCloseEpgFullscreen = null;
  let isFullscreen = false;
  // event ima
  component.handleImaLive(component.videoPlayer);
  function fnPlayToggle(event) {
    let isPause = this.getAttribute('title');
    if (isPause === 'Pause') {
      clearInterval(component.timePushEvent);
      pushEventFirebase(entityDetail, 'pause'); // event video pause
    }
    if (isPause === 'Play') {
      pushEventFirebase(entityDetail, 'play'); // event video play
    }
    if (isPause === 'Replay') {
      $player.find('.vjs-recommend-display').removeClass('vjs-recommend-open');
      pushEventFirebasePlayer(entityDetail, 'videoPlayStart');
      pushEventFirebasePlayer(entityDetail, 'videoFirstPlay');
      clearInterval(component.timePushEvent);
      handlePushTimeEvent(component);
    }
  }
  // event volumePanel
  const fnVolumePanel = () => {
    pushEventFirebase(entityDetail, 'volume');
  };
  // event subtitle
  const fnSubsCapsButton = () => {
    pushEventFirebase(entityDetail, 'subtitle');
  };
  playToggle.addEventListener('click', fnPlayToggle);
  volumePanel.addEventListener('click', fnVolumePanel);
  subsCapsButton.addEventListener('click', fnSubsCapsButton);

  player.on('dblclick', function () {
    if (player.isFullscreen()) {
      player.exitFullscreen();
    } else {
      player.requestFullscreen();
    }
  });

  //TODO : Update - Push progress When End Video - #Bug - 48710
  player.on('ended', () => {
    component._updateProgress(entityDetail.id, component.videoPlayer.currentTime());
    debug(() => {
      console.log('Push Progress When End Time', component.videoPlayer.currentTime());
    });
  });

  player.on('fullscreenchange', function () {
    if (this.isFullscreen_) {
      isFullscreen = true;
      player.on('userinactive', () => {
        if (!isFullscreen) {
          return;
        }
        timerCloseEpgFullscreen = setTimeout(() => {
          const $el = jquery(this.el());
          if ($el.hasClass('vjs-epg-open')) {
            $el.find('.vjs-epg-button').trigger('click');
          }
          clearTimeout(timerCloseEpgFullscreen);
        }, 30000);
      });
      player.on('useractive', () => {
        if (!isFullscreen) {
          return;
        }
        clearTimeout(timerCloseEpgFullscreen);
      });
      jquery(`#${player.id_}`).focus();
      pushEventFirebase(entityDetail, 'enter fullscreen'); // event video full
    } else {
      isFullscreen = false;
      pushEventFirebase(entityDetail, 'exit fullscreen'); // event video not full
    }
  });

  component._onSeeked(entityDetail.id);
  component._handleError();
  component._userActive();
  component._userInactive();

  player.on('contentchanged', function () {
    if (isLive) {
      eventLive = true;
    }
    // in a real plugin, you might fetch new ad inventory here
    if (entityDetail.type !== CURATED_LIVE) {
      player.trigger('adsready');
    }
  });

  player.on('volumechange', () => {
    if (disableControl) return;
    if (player.muted()) {
      component.props.dispatchSetVolume(0);
      return;
    }
    if (player.volume() === component.props.detailPage.player.volume) return;
    component.props.dispatchSetVolume(player.volume());
  });
  // event playerLoad
  player.on('loadstart', () => {
    if (isRewatch || eventLive) {
      return;
    }
    pushEventFirebasePlayer(entityDetail, 'playerLoad');
  });
  // event videoPlayStartFail
  player.on('error', function () {
    pushEventFirebasePlayer(entityDetail, 'videoPlayStartFail'); // event video error
  });
  // event viewhourLog
  let contentended = false;
  player.on('ended', function () {
    if (entityDetail.type === CURATED_LIVE || entityDetail.type === CHANNEL_TYPE) {
      return;
    }
    if (contentended) {
      contentended = false;
      return;
    }
    let lessTime = component.viewHour % 300;
    const restMinuteTime = component.viewHour % 60;
    if (restMinuteTime !== 0) {
      pushEventFirebasePlayer(entityDetail, 'viewMinuteLog', restMinuteTime);
    }
    if (lessTime) {
      pushEventFirebasePlayer(entityDetail, 'viewhourLog', lessTime);
    }
    clearInterval(component.timePushEvent);
    component.viewHour = 0;
  });

  player.on('contentended', function () {
    if (isRewatch || eventLive) {
      return;
    }
    contentended = true;
    let lessTime = component.viewHour % 300;
    let restMinuteTime = component.viewHour % 60;
    if (restMinuteTime !== 0) {
      pushEventFirebasePlayer(entityDetail, 'viewMinuteLog', restMinuteTime);
    }
    if (lessTime) {
      pushEventFirebasePlayer(entityDetail, 'viewhourLog', lessTime);
    }
    clearInterval(component.timePushEvent);
    component.viewHour = 0;
  });

  let timeout = '';
  let init = true;
  player.on('loadeddata', event => {
    if (isRewatch || eventLive) {
      return;
    }
    component.videoPlayer.textTracks().on('change', function action(event) {
      if (component.subtitleRender && component.videoPlayer.ads.isInAdMode()) {
        return;
      }
      clearTimeout(timeout);
      let showing = this.tracks_.filter(function (track) {
        if (track.subtitleRender && track.mode === 'showing') {
          return false;
        }
        if (track.kind === 'subtitles' && track.mode === 'showing') {
          return true;
        }
      })[0];
      timeout = setTimeout(function () {
        component.videoPlayer.trigger('subtitleChanged', showing);
      }, 10);
    });
  });

  player.on('subtitleChanged', function (event, track) {
    let newEntity = { ...entityDetail, track };
    if (!track) {
      ls.set('subtitle', 'off');
      return;
    }
    const { subtitles = [] } = entityDetail;
    const subtitleOfTrack = subtitles.filter(stt => stt.id === track.id);

    if (subtitleOfTrack.length) {
      ls.set('subtitle', track.language || track.srclang);
      const trackData = subtitleOfTrack[0];
      document.documentElement.style.setProperty(
        '--subtitle_color',
        trackData.subtitle_color || '#fff',
      );
      document.documentElement.style.setProperty(
        '--border_text_color',
        trackData.border_text_color || '#000',
      );
    }
    pushEventFirebase(newEntity, 'subtitle');
  });

  player.on('timeupdate', function (event) {
    clearInterval(this.timePushEvent);
    const $player = jquery(player.el_);
    if ($player.hasClass('vjs-gapContent')) {
      return;
    }
    if (init && component.videoPlayer.currentTime() > 1) {
      component._updateProgress(entityDetail.id, component.videoPlayer.currentTime());
      init = false;
    }
  });
  player.on('loadedmetadata', () => {
    if (isRewatch || eventLive) {
      return;
    }
    pushEventFirebasePlayer(entityDetail, 'videoPlayStart');
  });

  player.on('playing', function (event) {
    if (isRewatch || eventLive) {
      return;
    }
    clearInterval(component.timePushEvent);
    if (component.isFirstPlay && component.isReadyAds) {
      pushEventFirebasePlayer(entityDetail, 'videoFirstPlay'); // event ready video play start
      component.isFirstPlay = false;
      if (isLive) {
        eventLive = true;
      }
    }
    handlePushTimeEvent(component);
  });

  player.on('waiting', function (event) {
    const entityDetail = component.entityDetail;
    if (!entityDetail.drm_session_info) {
      timerSeekToLive = seekToLiveChanel(component, seekToLive);
    }
    clearInterval(component.timePushEvent);
  });

  player.on('canplaythrough', function (event) {
    seekToLive = true;
    clearInterval(timerSeekToLive);
    clearInterval(component.timePushEvent);
    handlePushTimeEvent(component);
  });
  player.on('seeking', () => {
    if (player.paused()) {
      player.play();
    }
  });
};

const seekToLiveChanel = (component, seekToLive) => {
  const player = component.videoPlayer;
  const { isBlackOut } = component.state;
  const entityDetail = component.entityDetail;
  let timer = 0;
  if (entityDetail.type !== CHANNEL_TYPE) return;
  if (!seekToLive || isBlackOut) return;
  seekToLive = false;
  let timerSeekToLive = setInterval(() => {
    timer += 1;
    if (!player || (player && !player.player_)) {
      clearInterval(timerSeekToLive);
      return;
    }
    if (timer % 20 === 0) {
      player.liveTracker.seekToLiveEdge();
    }
    if (timer > 60) {
      player.src({
        src: player.src(),
      });
      component._checkAutoplay();
      let playPromise = player.play();
      if (playPromise !== undefined) {
        playPromise.then(_ => {
          jquery(`#${player.id_}`).focus();
        });
      }
      setTimeout(() => {
        jquery(`#${player.id_}`).focus();
        player.play();
      }, 0);
      seekToLive = true;
      clearInterval(timerSeekToLive);
    }
  }, 1000);
  return timerSeekToLive;
};
export { handelEvent };
