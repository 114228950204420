import queryString from 'query-string';

export function getSlugMatch(nextProps: any, props: any): any {
  const { match, resetFilter, location } = nextProps || props;
  let slugMatch = '';
  if (match && match.params && match.params.slug) {
    slugMatch = match.params.slug;
  } else if (match && (match.path === '/' || match.path === '/terms-of-use')) {
    slugMatch = '/';
  }
  if (resetFilter) {
    let paramQuery = queryString.parse(location.search, { decode: false });
    slugMatch = resetFilter && paramQuery.tenants;
  }
  return slugMatch;
}
