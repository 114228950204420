import TagManager from 'react-gtm-module';
import { integrationConfigs } from '~constants/tenantConfig/integrationConfigs';

const initGTM = () => {
  if (!integrationConfigs.gtm) {
    return;
  }
  TagManager.initialize({
    gtmId: integrationConfigs.gtm.id,
  });
};

export default initGTM;
