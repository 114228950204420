import * as type from './../constants';
import '../videoComponents';
import { getConfigByKey } from '~features/tenantConfig/services';
import jquery from 'jquery';
import { getEps } from './getEps';
import { LIVE_EVENT, TENANT_NAME_ENUM } from '~core/constants';
import { canWatchContent, isLiveEvent } from '~core/method/contentMethod';
import { isProjectW } from '~core/method/authMethod';
import { useSelector } from 'react-redux';
import { setLiveEventStatus } from '~components/global/globalSlice';
import _ from 'lodash';

const ControlBar = (player, component, data = {}) => {
  if (player.childrenAdded) {
    return;
  }
  const LIVING_LIVE_EVENT_STATE = 'living';

  const controlBar = player.getChild('controlBar');
  const { detailPage, t, account } = component.props;
  const { entityTrailer, infoShow, isRestrictions } = component.state;
  const { entityDetail, renderLive } = data;
  let currentEpisode = entityTrailer || detailPage.episode || 1;
  let episodes = getEps(component, data);
  let isAdult = infoShow ? infoShow.restriction : entityDetail.restriction;
  let topOpts = { component: component };
  component.backUrl = entityDetail.slug;

  if (entityDetail.group && entityDetail.type === type.EPISODE_TYPE) {
    component.backUrl = infoShow.slug;
    const { landingPage } = component.props;
    let detailLanding = landingPage.detail;
    detailLanding = infoShow;
    let slugCurrent =
      component.entityDetail.current_season && component.entityDetail.current_season.slug;
    detailLanding.seasons.length > 0 &&
      detailLanding.seasons.map((item, index) => {
        index = index + 1;
        if (item.slug === slugCurrent && index === detailLanding.seasons.length) {
          player.addChild('Recommendation', {
            component: component,
            channelId: entityDetail.id,
            episodes,
          });
        }
      });
    controlBar.addChild(
      'listEps',
      {
        component: component,
        entity: entityDetail,
        translate: t,
        currentEpisode,
        translations: component.translations,
        episodes: episodes,
      },
      8,
    );
  }
  if (getConfigByKey('ui_theme') !== TENANT_NAME_ENUM.PROJECTW) {
    if (entityDetail.type === type.MOVIE_TYPE || entityDetail.type === type.CLIP) {
      if (episodes.length > 1) {
        controlBar.addChild(
          'listEps',
          {
            component: component,
            entity: entityDetail,
            translate: t,
            currentEpisode,
            translations: component.translations,
            episodes: episodes,
          },
          8,
        );
      }
      player.addChild('Recommendation', {
        component: component,
        channelId: entityDetail.id,
        episodes,
      });
    }
  }

  getConfigByKey('ui_theme') !== TENANT_NAME_ENUM.PROJECTW &&
    player.addChild('TopNavigation', topOpts);
  controlBar.addChild('SeekNext', { component: component, entity: entityDetail }, 7);
  controlBar.addChild('SeekPrev', { component: component, entity: entityDetail }, 6);
  if (getConfigByKey('ui_theme') === TENANT_NAME_ENUM.PROJECTW) {
    player.addChild('TopLeftContainerVjs', {
      component: component,
      channelId: entityDetail.id,
    });
    if (isLiveEvent.call(entityDetail)) {
      player.addChild('ToggleChatLiveVjs', {
        component: component,
        channelId: entityDetail.id,
      });
    }
  }

  if (getConfigByKey('ui_theme') === TENANT_NAME_ENUM.PROJECTW && isLiveEvent.call(entityDetail)) {
    player.on('ready', () => {
      $player.find('.vjs-seek-to-live-control').addClass('hide');
    });
    player.on('timeupdate', () => {
      const $videoEl = $player.parent().find('.video-js');
      const { duration } = component;
      const $body = jquery('#body');
      if (!$body.hasClass('vjs-live-event-after') || !duration || !_.isFinite(duration)) {
        $videoEl.addClass('vjs-live vjs-liveui').addClass('vjs-curated-live');
        $player.find('.vjs-seek-to-live-control').addClass('hide');
      } else {
        $videoEl.removeClass('vjs-live vjs-liveui').removeClass('vjs-curated-live');
        $player.find('.vjs-seek-to-live-control').removeClass('hide');
      }
    });
  }

  // if (
  //   isProjectW() &&
  //   isLiveEvent.call(entityDetail)
  // ) {
  //   controlBar.addChild('DurationContentLiveEvent', {
  //     component: component,
  //     entity: entityDetail,
  //   })
  //   controlBar.addChild('InLiveTimeContent', {
  //     entity: entityDetail,
  //     component: component,
  //   })
  // }

  if (isProjectW() && isLiveEvent.call(entityDetail)) {
    const $player = jquery(player.el_);
    player.on('loadstart', function () {
      $player.parent().find('.vjs-remaining-time').addClass('hide');
      $player.parent().find('.vjs-current-time').addClass('hide');
      const $controlbar = $player.parent().find('.vjs-control-bar');
      $controlbar.find('.vjs-live-control').addClass('hide');
    });
    player.on('update-live-state', function (event, liveState) {
      if (liveState !== LIVING_LIVE_EVENT_STATE) {
        controlBar.addChild('CurrentTimeContent', {
          entity: entityDetail,
          component: component,
        });
        controlBar.addChild('DurationContentLiveEvent', {
          component: component,
          entity: entityDetail,
        });
        return;
      }
      if ($player.hasClass('vjs-durationContent') && $player.hasClass('vjs-inLiveTime')) {
        return;
      }
      controlBar.addChild('InLiveTimeContent', {
        entity: entityDetail,
        component: component,
      });
      controlBar.addChild('DurationContentLiveEvent', {
        component: component,
        entity: entityDetail,
      });
    });
  }

  if (isProjectW() && entityDetail.type === type.EPISODE_TYPE) {
    player.on('ready', () => {
      if (!player || !player.player_ || !player.on || !player.one) {
        return;
      }
      document.addEventListener('keydown', (e, newPlayer = player) => {
        if (!newPlayer || !newPlayer.player_ || !newPlayer.on || !newPlayer.one) {
          return;
        }
        if (newPlayer && e.keyCode === 39) {
          return newPlayer.one('ended', () => {
            const $controlbar = $player.parent().find('.vjs-control-bar');
            const btnNext = $controlbar.find('.vjs-next');
            btnNext.trigger('click');
          });
        }
        return;
      });
    });
  }

  if (isProjectW() && !isLiveEvent.call(entityDetail)) {
    player.on('ready', function () {
      $player.parent().find('.vjs-remaining-time').addClass('hide');
      $player.parent().find('.vjs-current-time').addClass('hide');
      const $controlbar = $player.parent().find('.vjs-control-bar');
      $controlbar.find('.vjs-live-control').addClass('hide');
    });
    controlBar.addChild('CurrentTimeContent', {
      entity: entityDetail,
      component: component,
    });
    controlBar.addChild('DurationContentLiveEvent', {
      component: component,
      entity: entityDetail,
    });
  }

  getConfigByKey('ui_theme') !== TENANT_NAME_ENUM.PROJECTW &&
    player.addChild('BtnReports', {
      component: component,
      entity: entityDetail,
    });
  getConfigByKey('ui_theme') !== TENANT_NAME_ENUM.PROJECTW &&
    player.addChild('Reports', {
      component: component,
      channelId: entityDetail.id,
    });
  player.addChild('ButtonBigPause', { component: component });
  if (isAdult && !isRestrictions)
    player.addChild('WarningScreen', { component: component, channelId: entityDetail.id }, topOpts);
  if (entityDetail.type === type.CURATED_LIVE || entityDetail.type === type.CHANNEL_TYPE) {
    component.isDisplayCooldown = player.addChild('CoolDown', {
      component: component,
      channelId: entityDetail.id,
      isRender: renderLive,
    });
    player.addChild('AutoNextSpiner', { component: component });
    if (getConfigByKey('features.notify_player', true)) {
      player.addChild('NotifyPolicy', { component: component, t: t });
    }
    player.addChild('BlackOut', {});
    controlBar.addChild('EPG', { text: t('broadcastSchedule'), component: component }, 15);
    component.isDisplayEPG = player.addChild('EPGList', {
      component: component,
      channelId: entityDetail.id,
      isRender: renderLive,
    });
    if (entityDetail.type === type.CURATED_LIVE) {
      controlBar.addChild('NextLive', { component: component, entity: component.entityDetail }, 1);
      controlBar.addChild('PrevLive', { component: component, entity: component.entityDetail }, 0);
    }
  }
  if (getConfigByKey('ui_theme') !== TENANT_NAME_ENUM.PROJECTW) {
    controlBar.addChild('SpaceControlBar', {}, 16);

    controlBar.addChild(
      'TitleControlBar',
      { text: component.state.title || '', eps: episodes, component },
      14,
    );
  }

  if (component.state.isLive && getConfigByKey('features.deep_link')) {
    player.addChild('BtnShare', { component: component });
  }
  player.childrenAdded = true;
  const $player = jquery(player.el_);
  if (getConfigByKey('ui_theme') === TENANT_NAME_ENUM.PROJECTW) {
    return $player.find('.vjs-play-control').addClass('projectw__liveui');
  }
  window.addEventListener('orientationchange', async () => {
    if (!component.videoPlayer || $player.hasClass('vjs-curated-live')) {
      return;
    }
    if (window.screen.orientation && window.screen.orientation.angle === 90) {
      $player.parent().addClass('vjs-landscape');
    } else {
      $player.parent().removeClass('vjs-landscape');
    }
  });
};

export default ControlBar;
