import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

const paypal = require('./../../../../../assets/img/paypal.png').default;

export const ModalStyled = styled(Modal)`
  a {
    text-decoration: none;
  }
  &.popup-paypal {
    font-size: 1rem;
    display: flex;
    .modal-dialog {
      width: auto;
    }
  }
  .modal-content {
    border-radius: 10px;
  }
  .popup-paypal {
    &__close {
      position: absolute;
      top: 2%;
      right: 2%;
    }
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 2px 45px;
    }
    &__image {
      width: 100%;
      max-width: 188px;
      height: auto;
      margin-bottom: 18px;
      img {
        width: 100%;
      }
    }
    &__description {
      text-align: center;
      font-size: 1rem;
      margin-bottom: 33px;
      &--title {
        color: #283237;
        font-weight: bolder;
        font-size: 24px;
        margin-bottom: 10px;
      }
      &--subtitle {
        color: #495963;
        padding: 0;
      }
    }
    &__button {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 0 10px;
      a {
        width: 100%;
      }
      &--btn {
        margin: 0 5px;
        color: #fff;
        background: ${({ theme }) => theme.plans.btn};
        font-weight: bold;
        width: 100%;
        padding: 13px;
        border-radius: 5px;
      }
      &--back,
      &--success {
        width: 100%;
      }
      &--success {
        margin: 0;
      }
    }
  }
  @media (min-width: 768px) {
    &.popup-paypal {
      top: 5%;
      .modal-dialog {
        width: 570px;
      }
    }
    .popup-paypal {
      &__container {
        padding: 40px 85px 45px;
      }
      &__description {
        &--title {
        }
        &--subtitle {
          padding: 0;
        }
      }
      &__button {
        margin: 0;
      }
    }
  }
`;
