import React, { Component } from 'react';
import { Panel, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

class LoginLivePlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _loginUpgrade() {
    const { account, history, language, entityDetail } = this.props;
    let slug = entityDetail.entityDetail.slug;
    let svod = !entityDetail.entityDetail.is_watchable ? 1 : 0;
    let param = {
      upgrade: true,
      callbackUrl: `/detail/${slug}`,
      svod,
    };
    if (!account) {
      return entityDetail.showLoginAction(param);
    }
    return history.push(`/${language}/box/offers`, {
      upgrade: true,
      callbackUrl: `/${language}/detail/${slug}`,
    });
  }

  _renderUpgrate() {
    const { t, account } = this.props;
    return (
      <Button className="v-loginLive--btn" onClick={() => this._loginUpgrade()}>
        <span className="">
          {!account ? t('laddingPage.btnLogin') : t('video.messagePopup.btnUpgrade')}
        </span>
      </Button>
    );
  }

  render() {
    const { t, account } = this.props;
    return (
      <PanelStyled className="v-loginLive">
        <p className="v-loginLive--title">{t('video.messagePopup.txtTitle')}</p>
        <p className="v-loginLive--description">
          {!account
            ? t('video.messagePopup.txtDes')
            : t('video.messagePopup.txtDesHaveSupcription')}
        </p>
        {this._renderUpgrate()}
      </PanelStyled>
    );
  }
}

export const PanelStyled = styled(Panel)`
  &.v-loginLive {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100%;
    /* opacity: 0.8; */
    position: absolute;
    background-color: rgba(0, 0, 0, 0.9);
    border: none;
    color: #fff;
    z-index: 1000;
  }
  .v-loginLive {
    &--title {
      font-size: 2.25rem;
      font-weight: 600;
    }
    &--description {
      font-size: 1.25rem;
      font-weight: 100;
    }
    &--btn {
      background: ${({ theme }) => theme.player.btnServicePack};
      font-size: 2rem;
      margin-top: 1.25rem;
      padding: 0.5rem 4rem;
      color: #fff;
      border: none;
      span {
        text-transform: capitalize;
      }
    }
  }

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
    .v-loginLive {
    }
  }

  @media (min-width: 992px) {
    .v-loginLive {
    }
  }
`;

const mapStateToProps = state => ({
  account: state.auth.account,
  language: state.root.language,
});
export default withRouter(connect(mapStateToProps, null)(withTranslation()(LoginLivePlayer)));
