export const BANNER = 0;
export const THUMBNAIL = 1;
export const POSTER = 2;
export const CLASS_0 = 'banner';
export const CLASS_1 = 'thumbnail';
export const CLASS_2 = 'poster';
export const CLASS_3 = 'thumbnail';
export const CLASS_4 = 'thumbnail';
export const CLASS_5 = 'poster';
export const CLASS_6 = 'poster';

export const MOVIE_TYPE = 1;
export const SHOW_TYPE = 2;
export const SEASON_TYPE = 3;
export const EPISODE_TYPE = 4;
export const CHANNEL_TYPE = 5;
export const CLIP = 6;
export const CURATED_LIVE = 7;

export const TYPE_MASTER_THUMBNAIL = 0;
export const TYPE_THUMBNAIL = 2;
export const TYPE_NEW_RELEASE = 4;
export const TYPE_RECENT_MOVIE = 5;
export const TYPE_TOP_CONTENT = 6;
export const TYPE_REPLICATED = 7;
export const TYPE_AUTOMATIC = 8;
export const PAID_TVOD_CONTENT = 9;

export const DISPLAY_0 = 'Master-thumnail';
export const DISPLAY_2 = 'Thumnail';
export const DISPLAY_4 = 'New-release';
export const DISPLAY_5 = 'Recent';
export const DISPLAY_6 = 'Top10';
export const DISPLAY_7 = 'Replicated';
export const DISPLAY_8 = 'Automatic';
export const DISPLAY_9 = 'Paid-TVOD-Content';

export const TVOD = 'tvod';
export const SVOD = 'svod';
export const FREE = 'free';
