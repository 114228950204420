import * as homePageApi from '../../api/homePageApi';
import queryString from 'query-string';
import * as metaTagActions from '~components/metaTags/actions';
import { isProjectW } from '~core/method/authMethod';

function getRibbonDetail(pageSlug, ribbonSlug, search) {
  const queryParams = queryString.parse(search);
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (queryParams.type !== '5' && ribbonSlug !== 'watching_list') {
        homePageApi
          .getRibbonDetail(pageSlug, ribbonSlug, search)
          .then(response => {
            dispatch(metaTagActions.updateSeo(response.seo || {}));
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      } else {
        homePageApi
          .getRibbonDetailRecent(pageSlug, ribbonSlug, search)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      }
    });
  };
}

export { getRibbonDetail };
