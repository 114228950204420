var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OdxContentListingApi = void 0;
var constants_1 = require("../constants");
var content_listing_requests_1 = require("../requests/content-listing-requests");
var integration_service_1 = require("../integration-service");
var content_listing_parser_1 = require("../parser/content-listing-parser");
var utils_1 = require("../utils");
var ribbon_utils_1 = require("../utils/ribbon-utils");
var content_utils_1 = require("../utils/content-utils");
var mappers_1 = require("../mappers");
var cacheable_requests_1 = require("../requests/cacheable-requests");
var OdxContentListingApi = /** @class */ (function (_super) {
    __extends(OdxContentListingApi, _super);
    function OdxContentListingApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OdxContentListingApi.prototype.getAccountPaidContents = function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, type, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentListingApi.prototype.getChannelEpgs = function (acceptLanguage, page, limit, select, scheduleDate, startTime, endTime, channelId, timezone, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentListingApi.prototype.getContentsByMetadata = function (metadataSlug, tenantSlug, metadata, authorization, acceptLanguage, page, limit, select, imageResolutionScale, imageFormat, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentListingApi.prototype.getListClipHay = function (contentId, authorization, acceptLanguage, page, limit, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentListingApi.prototype.getListLiveChannel = function (authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentListingApi.prototype.getLiveSchedulesForPage = function (pageSlug, platformSlug, tenantSlug, startTime, endTime, authorization, acceptLanguage, select, imageResolutionScale, ribbonIds, timezone, mode, page, limit, adParams, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var xAdParams, isLat, did, adid, schedules, e_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        xAdParams = integration_service_1.OdxIntegration.currentProvider.get('adParams');
                        isLat = (0, utils_1.getValueOfKeyPairString)(xAdParams, 'is_lat');
                        did = (0, utils_1.getValueOfKeyPairString)(xAdParams, 'did');
                        adid = (0, utils_1.getValueOfKeyPairString)(xAdParams, 'adid');
                        return [4 /*yield*/, this.odxService.odxEpgApi.epg(tenantSlug, acceptLanguage, undefined, undefined, undefined, startTime ? new Date(startTime * 1000).toISOString() : undefined, endTime ? new Date(endTime * 1000).toISOString() : undefined, !startTime || !endTime ? 'channel_only' : undefined, (page !== null && page !== void 0 ? page : 0) + 1, limit !== null && limit !== void 0 ? limit : 10, isLat ? decodeURIComponent(isLat) : undefined, did ? decodeURIComponent(did) : undefined, adid ? decodeURIComponent(adid) : undefined)];
                    case 1:
                        schedules = _b.sent();
                        resolve(__assign(__assign({}, schedules), { data: (0, content_listing_parser_1.ParsePageLiveSchedulesFromOdxChannels)((_a = schedules.data.result) === null || _a === void 0 ? void 0 : _a.results) }));
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _b.sent();
                        reject((0, utils_1.transformError)(e_1));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxContentListingApi.prototype.getPagePersonalizedRibbons = function (accountId, tenantSlug, pageSlug, authorization, acceptLanguage, select, imageResolutionScale, platformSlug, page, limit, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, imageFormat, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                try {
                    resolve({
                        config: null,
                        status: 200,
                        statusText: 'OK',
                        data: {
                            ribbons: [],
                            banners: [],
                        },
                        headers: null,
                        request: null,
                    });
                }
                catch (e) {
                    reject((0, utils_1.transformError)(e));
                }
                return [2 /*return*/];
            });
        }); });
    };
    OdxContentListingApi.prototype.getPageStaticRibbons = function (tenantSlug, pageSlug, acceptLanguage, select, page, limit, imageResolutionScale, platformSlug, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, imageFormat, date, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var data, _a, e_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 8, , 9]);
                        data = void 0;
                        _a = pageSlug;
                        switch (_a) {
                            case constants_1.ODXKey.tvGuideSlug: return [3 /*break*/, 1];
                            case constants_1.ODXKey.homePageSlug: return [3 /*break*/, 3];
                        }
                        return [3 /*break*/, 5];
                    case 1: return [4 /*yield*/, (0, content_listing_requests_1.getPageStaticRibbonsForTvGuide)(this.odxService, tenantSlug, pageSlug, date, acceptLanguage)];
                    case 2:
                        data = _b.sent();
                        return [3 /*break*/, 7];
                    case 3: return [4 /*yield*/, (0, content_listing_requests_1.getPageStaticRibbonsForHomePage)(this.odxService, tenantSlug, pageSlug, acceptLanguage)];
                    case 4:
                        data = _b.sent();
                        return [3 /*break*/, 7];
                    case 5: return [4 /*yield*/, (0, content_listing_requests_1.getPageStaticRibbonsForOdxCategoryPage)(this.odxService, tenantSlug, pageSlug, acceptLanguage)];
                    case 6:
                        data = _b.sent();
                        return [3 /*break*/, 7];
                    case 7:
                        resolve(data);
                        return [3 /*break*/, 9];
                    case 8:
                        e_2 = _b.sent();
                        reject((0, utils_1.transformError)(e_2));
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxContentListingApi.prototype.getPaidContents = function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, type, contentIds, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentListingApi.prototype.getPersonalizedRibbonDetail = function (accountId, pageSlug, ribbonId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, platformSlug, page, limit, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, imageFormat, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentListingApi.prototype.getRecommendedOfContent = function (contentId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, page, limit, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var _a, id, slug, type, episodeId, movieInfoResponse, movieInfo, result, e_3;
            var _b, _c, _d, _e, _f;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        _g.trys.push([0, 4, , 5]);
                        _a = (0, content_utils_1.getIdInfoFromContentIdentity)(contentId), id = _a.id, slug = _a.slug, type = _a.type;
                        episodeId = id;
                        if (!(type === 'movie')) return [3 /*break*/, 2];
                        return [4 /*yield*/, cacheable_requests_1.CacheableRequests.getProgramInformation(slug, tenantSlug, acceptLanguage)];
                    case 1:
                        movieInfoResponse = _g.sent();
                        movieInfo = (_b = movieInfoResponse === null || movieInfoResponse === void 0 ? void 0 : movieInfoResponse.data) === null || _b === void 0 ? void 0 : _b.result;
                        episodeId = (_c = movieInfo === null || movieInfo === void 0 ? void 0 : movieInfo.direct_play_episode) === null || _c === void 0 ? void 0 : _c.id;
                        _g.label = 2;
                    case 2: return [4 /*yield*/, this.odxService.odxEpisodeRecommendationApi.episodeRecommendationRetrieve(episodeId.toString(), tenantSlug, undefined, acceptLanguage, undefined)];
                    case 3:
                        result = _g.sent();
                        resolve(__assign(__assign({}, result), { data: (_f = (_e = (_d = result === null || result === void 0 ? void 0 : result.data) === null || _d === void 0 ? void 0 : _d.result) === null || _e === void 0 ? void 0 : _e.recommendations) === null || _f === void 0 ? void 0 : _f.map(mappers_1.mapOdxRecommendationProgramItemToRecommendationContent) }));
                        return [3 /*break*/, 5];
                    case 4:
                        e_3 = _g.sent();
                        reject((0, utils_1.transformError)(e_3));
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxContentListingApi.prototype.getRelatedContentById = function (relatedByContentId, authorization, acceptLanguage, page, limit, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentListingApi.prototype.getRelatedContentsOfContent = function (contentId, tenantSlug, authorization, acceptLanguage, imageResolutionScale, select, page, limit, expectTypes, displayType, hasTopContents, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentListingApi.prototype.getRibbonDetail = function (pageSlug, ribbonId, tenantSlug, authorization, acceptLanguage, page, limit, imageResolutionScale, platform, select, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, secret, imageFormat, date, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var data, type, _a, e_4;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 6, , 7]);
                        data = void 0;
                        type = (0, ribbon_utils_1.getFilterOptionsFromRibbonSlug)(ribbonId).type;
                        _a = type;
                        switch (_a) {
                            case 'tv-guide': return [3 /*break*/, 1];
                        }
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, (0, content_listing_requests_1.getRibbonDetailForOdxTvGuidePage)(this.odxService, pageSlug, ribbonId, date, tenantSlug, acceptLanguage)];
                    case 2:
                        data = _b.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, (0, content_listing_requests_1.getRibbonDetailForOdxCategoryPage)(this.odxService, pageSlug, ribbonId, tenantSlug, acceptLanguage, page, limit)];
                    case 4:
                        data = _b.sent();
                        _b.label = 5;
                    case 5:
                        resolve(data);
                        return [3 /*break*/, 7];
                    case 6:
                        e_4 = _b.sent();
                        reject((0, utils_1.transformError)(e_4));
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxContentListingApi.prototype.getRibbonItems = function (ribbonId, authorization, acceptLanguage, page, limit, platform, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentListingApi.prototype.getRibbonTypeById = function (id, tenantSlug, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentListingApi.prototype.getRibbonTypes = function (tenantSlug, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentListingApi.prototype.getSearchResult = function (keyword, authorization, acceptLanguage, page, limit, tenantId, tenantSlug, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentListingApi.prototype.getSeasonDetailById = function (contentId, authorization, acceptLanguage, userId, page, limit, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentListingApi.prototype.getSeasonIndexOfShow = function (contentId, authorization, season, page, limit, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentListingApi.prototype.getSeasonsOfShow = function (showId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentListingApi.prototype.getTenantPageDetail = function (pageId, authorization, acceptLanguage, platform, regionSlug, genreSlug, languageSlug, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentListingApi.prototype.getUserHistory = function (authorization, acceptLanguage, resolution, size, cursor, xhr, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentListingApi.prototype.getUserRecentContent = function (authorization, acceptLanguage, resolution, size, xhr, options) {
        return Promise.reject('Method not implemented');
    };
    return OdxContentListingApi;
}(integration_service_1.OdxIntegration));
exports.OdxContentListingApi = OdxContentListingApi;
