import videojs from 'video.js';
const MenuItem = videojs.getComponent('MenuItem');
const Component = videojs.getComponent('Component');

class SourceMenuItem extends MenuItem {
  constructor(player, { handleMenuItemClick, ...options }) {
    // options.selectable = true;
    // options.multiSelectable = false;
    super(player, options);
    this.handleMenuItemClick = handleMenuItemClick;
  }

  handleClick(event) {
    var selected = this.options_;
    console.log('Changing quality to:', selected.label);
    // super.handleClick();
    this.handleMenuItemClick(event);
  }

  update() {
    var selectedIndex = this.player().qualityLevels().selectedIndex;

    this.selected(this.options_.index == selectedIndex);
  }
}

Component.registerComponent('SourceMenuItem', SourceMenuItem);
export default SourceMenuItem;
