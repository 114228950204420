import * as types from './actionTypes';
import { IRootState } from './reducers';

function initialApp() {
  return {
    type: types.INITIAL_APP,
  };
}

function loadingLocalStogare() {
  return {
    type: types.LOADING_LOCAL_STORAGE,
  };
}

function authencate(isAuth: IRootState['isAuth']) {
  return {
    type: types.AUTHENTICATE,
    payload: { isAuth },
  };
}

function changeLanguage(language: IRootState['language']) {
  return {
    type: types.CHANGE_LANGUAGE,
    payload: {
      language,
    },
  };
}
function isBlockCountries(isBlock: IRootState['isBlock']) {
  return {
    type: types.COUNTRIES_BLOCK,
    payload: {
      isBlock,
    },
  };
}
function updateTenantSlug(tenantSlug: IRootState['tenantSlug']) {
  return {
    type: types.UPDATE_TENANT_SLUG,
    payload: {
      tenantSlug,
    },
  };
}

function updateMuxKey(muxKey: IRootState['muxKey']) {
  return {
    type: types.UPDATE_MUX_KEY,
    payload: {
      muxKey,
    },
  };
}

function updateRegionSubcription(isFollowSubcription: IRootState['isFollowSubcription']) {
  return {
    type: types.UPDATE_REGION_SUBCRIPTION,
    payload: {
      isFollowSubcription,
    },
  };
}

export {
  initialApp,
  loadingLocalStogare,
  authencate,
  changeLanguage,
  isBlockCountries,
  updateTenantSlug,
  updateMuxKey,
  updateRegionSubcription,
};
