import React, { Component } from 'react';
import { connect } from 'react-redux';
import PrivacyPolicy from '../box/components/privacyPolicyPopup';
import TermOfUse from '../box/components/termOfUsePopup';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { firebase } from '../../services/firebase/firebase';
import { pushAnalyticsNotSlug } from '../../services/analytics/Analytics';
import TagManager from 'react-gtm-module';
import { REACT_APP_URL_PRIVACY_POLICY, REACT_APP_URL_DO_NOT_SELL_INFORMATION } from './constants';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getConfigByKey } from '../tenantConfig/services';
import FooterSctv2 from './footerSctv2';
import { DivStyled } from './styles/styled';

class FooterV2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      privacyPolicyModalShow: false,
      termOfUseModalShow: false,
    };
  }

  render() {
    const { privacyPolicyModalShow, termOfUseModalShow } = this.state;
    const { t, isFollowSubcription } = this.props;
    if (!isFollowSubcription || !getConfigByKey('features.subscriptionFeature')) {
      return null;
    }
    if (getConfigByKey('tenant_slug') === 'sctv') {
      if (!getConfigByKey('features.subscriptionFeature')) return null;
      return (
        <DivStyled>
          <FooterSctv2 t={t} />
        </DivStyled>
      );
    }
    return !getConfigByKey('features.bottom_bar_information', true) ? null : (
      <RowStyled className="v-footer">
        <div className="v-footer--contact">
          <Link to="/help" style={{ color: '#283237', margin: ' 0 0 10px', fontSize: 18 }}>
            {t('subcriptions.footer.ContactUs')} :{' '}
          </Link>
          <p className="v-footer--p">
            <i className="fa fa-phone" aria-hidden="true" style={{ color: '#283237' }} />
            <span style={{ color: '#283237' }}> : </span>
            {getConfigByKey('phone_number_1')}
          </p>
          <p className="v-footer--p">
            <i className="fa fa-commenting" aria-hidden="true" style={{ color: '#283237' }}>
              {' '}
              :{' '}
            </i>{' '}
            {getConfigByKey('phone_number_2')}
          </p>
          <p className="v-footer--p">
            <i className="fa fa-envelope-o" aria-hidden="true" style={{ color: '#283237' }}>
              {' '}
              :{' '}
            </i>{' '}
            {getConfigByKey('email')}
          </p>
        </div>
        <div className="v-footer--term">
          <div className="v-footer--policy">
            <a
              className="v-footer--link"
              target="_blank"
              href={REACT_APP_URL_PRIVACY_POLICY}
              onClick={() => {
                pushAnalyticsNotSlug('click', {
                  content_type: 'button',
                  item_name: 'Privacy Policy',
                });
              }}
            >
              {t('subcriptions.privacy policy')}
            </a>
            <div
              className="v-footer--link"
              onClick={() => {
                pushAnalyticsNotSlug('click', {
                  content_type: 'button',
                  item_name: 'Term Of Use ',
                });
                this.setState({ termOfUseModalShow: true });
              }}
            >
              {t('subcriptions.footer.termsOfUse')}
            </div>
            <a
              className="v-footer--link"
              target="_blank"
              href={REACT_APP_URL_DO_NOT_SELL_INFORMATION}
            >
              {t('subcriptions.footer.doNotSell')}
            </a>
          </div>
          <div className="v-footer--address">
            <p>{getConfigByKey('address.copyrightV2')}</p>
          </div>
        </div>
        <PrivacyPolicy
          modalShow={privacyPolicyModalShow}
          showModal={() => this.setState({ privacyPolicyModalShow: false })}
        />

        <TermOfUse
          modalShow={termOfUseModalShow}
          showModal={() => this.setState({ termOfUseModalShow: false })}
        />
      </RowStyled>
    );
  }
}

const RowStyled = styled.div`
  &.v-footer {
    display: flex;
    flex-direction: column;
  }
  .v-footer {
    &--p {
      color: #596d79;
    }
    &--contact {
      padding-left: 0;
      text-align: center;
    }
    &--term {
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      width: 100%;
      text-transform: capitalize;
    }
    &--policy {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
    }
    &--address {
      color: #596d79;
      padding: 0.5rem 0 1rem 0;
      text-align: center;
    }
    &--link {
      padding: 0.5rem 1.5rem;
      cursor: pointer;
      color: #596d79;
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
    .v-footer {
    }
  }
  @media (min-width: 992px) {
    &.v-footer {
      display: flex;
      flex-direction: row;
    }
    .v-footer {
      &--contact {
        position: relative;
        padding-left: 2rem;
        text-align: left;
      }
      &--term {
        position: absolute;
        bottom: 0;
      }
    }
  }
  @media (max-width: 1024px) and (orientation: landscape) {
    &.v-footer {
      display: flex;
      flex-direction: column;
    }
    .v-footer {
      &--contact {
        padding-left: 0;
        text-align: center;
      }
      &--term {
        display: flex;
        flex-direction: column;
        position: relative;
      }
    }
  }
  @media (min-width: 1200px) {
  }
`;
const mapStateToProps = state => ({
  isFollowSubcription: state.root.isFollowSubcription,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FooterV2)),
);
