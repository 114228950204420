import videojs from 'video.js';
import { _addAdsVideo } from '../function';
import jquery from 'jquery';

// Get the Component base class from Video.js
var BigPlayButton = videojs.getComponent('BigPlayButton');

// The videojs.extend function is used to assist with inheritance. In
// an ES6 environment, `class SeekNext extends Component` would work
// identically.

var ButtonPlay = videojs.extend(BigPlayButton, {
  // The constructor of a component receives two arguments: the
  // player it will be associated with and an object of options.
  constructor: function (player, options) {
    // It is important to invoke the superclass before anything else,
    // to get all the features of components out of the box!
    BigPlayButton.apply(this, arguments);
    this.renderAds = false;
    const $player = jquery(player.el_);
    player.one('loadeddata', () => {
      // this.handleClick()
    });
    $player.addClass('vjs-has-started');
    player.on('playing', () => {
      try {
        if (!this.renderAds) {
          // player.play();
          $player.parent().find('.vjs-play-button-request').addClass('hide');
        }
      } catch (error) {}
    });
    // If a `text` option was passed in, update the text content of
    // the component.
  },

  // The `createEl` function of a component creates its DOM element.
  createEl: function () {
    const btn = videojs.dom.createEl('button', {
      className: 'vjs-play-button-request',
    });
    btn.innerHTML = '<span class="vjs-icon-play" aria-hidden="true"></span>';
    return btn;
  },
  handleClick: function (event) {
    this.renderAds = true;
    const { component } = this.options_;
    const $player = jquery(this.el());
    if (!this.player_) return;
    if (!$player.hasClass('vjs-has-started')) $player.addClass('vjs-has-started');
    let timer = setInterval(() => {
      this.player_.play();
      clearInterval(timer);
    }, 500);
    $player.parent().find('.vjs-play-button-request').addClass('hide');
    _addAdsVideo(component, 1);
  },
});

// Register the component with Video.js, so it can be used in players.
videojs.registerComponent('ButtonPlay', ButtonPlay);
