import React from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getReactedContents } from '../../../../features/homepage/services';
import AddList from './actionButtons/AddList';
import Share from './actionButtons/Share';
import Like from './actionButtons/Like';
import Remove from './actionButtons/Remove';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const ActionButton = props => {
  const {
    t,
    ribbon: { type, display_type },
    link,
    history,
    item,
    language,
    handleRemoveRecent,
  } = props;
  const buttonPlay = () => (
    <div
      className="button"
      onClick={() =>
        history.push({
          pathname: `/${language}${link}`,
          state: { type: item.type },
        })
      }
    >
      <div className="button--wrap">
        <i className="fa fa-play" aria-hidden="true" />
      </div>
    </div>
  );
  const buttonDetail = () => (
    <TooltipWrap title={t('ribbon.detail')} arrow placement="top">
      <Link className="button" to={`/${language}/landing/${item.slug}`}>
        <div className="button--wrap">
          <i className="fa fa-info-circle" aria-hidden="true" />
        </div>
      </Link>
    </TooltipWrap>
  );
  if (type !== 5 && (display_type === 2 || display_type === 5 || display_type === 6)) {
    return (
      <ActionButtonWrap className="actions" onClick={e => e.stopPropagation()}>
        {buttonPlay()}
        <AddList item={item} />
        <Like item={item} />
        <Share item={item} target="landing" />
        {buttonDetail()}
      </ActionButtonWrap>
    );
  }

  return (
    <ActionButtonWrap className="actions" onClick={e => e.stopPropagation()}>
      <div className="actions-left">
        {buttonPlay()}
        <AddList item={item} />
        <Like item={item} />
      </div>
      <div className="actions-right">
        <Share item={item} target="landing" />
        {buttonDetail()}
        {type === 5 && (
          <Remove ribbon={props.ribbon} item={item} handleRemoveRecent={handleRemoveRecent} />
        )}
      </div>
    </ActionButtonWrap>
  );
};

export const TooltipWrap = withStyles({
  arrow: {
    '&:before': {
      border: '1px solid #ffffff',
    },
    color: 'white',
  },
  tooltip: {
    backgroundColor: 'white',
    border: '1px solid #ffffff',
    color: '#2b2b2b',
    fontSize: '14px',
  },
})(Tooltip);

const ActionButtonWrap = styled.div`
  &.actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    z-index: 111;
    .actions-left,
    .actions-right {
      display: flex;
    }
    .button {
      width: 30%;
      cursor: pointer;
      border: 1px solid white;
      border-radius: 50%;
      text-align: center;
      position: relative;
      &--wrap {
        padding-top: 100%;
      }
      svg,
      i {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        font-size: 14px;
      }
      svg {
        width: 75%;
        margin: 0 auto;
      }
      &:hover {
        color: white;
        background-color: ${({ theme }) => theme.primaryColor};
        border: unset;
      }
      .fa-play {
        margin-left: 2px;
      }
    }
    .actions-left {
      justify-content: flex-start;
      .button:not(:last-child) {
        margin-right: 7px;
      }
    }
    .actions-right {
      justify-content: flex-end;
      .button:not(:first-child) {
        margin-left: 7px;
      }
    }
    .liked {
      border-color: ${({ theme }) => theme.primaryColor};
      color: ${({ theme }) => theme.primaryColor};
    }
  }
`;

const mapDispatchToProps = {
  dpGetReactedContents: params => getReactedContents(params),
};

const mapStateToProps = state => ({
  account: state.auth.account,
  reactedContents: state.home.reactedContents,
  language: state.root.language,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ActionButton)),
);
