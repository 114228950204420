import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as payperviewService from '../../services';
import { loading } from '~features/loadingPage/services';
import { useTranslation } from 'react-i18next';
import { wFirebaseEvents } from '~services/analytics';

function PaymentFreeTicket(props) {
  const {
    state,
    typePayment,
    idPrices,
    getRemaTime,
    postSubscriptionsPayperview,
    dpLoading,
    confirmPaypal,
    getDetailContent,
    openModalSuccess,
    paymentProvider = '',
    setPaymentError,
    userProfile,
    priceFocus,
  } = props;
  const { t } = useTranslation();
  let contentId = state.infoShow ? state.infoShow.id : state.entityDetail.id;

  const paymentTicket = () => {
    dpLoading(true);
    if (!priceFocus.id || !priceFocus?.type) {
      dpLoading(false);
      return;
    }
    let data = {
      payment_provider: 'token',
      content_id: contentId,
      tvod_type: priceFocus?.type || undefined,
      price_id: priceFocus.id || undefined,
    };
    // data = setDataPaySubcription(data)
    postSubscriptionsPayperview(data)
      .then(res => {
        dpLoading(false);
        openModalSuccess();
        wFirebaseEvents.ppvIlluonCreditPaymentCompletedEvent({
          content_id: contentId,
          email: userProfile.email,
        });
      })
      .catch(err => {
        setPaymentError(true);
      })
      .finally(() => {
        dpLoading(false);
      });
  };

  return (
    <Button onClick={paymentTicket} id="linkBootpay" className={`project-w--watchmore freeTicket`}>
      {t('projectw__PPV.paymentxt.payment_btn_big3')}
    </Button>
  );
}

const mapStateToProps = state => ({
  userProfile: state.auth.account.profile,
});

const mapDispatchToProps = {
  postSubscriptionsPayperview: params => payperviewService.postSubscriptionsPayperview(params),
  postConfirmPaypalPayperview: params => payperviewService.postConfirmPaypalPayperview(params),
  dpLoading: value => loading(value),
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentFreeTicket);
