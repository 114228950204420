import React from 'react';
import RibbonItem from '../../../components/ribbon/components/ribbonItem';
import Slider from 'react-slick';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getRibbonDetail } from '../../ribbon/services';
import { getRelatedRibbons, getInfoDetail, getMoreLiveThis } from '../services';
import Loader from 'react-loader-spinner';
import { withRouter, Link } from 'react-router-dom';
import * as type from '../constants';
import Ribbons from './../../../components/ribbon/styled';
import { settingSlide } from '../../../components/ribbon/functions/initSlide';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getConfigByKey } from '~features/tenantConfig/services';

class Related extends React.PureComponent {
  constructor(props) {
    super(props);
    this.lazyLoad = true;
    this.sliderRef = React.createRef();
    this.state = {
      activeSlide: 0,
      ribbon: [],
      loading: true,
      relatedRibbonsId: '',
      idTarget: [],
      viewMore: 40,
      ribbonItems: [],
      page: 1,
      hasMoreItems: true,
    };
  }

  componentDidMount() {
    const { entityDetail, infoShow, target, previewLive, dispatchGetMoreLiveThis, method } =
      this.props;
    let idContent = target ? previewLive.channelId : (infoShow && infoShow.id) || entityDetail.id;
    let typeContent = target ? 1 : (infoShow && 2) || 1;
    let search = `?expect_types=${typeContent}&page=0&limit=24`;
    dispatchGetMoreLiveThis(idContent, {
      expect_types: typeContent,
      page: 0,
      limit: 24,
    })
      .then(res => {
        this.setState({
          ribbon: res,
          ribbonItems: res.items,
        });
        if (!res.items.length) {
          method && method.setNondata(true);
        }
      })
      .catch(() => {
        method && method.setNondata(true);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  handleLazyLoad = (curentSilde = null) => {
    const { entityDetail, infoShow, target, previewLive, dispatchGetMoreLiveThis } = this.props;
    return;
    const { ribbonItems, page, hasMoreItems, viewMore, idTarget } = this.state;
    let typeContent = target ? 1 : (infoShow && 2) || 1;
    let search = `?expect_types=${typeContent}&page=${page}&limit=24`;
    let idContent = target ? previewLive.channelId : (infoShow && infoShow.id) || entityDetail.id;
    const { innerSlider } = this.sliderRef.current;
    const total = ribbonItems.length;
    const slideScroll = innerSlider.props.slidesToScroll;
    let restSilde = total - (slideScroll + curentSilde);
    if (!hasMoreItems || restSilde > 3) {
      return;
    }

    dispatchGetMoreLiveThis(idContent, search)
      .then(res => {
        const data = ribbonItems;
        data.push(...res.items);
        if (res.items.length <= 0 || data.length >= viewMore) {
          return this.setState({
            hasMoreItems: false,
          });
        }
        this.setState({
          ribbonItems: data,
          page: page + 1,
        });
      })
      .catch(() => {})
      .finally(() => {});
  };

  _renderRibbonItem(ribbon) {
    const { items } = ribbon;
    const { entityDetail, infoShow } = this.props;
    const { idTarget, viewMore, ribbonItems } = this.state;
    const params = this.props.match.params;
    let slug = params.slug;
    ribbon.display_type = 1;
    if (entityDetail && entityDetail.type === type.EPISODE_TYPE && infoShow) {
      slug = infoShow.slug;
    }
    this.lazyLoad = false;
    if (ribbonItems && ribbonItems.length > 0) {
      return items.slice(0, viewMore).map((item, index) => {
        let linkDetail = true;
        if (slug && slug === item.slug) {
          return;
        }
        if (idTarget.length > 0) {
          ribbon.slug = idTarget[0].slug;
        }
        return (
          <RibbonItem
            item={item}
            ribbon={ribbon}
            index={index}
            key={index}
            linkDetail={linkDetail}
            lazyLoad={this.lazyLoad}
          />
        );
      });
    }
    return null;
  }

  render() {
    const { ribbonItems, ribbon, loading } = this.state;
    const { t, target } = this.props;
    const sliderSettings = settingSlide(this, target ? target : 'related');

    return (
      <div
        className="v-detailPage__recommand "
        style={{
          position: getConfigByKey('tenant_slug') === 'sctv' && 'static',
        }}
      >
        <Ribbons
          className={`sliderBlock ribbonContainer slider-${
            type[`CLASS_${ribbon.display_type || 1}`]
          }`}
        >
          {ribbonItems.length > 0 ? (
            <Slider {...sliderSettings} ref={this.sliderRef}>
              {this._renderRibbonItem(ribbon)}
            </Slider>
          ) : (
            <div className="v-detailPage__loading">
              {loading ? (
                <Loader type="Oval" color="#FFFFFF" height="30" width="30" />
              ) : (
                <p className="error-data">{t('notDataToShow')}</p>
              )}
            </div>
          )}
        </Ribbons>
      </div>
    );
  }
}

Related.defaultProps = {
  ribbon: [],
};

const mapStateToProps = state => ({
  ribbonTarget: state.home.ribbonTarget,
  page: state.home.page,
  menu: state.home.menu,
});

const mapDispatchToProps = {
  dispatchGetRibbon: (pageSlug, ribbonSlug, search) =>
    getRibbonDetail(pageSlug, ribbonSlug, search),
  dbGetRelatedRibbons: id => getRelatedRibbons(id),
  dpGetInfoDetail: slug => getInfoDetail(slug),
  dispatchGetMoreLiveThis: (id, params) => getMoreLiveThis(id, params),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Related)));
