import LogoChanel from '../logoChanel';
import ReactDOM from 'react-dom';
import videojs from 'video.js';
import React from 'react';

const vjsComponent = videojs.getComponent('Component');

class Logo extends vjsComponent {
  constructor(player, options) {
    super(player, options);

    /* Bind the current class context to the mount method */
    this.mount = this.mount.bind(this);

    /* When player is ready, call method to mount React component */
    player.ready(() => {
      this.mount();
    });

    /* Remove React root when component is destroyed */
    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }

  createEl() {
    const div = videojs.dom.createEl('div', {
      className: 'vjs--logo__chanel',
      id: 'vjs--logo__chanel',
    });
    return div;
  }

  mount() {
    const { component } = this.options_;

    ReactDOM.render(
      <LogoChanel
        component={component}
        player={this.player_}
        fullScreen={this.fullScreen}
        body="logo"
      />,
      this.el(),
    );
  }
}

/**
 * Make sure to register the vjsComponent so Video JS knows it exists
 */
vjsComponent.registerComponent('Logo', Logo);

export default Logo;
