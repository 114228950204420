var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OdxContentApi = void 0;
var integration_service_1 = require("../integration-service");
var parser_1 = require("../parser");
var content_utils_1 = require("../utils/content-utils");
var content_requests_1 = require("../requests/content-requests");
var utils_1 = require("../utils");
var OdxContentApi = /** @class */ (function (_super) {
    __extends(OdxContentApi, _super);
    function OdxContentApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OdxContentApi.prototype.createUserContentReport = function (contentId, tenantSlug, data, authorization, acceptLanguage, platform, version, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.deleteContentReviewDetail = function (reviewId, tenantSlug, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.getChannelLiveInfo = function (channelId, tenantSlug, timezone, select, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.getContentDeepLink = function (contentId, tenantSlug, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.getContentDetail = function (contentId, tenantSlug, acceptLanguage, select, imageResolutionScale, platform, dataType, imageFormat, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var result, _a, id, slug, type, _b, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 8, , 9]);
                        result = void 0;
                        _a = (0, content_utils_1.getIdInfoFromContentIdentity)(contentId), id = _a.id, slug = _a.slug, type = _a.type;
                        _b = type;
                        switch (_b) {
                            case 'live_channel': return [3 /*break*/, 1];
                            case 'trailer': return [3 /*break*/, 3];
                            case 'season': return [3 /*break*/, 3];
                            case 'episode': return [3 /*break*/, 3];
                        }
                        return [3 /*break*/, 5];
                    case 1: return [4 /*yield*/, (0, content_requests_1.getContentDetailForLiveChannel)(this.odxService, id.toString(), tenantSlug, acceptLanguage)];
                    case 2:
                        result = _c.sent();
                        return [3 /*break*/, 7];
                    case 3: return [4 /*yield*/, (0, content_requests_1.getContentDetailForChildrenContent)(this.odxService, contentId, tenantSlug, acceptLanguage)];
                    case 4:
                        result = _c.sent();
                        return [3 /*break*/, 7];
                    case 5: return [4 /*yield*/, (0, content_requests_1.getContentDetailForProgram)(this.odxService, id, slug, tenantSlug, acceptLanguage)];
                    case 6:
                        result = _c.sent();
                        _c.label = 7;
                    case 7:
                        resolve(result);
                        return [3 /*break*/, 9];
                    case 8:
                        e_1 = _c.sent();
                        reject((0, utils_1.transformError)(e_1));
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxContentApi.prototype.getContentDetailById = function (contentId, authorization, acceptLanguage, resolution, size, platform, xhr, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.getContentNavigationOption = function (tenantSlug, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.getContentRelatedRibbons = function (contentId, tenantSlug, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.getContentReportTemplates = function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, locale, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.getContentReviewDetail = function (reviewId, tenantSlug, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.getContentScrubberTemplate = function (contentId, tenantSlug, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.getContentScrubberThumbnails = function (contentId, tenantSlug, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.getContentUserReviews = function (contentId, tenantSlug, authorization, acceptLanguage, page, limit, select, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.getContentViewInfo = function (contentId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, dataType, adParams, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var result, _a, id, type, _b, e_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 8, , 9]);
                        result = void 0;
                        _a = (0, content_utils_1.getIdInfoFromContentIdentity)(contentId), id = _a.id, type = _a.type;
                        _b = type;
                        switch (_b) {
                            case 'live_channel': return [3 /*break*/, 1];
                            case 'episode': return [3 /*break*/, 3];
                            case 'season': return [3 /*break*/, 3];
                            case 'trailer': return [3 /*break*/, 3];
                        }
                        return [3 /*break*/, 5];
                    case 1: return [4 /*yield*/, (0, content_requests_1.getContentViewInfoForChannel)(this.odxService, id.toString(), tenantSlug, acceptLanguage, adParams)];
                    case 2:
                        result = _c.sent();
                        return [3 /*break*/, 7];
                    case 3: return [4 /*yield*/, (0, content_requests_1.getContentViewInfoForChildrenContent)(this.odxService, contentId, tenantSlug, acceptLanguage, adParams)];
                    case 4:
                        result = _c.sent();
                        return [3 /*break*/, 7];
                    case 5: return [4 /*yield*/, (0, content_requests_1.getContentViewInfoForProgram)(this.odxService, contentId, tenantSlug, acceptLanguage)];
                    case 6:
                        result = _c.sent();
                        _c.label = 7;
                    case 7:
                        resolve(result);
                        return [3 /*break*/, 9];
                    case 8:
                        e_2 = _c.sent();
                        reject((0, utils_1.transformError)(e_2));
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxContentApi.prototype.getDetailByContentId = function (contentId, authorization, acceptLanguage, resolution, size, platform, xhr, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.getHomePage = function (platformSlug, tenantSlug, authorization, acceptLanguage, regionSlug, genreSlug, languageSlug, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.getPageContentFilter = function (pageSlug, tenantSlug, acceptLanguage, select, locale, filterForSideNavigationSection, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.getParentProgramOfContent = function (contentId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, imageFormat, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.getRokuFeedSchemaJsonFormat = function (tenantSlug, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.getSearchContents = function (tenantSlug, q, authorization, acceptLanguage, page, limit, select, imageResolutionScale, imageFormat, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var searchData, e_3;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.odxService.odxSearchApi.searchList(tenantSlug, acceptLanguage, undefined, q, undefined, undefined, undefined, undefined, undefined, !isNaN(page) ? page + 1 : undefined, limit)];
                    case 1:
                        searchData = _b.sent();
                        resolve(__assign(__assign({}, searchData), { data: (0, parser_1.ParseSearchFromOdxSearch)(q, (_a = searchData === null || searchData === void 0 ? void 0 : searchData.data) === null || _a === void 0 ? void 0 : _a.result) }));
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _b.sent();
                        reject((0, utils_1.transformError)(e_3));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxContentApi.prototype.getSeasonDetail = function (seasonId, tenantSlug, page, limit, select, imageResolutionScale, imageFormat, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var slug, acceptLanguage, seasonData, e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        slug = (0, content_utils_1.getIdInfoFromContentIdentity)(seasonId).slug;
                        acceptLanguage = this.provider.get('acceptLanguage');
                        return [4 /*yield*/, (0, content_requests_1.getSeasonDetailRequest)(this.odxService, slug, tenantSlug, acceptLanguage, page, limit, seasonId)];
                    case 1:
                        seasonData = _a.sent();
                        resolve(seasonData);
                        return [3 /*break*/, 3];
                    case 2:
                        e_4 = _a.sent();
                        reject((0, utils_1.transformError)(e_4));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxContentApi.prototype.getStreamcallOfUser = function (contentId, authorization, acceptLanguage, resolution, size, platform, xhr, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.getTopContents = function (tenantSlug, tenantPageSlug, acceptLanguage, select, imageResolutionScale, imageFormat, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.getViewInfoOfContents = function (tenantSlug, ids, authorization, acceptLanguage, select, imageResolutionScale, imageFormat, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.searchContentInPage = function (tenantPageSlug, tenantSlug, q, authorization, select, imageResolutionScale, imageFormat, page, limit, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.searchGapContent = function (channelId, tenantSlug, gapDuration, authorization, acceptLanguage, imageResolutionScale, select, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.updateContentRealView = function (tenantSlug, contentId, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.updateContentReviewDetail = function (reviewId, tenantSlug, data, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.verifyCompleteUploadMultipartFile = function (tenantSlug, data, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.getContentCurrentStreaming = function (contentId, tenantSlug, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.drmLicenceCallback = function (tenantSlug, options) {
        return Promise.reject('Method not implemented');
    };
    OdxContentApi.prototype.staticImageApi = function (tenantSlug, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    return OdxContentApi;
}(integration_service_1.OdxIntegration));
exports.OdxContentApi = OdxContentApi;
