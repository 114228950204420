import styled from 'styled-components';
import { Modal, Row, Col } from 'react-bootstrap';
// const show = require('~img/img/payper-view-show.png)
// const offer = require('~img/img/payper-view-sub.png)
// const showOdv = require('~img/img/odv/ppv1.png)
// const offerOdv = require('~img/img/odv/ppv2.png)
export const ModalStyled = styled(Modal)`
  && {
    .txt {
      text-align: center;
      &--blue {
        color: #1e46ff;
      }
      &--white {
        color: #fff;
      }
      &--ChangeColor {
        color: #686868;
      }
    }

    .modal-body {
    }
    &.payperview {
      &-plan,
      &-register,
      &-checkout,
      &-success {
        font-size: 1em;
        display: block;
        .v-box {
          &--title {
            color: ${({ theme }) => theme.plans.color};
            font-size: 32px;
            text-transform: capitalize;
            text-align: center;
            margin-bottom: 10px;
            font-weight: bold;
            &-small {
              font-size: 14px;
              text-align: center;
              color: ${({ theme }) => theme.plans.secondaryColor};
            }
          }
        }
        .close {
          padding: 0;
          opacity: 1;
          font-size: 2rem;
          font-weight: 100;
          color: #fff;
        }
        .modal-header {
          border: none;
          padding: 1rem 1rem 0;
          display: flex;
          justify-content: flex-end;
          .payperview-close {
            color: #9a9a9a;
            font-size: 24px;
            cursor: pointer;
          }
        }
      }
      &-register {
        .v-login {
          padding-top: 0;
          &--title {
            color: #283237;
            font-size: 40px;
            text-transform: capitalize;
            font-weight: 600;
            margin-top: 0;
          }
          &--age {
            color: #b3bec4;
            text-decoration: none solid rgb(179, 190, 196);
          }
        }
        .modal-content {
          border-radius: 10px;
        }
      }
      &-checkout {
        .modal-body {
          display: flex;
          flex-direction: column-reverse;
        }
      }
    }
    .modal-content {
      border-radius: 10px;
      background: ${({ theme }) => theme.ppv.background};
    }
    .payperview {
      &-plan {
        &__container {
          font-size: 10px;
          display: flex;
          padding: 0 1em 2em;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          margin: 0;
          &-show,
          &-offer {
            max-width: 27.3em;
            background-image: url('${({ theme }) => theme.ppv.plan.showImg}');
            padding: 5em 1.5em 1.5em;
            background-repeat: no-repeat;
            background-position: top;
            background-size: cover;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            border: 2px solid #fff;
            box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 12%);
            border-radius: 12px;
            &:hover {
              /* box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.primaryColor}; */
              border: 2px solid ${({ theme }) => theme.primaryColor};
              .payperview-plan--btn {
                background: ${({ theme }) => theme.primaryColor};
                color: #fff;
                border: 2px solid ${({ theme }) => theme.primaryColor};
              }
            }
          }
          &-offer {
            margin-top: 2em;
            background-image: url('${({ theme }) => theme.ppv.plan.offImg}');
          }
        }
        &__wrap {
          padding: 0 0.5em;
        }
        &--category {
          /* color: #596d79; */
          color: ${({ theme }) => theme.ppv.plan.colorCategory};
          font-size: 1.3em;
          font-weight: 600;
          margin: 0;
        }
        &--title {
          /* color: #283237; */
          color: ${({ theme }) => theme.ppv.plan.colorTitle};
          font-size: 2em;
          font-weight: 600;
          &__offer {
            font-size: 2em;
            color: ${({ theme }) => theme.ppv.plan.colorTitleOffer};
          }
        }
        &--dolar {
          margin: 0.867em 0 0;
          font-size: 2.5em;
          /* color: ${({ theme }) => theme.primaryColor}; */
          color: ${({ theme }) => theme.ppv.plan.colorDolar};
          font-weight: 700;
        }
        &--currency {
          text-transform: uppercase;
        }
        &--description {
          /* color: #596d79; */
          font-size: 1.3em;
          color: ${({ theme }) => theme.ppv.plan.colorCategory};
          padding: 0;

          li {
            padding: 0.25em;
            font-style: italic;
            font-size: 13px;
            &:before {
              content: '•';
              color: #596d79;
              padding-right: 0.5rem;
            }
          }
        }
        &__ul {
          margin-bottom: 1.25em;
        }
        &--btn {
          width: 100%;
          text-transform: capitalize;
          border-radius: 5px;
          font-weight: 600;
          padding: 9px 0;
          margin: 1em 0 0;
          font-size: 1.5em;
          color: ${({ theme }) => theme.ppv.colorBtn};
          background: ${({ theme }) => theme.ppv.backgroundBtn};
          border-radius: 5px;
          border: 1px solid ${({ theme }) => theme.ppv.colorBtn};
          &:hover,
          &:focus {
            border: 1px solid ${({ theme }) => theme.primaryColor};
          }
        }
      }
      &-success {
        &__container {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          flex-direction: column;
          font-size: 10px;
          ion-icon {
            font-size: 6em;
            color: #fff;
          }
        }
        &--title {
          color: ${({ theme }) => theme.ppv.success.colorTitle};
          font-size: 3.2em;
          font-weight: bolder;
          margin: 1em 0 0.4em;
        }
        &--description {
          color: ${({ theme }) => theme.ppv.success.colorDescription};
          font-size: 1.6em;
        }
        &--btn {
          margin: 1em 0 3.375em;
          width: 80%;
          font-size: 1.6em;
          background: ${({ theme }) => theme.ppv.success.backgroundBtn};
          font-weight: bold;
          color: ${({ theme }) => theme.ppv.success.colorBtn};
          padding: 14px;
          border: none;
          border-radius: 5px;
        }
      }
    }

    @media (min-width: 576px) {
      &.payperview {
        &-plan {
          .modal-dialog {
            margin: auto;
            width: 35em;
          }
        }
      }
    }
    @media (min-width: 768px) {
      &.payperview {
        &-plan,
        &-register &-checkout {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 95%;
          .modal-dialog {
            width: auto;
          }
          margin: auto;
        }
        &-register {
          top: 0;
        }
      }
      .payperview {
        &-plan {
          &__container {
            flex-direction: row;
            align-items: stretch;
            padding: 0 1rem 2rem;
            margin: 0 0.75rem;
            &-offer,
            &-show {
              max-width: 38.8em;
              padding: 5.4em 2.9em 1.4em;
            }
            &-offer {
              margin-left: 2em;
              margin-top: 0em;
            }
          }
          &--btn {
            padding: 0.93em 0;
          }
        }
      }
    }

    @media (min-width: 992px) {
      &.payperview {
        &-plan {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          bottom: unset;
          right: unset;
        }
        &-plan,
        &-register,
        &-checkout {
          .modal-dialog {
            width: 915px;
          }
          .modal-body {
            width: 100%;
          }
        }
        &-checkout {
          .modal-body {
            flex-direction: row;
            padding: 14px 10px;
            width: 85%;
          }
        }
        &-success {
          .modal-dialog {
            width: 798px;
          }
        }
      }
      .payperview {
        &-plan {
          &__container {
            flex-direction: row;
            align-items: stretch;
            padding: 2em 4.8em 6.6em;
            margin: 0;
            &-show,
            &-offer {
              padding: 6.3em 2.8em 4.3em;
            }
          }
          &--category {
            font-size: 1.9em;
          }
          &--title {
            font-size: 2.4em;
            &__offer {
              font-size: 2.8em;
            }
          }
          &--dolar {
            font-size: 3em;
          }
          &--description {
            font-size: 1.6em;
            li {
              font-size: 0.9375em;
            }
          }
          &--btn {
            font-size: 1.6em;
          }
        }
        &-success {
          &--title {
            font-size: 4em;
            margin: 0.4em 0 0.525em;
          }
          &--description {
            font-size: 1.6em;
          }
          &--btn {
            margin: 2.375em 0 4.0625em;
            width: 80%;
            font-size: 1.6em;
            padding: 0.85em;
          }
        }
      }
    }
    @media (min-width: 1280px) {
      &.payperview {
        &-checkout {
          .modal-dialog {
            width: 1120px;
          }
        }
      }
    }
  }
`;

export const ColStyled = styled(Col)`
  .help-block {
    font-size: 1rem;
    color: #f23b46;
  }
  .StripeElement.StripeElement--invalid {
    border-color: #f23b46;
    background-color: #fff3f4;
  }
  &.v-stripe {
    display: flex;
    flex-direction: column;
    .v-group {
      .v-label {
        color: #94a3ad;
        font-weight: 100;
      }
    }
    .v-group {
      .v-input {
        &.filled {
          background: #fefefe;
        }
      }
      .v-label {
        color: ${({ theme }) => `${theme.ppv.checkout.txtLabel} !important`};
      }
    }
    .v-input {
      &.filled {
        background: ${({ theme }) => theme.ppv.checkout.backgroundInput};
      }
      &.StripeElement {
        opacity: 1;
      }
      &.StripeElement--focus ~ label,
      &.StripeElement--invalid ~ label,
      &.StripeElement--complete ~ label {
        font-size: 12px;
        color: #94a3ad;
        color: ${({ theme }) => theme.ppv.checkout.txtLabel};
      }
      &.StripeElement--empty {
        background: ${({ theme }) => theme.ppv.checkout.backgroundInput};
      }
      &.StripeElement--invalid ~ label {
        font-weight: 100;
        color: #f23b46;
      }
      &.StripeElement--invalid {
        color: ${({ theme }) => theme.ppv.checkout.txtError};
        background: ${({ theme }) => theme.ppv.checkout.backgroundInputErr};
        border: 1px solid ${({ theme }) => theme.ppv.checkout.txtError};
      }
    }
    .help-block {
      font-size: 12px;
    }
  }
  .v-stripe {
    &--select {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 40px 0;
      flex-wrap: wrap;
    }
    .StripeElement {
      display: block;
      margin: 0;
      width: 100%;
      font-size: 1rem;
      border-radius: 8px;
      opacity: 0.7;
      background: #fefefe;
      color: #fff;
      border: 1px solid #b3bec4;
      padding: 1rem;
      cursor: pointer;
    }
    &--helpcvc {
      position: absolute;
      top: 0.75rem;
      right: 10px;
      cursor: pointer;
      color: #e0e0e0;
      transition: color 0.2s ease;
      &:hover {
        i {
          color: ${({ theme }) => theme.primaryColor};
        }
      }
    }
    &--policy {
      font-size: 0.825rem;
      color: #596d79;
      font-weight: 100;
    }
  }
  @media (min-width: 576px) {
  }

  @media (min-width: 992px) {
  }

  @media (min-width: 1200px) {
  }
`;

export const RowStyled = styled(Row)`
  && {
    font-size: 1rem;
    .v-payment--pro-msg {
      padding: 0 8px;
      color: #596d79;
      font-size: 0.75em;
    }
    .group-coupon {
      display: flex;
      justify-content: space-between;
      align-items: stretch !important;
      margin-top: 1rem;
      .v-group {
        margin: 0;
      }
      button {
        margin: 0 0 0 1rem;
        padding: 0.8125rem 1rem;
        border-radius: 8px;
      }
    }
    .tables hr {
      border-top: 1px solid #e6ecef;
    }
    table {
      margin-bottom: 0;
    }
    .total {
      font-weight: bold;
    }
    table > tbody > tr > td {
      border: none;
    }
    td:last-child {
      text-align: right;
    }
    &.v-payment {
      font-size: 10px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .radio {
        margin-top: 10px;
        label {
          display: flex;
          align-items: center;
        }
        p {
          margin: 0;
          padding-left: 0.625rem;
          font-size: 14px;
        }
        img {
          width: 38px;
          margin-left: 0.375rem;
          height: 19px;
        }
        input[type='radio']:disabled {
          top: -13px;
        }
        input[type='radio'] {
          top: -14px;
          width: 20px;
          height: 40px;
          margin-left: -25px;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
    .v-payment {
      &--layout {
        display: flex;
        flex-direction: column;
      }
      &--success {
        color: #0095ff;
        position: absolute;
        top: 100%;
        left: 1rem;
        font-size: 0.75rem;
        font-weight: 400;
      }
      &--title {
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3.2em;
        margin-bottom: 10px;
        text-align: center;
        color: ${({ theme }) => theme.ppv.checkout.colorTitle};
        &-small {
          font-size: 2em;
          text-transform: capitalize;
          margin-bottom: 14px;
          color: ${({ theme }) => theme.ppv.checkout.colorTitleSmall};
        }
      }
      &--address {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &__header {
        font-size: 1.5rem;
        font-weight: 700;
        color: #283237;
      }
      &--name {
        font-size: 14px;
        color: #d6d6d6;
      }
      &__img {
        width: 38px;
        height: 19px;
        background: #ffffff;
        padding: 5px 3px;
        margin-left: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        &--mastercard {
          width: 24px !important;
          margin-left: 0 !important;
          height: 14px !important;
        }
        &--paypal {
          width: 30px !important;
          margin-left: 0 !important;
          height: 12px !important;
        }
      }
      &--stripeImage {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        margin-top: 0;
        margin-bottom: 36px;
      }
      &--coupon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        margin-top: 0;
        td {
          padding: 0;
        }
      }
      &__alert {
        background: #f7f8fa;
        color: #283237;
        padding: 30px 30px 47px;
        border: 1px solid #f7f8fa;
        border-radius: 10px;
        width: 100%;
        font-size: 16px;
        &-header {
          color: #000000;
          font-size: 20px;
          margin-bottom: 25px;
        }
      }
      &--shipping {
        padding: 0 0 1.25rem 0;
        background: #fff;
        border: none;
        border-bottom: 1px solid #e6ecef;
      }
      &--help {
        color: #f23b46;
        font-size: 0.75rem;
        font-weight: normal;
      }
      &--inline {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &--checkBoxAge {
        display: flex;
        .checkbox {
          position: static;
          margin: 0.5rem;
          font-size: 1rem;
          color: #b3bec4;
        }
        input[type='checkbox'] {
          margin-right: 3px;
        }
        &--title {
          font-size: 12px;
          span {
            color: #b3bec4;
            font-weight: 100;
            padding: 0 2px;
          }
          a,
          span:last-child {
            color: ${({ theme }) => theme.primaryColor};
            cursor: pointer;
            margin: 0;
            font-weight: 100;
            text-transform: lowercase;
            :hover {
              text-decoration: underline;
            }
          }
        }
      }
      &--btn {
        padding: 0.75rem;
        background: #fff;
        font-size: 1rem;
        color: ${({ theme }) => theme.primaryColor};
        border: 1px solid ${({ theme }) => theme.primaryColor};
        font-weight: 700;
        text-transform: capitalize;
        margin: 1rem 0;
        &_primary {
          background: ${({ theme }) => theme.primaryColor};
          color: #ffffff;
          font-weight: 600;
          padding: 0.8125rem 1.875rem;
          &:hover {
            border: 1px solid ${({ theme }) => theme.primaryColor};
            background: ${({ theme }) => theme.primaryColor};
            color: #ffffff;
          }
        }
        &_gray {
          border: 1px solid #94a3ad;
          color: #94a3ad;
          padding: 0.75rem 2rem;
          margin: 0;
          font-size: 1.25rem;
          font-weight: 400;
          &:hover {
            border: 1px solid ${({ theme }) => theme.primaryColor};
            color: ${({ theme }) => theme.primaryColor};
          }
        }
      }
    }
    @media (min-width: 992px) {
      &.v-payment {
        .radio {
          p {
            font-weight: 400;
          }
        }
      }
      .v-payment {
        &--layout {
          display: block;
        }
        &--title {
          font-size: 4em;
          margin-bottom: 0.775em;
          &-small {
            font-size: 2.8em;
          }
        }
        &--coupon {
          margin-top: 28px;
        }
        &__alert {
          width: 94%;
          padding: 30px 35px 47px;
        }
        &--stripeImage {
          width: 80%;
          margin-bottom: 2rem;
        }
      }
    }
  }
`;
