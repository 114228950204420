/* tslint:disable */
/* eslint-disable */
/**
 * Vimai Core Service UI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminCasApi = exports.AdminCasApiFactory = exports.AdminCasApiFp = exports.AdminCasApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * AdminCasApi - axios parameter creator
 * @export
 */
var AdminCasApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Login account [ADMIN-TOOL-ONLY]
         * @summary Login account [ADMIN-TOOL-ONLY]
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLoginUserAccount: function (accountId, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('adminLoginUserAccount', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('adminLoginUserAccount', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/login"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update user password
         * @summary Update user password
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {AdminResetAccountPasswordRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminResetAccountPassword: function (accountId, tenantSlug, data, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('adminResetAccountPassword', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('adminResetAccountPassword', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('adminResetAccountPassword', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/reset_password"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Create a account
         * @param {string} tenantSlug Tenant slug
         * @param {CreateAccountRequest} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserAccount: function (tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('createUserAccount', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('createUserAccount', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/accounts/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Export user resources
         * @param {string} tenantSlug
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [ids]
         * @param {string} [subscriptionPlan]
         * @param {string} [referralCode]
         * @param {string} [userType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportUserResources: function (tenantSlug, page, limit, authorization, acceptLanguage, ids, subscriptionPlan, referralCode, userType, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('exportUserResources', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/accounts/export"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (ids !== undefined) {
                                localVarQueryParameter['ids'] = ids;
                            }
                            if (subscriptionPlan !== undefined) {
                                localVarQueryParameter['subscription_plan'] = subscriptionPlan;
                            }
                            if (referralCode !== undefined) {
                                localVarQueryParameter['referral_code'] = referralCode;
                            }
                            if (userType !== undefined) {
                                localVarQueryParameter['user_type'] = userType;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get a cms member
         * @summary Get a cms member
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmsMember: function (id, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getCmsMember', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getCmsMember', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/members/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get list of cms members
         * @summary Get list of cms members
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmsMembers: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getCmsMembers', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/members/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (search !== undefined) {
                                localVarQueryParameter['search'] = search;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get a logined session
         * @summary Get a logined session
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginSession: function (id, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getLoginSession', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getLoginSession', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/logined_sessions/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get list logined sessions
         * @summary Get list logined session
         * @param {string} tenantSlug
         * @param {string} [accountId]
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [subId] Filter by account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginSessions: function (tenantSlug, accountId, search, page, pageSize, authorization, acceptLanguage, subId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getLoginSessions', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/logined_sessions"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (accountId !== undefined) {
                                localVarQueryParameter['account_id'] = accountId;
                            }
                            if (search !== undefined) {
                                localVarQueryParameter['search'] = search;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (subId !== undefined) {
                                localVarQueryParameter['sub_id'] = subId;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get one account
         * @param {string} id
         * @param {string} tenantSlug Tenant slug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccount: function (id, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getUserAccount', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getUserAccount', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary List all accounts
         * @param {string} tenantSlug Tenant slug
         * @param {string} [referralCode]
         * @param {1 | 3} [userType] User type of account (1: Normal or 3: Guest)
         * @param {string} [ids]
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccounts: function (tenantSlug, referralCode, userType, ids, search, page, pageSize, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getUserAccounts', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/accounts/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (referralCode !== undefined) {
                                localVarQueryParameter['referral_code'] = referralCode;
                            }
                            if (userType !== undefined) {
                                localVarQueryParameter['user_type'] = userType;
                            }
                            if (ids !== undefined) {
                                localVarQueryParameter['ids'] = ids;
                            }
                            if (search !== undefined) {
                                localVarQueryParameter['search'] = search;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get list user of type cms
         * @summary Get list user of type cms
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTypes: function (tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getUserTypes', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/user_types/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Invite new member
         * @summary Invite new member.
         * @param {string} tenantSlug
         * @param {string} setupPasswordUrl Setup password url
         * @param {InviteNewMemberRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteMember: function (tenantSlug, setupPasswordUrl, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('inviteMember', 'tenantSlug', tenantSlug);
                            // verify required parameter 'setupPasswordUrl' is not null or undefined
                            (0, common_1.assertParamExists)('inviteMember', 'setupPasswordUrl', setupPasswordUrl);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('inviteMember', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/invitations/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (setupPasswordUrl !== undefined) {
                                localVarQueryParameter['setup_password_url'] = setupPasswordUrl;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Setup password for new member
         * @summary Setup password for new member.
         * @param {string} invitationId
         * @param {string} tenantSlug
         * @param {SetupPasswordForNewMemberRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupPasswordForNewMember: function (invitationId, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'invitationId' is not null or undefined
                            (0, common_1.assertParamExists)('setupPasswordForNewMember', 'invitationId', invitationId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('setupPasswordForNewMember', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('setupPasswordForNewMember', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/invitations/{invitation_id}/setup_password/"
                                .replace("{".concat("invitation_id", "}"), encodeURIComponent(String(invitationId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update a cms member
         * @summary Update a cms members
         * @param {string} id
         * @param {string} tenantSlug
         * @param {MemberResource} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCmsMember: function (id, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('updateCmsMember', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('updateCmsMember', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateCmsMember', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/members/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update a account
         * @param {string} id
         * @param {string} tenantSlug Tenant slug
         * @param {ChangeInforAccountRequest} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAccount: function (id, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('updateUserAccount', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('updateUserAccount', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateUserAccount', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.AdminCasApiAxiosParamCreator = AdminCasApiAxiosParamCreator;
/**
 * AdminCasApi - functional programming interface
 * @export
 */
var AdminCasApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.AdminCasApiAxiosParamCreator)(configuration);
    return {
        /**
         * Login account [ADMIN-TOOL-ONLY]
         * @summary Login account [ADMIN-TOOL-ONLY]
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLoginUserAccount: function (accountId, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.adminLoginUserAccount(accountId, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update user password
         * @summary Update user password
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {AdminResetAccountPasswordRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminResetAccountPassword: function (accountId, tenantSlug, data, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.adminResetAccountPassword(accountId, tenantSlug, data, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Create a account
         * @param {string} tenantSlug Tenant slug
         * @param {CreateAccountRequest} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserAccount: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createUserAccount(tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Export user resources
         * @param {string} tenantSlug
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [ids]
         * @param {string} [subscriptionPlan]
         * @param {string} [referralCode]
         * @param {string} [userType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportUserResources: function (tenantSlug, page, limit, authorization, acceptLanguage, ids, subscriptionPlan, referralCode, userType, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.exportUserResources(tenantSlug, page, limit, authorization, acceptLanguage, ids, subscriptionPlan, referralCode, userType, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get a cms member
         * @summary Get a cms member
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmsMember: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getCmsMember(id, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get list of cms members
         * @summary Get list of cms members
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmsMembers: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getCmsMembers(tenantSlug, search, page, pageSize, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get a logined session
         * @summary Get a logined session
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginSession: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getLoginSession(id, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get list logined sessions
         * @summary Get list logined session
         * @param {string} tenantSlug
         * @param {string} [accountId]
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [subId] Filter by account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginSessions: function (tenantSlug, accountId, search, page, pageSize, authorization, acceptLanguage, subId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getLoginSessions(tenantSlug, accountId, search, page, pageSize, authorization, acceptLanguage, subId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get one account
         * @param {string} id
         * @param {string} tenantSlug Tenant slug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccount: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUserAccount(id, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary List all accounts
         * @param {string} tenantSlug Tenant slug
         * @param {string} [referralCode]
         * @param {1 | 3} [userType] User type of account (1: Normal or 3: Guest)
         * @param {string} [ids]
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccounts: function (tenantSlug, referralCode, userType, ids, search, page, pageSize, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUserAccounts(tenantSlug, referralCode, userType, ids, search, page, pageSize, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get list user of type cms
         * @summary Get list user of type cms
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTypes: function (tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUserTypes(tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Invite new member
         * @summary Invite new member.
         * @param {string} tenantSlug
         * @param {string} setupPasswordUrl Setup password url
         * @param {InviteNewMemberRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteMember: function (tenantSlug, setupPasswordUrl, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.inviteMember(tenantSlug, setupPasswordUrl, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Setup password for new member
         * @summary Setup password for new member.
         * @param {string} invitationId
         * @param {string} tenantSlug
         * @param {SetupPasswordForNewMemberRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupPasswordForNewMember: function (invitationId, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setupPasswordForNewMember(invitationId, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update a cms member
         * @summary Update a cms members
         * @param {string} id
         * @param {string} tenantSlug
         * @param {MemberResource} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCmsMember: function (id, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateCmsMember(id, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update a account
         * @param {string} id
         * @param {string} tenantSlug Tenant slug
         * @param {ChangeInforAccountRequest} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAccount: function (id, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateUserAccount(id, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.AdminCasApiFp = AdminCasApiFp;
/**
 * AdminCasApi - factory interface
 * @export
 */
var AdminCasApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.AdminCasApiFp)(configuration);
    return {
        /**
         * Login account [ADMIN-TOOL-ONLY]
         * @summary Login account [ADMIN-TOOL-ONLY]
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLoginUserAccount: function (accountId, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.adminLoginUserAccount(accountId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update user password
         * @summary Update user password
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {AdminResetAccountPasswordRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminResetAccountPassword: function (accountId, tenantSlug, data, authorization, options) {
            return localVarFp.adminResetAccountPassword(accountId, tenantSlug, data, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Create a account
         * @param {string} tenantSlug Tenant slug
         * @param {CreateAccountRequest} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserAccount: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.createUserAccount(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Export user resources
         * @param {string} tenantSlug
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [ids]
         * @param {string} [subscriptionPlan]
         * @param {string} [referralCode]
         * @param {string} [userType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportUserResources: function (tenantSlug, page, limit, authorization, acceptLanguage, ids, subscriptionPlan, referralCode, userType, options) {
            return localVarFp.exportUserResources(tenantSlug, page, limit, authorization, acceptLanguage, ids, subscriptionPlan, referralCode, userType, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get a cms member
         * @summary Get a cms member
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmsMember: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.getCmsMember(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get list of cms members
         * @summary Get list of cms members
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmsMembers: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
            return localVarFp.getCmsMembers(tenantSlug, search, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get a logined session
         * @summary Get a logined session
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginSession: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.getLoginSession(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get list logined sessions
         * @summary Get list logined session
         * @param {string} tenantSlug
         * @param {string} [accountId]
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [subId] Filter by account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginSessions: function (tenantSlug, accountId, search, page, pageSize, authorization, acceptLanguage, subId, options) {
            return localVarFp.getLoginSessions(tenantSlug, accountId, search, page, pageSize, authorization, acceptLanguage, subId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get one account
         * @param {string} id
         * @param {string} tenantSlug Tenant slug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccount: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.getUserAccount(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary List all accounts
         * @param {string} tenantSlug Tenant slug
         * @param {string} [referralCode]
         * @param {1 | 3} [userType] User type of account (1: Normal or 3: Guest)
         * @param {string} [ids]
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccounts: function (tenantSlug, referralCode, userType, ids, search, page, pageSize, authorization, acceptLanguage, options) {
            return localVarFp.getUserAccounts(tenantSlug, referralCode, userType, ids, search, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get list user of type cms
         * @summary Get list user of type cms
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTypes: function (tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.getUserTypes(tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Invite new member
         * @summary Invite new member.
         * @param {string} tenantSlug
         * @param {string} setupPasswordUrl Setup password url
         * @param {InviteNewMemberRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteMember: function (tenantSlug, setupPasswordUrl, data, authorization, acceptLanguage, options) {
            return localVarFp.inviteMember(tenantSlug, setupPasswordUrl, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Setup password for new member
         * @summary Setup password for new member.
         * @param {string} invitationId
         * @param {string} tenantSlug
         * @param {SetupPasswordForNewMemberRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupPasswordForNewMember: function (invitationId, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.setupPasswordForNewMember(invitationId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update a cms member
         * @summary Update a cms members
         * @param {string} id
         * @param {string} tenantSlug
         * @param {MemberResource} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCmsMember: function (id, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.updateCmsMember(id, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update a account
         * @param {string} id
         * @param {string} tenantSlug Tenant slug
         * @param {ChangeInforAccountRequest} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAccount: function (id, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.updateUserAccount(id, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.AdminCasApiFactory = AdminCasApiFactory;
/**
 * AdminCasApi - object-oriented interface
 * @export
 * @class AdminCasApi
 * @extends {BaseAPI}
 */
var AdminCasApi = /** @class */ (function (_super) {
    __extends(AdminCasApi, _super);
    function AdminCasApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Login account [ADMIN-TOOL-ONLY]
     * @summary Login account [ADMIN-TOOL-ONLY]
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCasApi
     */
    AdminCasApi.prototype.adminLoginUserAccount = function (accountId, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminCasApiFp)(this.configuration).adminLoginUserAccount(accountId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update user password
     * @summary Update user password
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {AdminResetAccountPasswordRequest} data
     * @param {string} [authorization] Access token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCasApi
     */
    AdminCasApi.prototype.adminResetAccountPassword = function (accountId, tenantSlug, data, authorization, options) {
        var _this = this;
        return (0, exports.AdminCasApiFp)(this.configuration).adminResetAccountPassword(accountId, tenantSlug, data, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Create a account
     * @param {string} tenantSlug Tenant slug
     * @param {CreateAccountRequest} data
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCasApi
     */
    AdminCasApi.prototype.createUserAccount = function (tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminCasApiFp)(this.configuration).createUserAccount(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Export user resources
     * @param {string} tenantSlug
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [ids]
     * @param {string} [subscriptionPlan]
     * @param {string} [referralCode]
     * @param {string} [userType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCasApi
     */
    AdminCasApi.prototype.exportUserResources = function (tenantSlug, page, limit, authorization, acceptLanguage, ids, subscriptionPlan, referralCode, userType, options) {
        var _this = this;
        return (0, exports.AdminCasApiFp)(this.configuration).exportUserResources(tenantSlug, page, limit, authorization, acceptLanguage, ids, subscriptionPlan, referralCode, userType, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get a cms member
     * @summary Get a cms member
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCasApi
     */
    AdminCasApi.prototype.getCmsMember = function (id, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminCasApiFp)(this.configuration).getCmsMember(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get list of cms members
     * @summary Get list of cms members
     * @param {string} tenantSlug
     * @param {string} [search] A search term.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCasApi
     */
    AdminCasApi.prototype.getCmsMembers = function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminCasApiFp)(this.configuration).getCmsMembers(tenantSlug, search, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get a logined session
     * @summary Get a logined session
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCasApi
     */
    AdminCasApi.prototype.getLoginSession = function (id, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminCasApiFp)(this.configuration).getLoginSession(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get list logined sessions
     * @summary Get list logined session
     * @param {string} tenantSlug
     * @param {string} [accountId]
     * @param {string} [search] A search term.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [subId] Filter by account id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCasApi
     */
    AdminCasApi.prototype.getLoginSessions = function (tenantSlug, accountId, search, page, pageSize, authorization, acceptLanguage, subId, options) {
        var _this = this;
        return (0, exports.AdminCasApiFp)(this.configuration).getLoginSessions(tenantSlug, accountId, search, page, pageSize, authorization, acceptLanguage, subId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get one account
     * @param {string} id
     * @param {string} tenantSlug Tenant slug
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCasApi
     */
    AdminCasApi.prototype.getUserAccount = function (id, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminCasApiFp)(this.configuration).getUserAccount(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary List all accounts
     * @param {string} tenantSlug Tenant slug
     * @param {string} [referralCode]
     * @param {1 | 3} [userType] User type of account (1: Normal or 3: Guest)
     * @param {string} [ids]
     * @param {string} [search] A search term.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCasApi
     */
    AdminCasApi.prototype.getUserAccounts = function (tenantSlug, referralCode, userType, ids, search, page, pageSize, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminCasApiFp)(this.configuration).getUserAccounts(tenantSlug, referralCode, userType, ids, search, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get list user of type cms
     * @summary Get list user of type cms
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCasApi
     */
    AdminCasApi.prototype.getUserTypes = function (tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminCasApiFp)(this.configuration).getUserTypes(tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Invite new member
     * @summary Invite new member.
     * @param {string} tenantSlug
     * @param {string} setupPasswordUrl Setup password url
     * @param {InviteNewMemberRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCasApi
     */
    AdminCasApi.prototype.inviteMember = function (tenantSlug, setupPasswordUrl, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminCasApiFp)(this.configuration).inviteMember(tenantSlug, setupPasswordUrl, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Setup password for new member
     * @summary Setup password for new member.
     * @param {string} invitationId
     * @param {string} tenantSlug
     * @param {SetupPasswordForNewMemberRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCasApi
     */
    AdminCasApi.prototype.setupPasswordForNewMember = function (invitationId, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminCasApiFp)(this.configuration).setupPasswordForNewMember(invitationId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update a cms member
     * @summary Update a cms members
     * @param {string} id
     * @param {string} tenantSlug
     * @param {MemberResource} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCasApi
     */
    AdminCasApi.prototype.updateCmsMember = function (id, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminCasApiFp)(this.configuration).updateCmsMember(id, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update a account
     * @param {string} id
     * @param {string} tenantSlug Tenant slug
     * @param {ChangeInforAccountRequest} data
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCasApi
     */
    AdminCasApi.prototype.updateUserAccount = function (id, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminCasApiFp)(this.configuration).updateUserAccount(id, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AdminCasApi;
}(base_1.BaseAPI));
exports.AdminCasApi = AdminCasApi;
