import {
  SET_ACTIVE_RIBBON,
  GET_LIVE_SCHEDULE,
  UPDATE_CURRENT_LIVE,
  UPDATE_LIVE_PAGE,
  SET_VOLUME_LIVE,
} from './actionTypes';

export function getLiveSchedule(payload) {
  return {
    type: GET_LIVE_SCHEDULE,
    payload,
  };
}

export function updateCurrentLive(payload) {
  return {
    type: UPDATE_CURRENT_LIVE,
    payload: payload,
  };
}

export function setActiveRibbonId(id) {
  return {
    type: SET_ACTIVE_RIBBON,
    id,
  };
}

export function updateLivePage(payload) {
  return {
    type: UPDATE_LIVE_PAGE,
    payload,
  };
}

export function setVolume(payload) {
  return {
    type: SET_VOLUME_LIVE,
    payload,
  };
}
