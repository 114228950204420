import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import { ModalStyled } from '../styles/styled';
import { IonIcon } from '@ionic/react';
import { checkmarkCircleOutline } from 'ionicons/icons';
import { getPaidContent } from '~features/auth/services';
import { SHOW_TYPE } from '../constants';
import CloseIcon from '@material-ui/icons/Close';

const Successful = props => {
  const { param, hideModal, dispatchGetPaidContent, state, t, location, history } = props;
  const [hide, setHide] = useState(false);
  let entityDetail = state.infoShow ? state.infoShow : state.entityDetail;
  let rentDuration = entityDetail.price && entityDetail.price.rent_duration;
  let unit =
    rentDuration > 48
      ? t('payperview.day')
      : rentDuration === 1
      ? t('payperview.hour')
      : t('payperview.hours');
  let timePaid = rentDuration > 48 ? Math.round(rentDuration / 24) : rentDuration;
  useEffect(() => {
    dispatchGetPaidContent().then().catch();
  }, []);

  const hideModalUpgrade = () => {
    let pathname = location.pathname;
    if (state && props.target === 'player') {
      pathname = state.redirect;
      history.replace({
        pathname,
        state: { payperview: true, isRestrictions: state.isRestrictions },
      });
    } else {
      history.replace({
        pathname,
        state: { payperview: true },
      });
    }
    setHide(true);
    if (hideModal) {
      return hideModal();
    }
    param.method.hideModal();
  };
  return (
    <ModalStyled
      show={!hide}
      onHide={() => hideModalUpgrade()}
      aria-labelledby="contained-modal-title-vcenter"
      className="payperview-success"
      bsSize="md"
    >
      <Modal.Header>
        <CloseIcon className="payperview-close" onClick={() => hideModalUpgrade()} />
      </Modal.Header>
      <Modal.Body>
        <Row className="payperview-success__container">
          <Col xs={12} sm={12} md={12} lg={12}>
            <IonIcon icon={checkmarkCircleOutline} />
            <p className="payperview-success--title">{t('payperview.success.txtTitle')}</p>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            <p
              className="payperview-success--description"
              dangerouslySetInnerHTML={{
                __html:
                  entityDetail.type === SHOW_TYPE
                    ? t('payperview.success.txtDesDrama', {
                        timePaid: timePaid,
                        unit: unit,
                      })
                    : t('payperview.success.txtDesMovie', {
                        timePaid: timePaid,
                        unit: unit,
                      }),
              }}
            />
            <Button className="payperview-success--btn" onClick={() => hideModalUpgrade()}>
              {t('payperview.success.btn')}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </ModalStyled>
  );
};

const mapStateToProps = state => ({
  account: state.auth.account,
});

const mapDispatchToProps = {
  dispatchGetPaidContent: () => getPaidContent(),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Successful)),
);
