import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const DialogStyled = styled(Dialog)`
  && {
    .MuiDialog-paperWidthSm {
      border-radius: 0;
      padding: 51px 59px;
    }
    .content {
      padding: unset;
      &-title {
        padding: unset;
        font-family: 'Neue Haas Grotesk Display Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 23px;
        line-height: 28px;
        text-align: center;
        color: #000000;
        margin-bottom: 8px;
      }
      &-message {
        font-family: 'Neue Haas Grotesk Display Pro';
        font-style: normal;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #000000;
        max-width: 263px;
        height: 57px;
        margin: auto;
      }
      &-dialogAction {
        padding: unset;
        margin-top: 8px;
        justify-content: center;
        &-btnAction {
          font-family: 'Neue Haas Grotesk Display Pro';
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 133.5%;
          text-align: center;
          letter-spacing: 0.025em;
          text-transform: uppercase;
          color: #ffffff;
          background-color: #000000;
          padding: 16.5px 128px;
          border-radius: 0;
        }
      }
    }
  }
`;
const PopupSuccess = props => {
  const { openPopup, handleCloseSuccessPopup } = props;
  const { t } = useTranslation();
  return (
    <DialogStyled
      open={openPopup}
      onClose={handleCloseSuccessPopup}
      aria-labelledby="form-dialog-title"
      className="dialogStyled"
    >
      <DialogContent className="content">
        <DialogTitle className="content-title" id="form-dialog-title">
          {t('projectw__modal.ChangeNickName.titleChangeSuccess')}
        </DialogTitle>
        <DialogContentText className="content-message">
          {t('projectw__modal.ChangeNickName.description')}
        </DialogContentText>
        <DialogActions className="content-dialogAction">
          <Button className="content-dialogAction-btnAction" onClick={handleCloseSuccessPopup}>
            {t('projectw__modal.ChangeNickName.btnOk')}
          </Button>
        </DialogActions>
      </DialogContent>
    </DialogStyled>
  );
};

export default PopupSuccess;
