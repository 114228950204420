import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as displayType from '../../constants';
import pushAnalytics from '../../../../services/analytics/Analytics';
import { Link } from 'react-router-dom';
import homePageId from '../../../../constants/ids/homePageId';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import queryString from 'query-string';

class UpgradeButton extends React.Component {
  render() {
    const { t, account, history, language, item, index } = this.props;
    let callbackUrl = `/${language}/landing/${item.slug}`;

    if (
      item.type === displayType.CHANNEL_TYPE ||
      item.type === displayType.CURATED_LIVE ||
      item.video_source === 1
    ) {
      callbackUrl = `/${language}/detail/${item.slug}`;
    }
    let param = {
      upgrade: true,
      callbackUrl: callbackUrl,
      svod: 1,
    };
    // if (!account && !item.is_watchable && !item.has_free_content) {
    //   return (
    //     <StyledButton
    //       className='v-slide--btn v-slide--btn-upgrade'
    //       onClick={(event) => {
    //         event.stopPropagation()
    //         this.props.showLoginAction(param)
    //         pushAnalytics('click', { content_type: 'button', item_name: 'UPGRADE TO WATCH', item_id: item.slug || '' })
    //       }
    //       }
    //     >
    //       <span>{t('laddingPage.buttonUpgrade')}</span>
    //     </StyledButton>
    //   )
    // }
    const hanldeCallBackURL = () => {
      let paramQuery = queryString.stringify(param);
      return `/${language}/landing/${item.slug}?ppv=true${paramQuery}`;
    };
    if (
      item.is_watchable ||
      item.has_free_content ||
      (account && account.profile && account.profile.subscription_plan_info)
    ) {
      return (
        <Link to={`/${language}/landing/${item.slug}`}>
          <StyledButton
            data-testid={`${homePageId.bannerId.contentPlayBtn}-${index}`}
            className="v-slide--btn"
          >
            <i className="fa fa-play" aria-hidden="true" /> <span>{t('ribbon.slide.watch')}</span>
          </StyledButton>
        </Link>
      );
    }
    return (
      <Link
        to={hanldeCallBackURL}
        onClick={() => event => {
          event.stopPropagation();
          pushAnalytics('click', {
            content_type: 'button',
            item_name: 'UPGRADE TO WATCH',
            item_id: item.slug || '',
          });
        }}
      >
        <StyledButton className="v-slide--btn v-slide--btn-upgrade">
          <span>{t('laddingPage.buttonUpgrade')}</span>
        </StyledButton>
      </Link>
    );
  }
}

const StyledButton = styled.button`
  &.v-slide {
    &--btn {
      background-color: ${({ theme }) => theme.primaryColor};
      color: #fff;
      font-size: 1rem;
      text-transform: capitalize;
      padding: 9px 13px;
      margin: 1rem 0;
      border-radius: 8px;
      border: none !important;
      outline: none !important;
      i {
        padding-right: 0.25em;
      }
      &-upgrade {
        background-image: ${({ theme }) => theme.newSlide.btnUpgrade};
      }
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    &.v-slide {
      &--btn {
        i {
          padding-right: 0.5em;
        }
      }
    }
  }

  @media (min-width: 1280px) {
  }
  @media (min-width: 1440px) {
  }
  @media (min-width: 1680px) {
  }
`;

UpgradeButton.propTypes = {
  item: PropTypes.any,
  account: PropTypes.any,
  index: PropTypes.number,
};

const mapStateToProps = state => ({
  language: state.root.language,
});

export default withRouter(connect(mapStateToProps)(withTranslation()(UpgradeButton)));
