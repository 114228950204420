import Select from 'react-select';
import styled from 'styled-components';

const SelectGenres = styled(Select)`
  .Select {
    &__control {
      background: none;
      border: 1px solid #c8d4db;
      border-color: #c8d4db !important;
      border-radius: 8px;
      box-shadow: unset !important;
      cursor: pointer;
    }
    &__indicator {
      &-separator {
        display: none !important;
      }
      svg {
        width: 17px;
      }
    }
    &__value-container {
      padding: 16px 20px;
    }
    &__placeholder {
      white-space: nowrap;
    }
    &__single-value {
      color: #a7b9c4;
      font-size: 1rem;
      .v-season__active {
        display: none;
      }
    }
    &__menu {
      z-index: 999;
      background: #111212;
      border-radius: 8px;
      border-color: #c8d4db !important;
      margin: 0;
      &-list {
        padding: 0;
        box-shadow: 0px 5px 0px 0px ${({ theme }) => theme.plans.btn};
        border-radius: 10px;
      }
    }
    &__option {
      cursor: pointer;
      color: #283237;
      text-align: left;
      padding: 12px 20px;
      &:last-child {
        border-bottom: none;
      }
      border-bottom: 1px solid rgb(222, 222, 222, 0.5);

      .v-season__active {
        opacity: 0;
      }
      &--is-focused,
      &--is-selected {
        background: #e6ecef;
      }
      &--is-selected {
        .v-season__active {
          opacity: 1;
        }
      }
    }
    &__dropdown-indicator {
      i {
        transition: transform 0.3s;
      }
    }
    &__control--menu-is-open {
      .Select__dropdown-indicator {
        i {
          color: #fff;
          transform: rotate(180deg);
        }
      }
    }
  }
`;
export { SelectGenres };
