import React from 'react';
import { connect } from 'react-redux';
import * as display from '../../constants';
import {
  contentTypes,
  getTenantConfig,
  secondsToMinute,
  secondsToTime,
} from '../../../../utils/utils';
import { updateRibbonTarget } from '../../../../features/homepage/services';
import classnames from 'classnames';
import pushAnalytics from '../../../../services/analytics/Analytics';
import { getConfigByKey } from '../../../../features/tenantConfig/services';
import { RibbonItemWrap } from './styled';
import { LineClampStyled } from '../../../../features/landingpage/styles/line-clamp';
import RibbonItemProgressBar from './progressBar';
import ActionButton from './actionButton';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';

const renderDuration = runtime => {
  const { h, m, s } = secondsToTime(runtime);
  return `${h}:${m}:${s}`;
};
const RibbonItem = props => {
  const { item, ribbon, dpUpdateRibbonTarget, index, slideToShow, handleRemoveRecent, language } =
    props;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { images, title } = item;

  let displayType = ribbon && ribbon.display_type;
  const displayTypeStr = display[`CLASS_${displayType || 1}`];

  let contentType = contentTypes(item);

  const tenantConfig = getTenantConfig(getConfigByKey('tenant_slug'));
  const link = `/${i18n.language}/detail/${item.slug}`;
  const linkDetail =
    item.type === display.CHANNEL_TYPE || item.type === display.CURATED_LIVE
      ? `/${i18n.language}/${i18n.language}/detail/${item.slug}`
      : `/${i18n.language}/landing/${item.slug}`;

  const playFrom =
    item.type === display.EPISODE_TYPE || item.type === display.SHOW_TYPE
      ? `${t('ribbon.play')} ${item.default_episode && item.default_episode.title}`
      : item.type === display.MOVIE_TYPE &&
        `${t('ribbon.playFrom')} ${secondsToMinute(item.progress)} / ${secondsToMinute(
          item.duration,
        )}m`;
  const progress =
    item.type === display.MOVIE_TYPE
      ? item.progress
      : item.default_episode && item.default_episode.progress;
  const duration =
    item.type === display.MOVIE_TYPE
      ? item.duration
      : item.default_episode && item.default_episode.duration;
  const getTotalEpisode =
    item.type === display.EPISODE_TYPE || item.type === display.SHOW_TYPE
      ? item.total_episodes + ` ${t('video.eps')}`
      : renderDuration(item.duration);

  return (
    <RibbonItemWrap
      className={classnames(
        'ribbonItemContainer',
        tenantConfig && tenantConfig.POSTER_SIZE,
        `${display[`DISPLAY_${ribbon ? ribbon.type : 1}`]}`,
        index === 0 && 'start-item', // init start of active item
        index === slideToShow - 1 && 'last-item', // init last of active item
      )}
      data-testid={`ribbon-detail-${index}`}
      style={{ position: 'relative' }}
    >
      <div className={classnames('imageWrapper', displayTypeStr)}>
        <div
          onClick={() => {
            if (!ribbon) return;

            pushAnalytics('select_content', {
              content_type: contentType,
              item_id: item.slug,
              widget: ribbon.slug,
              order: index + 1,
            });
            dpUpdateRibbonTarget(ribbon.id);
          }}
        >
          <img className="srcImg" src={images[displayTypeStr]} loading="lazy" />
        </div>
      </div>
      <Link
        className={classnames('infoWrapper', 'infoWrapper--' + displayTypeStr)}
        id={`/${language}linkDetail`}
        to={`/${language}linkDetail`}
      >
        <ActionButton
          ribbon={ribbon}
          link={link}
          item={item}
          handleRemoveRecent={handleRemoveRecent}
        />
        <div className="title">
          <LineClampStyled className="line-clamp-2">{title}</LineClampStyled>
        </div>
        <div className="play-from">
          <LineClampStyled className="line-clamp-1">{playFrom}</LineClampStyled>
        </div>
        <div className="total-episode">{getTotalEpisode}</div>
      </Link>
      <RibbonItemProgressBar
        displayType={displayTypeStr}
        percent={(progress / duration) * 100}
        visible={
          ribbon.type === 5 && // visible when ribbon is a channel type
          item.type !== display.CHANNEL_TYPE // hide when ribbon item is a channel show
        }
      />
    </RibbonItemWrap>
  );
};

RibbonItem.defaultProps = {
  title: '',
  sourceURL: '',
  viewNumber: 0,
  favoritesNumber: 0,
  slug: '',
  type: 0,
};
const mapStateToProps = state => ({
  language: state.root.language,
});
const mapDispatchToProps = {
  dpUpdateRibbonTarget: id => updateRibbonTarget(id),
};

export default connect(mapStateToProps, mapDispatchToProps)(RibbonItem);
