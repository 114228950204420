/* tslint:disable */
/* eslint-disable */
/**
 * Vimai Core Service UI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentListingApi = exports.ContentListingApiFactory = exports.ContentListingApiFp = exports.ContentListingApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * ContentListingApi - axios parameter creator
 * @export
 */
var ContentListingApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Get paid contents by account
         * @summary Get paid contents by account
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {'active' | 'all' | 'deactive'} [type] Type: active/all/deactive, default&#x3D;active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountPaidContents: function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, type, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('getAccountPaidContents', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getAccountPaidContents', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/paid_contents/"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (type !== undefined) {
                                localVarQueryParameter['type'] = type;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary List programs of EPG
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [scheduleDate] Specify will ignore start_time and end_time ( format - yyyy-mm-dd )
         * @param {string} [startTime] Filter programs start after this (default to now)
         * @param {string} [endTime] Filter programs end before this (default to now + 3hrs)
         * @param {string} [channelId] channel id if needed for specific
         * @param {string} [timezone] timezone of your current location
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getChannelEpgs: function (acceptLanguage, page, limit, select, scheduleDate, startTime, endTime, channelId, timezone, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/cm/epg/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (scheduleDate !== undefined) {
                                localVarQueryParameter['schedule_date'] = scheduleDate;
                            }
                            if (startTime !== undefined) {
                                localVarQueryParameter['start_time'] = startTime;
                            }
                            if (endTime !== undefined) {
                                localVarQueryParameter['end_time'] = endTime;
                            }
                            if (channelId !== undefined) {
                                localVarQueryParameter['channel_id'] = channelId;
                            }
                            if (timezone !== undefined) {
                                localVarQueryParameter['timezone'] = timezone;
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get contents by metadata
         * @summary Get contents by metadata
         * @param {string} metadataSlug
         * @param {string} tenantSlug
         * @param {string} metadata Choice [\&#39;genre\&#39;, \&#39;region\&#39;, \&#39;people\&#39;, \&#39;content_category\&#39;, \&#39;sub_category\&#39;]
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Required param
         * @param {number} [limit] Required param
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentsByMetadata: function (metadataSlug, tenantSlug, metadata, authorization, acceptLanguage, page, limit, select, imageResolutionScale, imageFormat, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'metadataSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getContentsByMetadata', 'metadataSlug', metadataSlug);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getContentsByMetadata', 'tenantSlug', tenantSlug);
                            // verify required parameter 'metadata' is not null or undefined
                            (0, common_1.assertParamExists)('getContentsByMetadata', 'metadata', metadata);
                            localVarPath = "/tenants/{tenant_slug}/metadata/{metadata_slug}/contents"
                                .replace("{".concat("metadata_slug", "}"), encodeURIComponent(String(metadataSlug)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (metadata !== undefined) {
                                localVarQueryParameter['metadata'] = metadata;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (imageResolutionScale !== undefined) {
                                localVarQueryParameter['image_resolution_scale'] = imageResolutionScale;
                            }
                            if (imageFormat !== undefined) {
                                localVarQueryParameter['image_format'] = imageFormat;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get clip hay ribbon
         * @summary Get clip hay ribbon
         * @param {string} contentId Id of content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [page] Page number ( start at 0 )
         * @param {string} [limit] Total content response
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getListClipHay: function (contentId, authorization, acceptLanguage, page, limit, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getListClipHay', 'contentId', contentId);
                            localVarPath = "/backend/cm/clip_hay/{content_id}/"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List channel of EPG
         * @summary List channel of EPG
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getListLiveChannel: function (authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/cm/channel/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get live schedules for page
         * @param {string} pageSlug
         * @param {string} platformSlug
         * @param {string} tenantSlug
         * @param {number} startTime start time (timestamp)
         * @param {number} endTime end time (timestamp)
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [ribbonIds] ribbon ids
         * @param {string} [timezone] Timezone
         * @param {'range_time' | 'freeze'} [mode] Dev mode
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [adParams] Additional ad parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveSchedulesForPage: function (pageSlug, platformSlug, tenantSlug, startTime, endTime, authorization, acceptLanguage, select, imageResolutionScale, ribbonIds, timezone, mode, page, limit, adParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'pageSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getLiveSchedulesForPage', 'pageSlug', pageSlug);
                            // verify required parameter 'platformSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getLiveSchedulesForPage', 'platformSlug', platformSlug);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getLiveSchedulesForPage', 'tenantSlug', tenantSlug);
                            // verify required parameter 'startTime' is not null or undefined
                            (0, common_1.assertParamExists)('getLiveSchedulesForPage', 'startTime', startTime);
                            // verify required parameter 'endTime' is not null or undefined
                            (0, common_1.assertParamExists)('getLiveSchedulesForPage', 'endTime', endTime);
                            localVarPath = "/tenants/{tenant_slug}/tenant_platforms/{platform_slug}/tenant_pages/{page_slug}/live_schedules/"
                                .replace("{".concat("page_slug", "}"), encodeURIComponent(String(pageSlug)))
                                .replace("{".concat("platform_slug", "}"), encodeURIComponent(String(platformSlug)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (startTime !== undefined) {
                                localVarQueryParameter['start_time'] = startTime;
                            }
                            if (endTime !== undefined) {
                                localVarQueryParameter['end_time'] = endTime;
                            }
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (imageResolutionScale !== undefined) {
                                localVarQueryParameter['image_resolution_scale'] = imageResolutionScale;
                            }
                            if (ribbonIds !== undefined) {
                                localVarQueryParameter['ribbon_ids'] = ribbonIds;
                            }
                            if (timezone !== undefined) {
                                localVarQueryParameter['timezone'] = timezone;
                            }
                            if (mode !== undefined) {
                                localVarQueryParameter['mode'] = mode;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (adParams !== undefined) {
                                localVarQueryParameter['ad_params'] = adParams;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get data for display page
         * @summary Get data for display page
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} pageSlug  id of page Get VOD_LIST page: page_slug&#x3D;\&quot;vod_list_page\&quot;
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [platformSlug] Application platform slug
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [genreSlug] Filter content by genre
         * @param {string} [languageSlug] Filter content by language
         * @param {string} [regionSlug] Filter content by region
         * @param {string} [peopleSlugs] Filter content by peoples
         * @param {string} [providerSlug] Filter content by provider
         * @param {string} [categorySlug] Filter content by category
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagePersonalizedRibbons: function (accountId, tenantSlug, pageSlug, authorization, acceptLanguage, select, imageResolutionScale, platformSlug, page, limit, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, imageFormat, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('getPagePersonalizedRibbons', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getPagePersonalizedRibbons', 'tenantSlug', tenantSlug);
                            // verify required parameter 'pageSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getPagePersonalizedRibbons', 'pageSlug', pageSlug);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/tenant_pages/{page_slug}/personalized_ribbons/"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)))
                                .replace("{".concat("page_slug", "}"), encodeURIComponent(String(pageSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (imageResolutionScale !== undefined) {
                                localVarQueryParameter['image_resolution_scale'] = imageResolutionScale;
                            }
                            if (platformSlug !== undefined) {
                                localVarQueryParameter['platform_slug'] = platformSlug;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (genreSlug !== undefined) {
                                localVarQueryParameter['genre_slug'] = genreSlug;
                            }
                            if (languageSlug !== undefined) {
                                localVarQueryParameter['language_slug'] = languageSlug;
                            }
                            if (regionSlug !== undefined) {
                                localVarQueryParameter['region_slug'] = regionSlug;
                            }
                            if (peopleSlugs !== undefined) {
                                localVarQueryParameter['people_slugs'] = peopleSlugs;
                            }
                            if (providerSlug !== undefined) {
                                localVarQueryParameter['provider_slug'] = providerSlug;
                            }
                            if (categorySlug !== undefined) {
                                localVarQueryParameter['category_slug'] = categorySlug;
                            }
                            if (imageFormat !== undefined) {
                                localVarQueryParameter['image_format'] = imageFormat;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get static page data
         * @param {string} tenantSlug
         * @param {string} pageSlug  id of page Get VOD_LIST page: page_slug&#x3D;\&quot;vod_list_page\&quot;
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [platformSlug] Application platform slug
         * @param {string} [genreSlug] Filter content by genre
         * @param {string} [languageSlug] Filter content by language
         * @param {string} [regionSlug] Filter content by region
         * @param {string} [peopleSlugs] Filter content by peoples
         * @param {string} [providerSlug] Filter content by provider
         * @param {string} [categorySlug] Filter content by category
         * @param {string} [imageFormat] Image type
         * @param {string} [date] TV_Guide has different display by date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageStaticRibbons: function (tenantSlug, pageSlug, acceptLanguage, select, page, limit, imageResolutionScale, platformSlug, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, imageFormat, date, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getPageStaticRibbons', 'tenantSlug', tenantSlug);
                            // verify required parameter 'pageSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getPageStaticRibbons', 'pageSlug', pageSlug);
                            localVarPath = "/tenants/{tenant_slug}/tenant_pages/{page_slug}/ribbons/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)))
                                .replace("{".concat("page_slug", "}"), encodeURIComponent(String(pageSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (imageResolutionScale !== undefined) {
                                localVarQueryParameter['image_resolution_scale'] = imageResolutionScale;
                            }
                            if (platformSlug !== undefined) {
                                localVarQueryParameter['platform_slug'] = platformSlug;
                            }
                            if (genreSlug !== undefined) {
                                localVarQueryParameter['genre_slug'] = genreSlug;
                            }
                            if (languageSlug !== undefined) {
                                localVarQueryParameter['language_slug'] = languageSlug;
                            }
                            if (regionSlug !== undefined) {
                                localVarQueryParameter['region_slug'] = regionSlug;
                            }
                            if (peopleSlugs !== undefined) {
                                localVarQueryParameter['people_slugs'] = peopleSlugs;
                            }
                            if (providerSlug !== undefined) {
                                localVarQueryParameter['provider_slug'] = providerSlug;
                            }
                            if (categorySlug !== undefined) {
                                localVarQueryParameter['category_slug'] = categorySlug;
                            }
                            if (imageFormat !== undefined) {
                                localVarQueryParameter['image_format'] = imageFormat;
                            }
                            if (date !== undefined) {
                                localVarQueryParameter['date'] = date;
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get paid contents by account V2
         * @summary Get paid contents by account V2
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {'active' | 'all' | 'deactive'} [type] Type: active/all/deactive, default&#x3D;active
         * @param {Array<string>} [contentIds] Filter list content ids is activated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaidContents: function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, type, contentIds, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('getPaidContents', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getPaidContents', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/paid_contents_v2/"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (type !== undefined) {
                                localVarQueryParameter['type'] = type;
                            }
                            if (contentIds) {
                                localVarQueryParameter['content_ids'] = contentIds.join(base_1.COLLECTION_FORMATS.csv);
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get personal ribbon detail API
         * @summary Get personal ribbon detail API
         * @param {string} accountId
         * @param {string} pageSlug
         * @param {string} ribbonId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [platformSlug] Application platform slug
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [genreSlug] Filter content by genre
         * @param {string} [languageSlug] Filter content by language
         * @param {string} [regionSlug] Filter content by region
         * @param {string} [peopleSlugs] Filter content by peoples
         * @param {string} [providerSlug] Filter content by provider
         * @param {string} [categorySlug] Filter content by category
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalizedRibbonDetail: function (accountId, pageSlug, ribbonId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, platformSlug, page, limit, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, imageFormat, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('getPersonalizedRibbonDetail', 'accountId', accountId);
                            // verify required parameter 'pageSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getPersonalizedRibbonDetail', 'pageSlug', pageSlug);
                            // verify required parameter 'ribbonId' is not null or undefined
                            (0, common_1.assertParamExists)('getPersonalizedRibbonDetail', 'ribbonId', ribbonId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getPersonalizedRibbonDetail', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/tenant_pages/{page_slug}/personalized_ribbons/{ribbon_id}/"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("page_slug", "}"), encodeURIComponent(String(pageSlug)))
                                .replace("{".concat("ribbon_id", "}"), encodeURIComponent(String(ribbonId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (imageResolutionScale !== undefined) {
                                localVarQueryParameter['image_resolution_scale'] = imageResolutionScale;
                            }
                            if (platformSlug !== undefined) {
                                localVarQueryParameter['platform_slug'] = platformSlug;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (genreSlug !== undefined) {
                                localVarQueryParameter['genre_slug'] = genreSlug;
                            }
                            if (languageSlug !== undefined) {
                                localVarQueryParameter['language_slug'] = languageSlug;
                            }
                            if (regionSlug !== undefined) {
                                localVarQueryParameter['region_slug'] = regionSlug;
                            }
                            if (peopleSlugs !== undefined) {
                                localVarQueryParameter['people_slugs'] = peopleSlugs;
                            }
                            if (providerSlug !== undefined) {
                                localVarQueryParameter['provider_slug'] = providerSlug;
                            }
                            if (categorySlug !== undefined) {
                                localVarQueryParameter['category_slug'] = categorySlug;
                            }
                            if (imageFormat !== undefined) {
                                localVarQueryParameter['image_format'] = imageFormat;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get content dynamic detail
         * @summary Get content dynamic detail
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendedOfContent: function (contentId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, page, limit, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getRecommendedOfContent', 'contentId', contentId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getRecommendedOfContent', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/contents/{content_id}/recommended"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (imageResolutionScale !== undefined) {
                                localVarQueryParameter['image_resolution_scale'] = imageResolutionScale;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get related content by a specific content id
         * @summary Get related content by a specific content id
         * @param {string} relatedByContentId related by content id
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [page] Page number ( start at 0 )
         * @param {string} [limit] Total content response
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getRelatedContentById: function (relatedByContentId, authorization, acceptLanguage, page, limit, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'relatedByContentId' is not null or undefined
                            (0, common_1.assertParamExists)('getRelatedContentById', 'relatedByContentId', relatedByContentId);
                            localVarPath = "/backend/cm/related/{related_by_content_id}/"
                                .replace("{".concat("related_by_content_id", "}"), encodeURIComponent(String(relatedByContentId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get related contents of content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [expectTypes] Expected return types of content, split by ,
         * @param {string} [displayType] Display type: NORMAL/PICK
         * @param {boolean} [hasTopContents] Display type: True/False
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedContentsOfContent: function (contentId, tenantSlug, authorization, acceptLanguage, imageResolutionScale, select, page, limit, expectTypes, displayType, hasTopContents, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getRelatedContentsOfContent', 'contentId', contentId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getRelatedContentsOfContent', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/contents/{content_id}/related_contents/"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (imageResolutionScale !== undefined) {
                                localVarQueryParameter['image_resolution_scale'] = imageResolutionScale;
                            }
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (expectTypes !== undefined) {
                                localVarQueryParameter['expect_types'] = expectTypes;
                            }
                            if (displayType !== undefined) {
                                localVarQueryParameter['display_type'] = displayType;
                            }
                            if (hasTopContents !== undefined) {
                                localVarQueryParameter['has_top_contents'] = hasTopContents;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get items of ribbon
         * @param {string} pageSlug
         * @param {string} ribbonId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [platform] web, android, ios
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [genreSlug] Filter content by genre
         * @param {string} [languageSlug] Filter content by language
         * @param {string} [regionSlug] Filter content by region
         * @param {string} [peopleSlugs] Filter content by peoples
         * @param {string} [providerSlug] Filter content by provider
         * @param {string} [categorySlug] Filter content by category
         * @param {string} [secret] Code to unlock the ribbon
         * @param {string} [imageFormat] Image type
         * @param {string} [date] Empathy interface: ribbon display is different by date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRibbonDetail: function (pageSlug, ribbonId, tenantSlug, authorization, acceptLanguage, page, limit, imageResolutionScale, platform, select, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, secret, imageFormat, date, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'pageSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getRibbonDetail', 'pageSlug', pageSlug);
                            // verify required parameter 'ribbonId' is not null or undefined
                            (0, common_1.assertParamExists)('getRibbonDetail', 'ribbonId', ribbonId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getRibbonDetail', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/tenant_pages/{page_slug}/ribbons/{ribbon_id}/"
                                .replace("{".concat("page_slug", "}"), encodeURIComponent(String(pageSlug)))
                                .replace("{".concat("ribbon_id", "}"), encodeURIComponent(String(ribbonId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (imageResolutionScale !== undefined) {
                                localVarQueryParameter['image_resolution_scale'] = imageResolutionScale;
                            }
                            if (platform !== undefined) {
                                localVarQueryParameter['platform'] = platform;
                            }
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (genreSlug !== undefined) {
                                localVarQueryParameter['genre_slug'] = genreSlug;
                            }
                            if (languageSlug !== undefined) {
                                localVarQueryParameter['language_slug'] = languageSlug;
                            }
                            if (regionSlug !== undefined) {
                                localVarQueryParameter['region_slug'] = regionSlug;
                            }
                            if (peopleSlugs !== undefined) {
                                localVarQueryParameter['people_slugs'] = peopleSlugs;
                            }
                            if (providerSlug !== undefined) {
                                localVarQueryParameter['provider_slug'] = providerSlug;
                            }
                            if (categorySlug !== undefined) {
                                localVarQueryParameter['category_slug'] = categorySlug;
                            }
                            if (secret !== undefined) {
                                localVarQueryParameter['secret'] = secret;
                            }
                            if (imageFormat !== undefined) {
                                localVarQueryParameter['image_format'] = imageFormat;
                            }
                            if (date !== undefined) {
                                localVarQueryParameter['date'] = date;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get items of ribbon
         * @summary Get items of ribbon
         * @param {string} ribbonId Id of ribbon
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Current page
         * @param {number} [limit] page limit
         * @param {string} [platform] web, android, ios
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getRibbonItems: function (ribbonId, authorization, acceptLanguage, page, limit, platform, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'ribbonId' is not null or undefined
                            (0, common_1.assertParamExists)('getRibbonItems', 'ribbonId', ribbonId);
                            localVarPath = "/backend/cm/ribbon/{ribbon_id}/"
                                .replace("{".concat("ribbon_id", "}"), encodeURIComponent(String(ribbonId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (platform !== undefined) {
                                localVarQueryParameter['platform'] = platform;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRibbonTypeById: function (id, tenantSlug, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getRibbonTypeById', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getRibbonTypeById', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/ribbon_styles/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRibbonTypes: function (tenantSlug, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getRibbonTypes', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/ribbon_styles/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get search result
         * @summary Get search result
         * @param {string} keyword Keyword
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [page] Page number ( start at 0 )
         * @param {string} [limit] Total content response
         * @param {number} [tenantId] id of tenant
         * @param {string} [tenantSlug] slug of tenant
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSearchResult: function (keyword, authorization, acceptLanguage, page, limit, tenantId, tenantSlug, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'keyword' is not null or undefined
                            (0, common_1.assertParamExists)('getSearchResult', 'keyword', keyword);
                            localVarPath = "/backend/cm/search/{keyword}/"
                                .replace("{".concat("keyword", "}"), encodeURIComponent(String(keyword)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (tenantId !== undefined) {
                                localVarQueryParameter['tenant_id'] = tenantId;
                            }
                            if (tenantSlug !== undefined) {
                                localVarQueryParameter['tenant_slug'] = tenantSlug;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get season by content_id
         * @summary Get season by content_id
         * @param {string} contentId id of content
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [userId] id of user request api
         * @param {number} [page] Page number ( start at 0 )
         * @param {number} [limit] Total content response (default is 1000 )
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSeasonDetailById: function (contentId, authorization, acceptLanguage, userId, page, limit, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getSeasonDetailById', 'contentId', contentId);
                            localVarPath = "/backend/cm/season_by_id/{content_id}/"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (userId !== undefined) {
                                localVarQueryParameter['user_id'] = userId;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get the {season_index}th season of show {content_id}
         * @summary Get the {season_index}th season of show {content_id}
         * @param {string} contentId id of content
         * @param {string} [authorization] user access token for future use
         * @param {number} [season] season index (1..n)
         * @param {number} [page] Page number (strart at 0)
         * @param {number} [limit] Total content response (default is 1000 )
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSeasonIndexOfShow: function (contentId, authorization, season, page, limit, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getSeasonIndexOfShow', 'contentId', contentId);
                            localVarPath = "/backend/cm/season/{content_id}/"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (season !== undefined) {
                                localVarQueryParameter['season'] = season;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get all season of show
         * @summary Get all season of show
         * @param {string} showId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeasonsOfShow: function (showId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'showId' is not null or undefined
                            (0, common_1.assertParamExists)('getSeasonsOfShow', 'showId', showId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getSeasonsOfShow', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/contents/{show_id}/seasons/"
                                .replace("{".concat("show_id", "}"), encodeURIComponent(String(showId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (imageResolutionScale !== undefined) {
                                localVarQueryParameter['image_resolution_scale'] = imageResolutionScale;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get data for display page
         * @summary Get data for display page
         * @param {string} pageId id of page
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [platform] web, android, ios
         * @param {string} [regionSlug] Filter by region
         * @param {string} [genreSlug] Filter by genre
         * @param {string} [languageSlug] Filter by language
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getTenantPageDetail: function (pageId, authorization, acceptLanguage, platform, regionSlug, genreSlug, languageSlug, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'pageId' is not null or undefined
                            (0, common_1.assertParamExists)('getTenantPageDetail', 'pageId', pageId);
                            localVarPath = "/backend/cm/page/{page_id}/"
                                .replace("{".concat("page_id", "}"), encodeURIComponent(String(pageId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (platform !== undefined) {
                                localVarQueryParameter['platform'] = platform;
                            }
                            if (regionSlug !== undefined) {
                                localVarQueryParameter['region_slug'] = regionSlug;
                            }
                            if (genreSlug !== undefined) {
                                localVarQueryParameter['genre_slug'] = genreSlug;
                            }
                            if (languageSlug !== undefined) {
                                localVarQueryParameter['language_slug'] = languageSlug;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get user history
         * @summary Get user history
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [resolution] 1x, 2x, 3x
         * @param {string} [size] small, medium, large
         * @param {number} [cursor] page ( default 0 )
         * @param {number} [xhr] (0/1) is web platform
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserHistory: function (authorization, acceptLanguage, resolution, size, cursor, xhr, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/cm/history/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (resolution !== undefined) {
                                localVarQueryParameter['resolution'] = resolution;
                            }
                            if (size !== undefined) {
                                localVarQueryParameter['size'] = size;
                            }
                            if (cursor !== undefined) {
                                localVarQueryParameter['cursor'] = cursor;
                            }
                            if (xhr !== undefined) {
                                localVarQueryParameter['xhr'] = xhr;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get user recent content
         * @summary Get user recent content
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [resolution] 1x, 2x, 3x
         * @param {string} [size] small, medium, large
         * @param {string} [xhr] (1/0) is web platform
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserRecentContent: function (authorization, acceptLanguage, resolution, size, xhr, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/cm/recent/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (resolution !== undefined) {
                                localVarQueryParameter['resolution'] = resolution;
                            }
                            if (size !== undefined) {
                                localVarQueryParameter['size'] = size;
                            }
                            if (xhr !== undefined) {
                                localVarQueryParameter['xhr'] = xhr;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ContentListingApiAxiosParamCreator = ContentListingApiAxiosParamCreator;
/**
 * ContentListingApi - functional programming interface
 * @export
 */
var ContentListingApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ContentListingApiAxiosParamCreator)(configuration);
    return {
        /**
         * Get paid contents by account
         * @summary Get paid contents by account
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {'active' | 'all' | 'deactive'} [type] Type: active/all/deactive, default&#x3D;active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountPaidContents: function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, type, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAccountPaidContents(accountId, tenantSlug, authorization, acceptLanguage, page, limit, type, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary List programs of EPG
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [scheduleDate] Specify will ignore start_time and end_time ( format - yyyy-mm-dd )
         * @param {string} [startTime] Filter programs start after this (default to now)
         * @param {string} [endTime] Filter programs end before this (default to now + 3hrs)
         * @param {string} [channelId] channel id if needed for specific
         * @param {string} [timezone] timezone of your current location
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getChannelEpgs: function (acceptLanguage, page, limit, select, scheduleDate, startTime, endTime, channelId, timezone, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getChannelEpgs(acceptLanguage, page, limit, select, scheduleDate, startTime, endTime, channelId, timezone, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get contents by metadata
         * @summary Get contents by metadata
         * @param {string} metadataSlug
         * @param {string} tenantSlug
         * @param {string} metadata Choice [\&#39;genre\&#39;, \&#39;region\&#39;, \&#39;people\&#39;, \&#39;content_category\&#39;, \&#39;sub_category\&#39;]
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Required param
         * @param {number} [limit] Required param
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentsByMetadata: function (metadataSlug, tenantSlug, metadata, authorization, acceptLanguage, page, limit, select, imageResolutionScale, imageFormat, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getContentsByMetadata(metadataSlug, tenantSlug, metadata, authorization, acceptLanguage, page, limit, select, imageResolutionScale, imageFormat, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get clip hay ribbon
         * @summary Get clip hay ribbon
         * @param {string} contentId Id of content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [page] Page number ( start at 0 )
         * @param {string} [limit] Total content response
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getListClipHay: function (contentId, authorization, acceptLanguage, page, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getListClipHay(contentId, authorization, acceptLanguage, page, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * List channel of EPG
         * @summary List channel of EPG
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getListLiveChannel: function (authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getListLiveChannel(authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get live schedules for page
         * @param {string} pageSlug
         * @param {string} platformSlug
         * @param {string} tenantSlug
         * @param {number} startTime start time (timestamp)
         * @param {number} endTime end time (timestamp)
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [ribbonIds] ribbon ids
         * @param {string} [timezone] Timezone
         * @param {'range_time' | 'freeze'} [mode] Dev mode
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [adParams] Additional ad parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveSchedulesForPage: function (pageSlug, platformSlug, tenantSlug, startTime, endTime, authorization, acceptLanguage, select, imageResolutionScale, ribbonIds, timezone, mode, page, limit, adParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getLiveSchedulesForPage(pageSlug, platformSlug, tenantSlug, startTime, endTime, authorization, acceptLanguage, select, imageResolutionScale, ribbonIds, timezone, mode, page, limit, adParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get data for display page
         * @summary Get data for display page
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} pageSlug  id of page Get VOD_LIST page: page_slug&#x3D;\&quot;vod_list_page\&quot;
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [platformSlug] Application platform slug
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [genreSlug] Filter content by genre
         * @param {string} [languageSlug] Filter content by language
         * @param {string} [regionSlug] Filter content by region
         * @param {string} [peopleSlugs] Filter content by peoples
         * @param {string} [providerSlug] Filter content by provider
         * @param {string} [categorySlug] Filter content by category
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagePersonalizedRibbons: function (accountId, tenantSlug, pageSlug, authorization, acceptLanguage, select, imageResolutionScale, platformSlug, page, limit, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, imageFormat, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPagePersonalizedRibbons(accountId, tenantSlug, pageSlug, authorization, acceptLanguage, select, imageResolutionScale, platformSlug, page, limit, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, imageFormat, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get static page data
         * @param {string} tenantSlug
         * @param {string} pageSlug  id of page Get VOD_LIST page: page_slug&#x3D;\&quot;vod_list_page\&quot;
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [platformSlug] Application platform slug
         * @param {string} [genreSlug] Filter content by genre
         * @param {string} [languageSlug] Filter content by language
         * @param {string} [regionSlug] Filter content by region
         * @param {string} [peopleSlugs] Filter content by peoples
         * @param {string} [providerSlug] Filter content by provider
         * @param {string} [categorySlug] Filter content by category
         * @param {string} [imageFormat] Image type
         * @param {string} [date] TV_Guide has different display by date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageStaticRibbons: function (tenantSlug, pageSlug, acceptLanguage, select, page, limit, imageResolutionScale, platformSlug, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, imageFormat, date, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPageStaticRibbons(tenantSlug, pageSlug, acceptLanguage, select, page, limit, imageResolutionScale, platformSlug, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, imageFormat, date, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get paid contents by account V2
         * @summary Get paid contents by account V2
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {'active' | 'all' | 'deactive'} [type] Type: active/all/deactive, default&#x3D;active
         * @param {Array<string>} [contentIds] Filter list content ids is activated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaidContents: function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, type, contentIds, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPaidContents(accountId, tenantSlug, authorization, acceptLanguage, page, limit, type, contentIds, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get personal ribbon detail API
         * @summary Get personal ribbon detail API
         * @param {string} accountId
         * @param {string} pageSlug
         * @param {string} ribbonId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [platformSlug] Application platform slug
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [genreSlug] Filter content by genre
         * @param {string} [languageSlug] Filter content by language
         * @param {string} [regionSlug] Filter content by region
         * @param {string} [peopleSlugs] Filter content by peoples
         * @param {string} [providerSlug] Filter content by provider
         * @param {string} [categorySlug] Filter content by category
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalizedRibbonDetail: function (accountId, pageSlug, ribbonId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, platformSlug, page, limit, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, imageFormat, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPersonalizedRibbonDetail(accountId, pageSlug, ribbonId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, platformSlug, page, limit, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, imageFormat, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get content dynamic detail
         * @summary Get content dynamic detail
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendedOfContent: function (contentId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, page, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getRecommendedOfContent(contentId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, page, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get related content by a specific content id
         * @summary Get related content by a specific content id
         * @param {string} relatedByContentId related by content id
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [page] Page number ( start at 0 )
         * @param {string} [limit] Total content response
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getRelatedContentById: function (relatedByContentId, authorization, acceptLanguage, page, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getRelatedContentById(relatedByContentId, authorization, acceptLanguage, page, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get related contents of content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [expectTypes] Expected return types of content, split by ,
         * @param {string} [displayType] Display type: NORMAL/PICK
         * @param {boolean} [hasTopContents] Display type: True/False
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedContentsOfContent: function (contentId, tenantSlug, authorization, acceptLanguage, imageResolutionScale, select, page, limit, expectTypes, displayType, hasTopContents, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getRelatedContentsOfContent(contentId, tenantSlug, authorization, acceptLanguage, imageResolutionScale, select, page, limit, expectTypes, displayType, hasTopContents, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get items of ribbon
         * @param {string} pageSlug
         * @param {string} ribbonId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [platform] web, android, ios
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [genreSlug] Filter content by genre
         * @param {string} [languageSlug] Filter content by language
         * @param {string} [regionSlug] Filter content by region
         * @param {string} [peopleSlugs] Filter content by peoples
         * @param {string} [providerSlug] Filter content by provider
         * @param {string} [categorySlug] Filter content by category
         * @param {string} [secret] Code to unlock the ribbon
         * @param {string} [imageFormat] Image type
         * @param {string} [date] Empathy interface: ribbon display is different by date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRibbonDetail: function (pageSlug, ribbonId, tenantSlug, authorization, acceptLanguage, page, limit, imageResolutionScale, platform, select, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, secret, imageFormat, date, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getRibbonDetail(pageSlug, ribbonId, tenantSlug, authorization, acceptLanguage, page, limit, imageResolutionScale, platform, select, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, secret, imageFormat, date, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get items of ribbon
         * @summary Get items of ribbon
         * @param {string} ribbonId Id of ribbon
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Current page
         * @param {number} [limit] page limit
         * @param {string} [platform] web, android, ios
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getRibbonItems: function (ribbonId, authorization, acceptLanguage, page, limit, platform, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getRibbonItems(ribbonId, authorization, acceptLanguage, page, limit, platform, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRibbonTypeById: function (id, tenantSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getRibbonTypeById(id, tenantSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRibbonTypes: function (tenantSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getRibbonTypes(tenantSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get search result
         * @summary Get search result
         * @param {string} keyword Keyword
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [page] Page number ( start at 0 )
         * @param {string} [limit] Total content response
         * @param {number} [tenantId] id of tenant
         * @param {string} [tenantSlug] slug of tenant
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSearchResult: function (keyword, authorization, acceptLanguage, page, limit, tenantId, tenantSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSearchResult(keyword, authorization, acceptLanguage, page, limit, tenantId, tenantSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get season by content_id
         * @summary Get season by content_id
         * @param {string} contentId id of content
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [userId] id of user request api
         * @param {number} [page] Page number ( start at 0 )
         * @param {number} [limit] Total content response (default is 1000 )
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSeasonDetailById: function (contentId, authorization, acceptLanguage, userId, page, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSeasonDetailById(contentId, authorization, acceptLanguage, userId, page, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get the {season_index}th season of show {content_id}
         * @summary Get the {season_index}th season of show {content_id}
         * @param {string} contentId id of content
         * @param {string} [authorization] user access token for future use
         * @param {number} [season] season index (1..n)
         * @param {number} [page] Page number (strart at 0)
         * @param {number} [limit] Total content response (default is 1000 )
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSeasonIndexOfShow: function (contentId, authorization, season, page, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSeasonIndexOfShow(contentId, authorization, season, page, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get all season of show
         * @summary Get all season of show
         * @param {string} showId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeasonsOfShow: function (showId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSeasonsOfShow(showId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get data for display page
         * @summary Get data for display page
         * @param {string} pageId id of page
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [platform] web, android, ios
         * @param {string} [regionSlug] Filter by region
         * @param {string} [genreSlug] Filter by genre
         * @param {string} [languageSlug] Filter by language
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getTenantPageDetail: function (pageId, authorization, acceptLanguage, platform, regionSlug, genreSlug, languageSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTenantPageDetail(pageId, authorization, acceptLanguage, platform, regionSlug, genreSlug, languageSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get user history
         * @summary Get user history
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [resolution] 1x, 2x, 3x
         * @param {string} [size] small, medium, large
         * @param {number} [cursor] page ( default 0 )
         * @param {number} [xhr] (0/1) is web platform
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserHistory: function (authorization, acceptLanguage, resolution, size, cursor, xhr, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUserHistory(authorization, acceptLanguage, resolution, size, cursor, xhr, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get user recent content
         * @summary Get user recent content
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [resolution] 1x, 2x, 3x
         * @param {string} [size] small, medium, large
         * @param {string} [xhr] (1/0) is web platform
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserRecentContent: function (authorization, acceptLanguage, resolution, size, xhr, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUserRecentContent(authorization, acceptLanguage, resolution, size, xhr, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.ContentListingApiFp = ContentListingApiFp;
/**
 * ContentListingApi - factory interface
 * @export
 */
var ContentListingApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ContentListingApiFp)(configuration);
    return {
        /**
         * Get paid contents by account
         * @summary Get paid contents by account
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {'active' | 'all' | 'deactive'} [type] Type: active/all/deactive, default&#x3D;active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountPaidContents: function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, type, options) {
            return localVarFp.getAccountPaidContents(accountId, tenantSlug, authorization, acceptLanguage, page, limit, type, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary List programs of EPG
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [scheduleDate] Specify will ignore start_time and end_time ( format - yyyy-mm-dd )
         * @param {string} [startTime] Filter programs start after this (default to now)
         * @param {string} [endTime] Filter programs end before this (default to now + 3hrs)
         * @param {string} [channelId] channel id if needed for specific
         * @param {string} [timezone] timezone of your current location
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getChannelEpgs: function (acceptLanguage, page, limit, select, scheduleDate, startTime, endTime, channelId, timezone, options) {
            return localVarFp.getChannelEpgs(acceptLanguage, page, limit, select, scheduleDate, startTime, endTime, channelId, timezone, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get contents by metadata
         * @summary Get contents by metadata
         * @param {string} metadataSlug
         * @param {string} tenantSlug
         * @param {string} metadata Choice [\&#39;genre\&#39;, \&#39;region\&#39;, \&#39;people\&#39;, \&#39;content_category\&#39;, \&#39;sub_category\&#39;]
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Required param
         * @param {number} [limit] Required param
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentsByMetadata: function (metadataSlug, tenantSlug, metadata, authorization, acceptLanguage, page, limit, select, imageResolutionScale, imageFormat, options) {
            return localVarFp.getContentsByMetadata(metadataSlug, tenantSlug, metadata, authorization, acceptLanguage, page, limit, select, imageResolutionScale, imageFormat, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get clip hay ribbon
         * @summary Get clip hay ribbon
         * @param {string} contentId Id of content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [page] Page number ( start at 0 )
         * @param {string} [limit] Total content response
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getListClipHay: function (contentId, authorization, acceptLanguage, page, limit, options) {
            return localVarFp.getListClipHay(contentId, authorization, acceptLanguage, page, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List channel of EPG
         * @summary List channel of EPG
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getListLiveChannel: function (authorization, acceptLanguage, options) {
            return localVarFp.getListLiveChannel(authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get live schedules for page
         * @param {string} pageSlug
         * @param {string} platformSlug
         * @param {string} tenantSlug
         * @param {number} startTime start time (timestamp)
         * @param {number} endTime end time (timestamp)
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [ribbonIds] ribbon ids
         * @param {string} [timezone] Timezone
         * @param {'range_time' | 'freeze'} [mode] Dev mode
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [adParams] Additional ad parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveSchedulesForPage: function (pageSlug, platformSlug, tenantSlug, startTime, endTime, authorization, acceptLanguage, select, imageResolutionScale, ribbonIds, timezone, mode, page, limit, adParams, options) {
            return localVarFp.getLiveSchedulesForPage(pageSlug, platformSlug, tenantSlug, startTime, endTime, authorization, acceptLanguage, select, imageResolutionScale, ribbonIds, timezone, mode, page, limit, adParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get data for display page
         * @summary Get data for display page
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} pageSlug  id of page Get VOD_LIST page: page_slug&#x3D;\&quot;vod_list_page\&quot;
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [platformSlug] Application platform slug
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [genreSlug] Filter content by genre
         * @param {string} [languageSlug] Filter content by language
         * @param {string} [regionSlug] Filter content by region
         * @param {string} [peopleSlugs] Filter content by peoples
         * @param {string} [providerSlug] Filter content by provider
         * @param {string} [categorySlug] Filter content by category
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagePersonalizedRibbons: function (accountId, tenantSlug, pageSlug, authorization, acceptLanguage, select, imageResolutionScale, platformSlug, page, limit, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, imageFormat, options) {
            return localVarFp.getPagePersonalizedRibbons(accountId, tenantSlug, pageSlug, authorization, acceptLanguage, select, imageResolutionScale, platformSlug, page, limit, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, imageFormat, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get static page data
         * @param {string} tenantSlug
         * @param {string} pageSlug  id of page Get VOD_LIST page: page_slug&#x3D;\&quot;vod_list_page\&quot;
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [platformSlug] Application platform slug
         * @param {string} [genreSlug] Filter content by genre
         * @param {string} [languageSlug] Filter content by language
         * @param {string} [regionSlug] Filter content by region
         * @param {string} [peopleSlugs] Filter content by peoples
         * @param {string} [providerSlug] Filter content by provider
         * @param {string} [categorySlug] Filter content by category
         * @param {string} [imageFormat] Image type
         * @param {string} [date] TV_Guide has different display by date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageStaticRibbons: function (tenantSlug, pageSlug, acceptLanguage, select, page, limit, imageResolutionScale, platformSlug, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, imageFormat, date, options) {
            return localVarFp.getPageStaticRibbons(tenantSlug, pageSlug, acceptLanguage, select, page, limit, imageResolutionScale, platformSlug, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, imageFormat, date, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get paid contents by account V2
         * @summary Get paid contents by account V2
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {'active' | 'all' | 'deactive'} [type] Type: active/all/deactive, default&#x3D;active
         * @param {Array<string>} [contentIds] Filter list content ids is activated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaidContents: function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, type, contentIds, options) {
            return localVarFp.getPaidContents(accountId, tenantSlug, authorization, acceptLanguage, page, limit, type, contentIds, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get personal ribbon detail API
         * @summary Get personal ribbon detail API
         * @param {string} accountId
         * @param {string} pageSlug
         * @param {string} ribbonId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [platformSlug] Application platform slug
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [genreSlug] Filter content by genre
         * @param {string} [languageSlug] Filter content by language
         * @param {string} [regionSlug] Filter content by region
         * @param {string} [peopleSlugs] Filter content by peoples
         * @param {string} [providerSlug] Filter content by provider
         * @param {string} [categorySlug] Filter content by category
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalizedRibbonDetail: function (accountId, pageSlug, ribbonId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, platformSlug, page, limit, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, imageFormat, options) {
            return localVarFp.getPersonalizedRibbonDetail(accountId, pageSlug, ribbonId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, platformSlug, page, limit, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, imageFormat, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get content dynamic detail
         * @summary Get content dynamic detail
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendedOfContent: function (contentId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, page, limit, options) {
            return localVarFp.getRecommendedOfContent(contentId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, page, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get related content by a specific content id
         * @summary Get related content by a specific content id
         * @param {string} relatedByContentId related by content id
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [page] Page number ( start at 0 )
         * @param {string} [limit] Total content response
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getRelatedContentById: function (relatedByContentId, authorization, acceptLanguage, page, limit, options) {
            return localVarFp.getRelatedContentById(relatedByContentId, authorization, acceptLanguage, page, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get related contents of content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [expectTypes] Expected return types of content, split by ,
         * @param {string} [displayType] Display type: NORMAL/PICK
         * @param {boolean} [hasTopContents] Display type: True/False
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedContentsOfContent: function (contentId, tenantSlug, authorization, acceptLanguage, imageResolutionScale, select, page, limit, expectTypes, displayType, hasTopContents, options) {
            return localVarFp.getRelatedContentsOfContent(contentId, tenantSlug, authorization, acceptLanguage, imageResolutionScale, select, page, limit, expectTypes, displayType, hasTopContents, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get items of ribbon
         * @param {string} pageSlug
         * @param {string} ribbonId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [platform] web, android, ios
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [genreSlug] Filter content by genre
         * @param {string} [languageSlug] Filter content by language
         * @param {string} [regionSlug] Filter content by region
         * @param {string} [peopleSlugs] Filter content by peoples
         * @param {string} [providerSlug] Filter content by provider
         * @param {string} [categorySlug] Filter content by category
         * @param {string} [secret] Code to unlock the ribbon
         * @param {string} [imageFormat] Image type
         * @param {string} [date] Empathy interface: ribbon display is different by date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRibbonDetail: function (pageSlug, ribbonId, tenantSlug, authorization, acceptLanguage, page, limit, imageResolutionScale, platform, select, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, secret, imageFormat, date, options) {
            return localVarFp.getRibbonDetail(pageSlug, ribbonId, tenantSlug, authorization, acceptLanguage, page, limit, imageResolutionScale, platform, select, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, secret, imageFormat, date, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get items of ribbon
         * @summary Get items of ribbon
         * @param {string} ribbonId Id of ribbon
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Current page
         * @param {number} [limit] page limit
         * @param {string} [platform] web, android, ios
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getRibbonItems: function (ribbonId, authorization, acceptLanguage, page, limit, platform, options) {
            return localVarFp.getRibbonItems(ribbonId, authorization, acceptLanguage, page, limit, platform, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRibbonTypeById: function (id, tenantSlug, options) {
            return localVarFp.getRibbonTypeById(id, tenantSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRibbonTypes: function (tenantSlug, options) {
            return localVarFp.getRibbonTypes(tenantSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get search result
         * @summary Get search result
         * @param {string} keyword Keyword
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [page] Page number ( start at 0 )
         * @param {string} [limit] Total content response
         * @param {number} [tenantId] id of tenant
         * @param {string} [tenantSlug] slug of tenant
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSearchResult: function (keyword, authorization, acceptLanguage, page, limit, tenantId, tenantSlug, options) {
            return localVarFp.getSearchResult(keyword, authorization, acceptLanguage, page, limit, tenantId, tenantSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get season by content_id
         * @summary Get season by content_id
         * @param {string} contentId id of content
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [userId] id of user request api
         * @param {number} [page] Page number ( start at 0 )
         * @param {number} [limit] Total content response (default is 1000 )
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSeasonDetailById: function (contentId, authorization, acceptLanguage, userId, page, limit, options) {
            return localVarFp.getSeasonDetailById(contentId, authorization, acceptLanguage, userId, page, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get the {season_index}th season of show {content_id}
         * @summary Get the {season_index}th season of show {content_id}
         * @param {string} contentId id of content
         * @param {string} [authorization] user access token for future use
         * @param {number} [season] season index (1..n)
         * @param {number} [page] Page number (strart at 0)
         * @param {number} [limit] Total content response (default is 1000 )
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSeasonIndexOfShow: function (contentId, authorization, season, page, limit, options) {
            return localVarFp.getSeasonIndexOfShow(contentId, authorization, season, page, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get all season of show
         * @summary Get all season of show
         * @param {string} showId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeasonsOfShow: function (showId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, options) {
            return localVarFp.getSeasonsOfShow(showId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get data for display page
         * @summary Get data for display page
         * @param {string} pageId id of page
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [platform] web, android, ios
         * @param {string} [regionSlug] Filter by region
         * @param {string} [genreSlug] Filter by genre
         * @param {string} [languageSlug] Filter by language
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getTenantPageDetail: function (pageId, authorization, acceptLanguage, platform, regionSlug, genreSlug, languageSlug, options) {
            return localVarFp.getTenantPageDetail(pageId, authorization, acceptLanguage, platform, regionSlug, genreSlug, languageSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get user history
         * @summary Get user history
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [resolution] 1x, 2x, 3x
         * @param {string} [size] small, medium, large
         * @param {number} [cursor] page ( default 0 )
         * @param {number} [xhr] (0/1) is web platform
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserHistory: function (authorization, acceptLanguage, resolution, size, cursor, xhr, options) {
            return localVarFp.getUserHistory(authorization, acceptLanguage, resolution, size, cursor, xhr, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get user recent content
         * @summary Get user recent content
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [resolution] 1x, 2x, 3x
         * @param {string} [size] small, medium, large
         * @param {string} [xhr] (1/0) is web platform
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserRecentContent: function (authorization, acceptLanguage, resolution, size, xhr, options) {
            return localVarFp.getUserRecentContent(authorization, acceptLanguage, resolution, size, xhr, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ContentListingApiFactory = ContentListingApiFactory;
/**
 * ContentListingApi - object-oriented interface
 * @export
 * @class ContentListingApi
 * @extends {BaseAPI}
 */
var ContentListingApi = /** @class */ (function (_super) {
    __extends(ContentListingApi, _super);
    function ContentListingApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get paid contents by account
     * @summary Get paid contents by account
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {'active' | 'all' | 'deactive'} [type] Type: active/all/deactive, default&#x3D;active
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentListingApi
     */
    ContentListingApi.prototype.getAccountPaidContents = function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, type, options) {
        var _this = this;
        return (0, exports.ContentListingApiFp)(this.configuration).getAccountPaidContents(accountId, tenantSlug, authorization, acceptLanguage, page, limit, type, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary List programs of EPG
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {string} [scheduleDate] Specify will ignore start_time and end_time ( format - yyyy-mm-dd )
     * @param {string} [startTime] Filter programs start after this (default to now)
     * @param {string} [endTime] Filter programs end before this (default to now + 3hrs)
     * @param {string} [channelId] channel id if needed for specific
     * @param {string} [timezone] timezone of your current location
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ContentListingApi
     */
    ContentListingApi.prototype.getChannelEpgs = function (acceptLanguage, page, limit, select, scheduleDate, startTime, endTime, channelId, timezone, options) {
        var _this = this;
        return (0, exports.ContentListingApiFp)(this.configuration).getChannelEpgs(acceptLanguage, page, limit, select, scheduleDate, startTime, endTime, channelId, timezone, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get contents by metadata
     * @summary Get contents by metadata
     * @param {string} metadataSlug
     * @param {string} tenantSlug
     * @param {string} metadata Choice [\&#39;genre\&#39;, \&#39;region\&#39;, \&#39;people\&#39;, \&#39;content_category\&#39;, \&#39;sub_category\&#39;]
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {number} [page] Required param
     * @param {number} [limit] Required param
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
     * @param {string} [imageFormat] Image type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentListingApi
     */
    ContentListingApi.prototype.getContentsByMetadata = function (metadataSlug, tenantSlug, metadata, authorization, acceptLanguage, page, limit, select, imageResolutionScale, imageFormat, options) {
        var _this = this;
        return (0, exports.ContentListingApiFp)(this.configuration).getContentsByMetadata(metadataSlug, tenantSlug, metadata, authorization, acceptLanguage, page, limit, select, imageResolutionScale, imageFormat, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get clip hay ribbon
     * @summary Get clip hay ribbon
     * @param {string} contentId Id of content
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [page] Page number ( start at 0 )
     * @param {string} [limit] Total content response
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ContentListingApi
     */
    ContentListingApi.prototype.getListClipHay = function (contentId, authorization, acceptLanguage, page, limit, options) {
        var _this = this;
        return (0, exports.ContentListingApiFp)(this.configuration).getListClipHay(contentId, authorization, acceptLanguage, page, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List channel of EPG
     * @summary List channel of EPG
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ContentListingApi
     */
    ContentListingApi.prototype.getListLiveChannel = function (authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.ContentListingApiFp)(this.configuration).getListLiveChannel(authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get live schedules for page
     * @param {string} pageSlug
     * @param {string} platformSlug
     * @param {string} tenantSlug
     * @param {number} startTime start time (timestamp)
     * @param {number} endTime end time (timestamp)
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
     * @param {string} [ribbonIds] ribbon ids
     * @param {string} [timezone] Timezone
     * @param {'range_time' | 'freeze'} [mode] Dev mode
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {string} [adParams] Additional ad parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentListingApi
     */
    ContentListingApi.prototype.getLiveSchedulesForPage = function (pageSlug, platformSlug, tenantSlug, startTime, endTime, authorization, acceptLanguage, select, imageResolutionScale, ribbonIds, timezone, mode, page, limit, adParams, options) {
        var _this = this;
        return (0, exports.ContentListingApiFp)(this.configuration).getLiveSchedulesForPage(pageSlug, platformSlug, tenantSlug, startTime, endTime, authorization, acceptLanguage, select, imageResolutionScale, ribbonIds, timezone, mode, page, limit, adParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get data for display page
     * @summary Get data for display page
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {string} pageSlug  id of page Get VOD_LIST page: page_slug&#x3D;\&quot;vod_list_page\&quot;
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
     * @param {string} [platformSlug] Application platform slug
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {string} [genreSlug] Filter content by genre
     * @param {string} [languageSlug] Filter content by language
     * @param {string} [regionSlug] Filter content by region
     * @param {string} [peopleSlugs] Filter content by peoples
     * @param {string} [providerSlug] Filter content by provider
     * @param {string} [categorySlug] Filter content by category
     * @param {string} [imageFormat] Image type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentListingApi
     */
    ContentListingApi.prototype.getPagePersonalizedRibbons = function (accountId, tenantSlug, pageSlug, authorization, acceptLanguage, select, imageResolutionScale, platformSlug, page, limit, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, imageFormat, options) {
        var _this = this;
        return (0, exports.ContentListingApiFp)(this.configuration).getPagePersonalizedRibbons(accountId, tenantSlug, pageSlug, authorization, acceptLanguage, select, imageResolutionScale, platformSlug, page, limit, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, imageFormat, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get static page data
     * @param {string} tenantSlug
     * @param {string} pageSlug  id of page Get VOD_LIST page: page_slug&#x3D;\&quot;vod_list_page\&quot;
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
     * @param {string} [platformSlug] Application platform slug
     * @param {string} [genreSlug] Filter content by genre
     * @param {string} [languageSlug] Filter content by language
     * @param {string} [regionSlug] Filter content by region
     * @param {string} [peopleSlugs] Filter content by peoples
     * @param {string} [providerSlug] Filter content by provider
     * @param {string} [categorySlug] Filter content by category
     * @param {string} [imageFormat] Image type
     * @param {string} [date] TV_Guide has different display by date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentListingApi
     */
    ContentListingApi.prototype.getPageStaticRibbons = function (tenantSlug, pageSlug, acceptLanguage, select, page, limit, imageResolutionScale, platformSlug, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, imageFormat, date, options) {
        var _this = this;
        return (0, exports.ContentListingApiFp)(this.configuration).getPageStaticRibbons(tenantSlug, pageSlug, acceptLanguage, select, page, limit, imageResolutionScale, platformSlug, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, imageFormat, date, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get paid contents by account V2
     * @summary Get paid contents by account V2
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {'active' | 'all' | 'deactive'} [type] Type: active/all/deactive, default&#x3D;active
     * @param {Array<string>} [contentIds] Filter list content ids is activated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentListingApi
     */
    ContentListingApi.prototype.getPaidContents = function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, type, contentIds, options) {
        var _this = this;
        return (0, exports.ContentListingApiFp)(this.configuration).getPaidContents(accountId, tenantSlug, authorization, acceptLanguage, page, limit, type, contentIds, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get personal ribbon detail API
     * @summary Get personal ribbon detail API
     * @param {string} accountId
     * @param {string} pageSlug
     * @param {string} ribbonId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
     * @param {string} [platformSlug] Application platform slug
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {string} [genreSlug] Filter content by genre
     * @param {string} [languageSlug] Filter content by language
     * @param {string} [regionSlug] Filter content by region
     * @param {string} [peopleSlugs] Filter content by peoples
     * @param {string} [providerSlug] Filter content by provider
     * @param {string} [categorySlug] Filter content by category
     * @param {string} [imageFormat] Image type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentListingApi
     */
    ContentListingApi.prototype.getPersonalizedRibbonDetail = function (accountId, pageSlug, ribbonId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, platformSlug, page, limit, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, imageFormat, options) {
        var _this = this;
        return (0, exports.ContentListingApiFp)(this.configuration).getPersonalizedRibbonDetail(accountId, pageSlug, ribbonId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, platformSlug, page, limit, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, imageFormat, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get content dynamic detail
     * @summary Get content dynamic detail
     * @param {string} contentId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentListingApi
     */
    ContentListingApi.prototype.getRecommendedOfContent = function (contentId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, page, limit, options) {
        var _this = this;
        return (0, exports.ContentListingApiFp)(this.configuration).getRecommendedOfContent(contentId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, page, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get related content by a specific content id
     * @summary Get related content by a specific content id
     * @param {string} relatedByContentId related by content id
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [page] Page number ( start at 0 )
     * @param {string} [limit] Total content response
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ContentListingApi
     */
    ContentListingApi.prototype.getRelatedContentById = function (relatedByContentId, authorization, acceptLanguage, page, limit, options) {
        var _this = this;
        return (0, exports.ContentListingApiFp)(this.configuration).getRelatedContentById(relatedByContentId, authorization, acceptLanguage, page, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get related contents of content
     * @param {string} contentId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {string} [expectTypes] Expected return types of content, split by ,
     * @param {string} [displayType] Display type: NORMAL/PICK
     * @param {boolean} [hasTopContents] Display type: True/False
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentListingApi
     */
    ContentListingApi.prototype.getRelatedContentsOfContent = function (contentId, tenantSlug, authorization, acceptLanguage, imageResolutionScale, select, page, limit, expectTypes, displayType, hasTopContents, options) {
        var _this = this;
        return (0, exports.ContentListingApiFp)(this.configuration).getRelatedContentsOfContent(contentId, tenantSlug, authorization, acceptLanguage, imageResolutionScale, select, page, limit, expectTypes, displayType, hasTopContents, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get items of ribbon
     * @param {string} pageSlug
     * @param {string} ribbonId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
     * @param {string} [platform] web, android, ios
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {string} [genreSlug] Filter content by genre
     * @param {string} [languageSlug] Filter content by language
     * @param {string} [regionSlug] Filter content by region
     * @param {string} [peopleSlugs] Filter content by peoples
     * @param {string} [providerSlug] Filter content by provider
     * @param {string} [categorySlug] Filter content by category
     * @param {string} [secret] Code to unlock the ribbon
     * @param {string} [imageFormat] Image type
     * @param {string} [date] Empathy interface: ribbon display is different by date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentListingApi
     */
    ContentListingApi.prototype.getRibbonDetail = function (pageSlug, ribbonId, tenantSlug, authorization, acceptLanguage, page, limit, imageResolutionScale, platform, select, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, secret, imageFormat, date, options) {
        var _this = this;
        return (0, exports.ContentListingApiFp)(this.configuration).getRibbonDetail(pageSlug, ribbonId, tenantSlug, authorization, acceptLanguage, page, limit, imageResolutionScale, platform, select, genreSlug, languageSlug, regionSlug, peopleSlugs, providerSlug, categorySlug, secret, imageFormat, date, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get items of ribbon
     * @summary Get items of ribbon
     * @param {string} ribbonId Id of ribbon
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {number} [page] Current page
     * @param {number} [limit] page limit
     * @param {string} [platform] web, android, ios
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ContentListingApi
     */
    ContentListingApi.prototype.getRibbonItems = function (ribbonId, authorization, acceptLanguage, page, limit, platform, options) {
        var _this = this;
        return (0, exports.ContentListingApiFp)(this.configuration).getRibbonItems(ribbonId, authorization, acceptLanguage, page, limit, platform, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {string} tenantSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentListingApi
     */
    ContentListingApi.prototype.getRibbonTypeById = function (id, tenantSlug, options) {
        var _this = this;
        return (0, exports.ContentListingApiFp)(this.configuration).getRibbonTypeById(id, tenantSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} tenantSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentListingApi
     */
    ContentListingApi.prototype.getRibbonTypes = function (tenantSlug, options) {
        var _this = this;
        return (0, exports.ContentListingApiFp)(this.configuration).getRibbonTypes(tenantSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get search result
     * @summary Get search result
     * @param {string} keyword Keyword
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [page] Page number ( start at 0 )
     * @param {string} [limit] Total content response
     * @param {number} [tenantId] id of tenant
     * @param {string} [tenantSlug] slug of tenant
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ContentListingApi
     */
    ContentListingApi.prototype.getSearchResult = function (keyword, authorization, acceptLanguage, page, limit, tenantId, tenantSlug, options) {
        var _this = this;
        return (0, exports.ContentListingApiFp)(this.configuration).getSearchResult(keyword, authorization, acceptLanguage, page, limit, tenantId, tenantSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get season by content_id
     * @summary Get season by content_id
     * @param {string} contentId id of content
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [userId] id of user request api
     * @param {number} [page] Page number ( start at 0 )
     * @param {number} [limit] Total content response (default is 1000 )
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ContentListingApi
     */
    ContentListingApi.prototype.getSeasonDetailById = function (contentId, authorization, acceptLanguage, userId, page, limit, options) {
        var _this = this;
        return (0, exports.ContentListingApiFp)(this.configuration).getSeasonDetailById(contentId, authorization, acceptLanguage, userId, page, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get the {season_index}th season of show {content_id}
     * @summary Get the {season_index}th season of show {content_id}
     * @param {string} contentId id of content
     * @param {string} [authorization] user access token for future use
     * @param {number} [season] season index (1..n)
     * @param {number} [page] Page number (strart at 0)
     * @param {number} [limit] Total content response (default is 1000 )
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ContentListingApi
     */
    ContentListingApi.prototype.getSeasonIndexOfShow = function (contentId, authorization, season, page, limit, options) {
        var _this = this;
        return (0, exports.ContentListingApiFp)(this.configuration).getSeasonIndexOfShow(contentId, authorization, season, page, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get all season of show
     * @summary Get all season of show
     * @param {string} showId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentListingApi
     */
    ContentListingApi.prototype.getSeasonsOfShow = function (showId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, options) {
        var _this = this;
        return (0, exports.ContentListingApiFp)(this.configuration).getSeasonsOfShow(showId, tenantSlug, authorization, acceptLanguage, select, imageResolutionScale, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get data for display page
     * @summary Get data for display page
     * @param {string} pageId id of page
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [platform] web, android, ios
     * @param {string} [regionSlug] Filter by region
     * @param {string} [genreSlug] Filter by genre
     * @param {string} [languageSlug] Filter by language
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ContentListingApi
     */
    ContentListingApi.prototype.getTenantPageDetail = function (pageId, authorization, acceptLanguage, platform, regionSlug, genreSlug, languageSlug, options) {
        var _this = this;
        return (0, exports.ContentListingApiFp)(this.configuration).getTenantPageDetail(pageId, authorization, acceptLanguage, platform, regionSlug, genreSlug, languageSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get user history
     * @summary Get user history
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [resolution] 1x, 2x, 3x
     * @param {string} [size] small, medium, large
     * @param {number} [cursor] page ( default 0 )
     * @param {number} [xhr] (0/1) is web platform
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ContentListingApi
     */
    ContentListingApi.prototype.getUserHistory = function (authorization, acceptLanguage, resolution, size, cursor, xhr, options) {
        var _this = this;
        return (0, exports.ContentListingApiFp)(this.configuration).getUserHistory(authorization, acceptLanguage, resolution, size, cursor, xhr, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get user recent content
     * @summary Get user recent content
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {string} [resolution] 1x, 2x, 3x
     * @param {string} [size] small, medium, large
     * @param {string} [xhr] (1/0) is web platform
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ContentListingApi
     */
    ContentListingApi.prototype.getUserRecentContent = function (authorization, acceptLanguage, resolution, size, xhr, options) {
        var _this = this;
        return (0, exports.ContentListingApiFp)(this.configuration).getUserRecentContent(authorization, acceptLanguage, resolution, size, xhr, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ContentListingApi;
}(base_1.BaseAPI));
exports.ContentListingApi = ContentListingApi;
