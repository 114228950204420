import React from 'react';
import Slider from 'react-slick';
import GroupThumbnail from '../group-thumbnail';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default class RibbonThumbnail extends React.Component {
  render() {
    const { title, ribbon, renderRibbonItem } = this.props;

    return (
      <React.Fragment>
        <Col sm={12} md={4}>
          <GroupThumbnail ribbon={ribbon} title={title} />
        </Col>
        <Col sm={12} md={8}>
          <Slider {...this.props.sliderSettings}>{renderRibbonItem()}</Slider>
        </Col>
      </React.Fragment>
    );
  }
}

RibbonThumbnail.propTypes = {
  sliderSettings: PropTypes.object,
  ribbon: PropTypes.object,
  renderRibbonItem: PropTypes.func,
  title: PropTypes.string,
  index: PropTypes.number,
};
