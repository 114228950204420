import styled from 'styled-components';

export const RibbonContainer = styled.div`
  &.ribbonContainer {
    width: 100%;
    display: block;
    float: left;
    background-color: #f5f5f5;
    font-size: 16px;
    .ribbonHead {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 0;
      h4.title {
        color: ${({ theme }) => theme.ribbon.titleColor};
        padding-left: 0;
        max-width: 78%;
        z-index: 11;
        text-transform: capitalize;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 13px;
      }
      h5.title_action {
        font-weight: 200;
        font-size: 16px;
        color: ${({ theme }) => theme.ribbon.viewAll};
        padding-right: 30px;
        cursor: pointer;
        white-space: nowrap;
        i {
          color: ${({ theme }) => theme.ribbon.viewAll};
          font-size: 1rem;
        }
      }
      a.viewAll {
        font-size: 1.125rem;
        color: #0095ff;
        padding-right: 0;
        margin-right: 0;
        float: right;
        text-decoration: none;
        z-index: 11;
      }
    }
    .prevButton {
      left: 0;
    }
    .nextButton {
      right: 0;
    }
    .customiseSlickBtn {
      bottom: 50% !important;
    }
    .prevButton,
    .nextButton {
      width: 30px;
      visibility: visible;
      position: absolute;
      z-index: 1;
      bottom: 20%;
      cursor: pointer;
      text-decoration: none;
      opacity: 1;
      transition: visibility 1s linear 0.1s, opacity 0.1s linear;
      border-radius: 0;
      display: flex;
      justify-content: center;
      .prevButtonIcon,
      .nextButtonIcon {
        transition: font-size 0.5s ease-out 100ms;
        font-size: 1rem;
        color: #ffffff;
      }
    }
  }

  .sliderBlock {
    clear: both;
    width: 100%;
  }

  .icon_chevron_right {
    font-size: 16px;
    color: #3e3e3e;
    margin-left: 8px;
  }

  &.ribbonContainer .sliderBlock .slick-slider {
    z-index: 100;
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  @media (min-width: 768px) {
    &.ribbonContainer {
      &:hover {
        .prevButton,
        .nextButton {
          visibility: visible;
          opacity: 0.9;
          transition-delay: 0s;
          text-decoration: none;
          &:hover {
            .prevButtonIcon,
            .nextButtonIcon {
              font-size: 1.85rem;
            }
          }
        }
      }
      .prevButton,
      .nextButton {
        width: 60px;
        opacity: 0;
      }
    }
  }

  @media (min-width: 992px) {
    &.ribbonContainer {
      .ribbonHead {
        h4.title {
          font-size: 24px;
          margin-bottom: 11px;
        }
      }
    }
  }
`;

export const RibbonItemWrap = styled.div`
  transition: transform 0.5s;
  box-shadow: rgb(0 0 0 / 75%) 0 30px 10px;
  .play-from {
    display: none;
  }
  &.Recent {
    &:hover,
    &:focus {
      transform: scale(1.4);
      z-index: 100;
      &.start-item {
        transform-origin: 0 50%;
      }
      &.last-item {
        transform-origin: 100% 50%;
      }
    }
    .play-from {
      display: block;
    }
    .total-episode {
      display: none;
    }
    .infoWrapper--thumbnail {
      .title {
        display: none;
      }
      .play-from {
        margin-bottom: 5px;
      }
    }
  }
  &.ribbonItemContainer {
    margin-right: 1rem;
    overflow: hidden;
    .loading-placeholder {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      span {
        width: 100%;
        height: 100%;
        display: flex;
      }
    }
    a {
      color: #444444;
      display: block;
      text-decoration: none;
    }
    .infoWrapper {
      bottom: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: flex-end;
      color: white;
      background-image: linear-gradient(to top, #1c1c1c 0%, rgba(255, 255, 255, 0) 100%);
      transition: visibility 1s linear 0.1s, opacity 0.1s linear;
      .title {
        font-size: 1rem;
        text-transform: capitalize;
        font-weight: bold;
        line-height: 29px;
        margin-bottom: 5px;
      }
      .summary,
      .play-from {
        font-size: 16px;
        font-weight: 200;
        text-transform: capitalize;
      }
      &.infoWrapper--poster {
        padding: 0 20px 25px;
        .actions-left,
        .actions-right {
          width: 45%;
        }
      }
      &.infoWrapper--thumbnail {
        padding: 0 18px 11px;
        .actions {
          .actions-left,
          .actions-right {
            width: 40%;
          }
        }
        .summary {
          display: none;
        }
      }
    }
    &:hover {
      a .srcImg {
        overflow: hidden;
        box-shadow: 0 0 19px -3px rgba(71, 71, 71, 1);
      }
      button.remove {
        opacity: 1;
        background-color: rgba(189, 2, 2, 0.8);
        z-index: 9;
        color: #fff;
      }
      .infoWrapper {
        display: flex;
      }
      &:not(.Recent) {
        .infoWrapper--poster {
          .actions {
            justify-content: space-around;
            .button {
              width: 17%;
            }
          }
        }
      }
      .progress-wrap-thumbnail {
        bottom: 9px;
      }
    }
    h6 {
      font-size: 14px;
      font-weight: bold;
      color: ${({ theme }) => theme.ribbon.itemNameColor};
    }
    .imageWrapper {
      width: 100%;
      margin-bottom: 0.5rem;
      padding: 0;
      border: none;
      overflow: hidden;
      position: relative;
      padding-top: calc(9 / 16 * 100%);
      &.thumbnail {
        background-color: unset;
      }
      &.poster {
        padding-top: calc(40 / 27 * 100%);
      }
      .srcImg {
        display: block;
        max-width: 100%;
        width: 100%;
        transition: all 200ms ease-out;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: contain;
        object-position: top;
      }
    }
    &.small {
      width: 90%;
    }
    .infoWrapper {
      display: none;
    }
  }
  @media (min-width: 576px) {
    &.ribbonItemContainer {
      .infoWrapper {
        .title {
          font-size: 1.6rem;
        }
      }
    }
  }
  @media (min-width: 768px) {
    &.ribbonItemContainer {
      .infoWrapper {
        .title {
          font-size: 2rem;
        }
      }
    }
  }
`;

export const RibbonItemActionsWrap = styled.div`
  position: absolute;
  width: 100%;
  bottom: 13px;
  .progress-bar {
    width: 100%;
    background-color: unset;
    padding: 0 20px;
    &--container {
      position: relative;
      height: 5px;
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: transparent;
      .background {
        position: absolute;
        background-color: #dadada;
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
      .percent {
        position: absolute;
        background-color: ${({ theme }) => theme.primaryColor};
        height: 100%;
        border-radius: 10px;
      }
    }
  }
`;
