import { getConfigByKey } from '~features/tenantConfig/services';
import * as type from './../constants';
import { TENANT_NAME_ENUM } from '~core/constants';

export const getEps = (component, data) => {
  const { landingPage } = component.props;
  const { infoShow } = component.state;
  const { entityDetail } = data;
  let episodes = landingPage.season.episodes;
  let trailers = entityDetail.trailers ? entityDetail.trailers : [];
  if (getConfigByKey('ui_theme') === TENANT_NAME_ENUM.PROJECTW) {
    trailers = [];
  }
  if (trailers.length > 0) {
    trailers.map(item => {
      item.can_preview = true;
      item.trailer = true;
    });
  }
  component.backUrl = entityDetail.slug;

  if (entityDetail.group && entityDetail.type === type.EPISODE_TYPE) {
    trailers = infoShow.trailers ? infoShow.trailers : [];
    if (getConfigByKey('ui_theme') === TENANT_NAME_ENUM.PROJECTW) {
      trailers = [];
    }
    if (trailers.length > 0) {
      trailers.map(item => {
        item.can_preview = true;
        item.trailer = true;
      });
    }
    episodes = [...(trailers || []), ...(episodes || [])];
    return episodes;
  }
  if (entityDetail.type === type.MOVIE_TYPE || entityDetail.type === type.CLIP) {
    episodes = [...trailers, entityDetail];
    return episodes;
  }
};
