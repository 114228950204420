import { RibbonItemActionsWrap } from './styled';
import React from 'react';

const RibbonItemProgressBar = props => {
  const { displayType, percent, visible } = props;
  if (!visible) return null;

  return (
    <RibbonItemActionsWrap className={'progress-wrap-' + displayType}>
      <div className="progress-bar">
        <div className="progress-bar--container">
          <div className="background">
            <div className="percent" style={{ width: percent + '%' }} />
          </div>
        </div>
      </div>
    </RibbonItemActionsWrap>
  );
};

export default RibbonItemProgressBar;
