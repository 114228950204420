import _ from 'lodash';
import { IMessage } from '@rocket.chat/sdk/dist/config/messageInterfaces';

export function isNormalMessage(message: IMessage) {
  try {
  } catch (error) {}
}

export function isEditedMessage(this: IMessage) {
  const self: IMessage = this;
  try {
    return !_.isEmpty(self.editedBy);
  } catch (error) {}
  return false;
}

export function isRemovedMessage(this: IMessage) {
  const self: IMessage = this;

  try {
    return self.t && self.t === 'rm';
  } catch (error) {}
  return false;
}
