import React from 'react';
import styled from 'styled-components';
import * as displayType from './../constants';

class BadgeNewRelease extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { item, target } = this.props;
    const { type } = item;
    if (!item.is_new_release) {
      return null;
    }
    if (target === 'banner') {
      return null;
      // return (
      //   <DivStyle className={`ribbonNewRelease`} theme={{ main: 'Episode' }}>
      //     <p>New</p>
      //     {type === displayType.SHOW_TYPE ||
      //     type === displayType.EPISODE_TYPE ? (
      //       <div className="ribbonNewRelease--subEpg">
      //         <p>Episode</p>
      //       </div>
      //     ) : null}
      //   </DivStyle>
      // )
    }
    return (
      <DivStyle
        className={`ribbonNewReleases ${
          (type === displayType.SHOW_TYPE || type === displayType.EPISODE_TYPE) &&
          'ribbonNewReleases--epg'
        }`}
        theme={{ main: 'Episode' }}
      >
        <p>New</p>
      </DivStyle>
    );
  }
}

export const DivStyle = styled.div`
  &.ribbonNewRelease {
    position: absolute;
    top: 80px;
    z-index: 1;
    width: 78px;
    height: 20px;
    background: unset;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    left: 0;
    color: #fff;
    font-weight: bold;
    border-top: 20px solid #00b2ff;
    border-right: 12px solid transparent;
    z-index: 2;
    p {
      margin: 0;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: bold;
      position: absolute;
      top: 0;
      left: 9px;
      transform: translateY(-90%);
    }
  }
  .ribbonNewRelease {
    &--subEpg {
      position: absolute;
      top: 0;
      width: 58px;
      height: 14px;
      background: unset;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      left: 0;
      color: #fff;
      border-top: 14px solid #fff;
      border-right: 8px solid transparent;
      z-index: 2;
      p {
        font-size: 9px;
        text-transform: capitalize;
        color: #2574d4;
      }
    }
  }
  &.ribbonNewReleases {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 12rem;
    height: 3.5rem;
    background: #00b2ff;
    margin-left: -1.75rem;
    transform: rotate(330deg);
    margin-top: -2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    left: -0.25rem;
    color: #fff;
    font-weight: 600;
    p {
      margin: 10px 0 0 1.75rem;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
    }
    &--epg {
      &::before {
        content: '${props => props.theme.main}';
        position: absolute;
        width: 50%;
        font-size: 10px;
        height: auto;
        background: #fff;
        color: #2574d4;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 53px;
        bottom: -0.75rem;
        right: 43%;

        padding: 1px;
      }
    }
  }
  @media (min-width: 992px) {
    &.ribbonNewReleases {
      height: 2.5rem;
      &--epg {
        &::before {
          right: 52%;
        }
      }
    }
  }
`;
export default BadgeNewRelease;
