var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OdxPaymentApi = void 0;
var integration_service_1 = require("../integration-service");
var factory_1 = require("../factory");
var OdxPaymentApi = /** @class */ (function (_super) {
    __extends(OdxPaymentApi, _super);
    function OdxPaymentApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OdxPaymentApi.prototype.applyRedeemCodeToAccount = function (accountId, tenantId, data, authorization, acceptLanguage, confirm, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.checkPaymentSubscriptionApi = function (accountId, paymentProvider, tenantSlug, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.adminCreateAccountSubscription = function (tenantSlug, data, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.applyCouponForUser = function (accountId, tenantId, data, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.cancelRequestRefund = function (id, tenantSlug, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.cancelUserSubscription = function (tenantId, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.checkCountryAllowedSubscription = function (tenantSlug, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.confirmPaymentSubscription = function (accountId, paymentProvider, tenantId, data, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.confirmPaypalPurchase = function (accountId, tenantSlug, data, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.confirmSingleContentSubscription = function (accountId, tenantSlug, data, authorization, acceptLanguage, idempotencyKey, timezone, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.createIapSubscription = function (accountId, tenantSlug, data, idempotencyKey, authorization, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.createIapSubscription_1 = function (tenantSlug, data, acceptLanguage, authorization, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.createPaymentSubscription = function (accountId, paymentProvider, tenantId, data, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.createPaypalPurchase = function (accountId, tenantSlug, data, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.createSingleContentSubscription = function (accountId, tenantSlug, data, authorization, acceptLanguage, idempotencyKey, timezone, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.createStripeSubscription = function (tenantId, data, authorization, acceptLanguage, draft, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.getAllSubscriptionPlans = function (tenantId, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.getApprovedRefunds = function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, status, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.getCurrentSubscriptionPlan = function (tenantId, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.getIapStatus = function (tenantId, data, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.getListSubscriptionPlan = function (tenantId, authorization, acceptLanguage, environment, tenantPlatform, planId, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.getListSubscriptionProduct = function (tenantId, authorization, acceptLanguage, environment, tenantPlatform, planId, showMultiMonths, showMobifone9029, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.getPayments = function (tenantSlug, paymentMethod, authorization, acceptLanguage, search, ordering, page, pageSize, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.getPaypalPayers = function (accountId, tenantSlug, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.getSubscriptionPlanDetail = function (planId, tenantId, authorization, acceptLanguage, environment, tenantPlatform, showMultiMonths, showMobifone9029, options) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var planDetail, priceInfo, priceDescription, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        planDetail = (0, factory_1.createSubscriptionPlan)(planId);
                        return [4 /*yield*/, this.odxService.odxPaymentApi.productSubscriptionRetrieve(planId, tenantId, undefined)];
                    case 1:
                        priceInfo = _a.sent();
                        priceDescription = priceInfo.data.result;
                        planDetail.name = priceDescription.product_name;
                        planDetail.cost = priceDescription.price;
                        planDetail.real_cost = priceDescription.price;
                        resolve(__assign(__assign({}, (0, factory_1.createNullAxiosResponse)()), { data: planDetail }));
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        reject(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    OdxPaymentApi.prototype.getSubscriptionTypes = function (tenantSlug, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.getTenantPaymentMethods = function (tenantSlug, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.getUserInvoiceHistory = function (tenantId, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.getUserPaymentMethod = function (tenantId, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.getUserSubscriptionHistory = function (accountId, tenantId, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.paypalWebhook = function (paymentSlug, tenantSlug, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.paypalWebhook_2 = function (paymentSlug, tenantSlug, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.preCheckSubscription = function (tenantId, originalTransactionId, authorization, acceptLanguage, appOs, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.remainingPurchasesAccount = function (accountId, tenantSlug, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.requestContentRefund = function (contentId, tenantSlug, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.syncIapSubscriptionToStripe = function (tenantId, data, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.transferSubscription = function (tenantId, data, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.triggerWebhookEvent = function (options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.triggerWebhookEvent_3 = function (tenantSlug, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.updateMobiphonePackage = function (authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.updateMobiphonePackage_4 = function (tenantSlug, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.updateUserPaymentMethod = function (tenantId, data, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.calcPaymentSubscription = function (accountId, paymentProvider, tenantId, data, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.exportPayments = function (tenantSlug, paymentMethod, authorization, acceptLanguage, search, ordering, page, pageSize, options) {
        return Promise.reject('Method not implemented');
    };
    OdxPaymentApi.prototype.subscribeContentByCode = function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    return OdxPaymentApi;
}(integration_service_1.OdxIntegration));
exports.OdxPaymentApi = OdxPaymentApi;
