import ReactDOM from 'react-dom';
import React from 'react';
import videojs from 'video.js';
import { OpenCloseChatLive } from '~components/chatLive/components';
import jquery from 'jquery';
import { provideComponent } from '~root';

const vjsComponent = videojs.getComponent('Component');

class ToggleChatLiveVjs extends vjsComponent {
  constructor(player, options) {
    super(player, options);
    this.mount();
    const $player = jquery(this.el());

    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }
  // TODO : Create class element for component render
  createEl() {
    const toggleChatLive = videojs.createEl('div', {
      className: 'vjs-toggle-chat-live',
    });
    return toggleChatLive;
  }

  //TODO : Render your component
  mount() {
    const { component, entity } = this.options_;
    provideComponent(
      <OpenCloseChatLive
        player={this.player_}
        component={component}
        entity={entity}
        isToggle={true}
      />,
      this.el(),
    );
  }
}

/**
 * Make sure to register the vjsComponent so Video JS knows it exists
 */
// TODO :  Add component into player
videojs.registerComponent('ToggleChatLiveVjs', ToggleChatLiveVjs);

export default ToggleChatLiveVjs;
