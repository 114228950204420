import EPG from '../components/EPG';
import ReactDOM from 'react-dom';
import React from 'react';
import videojs from 'video.js';
import jquery from 'jquery';

const vjsComponent = videojs.getComponent('Component');

class EPGList extends vjsComponent {
  constructor(player, options) {
    super(player, options);

    /* Bind the current class context to the mount method */
    this.mount = this.mount.bind(this);
    const $player = jquery(this.player_.el_);
    /* When player is ready, call method to mount React component */
    player.on('ready', () => {
      // if (!($player.hasClass('vjs-live') || $player.hasClass('vjs-liveui'))) {
      //   return
      // }
      this.mount(options.channelId);
    });
    this.mount(options.channelId);
    /* Remove React root when component is destroyed */
    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }

  createEl() {
    const btn = videojs.dom.createEl('div', {
      className: 'vjs-epg-display',
      id: 'vjs-epg-display',
    });
    return btn;
  }

  /**
   * We will render out the React EpisodeList component into the DOM element
   * generated automatically by the VideoJS createEl() method.
   *
   * We fetch that generated element using `this.el()`, a method provided by the
   * vjsComponent class that this class is extending.
   */
  mount(channelId) {
    const { component, isRender } = this.options_;
    if (!channelId) {
      return;
    }
    ReactDOM.render(
      <EPG channelId={channelId} player={this.player_} component={component} isRender={isRender} />,
      this.el(),
    );
  }
}

/**
 * Make sure to register the vjsComponent so Video JS knows it exists
 */
vjsComponent.registerComponent('EPGList', EPGList);

export default EPGList;
