import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import lodash from 'lodash';
import { useTranslation } from 'react-i18next';
import TitleRecommend from './TitleRecommend';
import RecordRecommend from './RecordRecommend';
import { BANNER_DISPLAY, ILLUON_COLLECTION, ILLUON_SPOTLIGHT } from '../constants';
import WatchMore from '~features/homepage/projectw/watchMore';
import { getViewContentsByIds } from '../../services';
import { getThumbnail9_5, getIdsTrailers } from '~core/method/contentMethod';
import { getAccessToken } from '~utils/utils';

const RecommendStyled = styled(Box)`
  /* margin-top: 80px; */
  @media (min-width: 768px) {
    margin-top: 134px;
    margin-bottom: 30px;
    .see-more {
      display: none;
    }
  }
`;

function Recommend(props) {
  const { className = '' } = props;
  const { i18n } = useTranslation();
  const [watchMore, setWatchMore] = useState(false);
  const [contents, setContents] = useState(props.contents || []);
  const ribbon = props.ribbon || {};
  const [contentViewById, setContentViewById] = useState({});
  const [isGetViewCompleted, setGetViewCompleted] = useState(false);

  const [displayContents, setDisplayContents] = useState([]);
  const auth = useSelector(state => state.auth);
  const idsRequestingRef = useRef('');

  useEffect(() => {
    if (!watchMore) {
      setDisplayContents(contents.slice(0, 4));
      return;
    }
    setDisplayContents(contents);
  }, [watchMore, contents]);

  useEffect(() => {
    if (!ribbon || !ribbon.items || !ribbon.items.length) {
      return;
    }
    setContents(ribbon.items);
  }, [ribbon]);

  useEffect(() => {
    const accessToken = getAccessToken(true, auth);

    if (
      lodash.isEmpty(accessToken) ||
      isGetViewCompleted ||
      !contents.length ||
      ribbon.display_type !== ILLUON_SPOTLIGHT
    ) {
      return;
    }

    // const ids = lodash.map(contents, o => o.id);
    let ids = [];
    contents.map(c => {
      ids = [...ids, ...getIdsTrailers.call(c)];
    });

    const idsJoined = ids.join();
    if (idsJoined === idsRequestingRef.current) {
      return;
    }

    idsRequestingRef.current = ids.join();
    getViewContentsByIds(ids).then(res => {
      setGetViewCompleted(true);
      setContentViewById(lodash.keyBy(res, o => o.id));
    });
  }, [contents, auth]);

  if (!displayContents.length || !ribbon || ribbon.display_type !== ILLUON_SPOTLIGHT) {
    return null;
  }

  return (
    <RecommendStyled className={`${className}`}>
      <TitleRecommend title={ribbon.name || ''} ribbon={ribbon} prefix={ribbon.sub_name || ''} />
      {displayContents.map((record, i) => (
        <RecordRecommend
          {...props}
          record={record}
          key={`${record.id}`}
          contentView={contentViewById[record.id] || null}
          contentViewById={contentViewById}
        />
      ))}

      {/* <WatchMore watchMore={watchMore} setWatchMore={setWatchMore} /> */}
    </RecommendStyled>
  );
}

const mapStateToProps = state => ({
  displayContents: state.home.displayContents,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Recommend);
