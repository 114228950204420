import React from 'react';
import BlockCountryDefault from './views/BlockCountry';
import BlockCountryW from './projectw/BlockCountryW';
import { getConfigByKey } from '~features/tenantConfig/services';
import { TENANT_NAME_ENUM } from '~core/constants';

function BlockCountry(props) {
  switch (getConfigByKey('ui_theme')) {
    case TENANT_NAME_ENUM.PROJECTW:
      return <BlockCountryW {...props} />;
      break;
    default:
      return <BlockCountryDefault {...props} />;
      break;
  }
}

export default BlockCountry;
